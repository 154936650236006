<template>
  <div class="st-new-question__page">
    <div
      v-if="showQuestionsPanel"
      class="st-new-question__questions-added"
    >
      <p class="st-new-question__mobile-questions-title st-new-question__mobile-questions-title--edit-survey">
        Questions
      </p>
      <draggable
        v-model="questions"
        v-bind="dragOptions"
        class="st-new-question__new-tile-scroll-container"
        @end="handleEndDraggable"
      >
        <transition-group
          tag="div"
          :class="{
            'st-new-question__scroll-area': true,
            'st-new-question__scroll-area--empty': questions.length === 0,
          }"
        >
          <new-metric-tile
            v-for="(question, idx) in questions"
            :key="question.id"
            :index="idx"
            :is-preview="isPreview"
            @setActive="setActive(idx)"
            @openDeleteModal="deleteModal = true"
            @showSpecifications="showSpecifications"
          />
          <div
            v-if="questions.length === 0 && !isPreview"
            key="add"
            class="st-new-question__empty-icon-and-instructions"
          >
            <div class="st-new-question__empty-icon" />
            <p class="st-new-question__add-instructions">
              Add content by selecting it from the menu on your right
            </p>
          </div>
        </transition-group>
      </draggable>
      <div
        v-if="questions.length > 0 && !isPreview"
        class="st-new-question__add-question"
      >
        <div
          class="st-new-question__add-question-text"
          @click="addQuestion"
        >
          <icon-wrapper
            class="st-new-question__add-question-icon"
            icon-name="plus"
            :invert="true"
          />
          Add Question
        </div>
      </div>
    </div>
    <div class="bx--grid">
      <div
        class="bx--row st-new-question__back-and-save-links-container"
      >
        <div
          class="mobile-header st-new-question__back-and-save-links st-new-question__back-and-save-links--mobile"
        >
          <a
            href="/welcome"
          >
            <icon-wrapper
              class="mobile-header__spacetrics-icon"
              icon-name="spacetrics"
            />
          </a>
          <st-button
            caption="Save & Continue"
            @click="fakeSaveToHub"
          />
        </div>
        <div class="bx--col-sm-12 st-new-question__back-and-save-links">
          <st-hyperlink
            :back-link="true"
            text="Back"
            @click="handleBackLink"
          />
          <div class="st-edit-survey__save-link-and-button st-edit-survey__save-link-and-button--content">
            <div class="st-edit-survey__inline-spiner-and-save">
              <st-inline-spinner
                v-if="$store.state.isInlineSpinnerVisible"
                class="st-edit-survey__desktop-inline-spinner"
              />
              <st-hyperlink
                icon-name="save"
                text="Save"
                @click="fakeSaveProgress"
              />
            </div>
            <st-button
              class="st-edit-survey__hide-save-and-continue"
              caption="Save & Continue"
              @click="fakeSaveToHub"
            />
          </div>
        </div>
      </div>
      <div
        v-if="activeTab === SPECS_TABS.CONTENT"
        ref="addContent"
      >
        <contents
          :metric-image-paths="metricImagePaths"
          :survey-id="surveyId"
          :container-type="containerType"
          @setActive="setActive"
          @show-questions="showQuestions = true"
        />
      </div>
      <div
        v-if="activeTab === SPECS_TABS.SPECIFICATIONS"
        class="bx--row"
      >
        <div class="bx--col-lg-7 st-new-question__question-specifications">
          <div
            :class="{
              'st-specifications__header-row': true,
              'st-specifications__header-row--incomplete': !activeQuestion.complete,
            }"
          >
            <p class="st-specifications__title">
              {{ activeMetricName }}
            </p>
            <div class="st-specifications__header-icons-container on-top">
              <slide-controls
                v-if="activeQuestion.questionType === 'slide'"
                @changeTemplate="changeTemplate"
              />
              <st-tooltip
                class="st-specifications__header-icon-info-tooltip"
                :direction="isDesktop ? 'bottom' : 'left'"
                :max-width="isDesktop ? 411 : 225"
                :tip="activeMetricDescription.description"
              >
                <icon-wrapper
                  class="st-specifications__header-icon"
                  icon-name="info"
                />
              </st-tooltip>
              <div
                @click="showDeleteModal = true"
              >
                <icon-wrapper
                  class="st-specifications__header-icon st-specifications__header-icon--trash"
                  icon-name="trash"
                />
              </div>
            </div>
          </div>
          <p
            :class="{
              'st-specifications__question-position': true,
              'st-specifications__question-position--incomplete': !activeQuestion.complete,
            }"
          >
            Question {{ activeQuestion.priority + 1 }}/{{ questions.length }}
          </p>
          <div
            v-if="!activeQuestion.complete"
            class="st-specifications__alert-row"
          >
            <icon-wrapper
              class="st-new-question-tile__alert-icon"
              icon-name="alert"
            />
            <p class="st-specifications__alert-description">
              This question is incomplete
            </p>
          </div>
          <specifications
            :key="activeQuestion.id"
            :folders="folders"
          />
        </div>
        <st-hyperlink
          class="bx--col-xs-12 st-new-question__preview-link"
          text="Preview Question"
          icon-name="view-password"
          icon-class="st-hyperlink__default-icon-spacing"
          :url="`/questions/${activeQuestion.id}/preview`"
        />
        <div class="bx--offset-xl-1 bx--col-lg-4">
          <div
            class="st-new-question__virtual-phone-preview"
          >
            <preview-example
              :survey-name="surveyName"
              :survey-question-count="questions.length"
            />
          </div>
          <div class="st-new-question__help-icon-container">
            <a
              id="Intercom"
              href="javascript:void(o)"
            >
              <icon-wrapper
                icon-name="help"
                class="st-new-question__help-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <st-modal
      v-model="showDeleteModal"
      size-class="st-new-question__delete-question-modal"
      :non-exit-modal="true"
    >
      <template v-slot:modalTitle>
        Delete Question
      </template>
      <template v-slot:modalActions>
        <div class="st-publish-options-modal__instructions-container">
          <p class="st-publish-options-modal__instruction-text">
            If you delete this question you will lose any information or media you had in place.
          </p>
          <p class="st-publish-options-modal__instruction-text">
            Are you sure you want to delete this question?
          </p>
        </div>
      </template>
      <template v-slot:modalButton>
        <div class="st-cancel-modal__row">
          <div
            class="st-cancel-modal__button"
            @click="showDeleteModal = false"
          >
            <p>Cancel</p>
            <icon-wrapper
              icon-name="cancel"
              class="st-cancel-modal__icon"
            />
          </div>
          <st-button
            caption="Delete Question"
            icon-name="trash"
            :show-action="true"
            @click="deleteActiveQuestion"
          />
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { SPECS_TABS } from '../../../utilities/enums'
import { IconWrapper, StButton, StHyperlink, StInlineSpinner, StModal, StTooltip } from '../../common'
import { QUESTION_DESCRIPTIONS } from './constants'
import { isDesktop } from '../../../mixins'
import updateQuestion from '../../../mixins/question/updateQuestion.vue'
import Contents from './contents'
import NewMetricTile from './new-metric-tile'
import PreviewExample from './preview-example'
import Specifications from './specifications'
import SlideControls from './question_specifications/slide/slide-controls'

export default {
  components: {
    Contents,
    draggable,
    IconWrapper,
    NewMetricTile,
    PreviewExample,
    Specifications,
    StButton,
    StHyperlink,
    StInlineSpinner,
    StModal,
    StTooltip,
    SlideControls
  },
  mixins: [isDesktop, updateQuestion],
  props: {
    activeQuestionId: {
      type: Number,
      required: false,
      default: -1
    },
    initialQuestions: {
      default () {
        return []
      },
      type: Array,
      required: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    metricImagePaths: {
      type: Array,
      required: false,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    surveyId: {
      type: Number,
      required: true
    },
    containerType: {
      type: String,
      required: false,
      default: 'Survey'
    },
    folders: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      deleteModal: false,
      QUESTION_DESCRIPTIONS,
      SPECS_TABS,
      surveyName: this.name,
      showDeleteModal: false,
      showQuestions: false
    }
  },
  computed: {
    ...mapState('specifications', ['activeQuestionIdx', 'activeTab']),
    ...mapGetters('specifications', ['activeQuestion']),
    activeMetricId () {
      if (this.activeQuestionIdx === -1) { return null }

      return this.activeQuestion.id
    },
    activeMetricName () {
      return this.activeQuestion.questionType === 'culture_board' ? 'Image Board' : this.activeQuestion.questionType.split('_').join(' ')
    },
    activeMetricDescription () {
      return this.QUESTION_DESCRIPTIONS.filter(list => {
        return list.questionType === this.activeQuestion.questionType
      })[0]
    },
    allQuestionsComplete: function () {
      return this.questions.every(question => {
        return question.complete
      })
    },
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        forceFallback: true
      }
    },
    noQuestions: function () {
      return this.questions.length === 0
    },
    questionIds () {
      return this.questions.map(question => question.id)
    },
    questions: {
      get () {
        return this.$store.state.specifications.questions
      },
      set (newQuestions) {
        this.$store.dispatch('specifications/reindexQuestions', { newQuestions: newQuestions, surveyId: this.surveyId })
      }
    },
    showQuestionsPanel () {
      return this.isDesktop ? true : this.showQuestions
    }
  },
  mounted () {
    let activeQuestionIdx = this.initialQuestions.map(x => x.id).indexOf(this.activeQuestionId)
    if (activeQuestionIdx > -1) { this.changeTab(SPECS_TABS.SPECIFICATIONS) }
    if (!this.isDesktop && this.initialQuestions.length > 0) { this.showQuestions = true }
    this.replaceQuestions(this.initialQuestions)
    this.setActiveQuestionIdx(activeQuestionIdx)
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    ...mapMutations('specifications', ['changeTab', 'setActiveQuestionIdx', 'replaceQuestions', 'resetSlideColors']),
    ...mapActions('specifications', ['deleteQuestionIdx', 'setCurrentTemplate']),
    addQuestion () {
      this.changeTab(SPECS_TABS.CONTENT)
      this.setActiveQuestionIdx(-1)
      this.showQuestions = false
    },
    handleBackLink () {
      if (!this.isDesktop && !this.showQuestions && this.questions.length > 0) {
        this.showQuestions = true
      } else {
        this.rerouteViaBackLink()
      }
    },
    rerouteViaBackLink () {
      window.location =
        this.containerType === 'Survey'
          ? `/surveys/${this.surveyId}/edit`
          : `/templates`
    },
    deleteActiveQuestion () {
      this.deleteQuestionIdx({ questionIdx: this.activeQuestionIdx, surveyId: this.surveyId })
        .then(() => {
          this.showDeleteModal = false
          let questionType = this.activeQuestion.questionType
          let state = { 'survey_id': this.surveyId, 'question_type': questionType }
          let title = this.activeQuestion.questionType
          let url = '?question=' + this.activeQuestion.id + '&type=' + questionType

          window.history.replaceState(state, title, url)
        })
        .catch((err) => console.log(err))
    },
    fakeSaveProgress () {
      // Real save done when modifying content
      this.$store.commit('toggleInlineSpinner', true)
      setTimeout(() => { this.$store.commit('toggleInlineSpinner', false) }, 2000)
    },
    fakeSaveToHub () {
      // Real save done when modifying content
      this.$store.commit('toggleFullSpinner', true)
      this.$nextTick(() => { this.rerouteViaBackLink() })
    },
    handleEndDraggable (ev) {
      this.setActive(ev.newIndex)
    },
    setActive (idx) {
      this.changeTab(SPECS_TABS.SPECIFICATIONS)
      this.setActiveQuestionIdx(idx)
      this.showQuestions = false

      let state = { 'survey_id': this.surveyId, 'question_type': this.questions[idx].questionType }
      let title = this.questions[idx].questionType
      let url = '?question=' + this.questions[idx].id + '&type=' + this.questions[idx].questionType

      window.history.replaceState(state, title, url)
    },
    showSpecifications (boolean) {
      if (boolean) { this.changeTab(SPECS_TABS.SPECIFICATIONS) }
    },
    changeTemplate (template) {
      const confirm = window.confirm('Some changes will be lost')
      if (confirm) {
        this.setCurrentTemplate(template)
        this.updateQuestion('slide_template_key', template)
        setTimeout(() => {
          this.updateQuestion('slide_colors', [])
        }, 1000)
        this.resetSlideColors()
      }
    }
  }
}
</script>
<style  scoped>
.on-top{
  z-index:999;
}
</style>