<script>
// Intended to be used within a question specifications component

export default {
  methods: {
    uploadOptionImage (ev) {
      let url = '/question_options/' + this.options[this.activeIdx].optionId
      let imageData = new FormData()
      imageData.append('question_option[image]', ev[0].file)
      this.$axios.request({
        url: url,
        method: 'patch',
        data: imageData
      })
        .then(res => {
          this.$store.commit('specifications/replaceActiveQuestion', res.data.question)
        }).catch(err => { console.log(err) })
    }
  }
}
</script>
