var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-favorite-choice__content--specs': _vm.isSpecificationsPreview,
  }},[_c('div',{ref:"questionContainer",staticClass:"st-participant__question-container st-participant__question-container--favorite-choice"},[_c('p',{ref:"question",staticClass:"st-participant__question-container-para"},[_vm._v("\n      "+_vm._s(_vm.activeQuestion.description)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"st-favorite-choice__option-canvas"},_vm._l((_vm.activeQuestion.options),function(option,index){return _c('div',{key:option.id,class:{
        'st-favorite-choice__option': true,
        'st-favorite-choice__option--active': _vm.activeOption === option.id,
        'st-favorite-choice__option--last': index == _vm.activeQuestion.options.length - 1,
        'st-favorite-choice__option--last-specs': index == _vm.activeQuestion.options.length - 1 && _vm.isSpecificationsPreview,
      },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.selectOption(option)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectOption(option)}}},[_c('p',{class:{
          'st-favorite-choice__option-text st-favorite-choice__option-text--ordinal': true,
          'st-favorite-choice__option-text--active': _vm.activeOption === option.id,
          'st-favorite-choice__option-text--active': _vm.activeOption === option.id,
          'st-favorite-choice__option-text--ordinal-specs': _vm.isSpecificationsPreview
        }},[_vm._v("\n        "+_vm._s(_vm.ordinalLetter(index))+")\n      ")]),_vm._v(" "),_c('p',{class:{
          'st-favorite-choice__option-text': true,
          'st-favorite-choice__option-text--active': _vm.activeOption === option.id,
          'st-favorite-choice__option-text--specs': _vm.isSpecificationsPreview
        }},[_vm._v("\n        "+_vm._s(option.name)+"\n      ")])])}),0),_vm._v(" "),_c('st-tooltip',{class:{
      'st-favorite-choice__submit-button-tooltip-survey': !_vm.isSpecificationsPreview,
      'st-favorite-choice__submit-button-tooltip-survey--active': !_vm.isSpecificationsPreview,
      'st-favorite-choice__submit-button-tooltip-specs': _vm.isSpecificationsPreview && !_vm.activeOption,
      'st-favorite-choice__submit-button-tooltip-specs--active': _vm.isSpecificationsPreview
    },attrs:{"tip":"Preview only button","is-active":_vm.isPreview && !_vm.isSurveyPreview}},[_c('st-button',{staticClass:"st-favorite-choice__submit-button",attrs:{"caption":_vm.buttonCaption,"disabled":!_vm.activeOption,"icon-name":"arrow","underline":false,"variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }