<template>
  <div class="icon_movemedia">
    <div class="icon_movemedia__svg">
      <svg
        viewBox="0 0 16.001 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h1v15.838H0z" />
        <path
          d="M0 0h1v8H0z"
          transform="translate(13)"
        />
        <path
          d="M0 0h1v7H0z"
          transform="translate(11 2)"
        />
        <path
          d="M0 0h1v7H0z"
          transform="translate(2 2)"
        />
        <path
          d="M0 0h13.95v1H0z"
          transform="translate(.001)"
        />
        <path
          d="M0 0h10v1H0z"
          transform="translate(2.002 2)"
        />
        <path
          d="M0 0h10v1H0z"
          transform="translate(2.002 8)"
        />
        <path
          d="M0 0h10v1H0z"
          transform="translate(.001 15)"
        />
        <path
          d="M4.1.8L1.6 2.9 4.1 5l-.6.8L0 2.9 3.5 0z"
          transform="rotate(180 8 7.899)"
        />
        <path
          d="M10 1H0V0h10z"
          transform="rotate(180 7.5 6.699)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
