
<template>
  <div>
    <st-accordion
      v-for="(demo, index) in demographics"
      :key="demo.component"
      :class="{ 'st-request-accordion__wrapper--active': demo.display }"
      :mobile-bottom-lock="true"
    >
      <template v-slot:visible-section="">
        <div class="st-request-accordion__demo-and-selected">
          <div class="st-request-accordion__icon-and-demo">
            <icon-wrapper
              :class="{
                'st-request-accordion__icon': true,
                'st-request-accordion__icon--active': demo.display
              }"
              :icon-name="ICON_NAMES[index]"
            />
            <p class="st-request-accordion__caption">
              {{ demo.component }}
            </p>
          </div>
          <p class="st-request-accordion__display">
            {{ demo.display }}
          </p>
        </div>
      </template>
      <template v-slot:expanded-section="{ toggleExpanded }">
        <div class="st-request-accordion-collapse">
          <div
            class="st-request-accordion-collapse__head-wrapper"
            @click="toggleExpanded"
          >
            <div class="st-request-accordion-collapse__head">
              <icon-wrapper
                :icon-name="ICON_NAMES[index]"
                class="st-request-accordion-collapse__title-icon"
              />
              <h6 class="st-request-accordion-collapse__title">
                {{ demo.component }}
              </h6>
              <div
                v-if="demo.display"
                class="st-request-accordion-collapse__title-value-wrapper"
              >
                <span class="st-request-accordion-collapse__title-value">{{ demo.display }}</span>
              </div>
            </div>
            <icon-wrapper
              icon-name="plus"
              class="st-request-accordion-collapse__close"
            />
          </div>
          <component
            :is="demo.component"
          />
        </div>
      </template>
    </st-accordion>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { IconWrapper, StAccordion, StButton } from '../../../common'
import { ICON_NAMES } from './iconConstants'
import demographics from './demographics'
import * as Sections from './sections'

export default {
  components: {
    IconWrapper,
    ...Sections,
    StAccordion,
    StButton
  },
  mixins: [demographics],
  props: {
  },
  data: function () {
    return {
      ICON_NAMES
    }
  },
  computed: {
    ...mapState('requestAudience', ['ageHigh', 'ageLow', 'financialHigh', 'financialLow', 'locationCity', 'locationState']),
    ...mapGetters('requestAudience', ['isAgePopulated', 'isFinancialPopulated', 'isLocationPopulated']),
    ageFormatDisplay () {
      return this.isAgePopulated ? `${this.ageLow}yo - ${this.ageHighDisplay}` : ''
    },
    ageHighDisplay () {
      return this.ageHigh === 99 ? `${this.ageHigh}yo+` : `${this.ageHigh}yo`
    },
    financialFormatDisplay () {
      if (!this.isFinancialPopulated) { return '' }

      let max = this.financialHigh === 200 ? '200K+' : `${this.financialHigh}K`
      return `${this.financialLow}K - ${max}`
    },
    locationFormatDisplay () {
      return this.isLocationPopulated ? `${this.locationCity}, ${this.locationState}` : ''
    }
  },
  methods: {
  }
}
</script>
