<template>
  <div>
    <virtual-tour-transcripts v-if="activeQuestionView === 'Transcripts'" />
    <virtual-tour-results v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VirtualTourResults from './virtual-tour-results'
import VirtualTourTranscripts from './virtual-tour-transcripts'

export default {
  components: {
    'virtual-tour-results': VirtualTourResults,
    'virtual-tour-transcripts': VirtualTourTranscripts
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionView'])
  }
}
</script>
