var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEmpty)?_c('div',{staticClass:"st-overflow-menu__empty-spacer"}):_c('div',{on:{"focusout":_vm.checkFocusOut}},[(_vm.open)?_c('div',{staticClass:"st-overflow-menu__disable-screen",on:{"click":function($event){$event.stopPropagation();return _vm.doToggle.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cv-overflow-menu bx--overflow-menu st-overflow-menu__overflow-icon",class:{ 'bx--overflow-menu--open': _vm.open },attrs:{"data-overflow-menu":"","tabindex":"0","aria-label":_vm.label,"aria-role":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.doToggle.apply(null, arguments)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.doToggle.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.doToggle.apply(null, arguments)}}},[_vm._t("open-menu-element",function(){return [_c('icon-wrapper',{class:{
            'st-overflow-menu__icon-container': true,
            'st-overflow-menu__icon--horizontal': _vm.horizontal
          },attrs:{"icon-name":"overflow"}})]}),_vm._v(" "),_c('ul',{ref:"popup",staticClass:"bx--overflow-menu-options",class:{
          'bx--overflow-menu--flip': _vm.flipMenu,
          'bx--overflow-menu-options--open': _vm.open,
          'bx--overflow-menu-options--open-left': _vm.open && _vm.flipMenu,
          'st-overflow-menu--open-horizontal': _vm.open && _vm.horizontal,
          'st-overflow-menu--open-header': _vm.open && _vm.verticalPosition == 'top',
        },style:(_vm.menuStyle),attrs:{"id":_vm.stUid,"tabindex":"-1"}},[(_vm.title.length>0)?_c('li',{staticClass:"st-overflow-menu__mobile-menu-title"},[_c('div',{staticClass:"st-overflow-menu__mobile-menu-drag-bar"}),_vm._v(" "),_c('div',{staticClass:"st-overflow-menu__mobile-menu-grid"},[_c('div',[(_vm.iconName.length > 0)?_c('icon-wrapper',{staticClass:"st-overflow-menu__icon",attrs:{"icon-name":_vm.iconName}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"st-overflow-menu__mobile-menu-title-text"},[_vm._v("\n              "+_vm._s(_vm.title)+"\n            ")])])]):_vm._e(),_vm._v(" "),_vm._t("default",null,{"close":function () { return _vm.doToggle; }})],2)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }