import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import browserDetect from 'vue-browser-detect-plugin'

import AOS from 'aos'

// carbon components //
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import CvIcon from '@carbon/vue/src/components/cv-icon/cv-icon'
// import CvLoading from '@carbon/vue/src/components/cv-loading/cv-loading'
import CvTextInput from '@carbon/vue/src/components/cv-text-input/cv-text-input'
import CvTile from '@carbon/vue/src/components/cv-tile/cv-tile'
import CvTooltip from '@carbon/vue/src/components/cv-tooltip/cv-tooltip'
// import CvToastNotification from '@carbon/vue/src/components/cv-toast-notification/cv-toast-notification'
import CvOverflowMenuItem from '@carbon/vue/src/components/cv-overflow-menu/cv-overflow-menu-item'
import "vue-swatches/dist/vue-swatches.css"
// New Components

import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInput,
  StSpinner,
  StToastNotification
} from '../components/common/'

import StSidebar from '../components/layout/st-sidebar'

// New Pages
import * as AudiencesPages from '../components/pages/audiences'
import * as HelpsPages from '../components/pages/helps'
import * as InsightsPages from '../components/pages/insights'
import * as LibrariesPages from '../components/pages/libaries'
import * as OnboardingPages from '../components/pages/onboarding'
import * as Participants from '../components/pages/participants'
import * as ProfilePages from '../components/pages/profile'
import * as ProjectsPages from '../components/pages/projects'
import * as ProspectivePages from '../components/pages/prospectives'
import * as SurveysPages from '../components/pages/surveys'
import * as TeamsPages from '../components/pages/teams'

import About from '../components/pages/about.vue'
import Home from '../components/pages/home.vue'
import Welcome from '../components/pages/welcome.vue'



// utilities
import storeFactory from '../store'
import initializeVuePrototype from '../utilities/vue_prototype_initializer'
import { axiosInstance } from '../utilities/request_utilities'
import VueQuillEditor from 'vue-quill-editor'

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(Vuex)
  Vue.use(VueQuillEditor)
  Vue.use(browserDetect)
  Vue.prototype.$goBack = (ev) => {
    ev.preventDefault()
    window.history.back()
  }

  const el = document.getElementById('vue-wrapper')
  const directUploadUrl = el ? el.dataset.directUploadUrl : null

  const app = new Vue({
    store: storeFactory(),
    el: '#vue-wrapper',
    components: {
      'cv-button': CvButton,
      'cv-icon': CvIcon,
      // 'cv-loading': CvLoading,
      'cv-overflow-menu-item': CvOverflowMenuItem,
      'cv-text-input': CvTextInput,
      'cv-tile': CvTile,
      // 'cv-toast-notification': CvToastNotification,
      'cv-tooltip': CvTooltip,

      // New Components
      IconWrapper,
      StButton,
      StHyperlink,
      StInput,
      StSidebar,
      StSpinner,
      StToastNotification,
      ...AudiencesPages,
      ...HelpsPages,
      ...InsightsPages,
      ...LibrariesPages,
      ...OnboardingPages,
      ...Participants,
      ...ProfilePages,
      ...ProjectsPages,
      ...ProspectivePages,
      ...SurveysPages,
      ...TeamsPages,

      // uncategorized pages
      'about': About,
      'home': Home,
      'welcome': Welcome
    },
    data: {
      hideNotifications: false,
      tmp: ''
    },
    computed: {
      ...Vuex.mapState({
        loadingOverlay: state => state.isFullSpinnerVisible
      })
    },
    mounted () {
      window.vue = this
      this.$store.commit('setCustomAxios', axiosInstance())
      this.$store.commit('addDirectUploadUrl', directUploadUrl)
      this.getBrowserType()
      this.getDeviceType()
      AOS.init()
    },
    methods: {
      signOut (path) {
        // could generalize this if we always knew where we should go
        // we could also use the rails_ujs strategy of inserting a form and submitting it
        this.$store.commit('toggleFullSpinner', true)
        Vue.prototype.$axios.request({
          url: path,
          method: 'DELETE'
        })
          .then(() => {
            window.location = '/'
          })
          .catch(error => {
            console.log('error', error)
          })
      },
      closeNotifications () {
        this.hideNotifications = true
      },
      closeToast () {
        this.$store.commit('closeToast')
      },
      getBrowserType () {
        let browser = Object.keys(this.$browserDetect).filter(key => { return this.$browserDetect[key] })[0]

        if (this.$browserDetect.meta.ua.includes('Safari')) {
          this.$store.commit('setBrowserType', 'isSafari')
        } else {
          this.$store.commit('setBrowserType', browser)
        }
      },
      getDeviceType () {
        let deviceType = ''
        if (this.$browserDetect.meta.ua.includes('iPhone')) { deviceType = 'iOS' }
        this.$store.commit('setDeviceType', deviceType)
      },
      refreshAOS () {
        AOS.refresh()
      },
      scrollIntoView ({ block, id, element }) {
        element = element || document.getElementById(id)
        if (element) {
          element.scrollIntoView({
            block: block,
            behavior: 'smooth'
          })
        }
      }
    }
  })

  initializeVuePrototype(app)
})
