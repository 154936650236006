<template>
  <div class="st-image-board__option">
    <div
      v-for="(option, index) in optionsWithVotesAndPercents"
      :key="option.id"
      class="st-image-board__option-row"
    >
      <div class="st-image-board__option-row__container">
        <div class="st-image-board__option-row__container-image">
          <img
            :src="option.mediaUrl"
          >
        </div>

        <div class="st-image-board__option-row__container-option">
          <h3 class="st-image-board__option-row__container-option-name">
            {{ option.name?option.name:option.filename }}
          </h3>
          <div class="bx--row st-image-board__option-row__container-option-row">
            <div class="bx--col-xl-9 bx--col-lg-8 bx--col-sm-7 bx-col-xs-12">
              <div
                :class="[{'st-favorite-choice-insights__bar': true }, barColor(index)]"
                :style="barWidth(option.count)"
              >
                <st-tooltip
                  v-if="option.count > 0"
                  :key="'tip' + index"
                  class="st-favorite-choice-insights__tooltip"
                  arrow-class="st-favorite-choice-insights__tooltip--arrow"
                  message-class="st-favorite-choice-insights__tooltip--message"
                  direction="right"
                  :tip="'test'"
                >
                  <div class="st-favorite-choice-insights__tooltip" />
                  <template slot="tip">
                    <div class="st-favorite-choice-insights__tooltip-contents">
                      <p class="st-favorite-choice-insights__participant-count">
                        {{ optionCount(option.participants.length) }}
                      </p>
                      <div
                        v-for="(participant) in option.participants"
                        :key="'participant' + participant.id"
                        class="st-favorite-choice-insights__participant-avatar-and-name"
                      >
                        <participant-avatar
                          :participant="participant"
                        />
                        <p class="st-favorite-choice-insights__participant-name">
                          {{ participant.fullName }}
                        </p>
                      </div>
                    </div>
                  </template>
                </st-tooltip>
              </div>
            </div>
            <div class="bx--col-xl-3 bx-col-lg-4 bx--col-sm-5 bx-col-xs-12 st-image-board__option-row__bar-and-stat">
              <p
                v-if="unitsOfMeasureIsVotes"
                class="st-favorite-choice-insights__stat"
              >
                {{ optionCount(option.count) }}
              </p>
              <p
                v-else
                class="st-favorite-choice-insights__stat"
              >
                {{ optionPercent(option.percent) }}%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { percent, sum } from '../../../../utilities/data_utilities'
import { StTooltip } from '../../../common'
import ParticipantAvatar from '../participant-avatar'

export default {
  components: {
    ParticipantAvatar,
    StTooltip
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionModifier']),
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingParticipantCheckboxFilter']),
    numberOfVotes () {
      return sum(Object.values(this.numberOfVotesByOptionId))
    },
    numberOfVotesByOptionId () {
      return this.responsesWithNumericalValues.reduce((obj, value) => {
        obj[value.value] = (obj[value.value] || 0) + 1
        return obj
      }, {})
    },
    optionIds () {
      return this.activeQuestion.options.map(option => option.id)
    },
    optionNames () {
      return this.activeQuestion.options.map(option => option.name)
    },
    optionFileNames () {
      return this.activeQuestion.options.map(option => option.media.filename)
    },
    optionMedias () {
      return this.activeQuestion.options.map(option => option.mediaUrl)
    },
    optionsWithVotesAndPercents () {
      return Object.fromEntries(
        this.optionIds.map((name, i) => [i, {
          count: this.numberOfVotesByOptionId[name] || 0,
          mediaUrl: this.optionMedias[i],
          name: this.optionNames[i],
          filename: this.optionFileNames[i],
          participants: this.responsesWithNumericalValues.filter(participant => participant.value === name),
          percent: parseInt(percent(this.numberOfVotesByOptionId[name], this.numberOfVotes, 0))
        }])
      )
    },
    responsesWithNumericalValues () {
      return Object.entries(this.responsesPassingParticipantCheckboxFilter).map(([title, response]) => {
        return { ...response, value: Math.floor(response.value) }
      })
    },
    unitsOfMeasureIsVotes () {
      return this.activeQuestionModifier === 'votes'
    }
  },
  methods: {
    barColor (value) {
      return { [`st-favorite-choice-insights__bar--${value}`]: true }
    },
    barWidth (count) {
      if (this.numberOfVotes === 0) { return { width: 0 + '%' } }
      return { width: (count / this.numberOfVotes) * 100 + '%' }
    },
    optionCount (count) {
      return count === 1 ? `${count} Vote` : `${count} Votes`
    },
    optionPercent (percent) {
      return isNaN(percent) ? 0 : percent
    }
  }
}
</script>
