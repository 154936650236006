<template>
  <div class="icon_addmember">
    <div class="icon_addmember__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 3.5A3.5 3.5 0 113.5 0 3.5 3.5 0 017 3.5zm-1 0A2.5 2.5 0 103.5 6 2.5 2.5 0 006 3.5z"
          transform="translate(2)"
        />
        <path
          d="M0 5.5A5.5 5.5 0 015.5 0a5.668 5.668 0 013.809 1.532c.946.8-1.594 1.975-1.553 3.968s.775 2.159.775 2.159L8.969 9H0zm2.318-3.182A4.483 4.483 0 001 5.5v2.493h7.135S7.1 8.214 7.1 5.358c0-1.275 2.409-2.2 1.429-3.189a4.555 4.555 0 00-6.211.149z"
          transform="translate(0 6)"
        />
        <path
          d="M9 4.5A4.5 4.5 0 114.5 0 4.5 4.5 0 019 4.5zm-1 0A3.5 3.5 0 104.5 8 3.5 3.5 0 008 4.5z"
          transform="translate(7 7)"
        />
        <path
          d="M.5 0v4h-1V0z"
          transform="translate(11.5 9.5)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(9.5 11.5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
