<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['isFullSpinnerVisible'])
  },
  beforeDestroy () {
    this.$store.commit('toggleFullSpinner', false)
  },
  mounted () {
    window.onpageshow = (event) => {
      if (event.persisted) { window.location.reload() }
    }
  },
  methods: {
    advanceToUrl (url) {
      this.$store.commit('toggleFullSpinner', true)
      window.location.href = url
    }
  }
}
</script>
