<script>
export default {
  computed: {
    sortedOptions () {
      let options = this.activeQuestion.options
      return options.sort((a, b) => a.priority - b.priority)
    }
  }
}
</script>
