<template>
  <div>
    <st-toast-notification
      v-if="showToast"
      kind="info"
      title="Info"
      :sub-title="toastMessage"
      @click="showToast = false"
    />
    <div class="bx--grid mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div
        v-if="!survey.published"
        class="mobile-header__button"
      >
        <st-button
          :caption="'Save & Continue'"
          :disabled="buttonDisabled"
          @click="saveAndToHub"
        />
      </div>
    </div>
    <div class="bx--grid">
      <div class="bx--row bx--col-xs-12">
        <div class="bx--col-sm-9">
          <div class="st-edit-survey__back-and-save">
            <st-hyperlink
              :back-link="true"
              text="Back"
              @click="saveAndToHub"
            />
            <div class="st-edit-survey__mobile-save">
              <st-inline-spinner
                v-if="$store.state.isInlineSpinnerVisible"
              />
              <st-hyperlink
                v-if="!survey.published"
                :disabled="isEditingDisabled"
                icon-name="save"
                text="Save"
                @click="saveProgress"
              />
            </div>
          </div>
          <div class="st-edit-survey__header-and-icon">
            <icon-wrapper
              class="st-edit-survey__header-icon"
              icon-name="welcome"
            />
            <h1 class="st-edit-survey__page-header">
              Welcome Page
            </h1>
          </div>
          <p
            class="st-page-instructions st-edit-invitation__instructions-spacing"
          >
            This is the very first screen participants will see before taking the survey.
          </p>
          <div class="bx--offset-lg-2">
            <st-input
              v-model="surveyInfo.headline"
              :disabled="isEditingDisabled"
              label="Heading"
              placeholder="Your title goes here, be brief and welcoming"
            />
            <st-text-area
              v-model="surveyInfo.greeting"
              class="st-edit-invitation__text-area"
              :disabled="isEditingDisabled"
              label="Greeting Text"
              label-size-class="st-edit-invitation__text-area-label"
              placeholder="Give your participants a bit of information about the survey they are about to take"
            />
            <div
              class="st-edit-invitation__upload-area"
            >
              <transition
                name="fade"
                mode="out-in"
              >
                <st-upload-preview
                  v-if="surveyInfo.pageImageUrl"
                  key="image-preview"
                  :disabled="isEditingDisabled"
                  :display-large-preview="true"
                  :filename="coverPhotoFileName"
                  :hide-bar="true"
                  :src="surveyInfo.pageImageUrl"
                  @remove="removeImagePreview"
                />
                <st-hyperlink
                  v-else
                  key="open-image-modal-link"
                  :disabled="isEditingDisabled"
                  icon-class="st-hyperlink__default-icon-spacing"
                  icon-name="upload"
                  text="Upload Cover Photo (Optional)"
                  @click="showCoverPhotoModal = true"
                />
              </transition>
            </div>
          </div>
        </div>
        <div class="bx--col-sm-3 st-edit-invitation__continue-and-preview">
          <div class="st-edit-survey__save-link-and-button">
            <div class="st-edit-survey__inline-spiner-and-save">
              <st-inline-spinner
                v-if="$store.state.isInlineSpinnerVisible"
                class="st-edit-survey__desktop-inline-spinner"
              />
              <st-hyperlink
                v-if="!survey.published"
                :disabled="isEditingDisabled"
                icon-name="save"
                text="Save"
                @click="saveProgress"
              />
            </div>
            <st-button
              v-if="!survey.published"
              :caption="'Save & Continue'"
              :disabled="buttonDisabled"
              @click="saveAndToHub"
            />
          </div>
          <st-hyperlink
            class="st-edit-invitation__preview-link"
            text="Preview"
            icon-name="view-password"
            icon-class="st-hyperlink__default-icon-spacing"
            @click="showPreviewModal = true"
          />
          <!-- :url="`${url}/preview`" -->
        </div>
      </div>
      <st-media-uploader
        v-if="showCoverPhotoModal"
        :aspect-ratio="4.23"
        :folders="folders"
        modal-title="Add Invitation Page Cover Photo"
        @closeMediaUploader="showCoverPhotoModal = false"
        @uploadComplete="uploadCoverPhoto"
      />
      <st-modal
        :show-modal="showPreviewModal"
        @closeModal="showPreviewModal = false"
      >
        <template slot="modalTitle">
          This is a preview
        </template>
        <template slot="additionalInformation">
          <st-participant-invite
            v-if="showPreviewModal"
            :survey="surveyInfo"
            :invitation-preview="true"
            @closeModal="showPreviewModal = false"
          />
        </template>
      </st-modal>
    </div>
  </div>
</template>

<script>
import isEditingDisabled from './isEditingDisabled'
import saveUpdates from './saveUpdates'
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInlineSpinner,
  StInput,
  StMediaUploader,
  StTextArea,
  StToastNotification,
  StUploadPreview,
  StModal
} from '../../common'
import StParticipantInvite from '../participants/participant-invite'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInlineSpinner,
    StInput,
    StMediaUploader,
    StTextArea,
    StToastNotification,
    StUploadPreview,
    StModal,
    StParticipantInvite
  },
  mixins: [isEditingDisabled, saveUpdates],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      showCoverPhotoModal: false,
      surveyInfo: this.survey,
      url: '/surveys/' + this.survey.id,
      showPreviewModal: false
    }
  },
  computed: {
    coverPhotoFileName () {
      return this.surveyInfo.pageImageUrl.split('/').slice(-1).pop()
    },
    buttonDisabled () {
      return this.fieldsEmpty || this.isEditingDisabled
    },
    fieldsEmpty () {
      return !(this.surveyInfo['greeting'] && this.surveyInfo['headline'])
    }
  },
  methods: {
    removeImagePreview () {
      let deleteUrl = `/surveys/${this.survey.id}/`
      let property = 'survey[page_image]'
      this.$axios.request({
        method: 'delete',
        url: deleteUrl,
        data: { property }
      })
        .then(res => { this.surveyInfo.pageImageUrl = null })
        .catch(err => { console.log(err) })
    },
    saveAndPreview () {
      this.$store.commit('toggleFullSpinner', true)
      this.updateSurvey()
        .then(() => { window.location = `/surveys/${this.survey.id}/preview` })
        .catch(() => { window.location = `/surveys/${this.survey.id}/preview` })
    },
    updateSurvey () {
      return this.$axios.request({
        method: 'patch',
        url: this.url,
        data: { survey: {
          headline: this.surveyInfo.headline,
          greeting: this.surveyInfo.greeting
        } }
      })
        .then(res => { console.log(res) })
        .catch(err => { console.log(err) })
    },
    updateAndRoute (nextRoute) {
      this.$store.commit('toggleFullSpinner', true)
      this.updateSurvey()
        .then(res => { console.log('res', res) })
        .catch(err => { console.log('err', err) })
    },
    uploadCoverPhoto (payload) {
      let formData = new FormData()
      formData.append('survey[page_image]', payload)

      this.$axios.request({
        method: 'patch',
        url: this.url,
        data: formData
      })
        .then(res => {
          this.surveyInfo.pageImageUrl = res.data.pageImageUrl
          this.showCoverPhotoModal = false
        }).catch(err => { console.log(err) })
    }
  }
}
</script>
