<template>
  <div class="icon_emoji_hate-it">
    <div class="icon_emoji_hate-it__svg">
      <svg
        viewBox="0 0 26.325 26.325"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M26.325 13.162A13.162 13.162 0 1113.162 0a13.162 13.162 0 0113.163 13.162zm-1 0a12.162 12.162 0 10-12.163 12.163 12.162 12.162 0 0012.163-12.163z" />
        <path
          d="M3.51 2A1.755 1.755 0 010 2z"
          transform="translate(6.142 6.775)"
        />
        <path
          d="M3.51 2A1.755 1.755 0 010 2z"
          transform="translate(16.672 6.775)"
        />
        <path
          d="M.554.688a3.287 3.287 0 003.573 3.036A3.532 3.532 0 007.882.705a4.02 4.02 0 00.1-.715l1 .02a5.142 5.142 0 01-1.868 3.81 5.273 5.273 0 01-5.985-.043A4.916 4.916 0 01-.5-.017l1 .034a3.881 3.881 0 00.054.671z"
          transform="rotate(180 8.678 9.714)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
