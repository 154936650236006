<script>
export default {
  methods: {
    fileNameFromUrl: function (str) {
      if (!str) { return '' }

      let parts = str.split('/')
      return decodeURIComponent(parts[parts.length - 1])
    }
  }
}
</script>
