
export default class PreviewFile {
  // file is expected to be a [js file object](https://developer.mozilla.org/en-US/docs/Web/API/File)
  // but really it just needs to respond to name, type, and be able to be passed to a file reader if src isn't given
  constructor (file, src = null) {
    this.file = file
    this.src = src
  }

  get filename () { return this.file.name }

  get name () { return this.file.name }

  tryReadFile () {
    return this.readFile()
      .then(() => this)
  }

  readFile () {
    return getPreviewUrl(this.file)
      .then(dataUrl => {
        this.src = dataUrl
      })
  }
}

export function getPreviewUrl (file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsDataURL(file)
  })
}

// returns a promise whose 1st param will be a PreviewFile
export function buildNewFilePreview (file) {
  let previewFile = new PreviewFile(file)
  return previewFile.tryReadFile()
}
