<template>
  <div class="icon_add">
    <div class="icon_add__svg">
      <svg
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h15v1H0z"
          transform="translate(0 7)"
        />
        <path
          d="M0 0h1v15H0z"
          transform="translate(7)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
