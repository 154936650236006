<template>
  <div v-if="showModal">
    <div
      ref="stModal"
      class="st-modal__opacitor"
      tabindex="0"
      @click="closeModal"
      @keyup.esc="escape"
    />
    <div
      style="z-index:9999"
      :class="[{
        'st-modal': true,
      }, sizeClass]"
    >
      <slot />
      <div
        v-if="!hideTitleAndClose"
        :class="{
          'st-modal__container--title-and-close': true,
          'st-modal__container--title-and-close--hide-close': hideClose,
        }"
      >
        <span
          class="st-modal__title"
        >
          <slot
            name="modalTitle"
          />
        </span>
        <button
          v-if="!nonExitModal && !hideClose"
          class="st-modal__icon-close--button"
          @click="$emit('closeModal', false)"
        >
          <icon-wrapper
            class="st-modal__icon-close"
            :icon-name="'plus'"
          />
        </button>
      </div>
      <div
        :class="actionsClass"
      >
        <slot
          name="modalActions"
        />
        <div
          v-if="!hideButton"
          :class="{
            'st-modal__container--button': true,
            'st-cancel-modal__row': cancelModal
          }"
        >
          <div
            v-if="cancelModal"
            tabindex="0"
            class="st-cancel-modal__button"
            @click="$emit('closeModal', false)"
            @keyup.enter="$emit('closeModal', false)"
          >
            Cancel
            <icon-wrapper
              icon-name="cancel"
              class="st-cancel-modal__icon"
            />
          </div>
          <slot
            name="modalButton"
          />
        </div>
      </div>
      <slot
        name="additionalInformation"
      />
    </div>
  </div>
</template>

<script>
import IconWrapper from '../common/icon-wrapper'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  model: {
    prop: 'showModal',
    event: 'closeModal'
  },
  props: {
    /** Used to change the styling on the main content section */
    actionsClass: {
      type: String,
      required: false,
      default: ''
    },
    /** Used hide the default button at the bottom of modals */
    hideButton: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used to show "cancel X" at the bottom left of st-modal (in conjunction with a nonExitModal) */
    cancelModal: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Allows for the removal of the "x" at the top right of the modal */
    hideClose: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Allows for the removal of the title and the "x" at the top right of the modal */
    hideTitleAndClose: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Change the size of the modal (default, large). */
    sizeClass: {
      type: String,
      required: false,
      default: 'st-modal--default'
    },
    /** Determines if the modals is displayed. */
    value: Boolean,
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    nonExitModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
    }
  },
  watch: {
    showModal (newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.stModal.focus()
        })
      }
    }
  },
  methods: {
    closeModal () {
      if (!this.nonExitModal) {
        this.$emit('closeModal', false)
      }
    },
    escape (event) {
      this.$emit('closeModal', false)
    }
  }
}
</script>
