<script>
// Intended to be used for media uploading component

export default {
  methods: {
    uploadComplete (...args) {
      console.log('uploadComplete')
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        url: `/questions/${this.activeQuestion.id}`,
        method: 'get'
      })
        .then((res) => {
          console.log(res)
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          this.replaceActiveQuestion(res.data)
        })
        .catch(err => {
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          console.log('bad', err)
        })
    }
  }
}
</script>
