<template>
  <div class="icon_signout">
    <div class="icon_signout__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 3A3.5 3.5 0 113-.5 3.5 3.5 0 016.5 3zm-1 0A2.5 2.5 0 103 5.5 2.5 2.5 0 005.5 3z"
          transform="translate(8.165 .5)"
        />
        <path
          d="M4.1.8L1.6 2.9 4.1 5l-.6.8L0 2.9 3.5 0z"
          transform="translate(0 9.108)"
        />
        <path
          d="M10 1H0V0h10z"
          transform="translate(1.001 11.508)"
        />
        <path
          d="M-23465.98-200.01h7.986l-.02-4.375a3.488 3.488 0 00-1.41-2.648 4.056 4.056 0 00-3.961-.676 3.634 3.634 0 00-2.494 2.755l-1-.051c.1-1.843 2.207-3.975 4.988-3.951a4.651 4.651 0 014.877 4.554l.02 5.393h-9.986v-2.146h1z"
          transform="translate(23472.994 215.01)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
