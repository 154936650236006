var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"culture-board"},[_c('div',{staticClass:"culture-board__tiles"},_vm._l((_vm.options),function(image){return _c('div',{key:image.optionId,class:{
        'culture-board__tile': true,
        'culture-board__tile--2-col': _vm.options.length < 6,
        'culture-board__tile--3-col': _vm.options.length > 5,
        'is-selected': _vm.selectedId == image.optionId
      },style:({
        background: _vm.backgroundProp(image.imageUrl),
        backgroundSize: 'cover'
      }),on:{"click":function($event){return _vm.handleSelect(image.optionId)},"touch":function($event){return _vm.handleSelect(image.optionId)}}})}),0),_vm._v(" "),_c('div',{staticClass:"drag-container__submit"},[(_vm.isSurveyPreview && _vm.priority > 0)?_c('cv-button',{staticClass:"st-question__preview-back-btn",attrs:{"kind":"secondary"},on:{"click":_vm.handlePreviewBack}},[_c('div',{staticClass:"st-page__btn-slot"},[_c('div',{staticClass:"st-svg__left-chevron"}),_vm._v(" "),_c('div',[_vm._v("Back")])])]):_vm._e(),_vm._v(" "),(_vm.selectedId != null || _vm.isSurveyPreview)?_c('cv-button',{staticClass:"next-btn",on:{"click":_vm.submitQuestionAnswer}},[_c('div',[_vm._v("\n        Next\n        "),_c('div',{staticClass:"btn-icon"})])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }