<template>
  <tr
    class="st-data-table__row"
  >
    <td
      class="st-data-table__row-checkbox-cell"
      @mouseenter="hover=true"
      @mouseleave="hover=false"
    >
      <div class="st-data-table__row-checkbox-column">
        <st-checkbox
          v-if="hover || showAllChecks"
          class="st-data-table__row-checkbox"
          :checked="checked"
          @input="onCheckboxInput"
        />
        <participant-avatar
          v-else
          class="st-data-table__avatar"
          :participant="rowData"
        />
      </div>
    </td>
    <td
      v-for="(key, n) in cellKeys"
      :key="key"
      class="st-data-table__item"
    >
      <!-- @slot one slot is built for each entry in cellKeys. first entry in cellKeys will be `cell-1`
        (avatar is first column)
      -->
      <slot :name="`cell-${n + 1}`">
        {{ rowData[key] }}
      </slot>
    </td>
  </tr>
</template>

<script>
import StCheckbox from '../common/st-checkbox'
import ParticipantAvatar from '../pages/insights/participant-avatar'

export default {
  components: {
    'st-checkbox': StCheckbox,
    'participant-avatar': ParticipantAvatar
  },
  props: {
    /** object from which to pull data for this row - assumed to include `selected` */
    rowData: {
      type: Object,
      required: true
    },
    /**
     * url to use for avatar when check is hidden
     * may be replaced by a slot
     */
    avatarUrl: {
      type: String,
      required: false,
      default: ''
    },
    /** ordered keys to pull data for columns */
    cellKeys: {
      type: Array,
      required: true
    },
    /** whether all rows should show their checks */
    showAllChecks: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: function () {
    return {
      hover: false
    }
  },
  computed: {
    checked () {
      return this.rowData.selected
    },
    cellCount () {
      return Object.keys(this.cells).length
    }
  },
  methods: {
    onCheckboxInput (val) {
      /** emits with new check value (true / false) */
      this.$emit('input', val)
    }
  }
}
</script>
