<template>
  <div class="icon_segment">
    <div class="icon_segment__svg">
      <svg
        viewBox="0 0 16 16.003"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M7 2h.5v7h6.542l-.048.544A7.006 7.006 0 117 2zM2.752 4.754A6.006 6.006 0 1012.923 10H6.5V3.02a5.968 5.968 0 00-3.748 1.734z"
            transform="translate(.008)"
          />
          <path d="M9 0a6.987 6.987 0 017 7v1H8.5V0zm6 7a5.984 5.984 0 00-5.5-5.98V7z" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
