<template>
  <div class="icon_cancel">
    <div class="icon_cancel__svg">
      <svg
        viewBox="0 0 16.001 16.001"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 0h1.078v21.551H0z"
            transform="rotate(-45 .92 .381)"
          />
          <path
            d="M0 21.551h1.078V0H0z"
            transform="rotate(-135 3.695 7.843)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
