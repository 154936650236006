<template>

	<table class="qp-table">
		<thead>
			<tr>
				<th
					v-for="(header, index) in headers"
					:key="index"
				>
					<div class="qp-category">
						{{ header }}
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="(row, rowIndex) in rows"
				:key="`ROW_${rowIndex}`"
			>
				<td>
					<div class="qp-category">{{ row.name }}</div>
				</td>
				<td>
					<quadrant-placement-data-cell
						:votes="row.c1"
						:total="row.total"
						:color="colors[row.index]"
					/>
				</td>
				<td>
					<quadrant-placement-data-cell
						:votes="row.c2"
						:total="row.total"
						:color="colors[row.index]"
					/>
				</td>
				<td>
					<quadrant-placement-data-cell
						:votes="row.c3"
						:total="row.total"
						:color="colors[row.index]"
					/>
				</td>
				<td>
					<quadrant-placement-data-cell
						:votes="row.c4"
						:total="row.total"
						:color="colors[row.index]"
					/>
				</td>
			</tr>
		</tbody>
	</table>

</template>

<script>
import QuadrantPlacementDataCell from './quadrant-placement-data-cell.vue';

export default {
	components: {
		QuadrantPlacementDataCell
	},
	props: {
		minXLabel: {
			type: String,
			required: true,
			default: "B"
		},
		maxXLabel: {
			type: String,
			required: true,
			default: "C"
		},
		minYLabel: {
			type: String,
			required: true,
			default: "D"
		},
		maxYLabel: {
			type: String,
			required: true,
			default: "A"
		},
		options: {
			type: Array,
			required: true,
			default: []
		},
		colors: {
			type: Array,
			required: true,
			default: []
		},
		dots: {
			type: Array,
			required: true,
			default: []
		}
	},
	computed: {
		headers: function() {
			return [
				"Category",
				`${this.maxYLabel}-${this.minXLabel}`,
				`${this.maxYLabel}-${this.maxXLabel}`,
				`${this.minYLabel}-${this.minXLabel}`,
				`${this.minYLabel}-${this.maxXLabel}`
			]
		},
		rows: function() {
			const data = {};
			this.dots.forEach(dot => {
				const { x, y, optionId } = dot;
				if (!data[optionId]) data[optionId] = [0, 0, 0, 0]
				let quadrant = Math.round(x) + Math.round(y) * 2;
				data[optionId][quadrant] += 1;
			});
			return this.options.map(({ name, id }, index) => {
				const [c1, c2, c3, c4] = data[id] ? data[id] : [0, 0, 0, 0];
				const row = { name, index, c1, c2, c3, c4, total: c1 + c2 + c3 + c4 };
				return row;
			});
		}
	}
}
</script>

<style scoped>
.qp-table {
	width: 100%;
}
.qp-table thead, tbody {
	border-color: #cecdcc;
	border-style: solid;
	border-width: 1px;
}
.qp-table td {
	color: #757b7e;
	font-size: 14px;
}
.qp-category {
	padding: 15px 25px;
	margin-top: 5px;
}
</style>