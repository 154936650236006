<template>
  <div class="st-diverging-bar-graph__grid">
    <p
      v-if="title"
      class="st-diverging-bar-graph__title"
    >
      {{ title }}
    </p>
    <div class="st-diverging-bar-graph__key">
      <div
        v-for="name in bucketNames"
        :key="name"
        class="st-diverging-bar-graph__key-color-and-value"
      >
        <div
          :class="[{'st-diverging-bar-graph__key-color': true }, sentimentColor(name)]"
        />
        <p class="st-diverging-bar-graph__key-value">
          {{ name }}
        </p>
      </div>
    </div>
    <div class="st-diverging-bar-graph">
      <div
        ref="barsContainer"
        class="st-diverging-bar-graph__bars-container"
      >
        <div
          class="st-diverging-bar-graph__bars"
          :style="barsContainerStyle"
        >
          <div
            v-for="(bar, index) in activeBucketsWithVotesAndPercents"
            :key="index"
            :class="[{'st-diverging-bar-graph__bar': true }, sentimentColor(bar.name)]"
            :style="barWidth(bar.count)"
          >
            <st-tooltip
              :key="'tip' + index"
              class="st-diverging-bar-graph__tooltip"
              message-class="st-diverging-bar-graph__tooltip-message"
              :max-width="isDesktop ? 411 : 125"
              :tip="bar.tooltip"
            >
              <div
                class="st-diverging-bar-graph__tooltip"
              />
            </st-tooltip>
          </div>
        </div>
      </div>
      <div
        class="st-diverging-bar-graph__space-axis-markers st-diverging-bar-graph__space-axis-markers--dashed"
        :style="dashedGridStyling"
      >
        <div
          v-for="n in numberOfVerticalLines"
          :key="`axis-line-${n}`"
          class="st-diverging-bar-graph__axis-line-dashed"
        />
      </div>
      <div class="st-diverging-bar-graph__axis-container">
        <div class="st-diverging-bar-graph__axis" />
        <div class="st-diverging-bar-graph__space-axis-markers ">
          <div
            v-for="n in numberOfVerticalLines"
            :key="`axis-line-${n}`"
            class="st-diverging-bar-graph__axis-line"
          >
            <div
              v-if="midAxisLabelPosition(n)"
              :class="{
                'st-diverging-bar-graph__axis-label-small': true,
                'st-diverging-bar-graph__axis-label-small--percentage': !unitsOfMeasureIsVotes,
              }"
            >
              {{ midAxisLabel }}
            </div>
          </div>
        </div>
        <div class="st-diverging-bar-graph__axis-labels">
          <div class="st-diverging-bar-graph__axis-label">
            {{ maxAxisLabel }}
          </div>
          <div
            class="st-diverging-bar-graph__axis-label"
            style="padding: 0 30px;"
          >
            {{ centerAxisLabel }}
          </div>
          <div class="st-diverging-bar-graph__axis-label">
            {{ maxAxisLabel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { kebabCase } from 'lodash'
import { isDesktop } from '../../../mixins'
import { bucketCounts, percent, sum } from '../../../utilities/data_utilities'
import { StTooltip } from '../../common'

export default {
  components: {
    StTooltip
  },
  mixins: [isDesktop],
  props: {
    sentimentValues: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      bucketNames: ['Very Negative', 'Negative', 'Neutral', 'Positive', 'Very Positive'],
      dashedHeight: null,
      dashedWidth: null,
      resizeObserver: null
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionModifier', 'activeQuestionView', 'sentiments']),
    activeBucketNames () {
      return Object.keys(this.numberOfResponsesPerBucket).filter((key, value) => {
        return this.numberOfResponsesPerBucket[key] > 0
      }).map(key => { return `${this.bucketNames[key]}` })
    },
    activeBucketsWithVotesAndPercents () {
      return Object.fromEntries(
        this.activeBucketNames.map((name, i) => [name, {
          count: this.reducedNumberOfResponsesPerBucked[i],
          name: name,
          percent: this.percentOfVotes[i],
          tooltip: `${this.categoryBarTooltip[i] + name}`
        }])
      )
    },
    barsContainerStyle () {
      return { left: this.neutralOffsetFraction * 100 + '%' }
    },
    categoryBarTooltip () {
      return this.unitsOfMeasureIsVotes ? this.participantTooltipArray : this.percentTooltipArray
    },
    centerAxisLabel () {
      return this.unitsOfMeasureIsVotes ? '0' : '0%'
    },
    dashedGridStyling () {
      return {
        height: this.dashedHeight + 10 + 'px',
        width: this.dashedWidth + 'px'
      }
    },
    maxAxisLabel () {
      return this.unitsOfMeasureIsVotes ? `${this.numberOfVotes}` : '100%'
    },
    midAxisLabel () {
      return this.unitsOfMeasureIsVotes ? `${(this.numberOfVotes / 2)}` : '50%'
    },
    neutralCenterFraction () {
      let bucketWidths = this.numberOfResponsesPerBucket.map(value => value / this.numberOfVotes)
      let result = bucketWidths[0] + bucketWidths[1]
      result += bucketWidths[2] / 2
      return result / 2
    },
    neutralOffsetFraction () {
      return 0.5 - this.neutralCenterFraction
    },
    numberOfResponsesPerBucket () {
      return bucketCounts(this.sentimentValues)
    },
    numberOfVerticalLines () {
      return this.isDesktop ? 9 : 5
    },
    numberOfVotes () {
      return sum(this.reducedNumberOfResponsesPerBucked)
    },
    participantTooltipArray () {
      return this.reducedNumberOfResponsesPerBucked.map(value => {
        return value === 1 ? `1 participant felt ` : `${value} participants felt `
      })
    },
    percentTooltipArray () {
      return this.percentOfVotes.map(value => {
        return `${value}% felt `
      })
    },
    percentOfVotes () {
      return this.reducedNumberOfResponsesPerBucked.map(value => {
        return percent(value, this.numberOfVotes, 0)
      })
    },
    reducedNumberOfResponsesPerBucked () {
      return this.numberOfResponsesPerBucket.filter(responses => { return responses > 0 })
    },
    unitsOfMeasureIsVotes () {
      return this.activeQuestionModifier === 'votes'
    }
  },
  mounted () {
    this.getBarGridDimensions()
    this.resizeObserver = new ResizeObserver(this.getBarGridDimensions)
    this.resizeObserver.observe(this.$refs.barsContainer)
  },
  beforeDestroy () {
    if (this.resizeObserver) { this.resizeObserver.unobserve(this.$refs.barsContainer) }
  },
  methods: {
    barWidth (count) {
      return { width: (count / this.numberOfVotes) * 100 + '%' }
    },
    getBarGridDimensions () {
      if (this.activeQuestionView === 'Results') {
        this.dashedHeight = this.$refs.barsContainer.clientHeight
        this.dashedWidth = this.$refs.barsContainer.clientWidth
      }
    },
    midAxisLabelPosition (n) {
      return this.isDesktop ? (n === 3 || n === 7) : (n === 2 || n === 4)
    },
    sentimentColor (value) {
      return { [`st-sentiment-badge--${kebabCase(value)}`]: true }
    }
  }
}
</script>
