<template>
  <div>
    <div v-if="hasSingleMedia">
      <video
        v-if="isMediaVideo(media[0])"
        :key="questionId"
        controls
        playsinline
        webkit-playsinline
        class="st-question-media__video"
      >
        <source
          :src="firstMedia.mediaUrl"
          :type="firstMedia.media.contentType"
        >
        <source :src="firstMedia.mediaUrl">
        Your browser does not support this video
      </video>
      <div v-else>
        <div
          v-if="showExpanedPhoto"
          class="st-question-media__image-container st-question-media__image-container--expanded"
        />
        <div
          v-else
          class="st-question-media__image-container"
        >
          <div
            v-if="isExpandPhotoIcon"
            class="st-question-media__expand-icon-container"
            @click="expandPhoto"
          >
            <icon-wrapper
              class="st-question-media__expand-icon"
              icon-name="expand-image"
            />
          </div>
          <img
            :src="firstMedia.mediaUrl"
            :class="{
              'st-question-media__image': true,
              'st-question-media__image--clickable': !isSpecificationsPreview,
            }"
            @click="expandPhoto"
          >
        </div>
      </div>
    </div>
    <div
      v-if="hasMultipleMedia"
      :class="{
        'st-question-media__zoom-icons': true,
        'st-question-media__zoom-icons--sticky-active': isStickyQuestionActive,
        'st-question-media__zoom-icons--desktop-preview': isDesktopSurveyPreview,
        'st-question-media__zoom-icons--preview-maximize': zoomLevel > 100 && isDesktopSurveyPreview,
      }"
    >
      <div
        class="st-question-media__zoom-icon-container"
        @click="handleZoomIn"
      >
        <icon-wrapper
          icon-name="plus"
        />
      </div>
      <div
        :class="{
          'st-question-media__zoom-icon-container st-question-media__zoom-icon-container--minus': true,
          'st-question-media__zoom-icon-container--disabled': zoomLevel === 60,
        }"
        @click="handleZoomOut"
      >
        <icon-wrapper
          icon-name="minus"
        />
      </div>
    </div>
    <div
      v-if="hasMultipleMedia"
      ref="multiMediaImages"
      :class="{
        'st-question-media__multi-media-images': zoomLevel > 100,
        'st-question-media__multi-media-images--survey-preview': isDesktopSurveyPreview && zoomLevel > 100,
        'st-question-media__multi-media-images--specs-preview': isSpecificationsPreview && zoomLevel > 100
      }"
      @click="resetZoomLevel"
    >
      <div
        v-for="(multiMedia, index) in media"
        :key="index"
        ref="multiMedia"
        :class="{
          'st-question-media__multi-media-container': true,
          'st-question-media__multi-media-container--last': index == media.length - 1,
        }"
        :style="mediaStyle"
      >
        <video
          v-if="isMediaVideo(multiMedia)"
          :key="'multiMedia' + index"
          controls
          playsinline
          webkit-playsinline
          class="st-question-media__multi-media"
          @loadeddata="setImageWidth(index)"
        >
          <source
            :src="multiMedia.mediaFullUrl"
          >
          <source :src="multiMedia.mediaFullUrl">
          Your browser does not support this video
        </video>
        <img
          v-else
          ref="multiMediaImage"
          class="st-question-media__multi-media"
          :src="multiMedia.mediaFullUrl"
          @load="setImageWidth(index)"
        >
      </div>
    </div>
    <div v-if="showExpanedPhoto">
      <div
        ref="stModal"
        class="st-modal__opacitor"
        tabindex="0"
        @click="showExpanedPhoto = false"
        @keyup.esc="showExpanedPhoto= false"
      />
      <button
        ref="closeLightboxIcon"
        class="st-question-media-light-box__close-icon-button"
        @click="showExpanedPhoto = false"
      >
        <icon-wrapper
          class="st-question-media-light-box__close-icon"
          :invert="true"
          icon-name="plus"
        />
      </button>
      <img
        ref="lightboxImage"
        :src="firstMedia.mediaFullUrl"
        class="st-question-media-light-box__media"
        @load="positionCloseIcon"
      >
    </div>
  </div>
</template>

<script>
import { isDesktop } from '../../mixins'
import IconWrapper from './icon-wrapper'

function nearestInArray (value, array) {
  return array.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0]
}

export default {
  components: {
    IconWrapper
  },
  mixins: [isDesktop],
  props: {
    isDesktopSurvey: {
      type: Boolean,
      required: false,
      default: false
    },
    isDesktopSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSpecificationsPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    media: {
      type: Array,
      required: true
    },
    questionId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: function () {
    return {
      imageWidth: 0,
      isStickyQuestionActive: false,
      mobileWindowScrollDistance: 0,
      multiMediaImagesScrollDistance: 0,
      showExpanedPhoto: false,
      surveyWrapperScrollDistance: 0,
      zoomLevel: 100
    }
  },
  computed: {
    firstMedia () {
      return this.media[0]
    },
    hasSingleMedia () {
      return this.media.length === 1
    },
    hasMultipleMedia () {
      return this.media.length > 1
    },
    isExpandPhotoIcon () {
      return !this.isSpecificationsPreview
    },
    mediaStyle () {
      if (this.zoomLevel <= 100) {
        return {
          marginLeft: `${100 - this.zoomLevel}%`,
          marginRight: `${100 - this.zoomLevel}%`
        }
      } else if (this.zoomLevel > 100) {
        return {
          width: `${this.imageWidth * (this.zoomLevel / 100)}px`
        }
      } else {
        return {}
      }
    }
  },
  mounted () {
    if (this.hasSingleMedia && !this.isMediaVideo(this.media[0])) {
      let img = document.createElement('img')
      img.src = this.media[0].mediaFullUrl
    }

    if (this.hasMultipleMedia && (this.isDesktopSurveyPreview || this.isDesktopSurvey)) {
      this.$parent.$parent.$el.parentElement.scrollTop = 0
    }

    if (this.hasMultipleMedia && this.isDesktop) { this.$parent.$parent.$el.parentElement.addEventListener('scroll', this.handleWrapperScroll) }
    if (this.hasMultipleMedia && !this.isDesktop) { window.addEventListener('scroll', this.handleMobileScroll) }
  },
  beforeDestroy () {
    if (this.hasMultipleMedia && this.isDesktop) { window.removeEventListener('scroll', this.handleWrapperScroll) }
    if (this.hasMultipleMedia && !this.isDesktop) { window.removeEventListener('scroll', this.handleMobileScroll) }
  },
  methods: {
    isMediaVideo (media) {
      return media.media.contentType.split('/')[0] === 'video'
    },
    positionCloseIcon () {
      let lightBoxImagePosition = this.$refs.lightboxImage.getBoundingClientRect()

      if (this.isDesktop) {
        this.$refs.closeLightboxIcon.style.left = lightBoxImagePosition.right + 10 + 'px'
        this.$refs.closeLightboxIcon.style.top = lightBoxImagePosition.top + 'px'
      } else {
        this.$refs.closeLightboxIcon.style.left = lightBoxImagePosition.right - 8 + 'px'
        this.$refs.closeLightboxIcon.style.top = lightBoxImagePosition.top - 24 + 'px'
      }
    },
    setImageWidth (index) {
      if (index === 0 && this.$refs.multiMedia.length > 0) { this.imageWidth = this.$refs.multiMedia[0].clientWidth }
    },
    handleFixedImagesScroll () {
      this.multiMediaImagesScrollDistance = this.$refs.multiMediaImages.scrollTop
    },
    handleWrapperScroll () {
      this.surveyWrapperScrollDistance = this.$parent.$parent.$el.parentElement.scrollTop
    },
    handleMobileScroll () {
      this.mobileWindowScrollDistance = window.top.scrollY
      window.top.scrollY >= this.$parent.$refs.question.getBoundingClientRect().top
        ? this.isStickyQuestionActive = true
        : this.isStickyQuestionActive = false
    },
    expandPhoto () {
      if (!this.isSpecificationsPreview) { this.showExpanedPhoto = true }
    },
    resetZoomLevel () {
      if (this.isDesktopSurveyPreview) { this.zoomLevel = 100 }
    },
    handleZoomIn () {
      this.zoomLevel += 10
      this.$nextTick(() => {
        this.isDesktopSurvey || this.isDesktopSurveyPreview
          ? this.zoomIn(this.multiMediaImagesScrollDistance, this.surveyWrapperScrollDistance)
          : this.zoomIn(this.multiMediaImagesScrollDistance, this.mobileWindowScrollDistance)
      })
    },
    zoomIn (fixedScrollDistance, parentScrollDistance) {
      let imageOffsets = this.$refs.multiMedia.map(media => { return media.offsetTop })
      if (this.zoomLevel === 110) {
        this.$refs.multiMediaImages.addEventListener('scroll', this.handleFixedImagesScroll)
        this.$refs.multiMediaImages.scrollTop = nearestInArray(parentScrollDistance, imageOffsets)
      } else if (this.zoomLevel > 110) {
        this.$refs.multiMediaImages.scrollTop = nearestInArray(fixedScrollDistance, imageOffsets)
      } else {
        this.$parent.$parent.$el.parentElement.scrollTop = nearestInArray(parentScrollDistance, imageOffsets)
      }
    },
    handleZoomOut () {
      if (this.zoomLevel > 60) {
        this.zoomLevel -= 10
        this.$nextTick(() => {
          this.isDesktopSurvey || this.isDesktopSurveyPreview
            ? this.zoomOut(this.multiMediaImagesScrollDistance, this.surveyWrapperScrollDistance)
            : this.zoomOut(this.multiMediaImagesScrollDistance, this.mobileWindowScrollDistance)
        })
      }
    },
    zoomOut (fixedScrollDistance, parentScrollDistance) {
      let imageOffsets = this.$refs.multiMedia.map(media => { return media.offsetTop })
      if (this.zoomLevel === 100) {
        this.isDesktopSurvey || this.isDesktopSurveyPreview
          ? this.$parent.$parent.$el.parentElement.scrollTop = nearestInArray(fixedScrollDistance, imageOffsets)
          : window.scrollTo({
            top: nearestInArray(fixedScrollDistance, imageOffsets),
            behavior: 'instant'
          })
      } else if (this.zoomLevel < 100) {
        this.$parent.$parent.$el.parentElement.scrollTop = nearestInArray(parentScrollDistance, imageOffsets)
      } else {
        this.$refs.multiMediaImages.scrollTop = nearestInArray(fixedScrollDistance, imageOffsets)
      }
    }
  }
}
</script>
