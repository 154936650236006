<template>
  <div class="st-edit-segments__segment">
    <st-accordion ref="accordion">
      <template
        v-slot:visible-section="slotProps"
      >
        <div
          :class="{
            'st-edit-segments__segment-name': true,
            'st-edit-segments__segment-name--disabled': disabled
          }"
          @mouseenter="hover=true"
          @mouseleave="hover=false"
          @click.stop="toggleSelected"
        >
          <div class="st-edit-segments__segment-name-row">
            <div
              :class="{
                'st-radio-button__input-and-indicator': true,
                'st-edit-segments__checkbox': true,
                'st-radio-button__input-and-indicator--active': selected,
                'st-edit-segments__checkbox--disabled': disabled
              }"
            >
              <div
                v-if="selected"
                :class="{
                  'st-radio-button-indicator st-edit-segments__checkbox--selected': true,
                  'st-edit-segments__checkbox--disabled': disabled
                }"
              >
                <icon-wrapper
                  class="st-radio-button-indicator__icon"
                  icon-name="check"
                  :invert="true"
                />
              </div>
            </div>
            <icon-wrapper
              icon-name="segment"
              class="st-edit-segments__icon"
            />
            <div
              :class="{
                'st-edit-segments__name-closed': !slotProps.expanded,
                'st-edit-segments__name-closed--hover': !slotProps.expanded && hover && editable
              }"
            >
              {{ segment.name }}
            </div>
          </div>
          <div class="st-edit-segments__segment-name-row--icons">
            <template v-if="editable && hover && isDesktop">
              <div @click.stop="handleEditSegment">
                <icon-wrapper
                  icon-name="edit"
                  :class="{
                    'st-edit-segments__hover-icon': true,
                    'st-edit-segments__hover-icon--disabled': disabled
                  }"
                />
              </div>
              <div @click.stop="handleDeleteSegment">
                <icon-wrapper
                  icon-name="trash"
                  :class="{
                    'st-edit-segments__hover-icon': true,
                    'st-edit-segments__hover-icon--disabled': disabled
                  }"
                />
              </div>
            </template>
            <st-overflow-menu
              v-else-if="editable && !isDesktop"
              :key="'overflow-' + segment"
            >
              <st-overflow-menu-item
                class="st-edit-segments__overflow-menu-item"
                text="Edit Segment"
                icon-name="edit"
                @mouseup.native="handleEditSegment"
              />
              <st-overflow-menu-item
                class="st-edit-segments__overflow-menu-item"
                text="Delete Segment"
                icon-name="trash"
                @mouseup.native="handleDeleteSegment"
              />
            </st-overflow-menu>
          </div>
        </div>
      </template>
      <template slot="expanded-section">
        <div class="st-edit-segments__segment-expanded">
          <ul>
            <li
              v-for="option in segment.options"
              :key="option.id"
            >
              {{ option.name }}
            </li>
          </ul>
        </div>
      </template>
    </st-accordion>
  </div>
</template>

<script>
import { isDesktop } from '../../../mixins'
import { IconWrapper, StAccordion, StOverflowMenu, StOverflowMenuItem } from '../../common'

export default {
  components: {
    IconWrapper,
    StAccordion,
    StOverflowMenu,
    StOverflowMenuItem
  },
  mixins: [isDesktop],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    segment: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      hover: false
    }
  },
  methods: {
    handleDeleteSegment () {
      if (this.disabled) { return }
      this.$emit('deleteSegment')
    },
    handleEditSegment () {
      if (this.disabled) { return }
      this.$emit('editSegment')
    },
    toggleSelected () {
      if (this.disabled) { return }
      this.$emit('toggleSelected')
    }
  }
}
</script>
