<template>
  <div
    v-if="responses.length>0"
    class="st-insights__sentiment-room-keywords"
  >
    <st-accordion>
      <template v-slot:visible-section>
        <div class="st-insights__sentiment-room-name-section">
          <div class="st-insights__sentiment-room-name-and-icon">
            <icon-wrapper
              icon-name="virtual-room"
            />
            <div class="st-insights__sentiment-room-name">
              {{ title }}
            </div>
          </div>
          <sentiment-tag
            :sentiment="averageSentiment"
            class="st-virtual-tour-insights__sentiment-room-tag"
          />
        </div>
      </template>
      <template v-slot:expanded-section>
        <diverging-bar-graph
          :key="`room-graph-${identifier}`"
          title="Average Sentiment"
          :sentiment-values="sentimentScores"
          class="st-virtual-tour-insights__room-graph"
        />
        <grouped-sentiments
          :expand-result-option="expandResultOption"
          :identifier="identifier"
          :include-sentence="includeSentence"
          :responses="responses"
          :show-prompts-section="showPromptsSection"
          :transcript-caption="`Participant Transcript`"
          @expand-result="expandResult"
        />
        <sentiment-data-table
          :include-count="true"
          :sentiment-rows="entitySentimentsForTable"
          :total-participants="totalParticipants"
          :expandable="true"
          column-title="Keyword"
        >
          <grouped-table-transcripts
            slot="expanded-row"
            slot-scope="{ row }"
            :entity-name="row.title"
            :expand-result-option="expandResultOption"
            :room-id="identifier"
            :total-participants="totalParticipants"
            :transcripts="entitySentiments[row.title]"
            @expand-result="expandResult"
          />
        </sentiment-data-table>
        <div
          class="st-insights__show-more"
          @click="showAllRows=!showAllRows"
        >
          <template v-if="entitySentimentNames.length > 5">
            <template v-if="showAllRows">
              <div class="st-insights__show-more-text">
                Show Less
              </div>
              <icon-wrapper
                class="st-insights__chevron-up"
                icon-name="chevron"
              />
            </template>
            <template v-else>
              <div class="st-insights__show-more-text">
                See all keywords
              </div>
              <icon-wrapper
                class="st-insights__chevron-down"
                icon-name="chevron"
              />
            </template>
          </template>
        </div>
      </template>
    </st-accordion>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { IconWrapper, StAccordion } from '../../common'
import { average, bucketCounts, bucketIdx } from '../../../utilities/data_utilities'
import { mapFromArr } from '../../../utilities/object_utilities'
import SentimentDataTable from './sentiment-table'
import GroupedTableTranscripts from './grouped-table-transcripts'
import DivergingBarGraph from './diverging-bar-graph'
import GroupedSentiments from './grouped-sentiments'
import SentimentTag from './sentiment-tag'

function detailedEntitySentiment (groupedSentiment, entitySentiment) {
  let detailedSentiment = { ...entitySentiment }
  detailedSentiment.responseId = groupedSentiment.id
  detailedSentiment.bucketIdx = bucketIdx(entitySentiment.sentimentScore)
  let props = ['firstName', 'lastName', 'transcript', 'roomTranscripts', 'sentences', 'surveyResponseId']
  props.forEach(prop => {
    detailedSentiment[prop] = groupedSentiment[prop]
  })
  return detailedSentiment
}

export default {
  components: {
    IconWrapper,
    StAccordion,
    'diverging-bar-graph': DivergingBarGraph,
    'grouped-sentiments': GroupedSentiments,
    'grouped-table-transcripts': GroupedTableTranscripts,
    'sentiment-data-table': SentimentDataTable,
    'sentiment-tag': SentimentTag
  },
  props: {
    responses: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    showPromptsSection: {
      type: Boolean,
      required: false,
      default: false
    },
    totalParticipants: {
      type: Number,
      required: true
    },
    expandResultOption: {
      type: String,
      required: true,
      validator: val => ['openTourMode', 'openTranscript'].includes(val)
    },
    identifier: {
      type: [Number, String],
      required: true
    },
    includeSentence: {
      type: Function,
      require: true,
      default: () => {}
    }
  },
  data: function () {
    return {
      showAllBars: false,
      showAllRows: false
    }
  },
  computed: {
    ...mapGetters('insights', ['activeQuestion', 'responsesByTopic', 'responsesByRoom']),
    allEntities () {
      return this.responses.reduce(
        (entityList, roomResponse) => {
          let detailedSentiments = roomResponse.entitySentiments.map((entityResponse) => {
            return detailedEntitySentiment(roomResponse, entityResponse)
          })
          return entityList.concat(detailedSentiments)
        },
        []
      )
    },
    entitySentiments () {
      return this.allEntities.reduce(
        (entityMap, entitySentiment) => {
          let name = entitySentiment.name
          entityMap[name].push(entitySentiment)
          return entityMap
        },
        mapFromArr(this.allEntities.map(entity => entity.name), () => [])
      )
    },
    entitySentimentNames () {
      return Object.keys(this.entitySentiments).sort()
    },
    entitySentimentScores () {
      return this.entitySentimentNames.reduce((scores, name) => {
        scores[name] = this.scoreForEntitySentiment(this.entitySentiments[name])
        return scores
      }, {})
    },
    entitySentimentBucketCounts () {
      let counts = {}
      this.entitySentimentNames.forEach(name => {
        counts[name] = bucketCounts(this.entitySentimentScores[name])
      })
      return counts
    },
    entitySentimentsForTable () {
      let sentiments = this.entitySentimentNames.map(name => {
        return this.entitySentimentForNameForTable(name)
      })
      sentiments.sort((a, b) => b.averageSentiment - a.averageSentiment)
      return this.showAllRows ? sentiments : sentiments.slice(0, 5)
    },
    averageSentiment () {
      return average(this.sentimentScores)
    },
    sentimentScores () {
      return this.responses.map(r => r.sentimentScore)
    }
  },
  methods: {
    ...mapActions('insights', ['moveVirtualTour']),
    ...mapMutations('insights', ['selectSingleResponse', 'toggleTourMode', 'toggleTourSegments', 'toggleTourTranscripts']),
    scoreForEntitySentiment (entitySentiments) {
      return entitySentiments.map(entitySentiment => entitySentiment.sentimentScore)
    },
    entitySentimentForNameForTable (name) {
      return {
        title: name,
        sentiments: this.entitySentimentScores[name],
        averageSentiment: average(this.entitySentimentScores[name])
      }
    },
    expandResult (response) {
      if (this.expandResultOption === 'openTranscript') {
        this.openFullTranscript(response)
      } else if (this.expandResultOption === 'openTourMode') {
        this.openTourMode(response)
      }
    },
    openTourMode (response) {
      let responseInTopic = this.responsesByTopic[this.identifier].find(r => { return r.id === response.id })
      let responseSentences = responseInTopic.sentences.filter(sentence => {
        if (sentence.topics.has(this.identifier)) { return sentence.panoIdentifiers }
      })
      let responsePano = responseSentences[0].panoIdentifiers.values()
      let responseViewpointIdentifier = responsePano.next().value

      this.toggleTourMode(true)
      this.$store.commit('toggleFullSpinner', true)
      this.selectSingleResponse(response)
      this.toggleTourTranscripts(true)
      this.toggleTourSegments(true)
      this.moveVirtualTour(responseViewpointIdentifier)

      let url = new URL(window.location)
      let questionId = url.searchParams.get('question')
      let title = ''
      let newState = {}
      title = 'tour mode'
      newState.mode = 'tour'
      url = `?question=${questionId}&mode=tour`
      window.history.pushState(newState, title, url)
    },
    openFullTranscript (response) {
      this.$store.dispatch('insights/openSingleTranscript', response)
      // scroll to top
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })

      // add to url
      let state = {
        mode: 'fulltranscript',
        responseId: response.id
      }
      let title = 'entered full transcript mode'
      let url = `?question=${this.activeQuestion.id}&mode=transcript&response=${response.id}`

      window.history.pushState(state, title, url)
    }
  }
}
</script>
