var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{'st-opinion-metric-insights__show-multiple-open': _vm.showAllImages}},[_c('div',{class:{
        'st-opinion-metric-insights__multi-option-grid': _vm.activeQuestion.options.length > 0 || _vm.activeQuestion.externalVideoUrl
      }},[(!_vm.activePanelSection && _vm.activeQuestion.options.length>0)?_c('div',{staticClass:"st-opinion-metric-insights__multi-option-media-wrapper",staticStyle:{"position":"relative"}},[(_vm.isMediaVideo(_vm.activeQuestion.options[0]))?_c('video',{staticClass:"st-opinion-metric-insights__multi-option-media",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.activeQuestion.options[0].mediaUrl,"type":_vm.mediaContentType}}),_vm._v(" "),_c('source',{attrs:{"src":_vm.activeQuestion.options[0].mediaUrl}}),_vm._v("\n          Your browser does not support this video\n        ")]):_c('img',{staticClass:"st-opinion-metric-insights__multi-option-media",attrs:{"alt":"Opinion Metric Media","src":_vm.activeQuestion.options[0].mediaUrl}}),_vm._v(" "),_c('div',{staticClass:"st-opinion-metric-insights__multi-option-open-images",on:{"click":function($event){_vm.showAllImages = true}}},[_vm._v("\n          "+_vm._s(_vm.openMediaText)+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.activeQuestion.externalVideoUrl)?_c('st-external-video',{staticClass:"st-opinion-metric-insights__multi-option-media",attrs:{"url":_vm.activeQuestion.externalVideoUrl}}):_vm._e(),_vm._v(" "),_c('div',{class:{
          'st-opinion-metric-insights-grid st-opinion-metric-insights__block': true,
          'st-opinion-metric-insights-grid__no-media': true
        }},[_c('div',{class:{
            'st-opinion-metric-insights-grid__average-and-bar-graph': true,
            'st-opinion-metric-insights-grid__average-and-bar-graph--no-media': true
          }},[_c('div',{class:[{'st-opinion-metric-insights__overall-sentiment-container': true}, _vm.sentimentColor]},[_c('p',{staticClass:"st-opinion-metric-insights__sentiment st-opinion-metric-insights__sentiment--title"},[_vm._v("\n              Average Answer:\n            ")]),_vm._v(" "),_c('p',{staticClass:"st-opinion-metric-insights__sentiment"},[_vm._v("\n              "+_vm._s(_vm.overallScoreBucket)+"\n            ")])]),_vm._v(" "),_c('diverging-bar-graph',{attrs:{"sentiment-values":_vm.allRespondantsSentiments}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"st-opinion-metric-insights__block"},[_c('p',{staticClass:"st-opinion-metric-insights__block-title st-opinion-metric-insights__block-title--sentiment"},[_vm._v("\n        Sentiment per Participant\n      ")]),_vm._v(" "),_vm._l((_vm.limitSentimentPerParticipant),function(response){return _c('st-accordion',{key:response.id,staticClass:"st-sentiment-per-participant__accordion",attrs:{"custom-icon":true,"visible-icon-class":"st-sentiment-per-participant__accordion--chevron","visible-icon-name":"chevron","visible-row-class":"st-sentiment-per-participant__accordion-visible-row"},scopedSlots:_vm._u([{key:"visible-section",fn:function(slotProps){return [_c('div',{class:{
              'st-sentiment-per-participant__avatar-and-sentiment': true,
              'st-sentiment-per-participant__avatar-and-sentiment--hover': slotProps.hover
            }},[_c('div',{staticClass:"st-sentiment-per-participant__avatar-and-name"},[(slotProps.hover || slotProps.expanded)?_c('icon-wrapper',{class:{
                  'st-sentiment-per-participant__chevron': true,
                  'st-sentiment-per-participant__chevron--expanded': slotProps.expanded,
                },attrs:{"icon-name":"chevron","neutral":!slotProps.expanded}}):_vm._e(),_vm._v(" "),_c('participant-avatar',{staticClass:"st-sentiment-per-participant__avatar",attrs:{"participant":response}}),_vm._v(" "),_c('p',{class:{
                  'st-sentiment-per-participant__name': true,
                  'st-sentiment-per-participant__name--expanded': slotProps.expanded,
                }},[_vm._v("\n                "+_vm._s(response.fullName || 'Anonymous User')+"\n              ")]),_vm._v(" "),_c('icon-wrapper',{class:{
                  'st-sentiment-per-participant__chevron st-sentiment-per-participant__chevron--mobile': true,
                  'st-sentiment-per-participant__chevron--expanded': slotProps.expanded,
                },attrs:{"icon-name":"chevron","neutral":!slotProps.expanded}})],1),_vm._v(" "),_c('sentiment-tag',{staticClass:"st-sentiment-per-participant__sentiment",attrs:{"sentiment":response.sentimentScore}})],1)]}}],null,true)},[_vm._v(" "),_c('template',{slot:"expanded-section"},[_c('p',{key:"keyWordsTitle",staticClass:"st-sentiment-per-participant__keyword-title"},[_vm._v("\n            Keywords\n          ")]),_vm._v(" "),_c('div',{key:"keyWords",staticClass:"st-sentiment-per-participant__keywords-container"},[_vm._l((_vm.orderedKeyWords(response.entitySentiments)),function(keyword){return _c('div',{key:keyword.id,staticClass:"st-sentiment-per-participant__keyword-and-sentiment"},[_c('p',{staticClass:"st-sentiment-per-participant__keyword"},[_vm._v("\n                "+_vm._s(keyword.name)+"\n              ")]),_vm._v(" "),_c('sentiment-tag',{staticClass:"st-sentiment-per-participant__sentiment st-sentiment-per-participant__sentiment--keyword",attrs:{"sentiment":keyword.sentimentScore}})],1)}),_vm._v(" "),_c('st-hyperlink',{staticClass:"st-sentiment-per-participant__view-full-transcript",attrs:{"contents-class":"st-sentiment-per-participant__view-full-transcript--hyperlink","icon-name":"transcript","icon-class":"st-hyperlink__default-icon-spacing","text":"View Full Transcript"},on:{"click":function($event){return _vm.openFullTranscript(response)}}})],2)])],2)}),_vm._v(" "),(_vm.orderedSentimentPerParticipant.length >= 7)?_c('div',{staticClass:"st-survey-insights__show-more",on:{"click":function($event){_vm.showAllParticipants = !_vm.showAllParticipants}}},[_c('p',[_vm._v("\n          "+_vm._s(_vm.showAllParticipants ? 'See fewer participants' : 'See all participants')+"\n        ")]),_vm._v(" "),_c('icon-wrapper',{class:{
            'st-survey-insights__show-more-icon st-survey-insights__show-more-icon--show-all': true,
            'st-survey-insights__show-more-icon--expanded': _vm.showAllParticipants,
          },attrs:{"icon-name":"chevron"}})],1):_vm._e()],2),_vm._v(" "),_c('p',{staticClass:"st-opinion-metric-insights__block-title"},[_vm._v("\n      Most Mentioned Keywords\n    ")]),_vm._v(" "),(_vm.entitySentimentsForTable.length === 0)?_c('p',{staticClass:"st-survey-insights__empty-keywords-message"},[_vm._v("\n      There are currently no \"Most Mentioned Keywords\"\n    ")]):_c('sentiment-table',{attrs:{"include-count":true,"sentiment-rows":_vm.showFullSentimentTable,"total-participants":_vm.responsesPassingParticipantCheckboxFilter.length,"expandable":true,"column-title":"Keyword"},scopedSlots:_vm._u([{key:"expanded-row",fn:function(ref){
          var row = ref.row;
return _c('grouped-table-transcripts',{attrs:{"expand-result-option":"openTranscript","total-participants":_vm.responsesPassingParticipantCheckboxFilter.length,"entity-name":row.title,"transcripts":_vm.entitySentimentsKeywords[row.title].transcripts},on:{"expand-result":_vm.openFullTranscript}})}}])}),_vm._v(" "),(_vm.entitySentimentsForTable.length >= 7)?_c('div',{staticClass:"st-survey-insights__show-more st-survey-insights__show-more--keywords",on:{"click":function($event){_vm.showAllKeywordsTable = !_vm.showAllKeywordsTable}}},[_c('p',[_vm._v("\n        "+_vm._s(_vm.showAllKeywordsTable ? 'See fewer keywords' : 'See all keywords')+"\n      ")]),_vm._v(" "),_c('icon-wrapper',{class:{
          'st-survey-insights__show-more-icon st-survey-insights__show-more-icon--show-all': true,
          'st-survey-insights__show-more-icon--expanded': _vm.showAllKeywordsTable,
        },attrs:{"icon-name":"chevron"}})],1):_vm._e()],1),_vm._v(" "),(_vm.showAllImages)?[_c('div',{staticClass:"st-opinion-metric-insights__images-backdrop"}),_vm._v(" "),_c('div',{staticClass:"st-opinion-metric-insights__images-container",style:(_vm.marginStyle)},[_vm._l((_vm.activeQuestion.options),function(media){return [(_vm.isMediaVideo(media))?_c('video',{key:media.id,staticClass:"st-opinion-metric-insights__image-large",style:(_vm.mediaStyle),attrs:{"controls":""}},[_c('source',{attrs:{"src":media.mediaFullUrl,"type":media.media.contentType}}),_vm._v(" "),_c('source',{attrs:{"src":media.mediaFullUrl}}),_vm._v("\n          Your browser does not support this video\n        ")]):_c('img',{key:media.id,staticClass:"st-opinion-metric-insights__image-large",style:(_vm.mediaStyle),attrs:{"alt":"Opinion Metric Media","src":media.mediaFullUrl}})]})],2),_vm._v(" "),_c('div',{on:{"click":function($event){_vm.showAllImages = false}}},[_c('icon-wrapper',{staticClass:"st-opinion-metric-insights__exit-lightbox",attrs:{"icon-name":"cancel","invert":_vm.zoomLevel<=90}})],1),_vm._v(" "),_c('div',{staticClass:"st-opinion-metric-insights__lightbox-plus-box",on:{"click":_vm.zoomIn}},[_c('icon-wrapper',{attrs:{"icon-name":"plus"}})],1),_vm._v(" "),_c('div',{staticClass:"st-opinion-metric-insights__lightbox-minus-box",on:{"click":_vm.zoomOut}},[_c('icon-wrapper',{attrs:{"icon-name":"minus"}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }