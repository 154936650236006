var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-edit-segments__segment"},[_c('st-accordion',{ref:"accordion",scopedSlots:_vm._u([{key:"visible-section",fn:function(slotProps){return [_c('div',{class:{
          'st-edit-segments__segment-name': true,
          'st-edit-segments__segment-name--disabled': _vm.disabled
        },on:{"mouseenter":function($event){_vm.hover=true},"mouseleave":function($event){_vm.hover=false},"click":function($event){$event.stopPropagation();return _vm.toggleSelected.apply(null, arguments)}}},[_c('div',{staticClass:"st-edit-segments__segment-name-row"},[_c('div',{class:{
              'st-radio-button__input-and-indicator': true,
              'st-edit-segments__checkbox': true,
              'st-radio-button__input-and-indicator--active': _vm.selected,
              'st-edit-segments__checkbox--disabled': _vm.disabled
            }},[(_vm.selected)?_c('div',{class:{
                'st-radio-button-indicator st-edit-segments__checkbox--selected': true,
                'st-edit-segments__checkbox--disabled': _vm.disabled
              }},[_c('icon-wrapper',{staticClass:"st-radio-button-indicator__icon",attrs:{"icon-name":"check","invert":true}})],1):_vm._e()]),_vm._v(" "),_c('icon-wrapper',{staticClass:"st-edit-segments__icon",attrs:{"icon-name":"segment"}}),_vm._v(" "),_c('div',{class:{
              'st-edit-segments__name-closed': !slotProps.expanded,
              'st-edit-segments__name-closed--hover': !slotProps.expanded && _vm.hover && _vm.editable
            }},[_vm._v("\n            "+_vm._s(_vm.segment.name)+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"st-edit-segments__segment-name-row--icons"},[(_vm.editable && _vm.hover && _vm.isDesktop)?[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.handleEditSegment.apply(null, arguments)}}},[_c('icon-wrapper',{class:{
                  'st-edit-segments__hover-icon': true,
                  'st-edit-segments__hover-icon--disabled': _vm.disabled
                },attrs:{"icon-name":"edit"}})],1),_vm._v(" "),_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteSegment.apply(null, arguments)}}},[_c('icon-wrapper',{class:{
                  'st-edit-segments__hover-icon': true,
                  'st-edit-segments__hover-icon--disabled': _vm.disabled
                },attrs:{"icon-name":"trash"}})],1)]:(_vm.editable && !_vm.isDesktop)?_c('st-overflow-menu',{key:'overflow-' + _vm.segment},[_c('st-overflow-menu-item',{staticClass:"st-edit-segments__overflow-menu-item",attrs:{"text":"Edit Segment","icon-name":"edit"},nativeOn:{"mouseup":function($event){return _vm.handleEditSegment.apply(null, arguments)}}}),_vm._v(" "),_c('st-overflow-menu-item',{staticClass:"st-edit-segments__overflow-menu-item",attrs:{"text":"Delete Segment","icon-name":"trash"},nativeOn:{"mouseup":function($event){return _vm.handleDeleteSegment.apply(null, arguments)}}})],1):_vm._e()],2)])]}}])},[_vm._v(" "),_c('template',{slot:"expanded-section"},[_c('div',{staticClass:"st-edit-segments__segment-expanded"},[_c('ul',_vm._l((_vm.segment.options),function(option){return _c('li',{key:option.id},[_vm._v("\n            "+_vm._s(option.name)+"\n          ")])}),0)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }