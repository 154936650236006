<script>
import { mapMutations } from 'vuex'

// Intended to be used within a question specifications component
export default {
  data: function () {
    return {
      deleteModal: false,
      activeOption: null
    }
  },
  computed: {
    objects () {
      return this.activeQuestion.slideQuestionObjects
    },
    highestPriority () {
      if (this.slideQuestionObjects.length === 0) { return 0 }

      return Math.max(...this.slideQuestionObjects.map(x => x.priority))
    }
  },
  methods: {
    ...mapMutations(['toggleInlineSpinner']),
    ...mapMutations('specifications', ['replaceActiveQuestion']),
    updateSlideQuestionObject (id, idx, optionData, skipStoreUpdate = false) {
      this.$axios.request({
        url: '/slide_question_objects/' + id,
        method: 'patch',
        data: optionData
      })
        .then(res => {
          if (!skipStoreUpdate) {
            const question = res.data.question
            question.slideQuestionObjects.sort((a, b) => a.id - b.id)
            this.replaceActiveQuestion(question)
          }
          setTimeout(() => { this.toggleInlineSpinner(false) }, 2450)
        })
        .catch(err => {
          console.log(err)
          setTimeout(() => { this.toggleInlineSpinner(false) }, 2450)
        })
    },
    createNewObjects (count, afterCreationCb = (() => {})) {
      let startPriority = this.highestPriority + 1
      for (let i = 0; i < count; i++) {
        // TODO: these are not guaranteed to return in the order we submit
        this.createNewObject(startPriority + i, afterCreationCb)
      }
    },
    createNewObject (object, priority, afterCreationCb = (() => {})) {
      if (this.isPreview) { return }

      let questionId = this.activeQuestion.id

      this.$axios.request({
        url: `/questions/${questionId}/slide_question_objects`,
        method: 'post',
        data: object
      })
        .then(res => {
          this.handleNewOptionResponse(res, afterCreationCb)
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleNewOptionResponse (res, afterCreationCb = (() => {})) {
      this.replaceActiveQuestion(res.data.question)
      afterCreationCb(res.data)
    },
    deleteSlideObject (id, idx) {
      if (this.isPreview) { return }
      let deleteOptionUrl = '/slide_question_objects/' + id
      this.$axios.request({
        url: deleteOptionUrl,
        method: 'delete'
      })
        .then(res => {
          this.isMounted = true
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
