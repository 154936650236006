<template>
  <div class="icon_preferenceslider">
    <div class="icon_preferenceslider__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h3v1H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(13)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(9 2)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(15 2)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(9 6)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(4 6)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(13 6)"
        />
        <path d="M0 0h3v1H0z" />
        <path
          d="M0 0h3v1H0z"
          transform="translate(4)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(6 2)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(0 6)"
        />
        <path
          d="M0 0h5v1H0z"
          transform="translate(0 13)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(6 11)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(9 11)"
        />
        <path
          d="M0 0h7v1H0z"
          transform="translate(0 13)"
        />
        <path
          d="M0 0h7v1H0z"
          transform="translate(9 13)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 11)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 15)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
