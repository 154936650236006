<template>
  <div class="icon_signup">
    <div class="icon_signup__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 .8l2.5 2.1L0 5.007l.6.8L4.1 2.9.6 0z"
          transform="rotate(-90 7.145 -3.047)"
        />
        <path
          d="M0 1h15V0H0z"
          transform="rotate(-90 14.298 1.702)"
        />
        <path
          d="M6.5 3A3.5 3.5 0 113-.5 3.5 3.5 0 016.5 3zm-1 0A2.5 2.5 0 103 5.5 2.5 2.5 0 005.5 3z"
          transform="translate(2.153 .5)"
        />
        <path
          d="M2.63 3.99A5.084 5.084 0 017.694.026a5.084 5.084 0 015.064 3.964c.151.721.1.72.1 5.984H2.525c0-5.356-.035-5.317.105-5.984zm.87.21c-.12.574-.083.623-.083 4.83h8.546V5A4.126 4.126 0 007.694.974 4.18 4.18 0 003.5 4.2z"
          transform="translate(-2.521 6.024)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
