<template>
  <div
    v-if="showAudioInstructions"
    class="st-opinion-metric__inner-wrapper"
  >
    <microphone-access
      v-if="showToast && errorMessage"
      class="st-opinion-metric__microphone-modal"
      :show-microphone-access="showToast && errorMessage !== ''"
      @closeMicrophoneAccess="showToast = false"
    />
    <instructions
      key="instructions"
      :error-message="errorMessage"
      :is-single-question-survey="false"
      :is-join-survey="isJoinSurvey"
      :has-media-recorder="true"
      :company-logo="activeQuestion.logoUrl"
      :audio-permission-granted="audioPermissionGranted"
      :spacetrics-logo="spacetricsLogo"
      @ask-for-audio-permission="askForAudioPermission"
      @disableInstructions="handleDisableInstructions"
    />
  </div>
  <div
    v-else
    :class="{
      'st-opinion-metric__container-set': true,
      'st-opinion-metric__container':isDesktop,
      'st-opinion-metric__container--preview': isSpecsDesktopPreview,
      'st-opinion-metric__container--multi-media-preview': isSpecsDesktopPreview && hasMultipleMedia
    }"
  >
    <div class="st-opinion-metric__canvas-preview">
      <div class="st-opinion-metric__canvas-page">
        <div
          :class="{
            'st-opinion-metric__question-with-text-area': hasNoMedia,
            'st-opinion-metric__question-with-text-area--focused':!hasSingleMedia && !hasExternalMedia && textAreaFocused && !isDesktop,
            'st-opinion-metric__question-with-text-area--preview': !hasSingleMedia && !hasExternalMedia && isSpecsDesktopPreview,
          }"
        >
          <div
            ref="questionContainer"
            :class="{
              'st-opinion-metric__question-container': true,
              'st-opinion-metric__question-container--hide': textAreaFocused && hasSingleMedia && !isDesktop,
              'st-opinion-metric__question-container--no-media': hasNoMedia,
              'st-opinion-metric__question-container--no-media-survey': hasNoMedia && !isSpecsDesktopPreview,
              'st-opinion-metric__question-container--no-media-specs': hasNoMedia && isSpecsDesktopPreview,
              'st-opinion-metric__question-container--no-media--focus': !isPreview && !hasSingleMedia && textAreaFocused && !hasExternalMedia && !isDesktop,
              'st-opinion-metric__question-container--video': isMediaVideo || activeQuestion.externalVideoUrl,
              'st-opinion-metric__question-container--overflow-description': overflowDescription
            }"
          >
            <p
              v-if="hasMultipleMedia && !isDesktop"
              ref="question"
              v-sticky
              class="st-opinion-metric__question--multi-media"
            >
              {{ description }}
            </p>
            <p
              v-else-if="hasMultipleMedia && isDesktop && isResponseTypeText"
              ref="question"
              class="st-opinion-metric__question--multi-media-text-response"
            >
              {{ description }}
            </p>
            <p
              v-else-if="!hasMultipleMedia || hasMultipleMedia && isSpecsDesktopPreview"
              ref="question"
              class="st-opinion-metric__question"
            >
              {{ description }}
            </p>
          </div>
          <div
            v-if="isResponseTypeText && hasNoMedia"
            :class="{
              'st-opinion-metric__text-area-container': !isDesktopSurveyPreview && !isDesktopSurvey,
              'st-opinion-metric__text-area-container--focused': textAreaFocused,
              'st-opinion-metric__text-area-container--focused-desktop': textAreaFocused && isDesktopSurveyPreview || textAreaFocused && isDesktopSurvey,
              'st-opinion-metric__text-area-container--focused-specs': textAreaFocused && isSpecsDesktopPreview,
              'st-opinion-metric__text-area-container--next-button-visible': !isPreview && nextButtonTextArea,
              'st-opinion-metric__text-area-container--next-button': nextButtonTextArea,
              'st-opinion-metric__text-area-container--specifications-preview': isSpecsDesktopPreview,
              'st-opinion-metric__text-area-container--specifications-preview-next-question': isSpecsDesktopPreview && nextButtonTextArea,
              'st-opinion-metric__text-area-container--desktop': isDesktopSurveyPreview || isDesktopSurvey
            }"
          >
            <st-text-area
              v-model="writtenResponse"
              :class="{
                'st-opinion-metric__text-area': true,
                'st-opinion-metric__text-area--next-button-visible': nextButtonTextArea,
                'st-opinion-metric__text-area--next-button-visible-specs': isPreview && nextButtonTextArea && !isSurveyPreview,
              }"
              :button="textAreaFocused"
              label="Share your thoughts here"
              :label-size-class="nextButtonTextArea && !isPreview || nextButtonTextArea && isSurveyPreview ? 'st-opinion-metric__text-area-label' : 'st-opinion-metric__text-area-label--specifications'"
              placeholder="Tell us what you think"
              @focus="focusTextArea"
              @focusout="focusOutTextArea"
            />
            <st-tooltip
              v-if="nextButtonTextArea"
              class="st-opinion-metric__button-tooltip"
              tip="Preview only button"
              :is-active="isSpecificationsPreview"
            >
              <st-button
                class="st-opinion-metric__button st-opinion-metric__next-question-button"
                :caption="buttonCaption"
                :disabled="!writtenResponse.length"
                icon-name="arrow"
                :underline="false"
                :variant="['secondary']"
                @click="submitQuestionAnswer"
              />
            </st-tooltip>
          </div>
        </div>
        <div
          v-if="hasSingleMedia"
          :class="{
            'st-opinion-metric__image-canvas-box': true,
            'st-opinion-metric__video-container': isMediaVideo,
            'st-opinion-metric__video-container--specifications': isMediaVideo && isSpecsDesktopPreview
          }"
        >
          <video
            v-if="isMediaVideo"
            :key="questionId"
            controls
            playsinline
            webkit-playsinline
            class="st-opinion-metric__video"
          >
            <source
              :src="mediaUrl"
              :type="contentType"
            >
            Your browser does not support this video
          </video>
          <div
            v-else
            class="st-opinion-metric__image-canvas"
          >
            <div
              v-if="showExpanedPhoto"
              class="st-opinion-metric__image-container st-opinion-metric__image-container--expanded"
            />
            <div
              v-else
              :class="{
                'st-opinion-metric__image-container': true,
                'st-opinion-metric__image-container--focused': textAreaFocused && !hasMultipleMedia
              }"
            >
              <div
                v-if="isExpandPhotoIcon"
                ref="expandImageIcon"
                class="st-opinion-metric__expand-icon-container"
                @click="expandPhoto"
              >
                <icon-wrapper
                  class="st-opinion-metric__expand-icon"
                  icon-name="expand-image"
                />
              </div>
              <img
                :src="mediaUrl"
                :class="{
                  'st-opinion-metric__image st-opinion-metric__image-full': true,
                  'st-opinion-metric__image--clickable': !isSpecsDesktopPreview,
                }"
                @click="expandPhoto"
              >
            </div>
          </div>
        </div>
        <div
          v-if="activeQuestion.externalVideoUrl"
          :class="{
            'st-opinion-metric__video-container': activeQuestion.externalVideoUrl,
            'st-opinion-metric__video-container--specifications': activeQuestion.externalVideoUrl && isSpecsDesktopPreview
          }"
        >
          <st-external-video
            :url="activeQuestion.externalVideoUrl"
          />
        </div>
      </div>
      <div
        v-if="hasMultipleMedia"
        :class="{
          'st-opinion-metric__zoom-icons': true,
          'st-opinion-metric__zoom-icons--sticky-active': isStickyQuestionActive,
          'st-opinion-metric__zoom-icons--desktop-preview': isDesktopSurveyPreview,
          'st-opinion-metric__zoom-icons--preview-maximize': zoomLevel > 100 && isDesktopSurveyPreview,
        }"
      >
        <div
          class="st-opinion-metric__zoom-icon-container"
          @click="handleZoomIn"
        >
          <icon-wrapper
            icon-name="plus"
          />
        </div>
        <div
          :class="{
            'st-opinion-metric__zoom-icon-container st-opinion-metric__zoom-icon-container--minus': true,
            'st-opinion-metric__zoom-icon-container--disabled': zoomLevel === 60,
          }"
          @click="handleZoomOut"
        >
          <icon-wrapper
            icon-name="minus"
          />
        </div>
      </div>
      <div
        v-if="hasMultipleMedia"
        ref="multiMediaImages"
        :class="{
          'st-opinion-metric__multi-media-images': zoomLevel > 100,
          'st-opinion-metric__multi-media-images--survey-preview': isDesktopSurveyPreview && zoomLevel > 100,
          'st-opinion-metric__multi-media-images--specs-preview': isSpecsDesktopPreview && zoomLevel > 100
        }"
        @click="resetZoomLevel"
      >
        <div
          v-for="(multiMedia, index) in options"
          :key="index"
          ref="multiMedia"
          :class="{
            'st-opinion-metric__multi-media-container': true,
            'st-opinion-metric__multi-media-container--last': index == options.length - 1,
          }"
          :style="imageStyle"
        >
          <video
            v-if="isMultiMediaVideo(multiMedia)"
            :key="'multiMedia' + index"
            controls
            playsinline
            webkit-playsinline
            class="st-opinion-metric__multi-media"
            @loadeddata="setImageWidth(index)"
          >
            <source
              :src="multiMedia.mediaFullUrl"
            >
            <source :src="multiMedia.mediaFullUrl">
            Your browser does not support this video
          </video>
          <img
            v-else
            ref="multiMediaImage"
            class="st-opinion-metric__multi-media"
            :src="multiMedia.mediaFullUrl"
            @load="setImageWidth(index)"
          >
        </div>
      </div>
      <div v-if="hasResponseType">
        <div
          v-if="isResponseTypeText && hasSingleMedia || isResponseTypeText && hasExternalMedia || isResponseTypeText && hasMultipleMedia"
          :class="{
            'st-opinion-metric__text-area-container': !isDesktopSurveyPreview && !isDesktopSurvey,
            'st-opinion-metric__text-area-container--focused': textAreaFocused,
            'st-opinion-metric__text-area-container--focused-desktop': textAreaFocused && isDesktopSurveyPreview || textAreaFocused && isDesktopSurvey,
            'st-opinion-metric__text-area-container--next-button-visible': !isPreview && nextButtonTextArea,
            'st-opinion-metric__text-area-container--next-button': nextButtonTextArea,
            'st-opinion-metric__text-area-container--multi-media': hasMultipleMedia,
            'st-opinion-metric__text-area-container--multi-media-focused': hasMultipleMedia && textAreaFocused,
            'st-opinion-metric__text-area-container--specifications-preview': isSpecsDesktopPreview,
            'st-opinion-metric__text-area-container--specifications-preview-next-question': isSpecsDesktopPreview && nextButtonTextArea,
            'st-opinion-metric__text-area-container--desktop': isDesktopSurveyPreview || isDesktopSurvey,
            'st-opinion-metric__text-area-container--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__text-area-container--multi-media-desktop-survey': hasMultipleMedia && isDesktop && nextButtonTextArea,
            'st-opinion-metric__text-area-container--multi-media-specs': hasMultipleMedia && isSpecsDesktopPreview
          }"
        >
          <st-text-area
            v-model="writtenResponse"
            :class="{
              'st-opinion-metric__text-area': true,
              'st-opinion-metric__text-area--next-button-visible-specs': isPreview && nextButtonTextArea && !isSurveyPreview,
              'st-opinion-metric__text-area--next-button-visible': nextButtonTextArea,
              'st-opinion-metric__text-area--multi-media': hasMultipleMedia,
              'st-opinion-metric__text-area--multi-media-specs': hasMultipleMedia && isSpecsDesktopPreview,
              'st-opinion-metric__text-area--multi-media-focused': textAreaFocused && hasMultipleMedia,
            }"
            :button="textAreaFocused"
            label="Share your thoughts here"
            :label-size-class="nextButtonTextArea && !isPreview || nextButtonTextArea && isSurveyPreview ? 'st-opinion-metric__text-area-label' : 'st-opinion-metric__text-area-label--specifications'"
            placeholder="Tell us what you think"
            @focus="focusTextArea"
            @focusout="focusOutTextArea"
          />
          <st-tooltip
            v-if="nextButtonTextArea"
            class="st-opinion-metric__button-tooltip"
            tip="Preview only button"
            :is-active="isSpecificationsPreview"
          >
            <st-button
              :class="{
                'st-opinion-metric__button st-opinion-metric__next-question-button': true,
                'st-opinion-metric__next-question-button--multi-media': hasMultipleMedia && !isSpecsDesktopPreview,
              }"
              :caption="buttonCaption"
              :disabled="!writtenResponse.length"
              icon-name="arrow"
              :underline="false"
              :variant="['secondary']"
              @click="submitQuestionAnswer"
            />
          </st-tooltip>
        </div>
        <div
          v-else-if="!isResponseTypeText"
          :class="{
            'st-opinion-metric__voice-response-container': true,
            'st-opinion-metric__voice-response-container--desktop': !hasMultipleMedia && isDesktop,
            'st-opinion-metric__voice-response-container--multi-media-no-description-survey': !description && hasMultipleMedia && isDesktop && !isSpecsDesktopPreview,
            'st-opinion-metric__voice-response-container--multi-media-survey-preview': isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__voice-response-container--multi-media-specs-preview': hasMultipleMedia && isSpecsDesktopPreview,
            'st-opinion-metric__voice-response-container--multi-media-preview-maximize': zoomLevel > 100 && isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__voice-response-container--multi-media-no-description-survey-preview': !description && isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__voice-response-container--multi-media': hasMultipleMedia,
          }"
        >
          <div
            v-if="hasMultipleMedia && isDesktop && !isSpecsDesktopPreview && description"
            ref="questionContainer"
            :class="{
              'st-opinion-metric__full-width-description': true,
              'st-opinion-metric__full-width-description--desktop-preview': isDesktopSurveyPreview,
              'st-opinion-metric__full-width-description--specs-preview': isSpecsDesktopPreview,
            }"
          >
            <p ref="question">
              {{ description }}
            </p>
          </div>
          <st-button
            v-if="isRecordButton"
            :class="{
              'st-opinion-metric__button st-opinion-metric__record-thoughts-button': true,
              'st-opinion-metric__record-thoughts--multi-media': hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description': hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview
            }"
            caption="Record My Thoughts"
            icon-name="microphone"
            :underline="false"
            :variant="['secondary']"
            @click="initiateRecording"
          />
          <div
            v-else-if="isStopButton"
            :class="{
              'st-opinion-metric__stop-button-and-visualizer': true,
              'st-opinion-metric__record-thoughts--multi-media': hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description': hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview
            }"
          >
            <button
              type="button"
              class="st-opinion-metric__audio-button-container"
              @click="stopRecording"
            >
              <div class="st-opinion-metric__stop-square" />
            </button>
            <canvas
              v-if="isVisualizerVisible"
              ref="audioCanvas"
              :width="canvasWidth"
              :height="canvasHeight"
              :class="{
                'st-opinion-metric__audio-visualizer': true,
                'st-opinion-metric__audio-visualizer--preview': isSpecsDesktopPreview
              }"
            />
          </div>
          <audio
            v-if="isAudioPlayer"
            ref="audioPlayer"
            class="st-opinion-metric__audio-player"
            controls
          >
            Your browser does not support the <code>audio</code> element.
          </audio>
          <st-tooltip
            v-if="errorMessage"
            :always-visible="true"
            :max-width="errorTooltipWidth"
            :tip="errorMessage"
            message-class="st-opinion-metric__button-tooltip--error"
            :class="{
              'st-opinion-metric__button st-opinion-metric__record-thoughts-button': true,
              'st-opinion-metric__record-thoughts--multi-media': hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description': hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview
            }"
            :is-active="isSpecificationsPreview"
          >
            <st-button
              :caption="buttonCaption"
              class="st-opinion-metric__button"
              icon-name="arrow"
              :underline="false"
              :variant="['secondary']"
              @click="submitQuestionAnswer"
            />
          </st-tooltip>
          <div
            v-if="readyToSubmit"
            :class="{
              'st-opinion-metric__ready-to-submit': true,
              'st-opinion-metric__record-thoughts--multi-media': hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description': hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview
            }"
          >
            <button
              v-if="readyToSubmit && !replayAudio"
              type="button"
              class="st-opinion-metric__audio-button-container"
              @click="playAgain"
            >
              <icon-wrapper
                class="st-opinion-metric__play-button"
                icon-name="play-button"
                :spacetrics-blue="true"
              />
            </button>
            <button
              v-if="readyToSubmit && replayAudio"
              type="button"
              class="st-opinion-metric__audio-button-container"
              @click="stopReplay"
            >
              <div class="st-opinion-metric__pause-bars">
                &#9612;
              </div>
              <div class="st-opinion-metric__pause-bars st-opinion-metric__pause-bars--right">
                &#9612;
              </div>
            </button>
            <p
              v-if="readyToSubmit"
              class="st-opinion-metric__audio-duration"
            >
              {{ formatAudioDuration }}
            </p>
            <div
              v-if="readyToSubmit"
              class="st-opinion-metric__trash-icon-container"
              @click="rerecord"
            >
              <icon-wrapper
                class="st-opinion-metric__trash-icon"
                icon-name="trash"
              />
            </div>
            <st-tooltip
              v-if="readyToSubmit"
              class="st-opinion-metric__button-tooltip"
              tip="Preview only button"
              :is-active="isSpecificationsPreview"
            >
              <st-button
                class="st-opinion-metric__button"
                :caption="buttonCaption"
                icon-name="arrow"
                :underline="false"
                :variant="['secondary']"
                @click="submitQuestionAnswer"
              />
            </st-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showExpanedPhoto">
      <div
        ref="stModal"
        class="st-modal__opacitor"
        tabindex="0"
        @click="showExpanedPhoto = false"
        @keyup.esc="showExpanedPhoto= false"
      />
      <button
        ref="closeLightboxIcon"
        class="st-opinion-metric-light-box__close-icon-button"
        @click="showExpanedPhoto = false"
      >
        <icon-wrapper
          class="st-opinion-metric-light-box__close-icon"
          :invert="true"
          icon-name="plus"
        />
      </button>
      <img
        ref="lightboxImage"
        :src="lightboxImageSrc"
        class="st-opinion-metric-light-box__media"
        @load="positionCloseIcon"
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { formatSecondsToStopwatch } from '../../../../utilities/data_utilities'
import { getBlobDuration } from '../../../../utilities/audio_utilities'
import { audioRecorder, handlePreviewBack, isDesktop, previousResponse, submitQuestionAnswer } from '../../../../mixins'
import { IconWrapper, StButton, StExternalVideo, StTextArea, StTooltip } from '../../../common'
import Instructions from '../instructions'
import MicrophoneAccess from '../microphone-access'

function nearestInArray (value, array) {
  return array.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0]
}

export default {
  components: {
    IconWrapper,
    MicrophoneAccess,
    StButton,
    StExternalVideo,
    StTextArea,
    StTooltip,
    Instructions
  },
  mixins: [ audioRecorder, handlePreviewBack, isDesktop, previousResponse, submitQuestionAnswer ],
  props: {
    askForExplanation: {
      type: String,
      required: false,
      default: ''
    },
    autoStopTime: {
      type: Number,
      default: 900000, // ms = 15 min
      required: false
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    spacetricsLogo: {
      type: String,
      required: false,
      default: ''
    },
    isJoinSurvey: {
      type: Boolean,
      required: false,
      default: false
    },
    media: {
      type: Object,
      required: false,
      default: () => ({})
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    priority: {
      type: Number,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    showQuestionInstructions: {
      type: Boolean,
      required: false,
      default: false
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      audioBlob: null,
      audioDuration: null,
      errorMessage: '',
      imageWidth: 0,
      lightboxImageSrc: '',
      mobileWindowScrollDistance: 0,
      multiMediaImagesScrollDistance: 0,
      isStickyQuestionActive: false,
      overflowDescription: false,
      readyToSubmit: false, // Change this later
      replayAudio: false,
      resizeObserver: null,
      showExpanedPhoto: false,
      surveyWrapperScrollDistance: 0,
      textAreaFocused: false,
      writtenResponse: '',
      zoomLevel: 100,
      showInstructions: this.showQuestionInstructions,
      audioPermissionGranted: false,
      showToast: false
    }
  },
  computed: {
    ...mapState('participants', { participantActiveQuestion: 'activeQuestion', opinionMetricPhase: 'opinionMetricPhase' }),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    ...mapGetters('participants', ['PHASES']),
    activeQuestion () {
      return this.isSpecsDesktopPreview ? this.specsActiveQuestion : this.participantActiveQuestion
    },
    contentType () {
      if (!this.hasSingleMedia) { return '' }
      if (!this.media.hasOwnProperty('contentType')) { return '' }
      return this.media.contentType
    },
    errorTooltipWidth () {
      return this.isDesktop ? 411 : window.innerWidth - 40
    },
    formatAudioDuration () {
      return formatSecondsToStopwatch(this.audioDuration)
    },
    hasExternalMedia () {
      return this.activeQuestion.externalVideoUrl
    },
    hasMultipleMedia () {
      return this.options.length > 1
    },
    hasNoMedia () {
      return !this.hasSingleMedia && !this.hasMultipleMedia && !this.hasExternalMedia
    },
    hasSingleMedia () {
      return this.options.length === 1
    },
    hasResponseType () {
      return this.askForExplanation
    },
    isAudioPlayer () {
      return this.audioBlob !== null
    },
    isExpandPhotoIcon () {
      return this.isDesktop ? !this.isSpecificationsPreview : (!this.isSpecificationsPreview && !this.textAreaFocused)
    },
    isDesktopSurvey () {
      return this.isDesktop && !this.isPreview
    },
    isDesktopSurveyPreview () {
      return this.isDesktop && this.isSurveyPreview
    },
    isMediaVideo () {
      if (!this.options[0]) {
        return false
      } else {
        return this.options[0].media.contentType.split('/')[0] === 'video'
      }
    },
    isMultiMediaDesktopSurveyPreview () {
      return this.hasMultipleMedia && this.isDesktopSurveyPreview
    },
    isResponseTypeText () {
      return this.askForExplanation === 'text'
    },
    isRecordButton () {
      if (this.mediaRecorder === null) { return false }
      if (this.isRecording) { return false }
      if (this.audioBlob !== null) { return false }
      return true
    },
    isSpecificationsPreview () {
      return this.isPreview && !this.isSurveyPreview
    },
    isSpecsDesktopPreview () {
      return this.isSpecificationsPreview && this.isDesktop
    },
    isStopButton () {
      return this.isRecording
    },
    isSubmissionSkippable () {
      return this.questionResponses && this.audioBlob === this.questionResponses[0].srcUrl
    },
    mediaExtension () {
      return !this.hasSingleMedia ? '' : this.options[0].mediaUrl.split('.').pop()
    },
    mediaFormatCategory () {
      return !this.hasSingleMedia ? '' : this.options[0].media.contentType.split('/')[0]
    },
    mediaFullUrl () {
      return this.options ? this.options[0].mediaFullUrl : ''
    },
    mediaUrl () {
      return this.options ? this.options[0].mediaUrl : ''
    },
    nextButtonTextArea () {
      return this.writtenResponse.length && !this.textAreaFocused
    },
    imageStyle () {
      if (this.zoomLevel <= 100) {
        return {
          marginLeft: `${100 - this.zoomLevel}%`,
          marginRight: `${100 - this.zoomLevel}%`
        }
      } else if (this.zoomLevel > 100) {
        return {
          width: `${this.imageWidth * (this.zoomLevel / 100)}px`
        }
      } else {
        return {}
      }
    },
    showAudioInstructions () {
      // display instructions only when question has voice recording requirement
      if (this.askForExplanation === 'voice_recording' && this.showInstructions) {
        // for specs preview page
        if (!this.isPreview && !this.isSurveyPreview) return true
        // for participant page
        else if (this.isPreview && this.isSurveyPreview) return true
        else return false
      } else {
        return false
      }
    }
  },
  watch: {
    askForExplanation (newValue, oldValue) {
      // this is for specs - we need to update on the fly
      if (newValue === 'voice_recording') {
        this.tryInitializingMediaRecorder()
          .then(() => {})
          .catch(() => {})
      }
    },
    description () {
      this.fitQuestionText()
    },
    options () {
      this.fitQuestionText()
    },
    showInstructions (newValue) {
      if (!newValue) {
        this.$emit('hideLogoHeader', false)
        this.$emit('hideSpacetricsFooter', false)
      }
    }
  },
  mounted () {
    if (this.showAudioInstructions) { this.$emit('hideLogoHeader', true) }
    this.$emit('hideSpacetricsFooter', true)
    this.$store.commit('hideSidebar', true)
    if ((this.hasSingleMedia || (this.hasMultipleMedia && !this.isDesktopSurveyPreview) || this.options.length === 0 || this.hasExternalMedia) && !this.showAudioInstructions) {
      this.resizeObserver = new ResizeObserver(this.fitQuestionText)
      this.resizeObserver.observe(this.$refs.questionContainer)
    }

    if (this.hasSingleMedia && !this.isMediaVideo && !this.hasExternalMedia) {
      this.lightboxImageSrc = this.mediaUrl
      let img = new Image()
      img.onload = () => {
        this.lightboxImageSrc = this.mediaFullUrl
      }
      img.src = this.mediaFullUrl
    }

    if (this.hasMultipleMedia && (this.isDesktopSurveyPreview || this.isDesktopSurvey)) {
      this.$parent.$el.parentElement.scrollTop = 0
    }

    if (this.hasMultipleMedia && this.isDesktop) { this.$parent.$el.parentElement.addEventListener('scroll', this.handleWrapperScroll) }
    if (this.hasMultipleMedia && !this.isDesktop) { window.addEventListener('scroll', this.handleMobileScroll) }

    if (this.askForExplanation === 'voice_recording' && !this.showInstructions) {
      this.tryInitializingMediaRecorder()
        .then(() => {})
        .catch(() => {})
    }
  },
  beforeDestroy () {
    if (this.resizeObserver) { this.resizeObserver.unobserve(this.$refs.questionContainer) }
    if (this.hasMultipleMedia && this.isDesktop) { window.removeEventListener('scroll', this.handleWrapperScroll) }
    if (this.hasMultipleMedia && !this.isDesktop) { window.removeEventListener('scroll', this.handleMobileScroll) }
  },
  methods: {
    ...mapMutations(['toggleFullSpinner']),
    askForAudioPermission (incrementPage) {
      if (!this.audioPermissionGranted && this.askForExplanation === 'voice_recording') {
        this.errorMessage = null
        this.showToast = true
        this.toggleFullSpinner(true)
        this.$nextTick(() => {
          this.tryInitializingMediaRecorder(incrementPage)
            .then(() => { console.log('good'); this.toggleFullSpinner(false) })
            .catch(() => { console.log('bad'); this.toggleFullSpinner(false) })
        })
      }
    },
    answerData () {
      let answer = new FormData()
      answer.append(...(this.isResponseTypeText ? ['response[transcript]', this.writtenResponse] : ['response[audio_response]', this.audioBlob]))
      return answer
    },
    expandPhoto () {
      if (!this.isSpecsDesktopPreview) { this.showExpanedPhoto = true }
    },
    setImageWidth (index) {
      if (index === 0) { this.imageWidth = this.$refs.multiMedia[0].clientWidth }
    },
    handleDisableInstructions () {
      this.showInstructions = false
      this.$nextTick(() => {
        this.resizeObserver = new ResizeObserver(this.fitQuestionText)
        this.resizeObserver.observe(this.$refs.questionContainer)
      })
    },
    handleFixedImagesScroll () {
      this.multiMediaImagesScrollDistance = this.$refs.multiMediaImages.scrollTop
    },
    handleWrapperScroll () {
      this.surveyWrapperScrollDistance = this.$parent.$el.parentElement.scrollTop
    },
    handleMobileScroll () {
      this.mobileWindowScrollDistance = window.top.scrollY
      window.top.scrollY >= this.$refs.question.getBoundingClientRect().top
        ? this.isStickyQuestionActive = true
        : this.isStickyQuestionActive = false
    },
    isMultiMediaVideo (media) {
      return media.media.contentType.split('/')[0] === 'video'
    },
    positionCloseIcon () {
      let lightBoxImagePosition = this.$refs.lightboxImage.getBoundingClientRect()

      if (this.isDesktop) {
        this.$refs.closeLightboxIcon.style.left = lightBoxImagePosition.right + 10 + 'px'
        this.$refs.closeLightboxIcon.style.top = lightBoxImagePosition.top + 'px'
      } else {
        this.$refs.closeLightboxIcon.style.left = lightBoxImagePosition.right - 8 + 'px'
        this.$refs.closeLightboxIcon.style.top = lightBoxImagePosition.top - 24 + 'px'
      }
    },
    fitQuestionText () {
      this.$nextTick(() => {
        let textDiv = this.$refs.questionContainer
        let textSpan = this.$refs.question
        let fontSize = this.hasMultipleMedia && this.isDesktop ? 18 : 26
        textSpan.style.fontSize = `${fontSize}px`
        while (textSpan.offsetHeight > textDiv.offsetHeight) {
          if (fontSize <= 12) {
            textDiv.style.overflowY = 'scroll'
            this.overflowDescription = true
            break
          }
          fontSize--
          textSpan.style.fontSize = `${fontSize}px`
        }
      })
    },
    focusOutTextArea () {
      this.textAreaFocused = false

      if (this.hasSingleMedia) {
        this.$emit('hideLogoHeader', false)
        this.$emit('hideSpacetricsFooter', false)
      }
    },
    focusTextArea () {
      this.textAreaFocused = true
      if (this.hasSingleMedia) {
        this.$emit('hideLogoHeader', true)
        this.$emit('hideSpacetricsFooter', true)
      }
    },
    loadResponses () {
      if (!this.isResponseTypeText && this.questionResponses && this.questionResponses.length > 0 && !this.showAudioInstructions) {
        this.audioBlob = this.questionResponses[0].srcUrl
        this.$nextTick(() => {
          this.$refs.audioPlayer.src = this.questionResponses[0].srcUrl
          this.$refs.audioPlayer.load()
          this.readyToSubmit = true
        })
      }
    },
    onRecordingAvailable (blob) {
      this.audioBlob = blob
      this.readyToSubmit = true
      getBlobDuration(this.audioBlob).then(duration => { this.audioDuration = duration })

      this.$nextTick(() => { this.setAudioSrc() })
    },
    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime) { this.replayAudio = false }
      }
      this.replayAudio = true
    },
    rerecord () {
      this.audioBlob = null
      this.isRecording = false
      this.readyToSubmit = false
    },
    resetZoomLevel () {
      if (this.isDesktopSurveyPreview) { this.zoomLevel = 100 }
    },
    setAudioSrc (audioUrl = '') {
      if (!this.isSpecificationsPreview) { audioUrl = window.URL.createObjectURL(this.audioBlob) }
      this.$refs.audioPlayer.src = audioUrl
      this.$refs.audioPlayer.load()
    },
    stopReplay () {
      this.replayAudio = false
      this.$refs.audioPlayer.pause()
    },
    handleZoomIn () {
      this.zoomLevel += 10
      this.$nextTick(() => {
        this.isDesktopSurvey || this.isDesktopSurveyPreview
          ? this.zoomIn(this.multiMediaImagesScrollDistance, this.surveyWrapperScrollDistance)
          : this.zoomIn(this.multiMediaImagesScrollDistance, this.mobileWindowScrollDistance)
      })
    },
    zoomIn (fixedScrollDistance, parentScrollDistance) {
      let imageOffsets = this.$refs.multiMedia.map(media => { return media.offsetTop })
      if (this.zoomLevel === 110) {
        this.$refs.multiMediaImages.addEventListener('scroll', this.handleFixedImagesScroll)
        this.$refs.multiMediaImages.scrollTop = nearestInArray(parentScrollDistance, imageOffsets)
      } else if (this.zoomLevel > 110) {
        this.$refs.multiMediaImages.scrollTop = nearestInArray(fixedScrollDistance, imageOffsets)
      } else {
        this.$parent.$el.parentElement.scrollTop = nearestInArray(parentScrollDistance, imageOffsets)
      }
    },
    handleZoomOut () {
      if (this.zoomLevel > 60) {
        this.zoomLevel -= 10
        this.$nextTick(() => {
          this.isDesktopSurvey || this.isDesktopSurveyPreview
            ? this.zoomOut(this.multiMediaImagesScrollDistance, this.surveyWrapperScrollDistance)
            : this.zoomOut(this.multiMediaImagesScrollDistance, this.mobileWindowScrollDistance)
        })
      }
    },
    zoomOut (fixedScrollDistance, parentScrollDistance) {
      let imageOffsets = this.$refs.multiMedia.map(media => { return media.offsetTop })
      if (this.zoomLevel === 100) {
        this.isDesktopSurvey || this.isDesktopSurveyPreview
          ? this.$parent.$el.parentElement.scrollTop = nearestInArray(fixedScrollDistance, imageOffsets)
          : window.scrollTo({
            top: nearestInArray(fixedScrollDistance, imageOffsets),
            behavior: 'instant'
          })
      } else if (this.zoomLevel < 100) {
        this.$parent.$el.parentElement.scrollTop = nearestInArray(parentScrollDistance, imageOffsets)
      } else {
        this.$refs.multiMediaImages.scrollTop = nearestInArray(fixedScrollDistance, imageOffsets)
      }
    }
  }
}
</script>
