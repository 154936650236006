export const ROOM_NAME_LIST = [
  'Anteroom',
  'Armory',
  'Assembly Room',
  'Attic',
  'Backroom',
  'Ballroom',
  'Basement',
  'Bathroom',
  'Bedroom',
  'Boardroom',
  'Boiler Room',
  'Boudoir',
  'Breakfast Nook',
  'Breakfast Room',
  'Cabin',
  'Cell',
  'Cellar',
  'Chamber',
  'Changing Room',
  'Chapel',
  'Classroom',
  'Clean Room',
  'Cloakroom',
  'Cold Room',
  'Common Room',
  'Conference Room (S)',
  'Conference Room (M)',
  'Conference Room (L)',
  'Conservatory',
  'Control Room',
  'Courtroom',
  'Cubby',
  'Darkroom',
  'Den',
  'Dining Room',
  'Dormitory',
  'Drawing Room',
  'Dressing Room',
  'Dungeon',
  'Emergency Room',
  'Engine Room',
  'Entry',
  'Family Room',
  'Fitting Room',
  'Formal Dining Room',
  'Foyer',
  'Front Room',
  'Game Room',
  'Garage',
  'Garret',
  'Great Room',
  'Green Room',
  'Grotto',
  'Guest Room',
  'Gym',
  'Hall',
  'Hallway',
  'Homeroom',
  'Hospital Room',
  'Hotel Room',
  'Inglenook',
  'Jail Cell',
  'Keep',
  'Kitchen',
  'Kitchenette',
  'Ladies’ Room',
  'Larder',
  'Laundry Room',
  'Library',
  'Living Room',
  'Lobby',
  'Locker Room',
  'Loft',
  'Lounge',
  'Lunchroom',
  'Maid’s Room',
  'Mailroom',
  'Men’s Room',
  'Morning Room',
  'Motel Room',
  'Mud Room',
  'Newsroom',
  'Nursery',
  'Office',
  'Operating Room',
  'Panic Room',
  'Pantry',
  'Parlor',
  'Playroom',
  'Pool Room',
  'Powder Room',
  'Prison Cell',
  'Rec Room',
  'Recovery Room',
  'Restroom',
  'Rumpus Room',
  'Salesroom',
  'Salon',
  'Schoolroom',
  'Screen Porch',
  'Scullery',
  'Showroom',
  'Sick Room',
  'Sitting Room',
  'Solarium',
  'Staff Room',
  'Stateroom',
  'Stockroom',
  'Storeroom',
  'Studio',
  'Study',
  'Suite',
  'Sunroom',
  'Tack Room',
  'Utility Room',
  'Vestibule',
  'Visitors’ Room',
  'Waiting Room',
  'Wardroom',
  'Washroom',
  'Water Closet',
  'Weight Room',
  'Wine Cellar',
  'Women’s Room',
  'Workroom'
]

export const SLIDE_COLORS = [
  '#000',
  '#FFF',
  '#ebeff2',
  '#ced4da',
  '#868e96',
  '#ffa8a8',
  '#ff6b6b',
  '#fcc2d7',
  '#f06595',
  '#eebefa',
  '#cc5de8',
  '#d0bfff',
  '#845ef7',
  '#bac8ff',
  '#5c7cfa',
  '#a5d8ff',
  '#339af0',
  '#99e9f2',
  '#22b8cf',
  '#96f2d7',
  '#20c997',
  '#b2f2bb',
  '#51cf66',
  '#c0eb75',
  '#fcc419',
  '#ff922b',
]

export const EDITOR_FONTS = [
  'monospace',
  'arial', 
  'courier', 
  'garamond', 
  'tahoma', 
  'verdana'
]

export const EDITOR_FONT_SIZES = [
  '10px', 
  '12px', 
  '14px', 
  '16px', 
  '18px', 
  '20px', 
  '24px'
]