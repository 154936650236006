<template>
  <div class="icon_opinionmetric">
    <div class="icon_opinionmetric__svg">
      <svg
        viewBox="0 0 15.998 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-23411.686-166.5v12.449h-3.447l-1.279 3.551-3.48-3.551h-7.791V-166.5zm-5.127 14.162l.977-2.713h3.15V-165.5h-14v10.449h7.211z"
          transform="translate(23427.684 166.499)"
        />
        <path
          d="M0 0h2v2H0z"
          transform="translate(3 5)"
        />
        <path
          d="M0 0h2v2H0z"
          transform="translate(7 5)"
        />
        <path
          d="M0 0h2v2H0z"
          transform="translate(11 5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
