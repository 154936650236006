<template>
  <div class="icon_addlist">
    <div class="icon_addlist__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 4.5A4.5 4.5 0 114.5 0 4.5 4.5 0 019 4.5zm-1 0A3.5 3.5 0 104.5 8 3.5 3.5 0 008 4.5z"
          transform="translate(7 7)"
        />
        <path
          d="M.5 0v4h-1V0z"
          transform="translate(11.5 9.5)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(9.5 11.5)"
        />
        <path
          d="M0-.5h7v1H0z"
          transform="translate(4 1.5)"
        />
        <path
          d="M0-.5h7v1H0z"
          transform="translate(4 5.5)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(4 9.5)"
        />
        <path d="M3 1.5A1.5 1.5 0 111.5 0 1.5 1.5 0 013 1.5zm-1 0a.5.5 0 10-.5.5.5.5 0 00.5-.5z" />
        <path
          d="M3 1.5A1.5 1.5 0 111.5 0 1.5 1.5 0 013 1.5zm-1 0a.5.5 0 10-.5.5.5.5 0 00.5-.5z"
          transform="translate(0 4)"
        />
        <path
          d="M3 1.5A1.5 1.5 0 111.5 0 1.5 1.5 0 013 1.5zm-1 0a.5.5 0 10-.5.5.5.5 0 00.5-.5z"
          transform="translate(0 8)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
