export const COMPANY_SIZE_CATEGORIES = Object.freeze({
  'size_6_10': '6 - 10',
  'size_11_25': '11 - 25',
  'size_26_50': '26 - 50',
  'size_51_100': '51 - 100',
  'size_101_500': '101 - 500',
  'size_501_999': '501 - 999',
  'size_1000_plus': '1000 +'
})

export const INDUSTRY_CATEGORIES = Object.freeze({
  'architect': 'Architect',
  'commercial_developer': 'Commercial Developer',
  'corporate_work_force_strategist': 'Corporate Work Force Strategist',
  'interior_designer': 'Interior Designer',
  'property_manager': 'Property Manager',
  'residential_developer': 'Residential Developer',
  'real_estate_investor': 'Real Estate Investor',
  'tenant': 'Tenant',
  'other': 'Other'
})

export const THEME_COLOR_CATEGORIES = Object.freeze({
  'blue': 'blue',
  'dark_navy': 'dark-navy',
  'purple': 'purple',
  'coral': 'coral',
  'green': 'green',
  'yellow': 'yellow'
})

export const SPECS_TABS = {
  CONTENT: 'content',
  SPECIFICATIONS: 'specifications',
  EXAMPLE: 'example'
}

export const SURVEY_PERMISSIONS_CATEGORIES = Object.freeze({
  'admin': 'Full Access',
  'edit': 'Can Edit',
  'read': 'Can Read'
})

export const SLIDES_TEMPLATES = Object.freeze({
  '1x1': 1,
  '1x2': 2,
  '2x1': 2,
  '1x3': 3
})

function categoryDisplay (categoryList, outerDefaultValue = '') {
  return (category, defaultValue = outerDefaultValue) => {
    if (!categoryList.hasOwnProperty(category)) {
      return defaultValue
    }

    return categoryList[category]
  }
}

export const companySizeCategoryDisplay = categoryDisplay(COMPANY_SIZE_CATEGORIES)

export const industryCategoryDisplay = categoryDisplay(INDUSTRY_CATEGORIES)

export const themeColorCategoryDisplay = categoryDisplay(THEME_COLOR_CATEGORIES)

export const surveyPermissionsCategoryDisplay = categoryDisplay(SURVEY_PERMISSIONS_CATEGORIES)
