<template>
  <div class="st-empaneling-location__map-canvas">
    <div
      ref="map"
      class="st-empaneling-location__google-map"
    />
    <input
      ref="autoComplete"
      :class="{
        'st-empaneling-location__auto-complete': true,
        'st-empaneling-location__auto-complete--active': isActive
      }"
      placeholder="Enter a city and state"
    >
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'

export default {
  components: {
  },
  props: {
  },
  data: function () {
    return {
      autocomplete: null,
      gcpMapStyle: 'f5f989e49c7c974',
      map: null,
      marker: null
    }
  },
  computed: {
    ...mapState('requestAudience', [
      'locationCity',
      'locationLatitude',
      'locationLongitude',
      'locationState',
      'mapMarker',
      'mapsKey'
    ]),
    isActive () {
      return this.locationCity && this.locationState
    },
    mapCenter () {
      return { lat: parseInt(this.locationLatitude), lng: parseInt(this.locationLongitude) }
    }
  },
  mounted () {
    const loader = new Loader({
      apiKey: this.mapsKey,
      libraries: ['places'],
      mapIds: [this.gcpMapStyle]
    })

    loader.load().then(() => {
      this.addMap()
      this.addMarker()
      this.addAutoComplete()
    })
  },
  methods: {
    ...mapActions('requestAudience', ['updateLocation', 'updateLocationCoordinates']),
    addAutoComplete () {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autoComplete, { types: ['(cities)'] })
      this.autocomplete.bindTo('bounds', this.map)
      this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this.autocomplete.addListener('place_changed', this.handleAutoCompleteUpdate)
    },
    addMap () {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.mapCenter,
        mapId: this.gcpMapStyle,
        zoom: 9
      })
    },
    addMarker () {
      // eslint-disable-next-line no-undef
      this.marker = new google.maps.Marker({
        map: this.map,
        // eslint-disable-next-line no-undef
        anchorPoint: new google.maps.Point(this.locationLatitude, this.locationLongitude),
        icon: { url: this.mapMarker }
      })

      this.marker.setPosition(this.mapCenter)
      this.marker.setVisible(true)
    },
    handleAutoCompleteUpdate () {
      let place = this.autocomplete.getPlace()

      if (place.geometry) {
        let newLat = place.geometry.location.lat()
        let newLng = place.geometry.location.lng()
        this.updateLocationCoordinates({ latitude: newLat, longitude: newLng })
        this.map.setCenter(place.geometry.location)
        this.marker.setPosition({ lat: newLat, lng: newLng })
      }

      if (place.address_components) {
        let city = place.address_components[0].long_name
        let state = place.address_components.filter(address => { return address.types.includes('administrative_area_level_1') })
        let country = place.address_components.filter(address => { return address.types.includes('country') })
        let locationState = state.length > 0 ? state[0].long_name : country[0].long_name
        this.updateLocation({ locationCity: city, locationState: locationState })
      }
    }
  }
}
</script>
