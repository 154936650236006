<template>
  <div
    :class="[{
      'st-checkbox__align-content': true
    }, wrapperClasses]"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="handleVariantClick"
    @keydown.enter="handleKeydown"
  >
    <input
      :id="stUid"
      v-model="innerChecked"
      type="checkbox"
      :class="{
        'st-checkbox': true,
        'st-checkbox--hover': hover,
        'st-checkbox--transparent': transparent
      }"
      :name="name"
      :disabled="disabled"
    >
    <label
      v-if="checked"
      :class="{
        'st-checkbox-indicator': true,
        'st-checkbox-indicator--multiple': selectMultiple,
        'st-checkbox-indicator--multiple-invert': selectMultiple && invert,
        'st-checkbox-indicator--disabled': disabled
      }"
      :for="stUid"
    >
      <icon-wrapper
        v-if="selectMultiple"
        class="st-checkbox__minus-icon"
        icon-name="minus"
        :invert="!invert"
      />
      <icon-wrapper
        v-else
        icon-name="check"
        :invert="true"
      />
    </label>
    <label
      v-else-if="transparent"
      class="st-checkbox-indicator--empty-transparent"
    />
    <label
      class="st-checkbox__label"
      :for="stUid"
      @click="handleVariantClick"
    >
      <!-- @slot What to show for label (usually just text) -->
      <slot />
    </label>
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'
import StUid from '../../mixins/uid'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [StUid],
  model: {
    prop: 'checked'
  },
  props: {
    /** Status of checkbox. */
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Blocks the checkbox from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Change the display of checkbox for head of tables. */
    selectMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    /** name of checkbox input */
    name: {
      type: String,
      required: false,
      default: ''
    },
    /* Makes selected box white and the icon black. */
    invert: {
      type: Boolean,
      required: false,
      default: false
    },
    /* Makes unselected box transparent with white border. */
    transparent: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Can be used to change the kind of checkbox (ex: fullBox) */
    variants: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      hover: false
    }
  },
  computed: {
    innerChecked: {
      get () {
        return this.checked
      },
      set (ev) {
        /** emits with the new value for the checkbox (true / false) */
        if (!this.isFullBoxVariant) { this.$emit('input', ev) }
      }
    },
    isFullBoxVariant () {
      return this.variants.includes('fullBox')
    },
    wrapperClasses () {
      return {
        'st-checkbox-variant__fullBox': this.isFullBoxVariant,
        'st-checkbox-variant__fullBox--hovered': this.isFullBoxVariant && this.hover && !this.checked,
        'st-checkbox-variant__fullBox--active': this.isFullBoxVariant && this.checked
      }
    }
  },
  methods: {
    handleKeydown () {
      this.$emit('input', !this.checked)
    },
    handleVariantClick () {
      if (this.isFullBoxVariant) { this.$emit('input', !this.checked) }
    }
  }
}
</script>
