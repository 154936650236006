<template>
  <div class="icon_speech-bubbles">
    <div class="icon_speech-bubbles__svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="94.023"
        height="120.044"
        viewBox="0 0 94.023 120.044"
      >
        <g transform="translate(-1113 -416.849)">
          <g transform="translate(-131 -30)">
            <path
              class="a"
              d="M50.14,15607.367H.086L0,15649.929H15.372l14.768,16.913v-10.114h2v15.446l-17.677-20.245H-2l.091-46.562H50.14Z"
              transform="translate(1246.002 -15158.519)"
            />
            <path
              class="a"
              d="M-24.4,15605.478H19.228l-.542,45.848H1.663l0-2,15.044,0,.508-41.852H-24.4Z"
              transform="translate(1277.795 -15158.519)"
            />
            <g transform="translate(1.979)">
              <path
                class="a"
                d="M4,0H0V4H4Z"
                transform="translate(1278.021 469.481)"
              /><path
                class="a"
                d="M4,0H0V4H4Z"
                transform="translate(1267.021 469.481)"
              /><path
                class="a"
                d="M4,0H0V4H4Z"
                transform="translate(1256.021 469.481)"
              /><path
                class="a"
                d="M4,0H0V4H4Z"
                transform="translate(1278.021 469.481)"
              /><path
                class="a"
                d="M4,0H0V4H4Z"
                transform="translate(1267.021 469.481)"
              /><path
                class="a"
                d="M4,0H0V4H4Z"
                transform="translate(1256.021 469.481)"
              /></g><g transform="translate(1285 499.849)"><g transform="translate(0)"><path
              class="a"
              d="M-2,15607.367H48.054l.085,42.562H32.767L18,15666.842v-10.114H16v15.446l17.677-20.245H50.141l-.091-46.562H-2Z"
              transform="translate(2.882 -15605.367)"
            /><path
              class="a"
              d="M19.229,15605.478H-24.4l.542,45.848H-6.836l0-2-15.045,0-.508-41.852H19.229Z"
              transform="translate(24.401 -15605.367)"
            /></g><g transform="translate(13.023 22.633)"><path
              class="a"
              d="M0,0H4V4H0Z"
              transform="translate(0)"
            /><path
              class="a"
              d="M0,0H4V4H0Z"
              transform="translate(11)"
            /><path
              class="a"
              d="M0,0H4V4H0Z"
              transform="translate(22)"
            /><path
              class="a"
              d="M0,0H4V4H0Z"
              transform="translate(0)"
            /><path
              class="a"
              d="M0,0H4V4H0Z"
              transform="translate(11)"
            /><path
              class="a"
              d="M0,0H4V4H0Z"
              transform="translate(22)"
            /></g></g></g><path
            class="b"
            d="M-17933.932,5630.849v24.07h-8.691l-8.639,9.97v-9.97H-17961v-24.07Z"
            transform="translate(19140.955 -5214)"
            style="fill: #BCBAB8; opacity: 0.3;"
          /><path
            class="b"
            d="M-17961,5630.849v25.317h9.143l9.086,10.486v-10.486h10.242v-25.317Z"
            transform="translate(19074 -5129.76)"
            style="fill: #BCBAB8; opacity: 0.3;"
          /></g></svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
