<template>
  <div>
    <div class="st-tour-mode">
      <matterport-iframe
        v-if="matterportId"
        ref="tour"
        class="st-tour-mode__matterport-viewer"
        :allow-zoom="false"
        :matterport-id="matterportId"
        :show-floorplan-icon="false"
        @matterport-phase-change="handlePhaseChange"
        @matterport-showcase-loaded="handleShowcaseLoad"
      />
      <div
        :class="{
          'st-tour-mode__segment-container': true,
          'st-tour-mode__segment-container--closed': !tourSegmentsOpen
        }"
      >
        <div
          v-if="!tourSegmentsOpen"
          @click="toggleTourSegments(true)"
        >
          <div class="st-tour-mode__heading-row st-tour-mode__heading-row--closed">
            <icon-wrapper
              icon-name="segment"
              :invert="true"
            />
            <div class="st-tour-mode__heading-text">
              Segments
            </div>
          </div>
        </div>
        <template v-else>
          <div class="st-tour-mode__heading-name-and-icon st-tour-mode__segment-title">
            <icon-wrapper
              icon-name="segment"
              :invert="true"
            />
            <div class="st-tour-mode__heading-text">
              Segments
            </div>
          </div>
          <div class="st-tour-mode__comment-scroll-area">
            <segments-panel
              :without-header="true"
              :invert="true"
            />
            <div
              :class="{
                'st-tour-mode__participant-checkboxes': true,
                'st-survey-insights__modifiers-toggle-row': true,
                'st-survey-insights__panel-subtitle': participantsExpanded
              }"
              @click="participantsExpanded = !participantsExpanded"
            >
              <div>Participants</div>
              <icon-wrapper
                icon-name="chevron"
                :invert="true"
                :class="{
                  'st-survey-insights__modifiers-toggle--up': participantsExpanded,
                  'st-survey-insights__modifiers-toggle--down': !participantsExpanded
                }"
              />
            </div>
            <template v-if="participantsExpanded">
              <participant-checkboxes :without-header="true" />
            </template>
          </div>
          <div
            class="st-tour-mode__collapse-segments"
            @click="toggleTourSegments(false)"
          >
            <icon-wrapper
              icon-name="plus"
              :invert="true"
              class="st-tour-mode__close-section-icon"
            />
          </div>
        </template>
      </div>
      <div
        :class="{
          'st-tour-mode__comment-container': true,
          'st-tour-mode__comment-container--closed': !tourTranscriptsOpen
        }"
      >
        <div
          v-if="!tourTranscriptsOpen"
          @click="toggleTourTranscripts(true)"
        >
          <div class="st-tour-mode__heading-row st-tour-mode__heading-row--closed">
            <icon-wrapper
              icon-name="transcript"
              :invert="true"
            />
            <div class="st-tour-mode__heading-text">
              Transcripts
            </div>
          </div>
        </div>
        <template v-else>
          <div class="st-tour-mode__heading-row">
            <div class="st-tour-mode__heading-name-and-icon">
              <icon-wrapper
                icon-name="transcript"
                :invert="true"
              />
              <div class="st-tour-mode__heading-text">
                Transcripts
              </div>
            </div>
            <div @click="toggleTourTranscripts(false)">
              <icon-wrapper
                icon-name="plus"
                :invert="true"
                class="st-tour-mode__close-section-icon"
              />
            </div>
          </div>
          <div class="st-tour-mode__header-room-row">
            <div class="st-tour-mode__room-icon" />
            <div>
              {{ activeRoom.name }}
            </div>
          </div>
          <sentiment-tag
            :sentiment="activeRoomSentiment"
            class="st-tour-mode__room-tag"
          />
          <div
            v-if="activeViewpointPrompt"
            class="st-tour-mode__active-prompt"
          >
            {{ activeViewpointPromptText }}
          </div>
          <div class="st-tour-mode__comment-scroll-area">
            <div class="st-tour-mode__transcripts-section">
              <div
                v-for="(viewpointTranscript, idx) in activeViewpointTranscripts"
                v-show="isSelected(viewpointTranscript.response) && viewpointTranscript.populatedTranscripts.length > 0"
                :key="activeViewpointIdentifier + '-' + idx"
                class="st-tour-mode__transcript-container"
              >
                <participant-avatar :participant="viewpointTranscript.response" />
                <div>
                  <h4 class="st-tour-mode__participant-name">
                    {{ viewpointTranscript.fullName }}
                  </h4>
                  <p class="st-tour-mode__time-spent">
                    {{ formatSeconds(viewpointTranscript.totalTime) }} spent on this viewpoint
                  </p>
                  <p class="st-tour-mode__transcript-text">
                    {{ activeSentencesForResponse(viewpointTranscript.response) }}
                  </p>
                </div>
              </div>
              <p
                v-if="activeViewpointTranscripts.length === 0"
                class="st-tour-mode__transcript-empty"
              >
                No transcripts at this viewpoint. Try changing settings or moving to a different location to see transcripts.
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="st-tour-mode__footer">
      <div />
      <div
        class="st-tour-mode__exit-link"
        @click="exitTourMode"
      >
        <div class="st-tour-mode__exit-text">
          Exit Tour Mode
        </div>
        <icon-wrapper
          icon-name="tour-mode"
          :invert="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { formatSecondsWithColon } from '../../../utilities/data_utilities'
import { IconWrapper } from '../../common'
import { ParticipantCheckboxes, SegmentsPanel } from './insights_layout'
import MatterportIframe from '../../matterport/matterport-iframe'
import ParticipantAvatar from './participant-avatar'
import SentimentTag from './sentiment-tag'

export default {
  components: {
    IconWrapper,
    'matterport-iframe': MatterportIframe,
    'participant-avatar': ParticipantAvatar,
    ParticipantCheckboxes,
    SegmentsPanel,
    SentimentTag
  },
  props: {
  },
  data: function () {
    return {
      participantsExpanded: false,
      showcase: null
    }
  },
  computed: {
    ...mapState('insights', ['activeViewpointIdentifier', 'queuedViewpointIdentifier', 'segments', 'selectedSurveyResponseIds', 'tourSegmentsOpen', 'tourTranscriptsOpen']),
    ...mapGetters('insights', [
      'activeRoom',
      'activeRoomSentiment',
      'activeQuestion',
      'activeViewpointTranscripts',
      'viewpointsByIdentifier',
      'responsesById'
    ]),
    matterportId () {
      if (!this.activeQuestion.hasOwnProperty('matterportId')) { return null }

      return this.activeQuestion.matterportId
    },
    activeViewpointPrompt () {
      if (this.activeViewpointIdentifier) {
        return this.activeViewpoint.prompt
      } else {
        return null
      }
    },
    activeViewpointPromptText () {
      if (this.activeViewpointIdentifier) {
        let topicOrPrompt = this.activeViewpoint.topic ? 'Topic' : 'Prompt'
        return `${topicOrPrompt}: ${this.activeViewpointPrompt}`
      } else {
        return null
      }
    },
    activeViewpoint () {
      return this.viewpointsByIdentifier[this.activeViewpointIdentifier]
    }
  },
  mounted () {
    this.$store.commit('toggleFullscreen', true)

    if (!this.activeViewpointIdentifier && this.activeViewpointTranscripts.length === 0) {
      this.$store.commit('insights/setActiveViewpointIdentifier', this.activeQuestion.initialViewpoint)
    }
  },
  methods: {
    ...mapMutations('insights', [
      'setActiveViewpointIdentifier',
      'resetSegments',
      'resetParticipants',
      'toggleTourSegments',
      'toggleTourTranscripts'
    ]),
    handlePhaseChange (appState) {
      if (this.queuedViewpointIdentifier) {
        if (appState === this.showcase.App.Phase.PLAYING) {
          this.moveToSweep(this.queuedViewpointIdentifier)
          this.$store.commit('toggleFullSpinner', false)
        }
      }
    },
    handleShowcaseLoad (sdk) {
      this.showcase = sdk
      this.$store.commit('insights/setMoveVirtualTour', this.moveToSweep)
      sdk.on(sdk.Sweep.Event.ENTER, (x, y) => {
        this.$store.commit('insights/setActiveViewpointIdentifier', y)
      })
    },
    moveToSweep (identifier) {
      this.showcase.Sweep.moveTo(identifier)
        .then(res => { console.log('MOVED', res) })
        .catch(err => { console.error('PROBLEM MOVING', err) })
    },
    formatSeconds (seconds) {
      return formatSecondsWithColon(seconds)
    },
    activeSentencesForResponse (response) {
      // lookup "real" response because the one stored here hasn't had the seconds set-up yet & stuff
      response = this.$store.getters['insights/findResponse'](response.id)
      let sentences = response.sentences
      let viewpointSentences = sentences.filter(sentence => sentence.occursOverPano(this.activeViewpointIdentifier))
      return viewpointSentences.map(sentence => sentence.text).join(' ')
    },
    isSelected (response) {
      return this.selectedSurveyResponseIds[response.surveyResponseId]
    },
    participantName (participant) {
      return (participant.firstName ? (participant.firstName + ' ' + (participant.lastName ? participant.lastName[0] : '')) : 'Anonymous user')
    },
    exitTourMode () {
      this.$store.commit('insights/toggleTourMode', false)
      this.$store.commit('toggleFullscreen', false)
      let url = new URL(window.location)
      let questionId = url.searchParams.get('question')
      let title = ''
      let newState = {}
      title = 'exit tour mode'
      newState.mode = 'normal'
      url = `?question=${questionId}`
      window.history.pushState(newState, title, url)
    }
  }
}
</script>
