<template>
  <div class="icon_add-virtual-room">
    <div class="icon_add-virtual-room__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 4.5A4.5 4.5 0 114.5 0 4.5 4.5 0 019 4.5zm-1 0A3.5 3.5 0 104.5 8 3.5 3.5 0 008 4.5z"
          transform="translate(7 7)"
        />
        <path
          d="M.5 0v4h-1V0z"
          transform="translate(11.5 9.5)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(9.5 11.5)"
        />
        <path
          d="M0 0h1v8.206H0z"
          transform="translate(6.479)"
        />
        <path d="M0 0h1v10.554H0z" />
        <path
          d="M0 0h1v8H0z"
          transform="translate(12.957)"
        />
        <path d="M0 0h13.821v1H0z" />
        <path
          d="M-23283.594-53.515l-5.52 1.988 5.52 2.254.527-.343.488.873-1.016.589-.229-.112-6.871-2.831.021-.933 7.078-2.471 2 .381-.2.979z"
          transform="translate(23290.693 62.239)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
