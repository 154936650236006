<template>
  <div class="st-empaneling-multiselect__checkboxes-wrapper">
    <st-checkbox
      v-for="(gender, idx) in GENDERS"
      :key="'gender-' + gender"
      class="st-empaneling-multiselect__checkbox"
      :checked="genderSelectedIndexes[idx]"
      :variants="['fullBox']"
      @input="updateGender({idx: idx, isSelected: !genderSelectedIndexes[idx]})"
    >
      {{ gender }}
    </st-checkbox>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { StCheckbox } from '../../../../common'

export default {
  components: {
    StCheckbox
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('requestAudience', ['genderSelectedIndexes']),
    ...mapGetters('requestAudience', ['GENDERS'])
  },
  methods: {
    ...mapActions('requestAudience', ['updateGender'])
  }
}
</script>
