<template>
  <div class="icon_elementpreference">
    <div class="icon_elementpreference__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-23266.73-265.595c.092-.124 1.256-1.587 2.293-1.179s.879 1.4.59 2.315l.482.013a1.366 1.366 0 011.3 1.507 1.828 1.828 0 01.047 1.184 1.752 1.752 0 01-.3 1.535 1.4 1.4 0 01-.24.745c-.49.721-1.248.623-2.064.687-2.328.182-2.908-.259-3.84-1.1v-3.4zm-.729 2.634v2.626c1 .9 2.469.511 3.5.509s.52-.9.52-.9c.785-.022.381-1.047.381-1.047a.592.592 0 00-.086-.982c.33-.462-.035-.728-.385-.7h-1.873c1.039-1.777.742-2.391.373-2.379-.371.034-.571.404-2.43 2.874z"
          transform="translate(23270.539 266.845)"
        />
        <path
          d="M-23267.641-266.1c.49-.721 1.248-.623 2.064-.687 2.328-.182 2.908.259 3.84 1.1v3.4l-1.729 2.3c-.092.124-1.141 1.521-2.26 1.045-.84-.35-.912-1.263-.623-2.181l-.482-.013a1.366 1.366 0 01-1.3-1.507 1.828 1.828 0 01-.047-1.184 1.752 1.752 0 01.3-1.535 1.4 1.4 0 01.237-.738zm.506 2.3a.687.687 0 00.086.982c-.342.454.041.771.385.7h1.873c-1.039 1.777-.742 2.391-.373 2.379.365-.036.57-.408 2.428-2.877v-2.626c-1-.9-2.469-.511-3.5-.509s-.52.9-.52.9c-.783.023-.379 1.051-.379 1.051z"
          transform="translate(23275.459 274.839)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(10 .003)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(0 3.001)"
        />
        <path
          d="M3 1H0V0h3z"
          transform="translate(12.803 12.106)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(0 6)"
        />
        <path
          d="M3 1H0V0h3z"
          transform="translate(12.803 9.107)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(0 14.997)"
        />
        <path
          d="M0 0h1v6H0z"
          transform="translate(0 9.107)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(0 3.001)"
        />
        <path
          d="M1 4H0V0h1z"
          transform="translate(14.803 9.107)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(2 3.001)"
        />
        <path
          d="M1 4H0V0h1z"
          transform="translate(12.803 9.107)"
        />
        <path
          d="M0 0h1v6H0z"
          transform="translate(15 .003)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
