<template>
  <div>
    <div
      :class="{
        'st-survey-insights__modifiers-toggle-row': true,
        'st-survey-insights__panel-subtitle': expanded
      }"
      @click="expanded = !expanded"
    >
      <div>{{ segment.name }}</div>
      <icon-wrapper
        icon-name="chevron"
        :invert="invert"
        :class="{
          'st-survey-insights__modifiers-toggle--up': expanded,
          'st-survey-insights__modifiers-toggle--down': !expanded
        }"
      />
    </div>
    <template v-if="expanded">
      <st-checkbox
        v-for="(option, n) in segment.options"
        :key="'option-' + option.id"
        :checked="option.selected"
        class="st-survey-insights__checkbox"
        :transparent="invert"
        @input="toggleSegmentOption(idx, n, !option.selected)"
      >
        {{ option.name }}
      </st-checkbox>
    </template>
  </div>
</template>

<script>
import {
  StCheckbox,
  IconWrapper
} from '../../../common'
export default {
  components: {
    StCheckbox,
    IconWrapper
  },
  props: {
    segment: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    invert: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      expanded: false
    }
  },
  computed: {
  },
  methods: {
    toggleSegmentOption (segmentIdx, optionIdx, selected) {
      this.$store.commit('insights/toggleSegmentOption', {
        segmentIdx: segmentIdx,
        optionIdx: optionIdx,
        value: selected
      })
    }
  }
}
</script>
