<template>
  <div>
    <div class="drag-container__y-axis">
      <div class="drag-container">
        <svg
          class="drag-container__background perception-slider"
          viewBox="0 0 300 250"
        >
          <line
            class="drag-container__axis"
            x1="8"
            y1="230"
            x2="292"
            y2="230"
            stroke="#C0C5C7"
            stroke-width="3"
            stroke-linecap="round"
          />
          <line
            class="drag-container__axis"
            x1="10"
            y1="238"
            x2="10"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="38"
            y1="238"
            x2="38"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="66"
            y1="238"
            x2="66"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="94"
            y1="238"
            x2="94"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="122"
            y1="238"
            x2="122"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="150"
            y1="238"
            x2="150"
            y2="248"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="178"
            y1="238"
            x2="178"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="206"
            y1="238"
            x2="206"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="234"
            y1="238"
            x2="234"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="262"
            y1="238"
            x2="262"
            y2="243.5"
            stroke="#C0C5C7"
          />
          <line
            class="drag-container__axis"
            x1="290"
            y1="238"
            x2="290"
            y2="243.5"
            stroke="#C0C5C7"
          />
        </svg>
        <div class="drag-container__elements">
          <ds-drag-element
            v-for="(dragElement, idx) in dragElements"
            :id="dragElement.optionId.toString()"
            :ref="dragElement.optionId"
            :key="dragElement.optionId"
            class="slider"
            :slider-x="true"
            :initial-top="dragElement.initialTop"
            :initial-left="dragElement.initialLeft"
            :initial-radius="50"
            :min-y="minY"
            :min-x="minX"
            :max-x="maxX"
            :max-y="maxY"
            @endPosition="finalPosition($event, idx)"
          >
            {{ dragElement.name }}
          </ds-drag-element>
        </div>
      </div>
      <div class="drag-container__slider-titles">
        <div class="drag-container__axis-title">
          {{ minXLabel }}
        </div>
        <div class="drag-container__axis-title">
          {{ maxXLabel }}
        </div>
      </div>
    </div>

    <div
      class="drag-container__submit"
    >
      <cv-button
        v-if="isSurveyPreview && priority > 0"
        kind="secondary"
        class="st-question__preview-back-btn"
        @click="handlePreviewBack"
      >
        <div class="st-page__btn-slot">
          <div class="st-svg__left-chevron" />
          <div>Back</div>
        </div>
      </cv-button>
      <cv-button
        v-if="submit"
        class="next-btn"
        @click="submitQuestionAnswer"
      >
        <div>
          Next
          <div class="btn-icon" />
        </div>
      </cv-button>
    </div>
  </div>
</template>

<script>
import DragElement from '../../common/drag-element'
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import previousResponse from '../../../mixins/previousResponse.vue'
import submitQuestionAnswer from '../../../mixins/question/submitQuestionAnswer.vue'
import handlePreviewBack from '../../../mixins/handlePreviewBack.vue'

export default {
  components: {
    'ds-drag-element': DragElement,
    'cv-button': CvButton
  },
  mixins: [submitQuestionAnswer, handlePreviewBack, previousResponse],
  props: {
    options: {
      type: Array,
      required: true
    },
    minXLabel: {
      default: 'x-measure 1',
      type: String,
      required: false
    },
    maxXLabel: {
      default: 'x-measure 2',
      type: String,
      required: false
    },
    questionId: {
      type: Number,
      required: true
    },
    priority: {
      type: Number,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    let top = 120
    let left = [80, 213]
    if (this.questionResponses && this.questionResponses.length > 0) {
      top = 185
    }
    return {
      dragElements: this.options.map((x, n) => {
        return {
          name: x.name,
          optionId: x.optionId,
          priority: x.priority,
          placed: false,
          initialTop: top,
          initialLeft: left[n],
          x: null,
          xScore: null,
          y: null,
          yScore: null
        }
      }),
      minX: -40, // -40
      maxX: 340, // 340
      minY: 135, // 135
      maxY: 135 // 135
    }
  },
  computed: {
    submit: function () {
      if (this.isSurveyPreview) { return true }
      let placedItems = 0
      this.dragElements.forEach(x => {
        if (x.placed) {
          placedItems++
        }
      })
      if (placedItems === this.dragElements.length) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
  },
  methods: {
    loadResponses () {
      if (this.questionResponses && this.questionResponses.length > 0) {
        this.$nextTick(() => {
          // match response to option
          let dragElementsOptionIds = this.dragElements.map(x => x.optionId)
          this.questionResponses.forEach(resp => {
            this.$refs[resp.questionOptionId][0].updateX(Number(resp.value) * 280 + 10)
            let dragIdx = dragElementsOptionIds.indexOf(resp.questionOptionId)
            this.dragElements[dragIdx].placed = true
            this.dragElements[dragIdx].xScore = resp.value
          })
        })
      }
    },
    answerResponse () {
      let answer = { options: {} }
      this.dragElements.forEach(x => {
        answer.options[x.optionId] = x.xScore
      })

      return answer
    },
    finalPosition (data, idx) {
      this.dragElements[idx].x = data.x
      this.dragElements[idx].y = data.y
      this.dragElements[idx].yScore = this.axisScore(data.y, this.minY, this.maxY, idx) * -1
      this.dragElements[idx].xScore = this.axisScore(data.x, 10, 290, idx)
      this.dragElements[idx].placed = data.placed
    },
    axisScore (val, min, max, idx) {
      if (val < min + 1) {
        return 0
      } else if (val > max - 1) {
        return 1
      } else {
        let distFromMin = val - min
        let totalDistance = max - min
        let score = distFromMin / totalDistance
        return score
      }
    }
  }
}
</script>
