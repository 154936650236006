<template>
  <div class="icon_exportlist">
    <div class="icon_exportlist__svg">
      <svg
        viewBox="0 0 16 16.002"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0-.5h7v1H0z"
            transform="translate(8.999 6.5)"
          />
          <path
            d="M0-.5h7v1H0z"
            transform="translate(8.999 10.5)"
          />
          <path
            d="M0-.5h7v1H0z"
            transform="translate(8.999 14.5)"
          />
          <path
            d="M3 1.5A1.5 1.5 0 111.5 0 1.5 1.5 0 013 1.5zm-1 0a.5.5 0 10-.5.5.5.5 0 00.5-.5z"
            transform="translate(5 5)"
          />
          <path
            d="M3 1.5A1.5 1.5 0 111.5 0 1.5 1.5 0 013 1.5zm-1 0a.5.5 0 10-.5.5.5.5 0 00.5-.5z"
            transform="translate(5 9)"
          />
          <path
            d="M3 1.5A1.5 1.5 0 111.5 0 1.5 1.5 0 013 1.5zm-1 0a.5.5 0 10-.5.5.5.5 0 00.5-.5z"
            transform="translate(5 13)"
          />
          <path
            d="M4.1.8L1.6 2.9 4.1 5l-.6.8L0 2.9 3.5 0z"
            transform="rotate(90 2.898 2.898)"
          />
          <path
            d="M15 1H0V0h15z"
            transform="rotate(90 1.198 2.2)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
