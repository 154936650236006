<template>
  <div>
    <new-question
      v-if="canEdit && !published"
      :active-question-id="activeQuestionId"
      :initial-questions="initialQuestions"
      :metric-image-paths="metricImagePaths"
      :survey-id="surveyId"
      :name="name"
      :folders="folders"
    />
    <template-preview
      v-else
      :template="surveyPreviewObject"
      :is-survey="true"
    />
  </div>
</template>

<script>
import TemplatePreview from './template-preview'
import NewQuestion from './new-question'
import { mapMutations } from 'vuex'

export default {
  components: {
    'new-question': NewQuestion,
    'template-preview': TemplatePreview
  },
  props: {
    accountId: {
      type: Number,
      required: true
    },
    activeQuestionId: {
      type: Number,
      required: false,
      default: -1
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    dummyAudioPath: {
      type: String,
      required: true
    },
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    initialQuestions: {
      default () {
        return []
      },
      type: Array,
      required: false
    },
    metricImagePaths: {
      type: Array,
      required: false,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    published: {
      type: Boolean,
      required: true
    },
    surveyId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      surveyMembers: [],
      questions: this.initialQuestions
    }
  },
  computed: {
    surveyPreviewObject () {
      return {
        accountId: this.accountId,
        surveyId: this.surveyId,
        name: this.name,
        questions: this.questions
      }
    }
  },
  mounted () {
    this.setDummyAudioPath(this.dummyAudioPath)
  },
  created () {
    this.setSurveyType('specificationsPreview')
  },
  methods: {
    ...mapMutations('participants', ['setSurveyType']),
    ...mapMutations('specifications', ['setDummyAudioPath'])
  }
}
</script>
