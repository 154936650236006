<template>
  <div>
    <div
      class="st-virtual-tour-insights__overall-sentiment-block"
      :class="sentimentClass"
    >
      <div class="st-virtual-tour-insights__overall-sentiment-label">
        Average sentiment for all rooms:
      </div>
      <div class="st-virtual-tour-insights__overall-sentiment">
        {{ overallSentimentString }}
      </div>
    </div>
    <diverging-bar-graph
      :key="`all-rooms-${activeQuestion.questionId}`"
      :sentiment-values="sentimentScores"
      class="st-virtual-tour-insights__diverging-bar-graph"
    />
    <div
      v-if="topicsWithResults.length > 0"
      class="st-virtual-tour-insights__section-title"
    >
      Sentiment per Topic
    </div>
    <grouped-keyword-sentiments
      v-for="topic in topicsWithResults"
      :key="topic"
      class="insights__question-insights"
      expand-result-option="openTourMode"
      :responses="responsesByTopic[topic]"
      :title="topic"
      :total-participants="totalResponseCount"
      :identifier="topic"
      :include-sentence="(sentence, identifier) => sentence.topics.has(identifier)"
    />
    <div class="st-virtual-tour-insights__section-title">
      Sentiment per Room
    </div>
    <grouped-keyword-sentiments
      v-for="roomName in sortedRoomNames"
      :key="roomName"
      class="insights__question-insights"
      expand-result-option="openTranscript"
      :responses="responsesByRoom[roomIdsByName[roomName]]"
      :title="roomName"
      :show-prompts-section="true"
      :total-participants="totalResponseCount"
      :identifier="roomIdsByName[roomName]"
      :include-sentence="(sentence, identifier) => sentence.roomIds.has(identifier)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { kebabCase } from 'lodash'

import { average, bucketIdx, sum } from '../../../../utilities/data_utilities'

import GroupedKeywordSentiments from '../grouped-keyword-sentiments'
import DivergingBarGraph from '../diverging-bar-graph'

const BADGE_CAPTIONS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

export default {
  components: {
    'diverging-bar-graph': DivergingBarGraph,
    'grouped-keyword-sentiments': GroupedKeywordSentiments
  },
  props: {
  },
  data: function () {
    return {
      allRooms: true
    }
  },
  computed: {
    ...mapGetters('insights', [
      'activeQuestion',
      'responsesByRoom',
      'responsesByTopic',
      'responsesPassingParticipantCheckboxFilter',
      'rooms',
      'roomSentiments',
      'topics'
    ]),
    allTranscriptionSeconds () {
      return this.responsesPassingParticipantCheckboxFilter.map(response => response.transcriptionTotalSeconds)
    },
    averageTranscriptionSeconds () {
      return average(this.allTranscriptionSeconds)
    },
    overallSentiment () {
      return average(this.sentimentScores)
    },
    overallSentimentString () {
      return BADGE_CAPTIONS[bucketIdx(this.overallSentiment)]
    },
    sentimentClass () {
      return `st-sentiment-badge--${kebabCase(this.overallSentimentString)}`
    },
    roomNamesById () {
      return this.rooms.reduce(
        (roomDict, room) => {
          roomDict[room.id] = room.name
          return roomDict
        },
        {}
      )
    },
    roomIdsByName () {
      return this.rooms.reduce(
        (roomDict, room) => {
          roomDict[room.name] = room.id
          return roomDict
        },
        {}
      )
    },
    sortedRoomNames () {
      let rooms = []
      this.rooms.forEach(room => {
        if (this.roomSentiments[room.id]) {
          rooms.push({ name: room.name, sentiment: average(this.roomSentiments[room.id]) })
        }
      })
      return rooms.sort((a, b) => b.sentiment - a.sentiment).map(room => room.name)
    },
    sentimentScores () {
      return this.responsesPassingParticipantCheckboxFilter.map(response => response.sentimentScore)
    },
    topicsWithResults () {
      return this.topics.filter(topic => this.responsesByTopic[topic] && this.responsesByTopic[topic].length > 0)
    },
    totalResponseCount () {
      return this.sentimentScores.length
    },
    totalTranscriptionSeconds () {
      return sum(this.allTranscriptionSeconds)
    }
  },
  mounted: function () {
    // set modifier to percent
    this.$store.commit('insights/replaceModifier', 'percentage')
  },
  methods: {
  }
}
</script>
