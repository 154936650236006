<template>
  <div
    :class="{
      'space-between st-emoji-scale': true,
      'space-around': max === 3,
    }"
  >
    <div
      v-for="(emoji, index) in scale"
      :key="index"
      :class="{
        'st-emoji-scale__emoji-and-label': true,
        'st-emoji-scale__emoji-and-label--active': value === index + 1,
      }"
      @click="$emit('input', index + 1)"
    >
      <icon-wrapper
        :icon-name="emoji"
        :class="{
          'st-emoji-scale__emoji': true,
          'st-emoji-scale__emoji--active': value === index + 1,
        }"
        :actionable="false"
        :spacetrics-blue="value === index + 1"
      />
      <p
        :class="{
          'st-emoji-scale__label': true,
          'st-emoji-scale__label--active': value === index + 1,
        }"
      >
        {{ getEmojiLabel(emoji) }}
      </p>
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash'
import IconWrapper from './icon-wrapper'

export default {
  components: {
    IconWrapper
  },
  props: {
    max: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      default: 0,
      required: false
    },
    value: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    scale () {
      return this.max === 3
        ? [ 'emoji-hate-it', 'emoji-indifferent', 'emoji-love-it' ]
        : [ 'emoji-hate-it', 'emoji-dislike', 'emoji-indifferent', 'emoji-like-it', 'emoji-love-it' ]
    }
  },
  methods: {
    getEmojiLabel (emojiScaleValue) {
      return startCase(emojiScaleValue.substring(6))
    }
  }
}
</script>
