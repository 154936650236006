<script>
// import { Notification } from 'carbon-components'

const kinds = ['error', 'warning', 'info', 'success']

export default {
  props: {
    kind: {
      type: String,
      required: true,
      validator: value => kinds.includes(value)
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    isAlert () {
      return kinds.indexOf(this.kind) < 2
    }
  }
}
</script>
