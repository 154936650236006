var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-dropdown-multiselect': true,
    'st-dropdown-multiselect--open': _vm.open
  },style:({
    width: _vm.minWidth && _vm.mobileMinWidth
  }),attrs:{"tabindex":_vm.tabIndex}},[(_vm.open)?_c('div',{staticClass:"st-dropdown-multiselect__mobile-opacitor",on:{"click":function($event){_vm.open = false}}}):_vm._e(),_vm._v(" "),(_vm.showLabelAbove)?_c('div',{class:[{
      'st-dropdown-multiselect__label': true,
      'st-dropdown-multiselect__label--select-multiple': _vm.selectMultiple,
      labelClass: _vm.labelClass
    }, _vm.labelVariantClasses]},[[_vm._v("\n      "+_vm._s(_vm.caption)+"\n    ")],_vm._v(" "),(_vm.selectMultiple && !_vm.isDesktop)?_c('p',{staticClass:"st-dropdown-multiselect__amount-selected"},[_vm._v("\n      ("+_vm._s(_vm.selectedOptions.length)+"\n      "),_c('icon-wrapper',{staticClass:"st-dropdown-multiselect__amount-selected--icon",attrs:{"icon-name":"plus","spacetrics-blue":true}}),_vm._v("\n      )\n    ")],1):_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{ref:"upperDiv",class:[{
      'st-dropdown-multiselect__align-chosen': true,
      'st-dropdown-multiselect__align-chosen--overlapping-options': _vm.applyBottomMargin,
      'st-dropdown-multiselect__align-chosen--menu-open': _vm.open,
      'st-dropdown-multiselect__disabled': _vm.disabled,
    }, _vm.inputContainerClasses],style:({
      width: _vm.minWidth && _vm.mobileMinWidth
    }),on:{"click":_vm.checkIfDisabled}},[_c('input',{ref:"chosen",class:_vm.inputClasses,attrs:{"value":_vm.dropdownValue,"type":"button"}}),_vm._v(" "),(_vm.open)?_c('div',{staticClass:"st-dropdown-multiselect__placeholder"},[_vm._v("\n      "+_vm._s(_vm.caption)+"\n    ")]):_vm._e(),_vm._v(" "),_c('icon-wrapper',{class:{
        'st-dropdown-multiselect__icon': true,
        'st-dropdown-multiselect__icon--open': _vm.open
      },attrs:{"icon-name":_vm.checkIconName}})],1),_vm._v(" "),(_vm.open)?_c('div',{ref:"lowerDiv",staticClass:"st-dropdown-multiselect__options-container"},[_c('div',{staticClass:"st-dropdown-multiselect__header"},[_c('div',{style:({display: 'flex'})},[_c('div',{staticClass:"st-dropdown-multiselect__chevrons",on:{"click":function($event){return _vm.updateIndex('prev')}}},[_c('icon-wrapper',{class:{
              'st-dropdown-multiselect__chevron-left': true,
              'st-dropdown-multiselect__chevron-left--active': _vm.activeIndex !== 0
            },attrs:{"icon-name":"chevron"}})],1),_vm._v(" "),_c('div',{staticClass:"st-dropdown-multiselect__chevrons",on:{"click":function($event){return _vm.updateIndex('next')}}},[_c('icon-wrapper',{class:{
              'st-dropdown-multiselect__chevron-right': true,
              'st-dropdown-multiselect__chevron-right--active': _vm.activeIndex !== _vm.options.length-1
            },attrs:{"icon-name":"chevron"},on:{"click":function($event){return _vm.updateIndex('next')}}})],1)]),_vm._v(" "),_c('p',{staticClass:"text-blue",on:{"click":function($event){_vm.open = false}}},[_vm._v("\n        Done "+_vm._s(_vm.selectMultiple ? ("(" + (_vm.selectedOptions.length) + " selected)") : '')+"\n      ")])]),_vm._v(" "),(_vm.selectMultiple)?[_c('div',{staticClass:"st-dropdown-multiselect__options-scroll"},_vm._l((_vm.optionsData),function(option,idx){return _c('st-checkbox',{key:'option ' + idx,staticClass:"st-dropdown-multiselect__multiple-select-option",attrs:{"checked":option.selected},on:{"input":function($event){return _vm.updateMultiple(idx)}}},[_vm._v("\n          "+_vm._s(option.name)+"\n        ")])}),1)]:_vm._l((_vm.inactiveOptions),function(option,index){return _c('div',{key:option,class:{
          'st-dropdown-multiselect__options': true,
          'st-dropdown-multiselect__options--selected': option===_vm.dropdownValue
        },on:{"click":function($event){_vm.updateSingle(index); _vm.open = false}}},[_vm._v("\n        "+_vm._s(option)+"\n      ")])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }