<template>
  <div class="icon_pdf-file">
    <div class="icon_pdf-file__svg">
      <svg
        viewBox="0 0 16.5 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(.5)">
          <path
            d="M0 0h9.881v1H0z"
            transform="translate(5.995 15)"
          />
          <path
            d="M0 0h8v1H0z"
            transform="translate(3.989)"
          />
          <path
            d="M0 0h1v12H0z"
            transform="translate(14.989 4)"
          />
          <path
            d="M0 0h1v6H0z"
            transform="translate(3.989)"
          />
          <path
            d="M0 0h1v5H0z"
            transform="translate(10.991)"
          />
          <path
            d="M0 0h5v1H0z"
            transform="translate(10.991 4)"
          />
          <path
            d="M-21146.123-10161.846l4 4-.707.707-4-4z"
            transform="translate(21158.121 10161.852)"
          />
          <path
            d="M.355.37v-4.734h1.792a1.969 1.969 0 011.2.322 1.173 1.173 0 01.429 1v.633a1.117 1.117 0 01-.426.967 2.022 2.022 0 01-1.207.311H1.36V.37zM1.36-1.9h.734a.86.86 0 00.515-.124.546.546 0 00.166-.461v-.5a.571.571 0 00-.166-.476.845.845 0 00-.515-.127H1.36zM4.5.37v-4.734h1.635a1.755 1.755 0 011.3.47A1.691 1.691 0 017.9-2.642v1.313a1.654 1.654 0 01-.47 1.237 1.783 1.783 0 01-1.3.461zm1-.781h.544A.807.807 0 006.7-.648a1.2 1.2 0 00.2-.763v-1.148a1.249 1.249 0 00-.2-.778.792.792 0 00-.657-.246H5.5zM8.845.37v-4.734h3.284v.781H9.851V-2.2h1.864v.781H9.851V.37z"
            transform="translate(-.354 12.363)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
