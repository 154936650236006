<script>
// Intended to be used within a question specifications component

export default {
  methods: {
    additionalProps () {
      let i = this.highestOptionPriority
      return {
        'question_option[question_id]': this.activeQuestion.id,
        'question_option[priority]': () => { i++; return i }
      }
    }
  }
}
</script>
