var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-virtual-tour__inner-wrapper"},[_c('prompt',{attrs:{"is-demo-preview":_vm.isDemoPreview,"is-specifications-preview":_vm.isSpecificationsPreview},on:{"openTopicList":function($event){return _vm.$emit('open-topic-list')}}}),_vm._v(" "),_c('microphone',{attrs:{"audio-stream":_vm.audioStream,"is-demo-preview":_vm.isDemoPreview,"is-specifications-preview":_vm.isSpecificationsPreview}}),_vm._v(" "),(!_vm.isDemoPreview && !_vm.isSpecificationsPreview)?_c('a',{staticClass:"st-virtual-tour__header-icon",attrs:{"href":"#"},on:{"click":_vm.$goBack}},[_c('icon-wrapper',{staticClass:"st-hyperlink__back-icon",attrs:{"icon-name":"chevron"}})],1):_vm._e(),_vm._v(" "),(_vm.isSpecificationsPreview)?_c('div',{class:{
      'st-virtual-tour__preview-container': true,
      'st-virtual-tour__preview-container--empty': !_vm.activeRoom
    }},[(_vm.activePanoIndex >= 0)?_c('p',{staticClass:"st-virtual-tour__preview-text"},[_vm._v("\n      Currently Previewing:\n    ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"st-virtual-tour__preview-text"},[_vm._v("\n      "+_vm._s(_vm.activeText)+"\n    ")])]):_vm._e(),_vm._v(" "),(!_vm.isDemoPreview)?_c('div',{class:{ 'st-virtual-tour__exit-button-group': true, 'st-virtual-tour__exit-button-group--specs-preview': _vm.isSpecificationsPreview },on:{"click":_vm.openExitPrompt}},[_c('st-tooltip',{staticClass:"st-virtual-tour__exit-tooltip",attrs:{"direction":"left","message-class":"st-virtual-tour__exit-tooltip-message","tip":"Preview only button","is-active":_vm.isSpecificationsPreview}},[_c('div',{class:{ 'st-virtual-tour__exit-button': true, 'st-virtual-tour__exit-button--specs-preview': _vm.isSpecificationsPreview }},[_c('icon-wrapper',{attrs:{"icon-name":"upload"}})],1)]),_vm._v(" "),_c('div',{class:{ 'st-virtual-tour__exit-text': true, 'st-virtual-tour__exit-text--specs-preview': _vm.isSpecificationsPreview }},[_vm._v("\n      Exit\n    ")])],1):_vm._e(),_vm._v(" "),(_vm.topics.length > 0 || _vm.isSpecificationsPreview)?_c('div',{class:{
      'st-virtual-tour__question-list-button': true,
      'st-virtual-tour__question-list-button--specs-preview': _vm.isSpecificationsPreview,
      'st-virtual-tour__question-list-button--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
    },on:{"click":function($event){return _vm.$emit('open-topic-list')}}},[_c('icon-wrapper',{class:{
        'st-virtual-tour__question-list-icon--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
      },attrs:{"icon-name":"opinion-metric"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }