<template>
  <div class="bx--row st-reset-password">
    <div class="bx--col-xs-10 bx--offset-xs-1 bx--col-sm-6 bx--offset-sm-3">
      <div class="st-reset-password__header-row">
        <h1>Forgot password</h1>
      </div>

      <p class="st-reset-password__form-text">
        Enter your email to receive a password reset link.
      </p>

      <form
        id="new_user"
        action="/users/password"
        accept-charset="UTF-8"
        method="post"
        class="new_user"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="token"
        >
        <st-input
          v-model="email"
          label="Email"
          name="user[email]"
          theme="light"
          placeholder="Your email goes here"
        />
        <slot />

        <div class="st-reset-password__submit-btn">
          <st-button
            type="submit"
            caption="Submit"
            icon-name="arrow-up"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  StInput,
  StButton
} from '../../common'

export default {
  components: {
    StInput,
    StButton
  },
  props: {
  },
  data: function () {
    return {
      email: ''
    }
  },
  computed: {
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
  }
}
</script>
