var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tourContainer",staticClass:"st-virtual-tour-specs__wrapper"},[(_vm.showFloorPlan)?_c('div',{staticClass:"st-virtual-tour-specs__zoom-buttons-wrapper"},[_c('button',{staticClass:"st-virtual-tour-specs__zoom-button",attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.zoomFloorplanIn.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.stopZooming.apply(null, arguments)},"mousedown":_vm.zoomFloorplanIn,"mouseout":_vm.stopZooming,"mouseup":_vm.stopZooming}},[_c('icon-wrapper',{staticClass:"st-virtual-tour-specs__zoom-button-icon",attrs:{"icon-name":"plus","invert":true}})],1),_vm._v(" "),_c('button',{class:{
        'st-virtual-tour-specs__zoom-button': true,
        'st-virtual-tour-specs__zoom-button--disabled': _vm.disableZoomOut
      },attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.zoomFloorplanOut.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.stopZooming.apply(null, arguments)},"mousedown":_vm.zoomFloorplanOut,"mouseout":_vm.stopZooming,"mouseup":_vm.stopZooming}},[_c('icon-wrapper',{staticClass:"st-virtual-tour-specs__zoom-button-icon",attrs:{"icon-name":"minus","actionable":!_vm.disableZoomOut,"invert":true}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"st-virtual-tour-specs__image-wrapper",on:{"mousedown":_vm.startDragSelect,"mousemove":_vm.handleMouseMove,"mouseup":_vm.endDragSelect}},[(_vm.showFloorPlan)?_c('img',{ref:"floorPlan",staticClass:"st-virtual-tour-specs__floorplan",style:(_vm.floorPlanStyle),attrs:{"src":_vm.floorplanSrc,"alt":"Virtual Tour Floor Plan"}}):_c('matterport-iframe',{ref:"tour",style:(_vm.iframeStyle),attrs:{"matterport-id":_vm.matterportId,"show-floorplan-icon":_vm.floorplan},on:{"matterport-showcase-loaded":_vm.handleShowcaseLoad}}),_vm._v(" "),(_vm.floorplan && _vm.floorplanSrc)?[_c('div',{staticClass:"st-virtual-tour-specs__drag-select-box",style:(_vm.dragSelectBoxStyle)}),_vm._v(" "),_vm._l((_vm.assignedViewpoints),function(point){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(point.top != null),expression:"point.top != null"}],key:'pano-' + _vm.virtualTourId + point.uuid,staticClass:"st-virtual-tour-specs__room-dot map-dot",class:{
          'active-dot': _vm.activeIdentifiers.includes(point.uuid)
        },style:({
          position: 'absolute',
          top: _vm.topCss(point.top),
          left: _vm.leftCss(point.left)
        }),on:{"click":function($event){return _vm.selectSweep(point.uuid)}}})}),_vm._v(" "),_vm._l((_vm.unassignedViewpoints),function(point){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(point.top != null),expression:"point.top != null"}],key:'pano-' + _vm.virtualTourId + point.uuid,staticClass:"st-virtual-tour-specs__room-dot map-dot unassigned-dot",class:{
          'active-dot': _vm.activeIdentifiers.includes(point.uuid)
        },style:({
          position: 'absolute',
          top: _vm.topCss(point.top),
          left: _vm.leftCss(point.left)
        }),on:{"click":function($event){return _vm.selectSweep(point.uuid)}}})})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }