<template>
  <div
    class="st-autocomplete"
    tabindex="0"
    @focus="addFocus"
    @blur="onFocusOut"
  >
    <template v-if="showCreateStage">
      <p
        class="st-autocomplete__cancel"
        @click="showCreateStage = false"
      >
        Cancel
      </p>
      <button
        class="st-autocomplete__create-button"
        @click="checkOptionToCreate"
      >
        Create
      </button>
    </template>
    <template v-if="isSeparateCreate">
      <icon-wrapper
        :class="{
          'st-autocomplete__caret': true,
          'st-autocomplete__caret--focused': focused
        }"
        :icon-name="iconName"
        :spacetrics-blue="focused"
      />
    </template>
    <st-input
      ref="textInput"
      v-model="text"
      :artificial-focus="focused"
      autocomplete="off"
      :icon-name="optionIconName"
      :input-class="showCreateStage ? 'st-autocomplete__input-show-create-stage' : ''"
      :label="label"
      :placeholder="placeholder"
      :show-icon="showInputIcon"
      @input="unselectOption"
      @focus="focused = true"
      @focusout="onFocusOut"
    />
    <div
      v-if="isMatchListVisible"
      :class="{
        'st-autocomplete__popup': true,
        'st-autocomplete__popup--show-create-stage': showCreateStage && visibleMatchableCaptions.length === 0
      }"
      @focusout="onFocusOut"
    >
      <ul
        v-if="anyVisibleMatches"
        class="st-autocomplete__list"
      >
        <li
          v-for="caption in visibleMatchableCaptions"
          :key="caption"
          class="st-autocomplete__option"
          @click="selectCaption(caption)"
        >
          <icon-wrapper
            v-if="optionIconName !== ''"
            :icon-name="optionIconName"
            class="st-autocomplete__option-icon"
          />
          <span>{{ caption }}</span>
        </li>
      </ul>
      <template v-if="isCreateVisible">
        <hr
          v-if="anyVisibleMatches"
          noshade
          class="st-autocomplete__create-line"
        >
        <a
          v-if="!matchableList.includes(text) || allowDuplicates"
          class="st-autocomplete__create-link"
          @click="checkOptionToCreate"
        >
          <icon-wrapper
            icon-name="plus"
            class="st-autocomplete__create-link-icon"
          />
          <span>{{ createMessage }}</span>
          <icon-wrapper
            v-if="createCaptionIconName.length>0"
            :icon-name="createCaptionIconName"
            class="st-autocomplete__create-icon"
          />
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'
import StInput from './st-input'

export default {
  components: { IconWrapper, StInput },
  props: {
    /**
     * allow duplicate entries in matchable list
     */
    allowDuplicates: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Array of options that are considered "matchable"
     */
    initialMatchableList: {
      type: Array,
      required: true
    },
    /**
     * Initial value. Expected to be the same as the objects in matchable list
     */
    initialValue: {
      type: Object,
      required: false,
      default: () => null
    },
    /**
     * How to get the caption to display for an option.
     * By default it's just the option
     */
    captionFn: {
      type: Function,
      required: false,
      default: option => option
    },
    /**
     * What to do when user clicks "create".
     * Expected to return a promise.
     * promise parameter is expected to be the same as the objects in matchable list
     * If not present 'Create ...' will not be shown
     */
    createFn: {
      type: Function,
      required: false,
      default: null
    },
    /** text to add to "Create '' ..." */
    createCaption: {
      type: String,
      required: false,
      default: ''
    },
    /** icon next to create caption text */
    createCaptionIconName: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Name of input field.
     */
    label: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * Example text of input field.
       */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    /** Icon to include before each option listing */
    optionIconName: {
      type: String,
      required: false,
      default: ''
    },
    /** Can be used to change the kind of autoComplete (ex: separateCreate) */
    variant: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    let data = {
      focused: false,
      isOptionSelected: false,
      matchableList: [...this.initialMatchableList],
      showCreateStage: false,
      text: ''
    }
    if (this.initialValue) {
      data = {
        ...data,
        isOptionSelected: true,
        text: this.captionFn(this.initialValue)
      }
    }
    return data
  },
  computed: {
    createMessage () {
      if (this.isSeparateCreate) { return `Create ${this.createCaption}` }
      return `Create '${this.text}' ${this.createCaption}`
    },
    iconName () {
      if (this.focused) { return 'caret-up' }
      return 'caret-down'
    },
    isSeparateCreate () {
      return this.variant.includes('separateCreate')
    },
    matchableCaptions () {
      return this.matchableList.map(this.captionFn)
    },
    showInputIcon () {
      if (this.isSeparateCreate) { return this.isOptionSelected }
      return false
    },
    visibleMatchableCaptions () {
      if (this.text === '') { return this.matchableCaptions }

      let searchString = this.text.toLowerCase()
      return this.matchableCaptions.filter(option => {
        const regex = new RegExp(searchString, 'gi')
        return option.match(regex)
      })
    },
    isMatchListVisible () {
      return this.focused && !this.isOptionSelected
    },
    anyVisibleMatches () {
      return this.visibleMatchableCaptions.length > 0
    },
    isCreateVisible () {
      if (this.isSeparateCreate) { return this.focused && !this.showCreateStage }
      return this.createFn && this.text.length > 0
    }
  },
  methods: {
    selectCaption (optionCaption) {
      let option = this.matchableList.find(option => this.captionFn(option) === optionCaption)
      this.selectOption(option)
    },
    selectOption (option) {
      /** select new option */
      this.$emit('selectOption', option)
      this.text = this.captionFn(option)
      this.isOptionSelected = true
    },
    selectOptionWithoutEmitting (text) {
      this.text = text
      this.isOptionSelected = true
    },
    unselectOption () {
      if (this.isOptionSelected) {
        this.isOptionSelected = false
        /** mark all options as not selected */
        this.$emit('unselectOption', {})
      }
    },
    addFocus (a) {
      this.focused = true
      this.$refs.textInput.focus()
    },
    onFocusOut (ev) {
      if (this.$el.contains(ev.relatedTarget)) { return }
      this.matchableList.some(list => list.name === this.text)
        ? this.selectCaption(this.text) : this.text = ''

      if (this.isSeparateCreate) { this.showCreateStage = false }
      this.focused = false
    },
    checkOptionToCreate () {
      if (this.variant.includes('separateCreate') && !this.showCreateStage) {
        this.showCreateStage = true
      } else if (!this.text) {
        this.$emit('failure')
      } else if (this.matchableList.some(list => list.name === this.text)) {
        this.showCreateStage = false
        this.$emit('failure', 'duplicate')
        this.selectCaption(this.text)
      } else {
        this.createNewOption()
          .then(res => {
            if (this.variant.includes('separateCreate')) {
              this.showCreateStage = false
              this.$emit('success')
            }
          })
          .catch(err => { console.log(err) })
      }
    },
    createNewOption () {
      return this.createFn(this.text).then(newListItem => {
        this.matchableList.push(newListItem)
        this.selectOption(newListItem)
      })
    }
  }
}
</script>
