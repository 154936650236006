var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-text-area': true,
    'st-text-area__focused': _vm.textAreaFocused
  },on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover= false}}},[_c('transition',{attrs:{"name":"fade"}},[((_vm.textAreaFocused && _vm.showLabel) || (_vm.value && _vm.hover))?_c('label',{staticClass:"st-text-area__show-label-above",attrs:{"id":_vm.stUid,"for":"st-text-area"}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"st-text-area__align-content"},[(!_vm.textAreaFocused && !_vm.value && _vm.showLabel)?_c('label',{class:[{
        'st-text-area__show-label-in-field': true,
        'st-text-area__show-label-in-field--disabled': _vm.disabled
      }, _vm.labelSizeClass],attrs:{"id":_vm.stUid,"for":"st-text-area"},on:{"click":function($event){return _vm.reFocusTextArea()}}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e(),_vm._v(" "),_c('textarea',_vm._g({ref:"stTextArea",class:{
        'st-text-area__field': true,
        'st-text-area__field--toggle-mobile-on': _vm.textAreaFocused,
        'st-text-area__label': _vm.textAreaFocused && _vm.label,
        'st-text-area__field--disabled': _vm.disabled
      },attrs:{"id":_vm.stUid,"rows":"1","cols":"1","disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter.apply(null, arguments)}}},_vm.inputListeners))]),_vm._v(" "),(_vm.button && _vm.hasFocus)?_c('button',{staticClass:"st-text-area__button",on:{"click":function($event){_vm.hasFocus = false}}},[_c('icon-wrapper',{attrs:{"icon-name":"check-mark","invert":true}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }