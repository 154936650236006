<script>
export default {
  methods: {
    // returns a promise with the response after creating an asset based off the given file
    createAsset (file, filename = '') {
      let assetData = new FormData()
      assetData.append('asset[media]', file, filename)
      return this.$axios.request({
        method: 'post',
        url: 'assets',
        data: assetData
      })
    },
    deleteAsset (id) {
      return this.$axios.request({
        method: 'delete',
        url: `assets/${id}`
      })
    }
  }
}
</script>
