<script>
export default {
  props: {
    spacetricsLogo: {
      type: String,
      required: false,
      default: ''
    },
    isJoinSurvey: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    companyLogoStyle () {
      let companyLogoUrl = this.companyLogo
      let companyLogoStyleObject = {}
      if (companyLogoUrl && !this.isJoinSurvey) {
        companyLogoStyleObject['background'] = 'center / cover no-repeat'
        companyLogoStyleObject['background-image'] = `url('${companyLogoUrl}')`
      } else if (this.isJoinSurvey) {
        companyLogoStyleObject['background'] = 'center / cover no-repeat'
        companyLogoStyleObject['background-image'] = `url('${this.spacetricsLogo}')`
      } else {
        companyLogoStyleObject['background'] = '#EFEFEF'
        companyLogoStyleObject['border'] = '2px solid #FFFFFF'
      }
      return companyLogoStyleObject
    }
  }
}
</script>
