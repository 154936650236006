<template>
  <div class="st-virtual-tour__inner-wrapper">
    <prompt
      :is-demo-preview="isDemoPreview"
      :is-specifications-preview="isSpecificationsPreview"
      @openTopicList="$emit('open-topic-list')"
    />
    <microphone
      :audio-stream="audioStream"
      :is-demo-preview="isDemoPreview"
      :is-specifications-preview="isSpecificationsPreview"
    />
    <a
      v-if="!isDemoPreview && !isSpecificationsPreview"
      href="#"
      class="st-virtual-tour__header-icon"
      @click="$goBack"
    >
      <icon-wrapper
        class="st-hyperlink__back-icon"
        icon-name="chevron"
      />
    </a>
    <div
      v-if="isSpecificationsPreview"
      :class="{
        'st-virtual-tour__preview-container': true,
        'st-virtual-tour__preview-container--empty': !activeRoom
      }"
    >
      <p
        v-if="activePanoIndex >= 0"
        class="st-virtual-tour__preview-text"
      >
        Currently Previewing:
      </p>
      <p class="st-virtual-tour__preview-text">
        {{ activeText }}
      </p>
    </div>
    <div
      v-if="!isDemoPreview"
      :class="{ 'st-virtual-tour__exit-button-group': true, 'st-virtual-tour__exit-button-group--specs-preview': isSpecificationsPreview }"
      @click="openExitPrompt"
    >
      <st-tooltip
        class="st-virtual-tour__exit-tooltip"
        direction="left"
        message-class="st-virtual-tour__exit-tooltip-message"
        tip="Preview only button"
        :is-active="isSpecificationsPreview"
      >
        <div :class="{ 'st-virtual-tour__exit-button': true, 'st-virtual-tour__exit-button--specs-preview': isSpecificationsPreview }">
          <icon-wrapper icon-name="upload" />
        </div>
      </st-tooltip>
      <div :class="{ 'st-virtual-tour__exit-text': true, 'st-virtual-tour__exit-text--specs-preview': isSpecificationsPreview }">
        Exit
      </div>
    </div>
    <div
      v-if="topics.length > 0 || isSpecificationsPreview"
      :class="{
        'st-virtual-tour__question-list-button': true,
        'st-virtual-tour__question-list-button--specs-preview': isSpecificationsPreview,
        'st-virtual-tour__question-list-button--phone-demo-preview': isDemoPreview && mobileDemoView
      }"
      @click="$emit('open-topic-list')"
    >
      <icon-wrapper
        :class="{
          'st-virtual-tour__question-list-icon--phone-demo-preview': isDemoPreview && mobileDemoView
        }"
        icon-name="opinion-metric"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { companyLogoStyle } from '../../../../../mixins'
import { IconWrapper, StTooltip } from '../../../../common'
import Microphone from './microphone'
import Prompt from './prompt'

export default {
  components: {
    IconWrapper,
    Microphone,
    Prompt,
    StTooltip
  },
  mixins: [companyLogoStyle],
  props: {
    audioStream: {
      type: MediaStream,
      required: true
    },
    isDemoPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSpecificationsPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    companyLogo: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('participants', { participantActiveQuestion: 'activeQuestion' }),
    ...mapState('participants', ['mobileDemoView']),
    ...mapGetters('participants', ['topics']),
    ...mapState('specifications', ['activeViewpointIdentifier']),
    ...mapGetters('specifications', ['activeViewpoint']),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    activeQuestion () {
      return this.isSpecificationsPreview ? this.specsActiveQuestion : this.participantActiveQuestion
    },
    activeRoom () {
      return this.activeQuestion.rooms
        ? this.activeQuestion.rooms.find(room => {
          return room.panoramas.find(pano => { return pano.identifier === this.activeViewpointIdentifier })
        })
        : []
    },
    activePanoIndex () {
      return this.activeQuestion.rooms
        ? this.activeQuestion.panoramas.findIndex(pano => pano.identifier === this.activeViewpointIdentifier)
        : -1
    },
    activeText () {
      if (this.activeRoom && this.activePanoIndex >= 0) {
        return `${this.activeRoom.name} - Viewpoint ${this.activePanoIndex + 1}`
      } else if (this.activePanoIndex >= 0) {
        return `Viewpoint ${this.activePanoIndex + 1}`
      } else if (this.activeRoom && this.activeRoom.length === 0 && !this.activeViewpointIdentifier) {
        return 'Select a Viewpoint'
      } else {
        return 'Assign Viewpoint to a Room'
      }
    }
  },
  methods: {
    openExitPrompt () {
      if (!this.isSpecificationsPreview) { this.$emit('open-exit-prompt') }
    }
  }
}
</script>
