<template>
  <div class="bx--grid">
    <div class="bx--row">
      <div class="desktop-page-and-dropdown-button box--column-sm bx--col-sm-12 bx--col-xs-12">
        <h1 class="page-header st-requested-recipients__page-title">
          Requested Audience Recipients
        </h1>
        <st-button
          class="st-requested-recipients__request-button"
          caption="Request Audience"
          url="audience/request"
        />
      </div>
      <div
        class="lookup-and-dropdown bx--col-sm-12 bx--col-xs-12"
      >
        <st-input
          v-model="orderLookup"
          class="st-input--default lookup-and-dropdown__space-input"
          :show-icon="true"
          :show-label="false"
          :label="isDesktop ? 'Order Lookup' : ''"
          label-in-field-class="st-input--hide-mobile-label"
          @focus="handleSearchFocus"
          @focusOut="handleSearchFocusOut"
          @blur="updateSearchParam"
        />
        <st-dropdown-menu
          :class="{
            'lookup-and-dropdown__dropdown--hide': searchFocused
          }"
          caption="Sort By"
          :display-caption="true"
          :initial-active-option="filterOption"
          :options="orderFilterValues"
          @updateOption="setFilterOption"
        />
      </div>
      <div class="st-member-groups__accordions bx--col-xs-12">
        <template v-for="order in filteredOrdersBySearch">
          <st-accordion
            :key="order.id"
            visible-row-class="st-requested-recipients__visible-row"
            :expanded-text="`${order.participants.length} Recipients`"
          >
            <template v-slot:visible-section="stAccordionSlotProps">
              <div class="st-requested-recipients__left-content">
                <icon-wrapper
                  icon-name="list"
                />
                <p class="st-requested-recipients__order-name">
                  {{ order.name || '[Audience Request]' }}
                </p>
                <div class="st-requested-recipients__modify-icons">
                  <div
                    @click="(ev) => {ev.stopPropagation(); editOrder(order)}"
                  >
                    <icon-wrapper
                      v-if="stAccordionSlotProps.hover"
                      icon-name="edit"
                    />
                  </div>
                  <div
                    @click="openDeleteOrder(order)"
                  >
                    <icon-wrapper
                      v-if="stAccordionSlotProps.hover && isOrderCancelable(order)"
                      icon-name="trash"
                    />
                  </div>
                </div>
                <div
                  :class="[
                    'st-requested-recipients__phase',
                    'st-requested-recipients__phase--center',
                    phaseColorClass(order.phase)
                  ]"
                >
                  {{ order.phase }}
                </div>
              </div>
            </template>
            <template v-slot:persist-visible-section-right>
              <div
                :class="[
                  'st-requested-recipients__phase',
                  'st-requested-recipients__phase--right',
                  phaseColorClass(order.phase)
                ]"
              >
                {{ order.phase }}
              </div>
            </template>
            <template v-slot:expanded-section>
              <div class="st-requested-recipients__expanded-content-wrapper">
                <st-hyperlink
                  :class="{
                    'st-requested-recipients__view-order': true,
                    'st-requested-recipients__view-order--no-participants': order.participants.length === 0
                  }"
                  icon-name="receipt"
                  text="View Order"
                  @click="viewOrder(order)"
                />
                <div
                  v-if="order.participants.length > 0"
                  class="st-requested-recipients__participant-avatars-wrapper"
                >
                  <template v-for="participant in order.participants">
                    <div
                      :key="`participants-${participant.id}`"
                      class="st-requested-recipients__participant-wrapper"
                    >
                      <participant-avatar
                        class="st-requested-recipients__participant-avatar"
                        :participant="participant"
                      />
                      <p class="st-requested-recipients__participant-name">
                        {{ participant.fullName }}
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </st-accordion>
        </template>
      </div>
    </div>
    <st-modal
      v-model="viewOrderModal"
      class="st-audience-receipt-modal"
    >
      <template v-slot:modalTitle>
        <p class="st-requested-recipients__receipt-title">
          Order
        </p>
      </template>
      <template v-slot:modalActions>
        <receipt
          class="st-requested-recipients__receipt"
          :remove-receipt="true"
          scroll-area-class="st-requested-recipients__receipt-scroll-area"
        />
      </template>
    </st-modal>
    <st-modal v-model="isEditOpen">
      <template slot="modalTitle">
        Edit Order Name
      </template>
      <template slot="modalActions">
        <st-input
          v-model="requestName"
          class="st-survey-hub__edit-name-input"
          :autofocus="true"
          :show-label="true"
          label="Order Name"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          icon-name="save"
          @click="saveName"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="isCancelOpen"
      :cancel-modal="true"
    >
      <template slot="modalTitle">
        Cancel Order
      </template>
      <template slot="modalActions">
        <p class="st-modal__body">
          Are you sure you want to cancel "{{ name }}"?
        </p>
        <p class="st-modal__body st-requested-recipients__cancel-modal-conents">
          If you do, you cannot change it back yourself.
        </p>
      </template>
      <template slot="modalButton">
        <st-button
          caption="Cancel"
          @click="cancelOrder"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { caseInsensitiveSortBy } from '../../../utilities/data_utilities'
import { AudienceRequest } from '../../../structures/audience-request'
import ParticipantAvatar from '../insights/participant-avatar'
import Receipt from './request-audience/receipt'
import { advanceToUrl, hideMobileDropdown, isDesktop, refineUrlFilter } from '../../../mixins'
import { IconWrapper, StAccordion, StButton, StDropdownMenu, StHyperlink, StInput, StModal } from '../../common'

export default {
  components: {
    IconWrapper,
    ParticipantAvatar,
    Receipt,
    StAccordion,
    StButton,
    StDropdownMenu,
    StHyperlink,
    StInput,
    StModal
  },
  mixins: [advanceToUrl, hideMobileDropdown, isDesktop, refineUrlFilter],
  props: {
    requests: {
      type: Array,
      required: true
    }
  },
  data: function () {
    let formattedRequests = this.requests.map(AudienceRequest)

    return {
      allRequests: formattedRequests.filter(request => { return request.phase !== 'cancelled' }),
      isCancelOpen: null,
      isEditOpen: null,
      requestName: '',
      filterOption: 'Newest First',
      orderLookup: '',
      viewOrderModal: null
    }
  },
  computed: {
    ...mapState('requestAudience', ['audienceRequestId', 'name']),
    activeOrder () {
      return this.allRequests.find(request => request.id === this.audienceRequestId)
    },
    filteredOrdersBySearch () {
      return this.filteredOrdersByDropdown.filter(order => {
        return order.name.toLowerCase().includes(this.orderLookup.toLowerCase())
      })
    },
    filteredOrdersByDropdown () {
      let recipientsList
      switch (this.filterOption) {
        case 'All Orders':
          recipientsList = this.allRequests
          break
        case 'A - Z':
          recipientsList = caseInsensitiveSortBy(this.allRequests, 'name')
          break
        case 'Z - A':
          recipientsList = caseInsensitiveSortBy(this.allRequests, 'name', true)
          break
        case 'Newest First':
          recipientsList = this.allRequests.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          break
        case 'Oldest First':
          recipientsList = this.allRequests.slice().sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          break
        case 'Recipients':
          recipientsList = this.allRequests.slice().sort((a, b) => b.participants.length - a.participants.length)
          break
        case 'Status':
          recipientsList = caseInsensitiveSortBy(this.allRequests, 'phase')
          break
      }
      return recipientsList
    },
    orderFilterValues () {
      return ['All Orders', 'A - Z', 'Z - A', 'Newest First', 'Oldest First', 'Recipients', 'Status']
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'requested-recipients')
  },
  methods: {
    ...mapMutations('requestAudience', ['replaceData', 'setName']),
    ...mapActions('requestAudience', ['updateAudienceRequestData', 'updateRequestName']),
    cancelOrder () {
      this.$axios.request({
        url: `audience_requests/${this.audienceRequestId}/cancel`,
        method: 'patch',
        data: { audience_request: this.activeOrder }
      })
        .then(res => {
          this.allRequests.splice(this.allRequests.indexOf(this.activeOrder), 1)
          this.isCancelOpen = false
        })
        .catch(err => { this.errorMessage = err.response.data.message })
    },
    editOrder (order) {
      if (order.phase === 'draft') {
        this.advanceToUrl(`/audience_requests/${order.id}/edit`)
      } else {
        this.editOrderName(order)
      }
    },
    editOrderName (order) {
      this.replaceData(order)
      this.requestName = order.name
      this.isEditOpen = true
    },
    isOrderCancelable (order) {
      // eslint-disable-next-line new-cap
      let currentMoment = new moment()
      let requestDifference = moment.duration(currentMoment.diff(order.requestedAt))
      let numberOfDaysDiff = requestDifference._data.days
      let numberOfHoursDiff = requestDifference._data.hours

      if (numberOfDaysDiff === 0 && numberOfHoursDiff <= 24 && order.phase === 'requested') { return true }
    },
    openDeleteOrder (order) {
      this.replaceData(order)
      this.isCancelOpen = true
    },
    phaseColorClass (phase) {
      return { [`st-requested-recipients__phase--${phase}`]: true }
    },
    setFilterOption (option) {
      this.filterOption = option
    },
    saveName () {
      this.updateActiveOrderName(this.requestName)
      this.updateRequestName(this.requestName)
      this.isEditOpen = false
    },
    updateActiveOrderName (newName) {
      let newOrderData = { ...this.activeOrder, name: newName }
      this.$set(this.allRequests, this.allRequests.indexOf(this.activeOrder), newOrderData)
    },
    viewOrder (order) {
      this.replaceData(order)
      this.viewOrderModal = true
    }
  }
}
</script>
