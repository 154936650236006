<template>
  <st-card
    class="st-nav-card__spacing"
    :url="url"
  >
    <template
      v-slot:card-content="slotProps"
    >
      <div
        :class="{
          'st-nav-card': true,
          'st-nav-card--hover': slotProps.hover
        }"
        @mouseover="hoverOverCard"
        @mouseleave="leaveHoverOverCard"
        @mouseup="advanceToNewPathViaCard(url)"
      >
        <div class="st-nav-card__title-and-description">
          <div class="st-nav-card__icon-and-title">
            <icon-wrapper
              class="st-nav-card__icon"
              :icon-name="iconName"
            />
            <div>
              <span
                :class="{
                  'st-nav-card__title': true,
                  'st-nav-card__title--hover': slotProps.hover
                }"
              >
                {{ title }}
              </span>
            </div>
          </div>
          <div class="st-nav-card__title-description">
            {{ description }}
          </div>
        </div>
        <div
          class="st-nav-card__desktop-button"
        >
          <st-button
            ref="navCardButton"
            :caption="buttonValues.caption"
            :icon-name="buttonValues.iconName"
            :url="url"
            :variant="['narrow']"
          />
        </div>
        <div class="st-nav-card__mobile-icon">
          <icon-wrapper
            :icon-name="buttonValues.iconName"
          />
        </div>
      </div>
    </template>
  </st-card>
</template>

<script>
import IconWrapper from './icon-wrapper'
import StButton from '../common/st-button'
import StCard from '../common/st-card'
import stUid from '../../mixins/uid'

export default {
  components: {
    'icon-wrapper': IconWrapper,
    'st-button': StButton,
    'st-card': StCard
  },
  mixins: [stUid],
  model: {
    prop: 'activeValue'
  },
  props: {
    /**
      * Information passed to st-button (caption, iconName).
    */
    buttonValues: {
      type: Object,
      required: true
    },
    /**
      * Further text to assist the user in deciding an action.
    */
    description: {
      type: String,
      required: false,
      default: ''
    },
    /**
      * Icon displayed next to the title of the st-nav-card.
    */
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    /**
      * Main action of the st-nav-card
    */
    title: {
      type: String,
      required: false,
      default: ''
    },
    /**
      * Window.location.href the user will be taken to when interacting with st-nav-card.
    */
    url: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    advanceToNewPathViaCard (url) {
      if (window.innerWidth <= 576) {
        window.location.href = url
      }
    },
    hoverOverCard () {
      this.$refs.navCardButton.hover = true
    },
    leaveHoverOverCard () {
      this.$refs.navCardButton.hover = false
    }
  }
}
</script>
