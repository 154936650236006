<template>
  <div class="icon_secondary-microphone">
    <div class="icon_secondary-microphone__svg">
      <svg
        width="51"
        height="84"
        viewBox="0 0 51 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.5 3.63794C17.6352 3.63794 11.2596 10.0444 11.2596 17.9472V37.9316C11.2596 45.8343 17.6352 52.2408 25.5 52.2408C33.3648 52.2408 39.7404 45.8343 39.7404 37.9316V17.9472C39.7404 10.0444 33.3648 3.63794 25.5 3.63794ZM7.63914 17.9472C7.63914 8.03522 15.6357 0 25.5 0C35.3643 0 43.3609 8.03522 43.3609 17.9472V37.9316C43.3609 47.8435 35.3643 55.8787 25.5 55.8787C15.6357 55.8787 7.63914 47.8435 7.63914 37.9316V17.9472ZM1.81022 35.6033C2.80998 35.6033 3.62045 36.4177 3.62045 37.4223C3.62045 43.2531 5.92561 48.8452 10.0288 52.9682C14.132 57.0912 19.6972 59.4075 25.5 59.4075C31.3028 59.4075 36.868 57.0912 40.9712 52.9682C45.0744 48.8452 47.3796 43.2531 47.3796 37.4223C47.3796 36.4177 48.19 35.6033 49.1898 35.6033C50.1895 35.6033 51 36.4177 51 37.4223C51 44.218 48.3134 50.7353 43.5312 55.5406C39.1775 59.9153 33.4117 62.5448 27.3102 62.9809V82.181C27.3102 83.1856 26.4998 84 25.5 84C24.5002 84 23.6898 83.1856 23.6898 82.181V62.9809C17.5883 62.5448 11.8225 59.9153 7.46878 55.5406C2.6866 50.7353 0 44.218 0 37.4223C0 36.4177 0.810464 35.6033 1.81022 35.6033Z"
          fill="#F8A617"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
