<template>
  <div class="icon_checkmark">
    <div class="icon_checkmark__svg">
      <svg
        viewBox="0 0 16 16.001"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-20991.408-10197.413l-10.91 15.425-5.09-5.042.705-.708 4.248 4.209 10.23-14.459z"
          transform="translate(21007.408 10197.989)"
        />
        <path d="M0 0h10v1H0z" />
        <path
          d="M0 0h6v1H0z"
          transform="rotate(180 8 8)"
        />
        <path
          d="M0 0h1v10H0z"
          transform="rotate(180 8 8)"
        />
        <path d="M0 0h1v6H0z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
