<template>
  <div class="icon_live-presentation">
    <div class="icon_live-presentation__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h16v1H0z" />
        <path
          d="M0 0h14v1H0z"
          transform="translate(1 13)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(1 15)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(12 15)"
        />
        <path
          d="M0 0h1v14H0z"
          transform="translate(1)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(3 13)"
        />
        <path
          d="M0 0h1v14H0z"
          transform="translate(14)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(12 13)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(7 6)"
        />
        <path
          d="M-23242.7-87.245l-.895.447a5.394 5.394 0 010-4.576l.891.453a4.428 4.428 0 00.004 3.676z"
          transform="translate(23249.139 96.061)"
        />
        <path
          d="M-23243.158-86.8l-.895-.447a4.168 4.168 0 00.275-2.87 3.943 3.943 0 00-.273-.807l.891-.453a5.342 5.342 0 01.002 4.577z"
          transform="translate(23253.604 96.061)"
        />
        <path
          d="M-23242.891-89.885a5.25 5.25 0 00.385 3.938l-.879.475a6.515 6.515 0 010-5.917l.875.481a5.022 5.022 0 00-.381 1.023z"
          transform="translate(23247.256 95.405)"
        />
        <path
          d="M-23242.691-90.147a6.236 6.236 0 01-.471 4.676l-.879-.475a5.249 5.249 0 00.385-3.938 5.022 5.022 0 00-.383-1.022l.875-.481a6.305 6.305 0 01.473 1.24z"
          transform="translate(23255.283 95.405)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
