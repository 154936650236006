<script>
export default {
  mounted () {
    document.addEventListener('click', this.closeFn)
    document.addEventListener('keyup', this.enterFn)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeFn)
    document.removeEventListener('keyup', this.enterFn)
  }
}
</script>
