<template>
  <div class="icon_multiplemembers">
    <div class="icon_multiplemembers__svg">
      <svg viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.90625 6.86719L7.07031 8.84375V10.6953L0.484375 7.78906V6.5625L1.90625 6.86719ZM7.07031 5.25L1.90625 7.25781L0.484375 7.53125V6.30469L7.07031 3.40625V5.25ZM14.0547 0.625L9.625 12.9766H8.14844L12.5859 0.625H14.0547ZM20.6328 7.17969L15.375 5.21875V3.39844L22.125 6.29688V7.53125L20.6328 7.17969ZM15.375 8.86719L20.6484 6.86719L22.125 6.55469V7.78125L15.375 10.6875V8.86719Z" fill="black"/>
			</svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
