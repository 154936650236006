<script>
export default {
  beforeMount () {
    window.addEventListener('beforeunload', this.isUnsaved)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.isUnsaved)
  },
  methods: {
    isUnsaved (event) {
      if (!this.pageInfoChanged) return
      event.preventDefault()
      event.returnValue = ''
    }
  }
}
</script>
