<script>
import { mapMutations } from 'vuex'

// Intended to be used within a question specifications component
export default {
  data: function () {
    return {
      deleteModal: false,
      activeOption: null
    }
  },
  computed: {
    options () {
      return this.activeQuestion.options
    },
    highestPriority () {
      if (this.options.length === 0) { return 0 }

      return Math.max(...this.options.map(x => x.priority))
    }
  },
  methods: {
    ...mapMutations(['toggleInlineSpinner']),
    ...mapMutations('specifications', ['replaceActiveQuestion']),
    updateQuestionOption (id, idx, optionData, skipStoreUpdate = false) {
      
      this.toggleInlineSpinner(true)
      this.$axios.request({
        url: '/question_options/' + id,
        method: 'patch',
        data: optionData
      })
        .then(res => {
          if (!skipStoreUpdate) { this.replaceActiveQuestion(res.data.question) }
          setTimeout(() => { this.toggleInlineSpinner(false) }, 2450)
        })
        .catch(err => {
          console.log(err)
          setTimeout(() => { this.toggleInlineSpinner(false) }, 2450)
        })
    },
    createNewOptions (count, afterCreationCb = (() => {})) {
      let startPriority = this.highestPriority + 1
      for (let i = 0; i < count; i++) {
        // TODO: these are not guaranteed to return in the order we submit
        this.createNewOption(startPriority + i, afterCreationCb)
      }
    },
    createNewOption (priority, afterCreationCb = (() => {})) {
      if (this.isPreview) { return }

      let questionId = this.activeQuestion.id
      this.$axios.request({
        url: `/questions/${questionId}/question_options`,
        method: 'post',
        data: {
          question_id: questionId,
          priority: priority
        }
      })
        .then(res => this.handleNewOptionResponse(res, afterCreationCb))
        .catch(err => console.log(err))
    },
    deleteLastOption (id, afterDeleteCb = (() => {})) {
      if (this.isPreview) { return }

      let deleteOptionUrl = '/question_options/' + id
      this.$axios.request({
        url: deleteOptionUrl,
        method: 'delete'
      })
        .then(res => {
          this.replaceActiveQuestion(res.data.question)
          afterDeleteCb()
        })
        .catch(err => console.log(err))
    },
    handleNewOptionResponse (res, afterCreationCb = (() => {})) {
      this.replaceActiveQuestion(res.data.question)
      afterCreationCb()
    },
    deleteQuestionOption (id, idx) {
      if (this.isPreview) { return }

      let deleteOptionUrl = '/question_options/' + id
      this.$axios.request({
        url: deleteOptionUrl,
        method: 'delete'
      })
        .then(res => {
          this.replaceActiveQuestion(res.data.question)
          this.isMounted = true
        })
        .catch(err => console.log(err))
    },
    deleteOptionModal (idx, boolean) {
      if (boolean) {
        this.activeOption = idx
      } else {
        this.activeOption = null
      }
      this.deleteModal = boolean
    }
  }
}
</script>
