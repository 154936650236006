var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"st-questions-panel__title-and-switch"},[_c('p',{staticClass:"st-questions-panel__title"},[_vm._v("\n      Questions\n    ")]),_vm._v(" "),_c('div',{staticClass:"st-questions-panel__switch"},[_c('div',{class:{
          'st-questions-panel__switch-icon-container': true,
          'st-questions-panel__switch-icon-container--active': !_vm.showDescription,
        },attrs:{"tabindex":"0"},on:{"click":function($event){_vm.showDescription = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.showDescription = false}}},[_c('icon-wrapper',{class:{
            'st-questions-panel__switch-icon': true,
            'st-questions-panel__switch-icon--active': !_vm.showDescription,
          },attrs:{"icon-name":"question-name-toggle","actionable":!_vm.showDescription}})],1),_vm._v(" "),_c('div',{staticClass:"st-questions-panel__switch-separator"}),_vm._v(" "),_c('div',{class:{
          'st-questions-panel__switch-icon-container': true,
          'st-questions-panel__switch-icon-container--active': _vm.showDescription,
        },attrs:{"tabindex":"0"},on:{"click":_vm.showQuestionDescriptions,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.showQuestionDescriptions.apply(null, arguments)}}},[_c('icon-wrapper',{class:{
            'st-questions-panel__switch-icon': true,
            'st-questions-panel__switch-icon--active': _vm.showDescription,
          },attrs:{"icon-name":"question-description-toggle","actionable":_vm.showDescription}})],1)])]),_vm._v(" "),_vm._l((_vm.surveyQuestions),function(question,index){return _c('a',{key:question.id,class:{
      'st-questions-panel__row': true,
      'st-questions-panel__row--active': index === _vm.activeQuestionIndex,
      'st-questions-panel__row--show-description': _vm.showDescription
    },attrs:{"tabindex":"0","href":("/surveys/1/results?question=" + index)},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('insights/setActive', index)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$store.dispatch('insights/setActive', index)}}},[_c('div',{class:{
        'st-questions-panel__number': true,
        'st-questions-panel--active': index === _vm.activeQuestionIndex
      }},[_vm._v("\n      "+_vm._s(index + 1)+".\n    ")]),_vm._v(" "),_c('icon-wrapper',{attrs:{"icon-name":_vm.questionIconName(question),"neutral":index !== _vm.activeQuestionIndex}}),_vm._v(" "),_c('div',[_c('p',{class:{
          'st-questions-panel__name': true,
          'st-questions-panel--active': index === _vm.activeQuestionIndex
        }},[_vm._v("\n        "+_vm._s(_vm.questionType(question))+"\n      ")]),_vm._v(" "),(_vm.showDescription)?_c('p',{class:{
          'st-questions-panel__description': true,
          'st-questions-panel--active': index === _vm.activeQuestionIndex
        }},[_vm._v("\n        "+_vm._s(question.description)+"\n      ")]):_vm._e()])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }