<template>
  <div class="icon_perceptionsliders">
    <div class="icon_perceptionsliders__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 3a3 3 0 11-3-3 3 3 0 013 3zM5 3a2 2 0 10-2 2 2 2 0 002-2z"
          transform="translate(5 1)"
        />
        <path d="M8 0c4.418 0 8 4 8 4s-3.582 4-8 4-8-4-8-4 3.582-4 8-4zM1.426 4C5.87 7.824 9.752 8.149 14.574 4 10.453.454 6.555-.233 1.957 3.543z" />
        <path
          d="M0 0h5v1H0z"
          transform="translate(0 13)"
        />
        <path
          d="M0 0h9v1H0z"
          transform="translate(7 13)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(4 11)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(4 15)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(4 11)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(7 11)"
        />
        <path
          d="M0 0h5v1H0z"
          transform="translate(0 13)"
        />
        <path
          d="M0 0h9v1H0z"
          transform="translate(7 13)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(4 11)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(4 15)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(4 11)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(7 11)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
