<template>
  <div
    :class="{
      'st-dropdown-multiselect': true,
      'st-dropdown-multiselect--open': open
    }"
    :style="{
      width: minWidth && mobileMinWidth
    }"
    :tabindex="tabIndex"
  >
    <div
      v-if="open"
      class="st-dropdown-multiselect__mobile-opacitor"
      @click="open = false"
    />
    <div
      v-if="showLabelAbove"
      :class="[{
        'st-dropdown-multiselect__label': true,
        'st-dropdown-multiselect__label--select-multiple': selectMultiple,
        labelClass
      }, labelVariantClasses]"
    >
      <template>
        {{ caption }}
      </template>
      <p
        v-if="selectMultiple && !isDesktop"
        class="st-dropdown-multiselect__amount-selected"
      >
        ({{ selectedOptions.length }}
        <icon-wrapper
          icon-name="plus"
          class="st-dropdown-multiselect__amount-selected--icon"
          :spacetrics-blue="true"
        />
        )
      </p>
    </div>
    <div
      ref="upperDiv"
      :class="[{
        'st-dropdown-multiselect__align-chosen': true,
        'st-dropdown-multiselect__align-chosen--overlapping-options': applyBottomMargin,
        'st-dropdown-multiselect__align-chosen--menu-open': open,
        'st-dropdown-multiselect__disabled': disabled,
      }, inputContainerClasses]"
      :style="{
        width: minWidth && mobileMinWidth
      }"
      @click="checkIfDisabled"
    >
      <input
        ref="chosen"
        :value="dropdownValue"
        type="button"
        :class="inputClasses"
      >
      <div
        v-if="open"
        class="st-dropdown-multiselect__placeholder"
      >
        {{ caption }}
      </div>
      <icon-wrapper
        :class="{
          'st-dropdown-multiselect__icon': true,
          'st-dropdown-multiselect__icon--open': open
        }"
        :icon-name="checkIconName"
      />
    </div>
    <div
      v-if="open"
      ref="lowerDiv"
      class="st-dropdown-multiselect__options-container"
    >
      <div class="st-dropdown-multiselect__header">
        <div :style="{display: 'flex'}">
          <div
            class="st-dropdown-multiselect__chevrons"
            @click="updateIndex('prev')"
          >
            <icon-wrapper
              :class="{
                'st-dropdown-multiselect__chevron-left': true,
                'st-dropdown-multiselect__chevron-left--active': activeIndex !== 0
              }"
              icon-name="chevron"
            />
          </div>
          <div
            class="st-dropdown-multiselect__chevrons"
            @click="updateIndex('next')"
          >
            <icon-wrapper
              :class="{
                'st-dropdown-multiselect__chevron-right': true,
                'st-dropdown-multiselect__chevron-right--active': activeIndex !== options.length-1
              }"
              icon-name="chevron"
              @click="updateIndex('next')"
            />
          </div>
        </div>
        <p
          class="text-blue"
          @click="open = false"
        >
          Done {{ selectMultiple ? `(${selectedOptions.length} selected)` : '' }}
        </p>
      </div>
      <template v-if="selectMultiple">
        <div class="st-dropdown-multiselect__options-scroll">
          <st-checkbox
            v-for="(option, idx) in optionsData"
            :key="'option ' + idx"
            :checked="option.selected"
            class="st-dropdown-multiselect__multiple-select-option"
            @input="updateMultiple(idx)"
          >
            {{ option.name }}
          </st-checkbox>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(option, index) in inactiveOptions"
          :key="option"
          :class="{
            'st-dropdown-multiselect__options': true,
            'st-dropdown-multiselect__options--selected': option===dropdownValue
          }"
          @click="updateSingle(index); open = false"
        >
          {{ option }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { IconWrapper, StCheckbox } from '../common'
import { isDesktop } from '../../mixins'

export default {
  components: {
    IconWrapper,
    StCheckbox
  },
  mixins: [isDesktop],
  props: {
    /**
       * Used to prevent a selected checkboxes from being removed
       */
    activeToggleDisable: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Additional information for the type of dropdown in mobile.
       */
    caption: {
      type: String,
      require: false,
      default: ''
    },
    /**
       * Passed to st-checkbox to prevent use
       */
    disableCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * How to get the caption to display for an option.
     * By default it's just the option (used for multi-select)
     */
    optionCaptionFn: {
      type: Function,
      required: false,
      default: option => option
    },
    /**
       * Blocks the dropdown from usage.
       */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * should the caption be displayed above the menu when the menu has focus?
       */
    displayCaption: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Set the min-width of the dropdown to be 100%.
       */
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Starting selected value.
       */
    initialActiveOption: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * Starting selected array of values for multi-select.
       */
    initialActiveOptionsArray: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
       * Used to change the styling of the multi-select's label.
       */
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * can you select more than one option in the dropdown?
       */
    selectMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * All available options, including the active option
       */
    options: {
      type: Array,
      required: true
    },
    /**
       * displayed in the dropdown selected position when there is nothing selected and the menu is open
       */
    placeholder: {
      type: String,
      required: false,
      default: 'Select one'
    },
    /** Can be used to change the kind of dropdown-menu (ex: fullBorder) */
    variant: {
      type: Array,
      required: false,
      default: () => []
    },
    tabIndex: {
      type: [Number, String],
      required: false,
      default: ''
    }
  },
  data: function () {
    let initialActiveCaptions = new Set(this.initialActiveOptionsArray.map(this.optionCaptionFn))
    return {
      activeIndex: this.options.indexOf(this.initialActiveOption),
      open: false,
      applyBottomMargin: false,
      selectedIndex: -1,
      optionsData: this.options.map(option => {
        let caption = this.optionCaptionFn(option)
        return {
          disabled: initialActiveCaptions.has(caption),
          name: caption,
          value: option,
          selected: initialActiveCaptions.has(caption)
        }
      }),
      minWidth: 'unset'
    }
  },
  computed: {
    selectedOptions () {
      if (this.selectMultiple) {
        return this.optionsData.filter(option => {
          return option.selected
        })
      } else {
        return [this.options[this.activeIndex]]
      }
    },
    activeOptionText () {
      if (this.selectMultiple) {
        if (this.selectedOptions.length > 0) {
          return this.selectedOptions.map(option => option.name).join(', ')
        } else {
          return undefined
        }
      } else {
        return this.options[this.activeIndex]
      }
    },
    checkIconName () {
      if (this.open) {
        return 'caret-up'
      }
      return 'caret-down'
    },
    inactiveOptions () {
      return this.options
    },
    inputContainerClasses () {
      return {
        'st-dropdown-multiselect__align-chosen--full-border': this.variant.includes('fullBorder')
      }
    },
    dropdownValue () {
      if (this.open) {
        if (this.selectMultiple || !this.activeOptionText) {
          return this.placeholder
        } else {
          return this.activeOptionText
        }
      } else {
        if (this.activeOptionText) {
          return this.activeOptionText
        } else {
          return this.caption
        }
      }
    },
    labelVariantClasses () {
      return {
        'st-dropdown-multiselect__label--persist': this.variant.includes('persistLabelAbove') && !this.open
      }
    },
    mobileMinWidth () {
      if (this.open && !isDesktop) {
        return (window.innerWidth - 20) + 'px'
      } else {
        return this.minWidth
      }
    },
    extraClass () {
      return 'st-extra-class--' + _.kebabCase(this.dropdownValue)
    },
    inputClasses () {
      let obj = {
        'st-dropdown-multiselect__chosen-option': true,
        'st-dropdown-multiselect__chosen-option--multiple': this.selectMultiple,
        'st-dropdown-multiselect__chosen-option--menu-open': this.open,
        'st-dropdown-multiselect__chosen-option--disabled': this.disabled,
        'st-dropdown-multiselect__chosen-option--empty': this.dropdownValue === this.caption
      }
      obj[this.extraClass] = true
      return obj
    },
    accordionContentsValuesCheck () {
      return !_.isEmpty(this.accordionContentsValues)
    },
    showLabelAbove () {
      return (this.open && this.displayCaption) ||
      (this.variant.includes('persistLabelAbove') && !this.open && this.activeOptionText)
    }
  },
  mounted () {
    // set min width to accomodate the hover effect and to be long enough for no ellipsis
    this.$nextTick(() => {
      if (!this.fullWidth) {
        let lengths = []
        this.options.forEach(option => {
          this.$refs.chosen.value = option
          lengths.push(this.$refs.chosen.clientWidth)
        })
        this.minWidth = Math.max(...lengths) + 87 + 'px'
        this.$refs.chosen.value = this.dropdownValue
      }
    })
  },
  updated: function () {
    this.$nextTick(() => {
      if (this.open) {
        let upperBound = this.$refs.upperDiv.getBoundingClientRect().bottom
        let lowerBound = this.$refs.lowerDiv.getBoundingClientRect().top
        let distance = lowerBound - upperBound
        if (distance < 200) {
          window.scrollBy(0, 500)
          this.applyBottomMargin = true
        }
      } else {
        this.applyBottomMargin = false
      }
    })
  },
  methods: {
    checkIfDisabled () {
      if (!this.disabled) { this.open = !this.open }
    },
    updateSingle (idx) {
      let option = this.options[idx]
      this.activeIndex = idx
      this.$emit('updateOption', option)
    },
    updateMultiple (index) {
      this.toggleSelected(index)
      let optionValues = this.selectedOptions.map(option => option.value)
      this.$emit('updateMultiple', optionValues, index)
    },
    toggleSelected (optionIdx) {
      this.optionsData[optionIdx].selected = !this.optionsData[optionIdx].selected
    },
    updateIndex (event) {
      if (event === 'prev') {
        if (this.activeIndex > 0) {
          this.activeIndex--
        }
      } else {
        if (this.activeIndex !== this.options.length) {
          this.activeIndex++
        }
      }
      if (this.selectMultiple) {
        this.updateMultiple(this.activeIndex)
      } else {
        this.updateSingle(this.activeIndex)
      }
    }
  }
}
</script>
