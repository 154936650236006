<template>
  <div class="">
    <div
      class="st-virtual-tour__blur-screen"
      @click="$emit('continue-tour')"
    />
    <div class="st-virtual-tour__exit-prompt-box">
      <div
        class="st-virtual-tour__exit-prompt-x"
        @click="$emit('continue-tour')"
      >
        <icon-wrapper
          icon-name="cancel"
        />
      </div>
      <div class="st-virtual-tour__exit-prompt-text">
        If you "Save & Continue" your recording will be submitted.
      </div>
      <st-button
        class="st-virtual-tour__exit-prompt-button"
        caption="Save & Continue"
        icon-name="save"
        :underline="false"
        :variant="['secondary']"
        @click="$emit('exit-tour')"
      />
      <div
        class="st-virtual-tour__exit-tour-continue-button"
        @click="$emit('continue-tour')"
      >
        Continue Recording
        <icon-wrapper
          class="st-virtual-tour__exit-continue-tour-icon"
          icon-name="microphone"
          :spacetrics-blue="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { IconWrapper, StButton } from '../../../../common'
export default {
  components: {
    IconWrapper,
    StButton
  },
  props: {
  },
  data: function () {
    return {
    }
  }
}
</script>
