<template>
  <div class="icon_tourmode">
    <div class="icon_tourmode__svg">
      <svg
        viewBox="0 0 12.18 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-23498.3-459.627a2.013 2.013 0 011.977.194 1.891 1.891 0 001.033.6c.7.2 1.492-.277 1.975.229.242.256.1.565.988.848.688.219 1.709.191 2.184.9a1.594 1.594 0 01-1.016 2.388 8.3 8.3 0 01-2.963-.5c-.115.536-.176.595.055 1.046a6.527 6.527 0 011.088 3.873c0 1.121-.463 1.77-1.609 1.769a1.578 1.578 0 01-1.637-1.455 1.622 1.622 0 01-.02-.252.1.1 0 010 .024l-.15-1c-.516 1.107-2.02 2.884-3.295 2.65a1.876 1.876 0 01-1.447-1.576 1.475 1.475 0 01.365-1.041c.3-.349 1.242-.925 1.482-1.348a22.015 22.015 0 00.789-3.249c-.727.692-1.2 1.317-1.961 1.339a1.719 1.719 0 01-1.576-1.137 1.66 1.66 0 01.26-1.387c1.978-1.796 2.819-2.633 3.478-2.915zm-2.693 3.534a.658.658 0 00-.09.476.753.753 0 00.6.43c.295-.019.525-.322 1.121-.9.125-.118 1.043-1.142 1.648-.771a.824.824 0 01.3.852c-.047.362-.15.95-.275 1.567-.566 2.785-.7 2.859-1.91 3.811-.307.24-.41.319-.475.431-.182.317.051.589.346.8.445.316.969-.148 1.385-.56a4.608 4.608 0 001.428-2.76.661.661 0 01.535-.5.565.565 0 01.477.208 11.013 11.013 0 01.652 2.911.9.9 0 00.258.708.879.879 0 00.906-.026.939.939 0 00.115-.616c-.033-3.392-1.572-3.061-1.16-4.962.053-.252.094-.835.48-1.027.361-.178.689.061 1.182.228a10.667 10.667 0 002.061.355.566.566 0 00.346-.143.745.745 0 00.094-.723c-.322-.483-2.33-.147-3.051-1.547a3.9 3.9 0 01-1.541-.026c-1.439-.414-1.127-.935-1.91-.925-.72.012-1.187.602-3.523 2.709z"
          transform="translate(23502.098 464.28)"
        />
        <path
          d="M4.983 2.491A2.491 2.491 0 112.491 0a2.491 2.491 0 012.492 2.491zm-1 0a1.495 1.495 0 10-1.492 1.495 1.495 1.495 0 001.495-1.495z"
          transform="translate(5.08)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
