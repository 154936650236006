var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-topic-list__list-container': true,
    'st-topic-list__list-container--specs-preview': _vm.isSpecificationsPreview,
    'st-topic-list__list-container--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
  }},[_c('div',{staticClass:"st-topic-list__title-and-close"},[_c('p',{staticClass:"st-topic-list__questions-title"},[_vm._v("\n      Topics\n    ")]),_vm._v(" "),_c('div',{staticClass:"st-topic-list__close-icon-container",on:{"click":function($event){return _vm.$emit('close-topic-list')}}},[_c('icon-wrapper',{staticClass:"st-topic-list__close-icon",attrs:{"icon-name":"plus","invert":true}})],1)]),_vm._v(" "),_c('div',{class:{
      'st-topic-list__scroll-area': true,
      'st-topic-list__scroll-area--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
    }},_vm._l((_vm.topics),function(topic){return _c('div',{key:'topic' + topic,staticClass:"st-topic-list__topic-container",on:{"click":function($event){return _vm.changeViewpoint(topic)}}},[_c('div',{staticClass:"st-topic-list__topic-and-arrow"},[_c('div',{staticClass:"st-topic-list__room-and-topic"},[_c('div',{staticClass:"st-topic-list__topic-room"},[_vm._v("\n            "+_vm._s(_vm.getTopicRoom(topic))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"st-topic-list__topic"},[_vm._v("\n            "+_vm._s(topic)+"\n          ")])]),_vm._v(" "),_c('icon-wrapper',{staticClass:"st-topic-list__topic-arrow",attrs:{"icon-name":"arrow","invert":true}})],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }