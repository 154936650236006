<template>
  <div class="st-countdown__contents">
    <div class="st-countdown__message-and-number">
      <p class="st-countdown__ready-message">
        {{ getReadyMessage }}
      </p>
      <transition
        name="fade-numbers"
        mode="out-in"
      >
        <p
          :key="activeNumber"
          class="st-countdown__active-number"
        >
          {{ activeNumber }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>

const MS_PER_NUMBER = 1250

export default {
  props: {
    companyLogo: {
      type: String,
      required: false,
      default: ''
    },
    isSpecificationsPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      activeNumber: 3,
      interval: null
    }
  },
  computed: {
    getReadyMessage () {
      return this.isSpecificationsPreview
        ? 'Get ready to start recording (preview)...'
        : 'Get ready to start recording...'
    }
  },
  mounted () {
    this.startCountdown()
  },
  methods: {
    startCountdown () {
      this.interval = setInterval(() => {
        if (this.activeNumber > 1) {
          this.activeNumber--
        } else {
          clearInterval(this.interval)
          this.$emit('end-countdown')
        }
      }, MS_PER_NUMBER)
    }
  }
}
</script>
