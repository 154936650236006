<template>
 <div class="st-image-board__mobile-body st-image-board__image-body-participant">
   <div class="description-container">
      <p
      ref="question"
      class="st-participant__question-container-para title-para"
    >
      {{description}}
    </p>
   </div>
   <div class="quadrant-board-container">
    <quadrant-placement-board
      :minXLabel="minXLabel"
      :maxXLabel="maxXLabel"
      :minYLabel="minYLabel"
      :maxYLabel="maxYLabel"
      :onDrop="handleDrop"
    >
      <drag
        v-for="(option, index) in droppedTags"
        :key="index"
        class="tag-item dropped-tag-item"
        @dragstart="handleDragStart(option)"
        :style="{
            top: `${option.y * 100}%`,
            left: `${option.x * 100}%`,
          }"
      >
        {{ option.name }}
      </drag>
    </quadrant-placement-board>
   </div>
   <div class="tags-row">
    <div class="button-container" v-if="this.$store.getters['specifications/extraTags']!='no'">
      <button class="add-button">
          +
      </button>
    </div>
    <div class="tags-container">
      <drag
        v-for="(option, index) in tags"
        :key="index"
        class="tag-item"
        @dragstart="handleDragStart(option)"
      >
        {{ option.name }}
      </drag>
    </div>
   </div>
   <div>
     <div class="submit-button-container">
        <st-button
          class="st-concept-ranking__button"
          :caption="buttonCaption"
          :underline="false"
          icon-name="arrow"
          :variant="['secondary']"
          @click="submitQuestionAnswer"
          :disabled="!allTagsPlaced"
        />
     </div>
   </div>
  </div>
</template>

<script>
import {StButton, StTooltip, QuadrantPlacementBoard} from "../../../common"
import submitQuestionAnswer from '../../../../mixins/question/submitQuestionAnswer.vue'
import { Drag } from 'vue-easy-dnd';

let dropzonePosition = null;
export default {
  components: {
    StButton,
    StTooltip,
    QuadrantPlacementBoard,
    Drag
  },
  mixins: [submitQuestionAnswer],
  data: function(){
    return {
      droppedOptions: [],
      draggedOptionId: null
    }
  },
  computed: {
    tags: function() {
      const droppedOptionIds = this.droppedOptions.map(({ id }) => id);
      return this.options.filter(({ id, name }) => !!name && !droppedOptionIds.includes(id));
    },
    droppedTags: function() {
      const droppedOptionIds = this.droppedOptions.map(({ id }) => id);
      return this.options.filter(({ id, name }) => !!name && droppedOptionIds.includes(id)).map(option => {
        const dropped = this.droppedOptions.find(({ id }) => id === option.id);
        return {
          ...option,
          ...dropped
        }
      });
    },
    allTagsPlaced: function() {
      return this.droppedOptions.length === this.options.filter(({ name }) => name).length;
    }
  },
  props: {
    askForExplanation: {
      type: String,
      required: false,
      default: ''
    },
     minXLabel: {
      default: 'x-measure 1',
      type: String,
      required: false
    },
    maxXLabel: {
      default: 'x-measure 2',
      type: String,
      required: false
    },
    minYLabel: {
      default: 'y-measure 1',
      type: String,
      required: false
    },
    maxYLabel: {
      default: 'y-measure 2',
      type: String,
      required: false
    },
    autoStopTime: {
      type: Number,
      default: 900000, // ms = 15 min
      required: false
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    media: {
      type: Object,
      required: false,
      default: () => ({})
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    priority: {
      type: Number,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    scaleLength: {
      type: Number,
      required: false,
      default: 0
    },
    scaleStyle: {
      type: String,
      required: false,
      default: ''
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods:{
    answerData () {
      let answer = { options: {} };
      this.droppedOptions.forEach(({ id, x, y }) => answer.options[id] = [x, y]);
      return { response:answer };
    },
    handleDragStart (item) {
      this.draggedOptionId = item.id;
    },
    handleDrop (event) {
      const optionId = this.draggedOptionId;
      const { width, height, top, left } = event.top.$el.getBoundingClientRect();
      if (!dropzonePosition) dropzonePosition = { left, top, width, height };
      const x = ( event.position.x - left ) / width;
      const y = ( event.position.y - top ) / height;
      if (x < 0 || y < 0) return;
      const option = {
        id: optionId,
        x,
        y
      };
      const droppedOptionIds = this.droppedOptions.map(({ id }) => id);
      if (droppedOptionIds.includes(optionId))
        this.droppedOptions = this.droppedOptions.filter(({ id }) => id !== optionId);
      this.droppedOptions.push(option);
    },
  }
}
</script>
<style scoped>
.description-container{
  min-height: 80px;
  max-height: 90px;
  overflow: auto;
}
.title-para{
  text-align: center;
  font-size: 14px;
  padding: 2px;
}
.tags-row{
  display:flex;
  padding:2px;
  margin-top: 3px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  align-items: center;
}
.add-button{
  background:#1e5dc6;
  font-size: 15px;
  color:white;
}
.button-container{
  margin-left: 5px;
}
::-webkit-scrollbar {
    display: none;
}
.tags-container{
  max-width:400%;
  padding: 5px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}
.submit-button-container{
  margin-top:1%;
}
.tag-item{
  padding:2px;
  font-size:12px;
  background:#e0e0e0;
  margin-left: 5px;
}
.tag-item:hover{
  cursor: pointer;
}
.dropped-tag-item {
  transform: translate(-50%,-50%);
  position: absolute;
  z-index: 9999;
  margin: 0px;
}
.quadrant-board-container {
  height: 360px;
}
</style>