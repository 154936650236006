<template>
  <div class="bx--grid">
    <div class="bx--offset-sm-1 bx--col-sm-11">
      <div class="st-choose-template__mobile-header">
        <a href="/welcome">
          <icon-wrapper
            icon-name="spacetrics"
            class="st-choose-template__mobile-header-icon"
          />
        </a>
      </div>
      <st-hyperlink
        class="back-hyperlink-spacing"
        :back-link="true"
        text="Back"
        :url="`/surveys/${surveyId}/edit/`"
      />
      <div class="st-edit-survey__header-and-icon st-choose-template__header">
        <icon-wrapper
          class="st-edit-survey__header-icon"
          icon-name="survey-content"
        />
        <h1 class="st-edit-survey__page-header">
          Content
        </h1>
      </div>
      <div class="bx--offset-sm-2 bx--col-sm-7 st-choose-template__align-cards">
        <st-icon-description-card
          v-for="(option, index) in newContentOptions"
          :key="option.name"
          :description="option.description"
          :icon-name="option.iconName"
          :url="option.newPath"
          :title="option.title"
          @click="selectOption(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StIconDescriptionCard from '../../common/st-icon-description-card'
import { IconWrapper, StHyperlink } from '../../common/'

export default {
  components: {
    IconWrapper,
    StHyperlink,
    'st-icon-description-card': StIconDescriptionCard
  },
  props: {
    surveyId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      newContentOptions: [{
        description: 'Choose your own question and content.',
        iconName: 'pointer',
        newPath: `/surveys/${this.surveyId}/edit/content`,
        title: 'Create your own'
      },
      {
        description: 'Choose a saved template to save some time.',
        iconName: 'survey-template',
        newPath: `/surveys/${this.surveyId}/select_template`,
        title: 'Templates'
      }]
    }
  }
}
</script>
