<template>
  <div
    :class="{
      'st-record-member': true,
      'st-record-member--delete-shown': showDeleteOption,
    }"
  >
    <participant-avatar
      :participant="recordMember"
      class="st-record-member__avatar"
    />
    <span
      v-if="!showDeleteOption"
      ref="fullName"
      class="st-record-member__name"
      tabindex="0"
      @focus="focusDelete"
      @mouseenter="canDeleteMember"
    >
      {{ recordMember.fullName }}
    </span>
    <div
      v-if="showDeleteOption"
      ref="deleteMember"
      class="st-record-member__remove-member"
      tabindex="0"
      :style="{ marginRight: deleteMessageMargin + 'px'}"
      @click="openDeleteModal"
      @focusout="showDeleteOption = false"
      @keyup.enter="openDeleteModal"
      @mouseleave="showDeleteOption = false"
    >
      Delete Member
      <icon-wrapper
        class="st-record-member__remove-member-icon"
        icon-name="trash"
      />
    </div>
    <div
      :class="{
        'st-record-member-dropdown__contents': true,
        'st-record-member-dropdown__contents--delete-shown': showDeleteOption,
      }"
    >
      <div
        :ref="recordMember.id"
        tabindex="0"
        :class="{
          'st-record-member-dropdown__active-value': true,
          'st-record-member-dropdown__active-value--dropdown-open': dropdownOpen,
          'st-record-member-dropdown__active-value--not-admin': !memberCanOpenDropdown
        }"
        @click.stop="openMemberTypeDropdown"
        @keyup.enter="openMemberTypeDropdown"
      >
        <input
          :class="{
            'st-record-member-dropdown__input': true,
            'st-record-member-dropdown__input--dropdown-open': dropdownOpen,
            'st-record-member-dropdown__input--not-admin': !memberCanOpenDropdown
          }"
          :value="matchSurveyPermission || recordMember.kind"
          type="button"
        >
        <icon-wrapper
          v-if="memberCanOpenDropdown"
          :class="{
            'st-record-member-dropdown__chevron': true,
            'st-record-member-dropdown__chevron--dropdown-open': dropdownOpen
          }"
          icon-name="chevron"
        />
      </div>
      <div v-if="dropdownOpen">
        <div
          class="st-record-member-dropdown__options"
          :style="dropdownOptionsStyle"
        >
          <div
            v-for="(option, index) in dropdownValues"
            :key="option"
            tabindex="0"
            class="st-record-member-dropdown__options-wrap"
            @blur="closeDropdownIfLast(index)"
            @keyup.enter="setDropdownOption(option)"
          >
            <input
              class="st-record-member-dropdown__input st-record-member-dropdown__input--option"
              type="button"
              :value="option"
              @mouseup.stop="setDropdownOption(option)"
            >
          </div>
        </div>
      </div>
    </div>
    <span
      v-if="!showDeleteOption"
      ref="email"
      class="st-record-member__email"
      @mouseenter="canDeleteMember"
    >
      {{ recordMember.email }}
    </span>
    <st-modal
      v-model="showConfirmationMessage"
      :cancel-modal="true"
      :non-exit-modal="true"
      @closeModal="closeConfirmationModal"
    >
      <template v-slot:modalTitle>
        Confirm Update
      </template>
      <template v-slot:modalActions>
        <div class="st-record-member-confirm-modal__instructions-container">
          <p class="st-record-member-confirm-modal__instruction-text">
            You {{ confirmationText }}. If you change this, you cannot change it back yourself.
          </p>
          <p class="st-record-member-confirm-modal__instruction-text">
            Are you sure you want to make this change?
          </p>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Confirm"
          icon-name="check"
          @click="confirmUpdate(newAdminValue)"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="showDeleteMessage"
      :cancel-modal="true"
      :non-exit-modal="true"
      @closeModal="closeDeleteModal"
    >
      <template v-slot:modalTitle>
        Confirm Member Removal
      </template>
      <template v-slot:modalActions>
        <div class="st-record-member-confirm-modal__instructions-container">
          <p class="st-record-member-confirm-modal__instruction-text">
            You will be removing {{ deleteMessage }}.
          </p>
          <p class="st-record-member-confirm-modal__instruction-text">
            Are you sure you want to make this change?
          </p>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Confirm"
          icon-name="check"
          @click="removeMember"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SURVEY_PERMISSIONS_CATEGORIES } from '../../../utilities/enums'
import closeElement from '../../../mixins/closeElement'
import { isDesktop } from '../../../mixins'
import { IconWrapper, StButton, StModal } from '../../common'
import ParticipantAvatar from '../insights/participant-avatar'

export default {
  components: {
    IconWrapper,
    StButton,
    StModal,
    'participant-avatar': ParticipantAvatar
  },
  mixins: [closeElement, isDesktop],
  props: {
    canAddRecordMember: {
      type: Boolean,
      required: true
    },
    canOpenDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmationText: {
      type: String,
      required: false,
      default: ''
    },
    member: {
      type: Object,
      required: true
    },
    projectId: {
      type: Number,
      required: false,
      default: null
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      deleteMessageMargin: 0,
      dropdownOpen: false,
      dropdownWidth: 0,
      newAdminValue: '',
      openDropdown: false,
      projectMemberKinds: ['admin', 'member'],
      recordMember: this.member,
      showConfirmationMessage: false,
      showDeleteMessage: false,
      showDeleteOption: false
    }
  },
  computed: {
    ...mapState(['currentUser']),
    avatarStyle () {
      return this.recordMember.avatarUrl ? { 'background-image': `url("${this.recordMember.avatarUrl}")` } : {}
    },
    deleteMessage () {
      return this.projectId
        ? `${this.recordMember.fullName} from this project`
        : `${this.recordMember.fullName} from this survey`
    },
    dropdownOptionsStyle () {
      return {
        width: this.dropdownWidth + 'px'
      }
    },
    dropdownValues () {
      if (this.isPermissionsDropdown) {
        return Object.values(SURVEY_PERMISSIONS_CATEGORIES).filter(values => values !== this.matchSurveyPermission)
      } else {
        return this.projectMemberKinds.filter(values => values !== this.recordMember.kind)
      }
    },
    isKindDropdown: {
      get () {
        return this.member.kind
      },
      set (newValue) {
        this.checkIfAdmin(newValue)
      }
    },
    isPermissionsDropdown: {
      get () {
        return this.member.permission
      },
      set (newValue) {
        this.checkIfAdmin(newValue)
      }
    },
    matchSurveyPermission () {
      return SURVEY_PERMISSIONS_CATEGORIES[this.isPermissionsDropdown]
    },
    memberCanOpenDropdown () {
      if (this.projectId && this.member.kind === 'spacetrics_admin' && this.currentUser.kind !== 'spacetrics_admin') { return false }
      return this.canOpenDropdown
    },
    memberIsCurrentUser () {
      return this.recordMember.email === this.currentUser.email
    }
  },
  mounted () {
    if (this.memberIsCurrentUser) {
      this.$store.commit('setCurrentUser', { ...this.currentUser, permission: this.recordMember.permission })
    }

    this.$nextTick(() => {
      let deleteMessageWidth = 159
      let emailWidth = this.$refs.email.getBoundingClientRect().width
      let fullNameWidth = this.$refs.fullName.getBoundingClientRect().width
      let widestElement = Math.max(emailWidth, fullNameWidth)
      this.deleteMessageMargin = widestElement - deleteMessageWidth
    })
  },
  beforeDestroy () {
  },
  methods: {
    canDeleteMember () {
      if (this.memberCanOpenDropdown) { this.showDeleteOption = true }
    },
    checkIfAdmin (newValue, confirm) {
      if ((this.memberIsCurrentUser) && newValue) {
        this.showConfirmationMessage = true
        this.newAdminValue = newValue
        this.$emit('modalOpen')
      } else {
        this.confirmUpdate(newValue)
      }
    },
    closeConfirmationModal () {
      event.stopPropagation()
      this.showConfirmationMessage = false
      if (this.isDesktop) { this.$emit('modalClosed') }
    },
    closeDeleteModal () {
      event.stopPropagation()
      this.showDeleteMessage = false
      this.$emit('modalClosed')
    },
    closeFn (event) {
      if (!this.$refs[this.recordMember.id].contains(event.target)) { this.dropdownOpen = false }
    },
    closeDropdownIfLast (index) {
      if (index === this.dropdownValues.length - 1) { this.closeFn(event) }
    },
    confirmUpdate (newValue) {
      event.stopPropagation()
      this.isPermissionsDropdown ? this.updateMemberPermission(newValue) : this.updateMemberKind(newValue)
    },
    enterFn (event) {
      if (event.keyCode === 13) { this.closeFn(event) }
    },
    focusDelete () {
      if (this.memberCanOpenDropdown) {
        this.showDeleteOption = true
        this.$nextTick(() => { this.$refs.deleteMember.focus() })
      }
    },
    openMemberTypeDropdown () {
      if (this.memberCanOpenDropdown) {
        this.dropdownWidth = this.$refs[this.recordMember.id].getBoundingClientRect().width
        this.dropdownOpen = !this.dropdownOpen
      }
    },
    deleteProjectMember () {
      this.$axios.request({
        method: 'delete',
        url: `/projects/${this.projectId}/users`,
        data: {
          ids: [this.recordMember.id]
        }
      }).then(res => {
        this.closeDeleteModal()
        this.$emit('removeMember', res.data.users)
      })
        .catch(err => { console.log(err) })
    },
    deleteSurveyMember () {
      this.$axios.request({
        method: 'delete',
        url: `/survey_members/${this.recordMember.surveyMemberId}`
      }).then(res => {
        this.closeDeleteModal()
        this.$emit('removeMember', this.recordMember.surveyMemberId)
      })
        .catch(err => { console.log(err) })
    },
    openDeleteModal () {
      this.showDeleteMessage = true
      this.$emit('modalOpen')
    },
    removeMember () {
      this.projectId ? this.deleteProjectMember() : this.deleteSurveyMember()
    },
    setDropdownOption (option) {
      if (this.isPermissionsDropdown) {
        this.isPermissionsDropdown = Object.keys(SURVEY_PERMISSIONS_CATEGORIES).find(key => SURVEY_PERMISSIONS_CATEGORIES[key] === option)
      } else {
        this.isKindDropdown = option
      }
      this.dropdownOpen = false
    },
    updateMemberKind (newValue) {
      let url = `/projects/${this.projectId}/users`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: {
          id: this.recordMember.id,
          project_id: this.projectId,
          user: { kind: newValue }
        }
      }).then(res => { this.$emit('updateMember', { ...this.recordMember, kind: res.data.kind }) })
        .catch(err => { console.log(err) })
    },
    updateMemberPermission (newValue) {
      if (this.showConfirmationMessage) { this.showConfirmationMessage = false }
      let url = `/survey_members/${this.recordMember.surveyMemberId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: {
          id: this.recordMember.id,
          survey_id: this.surveyId,
          permission: newValue
        }
      }).then(res => { this.$emit('updateMember', { ...this.recordMember, permission: res.data.permission }) })
        .catch(err => { console.log(err) })
    }
  }
}
</script>
