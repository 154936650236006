<template>
  <div class="icon_view_password">
    <div class="icon_view_password__svg">
      <svg
        viewBox="0 0 16.099 16.047"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(.005 .354)">
          <path
            d="M6 3a3 3 0 11-3-3 3 3 0 013 3zM5 3a2 2 0 10-2 2 2 2 0 002-2z"
            transform="translate(5 4.684)"
          />
          <path
            d="M8 0c4.418 0 8 4 8 4s-3.582 4-8 4-8-4-8-4 3.582-4 8-4zM1.426 4C5.87 7.824 9.752 8.149 14.574 4 10.453.454 6.555-.233 1.957 3.543z"
            transform="translate(0 3.684)"
          />
          <path
            d="M15.388 16.009L-.005.671.7-.038 16.094 15.3z"
            transform="translate(0 -.316)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
