<template>
  <div
    :class="{
      'st-text-area': true,
      'st-text-area__focused': textAreaFocused
    }"
    @mouseenter="hover = true"
    @mouseleave="hover= false"
  >
    <transition
      name="fade"
    >
      <label
        v-if="(textAreaFocused && showLabel) || (value && hover)"
        :id="stUid"
        for="st-text-area"
        class="st-text-area__show-label-above"
      >
        {{ label }}
      </label>
    </transition>
    <div
      class="st-text-area__align-content"
    >
      <label
        v-if="!textAreaFocused && !value && showLabel"
        :id="stUid"
        for="st-text-area"
        :class="[{
          'st-text-area__show-label-in-field': true,
          'st-text-area__show-label-in-field--disabled': disabled
        }, labelSizeClass]"

        @click="reFocusTextArea()"
      >
        {{ label }}
      </label>
      <textarea
        :id="stUid"
        ref="stTextArea"
        rows="1"
        cols="1"
        :class="{
          'st-text-area__field': true,
          'st-text-area__field--toggle-mobile-on': textAreaFocused,
          'st-text-area__label': textAreaFocused && label,
          'st-text-area__field--disabled': disabled
        }"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        v-on="inputListeners"
        @keyup.enter="handleEnter"
      />
    </div>
    <button
      v-if="button && hasFocus"
      class="st-text-area__button"
      @click="hasFocus = false"
    >
      <icon-wrapper
        icon-name="check-mark"
        :invert="true"
      />
    </button>
  </div>
</template>

<script>
import StUid from '../../mixins/uid'
import { IconWrapper } from './'

export default {
  components: {
    IconWrapper
  },
  mixins: [StUid],
  props: {
    /** Used to show a blue button on the right side of st-text-area. */
    button: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Blocks the text area from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Name of textarea field. */
    label: {
      type: String,
      required: false,
      default: ''
    },
    /** Change the size of the label of the text area (larger areas) */
    labelSizeClass: {
      type: String,
      required: false,
      default: ''
    },
    /** Textarea name. */
    name: {
      type: String,
      required: false,
      default: ''
    },
    /** Example text of textarea field. */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    /** Determines if the label appears above the textarea. */
    showLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    /** Characters entered by the user. */
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      hasFocus: false,
      hover: false
    }
  },
  computed: {
    inputListeners () {
      return {
        ...this.$listeners,
        input: this.handleInput,
        focus: this.onTextAreaFocus,
        focusout: this.checkFocusOut
      }
    },
    textAreaFocused () {
      return this.hasFocus
    }
  },
  methods: {
    checkFocusOut (ev) {
      this.hasFocus = false
      this.hover = false
      if (window.innerWidth <= 576) { this.hover = false }
      if (this.$listeners.hasOwnProperty('focusout')) { this.$listeners.focusout(ev) }
    },
    focus () {
      this.$refs.stTextArea.focus()
    },
    handleEnter (ev) {
      this.$emit('emitEnter')
      this.hasFocus = false
    },
    handleInput (ev) {
      this.$emit('input', ev.target.value)
    },
    onTextAreaFocus (ev) {
      this.hasFocus = true
      if (this.$listeners.hasOwnProperty('focus')) { this.$listeners.focus(ev) }
    },
    reFocusTextArea () {
      this.$refs.stTextArea.focus()
    }
  }
}
</script>
