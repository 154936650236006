<template>
  <div class="welcome">
    <div class="mobile-header--centered">
      <a
        href="/welcome"
      >
        <div class="mobile-header__centered-icon">
          <icon-wrapper
            icon-name="spacetrics"
          />
        </div>
      </a>
    </div>
    <div
      class="edit-cover-photo__photo-and-button"
      tabIndex="0"
      @focus="focusEditCoverPhoto"
      @focusout="coverPhotoHovered = false"
      @mouseleave="coverPhotoHovered = false"
      @mouseover="coverPhotoHovered = true"
    >
      <div
        :class="{
          'edit-cover-photo__photo': true,
          'edit-cover-photo__default': !useLargeDefaultCoverPhoto,
          'edit-cover-photo__default--large': useLargeDefaultCoverPhoto,
          'edit-cover-photo__photo--hovered': coverPhotoHovered
        }"
        :style="companyCoverPhotoStyle"
      />
      <transition name="fade">
        <div
          v-if="coverPhotoHovered && isUserAdmin"
          ref="editCoverPhotoButton"
          :class="{
            'edit-cover-photo__button edit-cover-photo__button--welcome-page ': true,
            'edit-cover-photo__button--active': editCoverPhotoActive
          }"
          tabIndex="0"
          @focus="coverPhotoHovered = true"
          @keyup.enter="openMediaUploader('cover_image')"
          @mouseup="openMediaUploader('cover_image')"
        >
          Change Cover Photo
        </div>
      </transition>
    </div>
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--offset-xxl-2 bx--col-xxl-8 bx--offset-sm-1 bx--col-sm-10 bx--col-xs-12 welcome__company-logo-and-welcome-message">
          <div
            class="edit-company-logo__hover-to-edit"
            tabIndex="0"
            @focus="companyLogoHovered = true"
            @focusout="companyLogoHovered = false"
            @keyup.enter="openMediaUploader('logo')"
            @mouseenter="companyLogoHovered = true"
            @mouseleave="companyLogoHovered = false"
          >
            <transition name="fade-div">
              <div
                v-if="companyLogoHovered && isUserAdmin"
                :class="{
                  'edit-company-logo': true,
                  'edit-company-logo--active': editCompanyLogoActive
                }"
                @mouseup="openMediaUploader('logo')"
              >
                <transition-group
                  name="fade"
                  class="edit-company-logo__transition-group"
                >
                  <icon-wrapper
                    key="uploadIcon"
                    :class="{
                      'edit-company-logo__icon': true,
                      'edit-company-logo__icon--active': editCompanyLogoActive
                    }"
                    icon-name="upload"
                    :invert="true"
                  />
                  <span
                    v-if="companyLogoHovered && !editCompanyLogoActive"
                    key="editCompanyLogoInstructions"
                    class="edit-company-logo__instructions"
                  >
                    Change Logo
                  </span>
                </transition-group>
              </div>
            </transition>
            <div
              class="edit-company-logo__company-logo"
              :style="companyLogoStyle"
            />
          </div>
          <h1 class="page-header welcome__header-space">
            Hi {{ currentUser.firstName }}, happy {{ new Date().toLocaleString('en-us', { weekday: 'long' }) }}!
          </h1>
        </div>
        <div class="bx--offset-xxl-2 bx--col-xxl-8 bx--offset-sm-1 bx--col-sm-10 bx--col-xs-12 welcome__next-and-cards">
          <p class="welcome__next-text">
            What would you like to do next?
          </p>
          <div class="welcome__cards">
            <st-card
              v-for="(card, index) in welcomeCards"
              :key="index"
              tabindex="0"
              class="welcome-card__wrapper"
              @click="advanceToUrl(card.url)"
            >
              <template v-slot:card-content>
                <div class="welcome-card__contents">
                  <div class="welcome-card__icon-and-title">
                    <icon-wrapper
                      class="welcome-card__icon"
                      :icon-name="card.icon"
                    />
                    <p class="welcome-card__title">
                      {{ card.title }}
                    </p>
                  </div>
                  <p class="welcome-card__description">
                    {{ card.description }}
                  </p>
                </div>
              </template>
            </st-card>
          </div>
        </div>
      </div>
    </div>
    <st-media-uploader
      v-if="showMediaUploader"
      :aspect-ratio="mediaUploaderPropValues.mediaUploaderAspectRatio"
      :folders="folders"
      :prop-name="`account[${mediaType}]`"
      :modal-title="mediaUploaderPropValues.mediaUploaderModalTitle"
      :url="`/accounts/${currentUser.accountId}`"
      @closeMediaUploader="showMediaUploader = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { advanceToUrl, companyLogoStyle, isUserAdmin } from '../../mixins'
import { IconWrapper, StCard, StMediaUploader } from '../common/'

export default {
  components: {
    IconWrapper,
    StCard,
    StMediaUploader
  },
  mixins: [advanceToUrl, companyLogoStyle, isUserAdmin],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      companyLogoHovered: false,
      coverPhotoHovered: false,
      editCompanyLogoActive: false,
      editCoverPhotoActive: false,
      mediaType: '',
      showMediaUploader: false,
      welcomeCards: [{
        icon: 'request-audience',
        description: 'Order a set of recipients based on specific demographics. It will take 5-7 days for us to collect your audience.',
        title: 'Request an Audience',
        url: '/audience/request'
      },
      {
        icon: 'projects',
        description: 'Gather unique data by starting a project and publishing it.',
        title: 'Start a Project',
        url: '/projects/new'
      },
      {
        icon: 'survey-template',
        description: 'Get ahead and save time by creating survey templates.',
        title: 'Create a Template',
        url: '/templates/new'
      }]
    }
  },
  computed: {
    ...mapState(['currentUser']),
    companyCoverPhotoStyle () {
      return this.currentUser.companyCoverImageUrl ? { 'background-image': `url('${this.currentUser.companyCoverImageUrl}')` } : {}
    },
    companyLogo () {
      return this.currentUser.companyLogoUrl
    },
    mediaUploaderPropValues () {
      let propValues = {}
      if (this.mediaType === 'logo') {
        propValues['mediaUploaderAspectRatio'] = 1.0
        propValues['mediaUploaderModalTitle'] = 'Change Company Logo'
      } else {
        propValues['mediaUploaderAspectRatio'] = 4.23
        propValues['mediaUploaderModalTitle'] = 'Change Company Cover Photo'
      }
      return propValues
    },
    useLargeDefaultCoverPhoto () {
      let useLarge = null
      if (!this.currentUser.companyCoverImageUrl) { (this.$store.state.navOpen) ? useLarge = false : useLarge = true }
      return useLarge
    }
  },
  methods: {
    focusEditCoverPhoto () {
      if (this.isUserAdmin) {
        this.coverPhotoHovered = true
        this.$nextTick(() => { this.$refs.editCoverPhotoButton.focus() })
      }
    },
    openMediaUploader (mediaType) {
      this.mediaType = mediaType;
      (this.mediaType === 'logo') ? this.editCompanyLogoActive = true : this.editCoverPhotoActive = true

      setTimeout(() => {
        this.showMediaUploader = true;
        (this.mediaType === 'logo') ? this.editCompanyLogoActive = false : this.editCoverPhotoActive = false
      }, 400)
    },
    uploadComplete (data) {
      this.showMediaUploader = false;
      (this.mediaType === 'logo')
        ? this.$store.commit('setCurrentUser', { ...this.currentUser, 'companyLogoUrl': data.logoUrl })
        : this.$store.commit('setCurrentUser', { ...this.currentUser, 'companyCoverImageUrl': data.coverImageUrl })
    }
  }
}
</script>
