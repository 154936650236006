<template>
  <div class="icon_hub">
    <div class="icon_hub__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h4v4H0zm3 3V1H1v2z" />
        <path
          d="M0 0h4v4H0zm3 3V1H1v2z"
          transform="translate(0 12)"
        />
        <path
          d="M0 0h4v4H0zm3 3V1H1v2z"
          transform="translate(12)"
        />
        <path
          d="M0 0h4v4H0zm3 3V1H1v2z"
          transform="translate(12 12)"
        />
        <path
          d="M0 0h4v4H0zm3 3V1H1v2z"
          transform="translate(6 6)"
        />
        <path
          d="M-23178.27-27.846l3.119 3.059-.7.714-3.119-3.059z"
          transform="translate(23182 31)"
        />
        <path
          d="M-23178.969-24.788l3.119-3.059.7.714-3.119 3.059z"
          transform="translate(23182 37)"
        />
        <path
          d="M-23175.148-27.132l-3.119 3.059-.7-.714 3.119-3.059z"
          transform="translate(23187.998 31)"
        />
        <path
          d="M-23175.848-24.074l-3.119-3.059.7-.714 3.119 3.059z"
          transform="translate(23187.998 37)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
