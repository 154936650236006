<template>
  <div class="icon_microphone">
    <div class="icon_microphone__svg">
      <svg
        viewBox="0 0 12 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h8v1H0z"
          transform="translate(2)"
        />
        <path
          d="M0 0h8v1H0z"
          transform="translate(2 11)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(2 9)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(2 6)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(2 3)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(7 9)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(7 6)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(7 3)"
        />
        <path
          d="M0 0h12v1H0z"
          transform="translate(0 13)"
        />
        <path
          d="M0 0h8v1H0z"
          transform="translate(2 15)"
        />
        <path
          d="M0 0h1v12H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h1v12H0z"
          transform="translate(2)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(0 10)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(11 10)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(6 13)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
