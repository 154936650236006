<template>
  <div class="icon_billing">
    <div class="icon_billing__svg">
      <svg
        viewBox="0 0 16.001 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h1v14H0z" />
        <path
          d="M0 0h1v3.5H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h4.9v1H0z"
          transform="translate(.001 13)"
        />
        <path
          d="M0 0h10v1H0z"
          transform="translate(.001)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(2.001 3)"
        />
        <path
          d="M0 0h2.424v1H0z"
          transform="translate(2.001 6)"
        />
        <path
          d="M0 0h1.535v1H0z"
          transform="translate(2.134 9)"
        />
        <path
          d="M13 6.5A6.5 6.5 0 116.5 0 6.5 6.5 0 0113 6.5zm-1 0A5.5 5.5 0 106.5 12 5.5 5.5 0 0012 6.5z"
          transform="translate(3.001 3)"
        />
        <path
          d="M.5 0v2.082h-1V0z"
          transform="translate(9.501 11.918)"
        />
        <path
          d="M.5 0v1.824h-1V0z"
          transform="translate(9.501 5)"
        />
        <path
          d="M-23408.588-121.337c-.264 1.643 3.652.048 4.578 2.078a1.878 1.878 0 01-.213 1.786c-.9 1.184-3.279.678-5.2.159l.262-.965c1.031.279 3.924 1.033 4.232.024.605-1.982-3.475-.4-4.48-2.141a1.771 1.771 0 01.068-1.728c.846-1.339 3.527-.994 4.891-.4l-.4.915c-1.025-.446-3.564-.824-3.738.272z"
          transform="translate(23416.293 129.375)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
