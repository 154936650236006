import { forIn } from 'lodash'

import { convertNumericProps } from './_helpers'
import { bucketIdx } from '../utilities/data_utilities'
import { ViewpointTranscript } from '../structures/timed-transcript'
import { TimedSentence } from '../structures/timed-sentence'

function propConverter (obj) {
  return (prop) => {
    obj[prop] = Number(obj[prop])
  }
}

function propArrayConverter (obj) {
  return (prop) => {
    let numeric = obj[prop].map(val => Number(val))
    obj[prop] = numeric
  }
}

const PROPS = ['sentimentMagnitude', 'sentimentScore']
const ENTITY_PROPS = PROPS.concat('salience')
const DEFAULT_PROMPT_TEXT = ''

function convertNumericProperties (response) {
  PROPS.concat('transcriptionTotalSeconds').forEach(propConverter(response))
  response.roomSentiments.forEach(roomSentiment => {
    PROPS.forEach(propConverter(roomSentiment))
    roomSentiment.entitySentiments.forEach(roomEntitySentiment => {
      ENTITY_PROPS.forEach(propConverter(roomEntitySentiment))
    })
  })
  response.entitySentiments.forEach(entitySentiment => {
    ENTITY_PROPS.forEach(propConverter(entitySentiment))
  })
  forIn(response.viewpointTranscripts, (val, key) => {
    ['endTimes', 'startTimes'].forEach(propArrayConverter(val))
  })
  return response
}

function initializeViewpointTranscripts (response) {
  forIn(response.viewpointTranscripts, (val, key) => {
    response.viewpointTranscripts[key] = new ViewpointTranscript(val.transcripts, val.startTimes, val.endTimes, response)
  })
  return response
}

function initializeRoomTranscripts (response, getters) {
  let transcriptObject = {}
  response.roomSentiments.forEach(room => {
    transcriptObject[room.roomId] = []
  })
  forIn(response.viewpointTranscripts, (viewpointTranscript, identifier) => {
    addViewpointTimedTranscriptsToRoom(transcriptObject, viewpointTranscript, identifier, getters)
  })
  forIn(transcriptObject, roomTranscript => {
    sortTimedTranscriptsForRoom(roomTranscript)
  })
  response.roomTranscripts = transcriptObject
}

function initializeSentences (response) {
  let sentences = response.sentences.map(values => new TimedSentence(values))
  response.sentences = sentences
}

function addViewpointTimedTranscriptsToRoom (transcriptObject, viewpointTranscript, identifier, getters) {
  let prompt = getters.promptByViewpointIdentifier[identifier] || DEFAULT_PROMPT_TEXT
  let timedTranscripts = viewpointTranscript.timedTranscripts.map(timedTranscript => {
    return {
      ...timedTranscript,
      prompt: prompt
    }
  })
  let roomId = getters.roomIdByViewpointIdentifier[identifier]
  if (transcriptObject.hasOwnProperty(roomId)) {
    transcriptObject[roomId] = transcriptObject[roomId].concat(timedTranscripts)
  }
}

function sortTimedTranscriptsForRoom (roomTranscripts) {
  roomTranscripts.sort((a, b) => a.startTime - b.startTime)
}

export class QuestionResponse {
  constructor (rawResult, _getters) {
    Object.assign(this, rawResult)
    const numericProps = ['sentimentScore', 'sentimentMagnitude']
    convertNumericProps.call(this, numericProps)
  }
}

export class OpinionMetricResponse extends QuestionResponse {
  constructor (rawResult, _getters) {
    super(rawResult)
    this.sentimentValueIdx = bucketIdx(this.sentimentScore)
    this.entitySentiments.forEach(entity => {
      entity.sentimentScore = parseFloat(entity.sentimentScore)
      entity.sentimentValueIdx = bucketIdx(entity.sentimentScore)
    })
  }
}

export class VirtualTourResponse extends QuestionResponse {
  constructor (rawResult, getters) {
    super(rawResult)
    this.sentimentValueIdx = bucketIdx(this.sentimentScore)
    convertNumericProperties(this)
    initializeViewpointTranscripts(this)
    initializeRoomTranscripts(this, getters)
    initializeSentences(this)
  }
}

export function questionResponseFactory (rawResult, getters) {
  switch (getters.activeQuestion.questionType) {
    case 'opinion_metric':
      return new OpinionMetricResponse(rawResult)

    case 'virtual_tour':
      return new VirtualTourResponse(rawResult, getters)

    default:
      return new QuestionResponse(rawResult)
  }
}
