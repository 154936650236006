<template>
  <div class="st-insights-transcripts__blocks-container">
    <div class="st-insights-transcripts__dropdown-container">
      <div class="st-insights-transcripts__dropdown">
        <div class="st-insights-transcripts__dropdown-head">
          <span class="st-insights-transcripts__dropdown-img" />
          <span class="st-insights-transcripts__dropdown-heading">
            Marble Interior
          </span>
          <icon-wrapper
            class="st-insights-transcripts__dropdown-caret"
            icon-name="caret-down"
          />
        </div>
      </div>
      <div class="st-insights-transcripts__dropdown st-insights-transcripts__dropdown-open">
        <div class="st-insights-transcripts__dropdown-head">
          <span class="st-insights-transcripts__dropdown-heading">
            All Images
          </span>
          <icon-wrapper
            class="st-insights-transcripts__dropdown-caret"
            icon-name="caret-down"
          />
        </div>
        <div class="st-insights-transcripts__dropdown-canvas">
          <div class="st-insights-transcripts__dropdown-menu">
            <div class="st-insights-transcripts__dropdown-item-row">
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
              <div class="st-insights-transcripts__dropdown-item-col">
                <div class="st-insights-transcripts__dropdown-item" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(response) in responsesPassingParticipantCheckboxFilter"
      :key="'response-' + response.id"
      ref="stInsightsTranscriptsBlock"
      class="st-insights-transcripts__block"
    >
      <div class="st-insights-transcripts__avatar-and-sentiment">
        <div class="st-insights-transcripts__avatar-and-name">
          <participant-avatar
            class="st-insights-transcripts__avatar"
            :participant="response"
          />
          <p class="st-insights-transcripts__name">
            {{ response.fullName || 'Anonymous' }}
          </p>
        </div>
        <div :class="[{'st-insights-transcripts__sentiment': true}, keywordColor(response.sentimentScore)]">
          {{ keywordBucket(response.sentimentScore) }}
        </div>
      </div>
      <div
        v-if="editResponse && response.id === editResponse.id"
        class="st-insights-transcripts__edit-opinion-transcript"
      >
        <textarea
          ref="textarea"
          v-model="editTranscriptText"
          class="st-input st-virtual-tour-insights__full-transcript-input"
          type="text"
          placeholder=" "
          :style="{
            minHeight: textAreaHeight
          }"
          @input="reStyleMinHeight"
        />
        <div class="st-virtual-tour-specs__prompt-actions">
          <div
            class="st-virtual-tour-specs__prompt-action st-virtual-tour-specs__prompt-action--cancel"
            @click="editResponse=null"
          >
            Cancel
          </div>
          <div
            class="st-virtual-tour-specs__prompt-action"
            @click="saveChanges"
          >
            Save
          </div>
        </div>
      </div>
      <template v-else>
        <p class="st-insights-transcripts__transcript">
          {{ response.transcript }}
        </p>

        <div
          v-if="response.srcUrl"
          class="st-virtual-tour-insights__transcript-actions"
        >
          <div
            class="st-virtual-tour-insights__play-audio-transcript"
            @click="playTranscript(response)"
          >
            <icon-wrapper
              icon-name="play"
              class="st-virtual-tour-insights__play-audio-transcript-icon"
            />
          </div>
          <div
            class="st-virtual-tour-insights__edit-transcript"
            @click="openEditMode(response)"
          >
            <icon-wrapper icon-name="edit" />
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="activeAudioUrl"
      :class="{
        'st-virtual-tour-insights__audio-bar': true,
        'st-virtual-tour-insights__audio-bar--panel-open': activePanelSection
      }"
    >
      <audio
        ref="transcriptAudio"
        class="st-virtual-tour-insights__full-audio"
        autoplay
        controls
        @timeupdate="handleTimeUpdate"
      >
        <source
          :src="activeAudioUrl"
          :type="activeAudioContentType"
        >
        <source
          v-if="activeAudioFlacUrl"
          :src="activeAudioFlacUrl"
          type="audio/x-flac"
        >
      </audio>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { kebabCase } from 'lodash'
import { bucketIdx } from '../../../../utilities/data_utilities'
import ParticipantAvatar from '../participant-avatar'

import { IconWrapper } from '../../../common'

const bucketNames = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

export default {
  components: {
    IconWrapper,
    ParticipantAvatar
  },
  props: {
  },
  data: function () {
    return {
      activeResponse: {},
      editResponse: null,
      editedResponseIds: [],
      editTranscriptText: '',
      anyChanges: false,
      currentAudioTime: 0,
      textAreaHeight: ''
    }
  },
  computed: {
    ...mapState('insights', ['activePanelSection']),
    ...mapGetters('insights', ['responsesPassingParticipantCheckboxFilter']),
    activeAudioFlacUrl () {
      return this.activeResponse.flacSrcUrl
    },
    activeAudioUrl () {
      return this.activeResponse.srcUrl
    },
    activeAudioContentType () {
      return this.activeResponse.contentType
    }
  },
  mounted () {
    if (this.$refs.stInsightsTranscriptsBlock && this.$refs.stInsightsTranscriptsBlock.length > 0) {
      this.$refs.stInsightsTranscriptsBlock[0].scrollIntoView({
        block: 'center',
        behavior: 'instant'
      })
    }
  },
  beforeDestroy () {
    if (!this.anyChanges) { return }

    this.editedResponseIds.forEach(responseId => {
      this.$axios.patch(`/question_responses/${responseId}/reanalyze`)
        .then(res => console.log('success'))
        .catch(err => console.log('error reanalyzing', err))
    })
  },
  methods: {
    avatarStyle (avatar) {
      return avatar ? { 'background-image': `url('${avatar}')` } : {}
    },
    keywordBucket (sentiment) {
      return bucketNames[bucketIdx(sentiment)]
    },
    keywordColor (sentiment) {
      return { [`st-sentiment-badge--${kebabCase(this.keywordBucket(sentiment))}`]: true }
    },
    playTranscript (response) {
      if (response.srcUrl !== this.activeAudioUrl) {
        this.activeResponse = response
        this.$nextTick(() => {
          this.$refs.transcriptAudio.load()
        })
      }
    },
    openEditMode (response) {
      this.editResponse = response
      this.editTranscriptText = response.transcript
      if (this.activeAudioUrl !== response.audioUrl) {
        this.activeAudioUrl = response.srcUrl
        if (response.srcUrl) {
          this.$nextTick(() => {
            this.$refs.transcriptAudio.load()
          })
        }
      }
      this.$nextTick(() => {
        this.reStyleMinHeight()
      })
    },
    exitEditMode () {
      this.editResponse = null
      this.editTranscriptText = ''
    },
    handleTimeUpdate (ev) {
      this.currentAudioTime = ev.srcElement.currentTime
    },
    saveChanges () {
      this.anyChanges = true
      let url = `/question_responses/${this.editResponse.id}/culture_transcript`
      let data = {
        transcript: this.editTranscriptText
      }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: data
      })
        .then(res => {
          this.$store.dispatch('insights/replaceEditedResponse', res.data)
          this.editedResponseIds.push(this.editResponse.id)
          this.$nextTick(() => {
            this.editTranscriptText = ''
            this.editResponse = null
          })
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    reStyleMinHeight () {
      this.textAreaHeight = this.$refs.textarea[0].scrollHeight + 'px'
    }
  }
}
</script>
