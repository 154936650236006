var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pick-favorite__container"},[_c('div',{class:{
      'pick-favorite': true,
      '__2': _vm.options.length === 2,
      '__4': _vm.options.length === 4
    }},_vm._l((_vm.options),function(image){return _c('div',{key:image.optionId,class:{
        'pick-favorite__image-2': _vm.options.length === 2,
        'pick-favorite__image-4': _vm.options.length === 4,
        'is-selected': _vm.selectedId == image.optionId,
        'unselected': _vm.options.length===2 && _vm.selectedId != null && _vm.selectedId != image.optionId,
        'first-image': _vm.options.length === 4 && _vm.selectedId === _vm.options[0].optionId,
        'second-image': _vm.options.length === 4 && _vm.selectedId === _vm.options[1].optionId,
        'third-image': _vm.options.length === 4 && _vm.selectedId === _vm.options[2].optionId,
        'fourth-image': _vm.options.length === 4 && _vm.selectedId === _vm.options[3].optionId,
      },style:({
        background: _vm.backgroundProp(image.imageUrl),
        backgroundSize: '100% 100%'
      }),on:{"click":function($event){return _vm.handleSelect(image.optionId)}}},[(image.name)?_c('div',{staticClass:"pick-favorite__image-4-gradient"},[_vm._v("\n        "+_vm._s(image.name)+"\n      ")]):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"drag-container__submit"},[(_vm.isSurveyPreview && _vm.priority > 0)?_c('cv-button',{staticClass:"st-question__preview-back-btn",attrs:{"kind":"secondary"},on:{"click":_vm.handlePreviewBack}},[_c('div',{staticClass:"st-page__btn-slot"},[_c('div',{staticClass:"st-svg__left-chevron"}),_vm._v(" "),_c('div',[_vm._v("Back")])])]):_vm._e(),_vm._v(" "),(_vm.selectedId != null || _vm.isSurveyPreview)?_c('cv-button',{staticClass:"next-btn",on:{"click":_vm.submitQuestionAnswer}},[_c('div',[_vm._v("\n        Next\n        "),_c('div',{staticClass:"btn-icon"})])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }