<template>
  <iframe
    :allow="iframeProperties"
    allowfullscreen
    frameborder="0"
    height="100%"
    :src="videoSrc"
    width="100%"
  />
</template>

<script>
export default {
  components: {
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    iframeProperties () {
      return this.isExternalVideoVimeo
        ? 'autoplay; fullscreen'
        : 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
    },
    isExternalVideoVimeo () {
      return this.url.includes('vimeo.com')
    },
    videoSrc () {
      return this.isExternalVideoVimeo
        ? `https://player.vimeo.com/video/${this.getVimeoId(this.url)}`
        : `https://www.youtube.com/embed/${this.getYoutubeId(this.url)}`
    }
  },
  methods: {
    getVimeoId (url) {
      // source: https://stackoverflow.com/questions/2916544/parsing-a-vimeo-id-using-javascript
      let regEx = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
      return regEx.exec(url)[5]
    },
    getYoutubeId (url) {
      // source: https://gist.github.com/takien/4077195
      let id = ''
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      if (url[2] !== undefined) {
        id = url[2].split(/[^0-9a-z_-]/i)
        id = id[0]
      } else {
        id = url
      }
      return id
    }
  }
}
</script>
