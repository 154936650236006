<template>
  <div class="icon_view_password">
    <div class="icon_view_password__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 3a3 3 0 11-3-3 3 3 0 013 3zM5 3a2 2 0 10-2 2 2 2 0 002-2z"
          transform="translate(5 5)"
        />
        <path
          d="M8 0c4.418 0 8 4 8 4s-3.582 4-8 4-8-4-8-4 3.582-4 8-4zM1.426 4C5.87 7.824 9.752 8.149 14.574 4 10.453.454 6.555-.233 1.957 3.543z"
          transform="translate(0 4)"
        />
        <path d="M0 0h1v1H0z" />
        <path
          d="M0 0h1v1H0V0z"
          transform="translate(15 15)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
