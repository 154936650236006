import { formatSecondsToStopwatch } from '../utilities/data_utilities'

function numericSet (stringValues) {
  let numericSet = stringValues.map(val => Number(val))
  return new Set(numericSet)
}

export class TimedSentence {
  constructor (rawSentenceObject) {
    this.text = rawSentenceObject.text
    this.startSecond = Number(rawSentenceObject.startSecond)
    this.endSecond = Number(rawSentenceObject.endSecond)
    this.roomIds = numericSet(rawSentenceObject.roomIds)
    this.panoIdentifiers = new Set(rawSentenceObject.panoIdentifiers)
    this.topics = new Set(rawSentenceObject.topics)
  }

  occursOverPano (identifier) {
    return this.panoIdentifiers.has(identifier)
  }

  occursOverRoom (roomId) {
    return this.roomIds.has(roomId)
  }

  containsText (keyword) {
    return this.text.includes(keyword)
  }

  get formattedTimes () {
    return `${formatSecondsToStopwatch(this.startSecond)} - ${formatSecondsToStopwatch(this.endSecond)}`
  }
}
