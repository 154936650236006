<template>
  <div
    :class="{
      'st-participant__container': true,
      'st-participant__container--demo': isDemoPreview
    }"
  >
    <div
      :class="{
        'st-participant__header': true,
        'st-participant__header--hide': hideLogoHeader || question.questionType === 'virtual_tour',
        'st-participant__header--desktop': isDesktop,
        'st-participant__header--full-width': question.fullWidth
      }"
    >
      <a
        href="#"
        class="st-participant__back-icon"
        @click="$goBack"
      >
        <icon-wrapper
          class="st-hyperlink__back-icon"
          icon-name="chevron"
        />
      </a>
      <st-hyperlink
        v-if="isPreview && !isDesktop"
        text="Exit Preview"
        icon-name="hide-password"
        icon-class="st-hyperlink__default-icon-spacing"
        :url="`${baseUrl}/surveys/${surveyId}/edit/content?question=${question.id}&type=${question.questionType}`"
      />
      <div
        v-else
        class="st-participant__question-count"
      >
        {{ question.priority + 1 }} of {{ surveyQuestionCount }}
      </div>
    </div>
    <div
      :class="{
        'st-participant__component-contain': !isDemoPreview,
        'st-participant__component-and-footer': isDesktop,
        'st-participant__component-and-footer--demo-preview': isDemoPreview
      }"
    >
      <div
        :class="{
          'bx--grid': question.questionType !== 'virtual_tour',
          'st-participant__component-wrapper': true,
          'st-participant__component-wrapper--hide-logo-header': hideLogoHeader,
          'st-participant__component-wrapper--full-width': fullWidth,
          'st-participant__component-wrapper--virtual-tour': question.questionType === 'virtual_tour',
          'st-participant__component-wrapper--demo-preview': isDemoPreview
        }"
      >
        <component
          :is="componentName"
          :key="question.id"
          :ask-for-explanation="question.askForExplanation"
          :description="question.description"
          :image="question.image"
          :image-url="question.imageUrl"
          :options="question.options"
          :question-id="question.id"
          :priority="question.priority"
          :prompt-lookup="question.promptLookup"
          :logo-url="companyLogo"
          :min-x-label="question.minXLabel"
          :max-x-label="question.maxXLabel"
          :min-y-label="question.minYLabel"
          :max-y-label="question.maxYLabel"
          :media-full-url="question.mediaFullUrl"
          :media-url="question.mediaUrl"
          :matterport-id="question.matterportId"
          :panoskin-id="question.panoskinId"
          :media="question.media"
          :next-question-id="question.nextQuestionId || 0"
          :next-preview-question="question.nextPreviewQuestion"
          :previous-preview-question="question.previousPreviewQuestion"
          :survey-id="surveyId"
          :survey-question-count="surveyQuestionCount"
          :is-demo-preview="isDemoPreview"
          :is-preview="isPreview"
          :is-join-survey="isJoinSurvey"
          :is-survey-preview="isPreview"
          :question-responses="question.questionResponses"
          :is-single-question-survey="surveyQuestionCount === 1"
          :can-skip="question.canSkip"
          :scale-length="question.scaleLength"
          :scale-style="question.scaleStyle"
          :spacetrics-logo="spacetricsLogo"
          :button-caption="buttonCaption"
          :show-question-instructions="question.showInstructions"
          @errorMessage="errorMessage"
          @hideLogoHeader="handleHideLogoHeader"
          @hideSpacetricsFooter="handleHideSpacetricsFooter"
          @loading="active = true"
          @nextQuestion="nextQuestion"
        />
      </div>
    </div>
    <st-toast-notification
      v-if="showErrorToast"
      class="st-toast-notification--fixed"
      kind="error"
      title="Error"
      :caption="error"
      @click="showErrorToast = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sendNameEmail } from '../../../utilities/fullstory'
import { IconWrapper, StHyperlink, StToastNotification } from '../../common'
import { companyLogoStyle, isDesktop, setSurveyType } from '../../../mixins'
// import Loading from '@carbon/vue/src/components/cv-loading/cv-loading'
// import CultureBoard from './question_types/culture-board'
// import PerceptionSlider from './question_types/perception-slider'
// import QuadrantPlacement from './question_types/quadrant-placement'
// import SpatialAdjacencies from './question_types/spatial-adjacencies'
// import ElementPreferences from './question_types/element-preferences'
// import ConceptRanking from './question_types/concept-ranking'
// import PickFavorite from './question_types/pick-favorite'
// import PreferenceSlider from './question_types/preference-slider'
import * as QuestionTypes from './question_types'

export default {
  components: {
    IconWrapper,
    ...QuestionTypes,
    StHyperlink,
    StToastNotification
  },
  mixins: [companyLogoStyle, isDesktop, setSurveyType],
  props: {
    hideMatterportIndicator: {
      type: Boolean,
      required: false,
      default: false
    },
    initialQuestion: {
      type: Object,
      required: true
    },
    isDemoPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    participantEmail: {
      default: '',
      type: String,
      required: false
    },
    participantName: {
      default: '',
      type: String,
      required: false
    },
    participantToken: {
      default: "",
      type: String,
      required: false
    },
    questionResponses: {
      type: Array,
      required: false,
      default: () => []
    },
    surveyId: {
      type: Number,
      required: true
    },
    surveyQuestionCount: {
      type: Number,
      required: false,
      default: 0
    },
    surveyName: {
      type: String,
      required: true
    },
    spacetricsLogo: {
      type: String,
      required: false,
      default: ''
    },
    isJoinSurvey: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      active: false,
      companyLogo: this.initialQuestion.logoUrl,
      error: null,
      hideLogoHeader: false,
      hideSpacetricsFooter: false,
      question: this.initialQuestion,
      showErrorToast: false
    }
  },
  computed: {
    ...mapGetters('participants', ['fullWidth']),
    baseUrl () { return window.location.origin },
    componentName () {
      return this.question.questionType.replace('_', '-')
    },
    hideFullWidthFooter () {
      return this.fullWidth && this.isDesktop
    },
    buttonCaption () {
      if (this.question.priority + 1 === this.surveyQuestionCount) {
        return 'Submit'
      }
      return 'Next Question'
    },
    isStatic () {
      return this.question.askForExplanation !== 'voice_recording' || this.question.questionType === 'concept_ranking' || this.question.options.length <= 1
    }
  },
  mounted () {
    if (!this.isPreview) { sendNameEmail(this.participantName, this.participantEmail) }

    window.addEventListener('popstate', this.reloadQuestion)
    let state = { questionId: this.initialQuestion.id }
    let title = 'question: ' + this.question.id
    let url = '/questions/' + this.question.id

    if (this.isPreview) { url = '/questions/' + this.question.id + '/preview' }
    if (!this.isDemoPreview) { window.history.replaceState(state, title, url) }

    this.$store.commit('hideSidebar', true)
    this.$store.commit("participants/setParticipantSubmissionToken", { token: this.participantToken });
    this.$store.commit('participants/setActiveQuestion', this.question) // TBD: Update each component to refer to activeQuestion in participant store, rather than pass in via props
    this.$store.commit('participants/setMatterportIndicator', this.hideMatterportIndicator)
    this.$store.commit('participants/setParticipantEmail', this.participantEmail)
  },
  created () {
    this.setSurveyType()
  },
  methods: {
    errorMessage () {
      // TODO: make this a more specific message (emitted from submitQuestionAnswer mixin)
      this.error = 'There was a problem submitting your question answer.'
      this.showErrorToast = true
      this.active = false
    },
    handleHideLogoHeader (payload) {
      this.hideLogoHeader = payload
    },
    handleHideSpacetricsFooter (payload) {
      this.hideSpacetricsFooter = payload
    },
    nextQuestion (nextQuestionData) {
      this.$store.commit('participants/setActiveQuestion', nextQuestionData)
      this.$store.commit('toggleFullSpinner', false)
      this.question = nextQuestionData
      this.active = false
      let state = { questionId: nextQuestionData.id }
      let title = 'question: ' + nextQuestionData.id
      let url = '/questions/' + nextQuestionData.id

      if (this.isPreview) { url = '/questions/' + nextQuestionData.id + '/preview' }
      window.history.pushState(state, title, url)
    },
    reloadQuestion () {
      this.active = true
      this.$nextTick(() => {
        let url = `/questions/${window.history.state.questionId}/question_and_response.json`
        this.$axios.get(url).then(res => {
          this.question = res.data
          this.active = false
          this.submitting = false
          this.$store.commit('participants/setActiveQuestion', res.data)
        })
          .catch(err => { console.log(err) })
      })
    }
  }
}
</script>
