
<template>
  <div>
    <v-swatches
      v-model="color"
      shapes="circles"
      swatch-size="20"
      show-fallback
      fallback-input-type="color"
      popover-x="left"
      :swatches="colors"
      @input="handleColorChange"
      @close="handleClosed"
    />
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { SLIDE_COLORS } from '../constants'
export default {
  components: { VSwatches },
  props: ['boxIndex'],
  data () {
    return {
      color: '#1CA085',
      colors: SLIDE_COLORS
    }
  },
  methods: {
    
    handleClosed(){
      this.$emit('closed')
    },
    handleColorChange (color) {
      this.$emit('changed', color, this.boxIndex)
    }
  }
}
</script>
