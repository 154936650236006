var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-prospectives--how-works-space st-prospectives__dots"},[_c('div',{staticClass:"bx--grid"},[_c('div',{staticClass:"bx--row"},[_c('div',{staticClass:"bx--col-xs-12"},[_c('h1',{staticClass:"st-prospectives__heading st-prospectives__how-work-heading"},[_vm._v("\n          See how spacetrics works.\n        ")]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__pick-content"},[_c('h5',{staticClass:"st-prospectives__pick-title"},[_vm._v("\n            Pick a type of space\n          ")]),_vm._v(" "),_c('div',{staticClass:"bx--row st-prospectives__row-pick"},[_c('st-radio-button',{attrs:{"content-class":"st-prospectives-radio__radio-wrapper","hide-caption":true,"hide-until-active":true,"indicator-class":"st-prospectives-radio__indicator-wrapper","indicator-icon-class":"st-prospectives-radio__indicator-icon","options":_vm.spaceTypes.map(function (spaceType, index) {
                return { value: index}
              }),"variant":['fullBorder', 'lighterGreyBorder']},scopedSlots:_vm._u([{key:"preceding-input",fn:function(slotProps){return [_c('div',{staticClass:"st-space-type-radio__image-and-value"},[_c('div',{class:[{
                      'st-space-type-radio__image': true
                    }, _vm.spaceTypeImage(slotProps.option.value)]}),_vm._v(" "),_c('h6',{staticClass:"st-prospectives-radio__title"},[_vm._v("\n                    "+_vm._s(_vm.spaceTypes[slotProps.option.value])+"\n                  ")])])]}}]),model:{value:(_vm.spaceTypeIndex),callback:function ($$v) {_vm.spaceTypeIndex=$$v},expression:"spaceTypeIndex"}})],1)]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__pick-content st-prospectives__pick-content-use"},[_c('h5',{staticClass:"st-prospectives__pick-title"},[_vm._v("\n            Pick a use case\n          ")]),_vm._v(" "),_c('div',{staticClass:"bx--row st-prospectives__row-pick"},[_c('st-radio-button',{attrs:{"content-class":"st-prospectives-radio__radio-wrapper st-prospectives-radio__radio-wrapper--use-case","hide-caption":true,"hide-until-active":true,"indicator-class":"st-prospectives-radio__indicator-wrapper","indicator-icon-class":"st-prospectives-radio__indicator-icon","options":_vm.useCases.map(function (use, index) {
                return { value: index}
              }),"variant":['fullBorder', 'lighterGreyBorder']},scopedSlots:_vm._u([{key:"preceding-input",fn:function(slotProps){
              var _obj;
return [_c('div',{staticClass:"st-use-case-radio__image-and-value"},[_c('div',{class:( _obj = {
                      'st-use-case-radio__image': true
                    }, _obj[("st-use-case-radio__image--" + (slotProps.option.value))] = true, _obj )}),_vm._v(" "),_c('div',{staticClass:"st-use-case-radio__detail"},[_c('p',{staticClass:"st-prospectives-radio__title"},[_vm._v("\n                      "+_vm._s(_vm.useCases[slotProps.option.value])+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"st-use-case-radio__description"},[_vm._v("\n                      "+_vm._s(_vm.useCaseDescriptions[slotProps.option.value])+"\n                    ")])])])]}}]),model:{value:(_vm.useCaseIndex),callback:function ($$v) {_vm.useCaseIndex=$$v},expression:"useCaseIndex"}})],1)]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__button-canvas"},[_c('st-button',{staticClass:"st-prospectives__button st-prospectives__button--continue",attrs:{"caption":"Continue","disabled":_vm.isContinueDisabled,"icon-name":"arrow","show-action":true,"underline":false,"variant":['secondary']},on:{"click":_vm.advanceToSelectedDemo}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }