<template>
  <iframe
    :src="`https://fast.wistia.net/embed/iframe/${wistiaId}?videoFoam=true`"
    allow="autoplay; fullscreen"
    allowtransparency="true"
    frameborder="0"
    scrolling="no"
    allowfullscreen
    msallowfullscreen
    width="100%"
    height="100%"
  />
</template>

<script>
export default {
  props: {
    wistiaId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  beforeMount () {
    let script = document.createElement('script')
    script.src = 'https://fast.wistia.net/assets/external/E-v1.js'
    script.async = true
    document.head.appendChild(script)
  }
}
</script>
