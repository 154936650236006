<template>
  <div
    :class="{
      'space-between': true,
      'space-around': max === 3,
    }"
  >
    <div
      v-for="star in max"
      :key="star"
      @click="$emit('input', star)"
    >
      <icon-wrapper
        v-if="value >= star"
        icon-name="star-filled"
        class="st-star-scale__icon"
        :spacetrics-blue="true"
      />
      <icon-wrapper
        v-else
        icon-name="star-outline"
        class="st-star-scale__icon"
        :actionable="false"
      />
    </div>
  </div>
</template>
<script>
import IconWrapper from './icon-wrapper'

export default {
  components: {
    IconWrapper
  },
  props: {
    max: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data: function () {
    return {
    }
  }
}
</script>
