<template>
  <div
    :class="{
      'st-voice-response__voice-response-container': true,
    }"
  >
    <st-button
      v-if="isRecordButton"
      :class="{
        'st-voice-response__button': true
      }"
      caption="Record My Thoughts"
      icon-name="microphone"
      :underline="false"
      :variant="['secondary']"
      @click="initiateRecording"
    />
    <div
      v-else-if="isStopButton"
      :class="{
        'st-voice-response__stop-button-and-visualizer': true,

      }"
    >
      <button
        type="button"
        class="st-voice-response__audio-button-container"
        @click="stopRecording"
      >
        <div class="st-voice-response__stop-square" />
      </button>
      <canvas
        v-if="isVisualizerVisible"
        ref="audioCanvas"
        :width="canvasWidth"
        :height="canvasHeight"
        :class="{
          'st-opinion-metric__audio-visualizer': true,
        }"
      />
    </div>
    <audio
      v-if="isAudioPlayer"
      ref="audioPlayer"
      class="st-voice-response__audio-player"
      controls
    >
      Your browser does not support the <code>audio</code> element.
    </audio>
    <st-tooltip
      v-if="errorMessage"
      :always-visible="true"
      :max-width="errorTooltipWidth"
      :tip="errorMessage"
      message-class="st-voice-response__button-tooltip--error"
      :class="{
        'st-voice-response__button st-voice-response__record-thoughts-button': true,
      }"
      :is-active="isSpecificationsPreview"
    >
      <st-button
        caption="Next Question"
        class="st-voice-response__button"
        icon-name="arrow"
        :underline="false"
        :variant="['secondary']"
        @click="$emit('update-audio-blob', localAudioBlob)"
      />
    </st-tooltip>
    <div
      v-if="readyToSubmit"
      :class="{
        'st-voice-response__stop-button-and-visualizer': true,
      }"
    >
      <button
        v-if="readyToSubmit && !replayAudio"
        type="button"
        class="st-voice-response__audio-button-container"
        @click="playAgain"
      >
        <icon-wrapper
          class="st-voice-response__play-button"
          icon-name="play-button"
          :spacetrics-blue="true"
        />
      </button>
      <button
        v-if="readyToSubmit && replayAudio"
        type="button"
        class="st-voice-response__audio-button-container"
        @click="stopReplay"
      >
        <div class="st-voice-response__pause-bars">
          &#9612;
        </div>
        <div class="st-voice-response__pause-bars st-voice-response__pause-bars--right">
          &#9612;
        </div>
      </button>
      <p
        v-if="readyToSubmit"
        class="st-voice-response__audio-duration"
      >
        {{ formatAudioDuration }}
      </p>
      <div
        v-if="readyToSubmit"
        class="st-voice-response__trash-icon-container"
        @click="rerecord"
      >
        <icon-wrapper
          class="st-voice-response__trash-icon"
          icon-name="trash"
        />
      </div>
      <st-tooltip
        v-if="readyToSubmit"
        class="st-voice-response__button-tooltip"
        tip="Preview only button"
        :is-active="isSpecificationsPreview"
      >
        <st-button
          class="st-voice-response__button"
          caption="Next"
          icon-name="arrow"
          :underline="false"
          :variant="['secondary']"
          @click="$emit('update-audio-blob', localAudioBlob)"
        />
      </st-tooltip>
    </div>
  </div>
</template>

<script>
import { audioRecorder } from '../../mixins'
import { formatSecondsToStopwatch } from '../../utilities/data_utilities'
import { getBlobDuration } from '../../utilities/audio_utilities'
import IconWrapper from './icon-wrapper'
import StButton from './st-button'
import StTooltip from './st-tooltip'

export default {
  components: {
    IconWrapper,
    StButton,
    StTooltip
  },
  mixins: [audioRecorder],
  props: {
    askForExplanation: {
      type: String,
      required: false,
      default: ''
    },
    audioBlob: {
      type: Blob,
      required: false,
      default: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      audioPlayerSrc: '',
      audioDuration: null,
      errorMessage: '',
      localAudioBlob: this.audioBlob,
      readyToSubmit: false,
      replayAudio: false
    }
  },
  computed: {
    errorTooltipWidth () {
      return this.isDesktop ? 411 : window.innerWidth - 40
    },
    formatAudioDuration () {
      return formatSecondsToStopwatch(this.audioDuration)
    },
    isAudioPlayer () {
      return this.localAudioBlob !== null
    },
    isRecordButton () {
      if (this.mediaRecorder === null) { return false }
      if (this.isRecording) { return false }
      if (this.localAudioBlob !== null) { return false }
      return true
    },
    isSpecificationsPreview () {
      return this.isPreview && !this.isSurveyPreview
    },
    isStopButton () {
      return this.isRecording
    }
  },
  watch: {
    askForExplanation (newValue, oldValue) {
      if (newValue === 'voice_recording') {
        this.tryInitializingMediaRecorder()
          .then(() => {})
          .catch(() => {})
      }
    }
  },
  mounted () {
    if (this.audioPlayerSrc) {
      this.$refs.audioPlayer.src = this.audioPlayerSrc
      this.$refs.audioPlayer.load()
      this.readyToSubmit = true
    }

    this.tryInitializingMediaRecorder()
      .then(() => {})
      .catch(() => {})
  },
  methods: {
    onRecordingAvailable (blob) {
      this.localAudioBlob = blob
      this.readyToSubmit = true
      getBlobDuration(this.localAudioBlob).then(duration => { this.audioDuration = duration })
      this.$nextTick(() => { this.setAudioSrc() })
    },
    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime) { this.replayAudio = false }
      }
      this.replayAudio = true
    },
    setAudioSrc (audioUrl = '') {
      if (!this.isPreview) { audioUrl = window.URL.createObjectURL(this.localAudioBlob) }
      this.audioPlayerSrc = audioUrl
      this.$refs.audioPlayer.load()
    },
    stopReplay () {
      this.replayAudio = false
      this.$refs.audioPlayer.pause()
    },
    rerecord () {
      this.localAudioBlob = null
      this.isRecording = false
      this.readyToSubmit = false
    }
  }
}
</script>
