<template>
  <div class="st-prospectives--pricing">
    <div class="st-prospectives__pricing-intro">
      <div class="bx--grid">
        <div class="bx--row st-prospectives__row-pricing-intro">
          <div class="bx--col-xs-12 bx--col-lg-7 bx--col-xl-6">
            <div class="st-prospectives__detail-pricing-intro">
              <p
                class="st-prospectives__para-lg st-prospectives__para-pricing-intro"
              >
                Pick the right plan
              </p>
              <h1
                class="page-header st-prospectives__heading st-prospectives__heading-pricing-intro"
              >
                Select a subscription that works for your organization
              </h1>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-lg-5 bx--offset-xl-1 bx--col-xl-5">
            <div class="st-prospectives__img-pricing-intro" />
          </div>
        </div>
      </div>
    </div>
    <div class="st-prospectives__pricing-combine">
      <div class="bx--grid">
        <div class="st-prospectives__projects-head">
          <h2
            class="st-prospectives__heading-block st-prospectives__heading-project-asses"
          >
            How many projects do you need to asses?
          </h2>
          <p class="st-prospectives__para-md">
            Multiple projects gets you more data FOR A LOWER PRICE. More data =
            more knowledge over time.
          </p>
        </div>
        <div class="st-prospectives__program">
          <div class="st-prospectives__container-xs">
            <st-slider-input
              name="projects"
              min="1"
              max="41"
              :slider-value="totalProjects"
              :count-label="totalProjects"
              @input="sliderChange"
            />
          </div>
          <div class="st-prospectives__switch-toggle-canvas">
            <span
              class="st-prospectives__switch-toggle-text st-prospectives__switch-toggle-text-active"
            >month</span>
            <label class="st-prospectives__switch-toggle">
              <input
                v-model="yearEnabled"
                type="checkbox"
                class="st-prospectives__switch-input"
                :class="yearEnabled && 'active'"
              >
              <span class="st-prospectives__switch-slider" />
            </label>
            <span class="st-prospectives__switch-toggle-text">year</span>
          </div>
          <pricing-plans
            :year-enabled="yearEnabled"
            :total-projects="totalProjects"
            :basic-total="basicTotal"
            :premium-total="premiumTotal"
          />
        </div>
      </div>
    </div>
    <pricing-plan-comparison />
    <pricing-tabs
      :emp-sentiment="empSentiment"
      :tenant-prefs="tenantPrefs"
      :client-feedback="clientFeedback"
    />
  </div>
</template>

<script>
import { StSliderInput } from '../../common'
import PricingPlanComparison from './pricing-plan-comparison.vue'
import PricingPlans from './pricing-plans.vue'
import PricingTabs from './pricing-tabs'

export default {
  components: {
    PricingPlanComparison,
    PricingPlans,
    PricingTabs,
    StSliderInput
  },
  props: {},
  data: function () {
    return {
      totalProjects: '1',
      yearEnabled: false,
      empSentiment: true,
      tenantPrefs: false,
      clientFeedback: false
    }
  },
  computed: {
    basicTotal () {
      let val = this.totalProjects
      if (typeof val === 'string' && val.includes('+')) {
        val = parseInt(val) + 1
      }
      let basicValue

      // calculating plan cost
      switch (true) {
        case val <= 5:
          basicValue = val * 895
          break
        case val <= 10:
          basicValue = val * 820
          break
        case val <= 20:
          basicValue = val * 745
          break
        case val <= 40:
          basicValue = val * 670
          break
        case val > 40:
          basicValue = val * 595
          break

        default:
          break
      }

      if (this.yearEnabled) {
        return this.formatPrice(basicValue * 12)
      }

      return this.formatPrice(basicValue)
    },
    premiumTotal () {
      let val = this.totalProjects
      if (typeof val === 'string' && val.includes('+')) {
        val = parseInt(val) + 1
      }
      let premiumValue

      // calculating premium plan cost
      switch (true) {
        case val <= 5:
          premiumValue = val * 1295
          break
        case val <= 10:
          premiumValue = val * 1095
          break
        case val <= 20:
          premiumValue = val * 895
          break
        case val <= 40:
          premiumValue = val * 695
          break
        case val > 40:
          premiumValue = val * 495
          break

        default:
          break
      }

      if (this.yearEnabled) {
        return this.formatPrice(premiumValue * 12)
      }

      return this.formatPrice(premiumValue)
    }

  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    sliderChange (val) {
      if (val > 40) this.totalProjects = '40+'
      else this.totalProjects = val
    },
    tabChange (tab) {
      this.empSentiment = false
      this.tenantPrefs = false
      this.clientFeedback = false
      if (!this[tab]) {
        this[tab] = true
      }
    },
    formatPrice (price) {
      return (price).toLocaleString('en')
    }
  }
}
</script>
