<script>
export default {
  data: function () {
    return {
      searchFocused: false
    }
  },
  computed: {
  },
  methods: {
    handleSearchFocus () {
      this.searchFocused = true
    },
    handleSearchFocusOut () {
      this.searchFocused = false
    }
  }
}
</script>
