<template>
  <div class="icon_star-border">
    <div class="icon_star-border__svg">
      <svg
        viewBox="0 0 33.053 33.054"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(1856.554 5032)">
          <path
            d="M121.158 118.717l5.107 10.881 11.42 1.745-8.263 8.469 1.951 11.959-10.214-5.646-10.214 5.646 1.951-11.959-8.263-8.469 11.42-1.745zm-4.2 11.305a1 1 0 01-.754.564l-9.5 1.451 6.9 7.076a1 1 0 01.271.859l-1.619 9.926 8.412-4.65a1 1 0 01.968 0l8.412 4.65-1.619-9.926a1 1 0 01.271-.859l6.9-7.076-9.5-1.451a1 1 0 01-.754-.564l-4.2-8.952z"
            transform="translate(-1961.186 -5150.717)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
