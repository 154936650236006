<template>
  <div class="icon_export">
    <div class="icon_export__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="rotate(90 8 8)">
          <path
            d="M0 0l7.53.153.018.892L.018.892z"
            transform="rotate(-45 7.944 -.952)"
          />
          <path
            d="M0 1.021L7.527.868l.018-.892-7.53.153z"
            transform="rotate(-135 7.919 .342)"
          />
          <path
            d="M0 0h12v1H0z"
            transform="rotate(-90 10.5 2.5)"
          />
          <path
            d="M0 0h1v9H0z"
            transform="translate(0 7)"
          />
          <path
            d="M0 0h1v16H0z"
            transform="rotate(90 .5 15.5)"
          />
          <path
            d="M0 0h1v6H0z"
            transform="rotate(90 -.5 6.5)"
          />
          <path
            d="M0 0h1v6H0z"
            transform="rotate(90 4.5 11.5)"
          />
          <path
            d="M0 0h1v9H0z"
            transform="translate(15 7)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
