<template>
  <div class="icon_playbutton">
    <div class="icon_playbutton__svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="23"
        viewBox="0 0 15 23"
      >
        <path
          class="a"
          d="M11.5,0,23,15H0Z"
          transform="translate(15) rotate(90)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
