<template>
  <div class="icon_receipt">
    <div class="icon_receipt__svg">
      <svg
        viewBox="0 0 12.731 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.731 0v16l-1.748-1.549L9.549 16l-1.863-1.488L5.911 16l-1.472-1.539L3.183 16l-1.72-1.488L0 16V0zM3.1 14.71l1.3-1.595 1.566 1.638 1.712-1.435 1.8 1.44 1.458-1.574 1 .886V1H.8v12.9l.629-.64z" />
        <path
          d="M0 0h7v1H0z"
          transform="translate(3 3)"
        />
        <path
          d="M0 0h7v1H0z"
          transform="translate(3 6)"
        />
        <path
          d="M0 0h7v1H0z"
          transform="translate(3 9)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
