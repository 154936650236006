var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasSingleMedia)?_c('div',[(_vm.isMediaVideo(_vm.media[0]))?_c('video',{key:_vm.questionId,staticClass:"st-question-media__video",attrs:{"controls":"","playsinline":"","webkit-playsinline":""}},[_c('source',{attrs:{"src":_vm.firstMedia.mediaUrl,"type":_vm.firstMedia.media.contentType}}),_vm._v(" "),_c('source',{attrs:{"src":_vm.firstMedia.mediaUrl}}),_vm._v("\n      Your browser does not support this video\n    ")]):_c('div',[(_vm.showExpanedPhoto)?_c('div',{staticClass:"st-question-media__image-container st-question-media__image-container--expanded"}):_c('div',{staticClass:"st-question-media__image-container"},[(_vm.isExpandPhotoIcon)?_c('div',{staticClass:"st-question-media__expand-icon-container",on:{"click":_vm.expandPhoto}},[_c('icon-wrapper',{staticClass:"st-question-media__expand-icon",attrs:{"icon-name":"expand-image"}})],1):_vm._e(),_vm._v(" "),_c('img',{class:{
            'st-question-media__image': true,
            'st-question-media__image--clickable': !_vm.isSpecificationsPreview,
          },attrs:{"src":_vm.firstMedia.mediaUrl},on:{"click":_vm.expandPhoto}})])])]):_vm._e(),_vm._v(" "),(_vm.hasMultipleMedia)?_c('div',{class:{
      'st-question-media__zoom-icons': true,
      'st-question-media__zoom-icons--sticky-active': _vm.isStickyQuestionActive,
      'st-question-media__zoom-icons--desktop-preview': _vm.isDesktopSurveyPreview,
      'st-question-media__zoom-icons--preview-maximize': _vm.zoomLevel > 100 && _vm.isDesktopSurveyPreview,
    }},[_c('div',{staticClass:"st-question-media__zoom-icon-container",on:{"click":_vm.handleZoomIn}},[_c('icon-wrapper',{attrs:{"icon-name":"plus"}})],1),_vm._v(" "),_c('div',{class:{
        'st-question-media__zoom-icon-container st-question-media__zoom-icon-container--minus': true,
        'st-question-media__zoom-icon-container--disabled': _vm.zoomLevel === 60,
      },on:{"click":_vm.handleZoomOut}},[_c('icon-wrapper',{attrs:{"icon-name":"minus"}})],1)]):_vm._e(),_vm._v(" "),(_vm.hasMultipleMedia)?_c('div',{ref:"multiMediaImages",class:{
      'st-question-media__multi-media-images': _vm.zoomLevel > 100,
      'st-question-media__multi-media-images--survey-preview': _vm.isDesktopSurveyPreview && _vm.zoomLevel > 100,
      'st-question-media__multi-media-images--specs-preview': _vm.isSpecificationsPreview && _vm.zoomLevel > 100
    },on:{"click":_vm.resetZoomLevel}},_vm._l((_vm.media),function(multiMedia,index){return _c('div',{key:index,ref:"multiMedia",refInFor:true,class:{
        'st-question-media__multi-media-container': true,
        'st-question-media__multi-media-container--last': index == _vm.media.length - 1,
      },style:(_vm.mediaStyle)},[(_vm.isMediaVideo(multiMedia))?_c('video',{key:'multiMedia' + index,staticClass:"st-question-media__multi-media",attrs:{"controls":"","playsinline":"","webkit-playsinline":""},on:{"loadeddata":function($event){return _vm.setImageWidth(index)}}},[_c('source',{attrs:{"src":multiMedia.mediaFullUrl}}),_vm._v(" "),_c('source',{attrs:{"src":multiMedia.mediaFullUrl}}),_vm._v("\n        Your browser does not support this video\n      ")]):_c('img',{ref:"multiMediaImage",refInFor:true,staticClass:"st-question-media__multi-media",attrs:{"src":multiMedia.mediaFullUrl},on:{"load":function($event){return _vm.setImageWidth(index)}}})])}),0):_vm._e(),_vm._v(" "),(_vm.showExpanedPhoto)?_c('div',[_c('div',{ref:"stModal",staticClass:"st-modal__opacitor",attrs:{"tabindex":"0"},on:{"click":function($event){_vm.showExpanedPhoto = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.showExpanedPhoto= false}}}),_vm._v(" "),_c('button',{ref:"closeLightboxIcon",staticClass:"st-question-media-light-box__close-icon-button",on:{"click":function($event){_vm.showExpanedPhoto = false}}},[_c('icon-wrapper',{staticClass:"st-question-media-light-box__close-icon",attrs:{"invert":true,"icon-name":"plus"}})],1),_vm._v(" "),_c('img',{ref:"lightboxImage",staticClass:"st-question-media-light-box__media",attrs:{"src":_vm.firstMedia.mediaFullUrl},on:{"load":_vm.positionCloseIcon}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }