<template>
  <div>
    <div
      v-for="(option, index) in optionsWithVotesAndPercents"
      :key="'option' + index"
    >
      <p class="st-favorite-choice-insights__option">
        {{ option.name }}
      </p>
      <div class="st-favorite-choice-insights__bar-and-stat">
        <div
          :class="[{'st-favorite-choice-insights__bar': true }, barColor(index)]"
          :style="barWidth(option.count)"
        >
          <st-tooltip
            v-if="option.count > 0"
            :key="'tip' + index"
            class="st-favorite-choice-insights__tooltip"
            arrow-class="st-favorite-choice-insights__tooltip--arrow"
            message-class="st-favorite-choice-insights__tooltip--message"
            direction="right"
            :tip="'test'"
          >
            <div class="st-favorite-choice-insights__tooltip" />
            <template slot="tip">
              <div class="st-favorite-choice-insights__tooltip-contents">
                <p class="st-favorite-choice-insights__participant-count">
                  {{ optionCount(option.participants.length) }}
                </p>
                <div
                  v-for="(participant) in option.participants"
                  :key="'participant' + participant.id"
                  class="st-favorite-choice-insights__participant-avatar-and-name"
                >
                  <participant-avatar
                    :participant="participant"
                  />
                  <p class="st-favorite-choice-insights__participant-name">
                    {{ participant.fullName }}
                  </p>
                </div>
              </div>
            </template>
          </st-tooltip>
        </div>
        <p
          v-if="unitsOfMeasureIsVotes"
          class="st-favorite-choice-insights__stat"
        >
          {{ optionCount(option.count) }}
        </p>
        <p
          v-else
          class="st-favorite-choice-insights__stat"
        >
          {{ optionPercent(option.percent) }}%
        </p>
      </div>
    </div>
    <div
      class="st-favorite-choice-insights__stat-canvas-mobile"
    >
      <p class="st-favorite-choice-insights__vote">
        <span v-if="unitsOfMeasureIsVotes">0</span>
        <span v-else>0%</span>
      </p>
      <p class="st-favorite-choice-insights__vote">
        <span v-if="!unitsOfMeasureIsVotes">50%</span>
      </p>
      <p class="st-favorite-choice-insights__vote">
        <span v-if="unitsOfMeasureIsVotes">{{ numberOfVotes }}</span>
        <span v-else>100%</span>
      </p>
    </div>
    <p class="st-favorite-choice-insights__stat-canvas-mobile-axis">
      <span v-if="unitsOfMeasureIsVotes">Total Number of Votes</span>
      <span v-else>Percent of Votes</span>
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { percent, sum } from '../../../../utilities/data_utilities'
import { StTooltip } from '../../../common'
import ParticipantAvatar from '../participant-avatar'

export default {
  components: {
    ParticipantAvatar,
    StTooltip
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionModifier']),
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingParticipantCheckboxFilter']),
    numberOfVotes () {
      return sum(Object.values(this.numberOfVotesByOptionId))
    },
    numberOfVotesByOptionId () {
      return this.responsesWithNumericalValues.reduce((obj, value) => {
        obj[value.value] = (obj[value.value] || 0) + 1
        return obj
      }, {})
    },
    optionIds () {
      return this.activeQuestion.options.map(option => option.id)
    },
    optionNames () {
      return this.activeQuestion.options.map(option => option.name)
    },
    optionsWithVotesAndPercents () {
      return Object.fromEntries(
        this.optionIds.map((name, i) => [i, {
          count: this.numberOfVotesByOptionId[name] || 0,
          name: this.optionNames[i],
          participants: this.responsesWithNumericalValues.filter(participant => participant.value === name),
          percent: parseInt(percent(this.numberOfVotesByOptionId[name], this.numberOfVotes, 0))
        }])
      )
    },
    responsesWithNumericalValues () {
      return Object.entries(this.responsesPassingParticipantCheckboxFilter).map(([title, response]) => {
        return { ...response, value: Math.floor(response.value) }
      })
    },
    unitsOfMeasureIsVotes () {
      return this.activeQuestionModifier === 'votes'
    }
  },
  methods: {
    barColor (value) {
      return { [`st-favorite-choice-insights__bar--${value}`]: true }
    },
    barWidth (count) {
      if (this.numberOfVotes === 0) { return { width: 0 + '%' } }
      return { width: (count / this.numberOfVotes) * 100 + '%' }
    },
    optionCount (count) {
      return count === 1 ? `${count} Vote` : `${count} Votes`
    },
    optionPercent (optionPercent) {
      return isNaN(optionPercent) ? 0 : optionPercent
    }
  }
}
</script>
