<template>
  <div class="st-toggle__box">
    <div
      :class="{
        'st-toggle__option': true,
        'st-toggle__option--selected': selectedOption === 0
      }"
      @click="updateOption(firstOption)"
    >
      {{ firstOption }}
    </div>
    <div
      :class="{
        'st-toggle__option': true,
        'st-toggle__option--selected': selectedOption === 1
      }"
      @click="updateOption(secondOption)"
    >
      {{ secondOption }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeOption: {
      type: String,
      required: true
    },
    firstIcon: {
      type: String,
      required: false,
      default: ''
    },
    firstOption: {
      type: String,
      required: true
    },
    secondIcon: {
      type: String,
      required: false,
      default: ''
    },
    secondOption: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {

    }
  },
  computed: {
    selectedOption () {
      if (this.activeOption.toLowerCase() === this.firstOption.toLowerCase()) {
        return 0
      } else {
        return 1
      }
    }
  },
  methods: {
    updateOption (option) {
      this.$emit('updateOption', option)
    }
  }
}
</script>
