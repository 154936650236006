<template>
  <div class="icon_surveytemplate">
    <div class="icon_surveytemplate__svg">
      <svg
        viewBox="0 0 16 16.001"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(2)">
          <path
            d="M0 0h4v1H0z"
            transform="translate(2 2)"
          />
          <path
            d="M0 0h4v1H0z"
            transform="translate(10 2)"
          />
          <path
            d="M0 0h3v1H0z"
            transform="translate(11 13)"
          />
          <path
            d="M0 0h6v1H0z"
            transform="translate(5 1)"
          />
          <path
            d="M0 0h6v1H0z"
            transform="translate(5 3)"
          />
          <path
            d="M0 0h5v1H0z"
            transform="translate(7 5)"
          />
          <path
            d="M0 0h1v1H0z"
            transform="translate(4 5)"
          />
          <path
            d="M0 0h1v5.971H0z"
            transform="translate(2 2)"
          />
          <path
            d="M0 0h1v12H0z"
            transform="translate(13 2)"
          />
          <path
            d="M0 0h2v2H0z"
            transform="translate(7)"
          />
          <path
            d="M0 0h1v12H0z"
            transform="translate(-2 4)"
          />
          <path
            d="M0 0h1v8.962H0z"
            transform="translate(11 7.039)"
          />
          <path
            d="M0 0h4v1H0z"
            transform="translate(-2 4)"
          />
          <path
            d="M0 0h14v1H0z"
            transform="translate(-2 7)"
          />
          <path
            d="M0 0h14v1H0z"
            transform="translate(-2 15)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
