<template>
  <div class="specifications__section">
    <div class="specifications__title">
      Spatial Adjacencies
    </div>
    <div class="st-input__row">
      <label class="st-input__label">
        Question
        <input
          :value="activeQuestion.description"
          class="st-input"
          type="text"
          name="question[description]"
          placeholder="Your question goes here"
          :disabled="isPreview"
          @blur="updateQuestion('description', $event.target.value)"
          @input="$emit('emitDescription', $event)"
        >
      </label>
    </div>
    <div>
      <label class="st-input__label">
        Plan Image
      </label>
      <div>
        <cv-button
          v-if="isPreview"
          kind="secondary"
          class="st-templates__preview-button"
          disabled
        >
          + Add Image
        </cv-button>
        <ds-dropzone
          :image="activeQuestion.imageUrl"
          :upload-url="`/questions/${activeQuestion.id}`"
          :property-name="'question[image]'"
          @uploadMedia="replaceActiveQuestion"
          @deleteImage="activeQuestion.imageUrl=null"
        >
          <cv-tile
            slot-scope="{ deleteImage }"
            class="specifications__image-tile"
          >
            <div class="specifications__image-and-name">
              <div
                class="specifications__image"
                :style="{
                  background: `url('${activeQuestion.imageUrl}') center no-repeat`,
                  backgroundSize: 'contain'
                }"
              />
              <p class="specifications__file-name">
                {{ fileNameFromUrl(activeQuestion.imageUrl) }}
              </p>
            </div>
            <div
              v-if="!isPreview"
              class="st-svg__delete"
              @click="deleteImage"
            />
          </cv-tile>
        </ds-dropzone>
      </div>
    </div>
    <div class="st-input__row">
      <label
        v-for="(label,idx) in options"
        :key="idx"
        class="st-input__label st-input__label--half-length"
      >
        Space Label #{{ idx + 1 }}
        <div class="st-input__delete-wrapper">
          <input
            :value="options[idx].name"
            class="st-input st-input--with-delete"
            type="text"
            placeholder="Space label goes here"
            :disabled="isPreview"
            @blur="updateQuestionOption(label.optionId, idx, $event.target.value)"
          >
          <div
            v-if="isPreview"
            class="st-input__delete-icon st-templates__preview-cursor"
          />
          <div
            v-else
            class="st-input__delete-icon"
            @click="deleteOptionModal(idx, true)"
          />
          <cv-modal
            :id="label.optionId.toString()"
            :visible="deleteModal && activeOption===idx"
            kind="danger"
            @modal-hidden="deleteOptionModal(idx, false)"
          >
            <template slot="title">
              Delete
            </template>
            <template slot="content">
              <p>Are you sure you want to delete this question option? </p>
              <cv-button
                kind="danger--primary"
                class="new-question__delete"
                @click="deleteQuestionOption(label.optionId, idx)"
              >
                Delete
              </cv-button>
            </template>
          </cv-modal>
        </div>
      </label>
    </div>
    <div
      v-if="options.length < 6"
      class="st-input__row"
    >
      <cv-button
        v-if="isPreview"
        class="bx--btn--secondary"
        disabled
      >
        <template v-if="options.length===0">
          Add a label
        </template>
        <template v-else>
          Add Another Label
        </template>
      </cv-button>
      <cv-button
        v-else
        class="bx--btn--secondary"
        @click="addLabel"
      >
        <template v-if="options.length===0">
          Add a label
        </template>
        <template v-else>
          Add Another Label
        </template>
      </cv-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import CvModal from '@carbon/vue/src/components/cv-modal/cv-modal'
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import CvTile from '@carbon/vue/src/components/cv-tile/cv-tile'
import fileName from '../../../mixins/fileName'
import updateQuestionOption from '../../../mixins/question/updateQuestionOption'
import updateQuestion from '../../../mixins/question/updateQuestion'
// import DsDropzone from '../../tools/ds-dropzone'

export default {
  components: {
    'cv-modal': CvModal,
    'cv-button': CvButton,
    'cv-tile': CvTile
    // 'ds-dropzone': DsDropzone
  },
  mixins: [fileName, updateQuestionOption, updateQuestion],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    ...mapMutations('specifications', ['replaceActiveQuestion']),
    addLabel () {
      this.createNewOption(this.highestPriority + 1)
    }
  }
}
</script>
