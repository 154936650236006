<template>
  <div class="icon_finance">
    <div class="icon_finance__svg">
      <svg
        viewBox="0 0 16.486 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.785A1.817 1.817 0 011.847 0a1.817 1.817 0 011.847 1.785 1.817 1.817 0 01-1.847 1.786A1.817 1.817 0 010 1.785zm.989-.171a.773.773 0 000 .342.894.894 0 001.716 0 .773.773 0 000-.342.894.894 0 00-1.716 0z"
          transform="translate(10.829 4.285)"
        />
        <path
          d="M0 1.785A1.817 1.817 0 011.847 0a1.817 1.817 0 011.847 1.785 1.817 1.817 0 01-1.847 1.786A1.817 1.817 0 010 1.785zm.989-.171a.773.773 0 000 .342.894.894 0 001.716 0 .773.773 0 000-.342.894.894 0 00-1.716 0z"
          transform="translate(4.918)"
        />
        <path
          d="M10.2 15.326a.485.485 0 110-.97h4.081c2.447 0 2.7 1.868 2.7 1.868a.485.485 0 01-.314.5l-8.69 3.232a2.77 2.77 0 01-2.167-.1L.774 17.421a.485.485 0 01-.274-.437v-5a.485.485 0 01.485-.484 8.8 8.8 0 011.841.107 5.052 5.052 0 012.823 1.321h1.986a3.392 3.392 0 013.44 3.342.485.485 0 01-.485.485H5.418a.485.485 0 010-.97h4.635l-.006-.025a2.451 2.451 0 00-2.412-1.86c-2.377 0-2.413.071-2.609-.2-.647-.893-2.369-1.228-3.556-1.228v4.21l4.762 2.3a1.8 1.8 0 001.407.066l8.246-3.066a1.813 1.813 0 00-1.6-.655z"
          transform="translate(-.5 -4.129)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
