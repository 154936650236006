<template>
  <div>
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--col-xs-12">
          <a
            href="/"
            target="_blank"
            class="st-join-audience__logo"
          >
            <icon-wrapper
              icon-name="spacetrics"
              class="st-sidebar__logo-icon"
            />
            <p class="st-join-audience__logo-title">
              spacetrics
            </p>
          </a>
          <div
            :class="[{
              'st-join-audience__top-section': true,
              [`st-join-audience__top-section--${version}`]: true
            }]"
          >
            <div class="st-join-audience__header-and-inputs">
              <div>
                <h1 class="st-join-audience__header">
                  {{ headerBeginning }} <span class="st-join-audience__header st-join-audience__header--blue"> {{ headerEnd }}</span>

                  <template v-if="version === 6">
                    Amazon Gift Card
                  </template>
                </h1>
                <p class="st-join-audience__top-info">
                  {{ topInfo }}
                </p>
              </div>
              <form
                v-if="!submitSuccess"
                class="st-join-audience__input-and-button"
                @submit="onSubmit"
              >
                <st-input
                  v-model="phone"
                  v-mask="'+1(###)-###-####'"
                  :input-container-class="inputClass"
                  input-type="tel"
                  label="Your Phone Number"
                  label-in-field-class="st-join-audience__input-label"
                  :show-label="false"
                  @input="onInput"
                />
                <st-button
                  :class="[{
                    'st-join-audience__button': true,
                    [`st-join-audience__button--${version}`]: true
                  }]"
                  caption="Send Text"
                  type="submit"
                  :underline="false"
                  value="Submit"
                  :variant="['secondary']"
                  @touchend="onSubmit"
                />
              </form>
              <p
                v-else
                class="st-join-audience__thanks"
              >
                Thanks!
              </p>
            </div>
            <div
              :class="[{
                'st-join-audience__space-image': true,
                [`st-join-audience__space-image--${version}`]: true
              }]"
            />
          </div>
          <div class="st-join-audience__cards">
            <div
              v-for="(card, index) in stepsCards"
              :key="index"
              class="st-join-audience-card__container"
            >
              <div
                :class="[{ 'st-join-audience-card__image': true }, cardImage(card.header)]"
              />
              <div class="st-join-audience-card__header-and-description">
                <h4 class="st-join-audience-card__header">
                  {{ card.header }}
                </h4>
                <p class="st-join-audience-card__description">
                  {{ card.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="st-join-audience__accordions">
            <h3 class="st-join-audience__faq-header">
              FAQ
            </h3>
            <st-accordion
              v-for="(faq, index) in faqs"
              :key="faq.question"
              class="st-join-audience-accordion__wrapper"
              icon-class="st-join-audience-accordion__wrapper-icon"
            >
              <template
                slot="visible-section"
              >
                <div class="st-join-audience-accordion__question">
                  {{ faq.question }}
                </div>
              </template>
              <template slot="expanded-section">
                <template v-if="index !== faqs.length - 1">
                  <div class="st-join-audience-accordion__answer--spacing">
                    <p
                      class="st-join-audience-accordion__answer"
                    >
                      {{ faq.answer }}
                    </p>
                    <p
                      v-if="faq.additional"
                      class="st-join-audience-accordion__answer st-join-audience-accordion__answer--additional"
                    >
                      {{ faq.additional }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div class="st-join-audience-accordion__answer--spacing">
                    <p class="st-join-audience-accordion__answer">
                      Spacetrics, founded in Washington DC, is a platform that gathers peoples' opinions about real estate space.  Just like what we're doing now :-). Feel free to visit us at
                      <a
                        href="https://www.spacetrics.com"
                        target="_blank"
                        class="st-join-audience-accordion__answer st-join-audience-accordion__answer--bold"
                      >
                        www.spacetrics.com</a>.
                      or email us at
                      <a
                        href="mailto:team@spacetrics.com"
                        class="st-join-audience-accordion__answer st-join-audience-accordion__answer--bold"
                      >
                        team@spacetrics.com</a>.
                      We're also on Twitter too . . .
                      <a
                        href="https://twitter.com/spacetrics"
                        target="_blank"
                        class="st-join-audience-accordion__answer st-join-audience-accordion__answer--bold"
                      >
                        @spacetrics</a>
                    </p>
                  </div>
                </template>
              </template>
            </st-accordion>
          </div>
          <div class="st-join-audience-get-money__block">
            <h2 class="st-join-audience-get-money__header">
              Get ${{ survey.incentiveAmount }} Today
            </h2>
            <form
              v-if="!submitSuccess"
              class="st-join-audience__input-and-button"
              @submit="onSubmit"
            >
              <st-input
                v-model="phone"
                v-mask="'+1(###)-###-####'"
                :input-container-class="inputClass"
                input-type="tel"
                label="Your Phone Number"
                label-in-field-class="st-join-audience__input-label"
                :show-label="false"
                @input="onInput"
              />
              <st-button
                class="st-join-audience__button"
                caption="Send Text"
                type="submit"
                :underline="false"
                value="Submit"
                :variant="['secondary']"
                @touchend="onSubmit"
              />
            </form>
            <p
              v-else
              class="st-join-audience__thanks"
            >
              Thanks!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="st-join-audience-learn-more__block">
      <div class="st-join-audience-learn-more__text-wrapper">
        <p class="st-join-audience-learn-more__text">
          Spacetrics helps organizations understand what people think about real estate space.
        </p>
        <p class="st-join-audience-learn-more__text">
          Want to find out more?
        </p>
        <a
          href="/"
          target="_blank"
          class="st-join-audience-learn-more__text st-join-audience-learn-more__text--visit"
        >
          Visit us here.
        </a>
      </div>
    </div>
    <footer class="st-join-audience-footer__block">
      <p
        class="st-join-audience-footer__text"
      >
        Powered by
        <icon-wrapper
          class="st-join-audience-footer__icon"
          :invert="true"
          icon-name="spacetrics"
        />
        spacetrics
      </p>
    </footer>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { kebabCase } from 'lodash'
import { IconWrapper, StAccordion, StButton, StInput } from '../../common'

export default {
  directives: { mask },
  components: {
    StAccordion,
    StInput,
    StButton,
    IconWrapper
  },
  props: {
    participant: {
      type: Object,
      required: false,
      default: () => ({})
    },
    survey: {
      type: Object,
      required: false,
      default: () => ({})
    },
    version: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      faqs: [
        {
          additional: "If your answers are too short or incomplete, we will send a message to your via Text and Email asking you to more fully articulate your thoughts about the space.  So for instance, please don't just say 'I like it,'  rather be detailed like 'I like the fact that there are wood floors and a tall windows.' . . . and so on. :-). This is like a conversation, and the more you talk about the space the better for us to understand what you like and do not like.",
          answer: "After you complete the survey, which should take about 15 mins, we will review your answers to make sure that you have spoken clearly and in detail.  You'll then receive a text message with an Amazon Digital Gift Card link along with an email too.  This process generally happens within the same day.",
          question: 'How & When do I get paid?'
        },
        {
          answer: 'The survey should take no more than 15 minutes in total.  The questions that require microphone access, where you are speaking, should take you the longest.  Maybe 2 to 3 mins per question.  But most of the questions are simple ranking choices that just require one click.',
          question: 'How long will it take?'
        },
        {
          answer: 'Spacetrics is hired by local developers, property management companies and other real estate professionals to provide feedback to what people like and do not like about spaces.  Your information will not be shared or sold to any third parties.',
          question: 'How is my information used?'
        },
        {
          answer: 'Yes. We will periodically send you via Text and Email other opportunities to give your insights about real estate space and for you to earn additional Amazon gift cards.',
          question: 'Do you have other surveys that I can take?'
        },
        {
          answer: "Spacetrics, founded in Washington DC, is a platform that gathers peoples' opinions about real estate space.  Just like what we're doing now :-). Feel free to visit us at www.spacetrics.com or email us at team@spacetrics.com.  We're also on Twitter too . . . @spacetrics",
          question: 'What is Spacetrics?'
        }
      ],
      inputClass: 'st-join-audience__input',
      phone: '',
      submitSuccess: false,
      stepsCards: [
        {
          header: 'Get invite',
          description: 'Enter your phone number and you’ll instantly receive a text to access an online interactive survey.'
        },
        {
          header: 'Answer questions',
          description: 'Complete an interactive survey where you freely voice your opinions while virtually touring a real space.'
        },
        {
          header: 'Receive reward',
          description: 'We’ll review your completed survey and you’ll receive a $15 Amazon Digital Gift Card.'
        }
      ]
    }
  },
  computed: {
    headerBeginning () {
      switch (this.version) {
        case 2:
          return 'Do a Focus Group From Home & Get a'
        case 3:
          return 'Do a Focus Group From Home & Get a'
        case 4:
          return 'Voice Your Opinion From Home &'
        case 5:
          return 'Voice Your Opinion From Home &'
        case 6:
          return 'Get a'
        default:
          return 'Voice Your Opinion From Home &'
      }
    },
    headerEnd () {
      switch (this.version) {
        case 2:
          return `$${this.survey.incentiveAmount} Amazon Gift Card`
        case 3:
          return `$${this.survey.incentiveAmount} Amazon Gift Card`
        case 4:
          return `Earn $${this.survey.incentiveAmount} Today`
        case 5:
          return `Earn $${this.survey.incentiveAmount} Today`
        case 6:
          return `FREE $${this.survey.incentiveAmount}`
        default:
          return `Earn $${this.survey.incentiveAmount} Today`
      }
    },
    topInfo () {
      switch (this.version) {
        case 2:
          return `We’ll text you a link to your mobile phone so you can take our survey about real estate.  Enter your phone number below and earn a $${this.survey.incentiveAmount} Amazon Gift Card today.`
        case 3:
          return `We’ll text you a link to your mobile phone so you can take our survey about real estate.  Enter your phone number below and earn a $${this.survey.incentiveAmount} Amazon Gift Card today.`
        case 4:
          return `We’ll text you a link to your mobile phone so you can take our survey about real estate.  Enter your phone number below and earn a $${this.survey.incentiveAmount} Amazon Gift Card today.`
        case 5:
          return 'Got 15 minutes? Freely voice your opinions about interior design through your phone and earn money. Enter your phone number to get started.'
        case 6:
          return 'Got 15 minutes? Freely voice your opinions about interior design through your phone and earn money. Enter your phone number to get started.'
        default:
          return 'Got 15 minutes? Freely voice your opinions about interior design through your phone and earn money. Enter your phone number to get started.'
      }
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    cardImage (cardHeader) {
      return { [`st-join-audience-card__image--${kebabCase(cardHeader)}`]: true }
    },
    onInput (ev) {
      if (this.phone.length === 16) { this.inputClass = 'st-join-audience__input' }
    },
    onSubmit (ev) {
      ev.preventDefault()
      if (this.phone.length !== 16) {
        this.inputClass = 'st-input--error st-join-audience__input-error'
        this.$store.commit('updateToastOptions', {
          caption: `${this.phone} is not a valid number`,
          kind: 'error',
          title: 'Invalid Number'
        })
      } else {
        const phoneNumber = this.phone.replaceAll(/[^\d]/g, '')
        this.$store.commit('toggleFullSpinner', true)
        this.$axios.request({
          url: window.location.pathname,
          method: 'POST',
          data: { 'phone': phoneNumber }
        })
          .then((res) => {
            const message = res.data.message === 'Existing participant found'
              ? "You've already been verified!"
              : 'You should be receiving a text message shortly at the number you provided'
            this.submitSuccess = true
            this.$store.commit('toggleFullSpinner', false)
            this.$store.commit('updateToastOptions', {
              caption: message,
              kind: 'success',
              title: 'Number Submitted'
            })
          })
          .catch(() => {
            this.$store.commit('toggleFullSpinner', false)
            this.$store.commit('updateToastOptions', {
              caption: 'There was a problem submitting your phone number',
              kind: 'error',
              title: 'Problem Encountered'
            })
          })
      }
    }
  }
}
</script>
