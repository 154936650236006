<template>
  <st-modal
    v-model="displayModal"
    :hide-title-and-close="true"
    size-class="st-new-question-preview-modal"
    @closeModal="$emit('closePreviewModal')"
  >
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--col-sm-12 st-new-question-preview-modal__title-and-button">
          <div>
            <st-hyperlink
              :back-link="true"
              text="Back"
              @click="$emit('closePreviewModal')"
            />
            <div class="st-new-question-preview-modal__metric-title">
              <icon-wrapper
                class="st-new-question-preview-modal__metric-icon"
                :icon-name="activePreviewMetric.iconName"
              />
              {{ activePreviewMetric.name }}
            </div>
          </div>
          <st-button
            class="st-new-question-preview-modal__choose-button"
            caption="Choose"
            icon-name="check-mark"
            @click="$emit('addMetric')"
          />
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col-sm-7 st-new-question-preview-modal__separate-content">
          <div>
            <p class="st-new-question-preview-modal__subsection-title st-new-question-preview-modal__subsection-title--spacing">
              Description
            </p>
            <p class="st-icon-and-description-card__description st-new-question-preview-modal__description">
              {{ activePreviewMetric.description }}
            </p>
          </div>
          <div v-if="activeMetricPath[0].questionType !== 'slide'">
            <p class="st-new-question-preview-modal__subsection-title st-new-question-preview-modal__subsection-title--spacing">
              Insights
            </p>
            <img
              :src="activeMetricPath[0].insightsPreview"
              class="st-new-question-preview-modal__pic"
            >
          </div>
        </div>
        <div class="bx--offset-sm-1 bx--col-sm-4 st-new-question-preview-modal__separate-content">
          <p class="st-new-question-preview-modal__subsection-title">
            Participant’s Interface
          </p>
          <img
            :src="activeMetricPath[0].participantPreview"
            class="st-new-question-preview-modal__pic"
          >
        </div>
      </div>
    </div>
  </st-modal>
</template>

<script>
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StModal
} from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StModal
  },
  props: {
    /** Current metric that will be displayed in the modal. */
    activePreviewMetric: {
      type: Object,
      required: true
    },
    /** Determines if the previewModal is shown on screen. */
    displayPreviewModal: {
      type: Boolean,
      required: true
    },
    /** Paths for insights and participant previews, passed in initially from edit_content.html.erb. */
    metricImagePaths: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      displayModal: this.displayPreviewModal
    }
  },
  computed: {
    activeMetricPath () {
      return this.metricImagePaths.filter(image => {
        return image.questionType === this.activePreviewMetric.underScoredName
      })
    }
  }
}
</script>
