var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-voice-response__voice-response-container': true,
  }},[(_vm.isRecordButton)?_c('st-button',{class:{
      'st-voice-response__button': true
    },attrs:{"caption":"Record My Thoughts","icon-name":"microphone","underline":false,"variant":['secondary']},on:{"click":_vm.initiateRecording}}):(_vm.isStopButton)?_c('div',{class:{
      'st-voice-response__stop-button-and-visualizer': true,

    }},[_c('button',{staticClass:"st-voice-response__audio-button-container",attrs:{"type":"button"},on:{"click":_vm.stopRecording}},[_c('div',{staticClass:"st-voice-response__stop-square"})]),_vm._v(" "),(_vm.isVisualizerVisible)?_c('canvas',{ref:"audioCanvas",class:{
        'st-opinion-metric__audio-visualizer': true,
      },attrs:{"width":_vm.canvasWidth,"height":_vm.canvasHeight}}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.isAudioPlayer)?_c('audio',{ref:"audioPlayer",staticClass:"st-voice-response__audio-player",attrs:{"controls":""}},[_vm._v("\n    Your browser does not support the "),_c('code',[_vm._v("audio")]),_vm._v(" element.\n  ")]):_vm._e(),_vm._v(" "),(_vm.errorMessage)?_c('st-tooltip',{class:{
      'st-voice-response__button st-voice-response__record-thoughts-button': true,
    },attrs:{"always-visible":true,"max-width":_vm.errorTooltipWidth,"tip":_vm.errorMessage,"message-class":"st-voice-response__button-tooltip--error","is-active":_vm.isSpecificationsPreview}},[_c('st-button',{staticClass:"st-voice-response__button",attrs:{"caption":"Next Question","icon-name":"arrow","underline":false,"variant":['secondary']},on:{"click":function($event){return _vm.$emit('update-audio-blob', _vm.localAudioBlob)}}})],1):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('div',{class:{
      'st-voice-response__stop-button-and-visualizer': true,
    }},[(_vm.readyToSubmit && !_vm.replayAudio)?_c('button',{staticClass:"st-voice-response__audio-button-container",attrs:{"type":"button"},on:{"click":_vm.playAgain}},[_c('icon-wrapper',{staticClass:"st-voice-response__play-button",attrs:{"icon-name":"play-button","spacetrics-blue":true}})],1):_vm._e(),_vm._v(" "),(_vm.readyToSubmit && _vm.replayAudio)?_c('button',{staticClass:"st-voice-response__audio-button-container",attrs:{"type":"button"},on:{"click":_vm.stopReplay}},[_c('div',{staticClass:"st-voice-response__pause-bars"},[_vm._v("\n        ▌\n      ")]),_vm._v(" "),_c('div',{staticClass:"st-voice-response__pause-bars st-voice-response__pause-bars--right"},[_vm._v("\n        ▌\n      ")])]):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('p',{staticClass:"st-voice-response__audio-duration"},[_vm._v("\n      "+_vm._s(_vm.formatAudioDuration)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('div',{staticClass:"st-voice-response__trash-icon-container",on:{"click":_vm.rerecord}},[_c('icon-wrapper',{staticClass:"st-voice-response__trash-icon",attrs:{"icon-name":"trash"}})],1):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('st-tooltip',{staticClass:"st-voice-response__button-tooltip",attrs:{"tip":"Preview only button","is-active":_vm.isSpecificationsPreview}},[_c('st-button',{staticClass:"st-voice-response__button",attrs:{"caption":"Next","icon-name":"arrow","underline":false,"variant":['secondary']},on:{"click":function($event){return _vm.$emit('update-audio-blob', _vm.localAudioBlob)}}})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }