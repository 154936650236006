import moment from 'moment'
import { forIn } from 'lodash'

export function convertNumericProps (propList) {
  propList.forEach(propName => {
    this[propName] = parseFloat(this[propName])
  })
}

export function convertDateProps (proplist) {
  proplist.forEach(propName => {
    this[propName] = moment(this[propName])
  })
}

export function convertNumericProperties (propList) {
  let entityProps = propList.concat('salience')
  propConverter(propList.concat('transcriptionTotalSeconds'), this)

  this.roomSentiments.forEach(roomSentiment => {
    propConverter(propList, roomSentiment)

    roomSentiment.entitySentiments.forEach(roomEntitySentiment => {
      propConverter(entityProps, roomEntitySentiment)
    })
  })

  this.entitySentiments.forEach(entitySentiment => {
    propConverter(entityProps, entitySentiment)
  })

  forIn(this.viewpointTranscripts, (val, key) => {
    ['endTimes', 'startTimes'].forEach(propArrayConverter(val))
  })
}

function propConverter (propList, obj) {
  propList.forEach(propName => {
    obj[propName] = Number(obj[propName])
  })
}

function propArrayConverter (obj) {
  return (prop) => {
    let numeric = obj[prop].map(val => Number(val))
    obj[prop] = numeric
  }
}
