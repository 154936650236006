<template>
  <div
    class="st-highlighted-slider__main-container"
    @mousedown="handleTrackClick"
  >
    <div
      ref="track"
      class="st-highlighted-slider__track"
    >
      <div
        v-if="isDualSlider"
        class="st-highlighted-slider__thumb-wrapper"
      >
        <div
          ref="minThumb"
          class="st-highlighted-slider__thumb st-highlighted-slider__thumb--min"
          :style="lowMarkerStyle"
          @mousedown="startLowTracking"
          @mouseup="endMouseTracking"
          @touchmove="handleLowMove"
          @touchstart="startLowTracking"
          @touchend="endMouseTracking"
        >
          <st-tooltip
            v-if="minTooltip"
            message-class="st-highlighted-slider__tooltip-message"
            :tip="`${minTooltip}`"
          >
            <div class="st-highlighted-slider__tooltip-anchor" />
          </st-tooltip>
        </div>
      </div>
      <div
        ref="highlightBar"
        class="st-highlighted-slider__highlight-bar"
        :style="highlightBarStyle"
      />
      <div
        class="st-highlighted-slider__thumb-wrapper"
      >
        <div
          ref="maxThumb"
          :class="{
            'st-highlighted-slider__thumb': true,
            'st-highlighted-slider__thumb--max-above-min': isDualSlider && highLeftPx === 0
          }"
          :style="highMarkerStyle"
          @mousedown="startHighTracking"
          @mouseup="endMouseTracking"
          @touchmove="handleHighMove"
          @touchstart="startHighTracking"
          @touchend="endMouseTracking"
        >
          <st-tooltip
            v-if="maxTooltip"
            message-class="st-highlighted-slider__tooltip-message"
            :tip="`${maxTooltip}`"
          >
            <div class="st-highlighted-slider__tooltip-anchor" />
          </st-tooltip>
        </div>
      </div>
    </div>
    <div
      ref="axis"
      class="st-highlighted-slider__axis-label-wrapper"
    >
      <p
        v-for="label in axisLabels"
        :key="label"
        class="st-highlighted-slider__axis-label"
      >
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
import StTooltip from './st-tooltip'

const THUMB_WIDTH = 24

function clamp (val, min, max) {
  return Math.min(Math.max(val, min), max)
}

export default {
  components: {
    'st-tooltip': StTooltip
  },
  props: {
    alwaysShowLabel: {
      type: Boolean,
      default: false,
      required: false
    },
    axisLabels: {
      type: Array,
      required: false,
      default: () => []
    },
    max: {
      type: Number,
      default: 1,
      required: false
    },
    maxTooltip: {
      type: String,
      default: '',
      required: false
    },
    min: {
      type: Number,
      default: 1,
      required: false
    },
    minTooltip: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: [Number, Array],
      default: 0,
      required: false
    },
    variant: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      highLeftPx: 999,
      highOffset: 0,
      lowLeftPx: 2,
      lowOffset: 0,
      observedTrackWidth: 0,
      trackObserver: null
    }
  },
  computed: {
    isDualSlider () {
      return this.variant.includes('dual')
    },
    currentHighValue () {
      let num = this.highLeftPx * (this.max - this.min)
      let currentMax = Math.round((num / this.reducedTrackWidth) + this.min)
      return clamp(currentMax, this.min, this.max)
    },
    currentLowValue () {
      let num = this.lowLeftPx * (this.max - this.min)
      let currentMin = Math.round((num / this.reducedTrackWidth) + this.min)
      return clamp(currentMin, this.min, this.max)
    },
    highlightBarStyle () {
      let singleSlider = {
        width: this.highlightBarWidth + 'px'
      }

      let dualSlider = {
        left: this.lowLeftPx + 'px',
        width: this.highlightBarWidth + 'px'
      }

      return this.isDualSlider ? dualSlider : singleSlider
    },
    highlightBarWidth () {
      let singleSliderWidth = (this.highLeftPx + THUMB_WIDTH)
      return this.isDualSlider ? singleSliderWidth - (this.lowLeftPx) : singleSliderWidth
    },
    highMarkerStyle () {
      return {
        left: this.highLeftPx + 'px'
      }
    },
    lowMarkerStyle () {
      return {
        left: this.lowLeftPx + 'px'
      }
    },
    reducedTrackWidth () {
      return this.observedTrackWidth - THUMB_WIDTH
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (!this.isDualSlider) {
        this.highLeftPx = this.valueToLeftPx(newValue, this.reducedTrackWidth)
      } else {
        this.highLeftPx = this.valueToLeftPx(this.value[1], this.reducedTrackWidth)
        this.lowLeftPx = this.valueToLeftPx(this.value[0])
      }
    }
  },
  mounted () {
    this.trackObserver = new ResizeObserver(this.updateSliderElements)
    this.observedTrackWidth = this.getTrackWidth()
    this.$nextTick(() => { this.trackObserver.observe(this.$refs.track) })
    if (this.isDualSlider) {
      this.highLeftPx = this.valueToLeftPx(this.value[1], this.reducedTrackWidth)
      this.lowLeftPx = this.valueToLeftPx(this.value[0])
    } else {
      this.highLeftPx = this.valueToLeftPx(this.value || this.min, this.reducedTrackWidth)
    }
  },
  beforeDestroy () {
    if (this.trackObserver) { this.trackObserver.unobserve(this.$refs.track) }
  },
  methods: {
    // touch events don't fire mouse ones when you actually hold it down
    endMouseTracking () {
      this.$emit('mouseup')
      document.removeEventListener('mousemove', this.handleHighMove)
      document.removeEventListener('mousemove', this.handleLowMove)
      document.removeEventListener('mouseup', this.endMouseTracking)
    },
    getEventX (event) {
      return ('touches' in event) ? event.touches[0].screenX : event.pageX
    },
    getTrackWidth () {
      return this.$refs.track.getBoundingClientRect().width
    },
    handleEmit () {
      let emitValue = this.isDualSlider ? [this.currentLowValue, this.currentHighValue] : this.currentHighValue
      this.$emit('input', emitValue)
    },
    handleHighMove (event) {
      this.highLeftPx = this.isDualSlider
        ? clamp(this.getEventX(event) - this.highOffset, this.lowLeftPx, (this.reducedTrackWidth))
        : clamp(this.getEventX(event) - this.highOffset, 0, (this.reducedTrackWidth))
      return this.handleEmit()
    },
    handleLowMove (event) {
      this.lowLeftPx = clamp(this.getEventX(event) - this.lowOffset, 0, this.highLeftPx)
      return this.handleEmit()
    },
    handleTrackClick (event) {
      if (event.target === this.$refs.track || event.target === this.$refs.highlightBar) {
        let track = this.$refs.track.getBoundingClientRect()
        let trackXDistance = event.clientX - track.left - (THUMB_WIDTH / 2)
        if (this.isDualSlider) {
          if (trackXDistance < this.lowLeftPx) {
            this.lowLeftPx = trackXDistance
            this.startLowTracking(event)
          } else {
            this.highLeftPx = trackXDistance
            this.startHighTracking(event)
          }
        } else {
          this.highLeftPx = trackXDistance
          this.startHighTracking(event)
        }
        return this.handleEmit()
      }
    },
    startHighTracking (event) {
      event.preventDefault()
      this.highOffset = this.getEventX(event) - this.highLeftPx
      document.addEventListener('mousemove', this.handleHighMove)
      document.addEventListener('mouseup', this.endMouseTracking)
      return this.handleEmit()
    },
    startLowTracking (event) {
      event.preventDefault()
      this.lowOffset = (this.getEventX(event) - this.lowLeftPx)
      document.addEventListener('mousemove', this.handleLowMove)
      document.addEventListener('mouseup', this.endMouseTracking)
      return this.handleEmit()
    },
    updateSliderElements () {
      this.$nextTick(() => {
        const trackWidth = this.getTrackWidth()
        const ratio = (trackWidth - THUMB_WIDTH) / (this.reducedTrackWidth)
        this.$refs.axis.style.width = this.getTrackWidth() + 'px'

        if (this.lowLeftPx) { this.lowLeftPx = this.lowLeftPx * ratio }
        if (this.highLeftPx) { this.highLeftPx = this.highLeftPx * ratio }

        this.observedTrackWidth = trackWidth
      })
    },
    valueToLeftPx (value, whenEmpty = 0) {
      if (value === null) { return whenEmpty }

      let ratio = (value - this.min) / (this.max - this.min)
      return ratio * this.reducedTrackWidth
    }
  }
}
</script>
