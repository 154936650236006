// Used to get the duration of a blob (used in <audio></audio> element)
// Needed for a Chrome bug that shows "Infinity" for duration (source: https://bugs.chromium.org/p/chromium/issues/detail?id=642012)
export async function getBlobDuration (blob) {
  let temporaryElement = document.createElement('video')
  let durationPromise = new Promise(resolve =>
    temporaryElement.addEventListener('loadedmetadata', () => {
      if (temporaryElement.duration === Infinity) {
        temporaryElement.currentTime = Number.MAX_SAFE_INTEGER
        temporaryElement.ontimeupdate = () => {
          temporaryElement.ontimeupdate = null
          resolve(temporaryElement.duration)
          temporaryElement.currentTime = 0
        }
      } else { resolve(temporaryElement.duration) }
    })
  )
  temporaryElement.src = typeof blob === 'string' || blob instanceof String ? blob : window.URL.createObjectURL(blob)
  return durationPromise
}
