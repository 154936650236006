<template>
  <div class="st-sentiment-grid">
    <st-tooltip
      v-for="(response) in sortedResponses"
      :key="response.id"
      :tip="participantName(response)"
    >
      <div
        class="st-sentiment-grid__item"
        :class="gridItemClassList(response)"
        @click="toggleActiveResponse(response)"
      >
        <span
          class="st-sentiment-grid__item-caption"
          :class="gridItemCaptionClass(response)"
        >
          {{ initials(response) }}
        </span>
      </div>
    </st-tooltip>
  </div>
</template>

<script>
// for example css: https://jsfiddle.net/6937rxnf/2/
import { StTooltip } from '../../common'

import { bucketIdx } from '../../../utilities/data_utilities'

const CLASS_SUFFIXES = [
  'very-negative',
  'negative',
  'neutral',
  'positive',
  'very-positive'
]

export default {
  components: {
    StTooltip
  },
  props: {
    responses: {
      type: Array,
      required: true
      // elements are expected to be objects with: first name, last name, email, id, sentimentScore defined
    }
  },
  data: function () {
    return {
      activeResponseId: null
    }
  },
  computed: {
    sortedResponses () {
      let sorted = this.responses.slice()
      return sorted.sort((a, b) => a.sentimentScore - b.sentimentScore)
    }
  },
  methods: {
    gridItemClassList (response) {
      let classList = {
        'st-sentiment-grid__item--active': response.id === this.activeResponseId
      }
      classList[this.gridItemClass(response)] = true
      return classList
    },
    gridItemClass (response) {
      let className = 'st-sentiment-grid__item--'
      return className + this.sentimentClassSuffix(response.sentimentScore)
    },
    gridItemCaptionClass (response) {
      let className = 'st-sentiment-grid__item-caption--'
      return className + this.sentimentClassSuffix(response.sentimentScore)
    },
    sentimentClassSuffix (score) {
      let idx = bucketIdx(score) // 0-4
      return CLASS_SUFFIXES[idx]
    },
    toggleActiveResponse (response) {
      let responseId = response.id
      if (this.activeResponseId === responseId) {
        this.activeResponseId = null
        this.$emit('closeResponse', responseId)
      } else {
        this.activeResponseId = responseId
        this.$emit('openResponse', responseId)
      }
    },
    initials (response) {
      let initials = []
      if (response.firstName) { initials.push(response.firstName[0]) }
      if (response.lastName) { initials.push(response.lastName[0]) }
      if (initials.length > 0) {
        return initials.join('').toUpperCase()
      } else if (response.email.length > 0) {
        return response.email[0].toUpperCase()
      } else {
        return '--'
      }
    },
    participantName (response) {
      let names = []
      if (response.firstName) { names.push(response.firstName) }
      if (response.lastName) { names.push(response.lastName) }
      if (names.length > 0) {
        return names.join(' ')
      } else {
        return response.email
      }
    }
  }
}
</script>
