<template>
  <div class="icon_notifications">
    <div class="icon_notifications__svg">
      <svg
        viewBox="0 0 16 15.999"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-21071.7-9354.309a6.261 6.261 0 017.676.146 6.35 6.35 0 011.863 3.826 7.382 7.382 0 01.074 1.128v2.922c0 .783 2.223.652 2.254 2.372a1.234 1.234 0 01-1.1 1.352c-1.887.027-10.871 0-13.309 0a1.381 1.381 0 01-1.59-1.38c.055-1.8 2.184-1.506 2.207-2.344 0-1.367-.008-2.993 0-2.891a6.626 6.626 0 011.925-5.131zm-.945 4.143a34.532 34.532 0 00-.039 3.879c-.051 1.736-2.262 1.309-2.207 2.375.047.485.965.37 7.2.37s6.855.186 6.914-.4c.051-1-2.242-.591-2.242-2.55a23.628 23.628 0 00-.07-3.7 4.6 4.6 0 00-4.949-4.354c-2.79.003-4.314 1.651-4.61 4.38z"
          transform="translate(21075.836 9356.553)"
        />
        <path
          d="M0 0v2"
          transform="translate(8)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(7)"
        />
        <path
          d="M4.916 15h5s-.508 1.012-2.387 1a3.266 3.266 0 01-2.613-1z"
          transform="translate(.084)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
