var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bx--grid mobile-header"},[_c('a',{attrs:{"href":"/welcome"}},[_c('icon-wrapper',{staticClass:"mobile-header__spacetrics-icon",attrs:{"icon-name":"spacetrics"}})],1),_vm._v(" "),(!_vm.hideElements)?_c('div',{staticClass:"mobile-header__button"},[_c('st-button',{attrs:{"caption":"Create Template","icon-name":"pointer","show-action":true,"url":"/templates/new"}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"bx--grid"},[_c('div',{staticClass:"bx--row"},[_c('div',{class:{
          'bx--col-sm-10 bx--col-xs-12': !_vm.hideElements,
          'bx--offset-sm-1 bx--col-sm-9 bx--col-xs-12': _vm.hideElements
        }},[_c('div',[(_vm.backLink)?_c('st-hyperlink',{staticClass:"back-hyperlink-spacing--extra-space",attrs:{"back-link":true,"text":"Back","url":("/surveys/" + (_vm.survey.id) + "/choose_template")}}):_vm._e(),_vm._v(" "),_c('h1',{class:{
              'page-header': true,
              'st-select-template__header-spacing': _vm.backLink
            }},[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")]),_vm._v(" "),(_vm.currentUser.isSpacetricsAccount && !_vm.previewLink)?_c('div',{staticClass:"st-select-template__spacetrics-message"},[_vm._v("\n            You are logged into the Spacetrics Account, any Templates here will show up as Default Templates for every other account.\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"lookup-and-dropdown"},[_c('st-input',{staticClass:"st-input--default lookup-and-dropdown__space-input",attrs:{"show-icon":true,"show-label":false,"label":"Template Lookup","label-in-field-class":"st-input--hide-mobile-label"},on:{"blur":_vm.updateSearchParam},model:{value:(_vm.templateLookupValue),callback:function ($$v) {_vm.templateLookupValue=$$v},expression:"templateLookupValue"}}),_vm._v(" "),_c('st-dropdown-menu',{attrs:{"caption":"Templates Filter","initial-active-option":_vm.activeTemplateFilterOption,"options":Object.values(_vm.templateFilterValues)},on:{"updateOption":_vm.setDropdownOption}})],1),_vm._v(" "),_c('div',{staticClass:"surveys"},_vm._l((_vm.activePageTemplates),function(template){return _c('st-card',{key:template.id,staticClass:"template-library-card__position-contents",attrs:{"tabindex":"0","url":_vm.templateCardUrl(template)},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleTemplateSelect(template)}},scopedSlots:_vm._u([{key:"card-content",fn:function(slotProps){return [_c('div',{staticClass:"template-library-card__title-and-author"},[_c('div',{staticClass:"template-library-card__position-title"},[_c('icon-wrapper',{staticClass:"template-library-card__title-icon",attrs:{"icon-name":"survey-template"}}),_vm._v(" "),_c('p',{class:{
                        'template-library-card__title': true,
                        'template-library-card__title--hover': slotProps.hover
                      }},[_vm._v("\n                      "+_vm._s(template.name)+" Template\n                    ")])],1),_vm._v(" "),_c('p',{staticClass:"template-library-card__description"},[_vm._v("\n                    "+_vm._s(template.description)+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"template-library-card__position-created-by"},[(template.spacetricsOwned)?_c('icon-wrapper',{staticClass:"template-library-card__spacetrics-icon",attrs:{"icon-name":'spacetrics'}}):(!_vm.isEmpty(template.user))?_c('div',{staticClass:"template-library-card__avatar",style:({
                        background: ("url('" + (template.user.avatarUrl) + "') center no-repeat"),
                        backgroundSize: 'cover'
                      })}):_vm._e(),_vm._v(" "),(template.spacetricsOwned)?_c('p',{staticClass:"template-library-card__author"},[_vm._v("\n                      Created by Spacetrics\n                    ")]):(!_vm.isEmpty(template.user))?_c('p',{staticClass:"template-library-card__author"},[_vm._v("\n                      Created by "+_vm._s(_vm.createdByName(template.user))+"\n                    ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"project-and-survey-cards__position-statistic project-and-survey-cards__position-statistic--question-or-survey-count"},[_c('p',{class:{
                      'project-and-survey-cards__statistic-number': true,
                      'project-and-survey-cards__statistic-number--hover': slotProps.hover
                    }},[_vm._v("\n                    "+_vm._s(template.questionCount)+"\n                  ")]),_vm._v(" "),_c('p',{class:{
                      'project-and-survey-cards__statistic-title': true,
                      'project-and-survey-cards__statistic-title--hover': slotProps.hover
                    }},[_vm._v("\n                    "+_vm._s(template.questionCount === 1 ? 'Question' : 'Questions')+"\n                  ")])]),_vm._v(" "),(!_vm.hideElements)?_c('st-overflow-menu',{class:{
                    'project-and-survey-cards__overflow-menu-hover': slotProps.hover
                  },attrs:{"title":((template.name) + " Template"),"icon-name":"survey-template"}},[(template.canEdit)?_c('st-overflow-menu-item',{attrs:{"text":"Delete","icon-name":"trash"},on:{"mouseup":function($event){return _vm.deleteTemplate($event, template)}}}):_vm._e(),_vm._v(" "),(template.questionCount>0)?_c('st-overflow-menu-item',{attrs:{"text":"Duplicate","icon-name":"duplicate"},on:{"mouseup":function($event){return _vm.duplicateTemplate($event, template)}}}):_vm._e(),_vm._v(" "),(template.canEdit)?_c('st-overflow-menu-item',{attrs:{"text":"Edit","icon-name":"edit"},on:{"mouseup":function($event){return _vm.editTemplate($event, template)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.previewLink)?_c('transition',{attrs:{"name":"fade"}},[(slotProps.hover)?_c('st-hyperlink',{staticClass:"st-select-template__preview-link",attrs:{"text":"Preview","icon-name":"view-password","icon-class":"st-hyperlink__default-icon-spacing"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToPreview(template.id)}}}):_vm._e()],1):_vm._e()]}}],null,true)})}),1),_vm._v(" "),_c('st-pagination',{attrs:{"number-of-items":_vm.filteredTemplatesBySearch.length},on:{"updateActiveIndices":_vm.updateIndicesOfTemplatesShown}})],1)]),_vm._v(" "),(!_vm.hideElements)?_c('div',{staticClass:"bx--col-sm-2 desktop-page-right-action-button"},[_c('st-button',{ref:"createSurveyButton",attrs:{"caption":"Create Template","show-action":true,"icon-name":"pointer","url":"/templates/new"}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }