<template>
  <div class="icon_imageboard">
    <div class="icon_imageboard__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h4v1H0z" />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 6)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 12)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 6)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 12)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 6)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 12)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 3)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 9)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 3)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 9)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 15)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 3)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 9)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 15)"
        />
        <path d="M0 0h1v4H0z" />
        <path
          d="M0 0h1v4H0z"
          transform="translate(0 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(0 12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(6 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(6 12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(12 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(12 12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(3)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(3 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(3 12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(9 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(9 12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(15)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(15 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(15 12)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
