<template>
  <div class="icon_library">
    <div class="icon_library__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1a1 1 0 011-1h15l-.031 12.962v.994L0 14.006V1zm14.971 11.962V1H1v11.962z"
          transform="translate(0 1.994)"
        />
        <path d="M0 1V0h6.413l2.569 2.991H0zm5.937 0H1v1h5.78z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
