import moment from 'moment'

const requestProxyHandler = {
  get (obj, prop) {
    let val = obj[prop]
    if (prop === 'requestedAt') {
      val = moment(val)
    } else if (prop === 'name') {
      val = val || ''
    }
    return val
  }
}

export function AudienceRequest (request) {
  return new Proxy(request, requestProxyHandler)
}
