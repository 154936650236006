var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{
    'st-checkbox__align-content': true
  }, _vm.wrapperClasses],on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":_vm.handleVariantClick,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeydown.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerChecked),expression:"innerChecked"}],class:{
      'st-checkbox': true,
      'st-checkbox--hover': _vm.hover,
      'st-checkbox--transparent': _vm.transparent
    },attrs:{"id":_vm.stUid,"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":Array.isArray(_vm.innerChecked)?_vm._i(_vm.innerChecked,null)>-1:(_vm.innerChecked)},on:{"change":function($event){var $$a=_vm.innerChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerChecked=$$c}}}}),_vm._v(" "),(_vm.checked)?_c('label',{class:{
      'st-checkbox-indicator': true,
      'st-checkbox-indicator--multiple': _vm.selectMultiple,
      'st-checkbox-indicator--multiple-invert': _vm.selectMultiple && _vm.invert,
      'st-checkbox-indicator--disabled': _vm.disabled
    },attrs:{"for":_vm.stUid}},[(_vm.selectMultiple)?_c('icon-wrapper',{staticClass:"st-checkbox__minus-icon",attrs:{"icon-name":"minus","invert":!_vm.invert}}):_c('icon-wrapper',{attrs:{"icon-name":"check","invert":true}})],1):(_vm.transparent)?_c('label',{staticClass:"st-checkbox-indicator--empty-transparent"}):_vm._e(),_vm._v(" "),_c('label',{staticClass:"st-checkbox__label",attrs:{"for":_vm.stUid},on:{"click":_vm.handleVariantClick}},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }