<template>
  <div class="st-empaneling-age__slider-wrapper">
    <st-highlighted-slider
      v-model="recipientsAge"
      :axis-labels="[18, 99]"
      :max="99"
      :max-tooltip="ageHigh ? `${ageHigh} Years Old` : ''"
      :min="18"
      :min-tooltip="ageLow ? `${ageLow} Years Old` : ''"
      :variant="['dual', 'highlighted-track']"
      @mouseup="saveAge"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { StHighlightedSlider } from '../../../../common'

export default {
  components: {
    StHighlightedSlider
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('requestAudience', ['ageHigh', 'ageLow']),
    recipientsAge: {
      get () {
        return [this.ageLow, this.ageHigh]
      },
      set (newAge) {
        this.setAge([...newAge])
      }
    }
  },
  methods: {
    ...mapMutations('requestAudience', ['setAge']),
    ...mapActions('requestAudience', ['updateAge']),
    saveAge () {
      this.updateAge([this.ageLow, this.ageHigh])
    }
  }
}
</script>
