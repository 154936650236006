<template>
  <div class="">
    <div class="st-survey-insights__panel-title st-survey-insights__modifiers-panel-title">
      Modifiers
    </div>
    <template v-if="questionHasSentiment">
      <div class="st-survey-insights__panel-section-with-line">
        <div class="st-survey-insights__panel-toggle-title">
          View
        </div>
        <st-toggle
          class="st-survey-insights__panel-top-toggle"
          first-option="Results"
          second-option="Transcripts"
          :active-option="activeQuestionView"
          @updateOption="updateViewToggle"
        />
        <div class="st-survey-insights__panel-toggle-title">
          Unit of Measure
        </div>
        <st-toggle
          first-option="Votes"
          second-option="Percentage"
          :active-option="activeQuestionModifier"
          @updateOption="updateMeasureToggle"
        />
      </div>
      <div class="st-survey-insights__panel-section-with-line">
        <div
          class="st-survey-insights__modifiers-toggle-row"
        >
          <div
            :class="{
              'st-survey-insights__panel-subtitle': true,
              'st-survey-insights__sentiments-panel-subtitle': !showSentiments
            }"
            @click="showSentiments = !showSentiments"
          >
            Sentiment
          </div>
          <icon-wrapper
            v-if="!isDesktop"
            icon-name="chevron"
            :class="{
              'st-survey-insights__modifiers-toggle--up': showSentiments,
              'st-survey-insights__modifiers-toggle--down': !showSentiments
            }"
          />
        </div>
        <template v-if="showSentiments">
          <st-checkbox
            key="veryPositive"
            :checked="sentiments.veryPositive"
            class="st-survey-insights__checkbox"
            @input="toggleSentiment('veryPositive', !sentiments.veryPositive)"
          >
            Very Positive
          </st-checkbox>
          <st-checkbox
            key="positive"
            :checked="sentiments.positive"
            class="st-survey-insights__checkbox"
            @input="toggleSentiment('positive', !sentiments.positive)"
          >
            Positive
          </st-checkbox>
          <st-checkbox
            key="neutral"
            :checked="sentiments.neutral"
            class="st-survey-insights__checkbox"
            @input="toggleSentiment('neutral', !sentiments.neutral)"
          >
            Neutral
          </st-checkbox>
          <st-checkbox
            key="negative"
            :checked="sentiments.negative"
            class="st-survey-insights__checkbox"
            @input="toggleSentiment('negative', !sentiments.negative)"
          >
            Negative
          </st-checkbox>
          <st-checkbox
            key="veryNegative"
            :checked="sentiments.veryNegative"
            class="st-survey-insights__checkbox"
            @input="toggleSentiment('veryNegative', !sentiments.veryNegative)"
          >
            Very Negative
          </st-checkbox>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="st-survey-insights__panel-section-with-line">
        <div class="st-survey-insights__panel-toggle-title">
          Unit of Measure
        </div>
        <st-toggle
          first-option="Votes"
          second-option="Percentage"
          :active-option="activeQuestionModifier"
          @updateOption="updateMeasureToggle"
        />
      </div>
    </template>
    <participant-checkboxes />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  IconWrapper,
  StCheckbox,
  StToggle
} from '../../../common'

import ParticipantCheckboxes from './participant-checkboxes'
import { isDesktop } from '../../../../mixins'

export default {
  components: {
    IconWrapper,
    StCheckbox,
    StToggle,
    'participant-checkboxes': ParticipantCheckboxes
  },
  mixins: [isDesktop],
  props: {
  },
  data: function () {
    return {
      showSentiments: true,
      showParticipants: true
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionModifier', 'activeQuestionView', 'sentiments']),
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingSentimentFilter', 'responsesPassingSegmentFilter']),
    activeResponses () {
      return this.questionHasSentiment ? this.responsesPassingSentimentFilter : this.responsesPassingSegmentFilter
    },
    questionHasSentiment () {
      const questionTypesHasSentiment = ['opinion_metric', 'virtual_tour', 'culture_board']
      return questionTypesHasSentiment.includes(this.activeQuestion.questionType)
    }
  },
  mounted () {
    if (!this.isDesktop) {
      this.showSentiments = false
    }
  },
  methods: {
    updateViewToggle (option) {
      this.$store.commit('insights/replaceView', option)
      let url = new URL(window.location)
      let questionId = url.searchParams.get('question')
      let title = ''
      let newState = {}

      if (option === 'Results') {
        title = 'results mode'
        newState.mode = 'normal'
        url = `?question=${questionId}`
      } else if (option === 'Transcripts') {
        url = `?question=${questionId}&mode=transcript`
        newState.mode = 'transcript'
      }
      window.history.replaceState(newState, title, url)
    },
    updateMeasureToggle (option) {
      this.$store.commit('insights/replaceModifier', option.toLowerCase())
    },
    toggleSentiment (key, val) {
      this.$store.commit('insights/toggleSentiment', {
        sentimentKey: key,
        value: val
      })
    }
  }
}
</script>
