<template>
  <div class="st-prospectives--thankyou">
    <div class="st-prospectives__component-thankyou st-prospectives__dots">
      <div class="bx--grid">
        <div class="bx--row st-prospectives__row-thankyou">
          <div class="bx--col-xs-12 bx--col-lg-7">
            <div class="st-prospectives__detail-thankyou">
              <h1 class="page-header st-prospectives__heading st-prospectives__heading-thankyou">
                Thank you!
              </h1>
              <p class="st-prospectives__para-lg st-prospectives__para-lg-thankyou">
                We will send you an email with your results in the next 2-5 minutes. In the meantime you
                can schedule a live demo of our platform, or go to our pricing page to see what works
                best for you and your company.
              </p>
              <div class="st-prospectives__button-canvas-double">
                <st-button
                  class="st-prospectives__button st-prospectives__button-caption-m st-button__set"
                  caption="Go to pricing page"
                  :show-action="true"
                  :underline="false"
                  :variant="['whiteBackground']"
                  @click="advanceToPricing"
                />
                <st-button
                  class="st-prospectives__button st-prospectives__button-caption-m st-button__set"
                  caption="Schedule a live demo"
                  :show-action="true"
                  :underline="false"
                  :variant="['secondary']"
                  @click="scrollIntoView"
                />
              </div>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-lg-5">
            <div class="st-prospectives__img-thankyou" />
          </div>
        </div>
      </div>
    </div>
    <div class="st-welcome__combine-component">
      <div class="bx--grid">
        <div
          id="contactForm"
          ref="contactForm"
          class="st-welcome__help"
        >
          <div class="bx--row">
            <div class="bx--col-xs-12 bx--col-lg-6 bx--col-xl-5">
              <h1 class="st-welcome__combine-heading st-welcome__combine-heading-help">
                See how spacetrics can help you understand what people think about your real estate spaces.
              </h1>
              <h5 class="st-welcome__subheading st-welcome__subheading-lightgrey">
                Fill in the form and we will be in touch shortly to give you a demo of the platform.
              </h5>
            </div>
            <div class="bx--col-xs-12 bx--col-lg-6 bx--offset-xl-1 bx--col-xl-5">
              <form
                class="st-welcome__help-group"
                action="https://formspree.io/f/zak@dupontstudios.com"
                method="post"
              >
                <input
                  id="fullName"
                  class="st-welcome__help-input"
                  name="Full Name"
                  placeholder="FULL NAME"
                  type="name"
                >
                <input
                  id="companyName"
                  class="st-welcome__help-input"
                  name="Company Name"
                  placeholder="COMPANY NAME"
                  type="name"
                >
                <input
                  id="email"
                  class="st-welcome__help-input"
                  name="Email"
                  placeholder="EMAIL"
                  type="email"
                >
                <input
                  id="phoneNumber"
                  class="st-welcome__help-input"
                  name="Phone Number"
                  placeholder="PHONE NUMBER"
                  type="tel"
                >
                <button
                  class="st-welcome__button-submit"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <footer class="st-footer">
          <a
            href="/"
            class="st-footer__logo"
          >
            <icon-wrapper
              icon-name="spacetrics"
              class="st-footer__logo-icon"
            />
            <span class="st-footer__logo-text">spacetrics</span>
          </a>
          <div class="st-footer__content">
            <div class="st-footer__list">
              <a
                class="st-footer__link"
                href="/terms"
                target="_blank"
              >terms & conditions</a>
              <a
                class="st-footer__link"
                href="/pricing"
                target="_blank"
              >privacy policy</a>
            </div>
            <p class="st-footer__copyright">
              ©2021 Spacetrics
            </p>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>

import { StButton, IconWrapper } from '../../common'

export default {
  components: {
    StButton,
    IconWrapper
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    advanceToPricing () {
      window.location = '/pricing'
    },
    scrollIntoView () {
      this.$root.scrollIntoView({ element: this.$refs.contactForm, block: 'center' })
    }
  }
}
</script>
