<script>
export default {
  data: function () {
    return {
      selectedId: null
    }
  },
  methods: {
    handlePreviewBack: function () {
      if (this.previousPreviewQuestion) {
        window.location.href = `/questions/${this.previousPreviewQuestion.id}/preview`
      } else {
        window.location.href = `/surveys/${this.surveyId}/publish`
      }
    }
  }
}

</script>
