<template>
  <div class="icon_heart">
    <div class="icon_heart__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M-23217.408-95.593c1.133-2.631 4.711-2.6 5.85-1.743a7.077 7.077 0 011.594 1.516 7.487 7.487 0 011.648-1.516 4.174 4.174 0 015.781 1.743 5.383 5.383 0 01-1.033 6.5l-6.4 7.232-6.254-7.232a6.343 6.343 0 01-1.186-6.5zm1.275-.2c-1 1.232-1.15 3.822.688 6.061l5.482 6.349 5.658-6.391a4.364 4.364 0 00.877-5.37 2.97 2.97 0 00-3.227-1.692c-1.5.187-2.092 1.1-3.34 2.636a6.689 6.689 0 00-2.166-2.34 3.509 3.509 0 00-3.972.749z"
            transform="translate(23217.865 97.863)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
