<script>
// Intended to be used within a question specifications component

export default {
  data: function () {
    return {
      acceptedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      showCropperModal: false,
      cropperSrc: null,
      cropperFileName: null,
      uploadFromDropzone: false,
      showNotification: false
    }
  },
  methods: {
    openCropper (ev, fromDropzone) {
      this.uploadFromDropzone = fromDropzone
      let fileType = ''
      if (this.uploadFromDropzone === true) {
        fileType = ev.target.files[0].type
      } else {
        fileType = ev[0].file.type
      }
      if (this.acceptedFileTypes.includes(fileType)) {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.cropperSrc = e.target.result
          this.showCropperModal = true
          console.log("going to open modal", this.showCropperModal)
        }
        if (ev.target) {
          console.log("going to set the names")
          this.cropperFileName = ev.target.files[0].name
          reader.readAsDataURL(ev.target.files[0])
        } else {
          this.cropperFileName = ev[0].file.name
          reader.readAsDataURL(ev[0].file)
        }
      } else {
        this.$data.showNotification = true
      }
    }
  }
}
</script>
