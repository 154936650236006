<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['currentUser']),
    isUserAdmin () {
      return ['admin', 'spacetrics_admin'].includes(this.currentUser.kind)
    }
  }
}
</script>
