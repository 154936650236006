<template>
  <div class="icon_spatialpreference">
    <div class="icon_spatialpreference__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h4v1H0z"
          transform="translate(8)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M0 0h5v1H0z"
          transform="translate(11 3)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 11)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(0 8)"
        />
        <path
          d="M0 0h1v6H0z"
          transform="translate(15 3)"
        />
        <path
          d="M0 0h1v6H0z"
          transform="translate(11)"
        />
        <path
          d="M1.5 0A1.5 1.5 0 110 1.5 1.5 1.5 0 011.5 0z"
          transform="translate(10 11)"
        />
        <path
          d="M1.5 0A1.5 1.5 0 110 1.5 1.5 1.5 0 011.5 0z"
          transform="translate(2 2)"
        />
        <path
          d="M7 3.5A3.5 3.5 0 113.5 0 3.5 3.5 0 017 3.5zm-1 0A2.5 2.5 0 103.5 6 2.5 2.5 0 006 3.5z"
          transform="translate(8 9)"
        />
        <path d="M7 3.5A3.5 3.5 0 113.5 0 3.5 3.5 0 017 3.5zm-1 0A2.5 2.5 0 103.5 6 2.5 2.5 0 006 3.5z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
