<template>
  <div class="bx--grid">
    <st-toast-notification
      v-if="showToast"
      kind="info"
      title="Info"
      :sub-title="toastMessage"
      @click="showToast = false"
    />
    <div class="mobile-header">
      <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          :caption="'Save & Continue'"
          :disabled="buttonDisabled"
          @click="saveAndToHub"
        />
      </div>
    </div>
    <div>
      <div class="bx--col-sm-12 bx--col-xs-12">
        <div class="space-between">
          <div>
            <div class="st-edit-survey__back-and-save">
              <st-hyperlink
                :back-link="true"
                text="Back"
                @click.native="saveAndToHub"
              />
              <div class="st-edit-survey__mobile-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
            </div>
            <div class="st-edit-survey__header-and-icon st-edit-incentives__header">
              <icon-wrapper
                class="st-edit-survey__header-icon"
                icon-name="incentives"
              />
              <h1 class="st-edit-survey__page-header">
                Incentives
              </h1>
            </div>
            <p class="st-page-instructions st-edit-incentives__header-spacing">
              Add a reward to your survey (disbursement is done through the Admin Dashboard).
            </p>
          </div>
          <div>
            <div class="st-edit-survey__save-link-and-button">
              <div class="st-edit-survey__inline-spiner-and-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                  class="st-edit-survey__desktop-inline-spinner"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
              <st-button
                :caption="'Save & Continue'"
                :disabled="buttonDisabled"
                @click="saveAndToHub"
              />
            </div>
          </div>
        </div>
        <div class="bx--offset-lg-1 bx--col-lg-10 bx--offset-xxl-2 st-edit-incentives__selectables">
          <p class="st-edit-incentives__sub-header">
            Select Gift Card
          </p>
          <div class="st-edit-incentives__gift-cards">
            <st-card
              v-for="(amount) in amounts"
              :key="amount"
              :class="{
                'st-edit-incentives-gift-card__contents': true,
                'st-edit-incentives-gift-card__contents--disabled': isEditingDisabled,
                'st-edit-incentives-gift-card__contents--selected': amount === incentiveAmount
              }"
              @click="updateIncentiveAmount(amount)"
            >
              <template v-slot:card-content>
                <div class="st-edit-incentives-gift-card__contents-wrapper">
                  <div
                    class="st-edit-incentives-gift-card__amazon-logo"
                  />
                  <p class="st-edit-incentives-gift-card__type">
                    Amazon Gift Card
                  </p>
                  <p class="st-edit-incentives-gift-card__price">
                    ${{ amount.toFixed(2) }}
                  </p>
                </div>
              </template>
            </st-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconWrapper, StButton, StCard, StHyperlink, StToastNotification } from '../../common'
import isEditingDisabled from './isEditingDisabled'
import saveUpdates from './saveUpdates'

export default {
  components: {
    IconWrapper,
    StButton,
    StCard,
    StHyperlink,
    StToastNotification
  },
  mixins: [isEditingDisabled, saveUpdates],
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      amounts: [5, 10, 15, 20, 25, 30, 40, 50],
      incentiveAmount: this.survey.incentiveAmount
    }
  },
  computed: {
    buttonDisabled () {
      return (this.incentiveAmount === 0 && !this.isEditingDisabled) || (this.isEditingDisabled)
    }
  },
  methods: {
    updateIncentiveAmount (amount) {
      if (this.isEditingDisabled) { return }
      this.incentiveAmount = amount === this.incentiveAmount ? 0 : amount
    },
    updateSurvey () {
      return this.$axios.request({
        method: 'patch',
        url: `surveys/${this.survey.id}/`,
        data: {
          survey: { incentive_amount: this.incentiveAmount }
        }
      })
        .then(res => { console.log(res) })
        .catch(err => { console.log(err) })
    }
  }
}
</script>
