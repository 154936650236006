<template>
  <div class="specifications__section">
    <div class="specifications__title">
      Preference Slider
    </div>
    <div class="st-input__row">
      <label class="st-input__label">
        Question
        <input
          :value="activeQuestion.description"
          class="st-input"
          type="text"
          name="question[description]"
          placeholder="Your question goes here"
          :disabled="isPreview"
          @blur="updateQuestion('description', $event.target.value)"
        >
      </label>
    </div>
    <div class="st-input__row">
      <label class="st-input__label st-input__label--half-length">
        Concept Name 1
        <input
          :value="activeQuestion.minYLabel"
          class="st-input"
          type="text"
          placeholder="Concept name goes here"
          :disabled="isPreview"
          @blur="updateQuestion('min_y_label', $event.target.value)"
        >
      </label>
      <label class="st-input__label st-input__label--half-length">
        Concept Name 2
        <input
          :value="activeQuestion.maxYLabel"
          class="st-input"
          type="text"
          placeholder="Concept name goes here"
          :disabled="isPreview"
          @blur="updateQuestion('max_y_label', $event.target.value)"
        >
      </label>
    </div>
  </div>
</template>

<script>
import updateQuestion from '../../../mixins/question/updateQuestion'

export default {
  mixins: [updateQuestion],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
    }
  }
}
</script>
