<template>
  <div class="icon_education">
    <div class="icon_education__svg">
      <svg
        viewBox="0 0 16.299 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M7.811.222L15.833 5.4l-8.022 5.082L0 5.4zm.007 9.085L14 5.4l-6.181-4-6.019 4z"
            transform="translate(.466 -.222)"
          />
          <path
            d="M5.179 9.191v4.364c2.877 3.349 6.857 2.9 8.866.036v-4.4h.99v4.667a6.4 6.4 0 01-5 3.044 6.786 6.786 0 01-4.904-1.982 8.056 8.056 0 01-.838-.885c-.148-.19-.1.386-.1-4.844z"
            transform="translate(-1.38 -2.498)"
          />
          <path
            d="M7.981 7.026l-6.332.9v6.167L.461 17.405l-.931-.334 1.13-3.149V7.069l7.181-1.023z"
            transform="translate(.471 -1.405)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
