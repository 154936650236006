<template>
  <cv-loading
    class="st-spinner"
    :overlay="true"
  />
</template>

<script>
import CvLoading from '@carbon/vue/src/components/cv-loading/cv-loading'

export default {
  components: {
    CvLoading
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
