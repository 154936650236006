var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-virtual-tour__inner-wrapper"},[_c('transition',{attrs:{"name":"fade-up"}},[(_vm.mode === _vm.MODES.PROMPT)?_c('div',{key:_vm.currentPrompt,class:{
        'st-virtual-tour__prompt': true,
        'st-virtual-tour__prompt--specs-preview': _vm.isSpecificationsPreview,
        'st-virtual-tour__prompt--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
      }},[_vm._v("\n      "+_vm._s(_vm.currentPrompt ? _vm.currentPrompt : '...')+"\n    ")]):([_vm.MODES.FULL_TOPIC, _vm.MODES.SMALL_TOPIC].includes(_vm.mode))?_c('div',{key:_vm.currentTopic,class:[{ 'st-virtual-tour__topic': true},_vm.topicClass]},[(_vm.mode === _vm.MODES.SMALL_TOPIC)?[_c('p',{staticClass:"st-virtual-tour__topic-title"},[_vm._v("\n          "+_vm._s(_vm.topicFraction)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"st-virtual-tour__close-wrapper",on:{"click":_vm.closeTopic}},[_c('icon-wrapper',{staticClass:"st-virtual-tour__icon-close",attrs:{"icon-name":'plus',"invert":true}})],1)]:_vm._e(),_vm._v(" "),_c('div',{class:{
          'st-virtual-tour__topic-text': true,
          'st-virtual-tour__topic-text--small': _vm.hasViewedTopic
        }},[_vm._v("\n        "+_vm._s(_vm.currentTopic ? _vm.currentTopic : '...')+"\n      ")]),_vm._v(" "),(_vm.mode === _vm.MODES.SMALL_TOPIC)?_c('a',{staticClass:"st-virtual-tour__topic-list-link",on:{"click":_vm.openTopicList}},[_vm._v("\n        View Other Topics\n      ")]):_vm._e()],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }