<template>
  <div class="icon_incentives">
    <div class="icon_incentives__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h14v7H0zm13 6V1H1v5z"
          transform="translate(1 9)"
        />
        <path
          d="M0 0h16v4H0zm15 3V1H1v2z"
          transform="translate(0 6)"
        />
        <path
          d="M-1114.5 3239v9.375h-1V3239z"
          transform="translate(1123 -3233)"
        />
        <path
          d="M-1117.59 3232.989c2.1.448 2.343 5.026 2.4 7.154-.683-.4-.983-.559-1.569-.954-1.083-.732-3.8-2.7-3.314-4.565a2.392 2.392 0 012.483-1.635zm-1.518 1.886c-.26 1.006 1.459 2.5 2.832 3.436-.158-1.783-.629-4.152-1.523-4.343a1.447 1.447 0 00-1.309.907z"
          transform="translate(1123 -3232.958)"
        />
        <path
          d="M-1117.759 3232.989a2.391 2.391 0 012.486 1.635c.483 1.864-2.231 3.833-4.883 5.519l.023-.894c.048-1.903.492-5.857 2.374-6.26zm.209.978c-.894.191-1.365 2.56-1.523 4.343 1.373-.938 3.093-2.43 2.832-3.436a1.445 1.445 0 00-1.309-.907z"
          transform="translate(1128 -3232.958)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
