<template>
  <div class="icon_pickfavorite">
    <div class="icon_pickfavorite__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h3v1H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(13)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(9 2)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(15 2)"
        />
        <path
          d="M-23263.764-260.467a1.577 1.577 0 01.619.44c.906 1.033.084 2.421-.838 3.443a1.644 1.644 0 01.271.482 1.51 1.51 0 01.854 1.189 1.66 1.66 0 01.381 2.057 12.614 12.614 0 01-2.7 2.891c-.957.739-2.689.461-3.971-.99-.955-1.08-1.572-1.695-1.275-3.075a2.287 2.287 0 01.377-2.218c.529-.873 1.189-1.645 2.229-1.514l1.336-1.454a1.06 1.06 0 00-.068.08c.695-.888 1.754-1.731 2.785-1.331zm-2.793 1.34l.008-.009zm.783.647s-.969 1-1.727 1.91c-.715-.408-1.141-.044-1.7.876s-.312.734-.207 1.72c-.23.928-.078 1.1 1 2.315s2.238 1.167 2.637.859a12.206 12.206 0 002.41-2.538c.137-.534.1-.8-.52-.867.168-.928-.156-.941-.742-1.126a.794.794 0 00-1.047-.872c.484-.71 2.453-2.368 1.789-3.125s-1.892.848-1.892.848z"
          transform="translate(23270.551 265.569)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(9 6)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(13 6)"
        />
        <path d="M0 0h3v1H0z" />
        <path
          d="M0 0h3v1H0z"
          transform="translate(4)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h1v3H0z"
          transform="translate(6 2)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(0 6)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
