<template>
  <div class="st-request-audience__page-wrapper">
    <div class="st-request-audience__left-panel">
      <div class="bx--grid">
        <div class="bx--row">
          <div class="bx--col">
            <div class="st-request-audience__header-and-back-link">
              <st-hyperlink
                class="back-hyperlink-spacing--extra-space"
                :back-link="true"
                text="Back"
                url="/audience_requests"
              />
              <h1 class="page-header st-request-audiennce__header">
                Order Recipients
              </h1>
            </div>
            <p class="st-request-audience__description">
              Each demographic has a different price point. It will take 5-7 days for us to collect your audience.
            </p>
            <div class="st-request-audience__edit-section">
              <div class="st-request-audience__subsection">
                <h2 class="st-request-audience__subsection-header">
                  How many recipients do you need?
                </h2>
                <div class="st-request-audience__recipients-slider-wrapper">
                  <input
                    v-model="recipientsInputCount"
                    class="st-number-of-requests__net-box"
                    :type="recipientsInputCount === '-' ? 'text' : 'number'"
                  >
                  <st-highlighted-slider
                    v-model="recipientsSliderCount"
                    :axis-labels="[50, 250, 500, 750, 1000]"
                    :max="1000"
                    :max-tooltip="recipientsSliderCount ? recipientsSliderCount.toString() : ''"
                    :min="50"
                    :variant="['highlighted-track']"
                    @mouseup="updateNumberOfRecipientsFromState"
                  />
                </div>
              </div>
              <div class="st-request-audience__subsection">
                <h2 class="st-request-audience__subsection-header st-request-audience__subsection-header--recipients-count">
                  Demographics
                </h2>
                <div class="st-request-audience__demographics-wrapper">
                  <edit-accordions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="st-request-audience__right-panel">
      <h2 class="st-request-audience__subsection-header">
        Order Summary
      </h2>
      <template v-if="totalCost">
        <receipt
          :with-delete="true"
        />
        <div class="st-request-audience__continue-button-wrapper">
          <st-button
            class="st-request-audience__continue-button"
            caption="Continue"
            :disabled="!numberOfRecipients"
            icon-name="arrow"
            @click="saveAudienceRequest"
          />
        </div>
      </template>
      <template v-else>
        <div class="st-request-audience__empty-order-wrapper">
          <div class="st-request-audience__empty-order-receipt" />
          <p class="st-request-audience__empty-order-description">
            There are currently no items selected in your order.
          </p>
        </div>
      </template>
    </div>
    <div
      v-if="totalCost"
      class="st-request-audience__mobile-continue-button-wrapper"
    >
      <st-button
        class="st-request-audience__mobile-continue-button"
        caption="Continue"
        :disabled="!numberOfRecipients"
        @click="saveAudienceRequest"
      >
        <div class="st-request-audience__mobile-continue-button-content">
          <div class="st-button__caption">
            Continue
          </div>
          <icon-wrapper
            class="st-button__icon"
            :actionable="true"
            icon-name="arrow"
          />
          <div class="st-request-audience__continue-price">
            USD {{ totalCost }}
          </div>
        </div>
      </st-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { IconWrapper, StButton, StHighlightedSlider, StHyperlink } from '../../../common'
import EditAccordions from './edit-accordions'
import Receipt from './receipt'

export default {
  components: {
    EditAccordions,
    IconWrapper,
    Receipt,
    StButton,
    StHighlightedSlider,
    StHyperlink
  },
  props: {
    apiKey: {
      type: String,
      required: false,
      default: ''
    },
    customMapMarker: {
      type: String,
      required: false,
      default: ''
    },
    request: {
      type: Object,
      require: false,
      default: () => ({})
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('requestAudience', ['audienceRequestId', 'numberOfRecipients']),
    ...mapGetters('requestAudience', ['totalCost']),
    recipientsInputCount: {
      get () {
        return this.numberOfRecipients || '-'
      },
      set (value) {
        value >= 50 && value <= 1000
          ? this.updateNumberOfRecipients(parseInt(value))
          : event.preventDefault()
      }
    },
    recipientsSliderCount: {
      get () {
        return this.numberOfRecipients
      },
      set (value) {
        this.setRecipientCount(value)
      }
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
    this.setMapsKey(this.apiKey)
    this.setMapMarker(this.customMapMarker)
    if (Object.keys(this.request).length > 0) { this.replaceData(this.request) }
  },
  methods: {
    ...mapMutations('requestAudience', ['replaceData', 'setMapsKey', 'setMapMarker', 'setRecipientCount']),
    ...mapActions('requestAudience', ['updateNumberOfRecipients']),
    updateNumberOfRecipientsFromState () {
      this.updateNumberOfRecipients(this.numberOfRecipients)
    },
    saveAudienceRequest () {
      window.location.href = `/audience_requests/${this.audienceRequestId}/review`
    }
  }
}
</script>
