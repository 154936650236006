<template>
  <div class="icon_checkmark">
    <div class="icon_checkmark__svg">
      <svg
        viewBox="0 0 16 16.001"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-20991.408-10197.413l-10.91 15.425-5.09-5.042.705-.708 4.248 4.209 10.23-14.459z"
          transform="translate(21007.408 10197.989)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
