<template>
  <div
    :class="{
      'bx--grid': true,
      'st-join-complete__page': isJoinSurvey
    }"
  >
    <template v-if="isWdm">
      <div class="st-complete__wdm-logo" />
      <div class="st-complete__wdm-thank-you">
        Thank you for reviewing this submission
      </div>

      <div class="bx--row st-complete__follow-up">
        <div
          v-if="followUpLink"
          class="bx--col-xs-12 bx--col-md-6"
        >
          <st-button
            class="st-complete__follow-up-btn"
            :url="followUpLink"
            caption="Next Submission"
            :underline="false"
            :variant="['secondary']"
          />
        </div>
        <div
          :class="{
            'bx--col-xs-12': true,
            'bx--col-md-6': followUpLink }"
        >
          <st-button
            class="st-complete__follow-up-btn"
            url="https://www.workdesign.com/the-next-work-environment-jury-feedback-part-1/"
            caption="All Submissions"
            :underline="false"
            :variant="followUpLink ? [] : ['secondary']"
          />
        </div>
      </div>
      <hr>
      <p class="st-complete__wdm-thanks-sponsors">
        Special thanks to our sponsors:
      </p>
      <div class="st-complete__logo-row">
        <div class="st-complete__logo st-complete__logo--crypton" />
        <div class="st-complete__logo st-complete__logo--acoufelt" />
        <div class="st-complete__logo st-complete__logo--kimball" />
      </div>

      <div
        :class="{
          'st-powered-by-spacetrics st-powered-by-spacetrics--complete': true,
          'st-powered-by-spacetrics--wdm-complete': true,
          'st-powered-by-spacetrics--desktop': isDesktop
        }"
      >
        Powered by
        <icon-wrapper
          class="st-powered-by-spacetrics__icon"
          :actionable="false"
          icon-name="spacetrics"
        />
        spacetrics
      </div>
    </template>
    <template v-else-if="isJoinSurvey">
      <div class="st-join-complete__content-wrapper">
        <div class="st-join-complete__illustration" />
        <div class="st-join-complete__congrats-and-info">
          <p class="st-join-complete__congrats">
            Congrats!
          </p>
          <div class="st-join-complete__info-block">
            <p class="st-join-complete__info">
              Well done and thank you for your time!
            </p>
            <p class="st-join-complete__info">
              Your reward will be sent to your email and phone number after we review your submission.
            </p>
          </div>
        </div>
        <div class="st-join-complete__additional-and-email-us">
          <p class="st-join-complete__additional">
            Questions or comments?
          </p>
          <a
            href="mailto:zak@spacetrics.com"
            class="st-join-complete__email-us"
          >
            Please email us.
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="st-complete__column">
        <div class="st-complete__image" />
        <p class="st-complete__text st-complete__text--header">
          Thank you for submitting your feedback!
        </p>
        <div class="st-join-complete__additional-and-email-us">
          <p class="st-complete__text">
            We appreciate your time. If you have anything to share please feel free to
          </p>
          <a
            href="mailto:zak@spacetrics.com"
            class="st-complete__email-us"
          >
            email us.
          </a>
        </div>
        <st-hyperlink
          v-if="isPreview && !isDesktop"
          text="Exit Preview"
          icon-name="hide-password"
          icon-class="st-hyperlink__default-icon-spacing"
          :url="`/surveys/${survey.id}/publish`"
        />

        <div
          :class="{
            'st-powered-by-spacetrics st-complete__powered-by-complete': true,
            'st-powered-by-spacetrics--desktop': isDesktop
          }"
        >
          Powered by
          <icon-wrapper
            class="st-powered-by-spacetrics__icon"
            :actionable="false"
            icon-name="spacetrics"
          />
          spacetrics
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { isDesktop } from '../../../mixins'
import { IconWrapper, StButton, StHyperlink } from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink
  },
  mixins: [isDesktop],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isJoinSurvey: {
      type: Boolean,
      required: false,
      default: false
    },
    isWdm: {
      type: Boolean,
      required: false,
      default: false
    },
    survey: {
      type: Object,
      required: false,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    followUpLink () {
      return this.survey.followUpLink
    },
    companyLogoStyle () {
      let companyLogoUrl = this.userInfo.logoUrl
      let companyLogoStyleObject = {}
      if (companyLogoUrl) {
        companyLogoStyleObject['background-image'] = `url('${companyLogoUrl}')`
      } else {
        companyLogoStyleObject['background'] = '#EFEFEF'
        companyLogoStyleObject['border'] = '2px solid #FFFFFF'
      }
      return companyLogoStyleObject
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  }
}
</script>
