<template>
  <li
    class="cv-overflow-menu-item bx--overflow-menu-options__option st-overflow-menu__option"
    :class="{
      'bx--overflow-menu-options__option--disabled': disabled
    }"
    @mouseenter="onHover(true)"
    @mouseleave="onHover(false)"
  >
    <component
      :is="path ? 'a' : 'button'"
      :href="path"
      class="bx--overflow-menu-options__btn"
      :disabled="disabled"
      v-on="$listeners"
      @mousedown="onMousedown"
      @mouseup="onMouseup"
      @touch-start="onMousedown"
      @focusout="onFocusOut"
      @keydown.esc.prevent="onEsc"
    >
      <div class="st-overflow-menu__item">
        <slot
          name="custom-icon"
          :hover="hover"
        >
          <icon-wrapper
            v-if="iconName.length>0"
            :icon-name="iconName"
            :class="{
              'st-overflow-menu__icon': true,
              'st-overflow-menu__icon--hover': hover,
              'st-overflow-menu__icon--clicked': parentClicked && hover
            }"
          />
        </slot>
        <div
          :class="{
            'st-overflow-menu__item-text': true,
            'st-overflow-menu__item-text--hover': hover,
            'st-overflow-menu__item-text--clicked': parentClicked && hover
          }"
        >
          {{ text }}
        </div>
      </div>
    </component>
  </li>
</template>

<script>
import IconWrapper from './icon-wrapper'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    path: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      hover: false
    }
  },
  computed: {
    parentClicked () {
      return this.$parent.itemClicked
    }
  },
  methods: {
    onMousedown () {
      this.$parent.itemClicked = true
    },
    onFocusOut (ev) {
      this.$parent.menuItemFocusOut(ev)
    },
    onEsc (ev) {
      this.$parent.doClose(ev)
    },
    onMouseup () {
      this.$emit('mouseup')
      setTimeout(() => {
        this.$parent.menuItemclick()
        this.clicked = false
      }, 200)
      // if (this.path.length > 0) {
      //   window.location.href = this.path
      // }
    },
    onHover (bool) {
      if (!this.disabled) {
        this.hover = bool
      }
    }
  }
}
</script>

<style lang="scss"></style>
