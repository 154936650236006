<template>
  <div class="icon_trash">
    <div class="icon_trash__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h12v1H0z"
          transform="translate(2 15)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(5)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(5.999 5)"
        />
        <path
          d="M0 0h1v13H0z"
          transform="translate(1.999 3)"
        />
        <path
          d="M0 0h1v13H0z"
          transform="translate(12.999 3)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(8.999 5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
