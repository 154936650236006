import moment from 'moment'

let momentSet = new Set(['createdAt', 'invitationAcceptedAt', 'invitationCreatedAt'])

const userProxyHandler = {
  get (obj, prop) {
    let val = obj[prop]
    if (momentSet.has(prop)) {
      val = moment(val)
    } else if (prop === 'initials') {
      val = initials(obj)
    }
    return val
  }
}

export function User (user) {
  return new Proxy(user, userProxyHandler)
}

function initials (user) {
  let letters = []
  if (user.firstName) { letters.push(user.firstName[0]) }
  if (user.lastName) { letters.push(user.lastName[0]) }
  if (letters.length === 0) { letters = [user.email[0]] }

  return letters.join('').toUpperCase()
}
