<template>
  <div class="st-matterport-indicator__contents">
    <div class="st-matterport-indicator__pointer-and-text">
      <div class="st-matterport-indicator__pointer-and-circle">
        <icon-wrapper
          class="st-matterport-indicator__pointer"
          icon-name="pointer"
          :invert="true"
        />
        <div class="st-matterport-indicator__circle" />
      </div>
      <p class="st-matterport-indicator__instructions-text">
        Click on the Matterport to answer questions
      </p>
    </div>
    <div class="st-matterport-indicator__checkbox-and-button">
      <st-checkbox
        class="st-matterport-indicator__checkbox"
        :checked="hideMatterportIndicator"
        @input="updateVirtualTour"
      >
        <p class="st-matterport-indicator__dont-show-text">
          Do not show this message again.
        </p>
      </st-checkbox>
      <st-button
        class="st-matterport-indicator__button"
        caption="Continue"
        :underline="false"
        :variant="['secondary']"
        @click="$emit('indicator-continued')"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { IconWrapper, StButton, StCheckbox } from '../../../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StCheckbox
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('participants', ['activeQuestion', 'hideMatterportIndicator', 'participantEmail'])
  },
  methods: {
    ...mapMutations('participants', ['setMatterportIndicator', 'updateActiveQuestion']),
    updateVirtualTour (event) {
      this.$axios.request({
        method: 'patch',
        url: `/surveys/${this.activeQuestion.surveyId}/response`,
        data: { participant: { hide_matterport_indicator: event } }

      })
        .then(res => {
          this.setMatterportIndicator(res.data.participant.hide_matterport_indicator)
        })
        .catch(err => { console.log('err', err) })
    }
  }
}
</script>
