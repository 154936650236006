var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-sentiment-data-table-container"},[_c('table',{staticClass:"st-sentiment-data-table"},[_c('thead',{staticClass:"st-sentiment-data-table__header"},[_c('tr',{staticClass:"st-sentiment-data-table__header-row"},[_c('th',{staticClass:"st-sentiment-data-table__head-item"},[_c('div',{staticClass:"st-sentiment-data-table__header-and-column-chevron",on:{"click":function($event){return _vm.handleColumnSorting('Keyword')},"mouseenter":function($event){_vm.hoverIdx = 'Keyword'},"mouseleave":function($event){_vm.hoverIdx = null}}},[_vm._v("\n            "+_vm._s(_vm.columnTitle)+"\n            "),_c('div',{staticClass:"st-sentiment-data-table__column-chevron-container"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.activeColumn && _vm.activeColumn.name === 'Keyword' || (_vm.hoverIdx === 'Keyword'))?_c('icon-wrapper',{class:{
                    'st-sentiment-data-table__column-chevron': true,
                    'st-sentiment-data-table__column-chevron--descending': _vm.activeColumn && _vm.activeColumn.name === 'Keyword' && !_vm.activeColumn.ascending
                  },attrs:{"icon-name":"chevron"}}):_vm._e()],1)],1)])]),_vm._v(" "),_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index,staticClass:"st-sentiment-data-table__head-item"},[_c('div',{staticClass:"st-sentiment-data-table__header-and-column-chevron",on:{"click":function($event){return _vm.handleColumnSorting(header)},"mouseenter":function($event){_vm.hoverIdx = index},"mouseleave":function($event){_vm.hoverIdx = null}}},[_vm._v("\n            "+_vm._s(header)+"\n            "),_c('div',{staticClass:"st-sentiment-data-table__column-chevron-container"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.activeColumn && _vm.activeColumn.name === header || (_vm.hoverIdx === index))?_c('icon-wrapper',{class:{
                    'st-sentiment-data-table__column-chevron': true,
                    'st-sentiment-data-table__column-chevron--descending': _vm.activeColumn && _vm.activeColumn.name === header && !_vm.activeColumn.ascending
                  },attrs:{"icon-name":"chevron"}}):_vm._e()],1)],1)])])})],2)]),_vm._v(" "),_c('tbody',{staticClass:"st-sentiment-data-table__body"},[_vm._l((_vm.sortedRowsByActiveColumn),function(sentimentRow,idx){return [_c('tr',{key:idx + 'row',class:{
            'st-sentiment-data-table__row': true,
            'st-sentiment-data-table__row--header': idx === 0 && _vm.isFirstRowHeader,
            'st-sentiment-data-table__row--expandable': _vm.expandable
          },on:{"click":function($event){return _vm.toggleRowExpansion(idx)}}},[(_vm.expandable)?_c('td',{staticClass:"st-sentiment-data-table__item st-sentiment-data-table__item--link"},[_c('icon-wrapper',{class:{
                'st-sentiment-data-table__chevron': true,
                'st-sentiment-data-table__chevron--open': _vm.isExpanded(idx)
              },attrs:{"icon-name":"chevron","neutral":!_vm.isExpanded(idx)}}),_vm._v(" "),_c('div',{class:{
                'st-sentiment-data-table__keyword': true,
                'st-sentiment-data-table__keyword-open': _vm.isExpanded(idx)
              }},[_vm._v("\n              "+_vm._s(sentimentRow.title)+"\n            ")]),_vm._v(" "),_c('div',{class:{
                'st-sentiment-data-table__spacer': true,
                'st-sentiment-data-table__spacer--expanded': _vm.isExpanded(idx)
              }})],1):_c('td',{staticClass:"st-sentiment-data-table__item"},[_vm._v("\n            "+_vm._s(sentimentRow.title)+"\n          ")]),_vm._v(" "),_c('td',{staticClass:"st-sentiment-data-table__item"},[_c('sentiment-tag',{attrs:{"sentiment":_vm.rowAverages[idx]}})],1),_vm._v(" "),(_vm.includeCount)?_c('td',{staticClass:"st-sentiment-data-table__item"},[_vm._v("\n            "+_vm._s(_vm.rowTotalCounts[idx])+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.activeQuestionModifier==='votes')?_vm._l((_vm.rowSentimentCounts[idx]),function(sentimentCount,percentIdx){return _c('td',{key:percentIdx,staticClass:"st-sentiment-data-table__item"},[_vm._v("\n              "+_vm._s(sentimentCount)+"\n            ")])}):_vm._l((_vm.rowSentimentPercents[idx]),function(sentimentPercent,percentIdx){return _c('td',{key:percentIdx,staticClass:"st-sentiment-data-table__item"},[_vm._v("\n              "+_vm._s(sentimentPercent)+"%\n            ")])})],2),_vm._v(" "),(_vm.isExpanded(idx))?_c('tr',{key:idx + 'expanded-row'},[_c('td',{staticClass:"st-sentiment-data-table__item",attrs:{"colspan":_vm.includeCount ? 8 : 7}},[_vm._t("expanded-row",null,{"row":sentimentRow,"rowIdx":idx})],2)]):_vm._e()]})],2),_vm._v(" "),_c('tbody')])])}
var staticRenderFns = []

export { render, staticRenderFns }