<template>
  <div class="st-prospectives__count-box">
    <h5
      v-if="countHeading"
      class="st-prospectives__count-heading"
    >
      {{ countHeading }}
    </h5>
    <div class="st-slider-input__canvas st-prospectives__slider-canvas">
      <div class="st-slider-input__slider">
        <input
          class="st-slider-input__slider-input"
          type="range"
          :value="sliderValue"
          :name="name"
          :min="min"
          :max="max"
          :step="step"
          @input="sliderChange"
        >
      </div>
      <span class="st-slider-input__slider-count">{{ countLabel || sliderValue }}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    sliderValue: [Number, String],
    min: [Number, String],
    max: [Number, String],
    step: {
      type: Number,
      default: 1,
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    countHeading: {
      type: String,
      default: '',
      required: false
    },
    countLabel: {
      type: String,
      default: '',
      required: false
    }
  },
  methods: {
    sliderChange (e) {
      this.$emit('input', parseInt(e.target.value))
    }
  }
}

</script>
