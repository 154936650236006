<template>
  <div class="st-prospectives__plans">
    <div class="bx--row st-prospectives__row-plans">
      <div class="bx--col-xs-12 bx--col-lg-4">
        <div class="st-prospectives__plans-box">
          <div class="st-prospectives__plans-top">
            <div class="st-prospectives__plans-head">
              <h3 class="st-prospectives__plans-heading">
                Basic Plan
              </h3>
              <p class="st-prospectives__plans-price-project">
                {{ yearEnabled ? "$10,740/project" : "$895/project" }}
              </p>
            </div>
            <div class="st-prospectives__plans-content">
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  <span class="st-prospectives__plans-specs-digit">
                    {{ totalProjects }}
                  </span>
                  Project{{ parseInt(totalProjects) > 1 ? "s" : "" }}
                </div>
                <div class="st-prospectives__plans-specs-example">
                  (i.e. A new development, an office space, a location)
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  500 Recipients Per Project/mo
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  5 Surveys Per Project/mo
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Unlimited Team Members
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Spacetrics Templates
                </div>
              </div>
            </div>
          </div>
          <div class="st-prospectives__plans-bottom">
            <div class="st-prospectives__plans-total-block">
              <div class="st-prospectives__plans-total-text">
                Total
              </div>
              <div class="st-prospectives__plans-total-group">
                ${{ basicTotal }}
                <span
                  class="st-prospectives__plans-total-month"
                >/{{ yearEnabled ? "year" : "month" }}</span>
              </div>
            </div>
            <div
              class="st-prospectives__plans-btn-canvas"
            >
              <st-button
                url="/users/sign_up"
                class="st-prospectives__button st-prospectives__button-caption-m st-button__set"
                :variant="['secondary']"
                caption="Start 14 Day Trial"
                :show-action="true"
                :underline="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bx--col-xs-12 bx--col-lg-4">
        <div
          class="st-prospectives__plans-box st-prospectives__plans-box-active"
        >
          <div class="st-prospectives__plans-top">
            <div class="st-prospectives__plans-head">
              <h3 class="st-prospectives__plans-heading">
                Premium Plan
              </h3>
              <p class="st-prospectives__plans-price-project">
                {{ yearEnabled ? "$15,540/project" : "$1,295/project" }}
              </p>
            </div>
            <div class="st-prospectives__plans-content">
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  <span class="st-prospectives__plans-specs-digit">
                    {{ totalProjects }}
                  </span>
                  Project{{ parseInt(totalProjects) > 1 ? "s" : "" }}
                </div>
                <div class="st-prospectives__plans-specs-example">
                  (i.e. A new development, an office space, a location)
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  500 Recipients Per Project/mo
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Unlimited Surveys
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Unlimited Team Members
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Custom Branding
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Custom Templates
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Aggregated Data
                </div>
              </div>
            </div>
          </div>
          <div class="st-prospectives__plans-bottom">
            <div class="st-prospectives__plans-total-block">
              <div class="st-prospectives__plans-total-text">
                Total
              </div>
              <div class="st-prospectives__plans-total-group">
                ${{ premiumTotal }}
                <span
                  class="st-prospectives__plans-total-month"
                >/{{ yearEnabled ? "year" : "month" }}</span>
              </div>
            </div>
            <div
              class="st-prospectives__plans-btn-canvas"
            >
              <st-button
                url="/users/sign_up"
                class="st-prospectives__button st-prospectives__button-caption-m st-button__set"
                :variant="['secondary']"
                caption="Start 14 Day Trial"
                :show-action="true"
                :underline="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bx--col-xs-12 bx--col-lg-4">
        <div class="st-prospectives__plans-box">
          <div class="st-prospectives__plans-top">
            <div class="st-prospectives__plans-head">
              <h3 class="st-prospectives__plans-heading">
                Development Plan
              </h3>
            </div>
            <div class="st-prospectives__plans-content">
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  All Premium Features
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Personal Onboarding
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Survey Consultation
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Insights Consultation
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Embeddable Spacetrics
                </div>
              </div>
              <div class="st-prospectives__plans-specs">
                <icon-wrapper
                  icon-name="check"
                  class="st-prospectives__check-circle st-prospectives__plans-specs-check"
                />
                <div class="st-prospectives__plans-specs-detail">
                  Audience Empaneling
                </div>
              </div>
            </div>
          </div>
          <div class="st-prospectives__plans-bottom">
            <div
              class="st-prospectives__plans-btn-canvas"
            >
              <st-button
                class="st-prospectives__button st-prospectives__button-caption-m st-button__set"
                :variant="['secondary']"
                caption="Contact Us"
                :show-action="true"
                :underline="false"
                @click="scrollToContact"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="st-prospectives__link-canvas">
      <!--
      <a
        href="#"
        class="st-prospectives__link-blue"
      >SEE THE VALUE OF SPACETRICS</a>
      -->
    </div>
  </div>
</template>

<script>
import { StButton, IconWrapper } from '../../common'

export default {
  components: {
    StButton,
    IconWrapper
  },
  props: {
    basicTotal: {
      type: [Number, String],
      required: true
    },
    premiumTotal: {
      type: [Number, String],
      required: true
    },
    totalProjects: {
      type: [Number, String],
      required: true
    },
    yearEnabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    scrollToContact () {
      this.$root.scrollIntoView({ id: 'contactForm', block: 'center' })
    }
  }
}
</script>

<style></style>
