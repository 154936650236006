<template>
  <div
    ref="stRecordMembers"
    :class="{
      'st-record-members': false,
      'st-record-members--open': isPopUpOpen && title === 'Survey'
    }"
  >
    <div
      v-if="isPopUpOpen && !isDesktop"
      class="st-dropdown-menu__mobile-opacitor"
      @click="isPopUpOpen = false"
    />
    <div
      v-if="isPopUpOpen"
      class="st-record-members__body"
    >
      <div class="st-record-members__title-and-close">
        <h2 class="st-record-members__title">
          {{ title }} Team
        </h2>
        <button
          class="st-record-members__close-button"
          @click="isPopUpOpen = false"
        >
          <icon-wrapper
            class="st-record-members__close-icon"
            icon-name="plus"
          />
        </button>
      </div>
      <div
        ref="membersContainer"
        class="st-record-members__members-container"
      >
        <record-member
          v-for="member in members"
          ref="member"
          :key="member.id"
          :confirmation-text="title === 'Survey'? 'currently have full access' : 'are currently an admin'"
          :member="member"
          :can-add-record-member="canAddRecordMember"
          :can-open-dropdown="canOpenDropdown"
          :project-id="projectId"
          :survey-id="surveyId"
          @modalClosed="$emit('modalClosed')"
          @modalOpen="$emit('modalOpen')"
          @removeMember="removeMember"
          @updateMember="$emit('updateMember', $event)"
        />
      </div>
    </div>
    <div
      v-if="isPopUpOpen"
      tabindex="0"
      :class="{
        'st-record-members__primary-button st-record-members__primary-button--add': true,
        'st-record-members__primary-button--not-admin': !canAddRecordMember
      }"
      @click="openModal"
      @keyup.enter="openModal"
    >
      <st-hyperlink
        v-if="canAddRecordMember"
        class="st-record-members__primary-button-link"
        icon-name="add-member"
        :invert="true"
        text="Invite Members"
      />
    </div>
    <slot
      v-else
      :openMembers="openMembers"
    />
    <st-modal
      v-model="isModalOpen"
      size-class="st-record-members__add-member-modal"
      @closeModal="closeAddMemberModal"
    >
      <template v-slot:modalTitle>
        Add Team Members
      </template>
      <template v-slot:modalActions>
        <st-email-input
          ref="emailInput"
          class="st-record-members__add-member-email-input"
          :invite-text="title === 'Survey'? 'survey' : 'project'"
          label="Type a name or email"
          :only-autocomplete="currentUser.kind !== 'admin' && currentUser.kind !== 'spacetrics_admin'"
          :existing-members-list="currentUsersNotInTeam"
          @addNewEmail="addInviteEmail"
          @removeEmail="removeInviteEmail"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Add Members"
          :disabled="newEmails.length===0 || $refs.emailInput.errorMessage.length!==0"
          icon-name="add-member"
          :show-action="true"
          @click="addMembers"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { compareObjects } from '../../../utilities/data_utilities'
import closeElement from '../../../mixins/closeElement'
import { isDesktop } from '../../../mixins'
import RecordMember from './record-member'
import { IconWrapper, StButton, StEmailInput, StHyperlink, StModal } from '../../common'

export default {
  components: {
    IconWrapper,
    RecordMember,
    StButton,
    StEmailInput,
    StHyperlink,
    StModal
  },
  mixins: [closeElement, isDesktop],
  props: {
    canAddRecordMember: {
      type: Boolean,
      required: false,
      default: true
    },
    canOpenDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    members: {
      type: Array,
      required: true
    },
    projectId: {
      type: Number,
      required: false,
      default: null
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    users: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      isModalOpen: false,
      isPopUpOpen: false,
      newEmails: []
    }
  },
  computed: {
    ...mapState(['currentUser']),
    currentUsersNotInTeam () {
      return compareObjects(this.users, this.members, 'email', true)
    }
  },
  methods: {
    addInviteEmail (email) {
      event.stopPropagation()
      this.newEmails.push(email.email)
    },
    addMembers () {
      event.stopPropagation()
      this.$emit('invite-members', this.sortNewEmails())
      this.isModalOpen = false
    },
    closeAddMemberModal () {
      event.stopPropagation()

      if (this.isDesktop) { this.$emit('modalClosed') }
    },
    closeFn (event) {
      if (!this.$refs.stRecordMembers.contains(event.target)) { this.isPopUpOpen = false }
    },
    enterFn (event) {
      if (event.keyCode === 27) { this.closeFn(event) }
    },
    openMembers () {
      this.$emit('modalOpen')
      this.isPopUpOpen = true
    },
    openModal () {
      this.newEmails = []
      this.isModalOpen = true
      this.$emit('modalOpen')
    },
    removeInviteEmail (email) {
      event.stopPropagation()
      if (!email.error) { this.newEmails.splice(this.newEmails.indexOf(email.email), 1) }
    },
    removeMember (payload) {
      this.$emit('removeMember', payload)
    },
    sortNewEmails () {
      let sortedUsers = {
        existingUsersToAdd: [],
        newEmailsToAdd: []
      }
      this.newEmails.forEach(email => {
        let user = this.users.find(u => u.email === email)
        user === undefined ? sortedUsers.newEmailsToAdd.push(email) : sortedUsers.existingUsersToAdd.push(user)
      })
      return sortedUsers
    }
  }
}
</script>
