<template>
  <component
    :is="componentName"
    :is-read-only="isReadOnly"
    :folders="folders"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import PreferenceSliderSpecifications from '../../projects_old/question_specifications/preference-slider'
// import CultureBoardSpecifications from '../../projects_old/question_specifications/culture-board'
import PerceptionSliderSpecifications from '../../projects_old/question_specifications/perception-slider'
import QuadrantPlacementSpecifications from './question_specifications/quadrant-placement.vue'
import SpatialAdjacenciesSpecifications from '../../projects_old/question_specifications/spatial-adjacencies'
import ElementPreferenceSpecifications from '../../projects_old/question_specifications/element-preference'
import PickFavoriteSpecifications from '../../projects_old/question_specifications/pick-favorite'

import * as QuestionSpecifications from './question_specifications'

export default {
  components: {
    'preference-slider-specifications': PreferenceSliderSpecifications,
    // 'culture-board-specifications': CultureBoardSpecifications,
    'perception-slider-specifications': PerceptionSliderSpecifications,
    'quadrant-placement-specifications': QuadrantPlacementSpecifications,
    'spatial-adjacencies-specifications': SpatialAdjacenciesSpecifications,
    'element-preference-specifications': ElementPreferenceSpecifications,
    'pick-favorite-specifications': PickFavoriteSpecifications,

    ...QuestionSpecifications
  },
  props: {
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    folders: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters('specifications', ['activeQuestion']),
    componentName: function () {
      if (this.activeQuestion.questionType) {
        const hyphenedName = this.activeQuestion.questionType.replace('_', '-')
        return `${hyphenedName}-specifications`
      } else {
        return ''
      }
    }
  }
}
</script>
