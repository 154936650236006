<template>
  <div class="bx--grid st-tutorials">
    <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
    </div>
    <div class="bx--offset-sm-1 bx--col-sm-8 bx--col-xs-12">
      <h1>
        Tutorials
      </h1>
      <p class="st-help-section__message">
        Here you will find answers to most questions you may have. For additional help, check our templates, resources, or chat with us!
      </p>
      <st-input
        v-model="tutorialLookupValue"
        class="st-help-section__questions-search"
        :show-icon="true"
        :show-label="false"
        label="Tutorial Lookup"
        @blur="updateSearchParam"
      />
      <div
        v-for="tutorial in filteredSections"
        :key="tutorial.section"
      >
        <p class="st-tutorials__section-name">
          {{ tutorial.section }}
        </p>
        <div
          class="st-tutorials__grid"
        >
          <st-wistia-video
            v-for="video in tutorial.videos"
            :key="'video-' + video.wistiaId"
            class="st-tutorials__video"
            :wistia-id="video.wistiaId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isDesktop, refineUrlFilter } from '../../../mixins'
import { IconWrapper, StInput, StWistiaVideo } from '../../common'

function includesLookupString (baseString, lookupString) {
  return baseString.toLowerCase().includes(lookupString.toLowerCase())
}

export default {
  components: {
    IconWrapper,
    StInput,
    StWistiaVideo
  },
  mixins: [isDesktop, refineUrlFilter],
  props: {
    tutorials: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      tutorialLookupValue: ''
    }
  },
  computed: {
    filteredVideosBySection () {
      return this.tutorials.map(tutorial => {
        return {
          section: tutorial.section,
          videos: tutorial.videos.filter(video => includesLookupString(video.description, this.tutorialLookupValue))
        }
      })
    },
    filteredSections () {
      return this.filteredVideosBySection.filter(tutorial => tutorial.videos.length > 0)
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'tutorials')
  },
  created () {
    this.tutorialLookupValue = this.searchParam
  }
}
</script>
