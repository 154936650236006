<template>
  <div class="icon_caretdown">
    <svg
      viewBox="0 0 32 32"
    >
      <path d="M24 11.305l-7.997 11.39L8 11.305z" />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
