<template>
  <div class="icon_chevron">
    <div class="icon_chevron__svg">
      <svg
        viewBox="0 0 6.205 11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M.019.125l7.554.129.015.895-7.554-.13z"
          transform="rotate(45 .585 1.068)"
        />
        <path
          d="M-.015.894L7.538.765l.016-.895L0 0z"
          transform="rotate(-45 12.554 5.054)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
