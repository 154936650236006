<template>
  <div>
    <st-input
      v-model="description"
      class="st-specifications__section-spacing"
      :disabled="isReadOnly"
      label="Question Text"
      name="question[description]"
      placeholder="What do you think about this fitness center?"
      @blur="updateQuestion('description', activeQuestion.description)"
    />
    <div class="st-specifications__section-spacing">
      <div class="st-specifications__section-title-row">
        <p class="st-specifications__section-title">
          How would you like participants to answer?
        </p>
        <st-tooltip
          tip="Voice recording requires participants to enable their microphone and speak their answer out loud. With typed response they will be able to type out their response."
          class="st-specifications__section-icon-tooltip"
          :direction="isDesktop ? 'bottom' : 'left'"
          :max-width="isDesktop ? 411 : 225"
        >
          <icon-wrapper
            icon-name="info"
          />
        </st-tooltip>
      </div>
      <st-radio-button
        v-model="askForExplanation"
        class="st-radio-button__default-container"
        :disabled="isReadOnly"
        :options="[
          { caption: 'Voice Recording', value: 'voice_recording', iconName: 'microphone'},
          { caption: 'Typed Response', value: 'text', iconName: 'keyboard' }
        ]"
      />
    </div>
    <st-hyperlink
      :disabled="isReadOnly"
      class="st-specifications__upload-link st-specifications__upload-link--spacing"
      icon-class="st-hyperlink__default-icon-spacing"
      icon-name="upload"
      text="Upload Media (optional)"
      @click="openUploader"
    />
    <draggable
      v-model="options"
      v-bind="dragOptions"
      class="st-specifications__draggable-options"
      @change="handleDrop"
    >
      <transition-group
        name="fade"
        mode="out-in"
      >
        <template v-if="options.length > 0">
          <st-upload-preview
            v-for="option in options"
            :key="`media-preview-${option.id}`"
            class="st-specifications__draggable-tile"
            :disabled="isReadOnly"
            :filename="fileNameFromUrl(option.mediaUrl)"
            :hide-bar="true"
            :src="option.mediaUrl"
            @remove="deleteOption(option.id)"
          />
        </template>
      </transition-group>
    </draggable>
    <st-media-uploader
      v-if="isUploadOpen"
      accept=".jpg, .jpeg, .png, .mp4"
      :additional-props="additionalProps"
      :folders="folders"
      modal-title="Upload Media or Select From Library"
      :multi-upload="true"
      prop-name="question_option[media]"
      :skip-cropper="true"
      url="/question_options"
      @closeMediaUploader="isUploadOpen = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import {
  IconWrapper,
  StHyperlink,
  StInput,
  StMediaUploader,
  StRadioButton,
  StTooltip,
  StUploadPreview
} from '../../../common'
import updateQuestion, { actionSetter, mutationSetter } from '../../../../mixins/question/updateQuestion.vue'
import { fileName, updateQuestionOption, isDesktop } from '../../../../mixins'

export default {
  components: {
    draggable,
    IconWrapper,
    StHyperlink,
    StInput,
    StMediaUploader,
    StRadioButton,
    StTooltip,
    StUploadPreview
  },
  mixins: [fileName, isDesktop, updateQuestion, updateQuestionOption],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: function () {
    return {
      acceptedFileTypes: '.jpg, .jpeg, .png, .mp4',
      isUploadOpen: false
    }
  },
  computed: {
    ...actionSetter(['askForExplanation']),
    ...mutationSetter(['description', 'options']),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        forceFallback: true
      }
    },
    highestOptionPriority () {
      const l = this.activeQuestion.options.length
      return l === 0 ? 0 : this.activeQuestion.options[l - 1].priority
    }
  },
  methods: {
    ...mapMutations(['toggleFullSpinner']),
    ...mapMutations('specifications', ['mergeActiveQuestion', 'replaceActiveQuestion']),
    formatDate (date) {
      let dateMoment = moment(date)
      console.log(dateMoment)
      return dateMoment.format('MM/DD/YYYY')
    },
    handleDrop () {
      let updatedOptions = this.options.map((option, index) => {
        if (option.priority !== (index + 1)) {
          this.updateQuestionOption(option.id, index, { priority: (index + 1) }, true)
        }
        return { ...option, priority: (index + 1) }
      })
      this.mutateQuestion('options', updatedOptions)
    },
    openUploader () {
      if (this.isReadOnly) { return }
      this.isUploadOpen = true
    },
    deleteOption (id) {
      this.toggleFullSpinner(true)
      this.$axios({
        url: `/question_options/${id}`,
        method: 'delete'
      })
        .then(res => {
          const idx = this.activeQuestion.options.findIndex(option => option.id === id)
          const newOptions = [...this.activeQuestion.options]
          newOptions.splice(idx, 1)
          this.mergeActiveQuestion(res.data.question)
          this.toggleFullSpinner(false)
        })
        .catch(() => { this.toggleFullSpinner(false) })
    },
    additionalProps () {
      let i = this.highestOptionPriority
      return {
        'question_option[question_id]': this.activeQuestion.id,
        'question_option[priority]': () => { i++; return i }
      }
    },
    uploadComplete (payload) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        url: `/questions/${this.activeQuestion.id}`,
        method: 'get'
      })
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          this.replaceActiveQuestion(res.data)
        })
        .catch(err => {
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          console.log('bad', err)
        })
    }
  }
}
</script>
