var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'space-between st-emoji-scale': true,
    'space-around': _vm.max === 3,
  }},_vm._l((_vm.scale),function(emoji,index){return _c('div',{key:index,class:{
      'st-emoji-scale__emoji-and-label': true,
      'st-emoji-scale__emoji-and-label--active': _vm.value === index + 1,
    },on:{"click":function($event){return _vm.$emit('input', index + 1)}}},[_c('icon-wrapper',{class:{
        'st-emoji-scale__emoji': true,
        'st-emoji-scale__emoji--active': _vm.value === index + 1,
      },attrs:{"icon-name":emoji,"actionable":false,"spacetrics-blue":_vm.value === index + 1}}),_vm._v(" "),_c('p',{class:{
        'st-emoji-scale__label': true,
        'st-emoji-scale__label--active': _vm.value === index + 1,
      }},[_vm._v("\n      "+_vm._s(_vm.getEmojiLabel(emoji))+"\n    ")])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }