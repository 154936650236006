var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-vt-microphone': true,
    'st-vt-microphone--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
  }},[_c('div',{class:{
      'st-vt-microphone__button-container': true,
      'st-vt-microphone__button-container--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView,
      'st-vt-microphone__button-container--disabled': _vm.skipCreateAudioStream
    },on:{"click":_vm.toggleMute}},[_c('div',{class:{
        'st-vt-microphone__button': true,
        'st-vt-microphone__button--muted': _vm.muted,
        'st-vt-microphone__button--specs-preview': _vm.isSpecificationsPreview,
        'st-vt-microphone__button--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
      }},[_c('icon-wrapper',{attrs:{"icon-name":"microphone","invert":_vm.muted}})],1),_vm._v(" "),_c('div',{ref:"audioVisualizer",class:{
        'st-vt-microphone__visualizer': true,
        'st-vt-microphone__visualizer--specs-preview': _vm.isSpecificationsPreview,
        'st-vt-microphone__visualizer--phone-demo-preview': _vm.isDemoPreview && _vm.mobileDemoView
      },style:(_vm.visualizerStyle)})]),_vm._v(" "),_c('p',{class:{
      'st-vt-microphone__caption': true,
      'st-vt-microphone__caption--specs-preview': _vm.isSpecificationsPreview
    }},[_vm._v("\n    "+_vm._s(_vm.muteText)+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }