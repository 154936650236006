<template>
  <div class="st-participant-invite-page">
    <div
      v-if="survey.pageImageUrl"
      class="edit-cover-photo__photo-and-button st-participant-invite-page__cover-photo"
      @focus="coverPhotoHovered = true"
      @focusout="coverPhotoHovered = false"
      @mouseleave="coverPhotoHovered = false"
      @mouseover="coverPhotoHovered = true"
    >
      <div
        :class="{
          'edit-cover-photo__photo': true,
          'edit-cover-photo__default': !survey.pageImageUrl,
          'edit-cover-photo__photo--hovered': coverPhotoHovered
        }"
        :style="surveyCoverPhotoStyle"
      />
    </div>
    <div class="bx--grid st-participant-invite-page__grid">
      <div class="bx--row st-participant-invite-page__row">
        <div
          :class="{
            'st-participant-invite-page__information-and-button': true,
            'st-participant-invite-page__information-and-button--no-cover-photo': !survey.pageImageUrl
          }"
        >
          <div
            key="company-logo"
            class="edit-company-logo__company-logo edit-company-logo--participant-invite-page"
            :style="companyLogoStyle"
          />
          <h1 class="st-participant-invite-page__header">
            {{ survey.headline || 'Welcome!' }}
          </h1>
          <p class="st-participant-invite-page__instructions">
            {{ survey.greeting || 'Thanks for being part of this amazing experience. You are about to take a survey, please provide us your email first.' }}
          </p>
          <template v-if="isWdm && participant.email">
            <div class="st-participant-invite-page__set-button-full-width">
              <st-button
                class="st-participant-invite-page__continue-button"
                caption="Continue"
                icon-name="arrow"
                :underline="false"
                @click="handleWdmContinue"
              />
            </div>
          </template>
          <template v-else>
            <st-tooltip
              class="st-participant__invalid-email-tooltip"
              :always-visible="showEmailError"
              arrow-class="st-participant__invalid-email-tooltip-arrow"
              direction="bottom"
              :is-active="showEmailError"
              :max-width="isDesktop ? 411 : 270"
              message-class="st-participant__invalid-email-tooltip-message"
              tip="Make sure your email has a @ and it ends in a valid email server"
            >
              <st-input
                v-model.trim="email"
                :label="inputLabel"
                :variant="inputVariants"
                @blur="handleInputFocusOut"
                @focus="handleInputFocus"
                @input="checkIfInputIsValid"
              />
            </st-tooltip>
            <p
              v-if="survey.anonymous"
              class="st-participant-invite-page__anon-text"
            >
              This survey is anonymous, so your identity won't be associated with your responses.
            </p>
            <div class="st-participant-invite-page__set-button-full-width">
              <st-button
                v-if="showContinueButton"
                class="st-participant-invite-page__continue-button"
                caption="Continue"
                :disabled="!isEmailValid"
                icon-name="arrow"
                :underline="false"
                :variant="['secondary']"
                @click="handleContinue"
              />
            </div>
          </template>
          <p class="st-participant__terms-text">
            By clicking continue you accept our <a
              class="st-participant__term-link"
              :href="`/terms`"
              target="_blank"
            >
              Terms.
            </a>
          </p>
        </div>
        <div
          v-if="showPoweredBy"
          :class="{
            'st-powered-by-spacetrics st-powered-by-spacetrics--participant-invite': true,
            'st-powered-by-spacetrics--participant-invite-preview': invitationPreview || responsePreview,
          }"
        >
          Powered by
          <icon-wrapper
            class="st-powered-by-spacetrics__icon"
            :actionable="false"
            icon-name="spacetrics"
          />
          spacetrics
        </div>
      </div>
    </div>
    <unsupported-browser
      v-if="survey.hasVoiceRecording"
    />
  </div>
</template>

<script>
import { isValidEmail } from '../../../utilities/validation_utilities'
import { sendNameEmail } from '../../../utilities/fullstory'
import { companyLogoStyle, isDesktop } from '../../../mixins'
import { IconWrapper, StButton, StInput, StTooltip } from '../../common'
import UnsupportedBrowser from '../../participant/unsupported-browser'

export default {
  components: {
    IconWrapper,
    StButton,
    StInput,
    StTooltip,
    UnsupportedBrowser
  },
  mixins: [companyLogoStyle, isDesktop],
  props: {
    invitationPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    participant: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    responsePreview: {
      type: Boolean,
      required: false,
      default: false
    },
    survey: {
      type: Object,
      required: true
    },
    isWdm: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      coverPhotoHovered: false,
      email: this.participant.email,
      showEmailError: false,
      showPoweredBy: true
    }
  },
  computed: {
    companyLogo () {
      return this.survey.logoUrl
    },
    isEmailValid () {
      return isValidEmail(this.email)
    },
    inputLabel () {
      return this.showEmailError
        ? 'Email is not valid'
        : 'Enter your email'
    },
    inputVariants () {
      return this.showEmailError
        ? ['fullBorder', 'largeFieldLabel', 'persistLabelAbove', 'invalid']
        : ['fullBorder', 'largeFieldLabel', 'persistLabelAbove']
    },
    showContinueButton () {
      if (this.isDesktop) { return true }
      if (!this.isDesktop && this.email) { return true }
      return false
    },
    surveyCoverPhotoStyle () {
      return this.survey.pageImageUrl ? { 'background-image': `url('${this.survey.pageImageUrl}')` } : {}
    }
  },
  mounted () {
    if (this.invitationPreview) { return }
    this.$store.commit('hideSidebar', true)
    sendNameEmail(this.participant.fullName, this.email)
  },
  methods: {
    checkIfInputIsValid () {
      if (this.showEmailError && this.isEmailValid) { this.showEmailError = false }
    },
    handleInputFocus () {
      if (!this.isDesktop) { this.showPoweredBy = false }
    },
    handleInputFocusOut () {
      this.showEmailError = !this.isEmailValid
      if (this.email === '') { this.showEmailError = false }
      if (!this.isDesktop) { this.showPoweredBy = true }
    },
    handleContinue () {
      if (!this.invitationPreview && !this.responsePreview) {
        let url = `/surveys/${this.survey.id}/response`
        this.$axios.request({
          method: 'post',
          url: url,
          data: { participant: { email: this.email }, survey: this.survey }
        })
          .then(res => {
            if (res.data.success) {
              if (this.isWdm) {
                this.handleWdmContinue()
              } else {
                window.location.href = `/surveys/${this.survey.id}?participant_token=${res.data.participant_token}`
              }
            }
          }).catch(err => { console.log(err) })
      } else if (this.responsePreview) {
        if (this.isWdm) {
          this.handleWdmContinue()
        } else {
          window.location.href = `/surveys/${this.survey.id}/segments/preview`
        }
      } else {
        this.$emit('closeModal', false)
      }
    },
    handleWdmContinue () {
      if (this.invitationPreview) { return }

      // go to first question
      let url = `/questions/${this.survey.firstQuestionId}`
      if (this.responsePreview) {
        url += '/preview'
      }
      window.location.href = url
    }
  }
}
</script>
