<template>
  <div class="icon_participants">
    <div class="icon_participants__svg">
      <svg
        viewBox="0 0 16 15.997"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-247.999 -101.5)">
          <path
            d="M6.5 3A3.5 3.5 0 113-.5 3.5 3.5 0 016.5 3zm-1 0A2.5 2.5 0 103 5.5 2.5 2.5 0 005.5 3z"
            transform="translate(253.171 102)"
          />
          <path
            d="M2.629 3.99A5.076 5.076 0 017.683.026a5.076 5.076 0 015.054 3.964c.151.721.1.72.1 5.984H2.525c0-5.356-.035-5.317.104-5.984zm.871.21c-.12.574-.083.623-.083 4.83h8.528V5A4.121 4.121 0 007.683.974 4.173 4.173 0 003.5 4.2z"
            transform="translate(248.5 107.523)"
          />
          <path
            d="M.3 5.2a2.091 2.091 0 001.47-.63A2.092 2.092 0 00.3 1V0a3.15 3.15 0 013.1 3.1A3.15 3.15 0 01.3 6.2z"
            transform="translate(260.044 102.003)"
          />
          <path
            d="M3.15 8.5V4.1C3.15 1.858 1.85.259.574.2L.5-.8c2.307.05 3.655 2.577 3.655 4.9v4.4z"
            transform="translate(259.844 108.026)"
          />
          <path
            d="M2.9 6.2a3.1 3.1 0 010-6.2v1A2.145 2.145 0 00.8 3.1a2.145 2.145 0 002.1 2.1z"
            transform="translate(248.755 102.003)"
          />
          <path
            d="M0 4.1C0 1.774 1.346-.753 3.652-.8v1C2.01.238 1 2.281 1 4.1v4.4H0z"
            transform="translate(248.002 108.026)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
