import moment from 'moment'

const templateProxyHandler = {
  get (obj, prop) {
    let val = obj[prop]
    if (prop === 'updatedAt') {
      val = moment(val)
    } else if (prop === 'createdAt') {
      val = moment(val)
    }
    return val
  }
}

export function Template (template) {
  return new Proxy(template, templateProxyHandler)
}
