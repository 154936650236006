<template>
  <div>
    <div class="bx--grid mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div
        v-if="!hideElements"
        class="mobile-header__button"
      >
        <st-button
          caption="Create Template"
          icon-name="pointer"
          :show-action="true"
          url="/templates/new"
        />
      </div>
    </div>
    <div class="bx--grid">
      <div class="bx--row">
        <div
          :class="{
            'bx--col-sm-10 bx--col-xs-12': !hideElements,
            'bx--offset-sm-1 bx--col-sm-9 bx--col-xs-12': hideElements
          }"
        >
          <div>
            <st-hyperlink
              v-if="backLink"
              class="back-hyperlink-spacing--extra-space"
              :back-link="true"
              text="Back"
              :url="`/surveys/${survey.id}/choose_template`"
            />
            <h1
              :class="{
                'page-header': true,
                'st-select-template__header-spacing': backLink
              }"
            >
              {{ title }}
            </h1>
            <div
              v-if="currentUser.isSpacetricsAccount && !previewLink"
              class="st-select-template__spacetrics-message"
            >
              You are logged into the Spacetrics Account, any Templates here will show up as Default Templates for every other account.
            </div>
            <div
              class="lookup-and-dropdown"
            >
              <st-input
                v-model="templateLookupValue"
                class="st-input--default lookup-and-dropdown__space-input"
                :show-icon="true"
                :show-label="false"
                label="Template Lookup"
                label-in-field-class="st-input--hide-mobile-label"
                @blur="updateSearchParam"
              />
              <st-dropdown-menu
                caption="Templates Filter"
                :initial-active-option="activeTemplateFilterOption"
                :options="Object.values(templateFilterValues)"
                @updateOption="setDropdownOption"
              />
            </div>
            <div
              class="surveys"
            >
              <st-card
                v-for="(template) in activePageTemplates"
                :key="template.id"
                tabindex="0"
                class="template-library-card__position-contents"
                :url="templateCardUrl(template)"
                @click.native.prevent="handleTemplateSelect(template)"
              >
                <template
                  v-slot:card-content="slotProps"
                >
                  <div
                    class="template-library-card__title-and-author"
                  >
                    <div
                      class="template-library-card__position-title"
                    >
                      <icon-wrapper
                        class="template-library-card__title-icon"
                        icon-name="survey-template"
                      />
                      <p
                        :class="{
                          'template-library-card__title': true,
                          'template-library-card__title--hover': slotProps.hover
                        }"
                      >
                        {{ template.name }} Template
                      </p>
                    </div>
                    <p class="template-library-card__description">
                      {{ template.description }}
                    </p>
                    <div class="template-library-card__position-created-by">
                      <icon-wrapper
                        v-if="template.spacetricsOwned"
                        class="template-library-card__spacetrics-icon"
                        :icon-name="'spacetrics'"
                      />
                      <div
                        v-else-if="!isEmpty(template.user)"
                        class="template-library-card__avatar"
                        :style="{
                          background: `url('${template.user.avatarUrl}') center no-repeat`,
                          backgroundSize: 'cover'
                        }"
                      />
                      <p
                        v-if="template.spacetricsOwned"
                        class="template-library-card__author"
                      >
                        Created by Spacetrics
                      </p>
                      <p
                        v-else-if="!isEmpty(template.user)"
                        class="template-library-card__author"
                      >
                        Created by {{ createdByName(template.user) }}
                      </p>
                    </div>
                  </div>
                  <div class="project-and-survey-cards__position-statistic project-and-survey-cards__position-statistic--question-or-survey-count">
                    <p
                      :class="{
                        'project-and-survey-cards__statistic-number': true,
                        'project-and-survey-cards__statistic-number--hover': slotProps.hover
                      }"
                    >
                      {{ template.questionCount }}
                    </p>
                    <p
                      :class="{
                        'project-and-survey-cards__statistic-title': true,
                        'project-and-survey-cards__statistic-title--hover': slotProps.hover
                      }"
                    >
                      {{ template.questionCount === 1 ? 'Question' : 'Questions' }}
                    </p>
                  </div>
                  <st-overflow-menu
                    v-if="!hideElements"
                    :class="{
                      'project-and-survey-cards__overflow-menu-hover': slotProps.hover
                    }"
                    :title="`${template.name} Template`"
                    icon-name="survey-template"
                  >
                    <st-overflow-menu-item
                      v-if="template.canEdit"
                      text="Delete"
                      icon-name="trash"
                      @mouseup="deleteTemplate($event, template)"
                    />
                    <st-overflow-menu-item
                      v-if="template.questionCount>0"
                      text="Duplicate"
                      icon-name="duplicate"
                      @mouseup="duplicateTemplate($event, template)"
                    />
                    <st-overflow-menu-item
                      v-if="template.canEdit"
                      text="Edit"
                      icon-name="edit"
                      @mouseup="editTemplate($event, template)"
                    />
                  </st-overflow-menu>
                  <transition
                    v-if="previewLink"
                    name="fade"
                  >
                    <st-hyperlink
                      v-if="slotProps.hover"
                      class="st-select-template__preview-link"
                      text="Preview"
                      icon-name="view-password"
                      icon-class="st-hyperlink__default-icon-spacing"
                      @click.native.stop="goToPreview(template.id)"
                    />
                  </transition>
                </template>
              </st-card>
            </div>
            <st-pagination
              :number-of-items="filteredTemplatesBySearch.length"
              @updateActiveIndices="updateIndicesOfTemplatesShown"
            />
          </div>
        </div>
        <div
          v-if="!hideElements"
          class="bx--col-sm-2 desktop-page-right-action-button"
        >
          <st-button
            ref="createSurveyButton"
            caption="Create Template"
            :show-action="true"
            icon-name="pointer"
            url="/templates/new"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'

import { Template } from '../../../structures/template'
import { advanceToUrl, refineUrlFilter } from '../../../mixins'
import {
  IconWrapper,
  StButton,
  StCard,
  StDropdownMenu,
  StHyperlink,
  StInput,
  StOverflowMenu,
  StOverflowMenuItem,
  StPagination
} from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StCard,
    StDropdownMenu,
    StHyperlink,
    StInput,
    StOverflowMenu,
    StOverflowMenuItem,
    StPagination
  },
  mixins: [advanceToUrl, refineUrlFilter],
  props: {
    backLink: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultTemplates: {
      type: Array,
      required: true
    },
    hideElements: {
      type: Boolean,
      required: false,
      default: false
    },
    previewLink: {
      type: Boolean,
      required: false,
      default: false
    },
    setActivePage: {
      type: Boolean,
      required: false,
      default: true
    },
    survey: {
      type: Object,
      required: false,
      default: () => ({})
    },
    templates: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'Templates'
    }
  },
  data: function () {
    return {
      activeIndices: [0, 0],
      activeTemplateFilterOption: '',
      allTemplates: [],
      displayPreviewModal: false,
      navOpen: false,
      previewModalIndex: 0,
      templateLookupValue: ''
    }
  },
  computed: {
    ...mapState(['currentUser']),
    baseUrl () { return window.location.origin },
    activePreviewTemplate () {
      return this.activePageTemplates[this.previewModalIndex]
    },
    spacetricsTemplates () {
      return this.allTemplates.filter(template => template.spacetricsOwned)
    },
    teamTemplates () {
      return this.allTemplates.filter(template => !template.spacetricsOwned)
    },
    myTemplates () {
      return this.teamTemplates.filter(template => template.userId === this.currentUser.id)
    },
    spacetricsTemplatesCaption () {
      return `Spacetrics' Templates (${this.spacetricsTemplates.length})`
    },
    allTemplatesCaption () {
      return `All Templates (${this.allPreviewableOrEditableTemplates.length})`
    },
    myTemplatesCaption () {
      return `My Templates (${this.myTemplates.length})`
    },
    teamTemplatesCaption () {
      return `Team's Templates (${this.teamTemplates.length})`
    },
    templateFilterValues () {
      return {
        spacetricsTemplates: this.spacetricsTemplatesCaption,
        allTemplates: this.allTemplatesCaption,
        myTemplates: this.myTemplatesCaption,
        teamTemplates: this.teamTemplatesCaption,
        newestFirst: 'Newest First',
        oldestFirst: 'Oldest First'
      }
    },
    filteredTemplatesByDropdown () {
      let templates = []
      switch (this.getTemplateFilterKey(this.activeTemplateFilterOption)) {
        case 'myTemplates':
          templates = this.myTemplates
          break
        case 'newestFirst':
          templates = this.allTemplates.slice().sort((a, b) => b.createdAt - a.createdAt)
          break
        case 'oldestFirst':
          templates = this.allTemplates.slice().sort((a, b) => a.createdAt - b.createdAt)
          break
        case 'spacetricsTemplates':
          templates = this.spacetricsTemplates
          break
        case 'teamTemplates':
          templates = this.teamTemplates
          break
        default:
          templates = this.allTemplates
      }
      return templates
    },
    filteredTemplatesBySearch () {
      return this.filteredTemplatesByDropdown.filter(template => {
        return template.questionCount > 0 || template.canEdit
      }).filter(template => {
        return template.name.toLowerCase().includes(this.templateLookupValue.toLowerCase())
      })
    },
    activePageTemplates () {
      return this.filteredTemplatesBySearch.slice(...this.activeIndices)
    },
    allPreviewableOrEditableTemplates () {
      return this.allTemplates.filter(template => {
        return template.questionCount > 0 || template.canEdit
      })
    }
  },
  mounted () {
    // not computed yet when initially setting data
    this.$nextTick(() => {
      this.allTemplates = this.currentUser.isSpacetricsAccount ? this.templates.map(Template) : this.defaultTemplates.concat(this.templates).map(Template)
    })

    // set correct highlighting in the sidebar
    if (this.setActivePage) { this.$store.commit('setActivePage', 'templates') }
  },
  created () {
    this.defaultFilter = 'allTemplates'
    this.activeTemplateFilterOption = this.templateFilterValues[this.filterParam]
    this.templateLookupValue = this.searchParam
  },
  methods: {
    isEmpty,
    deleteTemplate (ev, template) {
      if (ev) { return }

      this.$store.commit('toggleFullSpinner', true)
      let url = `/templates/${template.id}`
      this.$axios.request({
        url: url,
        method: 'delete'
      })
        .then(res => {
          const idx = this.allTemplates.findIndex(t => t.id === template.id)
          this.allTemplates.splice(idx, 1)
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(err => { this.$store.commit('toggleFullSpinner', false); console.log(err) })
    },
    duplicateTemplate (ev, template) {
      if (ev) { return }

      this.$store.commit('toggleFullSpinner', true)
      let url = `/templates/${template.id}/duplicate_template`
      this.$axios.request({
        method: 'post',
        url: url
      })
        .then(res => {
          this.$store.commit('toggleFullSpinner', false)
          const idx = this.allTemplates.findIndex(t => t.id === template.id)
          this.allTemplates.splice(idx, 0, res.data)
        })
        .catch(err => { this.$store.commit('toggleFullSpinner', false); console.log(err) })
    },
    editTemplate (ev, template) {
      if (ev) { return }
      this.advanceToUrl(`/templates/${template.id}/edit`)
    },
    handleTemplateSelect (template) {
      this.hideElements ? this.selectTemplate(template) : this.advanceToUrl(this.templateCardUrl(template))
      this.displayPreviewModal = false
    },
    goToPreview (templateId) {
      this.advanceToUrl(`/templates/${templateId}/preview?survey_id=${this.survey.id}&choose_template=true`)
    },
    selectTemplate (template) {
      let url = `/surveys/${this.survey.id}/use_template`

      this.$axios.request({
        method: 'post',
        url: url,
        data: { survey: { template_id: template.id } }
      })
        .then(res => { window.location = `/surveys/${this.survey.id}/edit/content` })
        .catch(err => { console.log(err) })
    },
    getTemplateFilterKey (payload) {
      return Object.keys(this.templateFilterValues).find(key => this.templateFilterValues[key] === payload)
    },
    setDropdownOption (payload) {
      this.activeTemplateFilterOption = payload
      this.updateUrlParams('filter', this.getTemplateFilterKey(payload))
    },
    updateIndicesOfTemplatesShown (payload) {
      this.activeIndices = payload
    },
    createdByName (user) {
      let name = user.email
      if (user.fullName) { name = user.fullName }
      return name
    },
    templateCardUrl (template) {
      if (template.questionCount === 0 && template.canEdit === true) {
        return `/templates/${template.id}/edit`
      } else {
        return `/templates/${template.id}/preview`
      }
    }
  }
}
</script>
