<template>
  <div class="icon_test">
    <div class="icon_test__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h6v1H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(10 2)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(5 1)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(5 3)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M0 0h1v14H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h1v14H0z"
          transform="translate(15 2)"
        />
        <path
          d="M0 0h2v2H0z"
          transform="translate(7)"
        />
        <path
          d="M-23146.3-302.45l1.723 1.635 4.2-5.984.82.574-4.863 6.934-2.562-2.433z"
          transform="translate(23151.383 312.244)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
