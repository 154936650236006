<template>
  <div class="icon_faq">
    <div class="icon_faq__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-21003.975-9292.724l-1.037.124c-.348-3.487 3.861-3.6 5.229-2.221a2.981 2.981 0 01.232 3.659c-.77.992-2.137 1.6-2.127 3l-1.047.008c-.014-1.946 1.658-2.752 2.344-3.637a1.958 1.958 0 00-.15-2.3 2.593 2.593 0 00-3.129.042 1.774 1.774 0 00-.315 1.325z"
          transform="translate(21008.031 9297.643)"
        />
        <path
          d="M1 .5V1H0V0h1z"
          transform="translate(5.334 11)"
        />
        <path
          d="M-23227.035-129.994l.008 14.059h-7.057l-1.939 1.941-1.287-1.941h-1.713v-14.059zm-8.834 14.428l1.377-1.374h6.469l-.008-12.05h-10v12.05h1.25z"
          transform="translate(23239.027 129.995)"
        />
        <path
          d="M-23227.471-129.995h4.625v9.923h-1.738l-1.365 2.077-1.893-1.948.754-.678.98 1.008.955-1.452h1.273v-7.937h-3.592z"
          transform="translate(23238.846 129.995)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
