<template>
  <div class="icon_search">
    <div class="icon_search__svg">
      <svg
        viewBox="0 0 16.178 16.163"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.659 3.968l-.635.667-6.386-5.381a.43.43 0 01-.038-.633.5.5 0 01.672-.035z"
          transform="translate(10.52 11.527)"
        />
        <path d="M12 6a6 6 0 11-6-6 6 6 0 016 6zm-1 0a5 5 0 10-5 5 5 5 0 005-5z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
