<template>
  <div class="st-financial-accordion__main-canvas">
    <p class="st-request-accordion__description">
      Annual household income
    </p>
    <div class="st-empaneling-age__slider-wrapper">
      <st-highlighted-slider
        v-model="recipientsFinance"
        :axis-labels="['$0', '$50K', '$100K', '$150K', '$200K+']"
        :max="200"
        :max-tooltip="financialHigh ? `$${financialHigh}K` : ''"
        :min="0"
        :min-tooltip="financialLow ? `$${financialLow}K` : ''"
        :variant="['dual', 'highlighted-track']"
        @mouseup="saveFinancial"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { StHighlightedSlider } from '../../../../common'

export default {
  components: {
    StHighlightedSlider
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('requestAudience', ['financialHigh', 'financialLow']),
    recipientsFinance: {
      get () {
        return [this.financialLow, this.financialHigh]
      },
      set (newAge) {
        this.setFinancial([...newAge])
      }
    }
  },
  methods: {
    ...mapMutations('requestAudience', ['setFinancial']),
    ...mapActions('requestAudience', ['updateFinancial']),
    saveFinancial () {
      this.updateFinancial([this.financialLow, this.financialHigh])
    }
  }
}
</script>
