<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('requestAudience', ['genderSelectedIndexes']),
    ...mapGetters('requestAudience', ['GENDERS']),
    demographics () {
      return [
        {
          component: 'location',
          display: this.locationFormatDisplay,
          reset: () => { this.updateLocation({ locationCity: '', locationState: '' }) }
        },
        {
          component: 'gender',
          display: this.$store.getters['requestAudience/genderFormatDisplay'],
          reset: () => { this.resetGender() }
        },
        {
          component: 'age',
          display: this.ageFormatDisplay,
          reset: () => { this.updateAge([null, null]) }
        },
        {
          component: 'education',
          display: this.$store.getters['requestAudience/educationFormatDisplay'],
          reset: () => { this.updateAudienceRequest({ education: null }) }
        },
        {
          component: 'financial',
          display: this.financialFormatDisplay,
          reset: () => { this.updateFinancial([null, null]) }
        },
        {
          component: 'relationship',
          display: this.$store.getters['requestAudience/relationshipFormatDisplay'],
          reset: () => { this.updateAudienceRequest({ relationship: null }) }
        },
        {
          component: 'work',
          display: this.$store.getters['requestAudience/workFormatDisplay'],
          reset: () => { this.updateAudienceRequest({ work: null }) }
        }
      ]
    }
  },
  methods: {
    ...mapActions('requestAudience', [
      'updateAge',
      'updateAudienceRequest',
      'updateEducation',
      'updateFinancial',
      'updateGender',
      'updateLocation'
    ]),
    resetGender () {
      this.GENDERS.map((gender, index) => {
        this.updateGender({ idx: index, isSelected: false })
      })
    }
  }
}
</script>
