<template>
  <div class="spatial-adjacencies__question">
    <div class="drag-container spatial-container">
      <div
        :style="style"
        class="spatial-adjacencies"
      />
      <div class="drag-container__elements">
        <ds-drag-element
          v-for="(dragElement, idx) in dragElements"
          :id="dragElement.optionId.toString()"
          :ref="dragElement.optionId"
          :key="dragElement.optionId"
          class="change-color"
          :initial-top="dragElement.top"
          :initial-left="initialLeft(idx,dragElements.length,0,maxWidth)"
          :initial-radius="25"
          :max-x="maxWidth"
          :max-y="maxY"
          @endPosition="finalPosition($event, idx)"
        >
          {{ dragElement.name }}
        </ds-drag-element>
      </div>
    </div>
    <div
      :class="{
        'drag-container__submit': true,
        'drag-container__submit-preview': isSurveyPreview,
      }"
    >
      <cv-button
        v-if="isSurveyPreview && priority > 0"
        kind="secondary"
        class="st-question__preview-back-btn"
        @click="handlePreviewBack"
      >
        <div class="st-page__btn-slot">
          <div class="st-svg__left-chevron" />
          <div>Back</div>
        </div>
      </cv-button>
      <cv-button
        v-if="submit"
        class="next-btn"
        @click="submitQuestionAnswer"
      >
        <div>
          Next
          <div class="btn-icon" />
        </div>
      </cv-button>
    </div>
  </div>
</template>

<script>
import DragElement from '../../common/drag-element'
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import previousResponse from '../../../mixins/previousResponse.vue'
import loadDragElementResponses from '../../../mixins/loadDragElementResponses'
import submitQuestionAnswer from '../../../mixins/question/submitQuestionAnswer.vue'
import initialLeft from '../../../mixins/initialLeft.vue'
import handlePreviewBack from '../../../mixins/handlePreviewBack.vue'

export default {
  components: {
    'ds-drag-element': DragElement,
    'cv-button': CvButton
  },
  mixins: [submitQuestionAnswer, initialLeft, handlePreviewBack, previousResponse, loadDragElementResponses],
  props: {
    options: {
      type: Array,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    priority: {
      type: Number,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      dragElements: this.options.map((x, idx) => {
        return {
          name: x.name,
          optionId: x.optionId,
          priority: x.priority,
          placed: false,
          top: 350,
          x: null,
          xScore: null,
          y: null,
          yScore: null
        }
      }),
      minX: 0,
      minY: 0,
      maxY: 310
    }
  },
  computed: {
    submit: function () {
      if (this.isSurveyPreview) { return true }
      let placedItems = 0
      this.dragElements.forEach(x => {
        if (x.placed) {
          placedItems++
        }
      })
      if (placedItems === this.dragElements.length) {
        return true
      } else {
        return false
      }
    },
    maxWidth: function () {
      if (this.isSurveyPreview) {
        return 290
      } else if (this.isPreview) {
        return 280
      } else if (window.innerWidth > 400) {
        return 356
      } else {
        return window.innerWidth - 20
      }
    },
    style: function () {
      return {
        background: `url("${this.imageUrl}") no-repeat`,
        backgroundSize: '100% 100%'

      }
    }
  },
  methods: {
    loadResponses () {
      if (this.questionResponses && this.questionResponses.length > 0) {
        this.$nextTick(() => {
          this.loadDragElementResponses(this.maxWidth, this.maxY)
        })
      }
    },
    answerResponse () {
      let answer = { options: {} }
      this.dragElements.forEach(x => {
        answer.options[x.optionId] = [x.xScore, x.yScore]
      })
      return answer
    },
    finalPosition (data, idx) {
      this.dragElements[idx].x = data.x
      this.dragElements[idx].y = data.y
      this.dragElements[idx].yScore = 1 - this.axisScore(data.y, this.minY, this.maxY)
      this.dragElements[idx].xScore = this.axisScore(data.x, this.minX, this.maxWidth)
      this.dragElements[idx].placed = data.placed
    },
    axisScore (val, min, max) {
      if (val < min) {
        return 0
      } else if (val > max) {
        return 1
      } else {
        let distFromMin = val - min
        let totalDistance = max - min
        let score = distFromMin / totalDistance
        return score
      }
    }
  }
}
</script>
