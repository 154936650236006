<template>
  <div class="icon_location">
    <div class="icon_location__svg">
      <svg
        viewBox="0 0 14 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 7a7 7 0 0114 0c0 3.866-7 9-7 9s-7-5.134-7-9zm2.757-4.243A5.978 5.978 0 001 7c0 2.18 3.033 5.243 5.2 7.082l.8.655C8.968 13.12 13 9.566 13 7A6.009 6.009 0 002.757 2.757z" />
        <path
          d="M6 3a3 3 0 11-3-3 3 3 0 013 3zM5 3a2 2 0 10-2 2 2 2 0 002-2z"
          transform="translate(4 4)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
