<template>
  <div class="icon_expandcontractmenu">
    <div class="icon_expandcontractmenu__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-19540.324 1506.123l-8.242 8.067 8.246 7.933.748-.7-7.523-7.236 7.529-7.364z"
          transform="translate(19555.566 -1506.123)"
        />
        <path
          d="M-19540.324 1506.123l-8.242 8.067 8.246 7.933.748-.7-7.523-7.236 7.529-7.364z"
          transform="translate(19548.566 -1506.123)"
        />
        <path d="M1 0a1 1 0 11-1 1 1 1 0 011-1z" />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(0 7)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(0 14)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(7)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(7 7)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(7 14)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(14)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(14 7)"
        />
        <path
          d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
          transform="translate(14 14)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
