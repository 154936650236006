<template>
	<drop
		class="option-parent-row"
		@drop="onDrop"
	>
		<slot />
		<div
			v-for="(quadrant, index) in quadrants"
			:key="index"
			class="option"
			:style="{
				borderStyle: `${view === 'question' ? 'dashed' : 'solid'}`
			}"
		>
			<div class="placeholder-value" :style="{ backgroundColor: view === 'question' ? '#ecf0f3' : '#ffffff' }">
				<span>{{ quadrant.yLabel }} - </span> 
				<span>{{ quadrant.xLabel }}</span> 
			</div>
			<div class="option-value">
				<span>{{ quadrant.number }}</span>
			</div>
		</div>
	</drop>
</template>

<script>
import { Drop } from 'vue-easy-dnd';

export default {
	components: {
		Drop
	},
	props: {
		onDrop: {
			type: Function,
			required: false,
			default: () => {}
		},
		minXLabel: {
			type: String,
			required: true,
			default: "B"
		},
		maxXLabel: {
			type: String,
			required: true,
			default: "C"
		},
		minYLabel: {
			type: String,
			required: true,
			default: "D"
		},
		maxYLabel: {
			type: String,
			required: true,
			default: "A"
		},
		view: {
			type: String,
			required: false,
			default: "question"
		}
	},
	computed: {
		quadrants: function() {
			return (
				[
					{
						number: 1,
						xLabel: this.minXLabel || "B",
						yLabel: this.maxYLabel || "A"
					},
					{
						number: 2,
						xLabel: this.maxXLabel || "C",
						yLabel: this.maxYLabel || "A"
					},
					{
						number: 3,
						xLabel: this.minXLabel || "B",
						yLabel: this.minYLabel || "D"
					},
					{
						number: 4,
						xLabel: this.maxXLabel || "C",
						yLabel: this.minYLabel || "D"
					},
				]
			)
		}
	}
}
</script>

<style>
.option-parent-row{
	width: 100%;
	height: 100%;
  display: grid;
  grid-template-columns: auto auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.option{
  margin:5px;
  position:relative;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px gray;
}
.placeholder-value{
  position: absolute;
  background: #ecf0f3;
	top: -1px;
	left: -1px;
  padding:1px;

}
.placeholder-value span{
  font-size: 10px;
  color: #999999;
  
}
.option-value span{
  font-size: 34px;
  color: #999999;
}
</style>