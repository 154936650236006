<template>
  <div
    class="st-email-input__container"
  >
    <div class="st-email-input">
      <label
        v-if="!emptyAndUnfocused"
        for="newEmail"
        :class="{
          'st-email-input__emails-label--focus': true,
          'st-email-input__emails-label--error':
            errorMessage && !isEmailValid(newMemberEmail)
        }"
      >
        {{ label }}
      </label>
      <div
        :class="[{ 'st-email-input__emails': true,
                   'st-email-input__emails--empty': emptyAndUnfocused,
                   'st-email-input__emails--disabled': disabled,
                   'st-email-input__emails--error':
                     errorMessage && !isEmailValid(newMemberEmail)},
                 emailAreaClass]"
        @click="focusTextarea"
      >
        <textarea
          id="newEmail"
          ref="emailInput"
          class="st-email-input__email-input"
          cols="1"
          :disabled="disabled"
          :style="textareaStyle"
          :value="newMemberEmail"
          rows="1"
          wrap="off"
          @focus="hasFocus = true"
          @input="handleInput"
          @keyup="handleKeyUp"
        />
        <div
          v-if="emptyAndUnfocused && !disabled"
          class="st-email-input__emails-label--empty"
        >
          {{ recipientText }} Emails
        </div>
        <div
          v-for="(member, n) in newEmailList.slice().reverse()"
          :key="'email-' + n"
          :class="{
            'st-email-input__email-tag': true,
            'st-email-input__email-tag--error': member.error,
          }"
        >
          <div>{{ member.email }}</div>
          <div
            :class="{
              'st-email-input__email-delete': true,
              'st-email-input__email-delete--disabled': disabled
            }"
            @click="removeEmail(newEmailList.length - n - 1)"
          >
            <icon-wrapper
              icon-name="plus"
              class="st-email-input__email-delete-icon"
            />
          </div>
        </div>
        <div
          v-for="(member, n) in nonRemovableList.slice().reverse()"
          :key="'non-removable-email-' + n"
          :class="{
            'st-email-input__email-tag': true,
            'st-email-input__email-tag--cannot-delete': !member.new
          }"
        >
          <div>{{ member.email }}</div>
        </div>
      </div>
      <div
        v-if="errorMessage"
        class="st-email-input__error-message"
      >
        {{ errorMessage }}
      </div>
      <div
        v-if="newMemberEmail.length > 0 && hasFocus"
        class="st-email-input__autocomplete-box"
      >
        <div
          v-if="!withoutAutocomplete"
          class="st-email-input__autocomplete-matching-members"
        >
          <div
            v-for="(member, idx) in matchingMembersArray"
            :key="'matching-member-' + member.id"
          >
            <div
              :class="{
                'st-email-input__user-match': true,
                'st-email-input__user-match--only-autocomplete': onlyAutocomplete && idx == matchingMembersArray.length - 1
              }"
              @click="selectExistingMember(idx)"
            >
              <div class="st-email-input__match-avatar-and-name">
                <participant-avatar
                  class="st-email-input__match-avatar"
                  :participant="member"
                />
                <div class="st-email-input__match-name">
                  {{ member.fullName }}
                </div>
              </div>
              <div class="st-email-input__match-email">
                {{ member.email }}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'st-email-input__autocomplete-invite-box': true,
            'st-email-input__autocomplete-line': !withoutAutocomplete && !onlyAutocomplete
          }"
        >
          <a
            v-if="!onlyAutocomplete"
            class="st-email-input__autocomplete-invite"
            @click="tryAddNewEmail(newMemberEmail)"
          >
            <icon-wrapper
              icon-name="plus"
              class="st-email-input__invite-new-member-icon"
            />
            <template v-if="afterEmailText">
              {{ addEmailWord }} ‘{{ newMemberEmail }}’ {{ afterEmailText }}
            </template>
            <template v-else>
              {{ addEmailWord }} ‘{{ newMemberEmail }}’ to this {{ inviteText }}
            </template>
          </a>
          <a
            v-else-if="onlyAutocomplete && matchingMembersArray.length === 0"
            class="st-email-input__no-matches-found"
          >
            No matches found
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isValidEmail } from '../../utilities/validation_utilities'
import ParticipantAvatar from '../pages/insights/participant-avatar'
import IconWrapper from './icon-wrapper'

export default {
  components: {
    ParticipantAvatar,
    'icon-wrapper': IconWrapper
  },
  props: {
    /** Blocks the email input from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Change the styling of the area that contains the emails
       */
    emailAreaClass: {
      type: String,
      required: false,
      default: ''
    },
    existingMembersList: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
       * Describes what the newMemberEmail will be added to
       */
    inviteText: {
      type: String,
      required: false,
      default: 'project'
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * A list of values that do not have delete buttons in their tags (ex: participants already added to a publshed survey)
       */
    nonRemovableList: {
      type: Array,
      required: false,
      default: () => []
    },
    onlyAutocomplete: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Describes what kind of email the newMemberEmail is
       */
    recipientText: {
      type: String,
      required: false,
      default: "Member's"
    },
    /**
     * invite word
     */
    addEmailWord: {
      type: String,
      required: false,
      default: 'Invite'
    },
    /**
     * replace all text after email
     */
    afterEmailText: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * A list of values that can be removed (ex: participants already added to a non published survey)
       */
    removableList: {
      type: Array,
      required: false,
      default: () => []
    },
    withoutAutocomplete: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      newMemberEmail: '',
      newEmailList: this.removableList,
      errorMessage: '',
      hasFocus: false
    }
  },
  computed: {
    textareaWidth () {
      return this.newMemberEmail.length * 9 + 14
    },
    textareaStyle () {
      return {
        width: this.textareaWidth + 'px'
      }
    },
    existingMemberEmails () {
      return new Set(this.existingMembersList.map(member => member.email))
    },
    emptyAndUnfocused () {
      return (
        !this.hasFocus &&
        this.newEmailList.length === 0 &&
        this.nonRemovableList.length === 0 &&
        this.newMemberEmail.length === 0
      )
    },
    matchingMembersArray () {
      return this.findPotentialMatches(this.newMemberEmail)
    },
    newMembersExistingAccount () {
      return this.newEmailList.filter(member => {
        return member.id
      })
    },
    newMembersExistingIds () {
      return new Set(this.newMembersExistingAccount.map(member => member.id))
    },
    newMembersNotInAccount () {
      return this.newEmailList.filter(member => {
        return member.new
      })
    },
    newMembersErrors () {
      return this.newEmailList.filter(member => {
        return member.error
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.focusTextarea()
    })
    setTimeout(() => {
      if (!this.disabled) { this.hasFocus = true }
    }, 50)
  },
  methods: {
    resetTextArea () {
      this.newMemberEmail = ''
    },
    focusTextarea () {
      this.$refs.emailInput.focus()
    },
    loseFocus () {
      setTimeout(() => {
        this.hasFocus = false
      }, 1000) // nextTick won't work for some reason??, selectExistingMember() won't fire
    },
    handleInput (ev) {
      this.newMemberEmail = ev.target.value
      this.errorMessage = ''
      if (ev.inputType === 'insertFromPaste') {
        this.handleCopyPasteEmails()
      }
    },
    handleKeyUp (ev) {
      this.hasFocus = true
      let key = ev.key
      if (key === 'Enter' || key === ' ' || key === ',') {
        if (this.onlyAutocomplete) {
          this.newMemberEmail = this.newMemberEmail.replace(/[\n, ]/g, '')
        } else {
          this.newMemberEmail = this.newMemberEmail.slice(0, -1)
          this.tryAddNewEmail(this.newMemberEmail)
        }
      }
    },
    findPotentialMatches (memberToMatch) {
      const regex = new RegExp(memberToMatch, 'i')
      return this.existingMembersList.filter(member => {
        return regex.test(member.fullName) || regex.test(member.email)
      })
    },
    // intended for clicking popup
    selectExistingMember (matchingMemberIdx) {
      this.addExistingMember(this.matchingMembersArray[matchingMemberIdx])
      this.$nextTick(() => {
        this.resetTextArea()
        this.hasFocus = true
      })
    },
    removeEmail (idx) {
      if (this.disabled) { return }
      if (this.newEmailList[idx].id) {
        this.$emit('uncheckEmail', this.newEmailList[idx])
      }
      this.$emit('removeEmail', this.newEmailList[idx])
      this.$nextTick(() => {
        this.newEmailList.splice(idx, 1)
        this.errorMessage = ''
      })
    },
    handleCopyPasteEmails () {
      let emailsArray = this.newMemberEmail
        .split(',')
        .map(email => email.trim())

      emailsArray.forEach(this.tryAddNewEmail)
      this.resetTextArea()
    },
    tryAddNewEmail (email) {
      event.stopPropagation()

      if (email.length === 0) { return }

      if (this.isEmailExistingUser(email)) {
        this.addExistingMember(this.existingUserfromEmail(email))
        this.$nextTick(() => {
          this.resetTextArea()
          this.hasFocus = true
        })
      } else if (this.isEmailValid(email)) {
        this.addNewMember(email)
        this.$nextTick(() => {
          this.resetTextArea()
          this.hasFocus = true
        })
      } else {
        this.handleEmailError(email)
      }
    },
    addEmailFromDataTable (email) {
      if (this.isEmailExistingUser(email)) {
        this.newEmailList.push(this.existingUserfromEmail(email))
      }
    },
    addExistingMember (member) {
      this.$emit('addNewEmail', member)
      if (this.newEmailList.indexOf(member) === -1) { this.newEmailList.push(member) }
    },
    addNewMember (email) {
      let newMember = {
        new: true,
        email: email
      }
      this.newEmailList.push(newMember)
      this.$emit('addNewEmail', newMember)
    },
    handleEmailError (email) {
      let errorMember = {
        error: true,
        email: email
      }
      this.newEmailList.push(errorMember)
      this.errorMessage = '`' + email + '` is not  a valid email'
      this.hasFocus = false
      this.$nextTick(this.resetTextArea)
    },
    addMemberUnlessPresent (member) {
      // check to see if they are already in list
      if (!this.newMembersExistingIds.has(member.id)) {
        this.addExistingMember(member)
      }
    },
    toggleAllExisting (addAll) {
      if (addAll) {
        this.existingMembersList.forEach(this.addMemberUnlessPresent)
      } else {
        // remove all existing
        this.newEmailList = this.newEmailList.filter(member => !member.id)
      }
    },
    isEmailValid (email) {
      return isValidEmail(email)
    },
    isEmailExistingUser (email) {
      return this.existingMemberEmails.has(email)
    },
    existingUserfromEmail (email) {
      return this.existingMembersList.find(member => member.email === email)
    }
  }
}
</script>
