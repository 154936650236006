<template>
  <div
    class="st-pagination"
  >
    <div
      class="st-pagination__container--items-information"
    >
      <div>
        Items per page:
      </div>
      <div
        class="st-pagination__container--options-dropdown"
      >
        <div
          :class="{
            'st-pagination__container--chosen-option': true,
            'st-pagination__container--disabled': !numberOfItems,
            'st-pagination__remove-focus': removeFocus,
            'st-pagination__container--chosen-option__open': itemsDropdownOpen
          }"
          tabindex="0"
          @click.stop="toggleItemsOptionsDropdown"
          @focusout="removeFocus = false"
          @keyup.enter.stop="toggleItemsOptionsDropdown"
          @mousedown="removeFocus = true"
        >
          <input
            :value="activeItemsPerPageOption"
            type="button"
            :class="{
              'st-pagination__container--per-option-number': true,
              'st-pagination__container--per-option-number-disabled': !numberOfItems
            }"
          >
          <div
            class="st-pagination__container--icon-caret"
          >
            <icon-wrapper
              :icon-name="checkItemsIconName"
            />
          </div>
        </div>
        <div
          v-if="itemsDropdownOpen"
          ref="itemsDropdownOptions"
          class="st-pagination__container--options"
        >
          <input
            v-for="(option, index) in inactiveItemsPerPageOptions"
            :key="option"
            class="st-pagination__options"
            type="button"
            tabindex="0"
            :value="option"
            @click="updateItemsOption(option, index)"
            @keyup.enter="updateItemsOption(option, index)"
          >
        </div>
      </div>
      <div>
        {{ paginationDisplayValues.from }} - {{ paginationDisplayValues.to }} of {{ paginationDisplayValues.total }}
      </div>
    </div>

    <div
      class="st-pagination__container--page-information"
    >
      <div
        v-if="activePageOption > 1"
        :class="{
          'st-pagination__container--icon-previous': true,
          'st-pagination__remove-focus': removeFocus,
        }"
        tabindex="0"
        @click="previousPageOption"
        @focusout="removeFocus = false"
        @keyup.enter="previousPageOption"
        @mousedown="removeFocus = true"
      >
        <icon-wrapper
          class="st-pagination__icon--chevron"
          icon-name="chevron"
        />
      </div>
      <div>
        Page
      </div>
      <div
        class="st-pagination__container--options-dropdown"
      >
        <div
          :class="{
            'st-pagination__container--chosen-option': true,
            'st-pagination__container--disabled': !numberOfItems,
            'st-pagination__remove-focus': removeFocus,
            'st-pagination__container--chosen-option__open': pageDropdownOpen
          }"
          tabindex="0"
          @click.stop="togglePageOptionsDropdown"
          @focusout="removeFocus = false"
          @keyup.enter.stop="togglePageOptionsDropdown"
          @mousedown="removeFocus = true"
        >
          <input
            :value="activePageOption"
            type="button"
            :class="{
              'st-pagination__container--per-option-number': true,
              'st-pagination__container--per-option-number-disabled': !numberOfItems
            }"
          >
          <div
            class="st-pagination__container--icon-caret"
          >
            <icon-wrapper
              :icon-name="checkPagesIconName"
            />
          </div>
        </div>
        <div
          v-if="pageDropdownOpen"
          ref="pageDropdownOptions"
          class="st-pagination__container--options"
        >
          <input
            v-for="(option, index) in inactivePageOptions"
            :key="option"
            class="st-pagination__options"
            type="button"
            tabindex="0"
            :value="option"
            @click="updatePageOption(option, index)"
            @keyup.enter="updatePageOption(option, index)"
          >
        </div>
      </div>
      <div>
        of {{ lastPage }}
      </div>
      <div
        :class="{
          'st-pagination__container--icon-next': true,
          'st-pagination__remove-focus': removeFocus,
          'st-pagination__container--icon-next__disabled': endOfPageOptions || !numberOfItems
        }"
        tabindex="0"
        @click="nextPageOption"
        @focusout="removeFocus = false"
        @keyup.enter="nextPageOption"
        @mousedown="removeFocus = true"
      >
        <icon-wrapper
          :class="{
            'st-pagination__icon--chevron': true,
            'st-pagination__icon--chevron__disabled': endOfPageOptions
          }"
          icon-name="chevron"
        />
      </div>
    </div>
  </div>
</template>

<script>
import closeElement from '../../mixins/closeElement'
import IconWrapper from '../common/icon-wrapper'
import refineUrlFilter from '../../mixins/refineUrlFilter'

const ALL = 'All'

function closestArrayValue (arr, searchVal) {
  return arr.reduce((prev, curr) => Math.abs(curr - searchVal) < Math.abs(prev - searchVal) ? curr : prev)
}

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [closeElement, refineUrlFilter],
  props: {
    /**
       * Starting amount of items value.
       */
    initialActiveItemsPerPageOption: {
      type: Number,
      required: false,
      default: 20
    },
    /**
       * Starting page value.
       */
    initialActivePageOption: {
      type: Number,
      required: false,
      default: 1
    },
    /**
       * All available options for items, including the active item option
       */
    numberPerPageOptions: {
      type: Array,
      default: () => [5, 10, 20, 50, 100],
      required: false
    },
    /**
       * Total number of items
       */
    numberOfItems: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      activeItemsPerPageOptionIndex: Math.max(this.numberPerPageOptions.indexOf(this.initialActiveItemsPerPageOption), 0),
      itemsDropdownOpen: false,
      pageDropdownOpen: false,
      pageOption: this.initialActivePageOption,
      removeFocus: false
    }
  },
  computed: {
    activeItemsPerPageOption () {
      return this.numberOfItems ? this.itemsPerPageOptions[this.activeItemsPerPageOptionIndex] : 0
    },
    activeItemsPerPageNumericOption () {
      return this.activeItemsPerPageOption === ALL ? this.numberOfItems : this.activeItemsPerPageOption
    },
    activeIndices () {
      // paginationDisplayValue is one-indexed, activeIndicies is zero-indexed for slicing
      return [this.paginationDisplayValues.from - 1, this.paginationDisplayValues.to]
    },
    activePageIndex () {
      return this.pageOptions.indexOf(this.pageOption)
    },
    activePageOption () {
      if (this.numberOfItems === 0) { return 0 }
      if (this.activePageIndex !== -1) { return this.pageOptions[this.activePageIndex] }

      return closestArrayValue(this.pageOptions, this.pageOption)
    },
    checkItemsIconName () {
      return this.itemsDropdownOpen ? 'caret-up' : 'caret-down'
    },
    checkPagesIconName () {
      return this.pageDropdownOpen ? 'caret-up' : 'caret-down'
    },
    endOfPageOptions () {
      return this.pageOption === this.pageOptions.length
    },
    inactiveItemsPerPageOptions () {
      return this.itemsPerPageOptions.filter((_val, idx) => idx !== this.activeItemsPerPageOptionIndex)
    },
    inactivePageOptions () {
      return this.pageOptions.filter((_val, idx) => idx !== this.activePageIndex)
    },
    lastPage () {
      return this.numberOfItems ? Math.ceil(this.numberOfItems / this.activeItemsPerPageNumericOption) : 0
    },
    itemsPerPageOptions () {
      return this.numberPerPageOptions.concat(ALL)
    },
    pageOptions () {
      return Array.from({ length: this.lastPage }, (a, b) => b + 1)
    },
    paginationDisplayValues () {
      let to = Math.min(this.activePageOption * this.activeItemsPerPageNumericOption, this.numberOfItems)
      let from = Math.min(((this.activePageOption - 1) * this.activeItemsPerPageNumericOption) + 1, this.numberOfItems)
      return {
        total: this.numberOfItems,
        from: from,
        to: to
      }
    }
  },
  watch: {
    activeIndices () {
      this.$emit('updateActiveIndices', this.activeIndices)
    }
  },
  created () {
    this.pageOption = this.pageParam || this.initialActivePageOption
    this.activeItemsPerPageOptionIndex = this.itemsPerPageOptions.indexOf(Number(this.itemsParam || this.initialActiveItemsPerPageOption))
  },
  mounted () {
    this.$emit('updateActiveIndices', this.activeIndices)
  },
  methods: {
    closeFn () {
      this.itemsDropdownOpen = false
      this.pageDropdownOpen = false
    },
    nextPageOption () {
      if (!this.endOfPageOptions && this.numberOfItems) {
        this.pageOption++
        this.updateUrlParams('page', this.pageOption)
      }
    },
    previousPageOption () {
      this.pageOption--
      this.updateUrlParams('page', this.pageOption)
    },
    enterFn (event) {
      if (event.keyCode === 13) { this.closeFn() }
    },
    toggleItemsOptionsDropdown () {
      if (this.numberOfItems) {
        this.itemsDropdownOpen = !this.itemsDropdownOpen
        this.pageDropdownOpen = false

        if (this.itemsDropdownOpen) {
          this.$nextTick(() => {
            this.$refs.itemsDropdownOptions.lastElementChild.scrollIntoView({
              block: 'center',
              behavior: 'smooth'
            })
          })
        }
      }
    },
    togglePageOptionsDropdown () {
      if (this.numberOfItems) {
        this.pageDropdownOpen = !this.pageDropdownOpen
        this.itemsDropdownOpen = false

        if (this.pageDropdownOpen) {
          this.$nextTick(() => {
            this.$refs.pageDropdownOptions.lastElementChild.scrollIntoView({
              block: 'center',
              behavior: 'smooth'
            })
          })
        }
      }
    },
    updateItemsOption (option, index) {
      this.activeItemsPerPageOptionIndex = this.itemsPerPageOptions.indexOf(option)
      this.itemsDropdownOpen = false
      this.pageOption = 1
      this.$emit('updateActiveIndices', this.activeIndices)
      this.updateUrlParams('page', 1)
      this.updateUrlParams('items', option)
    },
    updatePageOption (option, index) {
      this.pageDropdownOpen = false
      this.pageOption = option
      this.updateUrlParams('page', option)
    }
  }
}
</script>
