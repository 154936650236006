<template>
  <div
    :ref="src"
    class="lazy-image"
  >
    <div
      v-if="!loaded"
      :style="{ background }"
      class="lazy-image__placeholder"
    >
      <img
        :src="placeholder || src"
        :style="{width: width+'px', height: height+'px'}"
        alt=""
        v-bind="$attrs"
      >
    </div>
    <img
      :class="[{'lazy-image--loaded': loaded}, customClass && customClass]"
      :src="dataUrl"
      :alt="$attrs.alt || ''"
      v-bind="$attrs"
      class="lazy-image__img"
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    src: {
      type: String,
      required: true
    },
    placeholder: String,
    background: String,
    customClass: String
  },
  data: function () {
    return {
      width: null,
      height: null,
      loaded: false
    }
  },
  computed: {
    dataUrl () {
      const { width, height } = this.$attrs
      if (!width || !height) return ''

      // create a tiny png with matching aspect ratio as img
      const w = 100
      const canvas = document.createElement('canvas')
      canvas.width = w
      canvas.height = (height / width) * w

      return canvas.toDataURL()
    }
  },

  mounted () {
    const { src, srcset, $el } = this
    const clientWidth = this.$refs[src].clientWidth
    const clientHeight = this.$refs[src].clientHeight
    this.width = clientWidth
    this.height = clientHeight
    let timeOut

    const observer = new IntersectionObserver(([entry]) => {
      const img = $el.querySelector(`.lazy-image__img`)
      const placeholder = $el.querySelector(`.lazy-image__placeholder`)
      img.onload = () => {
        delete img.onload
        $el.classList.add(`lazy-image--loaded`)
        this.loaded = true
        if (placeholder) {
          timeOut = setTimeout(() => {
            placeholder.remove()
          }, 300)
        }
      }
      if (entry.isIntersecting) {
        // Element is in viewport
        if (srcset) {
          img.srcset = srcset
        }
        img.src = src
        observer.disconnect()
      }
    })
    observer.observe($el)

    this.$once('hook:beforeDestroy', () => {
      observer.disconnect()
      if (timeOut) {
        clearTimeout(timeOut)
      }
    })
  }
}
</script>
