<template>
  <div
    ref="tourContainer"
    class="st-virtual-tour-specs__wrapper"
  >
    <div
      v-if="showFloorPlan"
      class="st-virtual-tour-specs__zoom-buttons-wrapper"
    >
      <button
        class="st-virtual-tour-specs__zoom-button"
        tabindex="0"
        @keydown.enter="zoomFloorplanIn"
        @keyup.enter="stopZooming"
        @mousedown="zoomFloorplanIn"
        @mouseout="stopZooming"
        @mouseup="stopZooming"
      >
        <icon-wrapper
          class="st-virtual-tour-specs__zoom-button-icon"
          icon-name="plus"
          :invert="true"
        />
      </button>
      <button
        :class="{
          'st-virtual-tour-specs__zoom-button': true,
          'st-virtual-tour-specs__zoom-button--disabled': disableZoomOut
        }"
        tabindex="0"
        @keydown.enter="zoomFloorplanOut"
        @keyup.enter="stopZooming"
        @mousedown="zoomFloorplanOut"
        @mouseout="stopZooming"
        @mouseup="stopZooming"
      >
        <icon-wrapper
          class="st-virtual-tour-specs__zoom-button-icon"
          icon-name="minus"
          :actionable="!disableZoomOut"
          :invert="true"
        />
      </button>
    </div>
    <div
      class="st-virtual-tour-specs__image-wrapper"
      @mousedown="startDragSelect"
      @mousemove="handleMouseMove"
      @mouseup="endDragSelect"
    >
      <img
        v-if="showFloorPlan"
        ref="floorPlan"
        :src="floorplanSrc"
        alt="Virtual Tour Floor Plan"
        class="st-virtual-tour-specs__floorplan"
        :style="floorPlanStyle"
      >
      <matterport-iframe
        v-else
        ref="tour"
        :matterport-id="matterportId"
        :show-floorplan-icon="floorplan"
        :style="iframeStyle"
        @matterport-showcase-loaded="handleShowcaseLoad"
      />
      <template v-if="floorplan && floorplanSrc">
        <div
          class="st-virtual-tour-specs__drag-select-box"
          :style="dragSelectBoxStyle"
        />
        <div
          v-for="point in assignedViewpoints"
          v-show="point.top != null"
          :key="'pano-' + virtualTourId + point.uuid"
          class="st-virtual-tour-specs__room-dot map-dot"
          :style="{
            position: 'absolute',
            top: topCss(point.top),
            left: leftCss(point.left)
          }"
          :class="{
            'active-dot': activeIdentifiers.includes(point.uuid)
          }"
          @click="selectSweep(point.uuid)"
        />
        <div
          v-for="point in unassignedViewpoints"
          v-show="point.top != null"
          :key="'pano-' + virtualTourId + point.uuid"
          class="st-virtual-tour-specs__room-dot map-dot unassigned-dot"
          :style="{
            position: 'absolute',
            top: topCss(point.top),
            left: leftCss(point.left)
          }"
          :class="{
            'active-dot': activeIdentifiers.includes(point.uuid)
          }"
          @click="selectSweep(point.uuid)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { IconWrapper } from '../common'
import MatterportIframe from './matterport-iframe'

export default {
  components: {
    IconWrapper,
    'matterport-iframe': MatterportIframe
  },
  props: {
    assignedViewpointIdentifiers: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    activeViewpoints: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    floorplan: {
      type: Boolean,
      required: false,
      default: false
    },
    matterportId: {
      type: String,
      required: true
    },
    questionId: {
      type: Number,
      required: false,
      default: -1
    },
    saveSweeps: {
      type: Boolean,
      required: false,
      default: false
    },
    tourName: {
      type: String,
      required: false,
      default: ''
    },
    tourScreenshot: {
      type: Boolean,
      default: false,
      required: false
    },
    virtualTourId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      activeSweepIdentifier: null,
      details: null,
      dragBoxHeight: 0,
      dragBoxWidth: 0,
      dragBoxX: 0,
      dragBoxY: 0,
      dragEnabled: false,
      floorplanSrc: null,
      initialDragBoxX: 0,
      initialDragBoxY: 0,
      initialViewpoints: null,
      modelData: null,
      screenshotAttempt: 0,
      showcase: null,
      tourScreenshotSrc: null,
      viewpoints: null,
      zoomInterval: false,
      zoomLevel: 0
    }
  },
  computed: {
    assignedViewpoints () {
      return this.viewpoints.filter(pano => {
        return this.assignedViewpointIdentifiers.includes(pano.uuid)
      })
    },
    unassignedViewpoints () {
      return this.viewpoints.filter(pano => {
        return !this.assignedViewpointIdentifiers.includes(pano.uuid)
      })
    },
    activeIdentifiers () {
      return this.activeViewpoints.map(point => point.identifier)
    },
    disableZoomOut () {
      return this.zoomLevel === 0
    },
    dragSelectBoxStyle () {
      return {
        height: this.dragBoxHeight + 'px',
        left: this.dragBoxX + 'px',
        width: this.dragBoxWidth + 'px',
        top: this.dragBoxY + 'px'
      }
    },
    floorPlanStyle () {
      return {
        height: 100 + (this.zoomLevel * 10) + '%',
        width: 100 + (this.zoomLevel * 10) + '%'
      }
    },
    iframeStyle () {
      return {
        pointerEvents: this.tourScreenshot ? 'none' : 'all'
      }
    },
    showFloorPlan () {
      return this.floorplanSrc && this.floorplan
    }
  },
  mounted () {
    window.addEventListener('keydown', event => { this.handleKeyZooming() })
    window.addEventListener('keyup', event => { this.handleKeyStopZooming() })
  },
  beforeDestroy () {
    window.removeEventListener('keydown', event => { this.handleKeyZooming() })
    window.addEventListener('keyup', event => { this.handleKeyStopZooming() })
  },
  methods: {
    endDragSelect (event) {
      this.dragEnabled = false
      this.selectViewpoints()
      this.$nextTick(() => {
        this.dragBoxHeight = 0
        this.dragBoxWidth = 0
        this.dragBoxX = 0
        this.dragBoxY = 0
      })
    },
    startDragSelect (event) {
      event.preventDefault()
      this.dragEnabled = true

      this.dragBoxX = event.clientX
      this.dragBoxY = event.clientY

      this.initialDragBoxX = event.clientX
      this.initialDragBoxY = event.clientY
    },
    handleMouseMove (event) {
      if (this.dragEnabled) {
        if (event.clientY < this.initialDragBoxY) {
          this.dragBoxY = event.clientY
          this.dragBoxHeight = this.initialDragBoxY - this.dragBoxY
        } else {
          this.dragBoxHeight = event.clientY - this.dragBoxY
        }

        if (event.clientX < this.initialDragBoxX) {
          this.dragBoxX = event.clientX
          this.dragBoxWidth = this.initialDragBoxX - this.dragBoxX
        } else {
          this.dragBoxWidth = event.clientX - this.dragBoxX
        }
      }
    },
    isViewpointInSelectY (top) {
      if (this.floorplan) {
        let floorPlanTopOffset = this.$refs['floorPlan'].getBoundingClientRect().top
        let maxY = this.dragBoxY + this.dragBoxHeight
        return (this.dragBoxY - floorPlanTopOffset) <= top && top <= (maxY - floorPlanTopOffset)
      }
    },
    isViewpointInSelectX (left) {
      if (this.floorplan) {
        let floorPlanTopOffset = this.$refs['floorPlan'].getBoundingClientRect().left
        let maxX = this.dragBoxX + this.dragBoxWidth
        return (this.dragBoxX - floorPlanTopOffset) <= left && left <= (maxX - floorPlanTopOffset)
      }
    },
    selectViewpoints () {
      this.viewpoints.forEach((viewpoint, index) => {
        if (this.isViewpointInSelectY(viewpoint.top) && this.isViewpointInSelectX(viewpoint.left) && !this.activeIdentifiers.includes(viewpoint.uuid)) {
          this.selectSweep(viewpoint.uuid)
        }
      })
    },
    handleShowcaseLoad (sdk) {
      this.showcase = sdk
      sdk.on(sdk.Sweep.Event.ENTER, (x, y) => {
        this.$store.commit('specifications/setActiveViewpointIdentifier', y)
      })
      this.$emit('matterport-showcase-loaded', sdk)

      this.getData(sdk).then(() => {
        if (this.tourScreenshot) {
          this.$nextTick(() => {
            this.takeTourModeScreenshot()
            this.$nextTick(() => { this.takeFloorplanScreenshot() })
          })
        } else if (this.floorplan) {
          this.$nextTick(() => { this.takeFloorplanScreenshot() })
        }
      })
    },
    takeFloorplanScreenshot () {
      this.showcase.Mode.moveTo('mode.floorplan')
        .then((nextMode) => {
          setTimeout(() => { this.takeScreenShot() }, 500)
        })
        .catch((error) => {
          console.error('error', error)
          if (this.screenshotAttempt < 5) {
            setTimeout(() => { this.takeFloorplanScreenshot() }, 3000)
          }
          this.screenshotAttempt++
        })
    },
    takeTourModeScreenshot () {
      setTimeout(() => {
        this.showcase.Renderer.takeScreenShot({ width: 1320, height: 736 }, { mattertags: false, sweeps: false })
          .then(res => {
            this.$nextTick(() => {
              this.tourScreenshotSrc = res
              this.$emit('tourScreenshotSrc', res)
            })
          })
          .catch((error) => { console.error(error) })
      }, 3000)
    },
    getData (sdk) {
      return sdk.Model.getData().then(modelData => {
        this.getDetails(sdk)
        this.viewpoints = modelData.sweeps.map(sweep => {
          return Object.assign({ top: null, left: null }, sweep)
        })
        this.modelData = modelData
        if (this.saveSweeps) {
          this.saveViewpointsToDatabase()
        }
      }).catch(err => { console.error(err) })
    },
    getDetails (sdk) {
      sdk.Model.getDetails().then((details) => {
        this.details = details
        if (this.tourName !== details.name && this.questionId > 0) {
          this.saveNameToDatabase(details.name)
        }
      }).catch(err => { console.error(err) })
    },
    floorplanMode () {
      this.showcase.Mode.moveTo('mode.floorplan')
        .then((nextMode) => {
        }).catch((error) => { console.error(error) })
    },
    takeScreenShot () {
      this.viewpoints.forEach(sweep => {
        this.showcase.Renderer.getScreenPosition(sweep.position).then(data => {
          sweep.top = data.y
          sweep.left = data.x
          return data
        }).catch(err => { console.error(err) })
      })
      this.showcase.Renderer.takeScreenShot({ width: this.$refs.tourContainer.clientWidth, height: this.$refs.tourContainer.clientHeight }, { mattertags: false, sweeps: true })
        .then(res => {
          this.floorplanSrc = res
          if (this.assignedViewpointIdentifiers.length < this.viewpoints.length) {
            let unassignedViewpoint = this.viewpoints.find(sweep => {
              return !this.assignedViewpointIdentifiers.includes(sweep.uuid)
            })
            this.$nextTick(() => {
              this.selectSweep(unassignedViewpoint.uuid)
            })
          }
        })
        .catch((error) => { console.error(error) })
    },
    moveToSweep (identifier) {
      this.showcase.Sweep.moveTo(identifier)
        .then(res => { console.log(res) })
        .catch(err => { console.error(err) })
    },
    saveNameToDatabase (tourName) {
      let url = `/questions/${this.questionId}`
      let data = { description: tourName }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { question_id: this.questionId, question: data }
      })
        .then(res => { this.$emit('emitTourTitle', tourName) })
        .catch(err => { console.error(err) })
    },
    saveViewpointsToDatabase () {
      let viewpointIdentifiers = this.viewpoints.map(point => point.uuid)
      let url = `/virtual_tours/${this.virtualTourId}/add_panoramas`
      let data = { virtual_tour_id: this.virtualTourId, identifiers: viewpointIdentifiers }
      this.$axios.request({
        method: 'post',
        url: url,
        data: data
      })
        .then(res => { this.$emit('emitViewpoints', res.data) })
        .catch(err => { console.error(err) })
    },
    selectSweep (identifier) {
      this.$emit('selectSweep', identifier)
      this.activeSweepIdentifier = identifier

      if (!this.floorplan) { this.moveToSweep(identifier) }
    },
    topCss (top) {
      return (100 * top) / this.$refs.tourContainer.clientHeight + '%'
    },
    leftCss (left) {
      return 'calc(' + (100 * left) / this.$refs.tourContainer.clientWidth + '% - 8px)'
    },
    updateViewpoints () {
      if (!this.initialViewpoints) { this.initialViewpoints = this.viewpoints }
      this.viewpoints = this.viewpoints.map((viewpoint, index) => {
        let initialViewpoint = this.initialViewpoints[index]
        let zoomFraction = ((this.zoomLevel * 10) / 100)
        return { ...viewpoint,
          left: initialViewpoint.left + (zoomFraction * initialViewpoint.left),
          top: initialViewpoint.top + (zoomFraction * initialViewpoint.top)
        }
      })
    },
    handleKeyZooming () {
      if (event.keyCode === 187 && this.showFloorPlan) { this.zoomFloorplanIn() }
      if (event.keyCode === 189 && this.showFloorPlan) { this.zoomFloorplanOut() }
    },
    handleKeyStopZooming () {
      if (event.keyCode === 187 && this.showFloorPlan) { this.stopZooming() }
      if (event.keyCode === 189 && this.showFloorPlan) { this.stopZooming() }
    },
    stopZooming () {
      clearInterval(this.zoomInterval)
      this.zoomInterval = false
    },
    zoomFloorplanIn () {
      this.zoomIn()
      if (!this.zoomInterval) {
        this.zoomInterval = setInterval(() => { this.zoomIn() }, 50)
      }
    },
    zoomIn () {
      this.zoomLevel++
      this.updateViewpoints()
    },
    zoomFloorplanOut () {
      this.zoomOut()
      if (!this.zoomInterval && !this.disableZoomOut) {
        this.zoomInterval = setInterval(() => { this.zoomOut() }, 50)
      }
    },
    zoomOut () {
      if (this.zoomLevel === 0) {
        this.stopZooming()
        return
      }
      this.zoomLevel--
      this.updateViewpoints()
    }
  }
}
</script>
