<template>
  <div class="icon_next">
    <div class="icon_next__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h16v16H0zm15 15V1H1v14z" />
        <path
          d="M7963.05 16528.834l-3.836 3.836 3.836 3.836-.707.707-4.543-4.543 4.543-4.543z"
          transform="translate(-7952.507 -16524.48)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
