<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
  },
  props: {
    isDemoPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      isSurveyPreview: this.isPreview
    }
  },
  computed: {
    ...mapGetters('participants', ['SURVEY_TYPES']),
    isSpecificationsPreview () {
      return this.isPreview && !this.isSurveyPreview
    }
  },
  methods: {
    ...mapMutations('participants', { setSurvey: 'setSurveyType' }),
    setSurveyType () {
      let type
      if (this.isDemoPreview) {
        type = this.SURVEY_TYPES['demoPreview']
      } else if (this.isSpecificationsPreview) {
        type = this.SURVEY_TYPES['specificationsPreview']
      } else if (this.isPreview) {
        type = this.SURVEY_TYPES['surveyPreview']
      } else {
        type = this.SURVEY_TYPES['participantSurvey']
      }

      this.setSurvey(type)
    }
  }
}
</script>
