<template>
  <div class="icon_welcome">
    <div class="icon_welcome__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1018.857 961.663l-1.915-6.393a1.5 1.5 0 00-2.769-.025 1.417 1.417 0 00-.032 1.079l.168.682-2.972-2.968a1.5 1.5 0 00-2.118 0 1.52 1.52 0 00-.293.413l-.412-.412a1.489 1.489 0 00-1.059-.439 1.5 1.5 0 00-1.3 2.239 1.471 1.471 0 00-.465.316 1.5 1.5 0 000 2.116l.413.414a1.5 1.5 0 00-.413 2.408l5.659 5.653a3.484 3.484 0 004.924 0l2.127-2.126a2.484 2.484 0 00.5-2.788.656.656 0 00-.043-.169zm-1.163 2.251l-2.128 2.126a2.539 2.539 0 01-3.521-.009l-5.65-5.644a.5.5 0 01.708-.706l2.531 2.53a.5.5 0 00.707-.706l-2.532-2.529-1.413-1.411a.5.5 0 01.708-.706l3.728 3.725a.5.5 0 00.706-.705l-3.728-3.725-.706-.706a.5.5 0 01.352-.851.5.5 0 01.352.146l1.412 1.411 3.177 3.175a.5.5 0 00.707-.706l-3.178-3.175a.5.5 0 010-.705.5.5 0 01.707 0l4.238 4.235a.5.5 0 00.838-.472l-.619-2.489a.489.489 0 010-.381.5.5 0 01.273-.268.5.5 0 01.381 0 .5.5 0 01.253.228l1.918 6.418a.52.52 0 00.05.177 1.49 1.49 0 01-.271 1.723z"
          transform="translate(-1003.91 -951.99)"
        />
        <path
          d="M1016.326 954.208a1.283 1.283 0 011.053.409.893.893 0 01.294.865.5.5 0 00.386.59.528.528 0 00.1.011.5.5 0 00.489-.4 1.87 1.87 0 00-.587-1.793 2.237 2.237 0 00-1.833-.679.5.5 0 10.094.994z"
          transform="translate(-1003.901 -951.991)"
        />
        <path
          d="M1019.005 952.614a2.569 2.569 0 00-2-.6.5.5 0 10.136.988 1.631 1.631 0 011.249.4 1.14 1.14 0 01.506.96.5.5 0 00.474.523h.024a.5.5 0 00.5-.475 2.119 2.119 0 00-.889-1.796z"
          transform="translate(-1003.9 -951.99)"
        />
        <path
          d="M1007.484 965.773a1.284 1.284 0 01-1.053-.409.9.9 0 01-.3-.866.5.5 0 00-.977-.2 1.87 1.87 0 00.587 1.794 2.24 2.24 0 001.618.688c.071 0 .142 0 .213-.009a.5.5 0 00.45-.544.509.509 0 00-.538-.454z"
          transform="translate(-1003.911 -951.99)"
        />
        <path
          d="M1006.666 966.98a1.649 1.649 0 01-1.25-.4 1.139 1.139 0 01-.5-.961.5.5 0 00-.474-.522.485.485 0 00-.522.473 2.118 2.118 0 00.892 1.8 2.615 2.615 0 001.67.622 2.342 2.342 0 00.324-.022.5.5 0 00.427-.561.5.5 0 00-.567-.429z"
          transform="translate(-1003.912 -951.99)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
