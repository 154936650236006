import Axios from 'axios'

export function getToken () {
  const tokenTag = document.querySelector('meta[name=csrf-token]')
  return tokenTag === null ? '' : tokenTag.content
}

export function getCSRFParam () {
  const paramTag = document.querySelector('meta[name=csrf-param]')
  return paramTag === null ? '' : paramTag.content
}

export function buildDefaultHeader (token = null) {
  if (token === null) {
    token = getToken()
  }

  return {
    'X-CSRF-Token': token,
    'Accept': 'application/json'
  }
}

export function axiosInstance (defaultHeader = null) {
  if (defaultHeader === null) {
    defaultHeader = buildDefaultHeader()
  }
  return Axios.create({
    baseURL: '/api',
    headers: defaultHeader
  })
}
