<template>
  <div class="icon_createfolder">
    <div class="icon_createfolder__svg">
      <svg
        viewBox="0 0 16.001 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1V0h6.413l2.569 2.991H0zm5.937 0H1v1h5.78z"
        />
        <path
          d="M0 0h1v12H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(15 2)"
        />
        <path
          d="M9 4.5A4.5 4.5 0 114.5 0 4.5 4.5 0 019 4.5zm-1 0A3.5 3.5 0 104.5 8 3.5 3.5 0 008 4.5z"
          transform="translate(7.001 7)"
        />
        <path
          d="M.5 0v4h-1V0z"
          transform="translate(11.501 9.5)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(9.501 11.5)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(.001 2)"
        />
        <path
          d="M0 0h5v1H0z"
          transform="translate(.001 13)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
