<template>
  <div
    :class="{
      'st-concept-ranking__content': true,
      'st-concept-ranking__content--specs': isSpecificationsPreview
    }"
  >
    <div
      ref="questionContainer"
      :class="{
        'st-participant__question-container': true,
        'st-participant__question-container--no-media': hasNoMedia
      }"
    >
      <p
        ref="question"
        class="st-participant__question-container-para"
      >
        {{ description }} 
        
      </p>
    </div>
    <st-question-media
      :class="{
        'st-concept-ranking__question-media': true,
        'st-concept-ranking__question-media--specs': isSpecificationsPreview
      }"
      :is-desktop-survey="isDesktopSurvey"
      :is-desktop-survey-preview="isDesktopSurveyPreview"
      :is-specifications-preview="isSpecificationsPreview"
      :media="options"
      :question-id="questionId"
    />
    <div
      :class="{
        'st-concept-ranking__scales-and-response': true,
        'st-concept-ranking__scales-and-response--multi-media-desktop-preview': multiMediaSurveyPreview,
        'st-concept-ranking__scales-and-response--multi-media-desktop': hasMultipleMedia && isDesktopSurvey

      }"
    >
      <div
        v-if="scaleLength"
        :class="{
          'st-concept-ranking__scale-spacing': !isSpecificationsPreview,
          'st-concept-ranking__scales': scaleValue,
          'st-concept-ranking__scales--specs': isSpecificationsPreview,
        }"
      >
        <st-emoji-scale
          v-if="isEmojiScale"
          v-model="scaleValue"
          :max="scaleLength"
        />
        <st-numeric-scale
          v-else-if="isNumericScale"
          v-model="scaleValue"
          :max="scaleLength"
        />
        <st-slider
          v-else-if="isAgreeDisagreeScale"
          v-model="scaleValue"
          :labels="sliderLabels"
          :max="scaleLength"
        />
        <st-star-scale
          v-else-if="isStarsScale"
          v-model="scaleValue"
          :max="scaleLength"
        />
      </div>
      <st-tooltip
        v-if="hasResponse && isResponseTypeNone"
        class="st-concept-ranking__button-tooltip"
        tip="Preview only button"
        :is-active="isSpecificationsPreview"
      >
        <st-button
          class="st-concept-ranking__button"
          :caption="buttonCaption"
          icon-name="arrow"
          :underline="false"
          :variant="['secondary']"
          @click="submitQuestionAnswer"
        />
      </st-tooltip>
      <div
        v-if="hasResponse"
        :class="{
          'st-concept-ranking__response-area': isSpecificationsPreview,
        }"
      >
        <st-voice-response
          v-if="isResponseTypeVoice"
          :ask-for-explanation="askForExplanation"
          :audio-blob="audioBlob"
          :is-preview="isPreview"
          :is-survey-preview="isSurveyPreview"
          @update-audio-blob="updateAudioBlob"
        />
        <div v-else-if="isResponseTypeText">
          <st-text-area
            v-model="writtenResponse"
            :class="{
              'st-participant-elements__text-area': true,
              'st-participant-elements__text-area--large': textAreaFocused
            }"
            :button="textAreaFocused"
            label="Share your thoughts here"
            placeholder="Tell us what you think"
            @focus="focusTextArea"
            @focusout="focusTextArea"
          />
          <st-tooltip
            v-if="showTextResponseNextButton"
            class="st-concept-ranking__button-tooltip"
            tip="Preview only button"
            :is-active="isSpecificationsPreview"
          >
            <st-button
              class="st-concept-ranking__button"
              :caption="buttonCaption"
              :disabled="!writtenResponse.length"
              icon-name="arrow"
              :underline="false"
              :variant="['secondary']"
              @click="submitQuestionAnswer"
            />
          </st-tooltip>
        </div>
        <div v-else>
          <st-button
            class="st-concept-ranking__button"
            :caption="buttonCaption"
            :underline="false"
            icon-name="arrow"
            :variant="['secondary']"
            @click="submitQuestionAnswer"
          />
        </div>
      </div>
      <div
        v-else
        :class="{
          'st-concept-ranking__response-area': isSpecificationsPreview,
        }"
      >
        <st-button
          :disabled="true"
          class="st-concept-ranking__button"
          :caption="buttonCaption"
          :underline="false"
          icon-name="arrow"
          :variant="['secondary']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { handlePreviewBack, isDesktop, previousResponse, responsiveQuestionText, submitQuestionAnswer } from '../../../../mixins'
import { StButton, StEmojiScale, StNumericScale, StQuestionMedia, StSlider, StStarScale, StTextArea, StTooltip, StVoiceResponse } from '../../../common'

export default {
  components: {
    StButton,
    StEmojiScale,
    StNumericScale,
    StQuestionMedia,
    StSlider,
    StStarScale,
    StTextArea,
    StTooltip,
    StVoiceResponse
  },
  mixins: [handlePreviewBack, isDesktop, previousResponse, responsiveQuestionText, submitQuestionAnswer],
  props: {
    askForExplanation: {
      type: String,
      required: false,
      default: ''
    },
    autoStopTime: {
      type: Number,
      default: 900000, // ms = 15 min
      required: false
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    media: {
      type: Object,
      required: false,
      default: () => ({})
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    priority: {
      type: Number,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    scaleLength: {
      type: Number,
      required: false,
      default: 0
    },
    scaleStyle: {
      type: String,
      required: false,
      default: ''
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      audioBlob: null,
      scaleValue: null,
      textAreaFocused: false,
      writtenResponse: ''
    }
  },
  computed: {
    hasMultipleMedia () {
      return this.options.length > 1
    },
    hasNoMedia () {
      return this.options.length === 0
    },
    hasResponse () {
      return this.scaleValue > 0
    },
    hasResponseType () {
      return this.askForExplanation
    },
    isEmojiScale () {
      return this.scaleStyle === 'emoji'
    },
    isNumericScale () {
      return this.scaleStyle === 'numeric'
    },
    isAgreeDisagreeScale () {
      return this.scaleStyle === 'agree_disagree'
    },
    isStarsScale () {
      return this.scaleStyle === 'stars'
    },
    isResponseTypeNone () {
      return this.askForExplanation === 'no_explanation'
    },
    isResponseTypeText () {
      return this.askForExplanation === 'text'
    },
    isResponseTypeVoice () {
      return this.askForExplanation === 'voice_recording'
    },
    isSpecificationsPreview () {
      return this.isPreview && this.isDesktop && !this.isSurveyPreview
    },
    isDesktopSurvey () {
      return this.isDesktop && !this.isPreview
    },
    isDesktopSurveyPreview () {
      return this.isDesktop && this.isSurveyPreview
    },
    multiMediaSurveyPreview () {
      return this.hasMultipleMedia && this.isDesktopSurveyPreview
    },
    showTextResponseNextButton () {
      return this.writtenResponse.length && !this.textAreaFocused
    },
    sliderLabels () {
      return this.scaleLength === 3
        ? ['Strongly Disagree, Neutral, Strongly Agree']
        : ['Strongly Disagree, Disagree, Neutral, Agree, Strongly Agree']
    }
  },
  methods: {
    answerData () {
      let answer = new FormData()
      answer.append('response[value]', this.scaleValue / Number(this.scaleLength))
      if (this.hasResponseType !== 'no_explanation') {
        answer.append(...(this.isResponseTypeText ? ['response[transcript]', this.writtenResponse] : ['response[audio_response]', this.audioBlob]))
      }
      return answer
    },
    focusTextArea () {
      this.textAreaFocused = !this.textAreaFocused
    },
    loadResponses () {
      if (!this.isResponseTypeText && this.questionResponses.length > 0) {
        this.audioBlob = this.questionResponses[0].srcUrl
      }
    },
    updateAudioBlob (payload) {
      this.audioBlob = payload
      this.$nextTick(() => { this.submitQuestionAnswer() })
    },
    updateScaleValue (value) {
      this.scaleValue = value
    }
  }
}
</script>
