<template>
  <div class="st-table-dropdown-info">
    <div
      :class="{
        'st-table-dropdown-info__btn': true,
        'st-table-dropdown-info__btn--center': centerClosedText
      }"
      @click="handleOpen"
    >
      <span
        :class="{
          'st-table-dropdown-info__btn-text': showCount,
          'st-table-dropdown-info__btn-text--no-count': showCount && infoRows.length === 1
        }"
      >{{ displayText }}</span>
      <span v-if="infoRows.length>1 && showCount"> (+{{ infoRows.length - 1 }})</span>
    </div>
    <div
      v-if="open"
      class="st-table-dropdown-info__close-screen"
      @click="open=false"
    />
    <div
      v-if="open"
      class="st-table-dropdown-info__open"
    >
      <div
        class="st-table-dropdown-info__open-title"
        @click="open=false"
      >
        <div>{{ openTitle }}</div>
        <div>&times;</div>
      </div>
      <div class="st-table-dropdown-info__open-container">
        <!-- @slot inner body of dropdown -->
        <slot>
          <div
            v-for="infoRow in infoRows"
            :key="infoRow"
            class="st-table-dropdown-info__open-info"
          >
            {{ infoRow }}
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** remove flex to center text in closed state */
    centerClosedText: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used to prevent dropdown-info from opening */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** title when dropdown is collapsed */
    displayText: {
      type: String,
      required: true
    },
    /** title when dropdown is expanded */
    openTitle: {
      type: String,
      required: true
    },
    /** array to display when opened (can be replaced by a slot) */
    infoRows: {
      type: Array,
      required: false,
      default: () => []
    },
    /** boolean to display a count of additional items */
    showCount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      open: false
    }
  },
  methods: {
    handleOpen () {
      if (!this.disabled) { this.open = true }
    }
  }
}
</script>
