<template>
  <div class="icon_chat">
    <div class="icon_chat__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 1.631A1.732 1.732 0 011.714 0h12.572A1.732 1.732 0 0116 1.631V16l-4.825-1.71H1.714A1.724 1.724 0 010 12.668zm1 0v11.037a.733.733 0 00.667.62c.79.056-2.815 0 9.68 0L15 14.555V1.631A.741.741 0 0014.326 1C13.308.938 17.994 1 1.714 1A.74.74 0 001 1.631z" />
        <path
          d="M-23285.381-68.271a6.525 6.525 0 007.229.039 6.3 6.3 0 001.035-.78l.7.711a8.044 8.044 0 01-5.277 2.144 8.688 8.688 0 01-5.412-2.132l.676-.736a7.084 7.084 0 001.049.754z"
          transform="translate(23289.766 77.657)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
