<template>
  <div class="specifications__section">
    <div class="specifications__title">
      Pick Favorite
    </div>
    <div class="st-input__row">
      <label class="st-input__label">
        Question
        <input
          :value="activeQuestion.description"
          class="st-input"
          type="text"
          name="question[description]"
          placeholder="Your question goes here"
          :disabled="isPreview"
          @blur="updateQuestion('description', $event.target.value)"
        >
      </label>
    </div>
    <div class="st-input__row">
      <cv-radio-group @change="updateOptionsCount">
        <cv-radio-button
          v-if="isPreview"
          name="options-count"
          label="2 options"
          value="2"
          :checked="checked2"
          disabled
        />
        <cv-radio-button
          v-else
          name="options-count"
          label="2 options"
          value="2"
          :checked="checked2"
        />
        <cv-radio-button
          v-if="isPreview"
          name="options-count"
          label="4 options"
          value="4"
          :checked="checked4"
          disabled
        />
        <cv-radio-button
          v-else
          name="options-count"
          label="4 options"
          value="4"
          :checked="checked4"
        />
      </cv-radio-group>
    </div>

    <div class="st-input__row st-input__row--align-start">
      <div
        v-for="(image,idx) in options"
        :key="image.id"
        class="half-width"
      >
        <div
          v-if="image.imageUrl != null"
        >
          <cv-tile class="specifications__image-tile">
            <div
              class="specifications__image"
              :style="{
                background: `url('${image.imageUrl}') center no-repeat`,
                backgroundSize: 'contain'
              }"
            />
            <p class="specifications__file-name">
              {{ fileNameFromUrl(String(image.imageUrl)) }}
            </p>
            <div
              v-if="!isPreview"
              class="st-svg__delete"
              @click="deleteQuestionOption(image.id, idx)"
            />
          </cv-tile>
          <label
            class="st-input__label"
          >
            Name this option
            <input
              :value="options[idx].name"
              class="st-input"
              type="text"
              placeholder="option name"
              :disabled="isPreview"
              @blur="updateQuestionOption(image.optionId, idx, $event.target.value)"
            >
          </label>
        </div>
        <div
          v-else
          @click="setActiveIdx(idx)"
        >
          <cv-button
            v-if="isPreview"
            kind="secondary"
            class="st-templates__preview-button"
            disabled
          >
            + Add Image
          </cv-button>
          <cv-file-uploader
            v-else
            label=" + Add Image"
            accept=".jpg,.png"
            :clear-on-reselect="true"
            :initial-state-uploading="false"
            :multiple="false"
            :removable="true"
            @input="setActiveIdx(idx)"
            @change="openCropper"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showCropperModal"
    >
      <div
        class="edit-segments__modal-screen"
        @click="showCropperModal=false"
      />
      <div class="edit-segments__modal">
        <div class="edit-segments__modal-title">
          Crop &#38; Upload
          <div
            class="edit-segments__modal-x st-svg__close-color"
            @click="showCropperModal=false"
          />
        </div>
        <div class="edit-segments__modal-scroll-area">
          <ds-cropper
            :src="cropperSrc"
            :file-name="cropperFileName"
            :aspect-ratio="aspectRatio"
            @upload="uploadOptionImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CvRadioGroup from '@carbon/vue/src/components/cv-radio-button/cv-radio-group'
import CvRadioButton from '@carbon/vue/src/components/cv-radio-button/cv-radio-button'
import CvFileUploader from '@carbon/vue/src/components/cv-file-uploader/cv-file-uploader'
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import CvTile from '@carbon/vue/src/components/cv-tile/cv-tile'
import updateQuestion from '../../../mixins/question/updateQuestion'
import updateQuestionOption from '../../../mixins/question/updateQuestionOption'
import uploadOptionImage from '../../../mixins/question/uploadOptionImage'
import fileName from '../../../mixins/fileName'
// import DsCropper from '../../tools/cropper'
import openCropper from '../../../mixins/question/openCropper'

export default {
  components: {
    'cv-radio-group': CvRadioGroup,
    'cv-radio-button': CvRadioButton,
    'cv-file-uploader': CvFileUploader,
    'cv-button': CvButton,
    'cv-tile': CvTile // ,
    // 'ds-cropper': DsCropper
  },
  mixins: [updateQuestion, updateQuestionOption, uploadOptionImage, fileName, openCropper],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      optionsCount: '2',
      activeIdx: null
    }
  },
  computed: {
    checked2: function () {
      if (this.optionsCount === '2') {
        return true
      } else {
        return false
      }
    },
    checked4: function () {
      if (this.optionsCount === '4') {
        return true
      } else {
        return false
      }
    },
    aspectRatio () {
      if (this.optionsCount === '2') {
        return 2
      } else {
        return 1
      }
    }
  },
  mounted: function () {
    this.optionsCount = this.options.length.toString()
  },
  methods: {
    updateOptionsCount (ev) {
      // console.log(ev)
      if (this.isPreview) { return }
      this.optionsCount = ev
      if (ev === '4' && this.options.length === 2) {
        // send request to add two more question options and fetch the options again
        this.createNewOptions(2)
      }
      if (ev === '2' && this.options.length === 4) {
        // send delete requests and also remove from options array
        let id3 = this.options[2].optionId
        let id4 = this.options[3].optionId
        this.deleteLastOption(id3)
        this.deleteLastOption(id4)
      }
      if (this.options.length === 0) {
        let newOptionsCount = Number(ev)
        this.createNewOptions(newOptionsCount)
      }
    },
    setActiveIdx (idx) {
      this.activeIdx = idx
    }
  }
}
</script>
