<template>
  <div>
    <div :class="{'st-opinion-metric-insights__show-multiple-open': showAllImages}">
      <div
        :class="{
          'st-opinion-metric-insights__multi-option-grid': activeQuestion.options.length > 0 || activeQuestion.externalVideoUrl
        }"
      >
        <div
          v-if="!activePanelSection && activeQuestion.options.length>0"
          class="st-opinion-metric-insights__multi-option-media-wrapper"
          style="position:relative;"
        >
          <video
            v-if="isMediaVideo(activeQuestion.options[0])"
            controls
            class="st-opinion-metric-insights__multi-option-media"
          >
            <source
              :src="activeQuestion.options[0].mediaUrl"
              :type="mediaContentType"
            >
            <source
              :src="activeQuestion.options[0].mediaUrl"
            >
            Your browser does not support this video
          </video>
          <img
            v-else
            class="st-opinion-metric-insights__multi-option-media"
            alt="Opinion Metric Media"
            :src="activeQuestion.options[0].mediaUrl"
          >
          <div
            class="st-opinion-metric-insights__multi-option-open-images"
            @click="showAllImages = true"
          >
            {{ openMediaText }}
          </div>
        </div>
        <st-external-video
          v-if="activeQuestion.externalVideoUrl"
          class="st-opinion-metric-insights__multi-option-media"
          :url="activeQuestion.externalVideoUrl"
        />
        <div
          :class="{
            'st-opinion-metric-insights-grid st-opinion-metric-insights__block': true,
            'st-opinion-metric-insights-grid__no-media': true
          }"
        >
          <div
            :class="{
              'st-opinion-metric-insights-grid__average-and-bar-graph': true,
              'st-opinion-metric-insights-grid__average-and-bar-graph--no-media': true
            }"
          >
            <div
              :class="[{'st-opinion-metric-insights__overall-sentiment-container': true}, sentimentColor]"
            >
              <p class="st-opinion-metric-insights__sentiment st-opinion-metric-insights__sentiment--title">
                Average Answer:
              </p>
              <p class="st-opinion-metric-insights__sentiment">
                {{ overallScoreBucket }}
              </p>
            </div>
            <diverging-bar-graph
              :sentiment-values="allRespondantsSentiments"
            />
          </div>
        </div>
      </div>
      <div class="st-opinion-metric-insights__block">
        <p class="st-opinion-metric-insights__block-title st-opinion-metric-insights__block-title--sentiment">
          Sentiment per Participant
        </p>
        <st-accordion
          v-for="(response) in limitSentimentPerParticipant"
          :key="response.id"
          class="st-sentiment-per-participant__accordion"
          :custom-icon="true"
          visible-icon-class="st-sentiment-per-participant__accordion--chevron"
          visible-icon-name="chevron"
          visible-row-class="st-sentiment-per-participant__accordion-visible-row"
        >
          <template
            v-slot:visible-section="slotProps"
          >
            <div
              :class="{
                'st-sentiment-per-participant__avatar-and-sentiment': true,
                'st-sentiment-per-participant__avatar-and-sentiment--hover': slotProps.hover
              }"
            >
              <div class="st-sentiment-per-participant__avatar-and-name">
                <icon-wrapper
                  v-if="slotProps.hover || slotProps.expanded"
                  :class="{
                    'st-sentiment-per-participant__chevron': true,
                    'st-sentiment-per-participant__chevron--expanded': slotProps.expanded,
                  }"
                  icon-name="chevron"
                  :neutral="!slotProps.expanded"
                />
                <participant-avatar
                  class="st-sentiment-per-participant__avatar"
                  :participant="response"
                />
                <p
                  :class="{
                    'st-sentiment-per-participant__name': true,
                    'st-sentiment-per-participant__name--expanded': slotProps.expanded,
                  }"
                >
                  {{ response.fullName || 'Anonymous User' }}
                </p>
                <icon-wrapper
                  :class="{
                    'st-sentiment-per-participant__chevron st-sentiment-per-participant__chevron--mobile': true,
                    'st-sentiment-per-participant__chevron--expanded': slotProps.expanded,
                  }"
                  icon-name="chevron"
                  :neutral="!slotProps.expanded"
                />
              </div>
              <sentiment-tag
                class="st-sentiment-per-participant__sentiment"
                :sentiment="response.sentimentScore"
              />
            </div>
          </template>
          <template slot="expanded-section">
            <p
              key="keyWordsTitle"
              class="st-sentiment-per-participant__keyword-title"
            >
              Keywords
            </p>
            <div
              key="keyWords"
              class="st-sentiment-per-participant__keywords-container"
            >
              <div
                v-for="(keyword) in orderedKeyWords(response.entitySentiments)"
                :key="keyword.id"
                class="st-sentiment-per-participant__keyword-and-sentiment"
              >
                <p class="st-sentiment-per-participant__keyword">
                  {{ keyword.name }}
                </p>
                <sentiment-tag
                  class="st-sentiment-per-participant__sentiment st-sentiment-per-participant__sentiment--keyword"
                  :sentiment="keyword.sentimentScore"
                />
              </div>
              <st-hyperlink
                class="st-sentiment-per-participant__view-full-transcript"
                contents-class="st-sentiment-per-participant__view-full-transcript--hyperlink"
                icon-name="transcript"
                icon-class="st-hyperlink__default-icon-spacing"
                text="View Full Transcript"
                @click="openFullTranscript(response)"
              />
            </div>
          </template>
        </st-accordion>
        <div
          v-if="orderedSentimentPerParticipant.length >= 7"
          class="st-survey-insights__show-more"
          @click="showAllParticipants = !showAllParticipants"
        >
          <p>
            {{ showAllParticipants ? 'See fewer participants' : 'See all participants' }}
          </p>
          <icon-wrapper
            :class="{
              'st-survey-insights__show-more-icon st-survey-insights__show-more-icon--show-all': true,
              'st-survey-insights__show-more-icon--expanded': showAllParticipants,
            }"
            icon-name="chevron"
          />
        </div>
      </div>
      <p class="st-opinion-metric-insights__block-title">
        Most Mentioned Keywords
      </p>
      <p
        v-if="entitySentimentsForTable.length === 0"
        class="st-survey-insights__empty-keywords-message"
      >
        There are currently no "Most Mentioned Keywords"
      </p>
      <sentiment-table
        v-else
        :include-count="true"
        :sentiment-rows="showFullSentimentTable"
        :total-participants="responsesPassingParticipantCheckboxFilter.length"
        :expandable="true"
        column-title="Keyword"
      >
        <grouped-table-transcripts
          slot="expanded-row"
          slot-scope="{ row }"
          expand-result-option="openTranscript"
          :total-participants="responsesPassingParticipantCheckboxFilter.length"
          :entity-name="row.title"
          :transcripts="entitySentimentsKeywords[row.title].transcripts"
          @expand-result="openFullTranscript"
        />
      </sentiment-table>
      <div
        v-if="entitySentimentsForTable.length >= 7"
        class="st-survey-insights__show-more st-survey-insights__show-more--keywords"
        @click="showAllKeywordsTable = !showAllKeywordsTable"
      >
        <p>
          {{ showAllKeywordsTable ? 'See fewer keywords' : 'See all keywords' }}
        </p>
        <icon-wrapper
          :class="{
            'st-survey-insights__show-more-icon st-survey-insights__show-more-icon--show-all': true,
            'st-survey-insights__show-more-icon--expanded': showAllKeywordsTable,
          }"
          icon-name="chevron"
        />
      </div>
    </div>
    <template v-if="showAllImages">
      <div class="st-opinion-metric-insights__images-backdrop" />
      <div
        class="st-opinion-metric-insights__images-container"
        :style="marginStyle"
      >
        <template
          v-for="media in activeQuestion.options"
        >
          <video
            v-if="isMediaVideo(media)"
            :key="media.id"
            class="st-opinion-metric-insights__image-large"
            :style="mediaStyle"
            controls
          >
            <source
              :src="media.mediaFullUrl"
              :type="media.media.contentType"
            >
            <source
              :src="media.mediaFullUrl"
            >
            Your browser does not support this video
          </video>
          <img
            v-else
            :key="media.id"
            class="st-opinion-metric-insights__image-large"
            alt="Opinion Metric Media"
            :src="media.mediaFullUrl"
            :style="mediaStyle"
          >
        </template>
      </div>
      <div
        @click="showAllImages = false"
      >
        <icon-wrapper
          icon-name="cancel"
          class="st-opinion-metric-insights__exit-lightbox"
          :invert="zoomLevel<=90"
        />
      </div>
      <div
        class="st-opinion-metric-insights__lightbox-plus-box"
        @click="zoomIn"
      >
        <icon-wrapper
          icon-name="plus"
        />
      </div>
      <div
        class="st-opinion-metric-insights__lightbox-minus-box"
        @click="zoomOut"
      >
        <icon-wrapper
          icon-name="minus"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { kebabCase } from 'lodash'
import { average, bucketIdx } from '../../../../utilities/data_utilities'
import { IconWrapper, StAccordion, StExternalVideo, StHyperlink } from '../../../common'
import { DivergingBarGraph, ParticipantAvatar, GroupedTableTranscripts } from '../'
import SentimentTable from '../sentiment-table'
import SentimentTag from '../sentiment-tag'
const bucketNames = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]
export default {
  components: {
    DivergingBarGraph,
    IconWrapper,
    ParticipantAvatar,
    GroupedTableTranscripts,
    SentimentTable,
    SentimentTag,
    StAccordion,
    StExternalVideo,
    StHyperlink
  },
  props: {
  },
  data: function () {
    return {
      showAllKeywordsTable: false,
      showAllParticipants: false,
      showAllImages: false,
      zoomLevel: 80
    }
  },
  computed: {
    ...mapState('insights', ['activePanelSection']),
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingParticipantCheckboxFilter']),
    allRespondantsSentiments () {
      return this.responsesPassingParticipantCheckboxFilter.map(response => {
        return response.sentimentScore
      })
    },
    openMediaText () {
      return `Show Media (${this.activeQuestion.options.length})`
    },
    entitySentimentsKeywords () {
      let keyWordSentiments = {}
      this.responsesPassingParticipantCheckboxFilter.forEach((response) => {
        response.entitySentiments.map(entity => {
          if (keyWordSentiments.hasOwnProperty(entity.name)) {
            keyWordSentiments[entity.name].sentiments.push(entity.sentimentScore)
            keyWordSentiments[entity.name].transcripts.push({
              bucketIdx: entity.sentimentValueIdx,
              firstName: response.firstName,
              lastName: response.lastName,
              name: response.fullName,
              surveyResponseId: response.surveyResponseId,
              responseId: response.id,
              transcript: response.transcript
            })
          } else {
            keyWordSentiments[entity.name] = {
              sentiments: [entity.sentimentScore],
              transcripts: [{
                bucketIdx: entity.sentimentValueIdx,
                firstName: response.firstName,
                lastName: response.lastName,
                name: response.fullName,
                responseId: response.id,
                surveyResponseId: response.surveyResponseId,
                transcript: response.transcript
              }]
            }
          }
        })
      })
      return keyWordSentiments
    },
    entitySentimentsForTable () {
      let sortedKeyWords = Object.entries(this.entitySentimentsKeywords).map(([title, value]) => {
        return {
          ...value,
          title,
          averageSentiment: average(value.sentiments)
        }
      })
      sortedKeyWords.sort((a, b) => b.average - a.average)
      return sortedKeyWords
    },
    limitSentimentPerParticipant () {
      return this.showAllParticipants ? this.orderedSentimentPerParticipant : this.orderedSentimentPerParticipant.slice(0, 7)
    },
    mediaContentType () {
      return this.activeQuestion.media ? this.activeQuestion.media.contentType : ''
    },
    mediaFormatCategory () {
      return this.mediaContentType.split('/')[0] || ''
    },
    orderedSentimentPerParticipant () {
      return this.responsesPassingParticipantCheckboxFilter.slice().sort((a, b) => b.sentimentScore - a.sentimentScore)
    },
    overallScore () {
      return average(this.responsesPassingParticipantCheckboxFilter, 'sentimentScore')
    },
    overallScoreBucket () {
      return bucketNames[bucketIdx(this.overallScore)]
    },
    sentimentColor () {
      return { [`st-sentiment-badge--${kebabCase(this.overallScoreBucket)}`]: true }
    },
    showFullSentimentTable () {
      return this.showAllKeywordsTable ? this.entitySentimentsForTable : this.entitySentimentsForTable.slice(0, 7)
    },
    margin () {
      return Math.max((100 - this.zoomLevel) / 2, 0)
    },
    marginStyle () {
      let paddingTop = '80px'
      if (this.zoomLevel >= 100) {
        paddingTop = 0
      } else if (this.zoomLevel === 90) {
        paddingTop = '40px'
      }
      return {
        padding: paddingTop,
        left: `${this.margin}vw`
      }
    },
    mediaStyle () {
      return {
        width: `${this.zoomLevel}vw`
      }
    }
  },
  methods: {
    isMediaVideo (option) {
      return (option.media.contentType.split('/')[0] || '') === 'video'
    },
    openFullTranscript (response) {
      this.$store.dispatch('insights/openSingleTranscript', response)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      let state = {
        mode: 'fulltranscript',
        responseId: response.id
      }
      let title = 'entered full transcript mode'
      let url = `?question=${this.activeQuestion.id}&mode=transcript&response=${response.id}`
      window.history.pushState(state, title, url)
    },
    orderedKeyWords (keywords) {
      return keywords.slice().sort((a, b) => b.sentimentScore - a.sentimentScore)
    },
    zoomIn () {
      this.zoomLevel += 10
    },
    zoomOut () {
      this.zoomLevel -= 10
    }
  }
}
</script>
