<template>
  <div class="st-welcome__combine-component">
    <div class="bx--grid">
      <div
        v-show="false"
        class="st-prospectives__calc"
      >
        <div class="st-prospectives__calc-head">
          <h5 class="st-welcome__subheading st-welcome__subheading-white">
            VALUE CALCULATION
          </h5>
          <h1 class="st-prospectives__heading st-prospectives__calc-heading">
            Spacetrics gives you unmatched ability to understand people’s
            opinions at a fraction of the cost of other methods.
          </h1>
        </div>
        <div class="bx--row st-prospectives__calc-row">
          <div class="bx--col-xs-12 bx--col-lg-5 bx--col-xl-5">
            <div class="st-prospectives__opinion-block">
              <div
                class="st-prospectives__opinion-box"
                :class="{
                  'st-prospectives__opinion-box-active': empSentiment,
                }"
                @click="$emit('tab-change', 'empSentiment')"
              >
                For companies looking to understand employee sentiment.
              </div>
              <div
                class="st-prospectives__opinion-box"
                :class="{
                  'st-prospectives__opinion-box-active': tenantPrefs,
                }"
                @click="$emit('tab-change', 'tenantPrefs')"
              >
                For developers looking to understand tenant preferences.
              </div>
              <div
                class="st-prospectives__opinion-box"
                :class="{
                  'st-prospectives__opinion-box-active': clientFeedback,
                }"
                @click="$emit('tab-change', 'clientFeedback')"
              >
                For designers looking to collect client feedback.
              </div>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-lg-7 bx--offset-xl-1 bx--col-xl-6">
            <div
              class="st-prospectives__count"
              :class="{ 'st-prospectives__count-active': empSentiment }"
            >
              <div class="st-prospectives__count-box">
                <h5 class="st-prospectives__count-heading">
                  Employee Count
                </h5>
                <div class="st-prospectives__count-input-canvas">
                  <st-input
                    class="st-prospectives__count-input"
                    :show-label="false"
                    label="5"
                  />
                </div>
              </div>
              <st-slider-input
                name="averageSalary"
                count-heading="Average Salary"
                min="0"
                max="100"
              />
              <!-- <div class="st-prospectives__count-box">
                  <h5 class="st-prospectives__count-heading">
                    Average Salary
                  </h5>
                  <div class="st-prospectives__slider-canvas st-prospectives__slider-canvas-count">
                    <div class="st-prospectives__slider">
                      <input
                        class="st-prospectives__slider-input"
                      >
                      <div class="st-prospectives__slider-box" />
                    </div>
                    <span class="st-prospectives__slider-count st-prospectives__slider-count-text">50k/year</span>
                  </div>
                </div> -->

              <st-slider-input
                name="empCount"
                count-heading="% of Employee’s Interviewed"
                min="0"
                max="100"
              />

              <!-- <div class="st-prospectives__count-box">
                  <h5 class="st-prospectives__count-heading">
                    % of Employee’s Interviewed
                  </h5>
                  <div class="st-prospectives__slider-canvas st-prospectives__slider-canvas-count">
                    <div class="st-prospectives__slider">
                      <input
                        class="st-prospectives__slider-input"
                      >
                      <div class="st-prospectives__slider-box" />
                    </div>
                    <span class="st-prospectives__slider-count st-prospectives__slider-count-text">5%</span>
                  </div>
                </div> -->
            </div>
            <div
              class="st-prospectives__count"
              :class="{ 'st-prospectives__count-active': tenantPrefs }"
            >
              <div class="st-prospectives__count-box">
                <h5 class="st-prospectives__count-heading">
                  Number of focus group respondents
                </h5>
                <div class="st-prospectives__count-input-canvas">
                  <st-input
                    class="st-prospectives__count-input"
                    :show-label="false"
                    label="0"
                  />
                </div>
              </div>
              <st-slider-input
                name="averageSalary"
                count-heading="Average charge per focus group member"
                min="150"
                max="10000"
              />
              <st-slider-input
                name="empCount"
                count-heading="Average incentive per focus group member"
                min="20"
                max="200"
              />
            </div>
            <div
              class="st-prospectives__count"
              :class="{ 'st-prospectives__count-active': clientFeedback }"
            >
              <div class="st-prospectives__count-box">
                <h5 class="st-prospectives__count-heading">
                  Client Engagement Size
                </h5>
                <div class="st-prospectives__count-input-canvas">
                  <st-input
                    class="st-prospectives__count-input"
                    :show-label="false"
                    label="0"
                  />
                </div>
              </div>
              <st-slider-input
                name="averageSalary"
                count-heading="Number of decision makers"
                min="8"
                max="80"
              />
              <st-slider-input
                name="empCount"
                count-heading="Value of client"
                min="20"
                max="200"
              />
            </div>
            <div class="st-prospectives__button-canvas">
              <st-button
                class="st-prospectives__button -m st-button__set"
                :variant="['secondary']"
                caption="CALCULATE"
                :show-action="true"
                :underline="false"
              />
            </div>
            <!-- <div class="st-prospectives__count-intro">
                <div class="st-prospectives__count-intro-block">
                  <h4 class="st-prospectives__count-intro-heading">
                    WITHOUT SPACETRICS
                  </h4>
                  <p class="st-prospectives__count-intro-para">
                    For a company with [100] employees who earn [$80,000] a year, to spend 1 hour with
                    [15%] of them to talk with them about how they value their office space could cost
                    about $4,800 in employee time for 120 conversations.
                  </p>
                </div>
                <div class="st-prospectives__count-intro-block st-prospectives__count-intro-specs">
                  <h4 class="st-prospectives__count-intro-heading">
                    WITH SPACETRICS
                  </h4>
                  <p class="st-prospectives__count-intro-para">
                    Spacetrics would allow for all employees to have open ended input and cost of $900
                    for our basic plan over a two month period.
                  </p>
                </div>
                <div class="st-prospectives__button-canvas">
                  <st-button
                    class="st-prospectives__button st-prospectives__button-caption-m st-button__set"
                    :variant="['secondary']"
                    caption="CALCULATE AGAIN"
                    :show-action="true"
                    :underline="false"
                  />
                </div>
              </div> -->
            <!-- <div class="st-prospectives__count-intro">
                <div class="st-prospectives__count-intro-block">
                  <h4 class="st-prospectives__count-intro-heading">
                    WITHOUT SPACETRICS
                  </h4>
                  <p class="st-prospectives__count-intro-para">
                    To do a focus group with [40] people would cost $34,000
                  </p>
                </div>
                <div class="st-prospectives__count-intro-block st-prospectives__count-intro-specs">
                  <h4 class="st-prospectives__count-intro-heading">
                    WITH SPACETRICS
                  </h4>
                  <p class="st-prospectives__count-intro-para">
                    Spacetrics would allow for 10x as many respondents, 400, for a cost of $4,500 or 12
                    percent of total focus group cost.
                  </p>
                </div>
                <div class="st-prospectives__button-canvas">
                  <st-button
                    class="st-prospectives__button st-prospectives__button-caption-m st-button__set"
                    :variant="['secondary']"
                    caption="CALCULATE AGAIN"
                    :show-action="true"
                    :underline="false"
                  />
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div
        id="contactForm"
        class="st-welcome__help"
      >
        <div class="bx--row">
          <div class="bx--col-xs-12 bx--col-lg-6 bx--col-xl-5">
            <h1 class="st-welcome__combine-heading st-welcome__combine-heading-help">
              See how spacetrics can help you understand what people think about your real estate spaces.
            </h1>
            <h5 class="st-welcome__subheading st-welcome__subheading-lightgrey">
              Fill in the form and we will be in touch shortly to give you a demo of the platform.
            </h5>
          </div>
          <div class="bx--col-xs-12 bx--col-lg-6 bx--offset-xl-1 bx--col-xl-5">
            <form
              class="st-welcome__help-group"
              action="https://formspree.io/f/zak@dupontstudios.com"
              method="post"
            >
              <input
                id="fullName"
                class="st-welcome__help-input"
                name="Full Name"
                placeholder="FULL NAME"
                type="name"
              >
              <input
                id="companyName"
                class="st-welcome__help-input"
                name="Company Name"
                placeholder="COMPANY NAME"
                type="name"
              >
              <input
                id="email"
                class="st-welcome__help-input"
                name="Email"
                placeholder="EMAIL"
                type="email"
              >
              <input
                id="phoneNumber"
                class="st-welcome__help-input"
                name="Phone Number"
                placeholder="PHONE NUMBER"
                type="tel"
              >
              <button
                class="st-welcome__button-submit"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <footer class="st-footer">
        <a
          href="/"
          class="st-footer__logo"
        >
          <icon-wrapper
            icon-name="spacetrics"
            class="st-footer__logo-icon"
          />
          <span class="st-footer__logo-text">spacetrics</span>
        </a>
        <div class="st-footer__content">
          <div class="st-footer__list">
            <a
              class="st-footer__link"
              href="/terms"
              target="_blank"
            >terms & conditions</a>
            <a
              class="st-footer__link"
              href="/privacy"
              target="_blank"
            >privacy policy</a>
          </div>
          <p class="st-footer__copyright">
            ©2021 Spacetrics
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { StButton, StInput, IconWrapper, StSliderInput } from '../../common'

export default {
  components: {
    StButton,
    StInput,
    IconWrapper,
    StSliderInput
  },
  props: {
    empSentiment: {
      type: Boolean,
      default: true,
      required: true
    },
    tenantPrefs: {
      type: Boolean,
      default: false,
      required: true
    },
    clientFeedback: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>

<style></style>
