<template>
  <div class="icon_minus">
    <div class="icon_minus__svg">
      <svg
        viewBox="0 0 16 1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h16v1H0z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
