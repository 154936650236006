
<template>
  <div>
    <st-input
      v-model="description"
      class="st-specifications__section-spacing--small"
      :disabled="isReadOnly"
      label="Question Text"
      name="question[description]"
      placeholder="What do you think about this fitness center?"
      @blur="updateQuestion('description', activeQuestion.description)"
    />
    <st-hyperlink
      :disabled="isReadOnly"
      :class="{
        'st-specifications__upload-link--concept-ranking': true,
        'st-specifications__upload-link--concept-ranking-with-options': options.length > 0,
      }"
      icon-class="st-hyperlink__default-icon-spacing"
      icon-name="upload"
      text="Upload Media (optional)"
      @click="openUploader"
    />
    <draggable
      v-model="options"
      v-bind="dragOptions"
      class="st-specifications__draggable-options"
      @change="handleDrop"
    >
      <transition-group>
        <template v-if="options.length > 0">
          <st-upload-preview
            v-for="(option, index) in options"
            :key="`media-preview-${option.id}`"
            :class="{
              'st-specifications__draggable-tile': true,
              'st-specifications__draggable-tile--extra-space': index === options.length - 1,
            }"
            :disabled="isReadOnly"
            :filename="fileNameFromUrl(option.mediaUrl)"
            :hide-bar="true"
            :src="option.mediaUrl"
            @remove="deleteOption(option.id)"
          />
        </template>
      </transition-group>
    </draggable>
    <div class="st-specifications__section-spacing">
      <div class="st-specifications__section-title-row">
        <p class="st-specifications__section-title">
          Ranking Scale?
        </p>
      </div>
      <st-radio-button
        v-model="scaleLength"
        class="st-radio-button__default-container"
        :disabled="isReadOnly"
        :options="[
          { caption: '1-3', value: 3},
          { caption: '1-5', value: 5 }
        ]"
      />
    </div>
    <div class="st-specifications__section-spacing">
      <div class="st-specifications__section-title-row">
        <p class="st-specifications__section-title">
          Scale Style?
        </p>
        <st-tooltip
          tip="Change the Scale Style depending on the type of questions."
          class="st-specifications__section-icon-tooltip"
          :max-width="isDesktop ? 411 : 200"
        >
          <icon-wrapper
            icon-name="info"
          />
        </st-tooltip>
      </div>
      <st-radio-button
        v-model="scaleStyle"
        :disabled="isReadOnly"
        :multi-row="true"
        :options="[
          { caption: 'Emoji Scale', value: 'emoji'},
          { caption: 'Numeric Scale', value: 'numeric'},
          { caption: 'Disagree-Agree Scale', value: 'agree_disagree'},
          { caption: 'Stars Scale', value: 'stars'},
        ]"
      />
    </div>
    <!-- <div class="st-specifications__section-spacing"> -->
    <!-- Uncomment when insights has transcripts -->
    <!-- <div class="st-specifications__section-title-row">
        <p class="st-specifications__section-title">
          Would you like to ask for an explanation?
        </p>
        <st-tooltip
          :tip="`Understand the reason behind a participant’s decision. Voice: Participants will have to use their microphone to record their explanation. Typed: Participants will have to use their keyboard to type their explanation.`"
          class="st-specifications__section-icon-tooltip"
        >
          <icon-wrapper
            icon-name="info"
          />
        </st-tooltip>
      </div> -->
    <!-- <st-radio-button
        v-model="askForExplanation"
        class="st-radio-button__default-container"
        :disabled="isReadOnly"
        :options="[
          { caption: 'No', value: 'no_explanation', iconName: 'dont'},
          { caption: 'Yes, Voice', value: 'voice_recording', iconName: 'microphone'},
          { caption: 'Yes, Typed', value: 'text', iconName: 'keyboard' }
        ]"
      /> -->
    <!-- </div> -->
    <st-media-uploader
      v-if="isUploadOpen"
      accept=".jpg, .jpeg, .png, .mp4"
      :additional-props="additionalProps"
      :folders="folders"
      modal-title="Upload Media or Select From Library"
      :multi-upload="true"
      prop-name="question_option[media]"
      :skip-cropper="true"
      url="/question_options"
      @closeMediaUploader="isUploadOpen = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import { IconWrapper, StHyperlink, StInput, StMediaUploader, StRadioButton, StTooltip, StUploadPreview } from '../../../common'
import fileName from '../../../../mixins/fileName'
import updateQuestion, { actionSetter, mutationSetter } from '../../../../mixins/question/updateQuestion.vue'
import { isDesktop, updateQuestionOption } from '../../../../mixins'

export default {
  components: {
    draggable,
    IconWrapper,
    StHyperlink,
    StInput,
    StMediaUploader,
    StRadioButton,
    StTooltip,
    StUploadPreview
  },
  mixins: [fileName, isDesktop, updateQuestion, updateQuestionOption],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: function () {
    return {
      acceptedFileTypes: '.jpg, .jpeg, .png, .mp4',
      isUploadOpen: false
    }
  },
  computed: {
    ...actionSetter(['askForExplanation', 'scaleStyle', 'scaleLength']),
    ...mutationSetter(['description', 'options']),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        forceFallback: true
      }
    },
    highestOptionPriority () {
      const l = this.activeQuestion.options.length
      return l === 0 ? 0 : this.activeQuestion.options[l - 1].priority
    }
  },
  methods: {
    ...mapMutations(['toggleFullSpinner']),
    ...mapMutations('specifications', ['mergeActiveQuestion', 'replaceActiveQuestion']),
    formatDate (date) {
      let dateMoment = moment(date)
      console.log(dateMoment)
      return dateMoment.format('MM/DD/YYYY')
    },
    handleDrop () {
      let updatedOptions = this.options.map((option, index) => {
        if (option.priority !== (index + 1)) {
          this.updateQuestionOption(option.id, index, { priority: (index + 1) }, true)
        }
        return { ...option, priority: (index + 1) }
      })
      this.mutateQuestion('options', updatedOptions)
    },
    openUploader () {
      if (this.isReadOnly) { return }
      this.isUploadOpen = true
    },
    deleteOption (id) {
      this.toggleFullSpinner(true)
      this.$axios({
        url: `/question_options/${id}`,
        method: 'delete'
      })
        .then(res => {
          const idx = this.activeQuestion.options.findIndex(option => option.id === id)
          const newOptions = [...this.activeQuestion.options]
          newOptions.splice(idx, 1)
          this.mergeActiveQuestion(res.data.question)
          this.toggleFullSpinner(false)
        })
        .catch(() => { this.toggleFullSpinner(false) })
    },
    additionalProps () {
      let i = this.highestOptionPriority
      return {
        'question_option[question_id]': this.activeQuestion.id,
        'question_option[priority]': () => { i++; return i }
      }
    },
    uploadComplete (payload) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        url: `/questions/${this.activeQuestion.id}`,
        method: 'get'
      })
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          this.replaceActiveQuestion(res.data)
        })
        .catch(err => {
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          console.log('bad', err)
        })
    }
  }
}
</script>
