var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.cropperOpen)?_c('st-cropper',{staticClass:"st-media-uploader__cropper",attrs:{"aspect-ratio":_vm.aspectRatio,"button-values":{
      caption:'Crop',
      iconName: 'arrow'
    },"modal-title":_vm.cropperTitle,"src":_vm.mediaToUpload[0].previewUrl,"show-cropper":_vm.cropperOpen},on:{"closeCropper":_vm.onCropperClose,"upload":_vm.onCropperUpload}}):_c('st-modal',{attrs:{"hide-close":!_vm.isDesktop,"show-modal":true,"size-class":"st-modal--large st-modal__upload-modal"},on:{"closeModal":_vm.closeMediaUploader}},[_c('template',{slot:"modalTitle"},[_c('div',{staticClass:"st-media-uploader__mobile-bar"}),_vm._v(" "),_c('p',{staticClass:"st-media-uploader__title"},[_vm._v("\n        "+_vm._s(_vm.modalTitle)+"\n      ")])]),_vm._v(" "),_c('template',{slot:"modalActions"},[_c('div',{staticClass:"st-media-uploader__wrap-actions"},[_c('div',{staticClass:"action-toggle"},[_c('div',{class:{
              'action-toggle__button': true,
              'action-toggle__button--active': _vm.modalActionStage === 'upload'
            },on:{"click":function($event){return _vm.updateModalActionStage('upload')}}},[_vm._v("\n            Upload\n          ")]),_vm._v(" "),_c('div',{class:{
              'action-toggle__button': true,
              'action-toggle__button--active': _vm.modalActionStage === 'researcher'
            },on:{"click":function($event){return _vm.updateModalActionStage('researcher')}}},[_vm._v("\n            Your Library\n          ")]),_vm._v(" "),_c('div',{class:{
              'action-toggle__button': true,
              'action-toggle__button--active': _vm.modalActionStage === 'spacetrics'
            },on:{"click":function($event){return _vm.updateModalActionStage('spacetrics')}}},[_c('icon-wrapper',{attrs:{"icon-name":"spacetrics","actionable":false}}),_vm._v(" "),_c('p',{staticClass:"action-toggle__spacetrics-library-text"},[_vm._v("\n              Library\n            ")])],1)]),_vm._v(" "),(_vm.modalActionStage === 'upload')?_c('div',{class:{ 'st-media-uploader__upload-section': _vm.skipCropper }},[(_vm.mediaToUpload.length === 0 || _vm.multiUpload)?_c('st-dropzone',{class:{
              'st-media-uploader__dropzone-empty': _vm.mediaToUpload.length === 0,
              'st-media-uploader__dropzone-with-files': _vm.mediaToUpload.length > 0
            },attrs:{"activeQuestion":this.activeQuestion,"accept":_vm.accept,"name":"asset[media]","multiple":_vm.multiUpload},on:{"input":_vm.onDropzone}}):_vm._e(),_vm._v(" "),(_vm.mediaToUpload.length>0)?_c('div',{class:{ 'st-media-uploader__uploader-previews': _vm.multiUpload }},_vm._l((_vm.mediaToUpload),function(media,index){return _c('st-upload-preview',{key:'media ' + index,staticClass:"st-media-library__upload-preview",attrs:{"display-large-preview":_vm.aspectRatio === 4.23,"file":media.file,"filename":media.name,"src":media.previewUrl,"upload-percent":media.uploadPercent,"variant":['modal']},on:{"remove":function($event){return _vm.removePreview(media)}}})}),1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.modalActionStage === 'researcher' && _vm.libraries !== null)?_c('div',{staticClass:"st-media-uploader__library-container"},[_c('div',{staticClass:"st-media-uploader__library-scroll-area"},[(_vm.filteredRecentAssets.length > 0)?_c('st-accordion',{scopedSlots:_vm._u([{key:"visible-section",fn:function(){return [_c('div',{staticClass:"st-media-library__accordion-row"},[_c('icon-wrapper',{staticClass:"st-accordion__section-icon",attrs:{"icon-name":"clock"}}),_vm._v(" "),_c('div',{staticClass:"st-accordion__group-name"},[_vm._v("\n                    Recently Uploaded\n                  ")])],1)]},proxy:true},{key:"expanded-section",fn:function(){return [_c('div',{staticClass:"st-media-library__group-expanded-section"},_vm._l((_vm.filteredRecentAssets),function(asset){return _c('div',{key:'recent-asset-'+ asset.id},[_c('st-card',{class:{
                        'st-media-library__asset-card st-media-uploader__asset-card': true,
                        'st-media-uploader__asset-card--selected': _vm.selectedLibraryAssets.includes(asset.id)
                      },on:{"click":function($event){return _vm.selectLibraryMedia(asset)}},scopedSlots:_vm._u([{key:"card-content",fn:function(slotProps){return [(asset.mediaUrl.includes('mp4'))?_c('video',{staticClass:"st-media-library__asset-card-preview"},[_c('source',{attrs:{"src":asset.mediaUrl,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag.\n                        ")]):_c('div',{staticClass:"st-media-library__asset-card-preview",style:({
                            background: ("url('" + (asset.mediaUrl) + "') center / contain no-repeat")
                          })}),_vm._v(" "),_c('div',{class:{
                            'st-media-library__asset-name st-media-library__asset-name--uploader': true,
                            'st-media-library__asset-name--hover': slotProps.hover
                          }},[_vm._v("\n                          "+_vm._s(asset.filename)+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"st-media-library__asset-card-bottom-row"},[_c('div',{staticClass:"st-media-library__date-row"},[_c('icon-wrapper',{staticClass:"st-media-library__calendar-icon",attrs:{"icon-name":"calendar"}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.formatDate(asset.updatedAt)))])],1)])]}}],null,true)})],1)}),0)]},proxy:true}],null,false,445333598)}):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredAccountFolders),function(accountFolder){return _c('st-accordion',{key:'account-folder'+ accountFolder.id,scopedSlots:_vm._u([{key:"visible-section",fn:function(){return [_c('div',{staticClass:"st-media-library__accordion-row"},[_c('icon-wrapper',{staticClass:"st-accordion__section-icon",attrs:{"icon-name":"file"}}),_vm._v(" "),_c('div',{staticClass:"st-accordion__group-name"},[_vm._v("\n                    "+_vm._s(accountFolder.name)+"\n                  ")])],1)]},proxy:true},{key:"expanded-section",fn:function(){return [_c('div',{staticClass:"st-media-library__group-expanded-section"},_vm._l((accountFolder.assets),function(asset){return _c('div',{key:'account-asset-'+ asset.id},[_c('st-card',{class:{
                        'st-media-library__asset-card st-media-uploader__asset-card': true,
                        'st-media-uploader__asset-card--selected': _vm.selectedLibraryAssets.includes(asset.id)
                      },on:{"click":function($event){return _vm.selectLibraryMedia(asset)}},scopedSlots:_vm._u([{key:"card-content",fn:function(slotProps){return [(asset.mediaUrl.includes('mp4'))?_c('video',{staticClass:"st-media-library__asset-card-preview"},[_c('source',{attrs:{"src":asset.mediaUrl,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag.\n                        ")]):_c('div',{staticClass:"st-media-library__asset-card-preview",style:({
                            background: ("url('" + (asset.mediaUrl) + "') center / contain no-repeat")
                          })}),_vm._v(" "),_c('div',{class:{
                            'st-media-library__asset-name st-media-library__asset-name--uploader': true,
                            'st-media-library__asset-name--hover': slotProps.hover
                          }},[_vm._v("\n                          "+_vm._s(asset.filename)+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"st-media-library__asset-card-bottom-row"},[_c('div',{staticClass:"st-media-library__date-row"},[_c('icon-wrapper',{staticClass:"st-media-library__calendar-icon",attrs:{"icon-name":"calendar"}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.formatDate(asset.updatedAt)))])],1)])]}}],null,true)})],1)}),0)]},proxy:true}],null,true)})})],2)]):_vm._e(),_vm._v(" "),(_vm.modalActionStage === 'spacetrics' && _vm.libraries !== null)?_c('div',{staticClass:"st-media-uploader__library-container"},[_c('div',{staticClass:"st-media-uploader__library-scroll-area"},_vm._l((_vm.filteredSpacetricsFolders),function(spacetricsFolder){return _c('st-accordion',{key:'spacetrics-folder'+ spacetricsFolder.id,scopedSlots:_vm._u([{key:"visible-section",fn:function(){return [_c('div',{staticClass:"st-media-library__accordion-row"},[_c('icon-wrapper',{staticClass:"st-accordion__section-icon",attrs:{"icon-name":"file"}}),_vm._v(" "),_c('div',{staticClass:"st-accordion__group-name"},[_vm._v("\n                    "+_vm._s(spacetricsFolder.name)+"\n                  ")])],1)]},proxy:true},{key:"expanded-section",fn:function(){return [_c('div',{staticClass:"st-media-library__group-expanded-section"},_vm._l((spacetricsFolder.assets),function(asset){return _c('div',{key:'account-asset-'+ asset.id},[_c('st-card',{class:{
                        'st-media-library__asset-card st-media-uploader__asset-card': true,
                        'st-media-uploader__asset-card--selected': _vm.selectedLibraryAssets.includes(asset.id)
                      },on:{"click":function($event){return _vm.selectLibraryMedia(asset)}},scopedSlots:_vm._u([{key:"card-content",fn:function(slotProps){return [(asset.mediaUrl.includes('mp4'))?_c('video',{staticClass:"st-media-library__asset-card-preview"},[_c('source',{attrs:{"src":asset.mediaUrl,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag.\n                        ")]):_c('div',{staticClass:"st-media-library__asset-card-preview",style:({
                            background: ("url('" + (asset.mediaUrl) + "') center / contain no-repeat")
                          })}),_vm._v(" "),_c('div',{class:{
                            'st-media-library__asset-name st-media-library__asset-name--uploader': true,
                            'st-media-library__asset-name--hover': slotProps.hover
                          }},[_vm._v("\n                          "+_vm._s(asset.filename)+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"st-media-library__asset-card-bottom-row"},[_c('div',{staticClass:"st-media-library__date-row"},[_c('icon-wrapper',{staticClass:"st-media-library__calendar-icon",attrs:{"icon-name":"calendar"}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.formatDate(asset.updatedAt)))])],1)])]}}],null,true)})],1)}),0)]},proxy:true}],null,true)})}),1)]):_vm._e(),_vm._v(" "),(_vm.modalActionStage === 'upload')?_c('div',{staticClass:"st-media-uploader__auto-complete-input"},[_c('st-autocomplete',{attrs:{"initial-matchable-list":_vm.folders,"initial-value":_vm.folder,"caption-fn":function (folder) { return folder.name; },"create-caption":"a New Folder","create-fn":_vm.createNewFolder,"label":"Select folder or create one (Optional)","option-icon-name":"file","placeholder":_vm.folders.length > 0 ? '' : 'There are currently no folders create one to get started',"toast-caption":"Folder Created","variant":['separateCreate']},on:{"failure":_vm.handleAutocompleteFailure,"selectOption":function (val) {_vm.folder = val},"success":_vm.handleAutocompleteSuccess,"unselectOption":function($event){_vm.folder = null}}})],1):_vm._e()])]),_vm._v(" "),_c('template',{slot:"modalButton"},[_c('div',{staticClass:"st-media-uploader__upload-button-container"},[_c('st-button',{key:_vm.uploadButtonDisabled,staticClass:"st-media-uploader__upload-button",attrs:{"caption":_vm.uploadCaption,"icon-name":_vm.uploadIcon,"disabled":_vm.uploadButtonDisabled,"show-action":true},on:{"click":_vm.uploadMedia}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }