var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-pagination"},[_c('div',{staticClass:"st-pagination__container--items-information"},[_c('div',[_vm._v("\n      Items per page:\n    ")]),_vm._v(" "),_c('div',{staticClass:"st-pagination__container--options-dropdown"},[_c('div',{class:{
          'st-pagination__container--chosen-option': true,
          'st-pagination__container--disabled': !_vm.numberOfItems,
          'st-pagination__remove-focus': _vm.removeFocus,
          'st-pagination__container--chosen-option__open': _vm.itemsDropdownOpen
        },attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleItemsOptionsDropdown.apply(null, arguments)},"focusout":function($event){_vm.removeFocus = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.toggleItemsOptionsDropdown.apply(null, arguments)},"mousedown":function($event){_vm.removeFocus = true}}},[_c('input',{class:{
            'st-pagination__container--per-option-number': true,
            'st-pagination__container--per-option-number-disabled': !_vm.numberOfItems
          },attrs:{"value":_vm.activeItemsPerPageOption,"type":"button"}}),_vm._v(" "),_c('div',{staticClass:"st-pagination__container--icon-caret"},[_c('icon-wrapper',{attrs:{"icon-name":_vm.checkItemsIconName}})],1)]),_vm._v(" "),(_vm.itemsDropdownOpen)?_c('div',{ref:"itemsDropdownOptions",staticClass:"st-pagination__container--options"},_vm._l((_vm.inactiveItemsPerPageOptions),function(option,index){return _c('input',{key:option,staticClass:"st-pagination__options",attrs:{"type":"button","tabindex":"0","value":option},on:{"click":function($event){return _vm.updateItemsOption(option, index)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateItemsOption(option, index)}}})}),0):_vm._e()]),_vm._v(" "),_c('div',[_vm._v("\n      "+_vm._s(_vm.paginationDisplayValues.from)+" - "+_vm._s(_vm.paginationDisplayValues.to)+" of "+_vm._s(_vm.paginationDisplayValues.total)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"st-pagination__container--page-information"},[(_vm.activePageOption > 1)?_c('div',{class:{
        'st-pagination__container--icon-previous': true,
        'st-pagination__remove-focus': _vm.removeFocus,
      },attrs:{"tabindex":"0"},on:{"click":_vm.previousPageOption,"focusout":function($event){_vm.removeFocus = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.previousPageOption.apply(null, arguments)},"mousedown":function($event){_vm.removeFocus = true}}},[_c('icon-wrapper',{staticClass:"st-pagination__icon--chevron",attrs:{"icon-name":"chevron"}})],1):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n      Page\n    ")]),_vm._v(" "),_c('div',{staticClass:"st-pagination__container--options-dropdown"},[_c('div',{class:{
          'st-pagination__container--chosen-option': true,
          'st-pagination__container--disabled': !_vm.numberOfItems,
          'st-pagination__remove-focus': _vm.removeFocus,
          'st-pagination__container--chosen-option__open': _vm.pageDropdownOpen
        },attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.togglePageOptionsDropdown.apply(null, arguments)},"focusout":function($event){_vm.removeFocus = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.togglePageOptionsDropdown.apply(null, arguments)},"mousedown":function($event){_vm.removeFocus = true}}},[_c('input',{class:{
            'st-pagination__container--per-option-number': true,
            'st-pagination__container--per-option-number-disabled': !_vm.numberOfItems
          },attrs:{"value":_vm.activePageOption,"type":"button"}}),_vm._v(" "),_c('div',{staticClass:"st-pagination__container--icon-caret"},[_c('icon-wrapper',{attrs:{"icon-name":_vm.checkPagesIconName}})],1)]),_vm._v(" "),(_vm.pageDropdownOpen)?_c('div',{ref:"pageDropdownOptions",staticClass:"st-pagination__container--options"},_vm._l((_vm.inactivePageOptions),function(option,index){return _c('input',{key:option,staticClass:"st-pagination__options",attrs:{"type":"button","tabindex":"0","value":option},on:{"click":function($event){return _vm.updatePageOption(option, index)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updatePageOption(option, index)}}})}),0):_vm._e()]),_vm._v(" "),_c('div',[_vm._v("\n      of "+_vm._s(_vm.lastPage)+"\n    ")]),_vm._v(" "),_c('div',{class:{
        'st-pagination__container--icon-next': true,
        'st-pagination__remove-focus': _vm.removeFocus,
        'st-pagination__container--icon-next__disabled': _vm.endOfPageOptions || !_vm.numberOfItems
      },attrs:{"tabindex":"0"},on:{"click":_vm.nextPageOption,"focusout":function($event){_vm.removeFocus = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.nextPageOption.apply(null, arguments)},"mousedown":function($event){_vm.removeFocus = true}}},[_c('icon-wrapper',{class:{
          'st-pagination__icon--chevron': true,
          'st-pagination__icon--chevron__disabled': _vm.endOfPageOptions
        },attrs:{"icon-name":"chevron"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }