import axios from 'axios'
export async function requestReportExport ( vue, reportType = "pdf", userEmail) {
  const { survey, '$store': store } = vue

  const {
    state: {
      insights: {
        surveyQuestions
      }
    },
  } = store;

  const { name: surveyName, project: { name: projectName, locationName: locationName }, segments, id: surveyId } = survey;

  const apiPayload = {
   
    surveyId,
    surveyName,
    projectName,
    locationName,
    questionIds: surveyQuestions.map(({id}) => id),
    segments,
    reportType,
    userEmail,
  }
  try {
    await axios.post(process.env.VUE_APP_EXPORT_REPORT_BACKEND_URL, apiPayload);
    return Promise.resolve();
    
  }
  catch (error) {
    console.error("GENERATOR_API_ERROR", error)
    return Promise.reject(error)
  }

  
}