<template>
  <div class="icon_avatar">
    <div class="icon_avatar__svg">
      <svg
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M15 1c-3.74 0-7.255 1.456-9.9 4.1A13.908 13.908 0 001 15c0 3.74 1.456 7.255 4.1 9.9A13.908 13.908 0 0015 29c3.74 0 7.255-1.456 9.9-4.1A13.908 13.908 0 0029 15c0-3.74-1.456-7.255-4.1-9.9A13.908 13.908 0 0015 1m0-1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0z" />
        </g>
        <g transform="translate(7 8)">
          <path d="M2 0a2 2 0 11-2 2 2 2 0 012-2z" />
          <path d="M2 1a1.001 1.001 0 000 2 1.001 1.001 0 000-2m0-1a2 2 0 110 4 2 2 0 010-4z" />
        </g>
        <g transform="translate(19 8)">
          <path d="M2 0a2 2 0 11-2 2 2 2 0 012-2z" />
          <path d="M2 1a1.001 1.001 0 000 2 1.001 1.001 0 000-2m0-1a2 2 0 110 4 2 2 0 010-4z" />
        </g>
        <path
          d="M0 0s-.164 4.813 4.7 4.813S9.664 0 9.664 0"
          transform="translate(10.116 17.328)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
