<script>
export default {
  data: function () {
    return {
      $_windowInnerWidth: window.innerWidth
    }
  },
  computed: {
    isDesktop () {
      return this.$data.$_windowInnerWidth >= 576
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.$_getElementSize)
      this.$_getElementSize()
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.$_getElementSize)
  },
  methods: {
    $_getElementSize () {
      this.$data.$_windowInnerWidth = window.innerWidth
    }
  }
}
</script>
