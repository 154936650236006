<template>
  <div class="icon_replace">
    <div class="icon_replace__svg">
      <svg
        viewBox="0 0 16.015 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(.007)">
          <path
            d="M0 0l7.521.162.019.891L.019.891z"
            transform="rotate(45 5.338 12.886)"
          />
          <path
            d="M0 1.044L7.52.882l.019-.891L.018.153z"
            transform="rotate(-45 17.292 -6.712)"
          />
          <path
            d="M0 0h14.862v1H0z"
            transform="translate(.019 5)"
          />
          <path
            d="M0 0l7.521.162.019.891L.019.891z"
            transform="rotate(-135 6.183 7.397)"
          />
          <path
            d="M0 1.044L7.52.882l.019-.891L.018.153z"
            transform="rotate(135 1.699 4.651)"
          />
          <path
            d="M0 0h14.862v1H0z"
            transform="rotate(180 7.99 6)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
