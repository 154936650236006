<script>
export default {
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      showToast: false
    }
  },
  computed: {
    isEditAudience () {
      return window.location.href.includes('/edit/audience')
    },
    isEditingDisabled () {
      return !this.survey.canEdit || (this.survey.published && !this.isEditAudience)
    },
    toastMessage () {
      return this.survey.published && !this.isEditAudience
        ? 'This survey is already published and this section cannot be edited'
        : 'You only have read permissions, in order to make edits request permission changes from your account admin'
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.isEditingDisabled) { this.showToast = true }
    })
  }
}
</script>
