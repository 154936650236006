import moment from 'moment'

const surveyProxyHandler = {
  get (obj, prop) {
    let val = obj[prop]
    if (prop === 'updatedAt') {
      val = moment(val)
    }
    return val
  }
}

export function Survey (survey) {
  return new Proxy(survey, surveyProxyHandler)
}
