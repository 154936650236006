<template>
  <div class="st-sentiment-data-table-container">
    <table class="st-sentiment-data-table">
      <thead class="st-sentiment-data-table__header">
        <tr class="st-sentiment-data-table__header-row">
          <th
            class="st-sentiment-data-table__head-item"
          >
            <div
              class="st-sentiment-data-table__header-and-column-chevron"
              @click="handleColumnSorting('Keyword')"
              @mouseenter="hoverIdx = 'Keyword'"
              @mouseleave="hoverIdx = null"
            >
              {{ columnTitle }}
              <div class="st-sentiment-data-table__column-chevron-container">
                <transition name="fade">
                  <icon-wrapper
                    v-if="activeColumn && activeColumn.name === 'Keyword' || (hoverIdx === 'Keyword')"
                    :class="{
                      'st-sentiment-data-table__column-chevron': true,
                      'st-sentiment-data-table__column-chevron--descending': activeColumn && activeColumn.name === 'Keyword' && !activeColumn.ascending
                    }"
                    icon-name="chevron"
                  />
                </transition>
              </div>
            </div>
          </th>
          <th
            v-for="(header, index) in tableHeaders"
            :key="index"
            class="st-sentiment-data-table__head-item"
          >
            <div
              class="st-sentiment-data-table__header-and-column-chevron"
              @click="handleColumnSorting(header)"
              @mouseenter="hoverIdx = index"
              @mouseleave="hoverIdx = null"
            >
              {{ header }}
              <div class="st-sentiment-data-table__column-chevron-container">
                <transition name="fade">
                  <icon-wrapper
                    v-if="activeColumn && activeColumn.name === header || (hoverIdx === index)"
                    :class="{
                      'st-sentiment-data-table__column-chevron': true,
                      'st-sentiment-data-table__column-chevron--descending': activeColumn && activeColumn.name === header && !activeColumn.ascending
                    }"
                    icon-name="chevron"
                  />
                </transition>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="st-sentiment-data-table__body">
        <template v-for="(sentimentRow, idx) in sortedRowsByActiveColumn">
          <tr
            :key="idx + 'row'"
            :class="{
              'st-sentiment-data-table__row': true,
              'st-sentiment-data-table__row--header': idx === 0 && isFirstRowHeader,
              'st-sentiment-data-table__row--expandable': expandable
            }"
            @click="toggleRowExpansion(idx)"
          >
            <td
              v-if="expandable"
              class="st-sentiment-data-table__item st-sentiment-data-table__item--link"
            >
              <icon-wrapper
                icon-name="chevron"
                :class="{
                  'st-sentiment-data-table__chevron': true,
                  'st-sentiment-data-table__chevron--open': isExpanded(idx)
                }"
                :neutral="!isExpanded(idx)"
              />
              <div
                :class="{
                  'st-sentiment-data-table__keyword': true,
                  'st-sentiment-data-table__keyword-open': isExpanded(idx)
                }"
              >
                {{ sentimentRow.title }}
              </div>
              <div
                :class="{
                  'st-sentiment-data-table__spacer': true,
                  'st-sentiment-data-table__spacer--expanded': isExpanded(idx)
                }"
              />
            </td>
            <td
              v-else
              class="st-sentiment-data-table__item"
            >
              {{ sentimentRow.title }}
            </td>
            <td class="st-sentiment-data-table__item">
              <sentiment-tag :sentiment="rowAverages[idx]" />
            </td>
            <td
              v-if="includeCount"
              class="st-sentiment-data-table__item"
            >
              {{ rowTotalCounts[idx] }}
            </td>
            <template v-if="activeQuestionModifier==='votes'">
              <td
                v-for="(sentimentCount, percentIdx) in rowSentimentCounts[idx]"
                :key="percentIdx"
                class="st-sentiment-data-table__item"
              >
                {{ sentimentCount }}
              </td>
            </template>
            <template v-else>
              <td
                v-for="(sentimentPercent, percentIdx) in rowSentimentPercents[idx]"
                :key="percentIdx"
                class="st-sentiment-data-table__item"
              >
                {{ sentimentPercent }}%
              </td>
            </template>
          </tr>
          <tr
            v-if="isExpanded(idx)"
            :key="idx + 'expanded-row'"
          >
            <td
              class="st-sentiment-data-table__item"
              :colspan="includeCount ? 8 : 7"
            >
              <slot
                name="expanded-row"
                :row="sentimentRow"
                :row-idx="idx"
              />
            </td>
          </tr>
        </template>
      </tbody>
      <tbody />
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { average, bucketIdx, bucketCounts, caseInsensitiveSortBy, percent, sum } from '../../../utilities/data_utilities'
import { IconWrapper } from '../../common'
import SentimentTag from './sentiment-tag'
export default {
  components: {
    IconWrapper,
    'sentiment-tag': SentimentTag
  },
  props: {
    sentimentRows: {
      type: Array, // each should be an object with title and sentiments[] defined
      required: false,
      default: () => []
    },
    includeCount: {
      type: Boolean,
      required: false,
      default: false
    },
    isFirstRowHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    totalParticipants: {
      type: Number,
      required: false,
      default: 0
    },
    expandable: {
      type: Boolean,
      required: false,
      default: false
    },
    columnTitle: {
      type: String,
      required: false,
      default: 'Room Name'
    }
  },
  data: function () {
    return {
      activeColumn: { name: 'Avg. Sentiment', ascending: true },
      expandedRows: {},
      headers: ['Avg. Sentiment', 'Participant Mentions', 'Very Negative', 'Negative', 'Neutral', 'Positive', 'Very Positive'],
      hoverIdx: null
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionModifier']),
    rowAverages () {
      return this.sortedRowsByActiveColumn.map(sentimentRow => average(sentimentRow.sentiments || []))
    },
    rowTotalCounts () {
      return this.sortedRowsByActiveColumn.map(sentimentRow => sentimentRow.sentiments.length)
    },
    rowAverageBucketIndexes () {
      return this.rowAverages.map(average => bucketIdx(average))
    },
    rowSentimentCounts () {
      return this.sortedRowsByActiveColumn.map(row => this.sentimentCounts(row.sentiments))
    },
    rowSentimentPercents () {
      return this.sortedRowsByActiveColumn.map(sentimentRow => this.sentimentPercents(sentimentRow.sentiments))
    },
    tableHeaders () {
      return this.includeCount ? this.headers : this.headers.filter(header => header !== 'Participant Mentions')
    },
    sortedRowsByActiveColumn () {
      let rows = []
      switch (this.activeColumn.name) {
        case 'Keyword':
          return this.sortColumnProperty('title')
        case 'Avg. Sentiment':
          rows = this.activeColumn.ascending
            ? this.sentimentRows.slice().sort((a, b) => b.averageSentiment - a.averageSentiment)
            : this.sentimentRows.slice().sort((a, b) => a.averageSentiment - b.averageSentiment)
          break
        case 'Participant Mentions':
          rows = this.activeColumn.ascending
            ? this.sentimentRows.slice().sort((a, b) => b.sentiments.length - a.sentiments.length)
            : this.sentimentRows.slice().sort((a, b) => a.sentiments.length - b.sentiments.length)
          break
        case 'Very Negative':
          return this.sortSentiment(0)
        case 'Negative':
          return this.sortSentiment(1)
        case 'Neutral':
          return this.sortSentiment(2)
        case 'Positive':
          return this.sortSentiment(3)
        case 'Very Positive':
          return this.sortSentiment(4)
      }
      return rows
    }
  },
  methods: {
    sentimentPercents (sentiments) {
      let rowCounts = this.sentimentCounts(sentiments)
      let totalCount = sum(rowCounts)
      return rowCounts.map(rowCount => percent(rowCount, totalCount, 0))
    },
    sentimentCounts (sentiments) {
      return bucketCounts(sentiments)
    },
    handleColumnSorting (header) {
      this.activeColumn.name === header
        ? this.activeColumn = { ...this.activeColumn, ascending: !this.activeColumn.ascending }
        : this.activeColumn = { name: header, ascending: true }
    },
    isExpanded (idx) {
      return Boolean(this.expandedRows[idx])
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.sentimentRows, property)
        : caseInsensitiveSortBy(this.sentimentRows, property, true)
    },
    sortSentiment (index) {
      if (this.activeQuestionModifier === 'votes') {
        return this.activeColumn.ascending
          ? this.sentimentRows.slice().sort((a, b) => this.sentimentCounts(b.sentiments)[index] - this.sentimentCounts(a.sentiments)[index])
          : this.sentimentRows.slice().sort((a, b) => this.sentimentCounts(a.sentiments)[index] - this.sentimentCounts(b.sentiments)[index])
      } else {
        return this.activeColumn.ascending
          ? this.sentimentRows.slice().sort((a, b) => this.sentimentPercents(b.sentiments)[index] - this.sentimentPercents(a.sentiments)[index])
          : this.sentimentRows.slice().sort((a, b) => this.sentimentPercents(a.sentiments)[index] - this.sentimentPercents(b.sentiments)[index])
      }
    },
    toggleRowExpansion (idx) {
      if (!this.expandable) { return }
      let oldValue = this.expandedRows[idx]
      this.$set(this.expandedRows, idx, !oldValue)
    }
  }
}
</script>
