<template>
  <div class="icon_settings">
    <div class="icon_settings__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M6 3a3 3 0 11-3-3 3 3 0 013 3zM5 3a2 2 0 10-2 2 2 2 0 002-2z"
            transform="translate(5 4.995)"
          />
          <path
            d="M-23439.947-93.126h4.043v2.35a5.689 5.689 0 011.91 1.079l2.049-1.165 2.02 3.463-2.055 1.167a5.594 5.594 0 010 2.207l2.055 1.165-2.025 3.455-2.041-1.155a5.775 5.775 0 01-1.914 1.1v2.337h-4.039v-2.348a6.556 6.556 0 01-1.924-1.084l-2.049 1.153-2.008-3.464 2.047-1.151a6.2 6.2 0 01-.016-2.234l-2.031-1.142 2.008-3.463 2.051 1.162a6.537 6.537 0 011.922-1.089c.001-1.605.003-1.077-.003-2.343zm-1.8 4.651l-1.8-1.02-1 1.726 1.813 1.02a5.471 5.471 0 00-.023 3.247l-1.789 1.007 1 1.73 1.8-1.013a6.515 6.515 0 002.807 1.616v2.036h2.021v-2.047a6.1 6.1 0 002.813-1.6l1.781 1.008 1.014-1.727-1.809-1.025a5.644 5.644 0 000-3.224l1.807-1.026-1.012-1.732-1.8 1.023a6.132 6.132 0 00-2.8-1.611v-2.039h-2.021c0 .392 0 1.664-.006 2.054a6.246 6.246 0 00-2.793 1.597z"
            transform="translate(23445.924 93.126)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
