<template>
  <div class="icon_projects">
    <div class="icon_projects__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2V0h16v14H0V2zm1 0v11h14V1H1z"
          transform="translate(0 2)"
        />
        <path
          d="M0 1.5V0h6v3H0zm1 .03V2h4V1H1z"
          transform="translate(5)"
        />
        <path
          d="M0 0h3"
          transform="translate(6.5 11.5)"
        />
        <path
          d="M-.5.5v-1h4v1z"
          transform="translate(6.5 11.5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
