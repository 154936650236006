<template>
  <div class="specifications__section">
    <div class="specifications__title">
      Element Preferences
    </div>
    <div class="st-input__row">
      <label class="st-input__label">
        Question
        <input
          :value="activeQuestion.description"
          class="st-input"
          type="text"
          name="question[description]"
          placeholder="Your question goes here"
          :disabled="isPreview"
          @blur="updateQuestion('description', $event.target.value)"
        >
      </label>
    </div>
    <div>
      <div class="specifications__2-col-width">
        <cv-button
          v-if="isPreview"
          kind="secondary"
          class="st-templates__preview-button"
          disabled
        >
          + Add Image
        </cv-button>
      </div>
      <label class="st-input__label">
        Upload Image(s)
      </label>
      <ds-dropzone
        :image="activeQuestion.imageUrl"
        :info="activeQuestion"
        :upload-url="`/questions/${activeQuestion.id}`"
        :aspect-ratio="1"
        :property-name="'question[image]'"
        @uploadMedia="replaceActiveQuestion"
        @deleteImage="deleteMedia"
      >
        <cv-tile
          slot-scope="{ deleteImage }"
          class="specifications__image-tile"
        >
          <div class="specifications__image-and-name">
            <div
              class="specifications__image"
              :style="style"
            />
            <p class="specifications__file-name">
              {{ fileNameFromUrl(activeQuestion.imageUrl) }}
            </p>
          </div>
          <div
            v-if="!isPreview"
            class="st-svg__delete"
            @click="deleteImage"
          />
        </cv-tile>
      </ds-dropzone>
      <label class="st-input__label st-input__label--half-length">
        <input
          v-if="activeQuestion.imageUrl != null"
          :value="activeQuestion.name"
          class="st-input"
          type="text"
          placeholder="Image name goes here"
          :disabled="isPreview"
          @blur="updateQuestion('name', $event.target.value)"
        >
      </label>
    </div>
    <div
      class="st-input__row"
      style="padding-bottom: 100px;"
    >
      <label class="st-input__label">
        How many pins you would like users to have for this image?
        <cv-dropdown
          v-if="isPreview"
          class="st-input culture-board st-templates__preview-cursor"
          theme="light"
          :placeholder="pinCount"
        />
        <cv-dropdown
          v-else
          v-model="pinCount"
          class="st-input culture-board"
          theme="light"
          placeholder="-"
          @change="handlePinCountChange"
        >
          <cv-dropdown-item
            v-for="n in [3, 4, 5, 6]"
            :key="n"
            :value="n.toString()"
          >
            {{ n }}
          </cv-dropdown-item>
        </cv-dropdown>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import CvDropDown from '@carbon/vue/src/components/cv-dropdown/cv-dropdown'
import CvDropDownItem from '@carbon/vue/src/components/cv-dropdown/cv-dropdown-item'
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import CvTile from '@carbon/vue/src/components/cv-tile/cv-tile'
import fileName from '../../../mixins/fileName'
import updateQuestion from '../../../mixins/question/updateQuestion'
import updateQuestionOption from '../../../mixins/question/updateQuestionOption'
// import DsDropzone from '../../tools/ds-dropzone'

export default {
  components: {
    'cv-dropdown': CvDropDown,
    'cv-dropdown-item': CvDropDownItem,
    'cv-button': CvButton,
    'cv-tile': CvTile
    // 'ds-dropzone': DsDropzone
  },
  mixins: [fileName, updateQuestion, updateQuestionOption],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      pinCount: '3',
      dropDownBoolean: false
    }
  },
  computed: {
    ...mapGetters('specifications', ['activeQuestion']),
    style () {
      return {
        background: `url('${this.activeQuestion.imageUrl}') center/contain no-repeat`
      }
    }
  },
  mounted: function () {
    this.pinCount = this.options.length.toString()
    this.dropDownBoolean = true
  },
  methods: {
    ...mapMutations('specifications', ['mergeActiveQuestion', 'replaceActiveQuestion']),
    handlePinCountChange (val) {
      if (!this.dropDownBoolean) { return }

      let cb = () => { this.dropDownBoolean = true }
      val = Number(val)
      if (val > this.options.length) {
        this.dropDownBoolean = false
        let n = val - this.options.length
        this.createNewOptions(n, cb)
      }
      if (this.options.length > val) {
        this.dropDownBoolean = false
        let deleteArray = this.options.slice(val, this.options.length).map(option => option.optionId)
        deleteArray.forEach(id => {
          this.deleteLastOption(id, cb)
        })
      }
    },
    deleteMedia () {
      this.mergeActiveQuestion({
        mediaUrl: null,
        media: null,
        complete: false
      })
    }
  }
}
</script>
