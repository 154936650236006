<template>
  <st-accordion ref="accordion">
    <template v-slot:visible-section>
      <div class="st-virtual-tour-specs__room-accordion-visible-row">
        <div class="st-virtual-tour-specs__room-name-row">
          <icon-wrapper
            icon-name="virtual-room"
            class="st-virtual-tour-specs__accordion-row-icon"
          />
          <div class="st-accordion__row-name st-virtual-tour-specs__room-name">
            {{ room.name }}
          </div>
          <div
            v-if="!isPreview && isDesktop"
            class="st-virtual-tour-specs__hover-icon st-accordion__hover-icon"
            @click.stop="$emit('open-edit-room-modal',room.id)"
          >
            <icon-wrapper icon-name="edit" />
          </div>
          <div
            v-if="!isPreview && isDesktop"
            class="st-virtual-tour-specs__hover-icon st-accordion__hover-icon"
            @click.stop="$emit('open-delete-room-modal', room.id)"
          >
            <icon-wrapper icon-name="trash" />
          </div>
          <p
            v-if="activeRoom && room.id === activeRoom.id"
            class="st-virtual-tour-specs__preview-text"
          >
            (Currently Previewing)
          </p>
        </div>
        <div class="st-virtual-tour-specs__viewpoints-count">
          {{ summaryText }}
        </div>
      </div>
    </template>
    <template v-slot:expanded-section>
      <div
        v-for="(viewpoint, i) in room.panoramas"
        :key="'pano-'+ viewpoint.id"
        class="st-virtual-tour-specs__viewpoint-container"
      >
        <div class="st-virtual-tour-specs__viewpoint-row">
          <div
            class="st-virtual-tour-specs__viewpoint-name"
          >
            <div
              :class="{
                'st-virtual-tour-specs__viewpoint-bullet': true,
                'st-virtual-tour-specs__viewpoint-bullet--active': viewpoint.editPrompt
              }"
            />
            <div>Viewpoint {{ i + 1 }}</div>
            <p
              v-if="activeRoom && viewpoint.identifier === activeViewpointIdentifier"
              class="st-virtual-tour-specs__preview-text"
            >
              (Currently Previewing)
            </p>
          </div>
          <st-tooltip
            v-if="!viewpoint.prompt && !viewpoint.editPrompt"
            tip="Add Prompts to encourage participants to speak out loud. This will help you get better insights. We recommend having at least 1 prompt per room."
            :allow-wrap="true"
            direction="bottom"
          >
            <template v-slot:tip>
              <div>
                Add Prompts to encourage participants to speak out loud. This will help you get better insights.
                <br>
                <br>
                We recommend having at least 1 prompt per room.
              </div>
            </template>
            <template v-slot:default>
              <div
                class="st-virtual-tour-specs__edit-prompt"
                :class="{'st-new-question__preview-cursor': isPreview}"
                @click="editPrompt(i, viewpoint.identifier, '', false)"
              >
                <div>Add Prompt</div>
                <icon-wrapper
                  icon-name="add-prompt"
                  class="st-virtual-tour-specs__edit-icon"
                />
              </div>
            </template>
          </st-tooltip>
        </div>
        <div v-if="viewpoint.editPrompt">
          <st-input
            :ref="'promptInput' + i"
            v-model="prompt"
            :placeholder="`Your ${promptText} goes here`"
            class="st-virtual-tour-specs__edit-prompt-input"
            :label="`${room.name} Viewpoint #${i+1} ${promptText}`"
            @focus="updateActiveRoom(viewpoint.identifier)"
            @input="promptChange($event, roomIndex, i, activePromptToggle)"
          />
          <div class="st-virtual-tour-specs__topic-toggle-row">
            <st-toggle
              class="st-virtual-tour-specs__topic-toggle"
              first-option="Prompt"
              second-option="Topic"
              :active-option="activePromptToggle"
              @updateOption="updatePromptToggle($event, i)"
            />
            <st-tooltip tip="Topics will show up more prominently to participants" />
          </div>
          <div class="st-virtual-tour-specs__prompt-actions">
            <div
              class="st-virtual-tour-specs__prompt-action st-virtual-tour-specs__prompt-action--cancel"
              @click="$emit('cancel-edit-prompt', roomIndex, i, prompt, activePromptToggle)"
            >
              Cancel
            </div>
            <div
              class="st-virtual-tour-specs__prompt-action"
              @click="$emit('submit-edit-prompt', roomIndex,i, prompt, activePromptToggle)"
            >
              Submit
            </div>
          </div>
        </div>
        <template v-else>
          <div
            v-if="viewpoint.prompt"
            class="st-virtual-tour-specs__prompt-row"
          >
            <div><strong>{{ viewpoint.topic ? 'Topic' : 'Prompt' }}</strong> "{{ viewpoint.prompt }}"</div>
            <div class="st-virtual-tour-specs__viewpoint-name">
              <div
                v-if="!isPreview"
                @click="editPrompt(i, viewpoint.identifier, viewpoint.prompt, viewpoint.topic)"
              >
                <icon-wrapper
                  icon-name="edit"
                  class="st-virtual-tour-specs__edit-icon"
                />
              </div>
              <div
                v-if="!isPreview"
                @click="$emit('open-delete-prompt-modal', roomIndex, i)"
              >
                <icon-wrapper
                  icon-name="trash"
                  class="st-virtual-tour-specs__edit-icon"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </st-accordion>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { isDesktop } from '../../../../mixins'
import { IconWrapper, StAccordion, StInput, StToggle, StTooltip } from '../../../common'

export default {
  components: {
    IconWrapper,
    StAccordion,
    StInput,
    StToggle,
    StTooltip
  },
  mixins: [isDesktop],
  props: {
    isPreview: {
      type: Boolean,
      default: false,
      required: false
    },
    room: {
      type: Object,
      required: true
    },
    roomIndex: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      prompt: '',
      activePromptToggle: 'Prompt'
    }
  },
  computed: {
    ...mapState('specifications', ['activeViewpointIdentifier']),
    ...mapState('participants', ['activeQuestion']),
    activeRoom () {
      return this.activeQuestion.rooms
        ? this.activeQuestion.rooms.find(room => {
          return room.panoramas.find(pano => { return pano.identifier === this.activeViewpointIdentifier })
        })
        : []
    },
    promptText () {
      return this.activePromptToggle === 'Prompt' ? 'prompt ' : 'topic'
    },
    promptCount () {
      const prompts = this.room.panoramas
        .filter(pano => pano.prompt)
        .filter(pano => !pano.topic)
        .map(pano => pano.prompt)
      return new Set(prompts).size
    },
    topicCount () {
      const topics = this.room.panoramas
        .filter(pano => pano.prompt)
        .filter(pano => pano.topic)
        .map(pano => pano.prompt)
      return new Set(topics).size
    },
    summaryText () {
      return [
        [this.topicCount, 'topic'],
        [this.promptCount, 'prompt'],
        [this.room.panoramas.length, 'viewpoint']
      ]
        .map(([count, text]) => `${count} ${count === 1 ? text : text + 's'}`)
        .filter(([count, _], idx) => idx > 1 || count > 0)
        .join(', ')
    }
  },
  methods: {
    ...mapMutations('specifications', ['moveVirtualTourPreview', 'setActiveViewpointIdentifier']),
    promptChange (value, roomIndex, i, activePromptToggle) {
      this.$emit('prompt-handler', roomIndex, i, value, activePromptToggle)
    },
    editPrompt (i, identifier, prompt, topicBoolean) {
      if (this.isPreview) { return }

      this.$emit('edit-prompt', this.roomIndex, i, identifier)
      this.prompt = prompt
      this.activePromptToggle = topicBoolean ? 'Topic' : 'Prompt'
      this.$nextTick(() => {
        this.$refs[`promptInput${i}`][0].$refs.stInput.focus()
      })
    },
    updateActiveRoom (identifier) {
      if (identifier === this.activeViewpointIdentifier) { return }
      this.moveVirtualTourPreview(identifier)
      this.setActiveViewpointIdentifier(identifier)
    },
    updatePromptToggle (option, i) {
      this.activePromptToggle = option
      this.$emit('prompt-handler', this.roomIndex, i, this.prompt, this.activePromptToggle)
    }
  }
}
</script>
