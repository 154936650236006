<template>
  <div class="icon_questiondescriptiontoggle">
    <div class="icon_questiondescriptiontoggle__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 11)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 4)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 13)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 6)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
        <path d="M0 0h5v1H0z" />
        <path
          d="M0 0h5v1H0z"
          transform="translate(0 9)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
