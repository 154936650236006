<template>
  <div class="icon_keyboard">
    <div class="icon_keyboard__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 7)"
        />
        <path
          d="M0 0h12v1H0z"
          transform="translate(2 13)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(8 5)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(2 9)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(4 9)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(6 9)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(8 9)"
        />
        <path
          d="M0 0h1v2.794H0z"
          transform="translate(8 5.206)"
        />
        <path
          d="M0 0h1v6H0z"
          transform="translate(10)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(10 9)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(12 9)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(12 11)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(10 11)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(8 11)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(6 11)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(4 11)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(2 11)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M0 0h1v9H0z"
          transform="translate(0 7)"
        />
        <path
          d="M0 0h1v9H0z"
          transform="translate(15 7)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
