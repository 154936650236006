<template>
  <div
    :id="id"
    class="ds-drag-element"
    :style="style"
    @mousedown="handleMouseDown"
    @touchstart="handleTouchStart"
  >
    <template v-if="sliderX">
      <div class="slider-text">
        <slot />
      </div>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    sliderX: {
      default: false,
      type: Boolean,
      required: false
    },
    sliderY: {
      default: false,
      type: Boolean,
      required: false
    },
    initialTop: {
      default: 0,
      type: Number,
      required: false
    },
    initialLeft: {
      default: 0,
      type: Number,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    maxX: {
      default: 300,
      type: Number,
      required: false
    },
    minX: {
      default: 0,
      type: Number,
      required: false
    },
    maxY: {
      default: 300,
      type: Number,
      required: false
    },
    minY: {
      default: 0,
      type: Number,
      required: false
    },
    initialRadius: {
      default: 25,
      type: Number,
      required: false
    },
    resizable: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  data: function () {
    return {
      initialPosition: true,
      dragging: false,
      x: this.initialLeft,
      y: this.initialTop,
      cursorOffsetX: 0,
      cursorOffsetY: 0,
      activeTouch: 0,
      radius: this.initialRadius,
      startingDistance: null,
      saveDistance: true
    }
  },
  computed: {
    position () {
      return 'fixed'
    },
    top () {
      return (this.y - this.radius) + 'px'
    },
    left () {
      return (this.x - this.radius) + 'px'
    },
    height () {
      return this.radius * 2 + 'px'
    },
    width () {
      return this.radius * 2 + 'px'
    },
    positionData () {
      let data = {
        id: this.id,
        x: this.x,
        y: this.y,
        placed: true
      }
      return data
    },
    fontSize () {
      return (this.radius / 2) + 'px;'
    },
    style () {
      return {
        top: this.top,
        left: this.left,
        height: this.height,
        width: this.width,
        fontSize: this.fontSize
      }
    }
  },
  methods: {
    updateX (xVal) {
      this.x = xVal
    },
    updateY (yVal) {
      this.y = yVal
    },
    clamp (val, min, max) {
      if (val < min) {
        this.saveDistance = true
        return min
      } else if (val > max) {
        this.saveDistance = true
        return max
      } else {
        return val
      }
    },
    clampIncludingRadius (val, min, max) {
      return this.clamp(val, min + this.radius, max - this.radius)
    },
    dragStart (clientX, clientY, activeTouch = null) {
      this.dragging = true
      this.activeTouch = activeTouch
      this.cursorOffsetX = clientX - this.x
      this.cursorOffsetY = clientY - this.y
      this.$emit('startingPosition', this.positionData)
      if (activeTouch != null) {
        document.addEventListener('touchmove', this.dragMove)
        document.addEventListener('touchend', this.dragEnd)
      } else {
        document.addEventListener('mousemove', this.dragMove)
        document.addEventListener('mouseup', this.dragEnd)
      }
    },
    dragMove (ev) {
      let pos = ev
      if (this.activeTouch != null) {
        pos = ev.changedTouches[0]
      }
      let x = pos.clientX - this.cursorOffsetX
      let y = pos.clientY - this.cursorOffsetY

      if (this.sliderY) {
        this.x = this.maxX + this.radius
        this.$emit('endPosition', this.positionData)
      } else {
        this.x = this.clampIncludingRadius(x, this.minX, this.maxX)
      }
      if (this.sliderX) {
        this.y = this.maxY + this.radius
        this.$emit('endPosition', this.positionData)
        this.$emit('moving', this.positionData)
      } else {
        this.y = this.clampIncludingRadius(y, this.minY, this.maxY)
      }
    },
    dragEnd () {
      this.dragging = false
      this.$emit('endPosition', this.positionData)
      if (this.activeTouch != null) {
        document.removeEventListener('touchmove', this.dragMove)
        document.removeEventListener('touchend', this.dragEnd)
        this.activeTouch = 0
      } else {
        document.removeEventListener('mousemove', this.dragMove)
        document.removeEventListener('mouseup', this.dragEnd)
      }
    },
    handleTouchStart (ev) {
      // for movement, ignore all but the first touch
      ev.preventDefault()
      ev.stopPropagation()
      let t = ev.changedTouches[0]
      this.dragStart(t.clientX, t.clientY, t)
      this.saveDistance = true
      document.addEventListener('touchstart', this.handlePinchStart)
    },
    handleTouchEnd (ev) {
      ev.preventDefault()
      ev.stopPropagation()
      this.dragEnd()
    },
    handleMouseDown (ev) {
      ev.preventDefault()
      this.initialPosition = false
      this.dragStart(ev.clientX, ev.clientY)
    },
    handleMouseUp (ev) {
      this.dragEnd()
    },
    handlePinchStart (ev) {
      if (ev.touches.length === 2) {
        this.dragging = false
        document.removeEventListener('touchmove', this.dragMove)
        if (this.saveDistance) {
          this.startingDistance = Math.hypot(
            ev.touches[0].pageX - ev.touches[1].pageX,
            ev.touches[0].pageY - ev.touches[1].pageY)
        }
        this.saveDistance = false
        document.addEventListener('touchmove', this.resize)
        document.addEventListener('touchend', this.handlePinchEnd)
      }
    },
    handlePinchEnd () {
      document.removeEventListener('touchmove', this.resize)
      document.removeEventListener('touchstart', this.handlePinchStart)
      this.saveDistance = true
      this.$emit('endSize', this.radius)
    },
    resize (ev) {
      let currentRadius = this.radius
      let dist = Math.hypot(
        ev.touches[0].pageX - ev.touches[1].pageX,
        ev.touches[0].pageY - ev.touches[1].pageY)
      let scale = dist / this.startingDistance
      this.radius = this.clamp(scale * currentRadius, 15, 100)
    }
  }
}
</script>
