<template>
  <div class="icon_publish">
    <div class="icon_publish__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M1 0v1.958h4.629V0h1v2.958H0V0z"
            transform="rotate(45 -10.027 7.249)"
          />
          <path
            d="M2.482.8L1 1.9v1.943l.756-.578.607.795L0 5.865V1.4L1.887 0z"
            transform="rotate(45 -1.403 6.446)"
          />
          <path
            d="M2.111 6.054L0 4.349V0l2.548 2.131-.442.924L1 2.134v1.738l1.739 1.4z"
            transform="rotate(-135 7.927 6.09)"
          />
          <path
            d="M5 2.5A2.5 2.5 0 112.5 0 2.5 2.5 0 015 2.5zm-1 0A1.5 1.5 0 102.5 4 1.5 1.5 0 004 2.5z"
            transform="translate(7.052 3.976)"
          />
          <path
            d="M-23402.031-95.957a20.224 20.224 0 01-.742 3.745c-1.346 4.7-3.8 8.687-8.08 11.136l-5.965-5.931c2.393-4.338 6.334-6.848 11.053-8.211a20.716 20.716 0 013.109-.669zm-13.537 8.776l4.875 4.85c4.176-2.566 6.646-7.654 7.494-12.459-4.783.841-9.846 3.341-12.369 7.609z"
            transform="translate(23418.031 95.957)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
