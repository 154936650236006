<template>
  <div class="icon_info">
    <div class="icon_info__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-1 0a7 7 0 10-7 7 7 7 0 007-7z" />
        <path
          d="M1 .5V1H0V0h1z"
          transform="rotate(180 4.168 2)"
        />
        <path
          d="M1 3.744v3.743H0V0h1z"
          transform="rotate(180 4.168 6.5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
