<template>
  <div class="st-concept-ranking-insights">
    <template v-if="hasMedia">
      <div class="st-concept-ranking-insights__media-container">
        <video
          v-if="isMediaVideo(activeQuestion.options[0])"
          controls
          class="st-concept-ranking-insights__image"
        >
          <source
            :src="activeQuestion.options[0].mediaUrl"
            :type="mediaContentType"
          >
          <source
            :src="activeQuestion.options[0].mediaUrl"
          >
          Your browser does not support this video
        </video>
        <img
          v-else
          class="st-concept-ranking-insights__image"
          :src="activeQuestion.options[0].mediaFullUrl"
        >
        <div
          v-if="activeQuestion.options.length>1"
          class="st-opinion-metric-insights__multi-option-open-images st-concept-ranking-insights__open-images"
          @click="showAllImages = true"
        >
          {{ openMediaText }}
        </div>
      </div>
      <div
        v-if="hasMedia"
        class="st-concept-ranking-insights__overall-results"
      >
        <div class="st-concept-ranking-insights__overall-results-sentence">
          <div>{{ sentimentText1 }}</div>
          <icon-wrapper
            v-if="activeQuestion.scaleStyle === 'emoji'"
            class="st-concept-ranking-insights__emoji"
            :icon-name="overallSentimentString.iconName"
          />
          <div
            v-else
            class="st-concept-ranking-insights__overall-sentiment"
          >
            <template v-if="activeQuestion.scaleStyle === 'agree_disagree'">
              {{ overallSentimentString }}
            </template>
            <template v-else>
              {{ overallSentimentValue }}
            </template>
          </div>
          <icon-wrapper
            v-if="activeQuestion.scaleStyle === 'stars'"
            icon-name="star-filled"
          />
          <div>{{ sentimentText2 }}</div>
        </div>
      </div>
    </template>
    <vertical-bar-graph
      :bucket-names="bucketNames"
      :bucket-counts="bucketCounts"
      :bucket-keys="bucketIndexes"
      :response-count="responseValues.length"
      :y-axis-label="yAxisLabel"
      :y-axis-increments="yAxisIncrements"
    />
    <simple-data-table
      class="st-concept-ranking-insights__table"
      :headers="tableHeaders"
      :rows="tableRows"
    />
    <!-- overall category text bar fixed to bottom of screen on scroll-->
    <div
      v-if="hasMedia"
      class="st-concept-ranking-insights__overall-results-bar"
      :class="{
        'st-concept-ranking-insights__overall-results-bar--panel-open': activePanelSection
      }"
    >
      <div class="st-concept-ranking-insights__overall-results-sentence">
        <div>{{ sentimentText1 }}</div>
        <icon-wrapper
          v-if="activeQuestion.scaleStyle === 'emoji'"
          class="st-concept-ranking-insights__emoji"
          :icon-name="overallSentimentString.iconName"
        />
        <div
          v-else
          class="st-concept-ranking-insights__overall-sentiment"
        >
          <template v-if="activeQuestion.scaleStyle === 'agree_disagree'">
            {{ overallSentimentString }}
          </template>
          <template v-else>
            {{ overallSentimentValue }}
          </template>
        </div>
        <icon-wrapper
          v-if="activeQuestion.scaleStyle === 'stars'"
          icon-name="star-filled"
        />
        <div>{{ sentimentText2 }}</div>
      </div>
    </div>
    <template v-if="showAllImages">
      <div class="st-opinion-metric-insights__images-backdrop" />
      <div
        class="st-opinion-metric-insights__images-container"
        :style="marginStyle"
      >
        <template
          v-for="media in activeQuestion.options"
        >
          <video
            v-if="isMediaVideo(media)"
            :key="media.id"
            class="st-opinion-metric-insights__image-large"
            :style="mediaStyle"
            controls
          >
            <source
              :src="media.mediaFullUrl"
              :type="media.media.contentType"
            >
            <source
              :src="media.mediaFullUrl"
            >
            Your browser does not support this video
          </video>
          <img
            v-else
            :key="media.id"
            class="st-opinion-metric-insights__image-large"
            :src="media.mediaFullUrl"
            :style="mediaStyle"
          >
        </template>
      </div>
      <div
        @click="showAllImages = false"
      >
        <icon-wrapper
          icon-name="cancel"
          class="st-opinion-metric-insights__exit-lightbox"
          :invert="zoomLevel<=90"
        />
      </div>
      <div
        class="st-opinion-metric-insights__lightbox-plus-box"
        @click="zoomIn"
      >
        <icon-wrapper
          icon-name="plus"
        />
      </div>
      <div
        class="st-opinion-metric-insights__lightbox-minus-box"
        @click="zoomOut"
      >
        <icon-wrapper
          icon-name="minus"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { IconWrapper } from '../../../common'
import SimpleDataTable from '../simple-data-table'
import VerticalBarGraph from '../vertical-bar-graph'
import { average, bucketIdx } from '../../../../utilities/data_utilities'

const SENTIMENTS = {
  agree_disagree: [
    'Strongly Disagrees',
    'Disagrees',
    'Neutral',
    'Agrees',
    'Strongly Agrees'
  ],
  stars: [ 1, 2, 3, 4, 5 ],
  numeric: [ 1, 2, 3, 4, 5 ],
  emoji: [
    { iconName: 'emoji-hate-it' },
    { iconName: 'emoji-dislike' },
    { iconName: 'emoji-indifferent' },
    { iconName: 'emoji-like-it' },
    { iconName: 'emoji-love-it' }
  ]
}

const SENTIMENTS_3 = {
  agree_disagree: [
    'Disagrees',
    'Neutral',
    'Agrees'
  ],
  stars: [ 1, 2, 3 ],
  numeric: [ 1, 2, 3 ],
  emoji: [
    { iconName: 'emoji-hate-it' },
    { iconName: 'emoji-indifferent' },
    { iconName: 'emoji-like-it' }
  ]
}

export default {
  components: {
    IconWrapper,
    SimpleDataTable,
    VerticalBarGraph
  },
  props: {
  },
  data: function () {
    return {
      showAllImages: false,
      zoomLevel: 80
    }
  },
  computed: {
    ...mapState('insights', ['activePanelSection', 'activeQuestionModifier']),
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingParticipantCheckboxFilter']),
    bucketCounts () {
      let countObj = {
        0.2: 0,
        0.4: 0,
        0.6: 0,
        0.8: 0,
        1: 0
      }
      if (this.activeQuestion.scaleLength === 3) {
        countObj = {
          0.3: 0,
          0.6: 0,
          1: 0
        }
      }
      this.responseValues.forEach(value => {
        let roundedVal = Math.floor(value * 10) / 10
        countObj[roundedVal] += 1
      })
      return countObj
    },
    bucketIndexes () {
      let keys = Object.keys(this.bucketCounts)
      return keys.map(key => Number(key)).sort((a, b) => {
        return a - b
      })
    },
    bucketNames () {
      return this.sentimentsLookup
    },
    hasMedia () {
      return this.activeQuestion.options.length > 0
    },
    openMediaText () {
      return `Show Media (${this.activeQuestion.options.length})`
    },
    overallSentiment () {
      return average(this.responseValues)
    },
    overallSentimentValue () {
      return (Math.floor(this.overallSentiment * 100) / 100) * this.activeQuestion.scaleLength
    },
    overallSentimentString () {
      let minScore = 0.2
      if (this.activeQuestion.scaleLength === 3) { minScore = 0.3 }
      return this.sentimentsLookup[bucketIdx(this.overallSentiment, { minScore: minScore, bucketCount: this.activeQuestion.scaleLength, maxScore: 1.00000000001 })]
    },
    responseValues () {
      return this.responsesPassingParticipantCheckboxFilter.map(response => {
        return response.value
      })
    },
    tableHeaders () {
      return ['Scale', 'Votes']
    },
    tableRows () {
      let rows = [
        [this.sentimentsLookup[0], this.bucketCounts[0.2]],
        [this.sentimentsLookup[1], this.bucketCounts[0.4]],
        [this.sentimentsLookup[2], this.bucketCounts[0.6]],
        [this.sentimentsLookup[3], this.bucketCounts[0.8]],
        [this.sentimentsLookup[4], this.bucketCounts[1]]
      ]
      if (this.activeQuestion.scaleLength === 3) {
        rows = [
          [this.sentimentsLookup[0], this.bucketCounts[0.3]],
          [this.sentimentsLookup[1], this.bucketCounts[0.6]],
          [this.sentimentsLookup[2], this.bucketCounts[1]]
        ]
      }
      return rows.sort((a, b) => b[1] - a[1])
    },
    yAxisIncrements () {
      if (this.activeQuestionModifier === 'votes') {
        let counts = []
        counts.push(this.responseValues.length)
        counts.push(this.responseValues.length / 2)
        counts.push(0)
        return counts
      } else {
        return ['100', '50', '0']
      }
    },
    yAxisLabel () {
      if (this.activeQuestionModifier === 'votes') {
        return 'Votes'
      } else {
        return 'Percent'
      }
    },
    sentimentsLookup () {
      if (this.activeQuestion.scaleLength === 3) {
        return SENTIMENTS_3[this.activeQuestion.scaleStyle]
      } else {
        return SENTIMENTS[this.activeQuestion.scaleStyle]
      }
    },
    sentimentText1 () {
      let text = 'This audience'
      if (this.activeQuestion.scaleStyle === 'agree_disagree' && this.overallSentimentString === 'Neutral') {
        text = 'This audience feels'
      } else if (this.activeQuestion.scaleStyle === 'numeric' || this.activeQuestion.scaleStyle === 'stars') {
        text = 'This audience voted'
      }
      return text
    },
    sentimentText2 () {
      let text = 'with this'
      let mediaText = 'image'
      if (this.isMediaVideo(this.activeQuestion.options[0])) {
        mediaText = 'video'
      }
      if (this.activeQuestion.scaleStyle === 'agree_disagree' && this.overallSentimentString === 'Neutral') {
        text = 'about this'
      } else if (this.activeQuestion.scaleStyle === 'emoji') {
        text = 'this'
      }
      if (this.activeQuestion.options && this.activeQuestion.options.length > 1) {
        mediaText = 'media'
      }
      let mediaTextString = text + ' ' + mediaText
      if (this.activeQuestion.scaleStyle === 'numeric' || this.activeQuestion.scaleStyle === 'stars') {
        mediaTextString = ''
      }
      return mediaTextString
    }
  },
  methods: {
    isMediaVideo (option) {
      return (option.media.contentType.split('/')[0] || '') === 'video'
    },
    zoomIn () {
      this.zoomLevel += 10
    },
    zoomOut () {
      this.zoomLevel -= 10
    }
  }
}
</script>
