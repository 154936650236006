<script>
export default {
  data: function () {
    return {
      selectedId: null
    }
  },
  methods: {
    handleSelect: function (id) {
      if (id === this.selectedId) {
        this.selectedId = null
      } else {
        this.selectedId = id
      }
    }
  }
}

</script>
