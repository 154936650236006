<template>
  <div class="icon_slide">
    <div class="icon_slide__svg">
      <svg
        id="svg"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          id="Path_48181"
          data-name="Path 48181"
          d="M0,0H20V1.25H0Z"
        />
        <path
          id="Path_48184"
          data-name="Path 48184"
          d="M0,0H17.5V1.25H0Z"
          transform="translate(1.25 16.25)"
        />
        <path
          id="Path_48185"
          data-name="Path 48185"
          d="M0,0H3.75V1.25H0Z"
          transform="translate(1.25 18.75)"
        />
        <path
          id="Path_48192"
          data-name="Path 48192"
          d="M0,0H3.75V1.25H0Z"
          transform="translate(15 18.75)"
        />
        <path
          id="Path_48191"
          data-name="Path 48191"
          d="M0,0H1.25V17.5H0Z"
          transform="translate(1.25)"
        />
        <path
          id="Path_48186"
          data-name="Path 48186"
          d="M0,0H1.25V3.75H0Z"
          transform="translate(3.75 16.25)"
        />
        <path
          id="Path_48188"
          data-name="Path 48188"
          d="M0,0H1.25V17.5H0Z"
          transform="translate(17.5)"
        />
        <path
          id="Path_48189"
          data-name="Path 48189"
          d="M0,0H1.25V3.75H0Z"
          transform="translate(15 16.25)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
