<template>
  <div
    class="container"
  >
    <span v-if="activeQuestionModifier === 'votes'">
      {{ votes }} Votes
    </span>
    <span v-else>
      {{ parseInt((votes / (total || 1)) * 100, 10) }} Percent
    </span>
    <div
      class="value-bar"
      :style="{
        width: `${(votes / (total || 1)) * 100}%`,
        backgroundColor: color
      }"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('insights', ['activeQuestionModifier'])
  },
  props: {
    votes: {
      type: Number,
      required: true,
      default: 0
    },
    color: {
      type: String,
      required: true,
      default: '#000000'
    },
    total: {
      type: Number,
      required: true,
      default: 1
    }
  }
}

</script>

<style scoped>
.container {
	padding: 15px 25px;
	position: relative;
	margin-top: 5px;
}
.value-bar {
	position: absolute;
	height: 100%;
	top: 0px;
	left: 0px;
	opacity: 0.4;
	width: 0px;
}
</style>
