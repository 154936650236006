import { render, staticRenderFns } from "./st-emoji-scale.vue?vue&type=template&id=761edd67&"
import script from "./st-emoji-scale.vue?vue&type=script&lang=js&"
export * from "./st-emoji-scale.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports