<template>
  <div
    class="icon_insights"
  >
    <div class="icon_insights__svg">
      <svg
        ref="svg"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 2V0h6v2a1 1 0 01-1 1H1a1 1 0 01-1-1zm5 0V1H1v1z"
            transform="translate(4.954 11)"
          />
          <path
            d="M0 1V0h4v1a2 2 0 01-4 0zm1.065.357A1 1 0 003 1H1a1 1 0 00.065.357z"
            transform="translate(5.954 13)"
          />
          <path
            d="M-23362.951-84.9l8.07-6.779.6 3.125 6.582-7.453.752.66-7.936 8.987-.67-3.508-6.773 5.751z"
            transform="translate(23362.951 96.015)"
          />
          <path
            d="M-23358.58-94.787a5.9 5.9 0 017.215.008l-.635.779a5.028 5.028 0 00-4.2-.873 5.38 5.38 0 00-3.576 3.293 4.912 4.912 0 00-.016 2.943l-.961.282a5.758 5.758 0 012.173-6.432z"
            transform="translate(23362.951 96.015)"
          />
          <path
            d="M2.413 1.225a5.908 5.908 0 017.215.007l-.633.773a5.034 5.034 0 00-4.2-.871 5.379 5.379 0 00-3.574 3.288 4.9 4.9 0 00-.016 2.938l-.961.281a5.744 5.744 0 012.169-6.416z"
            transform="rotate(-174.87 6.97 5.911)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
