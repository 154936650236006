<template>
  <div class="icon_virtual-room">
    <div class="icon_virtual-room__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h8.52v10.086L0 12.272zm7.521 9.3V1.009H1v9.967z" />
        <path
          d="M0 0h8.52v12.272L0 10.086zm7.521 10.975V1.009H1V9.3z"
          transform="translate(7.455)"
        />
        <path
          d="M-23260.275-35.712l7.566 3.535 7.564-3.535.422.906-7.986 3.733-7.988-3.733z"
          transform="translate(23260.723 47.073)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
