<template>
  <div class="icon_paperplane">
    <div class="icon_paperplane__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-21138.152-10182.248l-9.834-10.165 16-5.768zm4.453-14.262l-12.508 4.508 7.689 7.945z"
          transform="translate(21147.986 10198.181)"
        />
        <path
          d="M10.8.9L.246 9.6l-.636-.772L10.164.133z"
          transform="translate(4.205 .681)"
        />
        <path
          d="M2.468 7.227l-3.425 3.136V3.535h1v4.557l1.75-1.6z"
          transform="translate(5.05 5.637)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
