<template>
  <div class="icon_csvfile">
    <div class="icon_csvfile__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 0h9.881v1H0z"
            transform="translate(5.995 15)"
          />
          <path
            d="M0 0h8v1H0z"
            transform="translate(3.989)"
          />
          <path
            d="M0 0h1v12H0z"
            transform="translate(14.989 4)"
          />
          <path
            d="M0 0h1v6H0z"
            transform="translate(3.989)"
          />
          <path
            d="M0 0h1v5H0z"
            transform="translate(10.991)"
          />
          <path
            d="M0 0h5v1H0z"
            transform="translate(10.991 4)"
          />
          <path
            d="M-21146.123-10161.846l4 4-.707.707-4-4z"
            transform="translate(21158.121 10161.853)"
          />
          <path
            d="M2.516-5.5a1.948 1.948 0 01-1.325-.39 1.483 1.483 0 01-.44-1.17v-1.666a1.512 1.512 0 01.443-1.188 1.94 1.94 0 011.328-.4 1.844 1.844 0 011.3.393 1.823 1.823 0 01.455 1.279l-1 .059a1.476 1.476 0 00-.2-.762.7.7 0 00-.572-.194.744.744 0 00-.592.205 1.067 1.067 0 00-.176.692v1.5a1.008 1.008 0 00.176.669.757.757 0 00.592.2.715.715 0 00.569-.188 1.388 1.388 0 00.2-.745l1 .059a1.782 1.782 0 01-.455 1.261 1.872 1.872 0 01-1.303.386zm4.035-.006a3.062 3.062 0 01-.994-.15 2.667 2.667 0 01-.783-.419l.311-.733a2.732 2.732 0 00.672.387 2.19 2.19 0 00.812.141.869.869 0 00.557-.152.5.5 0 00.188-.4.446.446 0 00-.205-.416 3.038 3.038 0 00-.739-.258 2.566 2.566 0 01-1.141-.5 1.167 1.167 0 01-.32-.886 1.316 1.316 0 01.416-1.006 1.7 1.7 0 011.2-.39 2.592 2.592 0 01.88.155 2.34 2.34 0 01.715.39l-.27.7a2.929 2.929 0 00-.639-.346 1.875 1.875 0 00-.674-.123.768.768 0 00-.475.126.447.447 0 00-.164.378.455.455 0 00.191.387 2.5 2.5 0 00.783.287 2.379 2.379 0 011.12.5 1.237 1.237 0 01.317.918 1.232 1.232 0 01-.487 1.047 2.061 2.061 0 01-1.271.363zm4.756-.053h-1.272L8.727-10.25h1l.938 3.888.985-3.888h.962z"
            transform="translate(-.751 18.309)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
