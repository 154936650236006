var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"cv-overflow-menu-item bx--overflow-menu-options__option st-overflow-menu__option",class:{
    'bx--overflow-menu-options__option--disabled': _vm.disabled
  },on:{"mouseenter":function($event){return _vm.onHover(true)},"mouseleave":function($event){return _vm.onHover(false)}}},[_c(_vm.path ? 'a' : 'button',_vm._g({tag:"component",staticClass:"bx--overflow-menu-options__btn",attrs:{"href":_vm.path,"disabled":_vm.disabled},on:{"mousedown":_vm.onMousedown,"mouseup":_vm.onMouseup,"touch-start":_vm.onMousedown,"focusout":_vm.onFocusOut,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();return _vm.onEsc.apply(null, arguments)}}},_vm.$listeners),[_c('div',{staticClass:"st-overflow-menu__item"},[_vm._t("custom-icon",function(){return [(_vm.iconName.length>0)?_c('icon-wrapper',{class:{
            'st-overflow-menu__icon': true,
            'st-overflow-menu__icon--hover': _vm.hover,
            'st-overflow-menu__icon--clicked': _vm.parentClicked && _vm.hover
          },attrs:{"icon-name":_vm.iconName}}):_vm._e()]},{"hover":_vm.hover}),_vm._v(" "),_c('div',{class:{
          'st-overflow-menu__item-text': true,
          'st-overflow-menu__item-text--hover': _vm.hover,
          'st-overflow-menu__item-text--clicked': _vm.parentClicked && _vm.hover
        }},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }