<template>
  <div class="bx--col-sm-12">
    <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-dropdown-button
          caption="Manage Media"
          icon-name="caret-down"
          :show-action="true"
          :options="dropdownOptions"
          @updateOption="handleDropdownOption"
        />
      </div>
    </div>
    <div class="bx--row st-page-layout">
      <div class="st-media-library__title-row">
        <h1 class="st-media-library__title">
          Media
        </h1>
        <st-dropdown-button
          class="page-dropdown-button"
          caption="Manage Media"
          icon-name="caret-down"
          :show-action="true"
          :options="dropdownOptions"
          @updateOption="handleDropdownOption"
        />
      </div>
      <div class="st-media-library__filter-row bx--col-sm-8">
        <!-- <st-input
        v-model="mediaLookup"
        class="st-input--default st-media-library__media-lookup"
        :show-icon="true"
        :show-label="false"
        label="Media Lookup"
        label-in-field-class="st-input--hide-mobile-label"
      /> -->
        <div class="st-member-groups__dropdown-container">
        <!-- <st-dropdown-menu
          caption="Member Filter"
          :initial-active-option="'All Media'"
          :options="['All Media', 'Spacetrics Media', 'My Media', 'Team Media', 'Newest First', 'Oldest First']"
          @updateOption="setFilterOption"
        /> -->
        </div>
      </div>

      <div class="bx--col-sm-9">
        <st-accordion
          v-if="recentAssetsFolder.length>0"
          visible-row-class="st-media-library__visible-row"
        >
          <template v-slot:visible-section="stAccordionSlotProps">
            <div class="st-media-library__accordion-row">
              <icon-wrapper
                icon-name="clock"
                class="st-accordion__section-icon"
              />
              <div class="st-accordion__group-name">
                Recently Uploaded
              </div>
            </div>
          </template>
          <template v-slot:expanded-section>
            <div class="st-media-library__group-expanded-section">
              <div
                v-for="(asset, n) in recentAssetsFolder"
                :key="'recent-asset-'+ asset.id"
              >
                <st-card
                  class="st-media-library__asset-card"
                  :url="asset.mediaUrl"
                >
                  <template v-slot:card-content="slotProps">
                    <video
                      v-if="isVideo(asset)"
                      class="st-media-library__asset-card-preview"
                    >
                      >
                      <source
                        :src="asset.mediaUrl"
                        type="video/mp4"
                      > Your browser does not support the video tag.
                    </video>
                    <div
                      v-else
                      class="st-media-library__asset-card-preview"
                      :style="{
                        background: `url('${asset.mediaUrl}') center / contain no-repeat`
                      }"
                    />
                    <div
                      :class="{
                        'st-media-library__asset-name': true,
                        'st-media-library__asset-name--hover': slotProps.hover
                      }"
                    >
                      {{ asset.filename }}
                    </div>
                    <div class="st-media-library__asset-card-bottom-row">
                      <div class="st-media-library__date-row">
                        <icon-wrapper
                          icon-name="calendar"
                          class="st-media-library__calendar-icon"
                        />
                        <div>{{ formatDate(asset.updatedAt) }}</div>
                      </div>
                      <st-overflow-menu
                        :class="{'st-media-library__asset-overflow--hover' : slotProps.hover }"
                        title="Projects Example"
                        icon-name="share"
                      >
                        <st-overflow-menu-item
                          text="Delete"
                          icon-name="trash"
                          @click="openDeleteAssetModal(n, -1)"
                        />
                        <st-overflow-menu-item
                          text="Download"
                          icon-name="arrow-down"
                          @click="downloadAsset(asset.id, asset.filename)"
                        />
                        <st-overflow-menu-item
                          text="Move To"
                          icon-name="move"
                          @click="openMoveModal(n, -1)"
                        />
                      </st-overflow-menu>
                    </div>
                  </template>
                </st-card>
              </div>
            </div>
          </template>
        </st-accordion>
        <template v-for="(folder, i) in filteredFolders">
          <st-accordion
            :key="folder.folderId"
          >
            <template v-slot:visible-section="stAccordionSlotProps">
              <div class="st-media-library__accordion-row">
                <icon-wrapper
                  icon-name="file"
                  class="st-accordion__section-icon"
                />
                <div class="st-accordion__group-name">
                  {{ folder.name }}
                </div>
                <div
                  class="st-accordion__action-icon"
                  @click.stop="addFileModal(i)"
                >
                  <st-tooltip
                    tip="upload files"
                    class="st-member-groups__tooltip"
                  >
                    <icon-wrapper
                      icon-name="upload"
                      :class="{
                        'st-member-groups__hover-icon': true,
                        'st-member-groups__hover-icon--accordion-expanded': stAccordionSlotProps.expanded
                      }"
                    />
                  </st-tooltip>
                </div>
                <div
                  class="st-accordion__action-icon"
                  @click.stop="openEditModal(i)"
                >
                  <st-tooltip
                    tip="edit folder name"
                    class="st-member-groups__tooltip"
                  >
                    <icon-wrapper
                      icon-name="edit"
                      :class="{
                        'st-member-groups__hover-icon': true,
                        'st-member-groups__hover-icon--accordion-expanded': stAccordionSlotProps.expanded
                      }"
                    />
                  </st-tooltip>
                </div>
                <div
                  class="st-accordion__action-icon"
                  @click.stop="openDeleteFolderModal(i)"
                >
                  <st-tooltip
                    tip="delete folder"
                    class="st-member-groups__tooltip"
                  >
                    <icon-wrapper
                      icon-name="trash"
                      :class="{
                        'st-member-groups__hover-icon': true,
                        'st-member-groups__hover-icon--accordion-expanded': stAccordionSlotProps.expanded
                      }"
                    />
                  </st-tooltip>
                </div>
              </div>
            </template>
            <template v-slot:expanded-section>
              <div class="st-media-library__group-expanded-section">
                <template v-if="folder.assets.length<1">
                  <div>There are currently no files in this folder.</div>
                </template>
                <template v-else>
                  <div
                    v-for="(asset, n) in folder.assets"
                    :key="'asset-'+ asset.id"
                  >
                    <st-card class="st-media-library__asset-card">
                      <template v-slot:card-content="slotProps">
                        <video
                          v-if="isVideo(asset)"
                          class="st-media-library__asset-card-preview"
                        >
                          >
                          <source
                            :src="asset.mediaUrl"
                            type="video/mp4"
                          > Your browser does not support the video tag.
                        </video>
                        <div
                          v-else
                          class="st-media-library__asset-card-preview"
                          :style="{
                            background: `url('${asset.mediaUrl}') center / contain no-repeat`
                          }"
                        />
                        <div
                          :class="{
                            'st-media-library__asset-name': true,
                            'st-media-library__asset-name--hover': slotProps.hover
                          }"
                        >
                          {{ asset.filename }}
                        </div>
                        <div class="st-media-library__asset-card-bottom-row">
                          <div class="st-media-library__date-row">
                            <icon-wrapper
                              icon-name="calendar"
                              class="st-media-library__calendar-icon"
                            />
                            <div>{{ formatDate(asset.updatedAt) }}</div>
                          </div>
                          <st-overflow-menu
                            :class="{'st-media-library__asset-overflow--hover' : slotProps.hover }"
                            title="Projects Example"
                            icon-name="share"
                          >
                            <st-overflow-menu-item
                              v-if="folder.accountId===accountId"
                              text="Delete"
                              icon-name="trash"
                              @click="openDeleteAssetModal(n, i)"
                            />
                            <st-overflow-menu-item
                              text="Duplicate"
                              icon-name="duplicate"
                              @click="duplicateAsset(asset.id, i, asset.filename)"
                            />
                            <st-overflow-menu-item
                              text="Download"
                              icon-name="arrow-down"
                              @click="downloadAsset(asset.id, asset.filename)"
                            />
                            <!-- <st-overflow-menu-item
                            text="Replace"
                            icon-name="replace"
                            @click="openReplaceAssetModal(n,i)"
                          /> -->
                            <st-overflow-menu-item
                              v-if="folder.accountId===accountId"
                              text="Move To"
                              icon-name="move"
                              @click="openMoveModal(n, i)"
                            />
                          </st-overflow-menu>
                        </div>
                      </template>
                    </st-card>
                  </div>
                </template>
              </div>
            </template>
          </st-accordion>
        </template>
      </div>

      <st-modal
        v-model="deleteFolderModal"
        :cancel-modal="true"
        :non-exit-modal="true"
        @closeModal="deleteFolderModal = false"
      >
        <template v-slot:modalTitle>
          Delete Media Folder
        </template>

        <template
          v-slot:modalActions
        >
          <div
            class="st-member-groups__modal-text st-member-groups__modal-text--delete-group"
          >
            This folder contains {{ modalFolder.assets.length }} media files.  If you go ahead and delete it, all files within will be deleted as well.
            <br>
            <br>
            Are you sure you want to continue and delete this folder?
          </div>
        </template>
        <template v-slot:modalButton>
          <st-button
            caption="Delete Folder"
            :show-action="true"
            icon-name="trash"
            @click="deleteFolder"
          />
        </template>
      </st-modal>
      <st-modal v-model="editModal">
        <template v-slot:modalTitle>
          Edit Folder Name
        </template>

        <template
          v-slot:modalActions
        >
          <div
            class="st-member-groups__modal-text"
          >
            <st-input
              ref="editNameInput"
              v-model="modalFolderName"
              class="st-input--default st-member-groups__edit-name-input"
              :show-label="true"
              label="Folder Name"
              @emitEnter="editFolderName"
            />
          </div>
        </template>
        <template v-slot:modalButton>
          <st-button
            caption="Save"
            :show-action="true"
            icon-name="arrow"
            @click="editFolderName"
          />
        </template>
      </st-modal>
      <st-modal v-model="createFolderModal">
        <template v-slot:modalTitle>
          Create Folder
        </template>

        <template
          v-slot:modalActions
        >
          <div
            class="st-member-groups__modal-text"
          >
            <st-input
              ref="editNameInput"
              v-model="newFolderName"
              class="st-input--default st-member-groups__edit-name-input"
              :show-label="true"
              label="Folder Name"
              @emitEnter="createFolder"
            />
          </div>
        </template>
        <template v-slot:modalButton>
          <st-button
            caption="Save"
            :show-action="true"
            icon-name="arrow"
            @click="createFolder"
          />
        </template>
      </st-modal>
      <st-modal
        v-model="uploadModal"
        size-class="st-modal--large"
        @closeModal="modalFolderIndex = -1"
      >
        <template v-slot:modalTitle>
          Upload Media
        </template>

        <template
          v-slot:modalActions
        >
          <div
            class="st-media-library__upload-modal-body"
          >
            <div class="st-media-library__upload-section">
              <st-dropzone
                name="asset[media]"
                :multiple="true"
                :class="{
                  'st-media-library__dropzone-empty': uploaderFiles.length === 0,
                  'st-media-library__dropzone-with-files': uploaderFiles.length > 0
                }"
                @input="onDropzone"
              />
              <div
                v-if="uploaderFiles.length>0"
                class="st-media-library__uploader-previews"
              >
                <st-upload-preview
                  v-for="(file, n) in uploaderFiles"
                  :key="'new-file-' + file.name"
                  :file="file"
                  :upload-percent="uploadPercent[n]"
                  @remove="removeFile(n)"
                />
              </div>
            </div>
            <st-dropdown-menu
              class="st-media-library__folder-dropdown"
              caption="Folder (Optional)"
              :display-caption="true"
              :initial-active-option="initialActiveFolder"
              :options="accountFolderNames"
              @updateOption="setUploadFolderOption"
            />
          </div>
        </template>
        <template v-slot:modalButton>
          <st-button
            :caption="uploadCaption"
            :show-action="true"
            icon-name="arrow"
            :disabled="uploadButtonDisabled"
            @click="uploadMedia"
          />
        </template>
      </st-modal>
      <st-modal
        v-model="deleteAssetModal"
        :cancel-modal="true"
        :non-exit-modal="true"
        @closeModal="deleteAssetModal = false"
      >
        <template v-slot:modalTitle>
          Delete Asset
        </template>

        <template
          v-slot:modalActions
        >
          <div
            class="st-media-library__delete-asset-modal"
          >
            <div
              class="st-media-library__delete-asset-file"
              :style="{
                background: `url('${modalAsset.mediaUrl}') center no-repeat`,
                backgroundSize: 'contain'
              }"
            />
            <div class="st-media-library__delete-asset-text">
              Are you sure you want to continue and delete this asset?
            </div>
          </div>
        </template>
        <template v-slot:modalButton>
          <st-button
            caption="Delete Asset"
            :show-action="true"
            icon-name="trash"
            @click="deleteAsset"
          />
        </template>
      </st-modal>
      <st-modal
        v-model="moveToModal"
        :cancel-modal="true"
        :non-exit-modal="true"
        @closeModal="moveToModal = false"
      >
        <template v-slot:modalTitle>
          Move File
        </template>

        <template
          v-slot:modalActions
        >
          <div
            class="st-member-groups__modal-text st-member-groups__modal-text--delete-group"
          >
            Select the folder that you would like to move this file to
            <st-dropdown-menu
              class="st-media-library__folder-dropdown"
              caption="Folder"
              :display-caption="true"
              :initial-active-option="modalFolderName"
              :options="accountFolderNames"
              @updateOption="setUploadFolderOption"
            />
          </div>
        </template>
        <template v-slot:modalButton>
          <st-button
            caption="Move File"
            :show-action="true"
            icon-name="move"
            @click="moveAsset"
          />
        </template>
      </st-modal>
    <!-- <st-modal
      v-model="replaceModal"
      size-class="st-modal--large"
    >
      <template v-slot:modalTitle>
        Replace Media file
      </template>

      <template
        v-slot:modalActions
      >
        <div
          class="st-media-library__upload-modal-body"
        >
          <div class="st-media-library__upload-section">
            <st-dropzone
              name="asset[media]"
              :class="{
                'st-media-library__dropzone-empty': replaceFiles.length === 0,
                'st-media-library__dropzone-with-files': replaceFiles.length === 1
              }"
              @input="onDropzoneReplace"
            />
            <div
              v-if="replaceFiles.length === 1"
              class="st-media-library__uploader-previews"
            >
              <st-upload-preview
                v-for="(file, n) in replaceFiles"
                :key="'replace-file-' + file.name"
                :file="file"
                :upload-percent="uploadPercent[n]"
                @remove="replaceFiles=[]"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          :caption="uploadCaption"
          :show-action="true"
          icon-name="arrow"
          :disabled="replaceFiles.length===0"
          @click="replaceMedia"
        />
      </template>
    </st-modal> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

import {
  IconWrapper,
  StAccordion,
  StButton,
  StCard,
  StDropdownButton,
  StDropdownMenu,
  StInput,
  StModal,
  StOverflowMenu,
  StOverflowMenuItem,
  StTooltip,
  StDropzone,
  StUploadPreview
} from '../../common'

const DROPDOWN_OPTIONS = [{
  caption: 'Create Folder',
  iconName: 'create-folder'
}, {
  caption: 'Upload Media',
  iconName: 'upload'
}]

export default {
  components: {
    IconWrapper,
    StAccordion,
    StButton,
    StCard,
    StDropdownMenu,
    StDropdownButton,
    StInput,
    StModal,
    StTooltip,
    StOverflowMenu,
    StOverflowMenuItem,
    StDropzone,
    StUploadPreview
  },
  props: {
    initialFolders: {
      type: Array,
      required: true
    },
    recentAssets: {
      type: Array,
      required: true
    },
    accountId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      folders: this.initialFolders,
      recentAssetsFolder: this.recentAssets,
      dropdownOptions: DROPDOWN_OPTIONS,
      createFolderModal: false,
      modalFolderIndex: -1,
      initialModalFolderIndex: -1,
      modalAssetIndex: -1,
      newFolderName: '',
      uploadModal: false,
      editModal: false,
      deleteFolderModal: false,
      mediaLookup: '',
      filterOption: 'All Media',
      uploaderFiles: [],
      uploadPercent: [],
      errorUploading: false,
      replaceModal: false,
      moveToModal: false,
      deleteAssetModal: false,
      uploaderFileCount: 0
    }
  },
  computed: {
    ...mapState(['navOpen']),
    filteredFolders () {
      if (this.mediaLookup) {
        return this.folders.map(folder => {
          folder.assets = folder.assets.filter(asset => {
            const regex = new RegExp(this.mediaLookup, 'gi')
            return asset.filename.match(regex)
          })
        })
      } else {
        return this.folders
      }
    },
    spacetricsFolders () {
      return this.folders.filter(folder => {
        return folder.spacetricsOwned
      })
    },
    accountFolders () {
      return this.folders.filter(folder => {
        return folder.accountId === this.accountId
      })
    },
    // sortedAndFilteredFolders () {
    // let folders
    // switch (this.filterOption) {
    //   case 'All Media':
    //     folders =
    //     break
    //   case 'Spacetrics Media':
    //     folders =
    //     break
    //   case 'My Media':
    //     folders =
    //     break
    //   case 'Team Media':
    //     folders =
    //     break
    //   case 'Newest First':
    //     folders =
    //     break
    //   case 'Oldest First':
    //     folders =
    //     break
    // }
    // return folders
    // },
    modalFolderName: {
      // getter
      get: function () {
        if (this.modalFolderIndex > -1) {
          return this.folders[this.modalFolderIndex].name
        } else {
          return null
        }
      },
      // setter
      set: function (newName) {
        this.folders[this.modalFolderIndex].name = newName
      }
    },
    modalFolder () {
      return this.folders[this.modalFolderIndex]
    },
    modalAsset () {
      if (this.initialModalFolderIndex === -1) {
        return this.recentAssetsFolder[this.modalAssetIndex]
      } else if (this.initialModalFolderIndex > -1) {
        return this.folders[this.initialModalFolderIndex].assets[this.modalAssetIndex]
      } else if (this.modalFolderIndex > -1) {
        return this.folders[this.modalFolderIndex].assets[this.modalAssetIndex]
      } else {
        return undefined
      }
    },
    allFolderNames () {
      return this.folders.map(folder => folder.name)
    },
    accountFolderNames () {
      return this.accountFolders.map(folder => folder.name)
    },
    initialActiveFolder () {
      if (this.modalFolderIndex > -1) {
        return this.folders[this.modalFolderIndex].name
      } else {
        return null
      }
    },
    uploadCaption () {
      if (this.uploaderFiles.length > 0) {
        return `Upload [${this.uploaderFiles.length}] Files`
      } else {
        return 'Upload Files'
      }
    },
    uploadButtonDisabled () {
      if (this.uploaderFiles.length === 0) { return true }
      if (this.uploaderFileCount !== this.uploaderFiles.length) { return true }
      if (this.uploaderFiles.length > 0 && this.errorUploading) { return true }
      return false
    }
  },
  mounted () {
    // set correct highlighting in the sidebar
    this.$store.commit('setActivePage', 'media-library')
  },
  methods: {
    setFilterOption (option) {
      // TO DO: link the redults to this option
      console.log('set Filter option', option)
    },
    setUploadFolderOption (option) {
      this.modalFolderIndex = this.allFolderNames.indexOf(option)
    },
    handleDropdownOption (option) {
      switch (option.caption) {
        case 'Create Folder':
          this.createFolderModal = true
          break

        case 'Upload Media':
          this.uploadModal = true
      }
    },
    createFolder () {
      let url = `/folders`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { name: this.newFolderName }
      })
        .then(res => {
          this.folders.push(Object.assign({ assets: [] }, res.data))
          this.newFolderName = ''
          this.createFolderModal = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    openEditModal (folderIndex) {
      this.modalFolderIndex = folderIndex
      this.editModal = true
    },
    openDeleteFolderModal (folderIndex) {
      this.modalFolderIndex = folderIndex
      this.deleteFolderModal = true
    },
    cancelDeleteFolderModal () {
      this.modalFolderIndex = -1
      this.deleteFolderModal = false
    },
    editFolderName () {
      let url = `/folders/${this.modalFolder.folderId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { name: this.modalFolderName }
      })
        .then(res => {
          console.log('editFolderName', res)
          this.modalFolderIndex = -1
          this.editModal = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    deleteFolder () {
      let url = `/folders/${this.modalFolder.folderId}`
      this.$axios.request({
        method: 'delete',
        url: url
      })
        .then(res => {
          this.folders.splice(this.modalFolderIndex, 1)
          this.$nextTick(() => {
            this.modalFolderIndex = -1
            this.deleteFolderModal = false
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    uploadAsset (file, idx) {
      this.$store.commit('toggleFullSpinner', true)
      let assetsUrl = `/assets`
      let assetData = new FormData()
      assetData.append('asset[media]', file, file.name)
      if (this.modalFolder) {
        assetData.append('asset[folder_id]', this.modalFolder.folderId)
      }
      this.$axios.request({
        method: 'post',
        url: assetsUrl,
        data: assetData
      }).then((res) => {
        if (this.modalFolderIndex === -1) {
          this.recentAssetsFolder.push(res.data)
        } else {
          this.folders[this.modalFolderIndex].assets.push(res.data)
        }
        if (idx === this.uploaderFiles.length - 1 && !this.errorUploading) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.uploadModal = false
              this.modalFolderIndex = -1
              // to do: end spinner on top of modal
              this.$store.commit('toggleFullSpinner', false)
              this.uploaderFiles = []
              this.uploaderFileCount = 0
            }, 1000)
          })
        }
      })
        .catch(err => {
          console.log('err', err)
          let fakeUploadFailedPercent = Math.floor(
            Math.random() * (89 - 5) + 5
          )
          this.uploadPercent[idx] = fakeUploadFailedPercent
          this.errorUploading = true
        })
    },
    uploadMedia () {
      // to do: add a spinner on top of the modal
      // send ajax requests to create the assets
      for (let index = 0; index < this.uploaderFiles.length; index++) {
        this.uploadAsset(this.uploaderFiles[index], index)
      }
    },
    removeFile (idx) {
      this.uploaderFiles.splice(idx, 1)
      this.uploadPercent.splice(idx, 1)
      this.uploaderFileCount--
    },
    onDropzone (files) {
      this.$store.commit('toggleFullSpinner', true)
      setTimeout(() => {
        this.$store.commit('toggleFullSpinner', false)
      }, 1000)
      let originalLength = this.uploaderFiles.length
      this.uploaderFileCount = files.length + originalLength
      for (let index = 0; index < files.length; index++) {
        this.uploaderFiles.push(files[index])
        this.uploadPercent.push(0)
        let fakeUploadDelay = Math.floor(
          Math.random() * (500 - 200) + 200
        )
        for (let i = 1; i <= 100; i++) {
          setTimeout(() => {
            this.uploadPercent.splice(originalLength + index, 1, i)
          }, fakeUploadDelay)
        }
      }
    },
    addFileModal (folderIdx) {
      this.modalFolderIndex = folderIdx
      this.uploadModal = true
    },
    formatDate (date) {
      let dateMoment = moment(date)
      return dateMoment.format('MM/DD/YYYY')
    },
    openDeleteAssetModal (assetIndex, folderIndex) {
      this.deleteAssetModal = true
      this.modalAssetIndex = assetIndex
      this.initialModalFolderIndex = folderIndex
    },
    cancelDeleteAssetModal () {
      this.deleteAssetModal = false
      this.modalAssetIndex = -1
      this.initialModalFolderIndex = -1
    },
    deleteAsset () {
      let url = `/assets/${this.modalAsset.id}`
      this.$axios.request({
        method: 'delete',
        url: url
      })
        .then(res => {
          if (this.initialModalFolderIndex > -1) {
            this.folders[this.initialModalFolderIndex].assets.splice(this.modalAssetIndex, 1)
          } else {
            this.recentAssetsFolder.splice(this.modalAssetIndex, 1)
          }
          this.$nextTick(() => {
            this.modalAssetIndex = -1
            this.modalFolderIndex = -1
            this.deleteAssetModal = false
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    duplicateAsset (assetId, folderIndex, assetName) {
      this.$store.commit('toggleFullSpinner', true)
      this.modalFolderIndex = folderIndex
      let url = `/assets/${assetId}/duplicate`
      this.$axios.request({
        method: 'post',
        url: url,
        data: {
          id: assetId,
          name: `${assetName} DUPLICATE`,
          folder_id: this.modalFolderId
        }
      })
        .then(res => {
          this.folders[folderIndex].assets.push(res.data)
          this.modalFolderIndex = -1
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(err => {
          console.error(err)
        })
    },
    downloadAsset (assetId, filename) {
      let url = `/assets/${assetId}/blob`
      this.$axios.request({
        method: 'get',
        url: url
      })
        .then(res => {
          this.forceDownload(res.data.blob, filename)
        })
        .catch(err => {
          console.error(err)
        })
    },
    forceDownload (assetUrl, filename) {
      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = assetUrl

      // Use download attribute to set set desired file name
      a.setAttribute('download', filename)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    },
    openMoveModal (assetIndex, folderIndex) {
      this.initialModalFolderIndex = folderIndex
      this.modalAssetIndex = assetIndex
      this.modalFolderIndex = folderIndex
      this.moveToModal = true
    },
    cancelMoveModal () {
      this.initialModalFolderIndex = -1
      this.modalAssetIndex = -1
      this.modalFolderIndex = -1
      this.moveToModal = false
    },
    moveAsset () {
      // send ajax request to move the file to a differnt folder
      let url = `/assets/${this.modalAsset.id}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: {
          folder_id: this.modalFolder.folderId
        }
      })
        .then(res => {
          this.folders[this.modalFolderIndex].assets.push(res.data)
          if (this.initialModalFolderIndex > -1) {
            this.folders[this.initialModalFolderIndex].assets.splice(this.modalAssetIndex, 1)
          } else {
            this.recentAssetsFolder.splice(this.modalAssetIndex, 1)
          }
          this.$nextTick(() => {
            this.initialModalFolderIndex = -1
            this.modalFolderIndex = -1
            this.modalAssetIndex = -1
            this.moveToModal = false
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    isVideo (asset) {
      return asset.contentType.split('/')[0] === 'video'
    }
  }
}
</script>
