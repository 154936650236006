<template>
  <div class="st-request-audience__radios-wrapper">
    <st-radio-button
      v-model="audienceRelationship"
      :multi-row="true"
      :options="RELATIONSHIP.map((status, index) => {
        return { caption: status, value: index}
      })"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { StRadioButton } from '../../../../common'

export default {
  components: {
    StRadioButton
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('requestAudience', ['relationshipIndex']),
    ...mapGetters('requestAudience', ['RELATIONSHIP']),
    audienceRelationship: {
      get () {
        return this.relationshipIndex
      },
      set (newIndex) {
        this.updateRelationship(newIndex)
      }
    }
  },
  methods: {
    ...mapActions('requestAudience', ['updateRelationship'])
  }
}
</script>
