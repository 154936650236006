<template>
  <div class="icon_link">
    <div class="icon_link__svg">
      <svg
        viewBox="0 0 17.043 17.085"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M.069 2.049A1.945 1.945 0 012.006.112L6.182.1a1.917 1.917 0 011.925 1.925L8.1 3.9a1.945 1.945 0 01-1.935 1.942l-4.176.013A1.917 1.917 0 01.064 3.929zm1.25-.686c-.377.377-.285.631-.291 2.564a.958.958 0 00.963.963l4.176-.013a.965.965 0 00.684-.285c.377-.377.285-.631.291-2.564a.958.958 0 00-.963-.963L2 1.078a.965.965 0 00-.681.285z"
            transform="rotate(-45 15.623 6.634)"
          />
          <path
            d="M.069 2.049A1.945 1.945 0 012.006.112L6.182.1a1.917 1.917 0 011.925 1.925L8.1 3.9a1.945 1.945 0 01-1.935 1.942l-4.176.013A1.917 1.917 0 01.064 3.929zm1.25-.686c-.377.377-.285.631-.291 2.564a.958.958 0 00.963.963l4.176-.013a.965.965 0 00.684-.285c.377-.377.285-.631.291-2.564a.958.958 0 00-.963-.963L2 1.078a.965.965 0 00-.681.285z"
            transform="rotate(-45 10.42 -5.824)"
          />
          <path
            d="M0 .153L8.04.129"
            transform="rotate(-45 16.412 -1.09)"
          />
          <path
            d="M0-.5h7v1H0z"
            transform="rotate(-45 16.228 -1.836)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
