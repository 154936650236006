import Vue from 'vue'
import Vuex from 'vuex'

import insights from './modules/insights'
import participants from './modules/participants'
import requestAudience from './modules/requestAudience'
import specifications from './modules/specifications'

Vue.use(Vuex)

// using the factory instead of just exporting an object lets us
// be lazier about timing. Now it's hard to change it back
export default function storeFactory (axios) {
  return new Vuex.Store({
    state: {
      activePage: null,
      axios: {},
      browserType: '',
      currentUser: {},
      deviceType: '',
      directUploadUrl: '',
      fullScreen: false,
      hideSidebar: false,
      isFullSpinnerVisible: false,
      isInlineSpinnerVisible: false,
      navOpen: true,
      showToast: false,
      signedOutMobileHeader: false,
      toastOptions: {
        caption: '',
        kind: '',
        title: ''
      }
    },
    getters: {
      isBrowserChrome (state) {
        return state.browserType === 'isChrome'
      },
      isBrowserFirefox (state) {
        return state.browserType === 'isFirefox'
      },
      isBrowserSafari (state) {
        return state.browserType === 'isSafari'
      },
      isDeviceIOS (state) {
        return state.deviceType === 'iOS'
      },
      rootClasses (state) {
        let classes = {
          'st-layout': true,
          'st-layout--collapse-body': state.fullScreen,
          'st-layout--locked': state.isFullSpinnerVisible
        }
        if (Object.keys(state.currentUser).length > 0 && !state.hideSidebar) {
          let className = `st-layout--with-sidebar-${state.navOpen ? 'open' : 'closed'}`
          classes[className] = true
        }
        return classes
      }
    },
    mutations: {
      addDirectUploadUrl: (state, payload) => {
        state.directUploadUrl = payload
      },
      closeToast: (state) => {
        state.showToast = false
      },
      toggleFullscreen: (state, payload) => {
        state.fullScreen = payload
      },
      toggleSidebar: (state, newState = null) => {
        if (newState === null) {
          state.navOpen = !state.navOpen
        } else {
          state.navOpen = newState
        }
      },
      toggleFullSpinner: (state, payload) => {
        if (payload === undefined) {
          state.isFullSpinnerVisible = !state.isFullSpinnerVisible
        } else {
          state.isFullSpinnerVisible = payload
        }
      },
      toggleInlineSpinner: (state, payload) => {
        if (payload === undefined) {
          state.isInlineSpinnerVisible = !state.isInlineSpinnerVisible
        } else {
          state.isInlineSpinnerVisible = payload
        }
      },
      toggleSignedOutMobileHeader: (state) => {
        state.signedOutMobileHeader = !state.signedOutMobileHeader
      },
      setCustomAxios: (state, payload) => {
        state.axios = payload
      },
      setActivePage: (state, payload) => {
        state.activePage = payload
      },
      setBrowserType: (state, payload) => {
        state.browserType = payload
      },
      setDeviceType: (state, payload) => {
        state.deviceType = payload
      },
      hideSidebar: (state, payload) => {
        state.hideSidebar = payload
      },
      setCurrentUser: (state, payload) => {
        state.currentUser = payload
      },
      updateToastOptions: (state, { caption, kind, title }) => {
        state.toastOptions.caption = caption
        state.toastOptions.kind = kind
        state.toastOptions.title = title
        state.showToast = true
      }
    },
    modules: {
      insights,
      participants,
      requestAudience,
      specifications
    }
  })
}
