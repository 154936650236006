<template>
  <nav
    v-if="!hideSidebar"
    class="st-layout__sidebar"
    :class="navOpen ? 'st-layout__sidebar--open' : 'st-layout__sidebar--closed'"
  >
    <div
      v-if="navOpen"
      class="st-sidebar__mobile-screen"
      @click="toggleMenuAndSave"
    />
    <div
      :class="navOpen ? 'st-sidebar__nav-open' : 'st-sidebar__nav-closed'"
    >
      <a
        href="/welcome"
        class="st-sidebar__logo"
      >
        <st-tooltip
          message-class="st-sidebar__tooltip-message"
          direction="right"
          tip="Home"
          :is-active="!navOpen"
          :container-size-multiplier="1.133"
        >
          <icon-wrapper
            icon-name="spacetrics"
            class="st-sidebar__logo-icon"
          />
        </st-tooltip>
        <div
          v-if="navOpen"
          class="st-sidebar__title"
        >
          spacetrics
        </div>
      </a>
      <div class="st-sidebar__nav-links">
        <div class="st-sidebar__top-group">
          <div
            class="st-sidebar__nav-link"
          >
            <a href="/projects">
              <div class="st-sidebar__nav-title">
                <st-tooltip
                  message-class="st-sidebar__tooltip-message"
                  direction="right"
                  tip="Projects"
                  :is-active="!navOpen"
                  :container-size-multiplier="1.25"
                >
                  <icon-wrapper
                    icon-name="projects"
                    :class="{
                      'st-sidebar__nav-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'projects'
                    }"
                  />
                </st-tooltip>
                <div
                  v-if="navOpen"
                  :class="{
                    'st-sidebar__nav-title-text': true,
                    'st-sidebar__nav-title-text--active': activePage === 'projects'
                  }"
                >
                  Projects
                </div>
              </div>
            </a>
          </div>
          <div class="st-sidebar__nav-link">
            <div
              class="st-sidebar__nav-title"
              @click="toggleSection('libraries')"
            >
              <st-tooltip
                message-class="st-sidebar__tooltip-message"
                direction="right"
                tip="Libraries"
                :is-active="!navOpen"
                :container-size-multiplier="1.25"
              >
                <icon-wrapper
                  icon-name="libraries"
                  :class="{
                    'st-sidebar__nav-icon': true,
                    'st-sidebar__nav-icon--active': isLibrariesActive
                  }"
                />
              </st-tooltip>
              <div
                v-if="navOpen"
                :class="{
                  'st-sidebar__nav-title-text': true,
                  'st-sidebar__nav-title-text--active': isLibrariesActive
                }"
              >
                Libraries <span>
                  <icon-wrapper
                    icon-name="chevron"
                    :class="{
                      'st-sidebar__chevron': true,
                      'st-sidebar__chevron--open': openSection==='libraries' || (isLibrariesActive && !openSection)
                    }"
                  />
                </span>
              </div>
            </div>
            <div
              v-if="((isLibrariesActive && !openSection) || (openSection==='libraries')) && navOpen"
              class="st-sidebar__sub-links"
            >
              <a
                href="/templates"
                class="st-sidebar__sub-link"
              >
                <icon-wrapper
                  icon-name="file"
                  :class="{
                    'st-sidebar__sub-icon': true,
                    'st-sidebar__nav-icon--active': activePage === 'templates'
                  }"
                />
                <div
                  :class="{'st-sidebar__sub-link-text': true,
                           'st-sidebar__nav-title-text--active': activePage === 'templates'
                  }"
                >
                  Templates
                </div>
              </a>
              <a
                href="/libraries/media"
                class="st-sidebar__sub-link"
              >
                <icon-wrapper
                  icon-name="file"
                  :class="{
                    'st-sidebar__sub-icon': true,
                    'st-sidebar__nav-icon--active': activePage === 'media-library'
                  }"
                />
                <div
                  :class="{'st-sidebar__sub-link-text': true,
                           'st-sidebar__nav-title-text--active': activePage === 'media-library' }"
                >
                  Media
                </div>
              </a>
              <!-- <div class="st-sidebar__sub-link">
                <icon-wrapper
                  icon-name="file"
                  class="st-sidebar__sub-icon"
                />
                <div class="st-sidebar__sub-link-text">
                  Segments
                </div>
              </div> -->
            </div>
          </div>
          <div
            ref="audience"
            class="st-sidebar__nav-link"
          >
            <div
              class="st-sidebar__nav-title"
              @click="toggleSection('audience')"
            >
              <st-tooltip
                message-class="st-sidebar__tooltip-message"
                direction="right"
                tip="Audience"
                :is-active="!navOpen"
                :container-size-multiplier="1.25"
              >
                <icon-wrapper
                  icon-name="participants"
                  :class="{
                    'st-sidebar__nav-icon': true,
                    'st-sidebar__nav-icon--active': activePage === 'all-recipients' || activePage === 'audience-lists'
                  }"
                />
              </st-tooltip>
              <div
                v-if="navOpen"
                :class="{
                  'st-sidebar__nav-title-text': true,
                  'st-sidebar__nav-title-text--active': activePage === 'all-recipients' || activePage === 'audience-lists'
                }"
              >
                Audience <span>
                  <icon-wrapper
                    icon-name="chevron"
                    :class="{
                      'st-sidebar__chevron': true,
                      'st-sidebar__chevron--open': openSection==='audience' || (isAudienceActive && !openSection)
                    }"
                  />
                </span>
              </div>
            </div>
            <div
              v-if="((isAudienceActive && !openSection) || (openSection==='audience')) && navOpen"
              class="st-sidebar__sub-links"
            >
              <a
                href="/audience"
                class="st-sidebar__sub-link"
              >
                <icon-wrapper
                  icon-name="paper-plane"
                  :class="{
                    'st-sidebar__sub-icon': true,
                    'st-sidebar__nav-icon--active': activePage === 'all-recipients'
                  }"
                />
                <div
                  :class="{
                    'st-sidebar__sub-link-text': true,
                    'st-sidebar__nav-title-text--active': activePage === 'all-recipients'
                  }"
                >
                  All Recipients
                </div>
              </a>
              <a
                href="/audience/lists"
                class="st-sidebar__sub-link"
              >
                <icon-wrapper
                  icon-name="list"
                  :class="{
                    'st-sidebar__sub-icon': true,
                    'st-sidebar__nav-icon--active': activePage === 'audience-lists'
                  }"
                />
                <div
                  :class="{
                    'st-sidebar__sub-link-text': true,
                    'st-sidebar__nav-title-text--active': activePage === 'audience-lists'
                  }"
                >
                  Audience Lists
                </div>
              </a>
              <a
                href="/audience_requests"
                class="st-sidebar__sub-link"
              >
                <icon-wrapper
                  icon-name="request-audience"
                  :class="{
                    'st-sidebar__sub-icon': true,
                    'st-sidebar__nav-icon--active': activePage === 'requested-recipients'
                  }"
                />
                <div
                  :class="{
                    'st-sidebar__sub-link-text': true,
                    'st-sidebar__nav-title-text--active': activePage === 'requested-recipients'
                  }"
                >
                  Requested Recipients
                </div>
              </a>
            </div>
          </div>
          <div
            class="st-sidebar__nav-link"
          >
            <a href="/insights_list">
              <div class="st-sidebar__nav-title">
                <st-tooltip
                  message-class="st-sidebar__tooltip-message"
                  direction="right"
                  tip="Insights"
                  :is-active="!navOpen"
                  :container-size-multiplier="1.25"
                >
                  <icon-wrapper
                    icon-name="insights"
                    :class="{
                      'st-sidebar__nav-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'insights_list'
                    }"
                  />
                </st-tooltip>
                <div
                  v-if="navOpen"
                  :class="{
                    'st-sidebar__nav-title-text': true,
                    'st-sidebar__nav-title-text--active': activePage === 'insights_list'
                  }"
                >
                  Insights
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="st-sidebar__bottom-group">
          <div
            ref="search"
          >
            <div
              v-if="overflowSections<5"
              class="st-sidebar__nav-link"
            >
              <!-- <a href="/"> -->
              <!-- add the link whenevr we develop the page -->
              <!-- <div class="st-sidebar__nav-title">
                  <st-tooltip
                  message-class="st-sidebar__tooltip-message"
                    direction="right"
                    tip="Search"
                    :is-active="!navOpen"
                    :container-size-multiplier="1.25"
                  >
                    <icon-wrapper
                      icon-name="search"
                      class="st-sidebar__nav-icon"
                    />
                  </st-tooltip>
                  <div
                    v-if="navOpen"
                    class="st-sidebar__nav-title-text"
                  >
                    Search
                  </div>
                </div>
              </a> -->
            </div>
          </div>
          <div ref="members">
            <div
              v-if="overflowSections<4"
              class="st-sidebar__nav-link"
            >
              <div
                class="st-sidebar__nav-title"
                @click="toggleSection('members')"
              >
                <st-tooltip
                  message-class="st-sidebar__tooltip-message"
                  direction="right"
                  tip="Members"
                  :is-active="!navOpen"
                  :container-size-multiplier="1.25"
                >
                  <icon-wrapper
                    icon-name="team"
                    :class="{
                      'st-sidebar__nav-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'member-groups' || activePage === 'all-members'
                    }"
                  />
                </st-tooltip>
                <div
                  v-if="navOpen"
                  class="st-sidebar__nav-title-text"
                  :class="{
                    'st-sidebar__nav-title-text': true,
                    'st-sidebar__nav-title-text--active': activePage === 'member-groups' || activePage === 'all-members'
                  }"
                >
                  Members <span>
                    <icon-wrapper
                      icon-name="chevron"
                      :class="{
                        'st-sidebar__chevron': true,
                        'st-sidebar__chevron--open': openSection==='members' || (isMembersActive && !openSection)
                      }"
                    />
                  </span>
                </div>
              </div>
              <div
                v-if="navOpen && (openSection==='members' || (!openSection && isMembersActive))"
                class="st-sidebar__sub-links"
              >
                <a
                  href="/team"
                  class="st-sidebar__sub-link"
                >
                  <icon-wrapper
                    icon-name="members"
                    :class="{
                      'st-sidebar__sub-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'all-members'
                    }"
                  />
                  <div
                    :class="{
                      'st-sidebar__sub-link-text': true,
                      'st-sidebar__nav-title-text--active': activePage === 'all-members'
                    }"
                  >
                    All Members
                  </div>
                </a>
                <a
                  href="/team/groups"
                  class="st-sidebar__sub-link"
                >
                  <icon-wrapper
                    icon-name="list"
                    :class="{
                      'st-sidebar__sub-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'member-groups'
                    }"
                  />
                  <div
                    :class="{
                      'st-sidebar__sub-link-text': true,
                      'st-sidebar__nav-title-text--active': activePage === 'member-groups'
                    }"
                  >
                    Groups
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div ref="help">
            <div
              v-if="overflowSections<3"
              class="st-sidebar__nav-link"
            >
              <div
                class="st-sidebar__nav-title"
                @click="toggleSection('help')"
              >
                <st-tooltip
                  message-class="st-sidebar__tooltip-message"
                  direction="right"
                  tip="Help"
                  :is-active="!navOpen"
                  :container-size-multiplier="1.25"
                >
                  <icon-wrapper
                    icon-name="help"
                    :class="{
                      'st-sidebar__nav-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'faq' || activePage === 'tutorials'
                    }"
                  />
                </st-tooltip>
                <div
                  v-if="navOpen"
                  :class="{
                    'st-sidebar__nav-title-text': true,
                    'st-sidebar__nav-title-text--active': activePage === 'faq' || activePage === 'tutorials'
                  }"
                >
                  Help <span>
                    <icon-wrapper
                      icon-name="chevron"
                      :class="{
                        'st-sidebar__chevron': true,
                        'st-sidebar__chevron--open': openSection==='help' || (isHelpActive && !openSection)
                      }"
                    />
                  </span>
                </div>
              </div>
              <div
                v-if="navOpen && (openSection==='help' || (isHelpActive && !openSection))"
                class="st-sidebar__sub-links"
              >
                <a
                  href="/help/faq"
                  class="st-sidebar__sub-link"
                >
                  <icon-wrapper
                    icon-name="faq"
                    :class="{
                      'st-sidebar__sub-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'faq'
                    }"
                  />
                  <div
                    :class="{
                      'st-sidebar__sub-link-text': true,
                      'st-sidebar__nav-title-text--active': activePage === 'faq'
                    }"
                  >
                    FAQ
                  </div>
                </a>
                <a
                  href="/help/tutorials"
                  class="st-sidebar__sub-link"
                >
                  <icon-wrapper
                    icon-name="tutorial"
                    :class="{
                      'st-sidebar__sub-icon': true,
                      'st-sidebar__nav-icon--active': activePage === 'tutorials'
                    }"
                  />
                  <div
                    :class="{
                      'st-sidebar__sub-link-text': true,
                      'st-sidebar__nav-title-text--active': activePage === 'tutorials'
                    }"
                  >
                    Tutorials
                  </div>
                </a>
                <a
                  id="Intercom"
                  class="st-sidebar__sub-link"
                  href="javascript:void(o)"
                >
                  <icon-wrapper
                    icon-name="chat"
                    class="st-sidebar__sub-icon"
                  />
                  <div class="st-sidebar__sub-link-text">
                    Chat with us
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- <div ref="notifications">
            <div
              v-if="overflowSections<2"
              class="st-sidebar__nav-link"
            >
              <a href="/"> -->
          <!-- add the link whenevr we develop the page -->
          <!-- <div class="st-sidebar__nav-title">
                  <st-tooltip
                  message-class="st-sidebar__tooltip-message"
                    direction="right"
                    tip="Notifications"
                    :is-active="!navOpen"
                    :container-size-multiplier="1.25"
                  >
                    <icon-wrapper
                      icon-name="notifications"
                      class="st-sidebar__nav-icon"
                    />
                  </st-tooltip>
                  <div
                    v-if="navOpen"
                    class="st-sidebar__nav-title-text"
                  >
                    Notifications
                  </div>
                </div>
              </a>
            </div>
          </div> -->
          <div
            ref="avatar"
          >
            <st-overflow-menu
              v-if="overflowSections<1"
              class="st-sidebar__account-overflow"
              title="Account"
              :horizontal="true"
              :fixed-page-position="true"
            >
              <template v-slot:open-menu-element>
                <div
                  class="st-sidebar__profile-section"
                >
                  <participant-avatar
                    class="st-sidebar__avatar"
                    :participant="currentUser"
                  />
                  <div
                    v-if="navOpen"
                    class="st-sidebar__user-info"
                  >
                    <div class="st-sidebar__user-name">
                      {{ currentUser.firstName }} {{ currentUser.lastName }}
                    </div>
                    <div class="st-sidebar__company-name">
                      @{{ currentUser.companyName }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:default>
                <st-overflow-menu-item
                  v-if="currentUser.kind === 'spacetrics_admin'"
                  text="Admin"
                  path="/admin"
                />
                <st-overflow-menu-item
                  icon-name="profile"
                  text="Profile"
                  :path="'/profile'"
                />
                <st-overflow-menu-item
                  icon-name="company"
                  text="Company Info"
                  path="/company_info"
                />
                <!-- <st-overflow-menu-item
                  icon-name="preferences"
                  text="Preferences"
                />
                <st-overflow-menu-item
                  icon-name="billing"
                  text="Billing"
                />
                <st-overflow-menu-item
                  icon-name="settings"
                  text="Settings"
                /> -->
                <st-overflow-menu-item
                  icon-name="sign-out"
                  text="Sign Out"
                  @click="$root.signOut('../users/sign_out')"
                />
              </template>
            </st-overflow-menu>
          </div>
          <div
            v-if="overflowSections>0"
            class="st-sidebar__overflow-icon"
          >
            <st-overflow-menu
              title="Sidebar Menu"
              :fixed-page-position="true"
              :horizontal="true"
            >
              <st-overflow-menu-item
                v-if="overflowSections > 4"
                icon-name="search"
                text="Search"
              />
              <st-overflow-menu-item
                v-if="overflowSections > 3"
                icon-name="members"
                text="Members"
              />
              <st-overflow-menu-item
                v-if="overflowSections > 2"
                icon-name="help"
                text="Help"
              />
              <st-overflow-menu-item
                v-if="overflowSections > 1"
                icon-name="notifications"
                text="Notifications"
              />
              <st-overflow-menu-item
                v-if="overflowSections > 0"
                text="Account"
              >
                <template v-slot:custom-icon="slotProps">
                  <div
                    class="st-sidebar__avatar st-sidebar__overflow-avatar"
                    :class="{'st-sidebar__overflow-avatar--hover': slotProps.hover}"
                    :style="avatarStyle"
                  />
                </template>
              </st-overflow-menu-item>
            </st-overflow-menu>
          </div>
        </div>
      </div>
      <div
        class="st-sidebar__icon-container st-sidebar__icon-container--mobile-only st-sidebar__icon-container--open"
        @click="toggleMenuAndSave"
      >
        <icon-wrapper
          icon-name="menu-icon"
          class="st-sidebar__icon"
        />
      </div>
    </div>
    <div
      class="st-sidebar__icon-container"
      :class="{
        'st-sidebar__icon-container--open': navOpen,
        'st-sidebar__icon-container--closed': !navOpen
      }"
      @click="toggleMenuAndSave"
    >
      <icon-wrapper
        icon-name="menu-icon"
        :class="{
          'st-sidebar__icon': true,
          'st-sidebar__icon--white': isWhite,
          'st-sidebar__icon--closed': !navOpen
        }"
      />
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import { IconWrapper,
  StTooltip,
  StOverflowMenu,
  StOverflowMenuItem
} from '../common/'
import { isDesktop } from '../../mixins'
import { ParticipantAvatar } from '../pages/insights'
export default {
  components: {
    IconWrapper,
    StTooltip,
    StOverflowMenu,
    StOverflowMenuItem,
    ParticipantAvatar
  },
  mixins: [isDesktop],
  props: {
    user: {
      type: Object,
      required: true
    },
    isWhite: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      openSection: null,
      isMounted: false,
      windowHeight: window.innerHeight,
      search: 0,
      members: 0,
      help: 0,
      notifications: 0,
      avatar: 0,
      gap: 0,
      audience: 0
    }
  },
  computed: {
    ...mapState(['currentUser', 'navOpen', 'activePage', 'hideSidebar']),
    avatarStyle () {
      if (this.currentUser.avatarUrl) {
        return {
          'border-radius': '100%',
          'background-image': `url('${this.currentUser.avatarUrl}')`,
          'background-size': 'cover',
          'background-repeat': 'no-repeat'
        }
      } else {
        return {}
      }
    },
    isLibrariesActive () {
      if (this.activePage === 'templates' || this.activePage === 'media-library' || this.activePage === 'segments') { return true }
      return false
    },
    isAudienceActive () {
      return this.openSection === 'audience' || this.activePage === 'all-recipients' || this.activePage === 'audience-lists' || this.activePage === 'requested-recipients'
    },
    isMembersActive () {
      return this.openSection === 'members' || this.activePage === 'member-groups' || this.activePage === 'all-members'
    },
    isHelpActive () {
      return this.openSection === 'help' || this.activePage === 'faq' || this.activePage === 'tutorials'
    },
    overflowSections () {
      let sections
      switch (true) {
        case (this.windowHeight <= this.search + 55 && this.gap < 50):
          sections = 5
          break
        case (this.windowHeight <= this.members + 55 && this.gap <= 50):
          sections = 4
          break
        case (this.windowHeight <= this.help + 55 && this.gap <= 50):
          sections = 3
          break
        case (this.windowHeight <= this.notifications + 55 && this.gap <= 50):
          sections = 2
          break
        case (this.windowHeight < this.avatar + 65 && this.gap <= 50):
          sections = 1
          break
        default:
          sections = 0
      }
      return sections
    }
  },
  mounted () {
    this.isMounted = true
    this.recalculatePositions()
    this.$store.commit('setCurrentUser', this.user)
    window.onresize = () => {
      this.windowHeight = window.innerHeight
      this.recalculatePositions()
    }
    if (this.isDesktop) {
      this.$nextTick(() => {
        const isClosed = document.cookie.split('; ').reduce((acc, val) => acc || val === 'sidebar_closed=', false)
        if (isClosed) {
          this.toggleMenu(false)
        }
      })
    } else {
      this.toggleMenu(false)
    }
  },
  beforeMount () {
  },
  methods: {
    toggleMenu (newState = null) {
      this.$store.commit('toggleSidebar', newState)
    },
    toggleMenuAndSave () {
      this.toggleMenu()
      if (this.navOpen) {
        document.cookie = 'sidebar_closed=; expires=Fri, 31 Dec 1999 23:59:59 GMT;'
      } else {
        document.cookie = 'sidebar_closed=;'
      }
    },
    toggleSection (section) {
      if (!this.navOpen) {
        this.toggleMenuAndSave()
      }
      if (this.openSection === section) {
        this.openSection = null
      } else {
        this.openSection = section
      }
      this.$nextTick(() => { this.recalculatePositions() })
    },
    recalculatePositions () {
      if (this.hideSidebar) { return }
      this.audience = this.$refs.audience.offsetTop + this.$refs.audience.clientHeight
      if (this.$refs.search) {
        this.search = this.$refs.search.offsetTop
      } else {
        this.search = 0
      }
      if (this.$refs.members) {
        this.members = this.$refs.members.offsetTop
      } else {
        this.members = 0
      }
      if (this.$refs.help) {
        this.help = this.$refs.help.offsetTop
      } else {
        this.help = 0
      }
      if (this.$refs.notifications) {
        this.notifications = this.$refs.notifications.offsetTop
      } else {
        this.notifications = 0
      }
      if (this.$refs.avatar) {
        this.avatar = this.$refs.avatar.offsetTop
      } else {
        this.avatar = 0
      }
      this.$nextTick(() => { this.gap = this.search - this.audience })
    }
  }
}
</script>
