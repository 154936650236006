<template>
  <div class="icon_save">
    <div class="icon_save__svg">
      <svg
        viewBox="0 0 16.006 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h14v1H0z" />
        <path
          d="M0 0h8v1H0z"
          transform="translate(4 5)"
        />
        <path
          d="M0 0h10v1H0z"
          transform="translate(3 8)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
        <path d="M0 0h1v16H0z" />
        <path
          d="M0 0h1v6H0z"
          transform="translate(4)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(3 8)"
        />
        <path
          d="M0 0h1v14H0z"
          transform="translate(15 2)"
        />
        <path
          d="M0 0h1v6H0z"
          transform="translate(11)"
        />
        <path
          d="M-.167 3.372h1l.037 2.8h-1z"
          transform="rotate(-45 3.378 -14.192)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(12 8)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
