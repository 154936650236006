<template>
	<div>
		<div class="qp-board">
			<quadrant-placement-board
				:minXLabel="activeQuestion.minXLabel"
				:maxXLabel="activeQuestion.maxXLabel"
				:minYLabel="activeQuestion.minYLabel"
				:maxYLabel="activeQuestion.maxYLabel"
				view="insights"
			>
				<span
					v-for="(option, index) in dots"
					:key="index"
					class="dot"
					:style="{
						top: `${option.y * 100}%`,
						left: `${option.x * 100}%`,
						backgroundColor: colors[option.colorIndex]
					}"
				/>
			</quadrant-placement-board>
		</div>
		<p class="st-survey-insights__active-question-description">Data Table</p>
		<data-table
			:minXLabel="activeQuestion.minXLabel"
			:maxXLabel="activeQuestion.maxXLabel"
			:minYLabel="activeQuestion.minYLabel"
			:maxYLabel="activeQuestion.maxYLabel"
			:options="activeQuestion.options"
			:colors="colors"
			:dots="dots"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { QuadrantPlacementBoard } from '../../../../common';
import DataTable from './quadrant-placement-data-table.vue';

export default {
	components: {
		QuadrantPlacementBoard,
		DataTable
	},
	data: function() {
		return {
			colors: ["#00a3a5", "#5d995d", "#00605f", "#1977d4", "#000660", "#7612a8", "#d25265", "#b78508"]
		};
	},
	computed: {
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingParticipantCheckboxFilter']),
		dots: function() {
			const responses = {};
			this.responsesPassingParticipantCheckboxFilter.forEach(response => {
				const { questionOptionId: optionId, context, surveyResponseId: responseId, value } = response;
				if (!responses[optionId]) responses[optionId] = {};
				if (!responses[optionId][responseId]) responses[optionId][responseId] = {};
				const obj = { optionId, responseId, [context === "x_axis" ? "x" : "y"]: value, colorIndex: this.activeQuestion.options.findIndex(({ id }) => id === optionId) };
				responses[optionId][responseId] = { ...responses[optionId][responseId], ...obj };
			});
			const dots = [];
			Object.keys(responses).forEach(optionId => {
				Object.keys(responses[optionId]).forEach(responseId => {
					dots.push(responses[optionId][responseId]);
				})
			});
			return dots;
		}
	}
}
</script>
<style scoped>
.dot {
	width: 15px;
	height: 15px;
	border-radius: 10px;
	opacity: 0.75;
	position: absolute;
}
.qp-board {
	height: 600px;
}
</style>