<template>
  <div class="icon_virtualtour">
    <div class="icon_virtualtour__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h8.008v1H0z"
          transform="translate(4.999)"
        />
        <path
          d="M0 0h8.008v1H0z"
          transform="translate(4.999 7.01)"
        />
        <path
          d="M0 0h8.008v1H0z"
          transform="translate(2.998 9.012)"
        />
        <path
          d="M0 0h1v8.011H0z"
          transform="translate(5.002)"
        />
        <path
          d="M0 0h1v8.011H0z"
          transform="translate(3.001 2.003)"
        />
        <path
          d="M0 0h1v8.011H0z"
          transform="translate(12.006)"
        />
        <path
          d="M-23469-144.005l1.979-1.98.707.707-1.979 1.98z"
          transform="translate(23472.002 146)"
        />
        <path
          d="M-23468.713-143.71l2-2 .707.708-2 2z"
          transform="translate(23479.006 153.012)"
        />
        <path
          d="M-23468.713-143.71l2-2 .707.708-2 2z"
          transform="translate(23472.002 153.012)"
        />
        <path
          d="M-23408.367-138.974l2.688 2.234-2.689 2.212-.637-.772 1.756-1.444-1.758-1.461z"
          transform="translate(23415.191 150.528)"
        />
        <path
          d="M-23427.322-140.182c-1.07.361-2.236 1.052-2.125 2.174.191 1.934 5.264 2.235 7.207 2.273v1c-2.561-.049-7.5-.372-8.146-2.861-.49-1.884 1.42-3.3 3.383-3.714l.2.979a4.978 4.978 0 00-.519.149z"
          transform="translate(23430.465 149.022)"
        />
        <path
          d="M-23421.486-137.907c-.189 1.822-2.666 2.509-4.457 2.851l-.187-.982c2.186-.417 3.41-1.041 3.619-1.812.354-1.294-1.447-2.226-2.721-2.48l.2-.98c1.78.363 3.737 1.526 3.546 3.403z"
          transform="translate(23437.473 149.022)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
