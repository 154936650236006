<template>
  <div
    class="st-virtual-tour__inner-wrapper"
  >
    <transition name="fade-up">
      <div
        v-if="mode === MODES.PROMPT"
        :key="currentPrompt"
        :class="{
          'st-virtual-tour__prompt': true,
          'st-virtual-tour__prompt--specs-preview': isSpecificationsPreview,
          'st-virtual-tour__prompt--phone-demo-preview': isDemoPreview && mobileDemoView
        }"
      >
        {{ currentPrompt ? currentPrompt : '...' }}
      </div>
      <div
        v-else-if="[MODES.FULL_TOPIC, MODES.SMALL_TOPIC].includes(mode)"
        :key="currentTopic"
        :class="[{ 'st-virtual-tour__topic': true},topicClass]"
      >
        <template v-if="mode === MODES.SMALL_TOPIC">
          <p class="st-virtual-tour__topic-title">
            {{ topicFraction }}
          </p>
          <div
            class="st-virtual-tour__close-wrapper"
            @click="closeTopic"
          >
            <icon-wrapper
              class="st-virtual-tour__icon-close"
              :icon-name="'plus'"
              :invert="true"
            />
          </div>
        </template>
        <div
          :class="{
            'st-virtual-tour__topic-text': true,
            'st-virtual-tour__topic-text--small': hasViewedTopic
          }"
        >
          {{ currentTopic ? currentTopic : '...' }}
        </div>
        <a
          v-if="mode === MODES.SMALL_TOPIC"
          class="st-virtual-tour__topic-list-link"

          @click="openTopicList"
        >
          View Other Topics
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { IconWrapper } from '../../../../common'

const MODES = Object.freeze({
  'HIDDEN': 'hidden',
  'PROMPT': 'prompt',
  'FULL_TOPIC': 'full-topic',
  'SMALL_TOPIC': 'small-topic',
  'HIDDEN_TOPIC': 'hidden-topic'
})

export default {
  components: {
    IconWrapper
  },
  props: {
    isDemoPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSpecificationsPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      viewedTopics: new Set(),
      closedPrompt: '',
      MODES
    }
  },
  computed: {
    ...mapGetters('participants', ['currentPrompt', 'currentTopic', 'hasViewedTopic', 'topics', 'isTopicListOpen']),
    ...mapState('participants', ['currentPano', 'activeQuestion', 'isTopicClosed', 'mobileDemoView']),
    topicClass () {
      const modifier = this.mode === MODES.FULL_TOPIC ? 'full' : 'small'

      return {
        [`st-virtual-tour__topic--${modifier}`]: true,
        'st-virtual-tour__topic--specs-preview': this.isSpecificationsPreview && modifier === 'small',
        'st-virtual-tour__topic--phone-demo-preview': this.isDemoPreview && this.mobileDemoView && modifier === 'small'
      }
    },
    topicFraction () {
      const idx = this.topics.indexOf(this.currentTopic)
      if (idx === -1) { return '...' }

      return `${idx + 1} / ${this.topics.length}`
    },
    mode () {
      if (this.currentPrompt || (this.isSpecificationsPreview && !this.currentTopic && !this.currentPrompt)) { return MODES.PROMPT }
      if (!this.currentTopic) { return MODES.HIDDEN }
      if (this.isTopicClosed) { return MODES.HIDDEN_TOPIC }
      if (this.isTopicListOpen) { return MODES.HIDDEN_TOPIC }
      if (this.isSpecificationsPreview) { return MODES.SMALL_TOPIC }
      if (this.hasViewedTopic) { return MODES.SMALL_TOPIC }
      return MODES.FULL_TOPIC
    }
  },
  methods: {
    ...mapMutations('participants', ['closeTopic']),
    openTopicList () {
      this.$emit('openTopicList')
    }
  }
}
</script>
