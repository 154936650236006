import { throttle, max } from 'lodash'
import Vue from 'vue/dist/vue.esm'

import {
  getCSRFParam,
  getToken,
  buildDefaultHeader,
  axiosInstance
} from './request_utilities'

export default function initializeVue (app) {
  initialize(Vue, app)
}

function initialize (vue, app) {
  vue.prototype.$smoothScroll = smoothScroll
  vue.prototype.$csrfParam = getCSRFParam()
  setupDefaultRequest(vue)
  addDeleteDirective(vue)
}

function smoothScroll (y0, y1) {
  for (let i = y0; i < y1; i++) {
    setTimeout(() => window.scrollTo(0, i), i - y0)
  }
}

// todo - can't this just use signOut?
function addDeleteDirective (vue) {
  vue.directive('method', {
    inserted (el, binding) {
      el.addEventListener('click', (ev) => {
        ev.preventDefault()
        let confirmMessage = el.getAttribute('confirm')
        if (confirmMessage) {
          let response = confirm(confirmMessage)
          if (!response) { return }
        }

        let method = binding.value
        let methodInput = document.createElement('INPUT')
        methodInput.setAttribute('name', '_method')
        methodInput.setAttribute('value', method)
        methodInput.setAttribute('type', 'hidden')

        let csrfInput = document.createElement('INPUT')
        csrfInput.setAttribute('name', vue.prototype.$csrfParam)
        csrfInput.setAttribute('value', vue.prototype.$token)
        csrfInput.setAttribute('type', 'hidden')

        let form = document.createElement('FORM')
        form.setAttribute('method', 'post')
        form.setAttribute('action', el.href)
        form.append(methodInput)
        form.append(csrfInput)

        document.body.appendChild(form)
        form.submit()
      })
    }
  })
}

function setupDefaultRequest (vue) {
  const token = getToken()
  const defaultHeader = buildDefaultHeader(token)
  vue.prototype.$token = token
  const customAxios = axiosInstance(defaultHeader)
  vue.prototype.$axios = customAxios
}

function getTotalOffset (el, prop = 'offsetTop') {
  let offset = 0
  while (el !== null) {
    offset += el[prop]
    el = el.offsetParent
  }
  return offset
}

function makeClone (el) {
  let clone = el.cloneNode(false)
  clone.style.pointerEvents = 'none'
  clone.style.height = el.offsetHeight + 'px'
  clone.style.width = el.offsetWidth + 'px'
  clone.style.flexShrink = false
  clone.style.visibility = 'hidden'
  clone.removeAttribute('id')
  return clone
}

function incrementZindex (el) {
  let currentZ = window.getComputedStyle(el)['z-index']
  if (isNaN(currentZ)) { currentZ = 0 }
  el.style.zIndex = currentZ + 1
}

// add to elements to make them stick to top instead of scrolling past
Vue.directive('sticky', {
  inserted (el, { value }) {
    let totalOffsetTop = getTotalOffset(el)
    el.dataset.top = totalOffsetTop
    value = value || 10
    let absoluteTop = el.offsetTop + 'px'
    let absoluteLeft = el.offsetLeft + 'px'
    let fixedTop = value + 'px'
    let fixedLeft = getTotalOffset(el, 'offsetLeft') + 'px'

    let clone = makeClone(el)
    el.parentElement.insertBefore(clone, el)

    el.classList.add('st-sticky')
    el.classList.add('st-sticky--sidebar-closed')
    incrementZindex(el)

    // throttle to at most 50x per second
    window.addEventListener('scroll', throttle(setAbsoluteOrFixed, 20))
    setAbsoluteOrFixed()

    function setAbsoluteOrFixed () {
      // console.log('log')
      if (window.scrollY >= totalOffsetTop - value) {
        el.classList.add('st-sticky--fixed')
        el.style.top = fixedTop
        el.style.left = fixedLeft
      } else {
        el.classList.remove('st-sticky--fixed')
        el.style.top = absoluteTop
        el.style.left = absoluteLeft
      }
    }
    // el.onresize = () => { window.setTimeout(setAbsoluteOrFixed, 50) }
    // window.setTimeout(() => { console.log('yes') }, 500)
    // window.setTimeout(() => { el.onresize = () => { console.log('x') } }, 5000)
  }
})

const scrollBuffer = 5
// add to elements you only want to appear after a specific scroll distance
// the passed value should be an array of elements, when we reach the lowest one we display this element
Vue.directive('sticky-header', {
  update (el, { value: anchors }) {
    if (el.dataset.stickied) { return }
    if (anchors.some(anchor => !anchor)) { return }

    el.stickied = true
    anchors = anchors.map(anchor => anchor.$el || anchor)
    let offsets = anchors.map(anchor => anchor.dataset.top || getTotalOffset(anchor))
    let maxOffset = max(offsets)

    showOrHide()
    window.addEventListener('scroll', showOrHide)

    function showOrHide () {
      if (window.scrollY >= maxOffset - scrollBuffer) {
        el.style.visibility = 'visible'
      } else {
        el.style.visibility = 'hidden'
      }
    }
  }
})

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
      var vm = vnode.context;
      var callback = binding.value;

      el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
              return callback.call(vm, event);
          }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})
