<script>
// Intended to be used for option

export default {
  methods: {
    deleteOption (id) {
      this.toggleFullSpinner(true)
      this.$axios({
        url: `/question_options/${id}`,
        method: 'delete'
      })
        .then(res => {
          const idx = this.activeQuestion.options.findIndex(option => option.id === id)
          const newOptions = [...this.activeQuestion.options]
          newOptions.splice(idx, 1)
          this.mergeActiveQuestion(res.data.question)
          this.toggleFullSpinner(false)
        })
        .catch(() => { this.toggleFullSpinner(false) })
    }
  }
}
</script>
