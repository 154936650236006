<template>
  <div>
    <st-input
      v-model="description"
      class="st-specifications__section-spacing--small"
      :disabled="isReadOnly"
      label="Question Text"
      name="question[description]"
      placeholder="Choose your favorite design concept"
      @blur="updateQuestion('description', activeQuestion.description)"
    />

    <span
      v-if="options.length < 3"
      style="color:red"
    > Upload Min 3 and Max 12 Images
    </span>
    <draggable
      v-model="options"
      v-bind="dragOptions"
      class="st-specifications__draggable-options"
      @change="handleDrop"
    >
      <transition-group>
        <template v-if="options.length > 0">
          <st-upload-preview
            v-for="(option, index) in options"
            :key="`media-preview-${option.id}`"
            :class="{
              'st-specifications__draggable-tile': true,
              'st-specifications__draggable-tile--extra-space': index === options.length - 1,
            }"
            :disabled="isReadOnly"
            :filename="fileNameFromUrl(option.mediaUrl)"
            :hide-bar="true"
            :src="option.mediaUrl"
            :input="true"
            :option-id="option.id"
            :value="option.name"
            @nameChange="changeImageName"
            @remove="deleteOption(option.id)"
          />
        </template>
      </transition-group>
    </draggable>
    <st-hyperlink
      :disabled="isReadOnly || options.length>11"
      :class="{
        'st-specifications__upload-link--image-board st-image-board__upload-link': true,
        'st-specifications__upload-link--image-board-with-options': options.length > 0,
      }"
      icon-class="st-hyperlink__default-icon-spacing"
      icon-name="upload"
      :text="options.length>2? 'Upload More Images': 'Upload Images' "
      style="margin-top:10px;"
      @click="openUploader"
    />
    <st-media-uploader
      v-if="isUploadOpen"
      :active-question="this.activeQuestion"
      :accept="acceptedFileTypes"
      :additional-props="additionalProps"
      :folders="folders"
      modal-title="Upload Media or Select From Library"
      :multi-upload="false"
      prop-name="question_option[media]"

      url="/question_options"
      @closeMediaUploader="isUploadOpen = false"
      @uploadComplete="uploadComplete"
    />

    <!-- <p>Would you like to ask for an explaination?</p>
    <st-radio-button
      v-model="askForExplanation"
      class="st-radio-button__default-container"
      :disabled="isReadOnly"
      :options="[
        { caption: 'No', value: 'no_explanation', iconName: 'dont'},
        { caption: 'Yes, Voice', value: 'voice_recording', iconName: 'microphone'},
        { caption: 'Yes, Typed', value: 'text', iconName: 'keyboard' }
      ]"
    /> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import { StHyperlink, StInput, StMediaUploader, StUploadPreview, StRadioButton } from '../../../common'
import fileName from '../../../../mixins/fileName'
import updateQuestion, { actionSetter, mutationSetter } from '../../../../mixins/question/updateQuestion.vue'
import { isDesktop, updateQuestionOption, additionalProps, deleteOption, uploadComplete } from '../../../../mixins'

export default {
  mounter: () => {
    this.$store.commit('toggleFullSpinner', true)
  },
  components: {
    draggable,
    StHyperlink,
    StInput,
    StMediaUploader,
    StUploadPreview,
    StRadioButton
  },
  mixins: [fileName, isDesktop, updateQuestion, updateQuestionOption, additionalProps, deleteOption, uploadComplete],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: function () {
    return {
      acceptedFileTypes: '.jpg, .jpeg, .png',
      isUploadOpen: false
    }
  },

  computed: {
    ...actionSetter(['askForExplanation']),
    ...mutationSetter(['description', 'options']),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        forceFallback: true
      }
    },
    highestOptionPriority () {
      const length = this.activeQuestion.options.length
      return length === 0 ? 0 : this.activeQuestion.options[length - 1].priority
    }
  },
  methods: {
    ...mapMutations(['toggleFullSpinner']),
    ...mapMutations('specifications', ['mergeActiveQuestion', 'replaceActiveQuestion']),
    handleDrop () {
      let updatedOptions = this.options.map((option, index) => {
        if (option.priority !== (index + 1)) {
          this.updateQuestionOption(option.id, index, { priority: (index + 1) }, true)
        }
        return { ...option, priority: (index + 1) }
      })
      this.mutateQuestion('options', updatedOptions)
    },
    changeImageName (data) {
      let options = this.activeQuestion.options.map((option) => {
        if (option.id === data.id) {
          this.updateQuestionOption(data.id, null, { name: data.name }, true)
          return { ...option, name: data.name }
        }
        return option
      })

      this.mutateQuestion('options', options)
    },
    openUploader () {
      if (this.isReadOnly) { return }
      this.isUploadOpen = true
    },
    saveName () {

    }

  }
}
</script>
