<template>
  <div class="bx--grid">
    <div class="bx--row">
      <div class="bx--col">
        <div class="st-review-request__box-top">
          <st-hyperlink
            class="back-hyperlink-spacing--extra-space"
            :back-link="true"
            text="Back"
            :url="backLink"
          />
          <h1 class="page-header st-request-audience__header">
            Review Your Order
          </h1>
        </div>
      </div>
      <div class="st-review-request__main-content-wrapper">
        <div class="st-review-request__illustration" />
        <div class="bx--col-set bx--col-xs-12 bx--col-md-7 bx--offset-lg-4 bx--col-lg-5 bx--offset-xl-4 bx--col-xl-4">
          <receipt
            class="st-review-request__receipt"
            scroll-area-class="st-review-request__receipt-scroll-area"
          />
        </div>
        <div class="bx--col-set bx--col-xs-12 bx--col-md-5 bx--col-lg-3 bx--col-xl-4">
          <p class="st-review-request__info-text">
            It will take 5-7 days for us to collect your audience. We will invoice you offline for your order.
          </p>
        </div>
      </div>
      <div class="bx--col-set bx--col-xs-12 bx--col-md-7 bx--offset-lg-4 bx--col-lg-5 bx--offset-xl-4 bx--col-xl-4">
        <st-button
          class="st-review-request__button"
          caption="Request Audience"
          @click="requestAudience"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { StButton, StHyperlink } from '../../../common'
import Receipt from './receipt'

export default {
  components: {
    Receipt,
    StButton,
    StHyperlink
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    backLink () {
      return this.request.phase === 'draft'
        ? `/audience_requests/${this.request.id}/edit`
        : `/audience_requests`
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
    this.replaceData(this.request)
  },
  methods: {
    ...mapMutations('requestAudience', ['replaceData']),
    ...mapActions('requestAudience', ['updatePhase']),
    requestAudience () {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'patch',
        url: `/audience_requests/${this.request.id}/submit_request`
      })
        .then(() => {
          window.location.href = `/audience_requests/${this.request.id}/request_complete`
        })
        .catch(() => {
          console.log('error')
        })
    }
  }
}
</script>
