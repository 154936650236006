<template>
  <component
    :is="iconName"
    ref="icon"
    class="st-icon"
    :class="modifierClasses"
  />
</template>

<script>
import { iconMap, iconNames } from '../../icons'

export default {
  components: iconMap,
  props: {
    actionable: {
      type: Boolean,
      default: true,
      required: false
    },
    iconName: {
      type: String,
      required: true,
      validator: (val) => iconNames.has(val)
    },
    invert: {
      type: Boolean,
      default: false,
      required: false
    },
    neutral: {
      type: Boolean,
      default: false,
      required: false
    },
    spacetricsBlue: {
      type: Boolean,
      default: false,
      required: false
    },
    spacetricsOrange: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    modifierClasses () {
      return {
        'st-icon--state-spacetrics-blue': this.spacetricsBlue,
        'st-icon--state-spacetrics-orange': this.spacetricsOrange,
        'st-icon--state-inactionable': !this.actionable,
        'st-icon--state-invert': this.invert,
        'st-icon--state-neutral': this.neutral
      }
    }
  },
  methods: {
  }
}
</script>
