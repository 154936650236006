<template>
  <div class="st-sign-in bx--col-xs-12 bx--col-sm-6 bx--offset-sm-3">
    <div class="st-sign-in__header-row">
      <h1>
        Sign Up
      </h1>
      <div class="st-sign-in__sign-up">
        <div class="st-sign-in__help-text">
          Already have an account?
        </div>
        <st-hyperlink
          text="Sign In"
          url="/users/sign_in"
          class="st-sign-in__sign-up-link"
          icon-name="sign-in"
        />
      </div>
    </div>
    <form
      id="new_user"
      action="/users"
      accept-charset="UTF-8"
      method="post"
      class="new_user"
    >
      <input
        type="hidden"
        name="authenticity_token"
        :value="token"
      >
      <st-input
        v-model="user.firstName"
        class="st-sign-up__input"
        label="First Name"
        name="user[first_name]"
        :autofocus="true"
      />
      <st-input
        v-model="user.lastName"
        class="st-sign-up__input"
        label="Last Name"
        name="user[last_name]"
      />
      <st-tooltip
        class="st-participant__invalid-email-tooltip"
        :always-visible="showEmailError"
        arrow-class="st-participant__invalid-email-tooltip-arrow"
        direction="bottom"
        :is-active="showEmailError"
        :max-width="isDesktop ? 411 : 270"
        message-class="st-participant__invalid-email-tooltip-message"
        tip="Make sure your email has a @ and it ends in a valid email server"
      >
        <st-input
          v-model="user.email"
          class="st-sign-up__input"
          autocomplete="email"
          :label="emailInputLabel"
          name="user[email]"
          :variant="emailInputVariants"
          @blur="handleEmailInputFocusOut"
          @input="checkIfEmailInputIsValid"
        />
      </st-tooltip>
      <st-input
        v-model="password"
        class="st-sign-up__input"
        label="Password"
        name="user[password]"
        type="password"
        autocomplete="new-password"
        :new-password="true"
        :password="true"
      />
      <div class="st-sign-up__button-row">
        <div class="st-sign-up__terms-check">
          <st-checkbox
            v-model="terms"
          />
          <div class="st-sign-up__terms-text">
            I agree to
            <span
              class="st-sign-up__terms"
              @click="goToTerms"
            >
              Spacetrics Terms
            </span>
          </div>
        </div>

        <st-button
          type="submit"
          caption="Sign Up"
          icon-name="sign-up"
          :disabled="!formReady"
        />
      </div>
    </form>
    <div
      id="Intercom"
      class="st-intercom__button"
    >
      <icon-wrapper
        class="st-intercom__icon"
        icon-name="intercom"
        :invert="true"
      />
    </div>
  </div>
</template>

<script>
import { isDesktop } from '../../../mixins'
import { isValidEmail } from '../../../utilities/validation_utilities'
import { IconWrapper, StButton, StCheckbox, StHyperlink, StInput, StTooltip } from '../../common'

export default {
  components: {
    IconWrapper,
    StInput,
    StButton,
    StCheckbox,
    StHyperlink,
    StTooltip
  },
  mixins: [isDesktop],
  props: {
    email: {
      type: String,
      required: true
    },
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      showEmailError: false,
      password: '',
      terms: false,
      user: {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName
      }
    }
  },
  computed: {
    emailInputLabel () {
      return this.showEmailError ? 'Email is not valid' : 'Email'
    },
    emailInputVariants () {
      return this.showEmailError ? ['persistLabelAbove', 'invalid'] : ['']
    },
    formReady () {
      return this.terms && this.isEmailValid && this.user.firstName.length > 0 && this.user.lastName.length > 0 && this.password.length > 6
    },
    isEmailValid () {
      return isValidEmail(this.user.email)
    },
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
    checkIfEmailInputIsValid () {
      if (this.showEmailError && this.isEmailValid) { this.showEmailError = false }
    },
    goToTerms () {
      window.open('/terms', '_blank')
    },
    handleEmailInputFocusOut () {
      this.showEmailError = !this.isEmailValid
      if (this.user.email === '') { this.showEmailError = false }
    }
  }
}
</script>
