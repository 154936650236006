<template>
  <div class="pick-favorite__container">
    <div
      :class="{
        'pick-favorite': true,
        '__2': options.length === 2,
        '__4': options.length === 4
      }"
    >
      <div
        v-for="image in options"
        :key="image.optionId"
        :style="{
          background: backgroundProp(image.imageUrl),
          backgroundSize: '100% 100%'
        }"
        :class="{
          'pick-favorite__image-2': options.length === 2,
          'pick-favorite__image-4': options.length === 4,
          'is-selected': selectedId == image.optionId,
          'unselected': options.length===2 && selectedId != null && selectedId != image.optionId,
          'first-image': options.length === 4 && selectedId === options[0].optionId,
          'second-image': options.length === 4 && selectedId === options[1].optionId,
          'third-image': options.length === 4 && selectedId === options[2].optionId,
          'fourth-image': options.length === 4 && selectedId === options[3].optionId,
        }"
        @click="handleSelect(image.optionId)"
      >
        <div
          v-if="image.name"
          class="pick-favorite__image-4-gradient"
        >
          {{ image.name }}
        </div>
      </div>
    </div>
    <div
      class="drag-container__submit"
    >
      <cv-button
        v-if="isSurveyPreview && priority > 0"
        kind="secondary"
        class="st-question__preview-back-btn"
        @click="handlePreviewBack"
      >
        <div class="st-page__btn-slot">
          <div class="st-svg__left-chevron" />
          <div>Back</div>
        </div>
      </cv-button>
      <cv-button
        v-if="selectedId != null || isSurveyPreview"
        class="next-btn"
        @click="submitQuestionAnswer"
      >
        <div>
          Next
          <div class="btn-icon" />
        </div>
      </cv-button>
    </div>
  </div>
</template>

<script>
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import previousResponse from '../../../mixins/previousResponse'
import handleSelect from '../../../mixins/handleSelect.vue'
import submitQuestionAnswer from '../../../mixins/question/submitQuestionAnswer.vue'
import handlePreviewBack from '../../../mixins/handlePreviewBack.vue'

export default {
  components: {
    'cv-button': CvButton
  },
  mixins: [handleSelect, submitQuestionAnswer, handlePreviewBack, previousResponse],
  props: {
    options: {
      type: Array,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    priority: {
      type: Number,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      selectedId: null
    }
  },
  computed: {
  },
  methods: {
    loadResponses () {
      if (this.questionResponses && this.questionResponses.length > 0) {
        this.$nextTick(() => {
          this.selectedId = Number(this.questionResponses[0].value)
        })
      }
    },
    backgroundProp (path) {
      return `url('${path}') center / cover no-repeat`
    },
    answerResponse () {
      return { value: this.selectedId }
    }
  }
}
</script>
