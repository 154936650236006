var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"st-tooltip__container",on:{"click":function($event){return _vm.showTip(!_vm.displayTooltip)},"mouseenter":function($event){return _vm.showTip(true)},"mouseleave":function($event){return _vm.showTip(false)}}},[_vm._t("default",function(){return [_c('icon-wrapper',{attrs:{"icon-name":"info"}})]}),_vm._v(" "),_c('div',{ref:"tooltip",class:[{
      'st-tooltip__message': true,
      'st-flash-tooltip': _vm.flash
    }, _vm.messageClassObj()],style:(_vm.styleObject)},[(_vm.maintainVisibility)?_c('div',{staticClass:"st-tooltip__close-icon-container",attrs:{"tabindex":"0"},on:{"click":function($event){_vm.maintainVisibility = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.maintainVisibility = false}}},[_c('icon-wrapper',{staticClass:"st-tooltip__close-icon",attrs:{"icon-name":"plus","invert":true}})],1):_vm._e(),_vm._v(" "),_vm._t("tip",function(){return [_vm._v("\n      "+_vm._s(_vm.tip)+"\n    ")]})],2),_vm._v(" "),_c('div',{class:[{
      'st-tooltip__arrow': true,
      'st-tooltip__arrow--flash st-flash-tooltip': _vm.flash
    }, _vm.arrowClassObj()],style:({
      visibility: _vm.visibility
    })})],2)}
var staticRenderFns = []

export { render, staticRenderFns }