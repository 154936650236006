<template>
  <div class="icon_edit">
    <div class="icon_edit__svg">
      <svg
        viewBox="0 0 16 16.009"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(0 .009)">
          <path
            d="M0 0h16v1H0z"
            transform="translate(0 15)"
          />
          <path
            d="M0 0h10v1H0z"
            transform="translate(0 .021)"
          />
          <path
            d="M0 0h1v15.979H0z"
            transform="translate(0 .021)"
          />
          <path
            d="M0 0h1v10H0z"
            transform="translate(15 6)"
          />
          <path
            d="M-.009 13.415L.029 0h5l-.042 13.411L2.4 16.045zm1-.388l1.433 1.566L3.989 13l.035-12h-3z"
            transform="rotate(45 6.168 14.89)"
          />
          <path
            d="M0 0h4v1H0z"
            transform="rotate(45 3.134 13.675)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
