<template>
  <div class="st-vertical-bar-graph">
    <div class="st-vertical-bar-graph__layout">
      <div
        ref="yAxisLabel"
        class="st-vertical-bar-graph__y-axis-label"
      >
        {{ yAxisLabel }}
      </div>
      <div
        ref="yAxisValues"
        class="st-vertical-bar-graph__y-axis-values"
      >
        <div
          v-for="(value, i) in yAxisIncrements"
          :key="`y-axis-${i}`"
        >
          {{ value }}
        </div>
      </div>
      <div class="st-vertical-bar-graph__body">
        <div
          v-for="index in bucketKeys"
          :key="`idx-${index}`"
          class="st-vertical-bar-graph__bar"
          :style="{
            height: barHeight(index),
            width: barWidth
          }"
        />
      </div>
    </div>
    <div
      class="st-vertical-bar-graph__x-axis"
      :style="{
        paddingLeft: xAxisPadding + 'px'
      }"
    >
      <div
        v-for="index in bucketKeys"
        :key="`x-axis-${index}`"
        class="st-vertical-bar-graph__x-axis-tick"
      />
      <div class="st-vertical-bar-graph__x-axis-tick" />
    </div>
    <div
      class="st-vertical-bar-graph__x-axis-labels"
      :style="{
        paddingLeft: xAxisPadding + 'px'
      }"
    >
      <!-- st-vertical-bar-graph__x-axis-label--rotate need class for five options -->
      <div
        v-for="(name, i) in bucketNames"
        :key="`x-label-${i}`"
        class="st-vertical-bar-graph__x-axis-label"
      >
        <template v-if="name.iconName">
          <icon-wrapper
            :icon-name="name.iconName"
            class="st-concept-ranking-insights__graph-emoji"
          />
        </template>
        <template v-else>
          <div :class="{'st-vertical-bar-graph__x-axis-label--rotate': bucketNames.length===5}">
            {{ name }}
          </div>
        </template>
      </div>
    </div>
    <div
      class="st-vertical-bar-graph__x-axis-overall-label"
      :style="{
        paddingLeft: xAxisPadding + 'px'
      }"
    >
      {{ xAxisLabel }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { IconWrapper } from '../../common'

export default {
  components: {
    IconWrapper
  },
  props: {
    bucketNames: {
      type: Array,
      required: true
    },
    bucketCounts: {
      type: Object,
      required: true
    },
    bucketKeys: {
      type: Array,
      required: true
    },
    responseCount: {
      type: Number,
      required: true
    },
    yAxisIncrements: {
      type: Array,
      required: true
    },
    yAxisLabel: {
      type: String,
      required: true
    },
    xAxisLabel: {
      type: String,
      required: false,
      default: 'Scale'
    }
  },
  data: function () {
    return {
      xAxisPadding: 93
    }
  },
  computed: {
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingParticipantCheckboxFilter']),
    barWidth () {
      if (this.bucketNames.length === 3) {
        return '22%'
      } else if (this.bucketNames.length === 5) {
        return '13%'
      } else {
        return '118px'
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setXAxisPadding()
    })
  },
  beforeDestroy () {
  },
  methods: {
    barHeight (key) {
      let percent = 100 * this.bucketCounts[key] / this.responseCount
      return `${percent}%`
    },
    setXAxisPadding () {
      this.xAxisPadding = this.$refs.yAxisLabel.clientWidth + this.$refs.yAxisValues.clientWidth + 15
    }
  }
}
</script>
