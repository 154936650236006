<template>
  <div class="st-prospectives__pricing-comparison">
    <div class="bx--grid st-prospectives__row-comparison">
      <div class="st-prospectives__pricing-comparison-box">
        <h2
          class="st-prospectives__heading-block st-prospectives__heading-comparison"
        >
          Plan Comparison
        </h2>
        <div class="st-prospectives__table-container">
          <table class="st-data-table st-prospectives__table-plan">
            <thead>
              <tr>
                <th>Features</th>
                <th>Basic Plan</th>
                <th>Premium Plan</th>
                <th>Development Plan</th>
              </tr>
            </thead>
            <tbody class="st-data-table__body">
              <tr
                v-for="(feature, index) in features"
                :key="index"
              >
                <td>{{ feature.name }}</td>
                <td>
                  <icon-wrapper
                    v-if="feature.plans.find((plan) => plan === 'basic')"
                    icon-name="check"
                    class="st-prospectives__check-circle st-prospectives__check-circle-plan"
                  />
                  <icon-wrapper
                    v-else
                    icon-name="plus"
                    class="st-prospectives__check-circle st-prospectives__cross-circle"
                  />
                </td>
                <td>
                  <icon-wrapper
                    v-if="feature.plans.find((plan) => plan === 'premium')"
                    icon-name="check"
                    class="st-prospectives__check-circle st-prospectives__check-circle-plan"
                  />
                  <icon-wrapper
                    v-else
                    icon-name="plus"
                    class="st-prospectives__check-circle st-prospectives__cross-circle"
                  />
                </td>
                <td>
                  <icon-wrapper
                    v-if="feature.plans.find((plan) => plan === 'development')"
                    icon-name="check"
                    class="st-prospectives__check-circle st-prospectives__check-circle-plan"
                  />
                  <icon-wrapper
                    v-else
                    icon-name="plus"
                    class="st-prospectives__check-circle st-prospectives__cross-circle"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconWrapper } from '../../common'
import { PLAN_COMPARISON_FEATURES } from '../../../utilities/plan_comparison'

export default {
  components: {
    IconWrapper
  },
  data: function () {
    return {
      features: PLAN_COMPARISON_FEATURES
    }
  }
}
</script>

<style></style>
