<template>
  <div class="icon_preferences">
    <div class="icon_preferences__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 2.5A2.5 2.5 0 112.5 0 2.5 2.5 0 015 2.5zm-1 0A1.5 1.5 0 102.5 4 1.5 1.5 0 004 2.5z"
          transform="translate(1)"
        />
        <path
          d="M5 2.5A2.5 2.5 0 112.5 0 2.5 2.5 0 015 2.5zm-1 0A1.5 1.5 0 102.5 4 1.5 1.5 0 004 2.5z"
          transform="translate(9 5)"
        />
        <path
          d="M5 2.5A2.5 2.5 0 112.5 0 2.5 2.5 0 015 2.5zm-1 0A1.5 1.5 0 102.5 4 1.5 1.5 0 004 2.5z"
          transform="translate(4 11)"
        />
        <path
          d="M0 0h10.623v1H0z"
          transform="translate(5.377 2)"
        />
        <path
          d="M0 0h1.6v1H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h2.572v1H0z"
          transform="translate(13.428 7)"
        />
        <path
          d="M0 0h9.746v1H0z"
          transform="translate(0 7)"
        />
        <path
          d="M0 0h7.734v1H0z"
          transform="translate(8.266 13)"
        />
        <path
          d="M0 0h4.307v1H0z"
          transform="translate(0 13)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
