<template>
  <div
    v-if="participant.avatarUrl"
    class="st-insights__avatar-image"
    :style="avatarStyle"
  />
  <div
    v-else
    class="st-insights__transcript-avatar"
    :style="{
      backgroundColor: backgroundColor
    }"
  >
    {{ initials }}
  </div>
</template>

<script>
export default {
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    avatarStyle () {
      if (this.participant.avatarUrl) {
        return {
          background: `url('${this.participant.avatarUrl}') center / contain no-repeat`
        }
      } else {
        return {}
      }
    },
    backgroundColor () {
      let colors = ['#52be80', '#5b2c6f', '#0b5345', '#566573', '#2e86c1', '#797d7f', '#633974', '#873600', '#7d3c98', '#943126', '#17202a', '#a93226', '#3498db', '#58d68d', '#b03a2e', '#196f3d', '#cb4335', '#7b7d7d', '#117864', '#ba4a00', '#27ae60', '#7b241c', '#d68910', '#145a3', '#935116', '#9b59b6', '#0e6251', '#5d6d7e', '#8e44ad', '#e67e22', '#4a235a', '#1d8348', '#0e6655', '#616a6b', '#6e2c00', '#212f3c', '#17a589', '#c0392b', '#884ea0', '#784212', '#76448a', '#2980b9', '#229954', '#922b21', '#21618c', '#154360', '#6c3483', '#2471a3', '#5f6a6a', '#dc7633', '#1b2631', '#34495e', '#f39c12', '#1abc9c', '#117a65', '#512e5f', '#b9770e', '#48c9b0', '#186a3', '#ca6f1e', '#283747', '#d35400', '#78281f', '#2ecc71', '#f1c40f', '#2c3e50', '#1f618d', '#1a5276', '#212f3d', '#148f77', '#1b4f72', '#239b56', '#515a5a', '#e74c3c', '#7e5109', '#6a085', '#af601a', '#45b39d', '#626567', '#424949', '#28b463', '#2e4053', '#a04000', '#138d75', '#1e844', '#2874a6', '#273746', '#641e16', '#9c640c', '#1c2833', '#4d5656']
      if (this.participant.responseId) {
        return colors[this.participant.responseId % colors.length]
      } else {
        return colors[this.participant.id % colors.length]
      }
    },
    initials () { // eventually add logic for anonymous surveys
      if (this.participant.firstName && this.participant.lastName) {
        return this.participant.firstName[0] + this.participant.lastName[0]
      } else if (this.participant.email) {
        return this.participant.email[0]
      } else {
        return 'AU'
      }
    }
  },
  methods: {

  }
}
</script>
