<template>
  <a
    :href="url"
    :class="{
      'st-hyperlink': true,
      'st-hyperlink--hover': hover,
      'st-hyperlink--focus': focus,
      'st-hyperlink--invert': invert,
      'st-hyperlink--disabled': disabled
    }"
    tabindex="0"
    @keyup.enter="handleNewPath"
    @mouseenter="hover=true"
    @mouseleave="hover=false"
    @click.prevent="handleNewPath"
    @focus="focus = true"
    @focusout="focus = false"
  >
    <div
      class="st-hyperlink__wrap-information"
      :class="{'st-hyperlink__wrap-information--back': backLink}"
    >
      <icon-wrapper
        v-if="backLink"
        :class="{
          'st-hyperlink__back-icon': true,
          'st-hyperlink__back-icon--hover': hover,
        }"
        icon-name="chevron"
        :neutral="!hover && !focus"
      />
      <div
        ref="stHyperlink"
        tabindex="-1"
        :class="[{
          'st-hyperlink__contents': true,
          'st-hyperlink__contents--hover': hover,
          'st-hyperlink__contents--focus': focus,
          'st-hyperlink__contents--invert': invert,
          'st-hyperlink__back-link-contents': backLink,
          'st-hyperlink__back-link-contents--hover': backLink && hover,
          'st-hyperlink__back-link-contents--focus': backLink && focus,
          'st-hyperlink__contents--disabled': disabled
        }, contentsClass]"
      >
        <div>{{ text }}</div>
        <icon-wrapper
          v-if="iconName"
          :icon-name="iconName"
          :class="iconClass"
          :spacetrics-blue="blueIcon"
          :invert="invert"
        />
      </div>
    </div>
  </a>
</template>

<script>
import HandleNewPath from '../../mixins/handleNewPath'
import IconWrapper from './icon-wrapper'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [HandleNewPath],
  props: {
    /** Variant of st-hyperlink (ex: "< back") */
    backLink: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used to change the style of the main content of st-hyperlink */
    contentsClass: {
      type: String,
      requried: false,
      default: ''
    },
    /** Blocks the hyperlink from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used to change the styling of st-hyperlink icon */
    iconClass: {
      type: String,
      requried: false,
      default: 'st-hyperlink__icon'
    },
    /** Used by iconWrapper to display icon right of text */
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    /** Change the color of st-hyperlink to white */
    invert: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Text displayed in st-hyperlink */
    text: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      focus: false,
      hover: false
    }
  },
  computed: {
    blueIcon () {
      if (this.invert || this.disabled) { return false }

      return this.hover || this.focus
    }
  }
}
</script>
