<template>
  <record-members
    :class="{
      'st-record-members--locked-bottom': isDesktop,
      'st-record-members--mobile': !isDesktop
    }"
    :can-add-record-member="canEdit"
    :can-open-dropdown="isUserAdmin"
    :members="memberUsers"
    :project-id="projectId"
    title="Project"
    :users="users"
    @invite-members="inviteProjectMembers"
    @removeMember="$emit('removeMember', $event)"
    @updateMember="$emit('updateMember', $event)"
  >
    <template v-slot="{ openMembers }">
      <st-hyperlink
        class="st-record-members__primary-button-link"
        :text="closedCaption"
        icon-name="multiple-members"
        :invert="true"
        @click.native.stop="openMembers"
      />
    </template>
  </record-members>
</template>

<script>
import { mapState } from 'vuex'
import { isUserAdmin, isDesktop } from '../../../mixins'
import { User } from '../../../structures/user'
import RecordMembers from './record-members'
import { StHyperlink } from '../../common'

export default {
  components: {
    RecordMembers,
    StHyperlink
  },
  mixins: [isUserAdmin, isDesktop],
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState(['currentUser']),
    closedCaption () {
      let count = this.members.length
      switch (count) {
        case 0:
          return `${this.isUserAdmin ? 'Invite' : 'View'} Members`
        case 1:
          return `1 member`
        default:
          return `${count} members`
      }
    },
    memberUsers () {
      return this.members.map(User)
    }
  },
  methods: {
    existingUsersPromise (existingUsersToAdd) {
      if (existingUsersToAdd.length > 0) {
        return this.$axios.request({
          method: 'post',
          url: `/projects/${this.projectId}/users`,
          data: {
            ids: existingUsersToAdd.map(u => u.id)
          }
        })
      } else {
        return this.$axios.request({
          method: 'get',
          url: `/projects/${this.projectId}`
        })
      }
    },
    inviteProjectMembers ({ existingUsersToAdd, newEmailsToAdd }) {
      this.newUsersPromise(newEmailsToAdd)
        .then(() => this.existingUsersPromise(existingUsersToAdd))
        .then(res => {
          let newMembers = res.data.users.map(User)
          this.$emit('updateMembers', newMembers)
        })
    },
    newUsersPromise (newEmailsToAdd) {
      if (newEmailsToAdd.length > 0) {
        return this.$axios.request({
          method: 'post',
          url: '/users/invite',
          data: {
            emails: newEmailsToAdd,
            project_ids: [this.projectId],
            kind: 'member'
          }
        })
      } else {
        return Promise.resolve()
      }
    }
  }
}
</script>
