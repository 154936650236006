<template>
  <div class="icon_arrowup">
    <div class="icon_arrow__svg">
      <svg
        viewBox="0 0 16.019 11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 0l7.53.153.018.892L.018.892z"
            transform="rotate(45 5.341 12.894)"
          />
          <path
            d="M0 1.021L7.527.868l.018-.892-7.53.153z"
            transform="rotate(-45 17.322 -6.734)"
          />
          <path
            d="M0 0h14.9v1H0z"
            transform="translate(0 5)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
