<template>
  <div class="st-slides">
    <div
      ref="slidesMobile"
      class="st-slides__canvas-main"
      style=" border:none; border-style: hidden; overflow-y:scroll"
    >
      <div
        v-for="(templateVal, templateKey, index) in templates"
        :key="'pr'+index"
        :class="{
          'st-slides__block': true,
          'st-slides__block--active': currentTemplate === templateKey,
          'st-slides__block--1x1' : templateKey === '1x1',
          'st-slides__block--1x2' : templateKey === '1x2',
          'st-slides__block--2x1' : templateKey === '2x1',
          'st-slides__block--1x3' : templateKey === '1x3'
        }"
        :style="{backgroundColor: slideColors[index], }"
        style="border:none; overflow-y: scroll;"
      >
        <!-- eslint-disable -->
        <span
          v-for="(text, i) in texts"
          :key="'text'+i"
          :style="{position: 'absolute', left: text.xPosition+'%', top: text.yPosition+'%', zIndex: 99}"
          v-html="text.content"
        />
        <!-- eslint-enable -->
        <img
          v-for="(image,i) in images"
          :key="i"
          :style="{position: 'absolute', objectFit: 'cover', left: image.xPosition+'%', top: image.yPosition+'%', width: image.width+'%', height: image.height+'%', zIndex: 9}"
          :src="image.mediaUrl"
          alt=""
        >
        <template v-if="currentTemplate === templateKey">
          <div
            v-for="(val, boxIndx) in templateVal"
            :key="'preview'+val"
            :ref="'preview-box' + val"
            :class="{
              'st-slides__box': true,
            }"
            :style="{backgroundColor: slideColors[boxIndx]}"
          />
        </template>
      </div>
    </div>
    <div class="st-specifications__button-slides-canvas">
      <st-button
        class="st-slide__button"
        :caption="buttonCaption"
        :underline="false"
        icon-name="arrow"
        :variant="['secondary']"
        @click="submitQuestionAnswer"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SLIDES_TEMPLATES } from '../../../../utilities/enums'
import { StButton } from '../../../common'
import { submitQuestionAnswer } from '../../../../mixins'

export default {
  components: {
    StButton
  },
  mixins: [submitQuestionAnswer],
  props: {
    questionId: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: Function,
      required: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data: function () {
    return {
      templates: SLIDES_TEMPLATES
    }
  },
  computed: {
    ...mapState('participants', { participantActiveQuestion: 'activeQuestion' }),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    activeQuestion () {
      return this.isSpecsDesktopPreview ? this.specsActiveQuestion : this.participantActiveQuestion
    },
    isSpecsDesktopPreview () {
      return this.isSpecificationsPreview
    },
    currentTemplate () {
      return this.activeQuestion.slideTemplateKey
    },
    slideColors () {
      return this.activeQuestion.slideColors
    },
    objects () {
      return this.activeQuestion.slideQuestionObjects
    },
    texts () {
      return this.activeQuestion.slideQuestionObjects.filter(o => o.slideObjectType === 'text')
    },
    images () {
      return this.activeQuestion.slideQuestionObjects.filter(o => o.slideObjectType === 'image')
    }
  },
  methods: {
    answerData () {
      let answer = new FormData()
      answer.append('response[value]', '')
      return answer
    }
  }
}
</script>
