<template>
  <div
    class="st-sentiment-badge"
    :class="sentimentClass"
  >
    {{ sentimentString }}
  </div>
</template>

<script>
import { bucketIdx } from '../../../utilities/data_utilities'

const SENTIMENTS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

const SENTIMENT_CLASSES = [
  'st-sentiment-badge--very-negative',
  'st-sentiment-badge--negative',
  'st-sentiment-badge--neutral',
  'st-sentiment-badge--positive',
  'st-sentiment-badge--very-positive'
]

export default {
  props: {
    sentiment: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    sentimentClass () {
      return SENTIMENT_CLASSES[bucketIdx(this.sentiment)]
    },
    sentimentString () {
      return SENTIMENTS[bucketIdx(this.sentiment)]
    }

  },
  methods: {

  }
}
</script>
