var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-prospectives--demo"},[_c('div',{staticClass:"bx--grid st-prospectives__demo-grid"},[_c('div',{staticClass:"st-prospectives__demo-head"},[_c('div',{staticClass:"st-prospectives__demo-head-left"},[_c('a',{staticClass:"st-prospectives__demo-logo"},[_c('icon-wrapper',{attrs:{"icon-name":"spacetrics"}}),_vm._v(" "),_c('span',{staticClass:"st-layout__header-logo-name"},[_vm._v("spacetrics")])],1),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-head-content"},[_c('div',{staticClass:"st-prospectives__demo-dropdown-component"},[_c('span',{staticClass:"st-prospectives__demo-dropdown-text"},[_vm._v("For A")]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-dropdown"},[_c('div',{class:( _obj = {
                  'st-prospectives__demo-dropdown-image': true
                }, _obj[("st-prospectives__demo-dropdown-image--" + _vm.useCaseIndex)] = true, _obj )}),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm.useCases[_vm.useCaseIndex])+"\n              ")])])]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-dropdown-component"},[_c('span',{staticClass:"st-prospectives__demo-dropdown-text"},[_vm._v("Considering")]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-dropdown"},[_vm._v("\n              "+_vm._s(_vm.spaceTypes[_vm.spaceTypeIndex])+"\n            ")])])])]),_vm._v(" "),(_vm.isDesktop)?_c('div',{staticClass:"st-prospectives__toggle-canvas"},[_c('st-toggle',{staticClass:"st-prospectives__toggle",attrs:{"active-option":_vm.activeDemoView,"first-option":"Mobile","second-option":"Desktop"},on:{"updateOption":_vm.handleViewToggle}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-main"},[_c('div',{class:{
          'st-prospectives__demo-content': true,
          'st-prospectives__demo-content--desktop': !_vm.mobileDemoView
        }},[_c('a',{staticClass:"st-prospectives__link st-prospectives__back",attrs:{"href":"/prospective/how_it_works"}},[_c('icon-wrapper',{staticClass:"st-prospectives__back-icon",attrs:{"icon-name":"arrow"}}),_vm._v(" "),_c('span',{staticClass:"st-prospectives__back-text"},[_vm._v("Back")])],1),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-detail"},[(_vm.showSteps)?[_c('h1',{class:{
                'st-prospectives__heading st-prospectives__demo-main-heading': true,
                'st-prospectives__demo-main-heading--desktop': !_vm.mobileDemoView
              }},[_vm._v("\n              Before you begin the demo, follow these steps:\n            ")]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-steps"},[_c('h4',{class:{
                  'st-prospectives__demo-steps-heading': true,
                  'st-prospectives__demo-steps-heading--desktop': !_vm.mobileDemoView
                }},[_vm._v("\n                "+_vm._s(_vm.stepNumber)+"\n              ")]),_vm._v(" "),_c('p',{class:{
                  'st-prospectives__demo-steps-para': true,
                  'st-prospectives__demo-steps-para--desktop': !_vm.mobileDemoView
                }},[_vm._v("\n                "+_vm._s(_vm.stepDescription)+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"st-prospectives__demo-steps-bottom"},[_c('div',{staticClass:"st-prospectives__demo-dots-canvas"},[_c('span',{class:{
                      'st-prospectives__demo-dots': true,
                      'st-prospectives__demo-dots-current': _vm.stepCount === 1
                    },on:{"click":function($event){_vm.stepCount = 1}}}),_vm._v(" "),_c('span',{class:{
                      'st-prospectives__demo-dots': true,
                      'st-prospectives__demo-dots-current': _vm.stepCount === 2
                    },on:{"click":function($event){_vm.stepCount = 2}}})]),_vm._v(" "),_c('a',{staticClass:"st-prospectives__link st-prospectives__forward",on:{"click":_vm.handleStepAction}},[_c('span',{staticClass:"st-prospectives__forward-text"},[_vm._v("\n                    "+_vm._s(_vm.stepAction)+"\n                  ")]),_vm._v(" "),(_vm.stepCount === 1)?_c('icon-wrapper',{staticClass:"st-prospectives__forward-icon",attrs:{"icon-name":"arrow"}}):_vm._e()],1)])])]:_c('p',{class:{
              'st-prospectives__demo-assist-text': true,
              'st-prospectives__demo-assist-text--desktop': !_vm.mobileDemoView
            }},[_vm._v("\n            "+_vm._s(_vm.inDemoAssistText)+"\n          ")])],2)]),_vm._v(" "),_c('div',{class:{
          'st-prospectives__demo-intro': true,
          'st-prospectives__demo-intro--desktop': !_vm.mobileDemoView
        }},[(_vm.mobileDemoView)?_c('div',{staticClass:"st-prospectives__demo-intro-mobile"},[_c('div',{class:{
              'st-prospectives__demo-background': true,
              'st-prospectives__demo-background--disabled': _vm.questionPhase === _vm.PHASES.demoSteps
            }},[_vm._t("default")],2)]):_c('div',{staticClass:"st-prospectives__demo-intro-desktop"},[_c('div',{class:{
              'st-prospectives__demo-background': true,
              'st-prospectives__demo-background--disabled': _vm.questionPhase === _vm.PHASES.demoSteps
            }},[_vm._t("default")],2)]),_vm._v(" "),_c('div',{class:{
            'st-prospectives__demo-button-canvas': true,
            'st-prospectives__demo-button-canvas--desktop': !_vm.mobileDemoView
          }},[_c('st-button',{class:{
              'st-prospectives__button st-prospectives__demo-button st-button__set': true,
            },attrs:{"variant":['secondary'],"caption":"Exit & Submit","icon-name":"upload","disabled":_vm.showSteps,"show-action":true,"underline":false},on:{"click":_vm.handleSubmit}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }