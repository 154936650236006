<template>
  <div>
    <culture-board-transcripts v-if="activeQuestionView === 'Transcripts'" />
    <culture-board-results v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CultureBoardResults from './culture-board-results'
import CultureBoardTranscripts from './culture-board-transcripts'

export default {
  components: {
    CultureBoardResults,
    CultureBoardTranscripts
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionView'])
  }
}
</script>
