<template>
  <div class="icon_transcripts">
    <div class="icon_transcripts__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.023-.5H0v1h3.023z"
          transform="translate(10.629 3.5)"
        />
        <path
          d="M7-.5H0v1h7z"
          transform="translate(9 .5)"
        />
        <path
          d="M0 1h16V0H0z"
          transform="rotate(90 8 8)"
        />
        <path
          d="M0 1h8.5V0H0z"
          transform="rotate(90 -1.25 6.25)"
        />
        <path
          d="M12-.5H0v1h12z"
          transform="translate(4 15.5)"
        />
        <path
          d="M7.219-.5H0v1h7.219z"
          transform="translate(6.391 7.5)"
        />
        <path
          d="M7.219-.5H0v1h7.219z"
          transform="translate(6.391 10.029)"
        />
        <path
          d="M7.219-.5H0v1h7.219z"
          transform="translate(6.391 12.559)"
        />
        <path
          d="M-23419.684-166.5v6.225h-3.895l-1.742 1.775-.639-1.775h-1.725v-6.225zm-5.234 6.163l.92-.938h3.314v-4.225h-6v4.225h1.428z"
          transform="translate(23427.684 166.499)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
