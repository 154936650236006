<template>
  <div class="bx--grid">
    <div
      id="Intercom"
      class="st-intercom__button"
    >
      <icon-wrapper
        class="st-intercom__icon"
        icon-name="intercom"
        :invert="true"
      />
    </div>
    <div class="bx--col-xs-10 bx--offset-xs-1 bx--col-sm-6 bx--offset-sm-3">
      <div class="st-sign-in__header-row">
        <h2 class="st-sign-in__title">
          Sign in
        </h2>
        <div
          v-if="false"
          class="st-sign-in__sign-up"
        >
          <div class="st-sign-in__help-text">
            Don't have an account?
          </div>
          <st-hyperlink
            text="Sign Up"
            url="/users/sign_up"
            class="st-sign-in__sign-up-link"
            icon-name="sign-up"
            icon-class="st-hyperlink__default-icon-spacing"
          />
        </div>
      </div>
      <form
        id="new_user"
        action="/users/sign_in"
        accept-charset="UTF-8"
        method="post"
        class="new_user"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="token"
        >
        <div class="st-sign-in__input">
          <st-input
            v-model="email"
            label="Email"
            name="user[email]"
            placeholder="Your email goes here"
            :autofocus="true"
          />
        </div>
        <st-input
          v-model="password"
          label="Password"
          name="user[password]"
          type="password"
          :password="true"
          placeholder="Password goes here"
        />
        <div class="st-sign-in__remember-me">
          <st-checkbox
            v-model="rememberMe"
            name="user[remember_me]"
          >
            Remember Me
          </st-checkbox>
        </div>
        <div class="st-sign-in__submit-btn">
          <st-button
            type="submit"
            caption="Sign In"
            icon-name="sign-in"
          />
        </div>
      </form>
      <div class="st-page__shared-links">
        <p class="st-sign-in__reset-password">
          <span class="st-sign-in__help-text">
            Trouble logging in?
          </span>
          <st-hyperlink
            text="Reset my password"
            url="/users/password/new"
            class="st-sign-in__reset-password-link"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IconWrapper,
  StInput,
  StButton,
  StCheckbox,
  StHyperlink
} from '../../common'

export default {
  components: {
    IconWrapper,
    StInput,
    StButton,
    StCheckbox,
    StHyperlink
  },
  props: {
    savedEmail: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      rememberMe: false,
      email: this.savedEmail,
      password: ''
    }
  },
  computed: {
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
  }
}
</script>
