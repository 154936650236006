<template>
  <div class="icon_quadrantplacement">
    <div class="icon_quadrantplacement__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h7v1H0z" />
        <path
          d="M0 0h7v1H0z"
          transform="translate(0 9)"
        />
        <path
          d="M0 0h7v1H0z"
          transform="translate(0 6)"
        />
        <path
          d="M0 0h7v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M7 0H0v1h7z"
          transform="translate(9)"
        />
        <path
          d="M7 0H0v1h7z"
          transform="translate(9 9)"
        />
        <path
          d="M7 0H0v1h7z"
          transform="translate(9 6)"
        />
        <path
          d="M7 0H0v1h7z"
          transform="translate(9 15)"
        />
        <path d="M0 0h1v6H0z" />
        <path
          d="M0 0h1v6H0z"
          transform="translate(0 10)"
        />
        <path
          d="M0 0h1v7H0z"
          transform="translate(6)"
        />
        <path
          d="M0 0h1v7H0z"
          transform="translate(6 9)"
        />
        <path
          d="M0 0h1v7H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h1v7H0z"
          transform="translate(9 9)"
        />
        <path
          d="M1 0H0v6h1z"
          transform="translate(15)"
        />
        <path
          d="M1 0H0v6h1z"
          transform="translate(15 10)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
