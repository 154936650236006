<template>
  <div class="icon_notes">
    <div class="icon_notes__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 3)"
        />
        <path d="M0 0h16v1H0z" />
        <path
          d="M0 0h8v1H0z"
          transform="translate(5 12)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(3 12)"
        />
        <path
          d="M0 0h8v1H0z"
          transform="translate(5 9)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(3 9)"
        />
        <path
          d="M0 0h8v1H0z"
          transform="translate(5 6)"
        />
        <path
          d="M0 0h1v1H0z"
          transform="translate(3 6)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
        <path d="M0 0h1v16H0z" />
        <path
          d="M0 0h1v16H0z"
          transform="translate(15)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
