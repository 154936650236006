
function uniqueKeyForPair (response) {
  return `${response.surveyResponseId}-${response.questionOptionId}`
}

function translateContext (context) {
  switch (context) {
    case 'x_axis':
      return 'xValue'
    case 'y_axis':
      return 'yValue'
    default:
      return 'invalid context'
  }
}

function findOrInitializeDot (dotsById, resp) {
  let dot
  let key = uniqueKeyForPair(resp)
  if (dotsById.hasOwnProperty(key)) {
    dot = dotsById[key]
  } else {
    dot = {
      optionId: resp.questionOptionId,
      key: key
    }
    dotsById[key] = dot
  }
  return dot
}

export function sortIntoXYPairs (responses) {
  // initialize hash -> id: {id: ##, x: 123, y: 123}
  let dotsById = {}
  responses.forEach((resp) => {
    let dot = findOrInitializeDot(dotsById, resp)
    let prop = translateContext(resp.context)
    dot[prop] = resp.value
  })
  let allDots = Object.values(dotsById)
  return allDots
}
