<template>
  <div class="bx--row">
    <div class="bx--col-xs-10 bx--offset-xs-1 bx--col-sm-6 bx--offset-sm-3">
      <div class="st-reset-password__header-row">
        <h1>Change your password</h1>
      </div>

      <p class="st-reset-password__form-text">
        Passwords must be at least 6 characters
      </p>
      <form
        id="new_user"
        action="/users/password"
        accept-charset="UTF-8"
        method="post"
        class="new_user"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="token"
        >

        <input
          id="user_reset_password_token"
          type="hidden"
          name="user[reset_password_token]"
          :value="passwordToken"
        >

        <input
          type="hidden"
          name="_method"
          value="put"
        >

        <st-input
          v-model="password"
          label="New Password"
          name="user[password]"
          theme="light"
          :password="true"
          placeholder="New password goes here"
        />

        <st-input
          v-model="passwordConfirmation"
          label="Confirm New Password"
          name="user[password_confirmation]"
          theme="light"
          :password="true"
          placeholder="New password goes here"
        />
        <slot />

        <div class="st-reset-password__submit-btn">
          <st-button
            :disabled="password!==passwordConfirmation || password.length<6"
            type="submit"
            caption="Change Password"
            icon-name="arrow-up"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  StInput,
  StButton
} from '../../common'

export default {
  components: {
    StInput,
    StButton
  },
  props: {
    passwordToken: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      password: '',
      passwordConfirmation: ''
    }
  },
  computed: {
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
  }
}
</script>
