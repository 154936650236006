<template>
  <div class="bx--grid">
    <st-toast-notification
      v-if="showToast"
      kind="info"
      title="Info"
      :sub-title="toastMessage"
      @click="showToast = false"
    />
    <div class="mobile-header">
      <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          :caption="'Save & Continue'"
          :disabled="buttonDisabled"
          @click="saveAndToHub"
        />
      </div>
    </div>
    <div>
      <div class="bx--col-sm-12 bx--col-xs-12">
        <div class="space-between">
          <div>
            <div class="st-edit-survey__back-and-save">
              <st-hyperlink
                :back-link="true"
                text="Back"
                @click.native="saveAndToHub"
              />
              <div class="st-edit-survey__mobile-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
            </div>
            <div class="st-edit-survey__header-and-icon">
              <icon-wrapper
                class="st-edit-survey__header-icon"
                icon-name="participants"
              />
              <h1 class="st-edit-survey__page-header">
                Audience
              </h1>
            </div>
            <p
              :class="{
                'st-page-instructions st-edit-audience__header-spacing': true,
                'st-edit-audience__header-spacing--no-lists': !lists.length
              }"
            >
              {{ instructionsText }}
            </p>
          </div>
          <div>
            <div class="st-edit-survey__save-link-and-button">
              <div class="st-edit-survey__inline-spiner-and-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                  class="st-edit-survey__desktop-inline-spinner"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
              <st-button
                :caption="'Save & Continue'"
                :disabled="buttonDisabled"
                @click="saveAndToHub"
              />
            </div>
          </div>
        </div>
        <div class="st-edit-audience__editable-contents">
          <div v-if="lists.length > 0">
            <st-dropdown-menu
              class="st-edit-audience__lists-dropdown"
              caption="Audience Lists"
              :accordion-contents-values="{
                nestedProperty: 'participants',
                nestedValue: 'email'
              }"
              :active-toggle-disable="survey.published && !isEditingDisabled"
              :display-caption="true"
              :disable-checkbox="isEditingDisabled"
              :full-width="true"
              :initial-active-options-array="initialActiveOptionsArray"
              :options="lists"
              :option-caption-fn="(list) => list.name"
              placeholder="Select one or more"
              :select-multiple="true"
              @updateMultiple="updateLists"
            >
              <template v-slot:accordion-expanded-section="{value: options}">
                <div class="st-dropdown-menu__expanded-accordion-container">
                  <p
                    v-for="(participant, index) in options['participants']"
                    :key="'participant ' + index"
                  >
                    {{ participant.email }}
                  </p>
                </div>
              </template>
            </st-dropdown-menu>
            <p class="st-edit-audience__additional-instructions">
              You can select an audience list and also add individual emails
            </p>
          </div>
          <st-email-input
            ref="emailInput"
            :disabled="isEditingDisabled"
            email-area-class="st-edit-audience__emails-container"
            invite-text="survey"
            label="Individual Recipients' Emails"
            :existing-members-list="allAccountParticipants"
            :non-removable-list="nonRemovableEmails"
            :removable-list="removableEmails"
            recipient-text="Recipients'"
            variant="survey-audience"
            @addNewEmail="addNewEmail"
            @removeEmail="removeNewEmail"
          />
          <div class="st-edit-audience__anonymous-section">
            <div class="st-edit-audience__anonymous-instructions">
              Would you like to make this audience anonymous?
              <st-tooltip
                class="st-edit-audience__anonymous-tooltip"
                :direction="isDesktop ? 'top' : 'left'"
                :max-width="isDesktop ? 411 : 250"
                tip="Making an audience anonymous means we won’t display any identifiable information in your insights, such as email, first or last name."
              >
                <icon-wrapper
                  icon-name="info"
                />
              </st-tooltip>
            </div>
            <st-radio-button
              v-model="anonymousSurvey"
              class="st-radio-button__default-container"
              :disabled="survey.published || isEditingDisabled"
              :options="[
                { caption: 'Yes', value: true },
                { caption: 'No', value: false }
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isDesktop } from '../../../mixins'
import isEditingDisabled from './isEditingDisabled'
import saveUpdates from './saveUpdates'
import {
  IconWrapper,
  StButton,
  StDropdownMenu,
  StEmailInput,
  StHyperlink,
  StInlineSpinner,
  StRadioButton,
  StToastNotification,
  StTooltip
} from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StDropdownMenu,
    StEmailInput,
    StHyperlink,
    StInlineSpinner,
    StRadioButton,
    StToastNotification,
    StTooltip
  },
  mixins: [isDesktop, isEditingDisabled, saveUpdates],
  props: {
    allAccountParticipants: {
      type: Array,
      required: true
    },
    initialLists: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      anonymousSurvey: this.survey.anonymous,
      lists: this.initialLists.filter(list => { return list.participants.length }),
      listEmails: [],
      newEmails: [],
      surveyEmails: this.survey.responses.map(response => response.email)
    }
  },
  computed: {
    addListsToNewEmails () {
      return this.filteredNewEmailsByLists.concat(this.listEmails)
    },
    buttonDisabled () {
      return ((this.totalNewEmails.length === 0 && !this.isEditingDisabled) &&
      (this.survey.responses.length === 0 && !this.isEditingDisabled)) || (this.isEditingDisabled)
    },
    instructionsText () {
      return this.lists.length > 0
        ? 'Create an audience by selecting it from a list, typing your recipient’s emails, or both!'
        : 'Create an audience by typing your recipient’s emails.'
    },
    nonRemovableSurveyEmails () {
      if (this.survey.published) {
        return this.surveyEmails.filter(email => {
          if (this.initialEmailsInSelectedLists.includes(email)) { return }
          return email
        })
      } else {
        return []
      }
    },
    nonRemovableEmails () {
      return this.survey.responses.filter(response => {
        if (this.nonRemovableSurveyEmails.includes(response.email)) {
          return response
        }
      })
    },
    removableSurveyEmails () {
      if (!this.survey.published) {
        return this.surveyEmails.filter(email => !this.initialEmailsInSelectedLists.includes(email))
      } else {
        return []
      }
    },
    removableEmails () {
      return this.survey.responses.filter(response => {
        if (this.removableSurveyEmails.includes(response.email)) {
          return response
        }
      })
    },
    initialEmailsInAnyList () {
      let emails = this.lists.map(list => {
        return list.participants.map(participant => participant.email)
      }).flat(1)
      return Array.from(new Set(emails))
    },
    initialActiveOptionsArray () {
      let options = []
      this.lists.forEach(list => {
        let emails = list.participants.map(participant => participant.email)
        if (emails.every(email => this.surveyEmails.includes(email))) { options.push(list) }
      })
      return options
    },
    initialEmailsInSelectedLists () {
      let emails = this.initialActiveOptionsArray.map(list => {
        return list.participants.map(participant => participant.email)
      }).flat(1)
      return Array.from(new Set(emails))
    },
    initialEmailsNotListed () {
      return this.initialEmailsInSelectedLists.filter(email => !this.addListsToNewEmails.includes(email))
    },
    listEmailsToRemove () {
      return this.listResponsesToRemove.map(list => list.email)
    },
    listResponsesToRemove () {
      return this.survey.responses.filter(response => {
        if (this.initialEmailsNotListed.includes(response.email)) { return response }
      })
    },
    newEmailsArray () {
      return this.newEmails.map(value => value.email)
    },
    filteredNewEmailsByLists () {
      return this.newEmailsArray.filter(email => !this.listEmails.includes(email))
    },
    totalNewEmails () {
      return this.addListsToNewEmails.filter(email => !this.surveyEmails.includes(email))
    }
  },
  mounted () {
    this.listEmails = this.initialEmailsInSelectedLists
  },
  methods: {
    addNewEmail (payload) {
      this.newEmails.push(payload)
    },
    addNewEmailsToSurvey () {
      let url = `/surveys/${this.survey.id}/invite_emails`
      return this.$axios.request({
        url: url,
        method: 'post',
        data: { emails: this.totalNewEmails }
      })
        .then(res => { console.log('res', res) })
        .catch((err) => { console.log('error', err) })
    },
    removeListEmails () {
      return Promise.all(this.listEmailsToRemove.map(email => {
        let responseEmail = this.survey.responses.find(response => { return response.email === email })
        return this.removeNewEmail(responseEmail)
      }))
    },
    removeNewEmail (payload) {
      if (this.newEmails.indexOf(payload) > -1) {
        let emailToRemoveFromNew = this.newEmails.indexOf(payload)
        this.newEmails.splice(emailToRemoveFromNew, 1)
        return Promise.resolve()
      } else {
        return Promise.all(
          this.survey.responses.filter(member => member.email === payload.email).map(member => {
            let url = `/survey_responses/${payload.id}/`
            return this.$axios.request({
              url: url,
              method: 'delete',
              data: payload
            })
              .then(res => { console.log('response', res) })
              .catch((err) => { console.log('error', err) })
          })
        )
      }
    },
    updateSurveyAnonymity () {
      let url = `/surveys/${this.survey.id}`
      return this.$axios.request({
        url: url,
        method: 'patch',
        data: { survey: { anonymous: this.anonymousSurvey } }
      })
    },
    updateLists (payload, index) {
      let newEmails = []
      payload.forEach(value => {
        value.participants.forEach(participant => {
          if (newEmails.includes(participant.email)) { return }
          newEmails.push(participant.email)
        })
      })
      this.listEmails = newEmails
    },
    updateSurvey () {
      const reqs = []
      if (this.totalNewEmails.length) { reqs.push(this.addNewEmailsToSurvey()) }
      if (this.listEmailsToRemove) { reqs.push(this.removeListEmails()) }
      if (this.anonymousSurvey !== undefined) { reqs.push(this.updateSurveyAnonymity()) }
      return Promise.all(reqs)
    }
  }
}
</script>
