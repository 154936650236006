<template>
  <div class="icon_emoji_indifferent">
    <div class="icon_emoji_indifferent__svg">
      <svg
        viewBox="0 0 26.325 26.325"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(1876.723 4963.163)">
          <path
            d="M26.325 13.162A13.162 13.162 0 1113.162 0a13.162 13.162 0 0113.163 13.162zm-1 0a12.162 12.162 0 10-12.163 12.163 12.162 12.162 0 0012.163-12.163z"
            transform="translate(-1876.723 -4963.163)"
          />
          <path
            d="M1.5 0A1.5 1.5 0 110 1.5 1.5 1.5 0 011.5 0z"
            transform="translate(-1871 -4956)"
          />
          <path
            d="M1.5 0A1.5 1.5 0 110 1.5 1.5 1.5 0 011.5 0z"
            transform="translate(-1860 -4956)"
          />
          <path
            d="M0-.5h8.48v1H0z"
            transform="translate(-1867.846 -4947.958)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
