<template>
  <div class="bx--grid">
    <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
    </div>
    <div class="bx--col-xs-12">
      <st-hyperlink
        class="back-hyperlink-spacing"
        :back-link="true"
        text="Back"
        url="/templates"
      />
      <h1 class="page-header--maximum-space">
        New Template
      </h1>
    </div>
    <div class="bx--col-sm-6 bx--col-xs-12 bx--offset-sm-3">
      <st-input
        v-model="name"
        class="input-spacing"
        label="Template Name (required)"
        name="template[name]"
        placeholder="Template Name"
      />

      <st-input
        v-model="description"
        class="input-spacing"
        label="Template Description"
        name="template[description]"
        placeholder="What is this template used for?"
      />

      <div class="st-new-survey__create-button-container">
        <st-button
          caption="Create Template"
          icon-name="pointer"
          class="st-new-project__start-button"
          :disabled="isCreateDisabled"
          @click="createTemplate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IconWrapper, StButton, StHyperlink, StInput } from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInput
  },
  props: {
  },
  data: function () {
    return {
      name: '',
      description: ''
    }
  },
  computed: {
    ...mapState(['currentUser']),
    isCreateDisabled () {
      return !this.name
    }
  },
  methods: {
    createTemplate () {
      let url = '/templates/'
      let data = {
        name: this.name,
        description: this.description,
        user_id: this.currentUser.id,
        account_id: this.currentUser.accountId
      }
      this.$axios.request({
        url: url,
        method: 'post',
        data: data
      })
        .then(res => {
          window.location.href = `/templates/${res.data.id}/edit`
        })
        .catch(err => { console.log(err) })
    }
  }
}
</script>
