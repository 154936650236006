var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-email-input__container"},[_c('div',{staticClass:"st-email-input"},[(!_vm.emptyAndUnfocused)?_c('label',{class:{
        'st-email-input__emails-label--focus': true,
        'st-email-input__emails-label--error':
          _vm.errorMessage && !_vm.isEmailValid(_vm.newMemberEmail)
      },attrs:{"for":"newEmail"}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{class:[{ 'st-email-input__emails': true,
                 'st-email-input__emails--empty': _vm.emptyAndUnfocused,
                 'st-email-input__emails--disabled': _vm.disabled,
                 'st-email-input__emails--error':
                   _vm.errorMessage && !_vm.isEmailValid(_vm.newMemberEmail)},
               _vm.emailAreaClass],on:{"click":_vm.focusTextarea}},[_c('textarea',{ref:"emailInput",staticClass:"st-email-input__email-input",style:(_vm.textareaStyle),attrs:{"id":"newEmail","cols":"1","disabled":_vm.disabled,"rows":"1","wrap":"off"},domProps:{"value":_vm.newMemberEmail},on:{"focus":function($event){_vm.hasFocus = true},"input":_vm.handleInput,"keyup":_vm.handleKeyUp}}),_vm._v(" "),(_vm.emptyAndUnfocused && !_vm.disabled)?_c('div',{staticClass:"st-email-input__emails-label--empty"},[_vm._v("\n        "+_vm._s(_vm.recipientText)+" Emails\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.newEmailList.slice().reverse()),function(member,n){return _c('div',{key:'email-' + n,class:{
          'st-email-input__email-tag': true,
          'st-email-input__email-tag--error': member.error,
        }},[_c('div',[_vm._v(_vm._s(member.email))]),_vm._v(" "),_c('div',{class:{
            'st-email-input__email-delete': true,
            'st-email-input__email-delete--disabled': _vm.disabled
          },on:{"click":function($event){return _vm.removeEmail(_vm.newEmailList.length - n - 1)}}},[_c('icon-wrapper',{staticClass:"st-email-input__email-delete-icon",attrs:{"icon-name":"plus"}})],1)])}),_vm._v(" "),_vm._l((_vm.nonRemovableList.slice().reverse()),function(member,n){return _c('div',{key:'non-removable-email-' + n,class:{
          'st-email-input__email-tag': true,
          'st-email-input__email-tag--cannot-delete': !member.new
        }},[_c('div',[_vm._v(_vm._s(member.email))])])})],2),_vm._v(" "),(_vm.errorMessage)?_c('div',{staticClass:"st-email-input__error-message"},[_vm._v("\n      "+_vm._s(_vm.errorMessage)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.newMemberEmail.length > 0 && _vm.hasFocus)?_c('div',{staticClass:"st-email-input__autocomplete-box"},[(!_vm.withoutAutocomplete)?_c('div',{staticClass:"st-email-input__autocomplete-matching-members"},_vm._l((_vm.matchingMembersArray),function(member,idx){return _c('div',{key:'matching-member-' + member.id},[_c('div',{class:{
              'st-email-input__user-match': true,
              'st-email-input__user-match--only-autocomplete': _vm.onlyAutocomplete && idx == _vm.matchingMembersArray.length - 1
            },on:{"click":function($event){return _vm.selectExistingMember(idx)}}},[_c('div',{staticClass:"st-email-input__match-avatar-and-name"},[_c('participant-avatar',{staticClass:"st-email-input__match-avatar",attrs:{"participant":member}}),_vm._v(" "),_c('div',{staticClass:"st-email-input__match-name"},[_vm._v("\n                "+_vm._s(member.fullName)+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"st-email-input__match-email"},[_vm._v("\n              "+_vm._s(member.email)+"\n            ")])])])}),0):_vm._e(),_vm._v(" "),_c('div',{class:{
          'st-email-input__autocomplete-invite-box': true,
          'st-email-input__autocomplete-line': !_vm.withoutAutocomplete && !_vm.onlyAutocomplete
        }},[(!_vm.onlyAutocomplete)?_c('a',{staticClass:"st-email-input__autocomplete-invite",on:{"click":function($event){return _vm.tryAddNewEmail(_vm.newMemberEmail)}}},[_c('icon-wrapper',{staticClass:"st-email-input__invite-new-member-icon",attrs:{"icon-name":"plus"}}),_vm._v(" "),(_vm.afterEmailText)?[_vm._v("\n            "+_vm._s(_vm.addEmailWord)+" ‘"+_vm._s(_vm.newMemberEmail)+"’ "+_vm._s(_vm.afterEmailText)+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm.addEmailWord)+" ‘"+_vm._s(_vm.newMemberEmail)+"’ to this "+_vm._s(_vm.inviteText)+"\n          ")]],2):(_vm.onlyAutocomplete && _vm.matchingMembersArray.length === 0)?_c('a',{staticClass:"st-email-input__no-matches-found"},[_vm._v("\n          No matches found\n        ")]):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }