<template>
  <div>
    <div
      :class="{
        'st-accordion': true,
        'st-accordion--with-custom-icon': customIcon
      }"
    >
      <div
        :class="{ 'st-accordion--remove-focus': removeFocus }"
        tabindex="0"
        @focus="hover = true"
        @focusout="hover = false, removeFocus = false"
        @mousedown="removeFocus = true"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        @keydown.enter="toggleExpanded"
        @click="toggleExpanded"
      >
        <div
          :class="[{
            'st-accordion__visible-row': true,
            'st-accordion__custom-icon-row': customIcon
          }, visibleRowClass]"
        >
          <slot
            name="visible-section"
            :expanded="expanded"
            :hover="hover"
          />
          <div v-if="!customIcon">
            <div v-if="expanded">
              <div class="st-accordion--with-text">
                <div
                  v-if="expandedText.length > 0"
                  class="st-accordion__expanded-text"
                >
                  ({{ expandedText }})
                </div>
                <slot
                  name="persist-visible-section-right"
                />
                <icon-wrapper
                  :class="[{ 'st-accordion__minus-icon': true }, iconClass]"
                  icon-name="minus"
                />
              </div>
            </div>
            <div
              v-else
              class="st-accordion__plus-icon-wrapper"
            >
              <slot
                name="persist-visible-section-right"
              />
              <icon-wrapper
                :class="[{ 'st-accordion__plus-icon': true }, iconClass]"
                icon-name="plus"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="expanded"
        :class="{
          'st-accordion__collapse' : true,
          'st-accordion__collapse--lock-bottom': mobileBottomLock
        }"
      >
        <slot
          name="expanded-section"
          :toggleExpanded="toggleExpanded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  props: {
    customIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    expandedText: {
      type: String,
      required: false,
      default: ''
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    isExpanded: {
      type: Boolean,
      default: false,
      required: false
    },
    mobileBottomLock: {
      type: Boolean,
      default: false,
      required: false
    },
    visibleRowClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      expanded: this.isExpanded,
      hover: false,
      removeFocus: false
    }
  },
  computed: {
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  }
}
</script>
