<template>
  <div class="icon_profile">
    <div class="icon_profile__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-1 0a7 7 0 10-7 7 7 7 0 007-7z" />
        <path
          d="M6.5 3A3.5 3.5 0 113-.5 3.5 3.5 0 016.5 3zm-1 0A2.5 2.5 0 103 5.5 2.5 2.5 0 005.5 3z"
          transform="translate(5.17 2.5)"
        />
        <path
          d="M2.629 3.99A5.076 5.076 0 017.683.026a5.076 5.076 0 015.054 3.964 5.243 5.243 0 01.106 1.072c.018.3-.5 1.059-3.118 2.416L5.068 7.25C4.7 6.88 2.521 6.9 2.522 5.771a6.932 6.932 0 01.107-1.781zm.871.21a8 8 0 00-.088 1.852c0 .34 1.391.555 4.047 1.108 0 0-1.291.311.59 0a13.4 13.4 0 003.9-1.5V5A4.121 4.121 0 007.683.974 4.173 4.173 0 003.5 4.2z"
          transform="translate(.5 8.023)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
