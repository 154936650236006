<template>
  <div class="icon_custom-label">
    <div class="icon_custom-label__svg">
      <svg
        viewBox="0 0 16 16.004"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h3v1H0z" />
        <path
          d="M0 0h3v1H0z"
          transform="translate(0 8)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(1 4)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(2)"
        />
        <path
          d="M0 0h3v1H0z"
          transform="translate(2 8)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(2 4)"
        />
        <path
          d="M0 0h1v9H0z"
          transform="translate(2)"
        />
        <path
          d="M-19540.32 1506.441l-8.246 7.749 8.256 7.646.748-.7-7.533-6.949 7.533-7.045z"
          transform="rotate(90 -9016.722 10538.844)"
        />
        <path
          d="M0 0h9v1H0z"
          transform="translate(7)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(0 11.003)"
        />
        <path
          d="M0 0h1v16H0z"
          transform="translate(14.99)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
