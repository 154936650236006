<template>
  <div class="icon_conceptranking">
    <div class="icon_conceptranking__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h1v16H0z" />
        <path
          d="M0 0h1v11H0z"
          transform="translate(6 5)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(12 8)"
        />
        <path
          d="M0 0h1v16H0z"
          transform="translate(3)"
        />
        <path
          d="M0 0h1v11H0z"
          transform="translate(9 5)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(15 8)"
        />
        <path d="M0 0h4v1H0z" />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 5)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 8)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(6 15)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 15)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
