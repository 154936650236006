<template>
  <div>
    <st-input
      v-model="description"
      class="st-specifications__header-spacing"
      :disabled="isReadOnly"
      label="Question Text"
      name="question[description]"
      placeholder="Which option do you prefer?"
      @blur="updateQuestion('description', activeQuestion.description)"
    />
    <div
      v-for="(option, index) in activeQuestion.options"
      :key="option.id"
      class="st-specifications__question-option-inputs"
    >
      <st-input
        :disabled="isReadOnly"
        :hover-delete="index > 1"
        :label="`${numberToStringPlace((index + 1))} Option`"
        :name="`question[options][${index}]`"
        :value="option.name"
        @blur="updateQuestionOption(option.id, index, { name: $event.target.value })"
        @input="updateOptions(option, $event)"
        @onDelete="deleteQuestionOption(option.id, index)"
      />
    </div>
    <st-hyperlink
      v-if="activeQuestion.options.length >= 2 && activeQuestion.options.length < 3"
      class="st-specifications__add-option"
      icon-name="plus"
      text="Add Option"
      @click.native="createNewOptions(1)"
    />
  </div>
</template>
<script>
import { updateQuestionOption } from '../../../../mixins'
import updateQuestion, { mutationSetter } from '../../../../mixins/question/updateQuestion.vue'
import { StHyperlink, StInput } from '../../../common'

export default {
  components: {
    StHyperlink,
    StInput
  },
  mixins: [updateQuestion, updateQuestionOption],
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mutationSetter(['description', 'options'])
  },
  mounted () {
    if (!this.activeQuestion.options.length) { this.createNewOptions(2) }
  },
  methods: {
    numberToStringPlace (number) {
      let string
      switch (number) {
        case 1:
          string = 'First'
          break
        case 2:
          string = 'Second'
          break
        case 3:
          string = 'Third'
          break
      }
      return string
    },
    updateOptions (option, payload) {
      let updatedOptions = this.activeQuestion.options.map(oldInput => {
        if (oldInput.id !== option.id) return oldInput
        return { ...oldInput, name: payload }
      })
      this.mutateQuestion('options', updatedOptions)
    }
  }
}
</script>
