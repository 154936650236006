<template>
  <div>
    Notes Panel
  </div>
</template>

<script>
export default {
  props: {
    notes: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      hover: false
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
