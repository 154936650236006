<template>
  <div
    :class="{
      'space-between': true,
      'space-around': max === 3,
    }"
  >
    <div
      v-for="number in max"
      :key="number"
      :class="{
        'st-numeric-scale__number-wrapper': true,
        'st-numeric-scale__number-wrapper--active': value === number
      }"
      @click="$emit('input', number)"
    >
      <h2
        :class="{
          'st-numeric-scale__numbers': true,
          'st-numeric-scale__numbers--active': value === number
        }"
      >
        {{ number }}
      </h2>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    max: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data: function () {
    return {
    }
  }
}
</script>
