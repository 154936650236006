<template>
  <div class="icon_company">
    <div class="icon_company__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M0 0h9v1H0z"
          transform="translate(1)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(9 5)"
        />
        <path
          d="M0 0h1v16H0z"
          transform="translate(1)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(3 3)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(3 7)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(3 11)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(6 3)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(6 7)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(6 11)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(11 8)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(11 12)"
        />
        <path
          d="M0 0h1v16H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h1v11H0z"
          transform="translate(14 5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
