<template>
  <div
    :class="{
      'st-new-question-tile': true,
      'st-new-question-tile--active': isActive
    }"
    @click="$emit('setActive')"
  >
    <p>
      {{ index + 1 }}.
    </p>
    <icon-wrapper
      class="st-new-question-tile__question-type-icon"
      :actionable="isActive"
      :icon-name="iconName"
    />
    <p class="question-title">{{ metricName }} </p>
    <div
      v-if="!question.complete"
      class="st-new-question-tile__alert-icon-container"
    >
      <icon-wrapper
        class="st-new-question-tile__alert-icon"
        icon-name="alert"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IconWrapper } from '../../common'

export default {
  components: {
    IconWrapper
  },
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('specifications', ['questions', 'activeQuestionIdx']),
    iconName () {
      return this.questionType === 'favorite_choice'
        ? 'check-mark'
        : this.questionType.split('_').join('-')
    },
    isActive () {
      return this.activeQuestionIdx === this.index
    },
    isIncomplete () {
      return !this.question.complete
    },
    metricName () {
      return this.questionType === 'culture_board' ? 'Image Board' : this.questionType.split('_').join(' ')
    },
    question () {
      return this.questions[this.index]
    },
    questionId () {
      return this.question.id
    },
    questionType () {
      return this.question.questionType
    }
  },
  mounted () {
    this.$nextTick(() => {
      let alertIcons = []
      let offsets = []

      let allAlertIcons = document.querySelectorAll('.st-new-question-tile__alert-icon-tooltip')
      for (let i = 0; i < allAlertIcons.length; i++) {
        alertIcons.push(allAlertIcons[i])
        offsets.push(allAlertIcons[i].getBoundingClientRect().top)
      }

      let parentScrollArea = document.querySelector('.st-new-question__scroll-area')
      parentScrollArea.onscroll = () => {
        let scrollTop = parentScrollArea.scrollTop
        for (let i = 0; i < alertIcons.length; i++) {
          alertIcons[i].style.top = ((offsets[i] - scrollTop) - 80) + 'px'
        }
      }
    })
  },
  methods: {
    deleteTile () {
      this.$emit('openDeleteModal')
    },
    showSpecifications () {
      this.$emit('showSpecifications', true)
    }
  }
}
</script>
<style scoped>
.question-title{
  line-height: 20px;
}
</style>