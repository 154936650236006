import moment from 'moment'

import { User } from './user'

const projectProxyHandler = {
  get (obj, prop) {
    let val = obj[prop]
    if (prop === 'updatedAt') {
      val = moment(val)
    } else if (prop === 'createdAt') {
      val = moment(val)
    } else if (prop === 'name') {
      val = val || ''
    }
    return val
  }
}

export function Project (project) {
  let mappedProject = { ...project }
  if (mappedProject.hasOwnProperty('users')) {
    mappedProject.users = mappedProject.users.map(User)
  }
  return new Proxy(mappedProject, projectProxyHandler)
}
