<script>
// Intended to be used within a question specifications component

export default {
  methods: {
    addLabel () {
      let url = '/questions/' + this.activeQuestion.id + '/question_options/'
      this.$axios.request({
        url: url,
        method: 'post',
        data: { question_id: this.activeQuestion.id, priority: this.options.length + 1 }
      })
        .then(res => {
          // console.log(res)
          this.$store.commit('specifications/replaceActiveQuestion', res.data.question)
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
