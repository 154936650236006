<template>
  <div class="icon_emoji_love-it">
    <div class="icon_emoji_love-it__svg">
      <svg
        viewBox="0 0 26.325 26.325"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(1745.098 4963.163)">
          <path
            d="M2173.326 2635.827a1.068 1.068 0 00-1.511 0l-.175.174-.174-.174a1.069 1.069 0 00-1.511 1.511l.174.174 1.511 1.511 1.511-1.511.174-.174a1.069 1.069 0 00.001-1.511z"
            transform="translate(-3908.549 -7591.656)"
          />
          <path
            d="M2173.326 2635.827a1.068 1.068 0 00-1.511 0l-.175.174-.174-.174a1.069 1.069 0 00-1.511 1.511l.174.174 1.511 1.511 1.511-1.511.174-.174a1.069 1.069 0 00.001-1.511z"
            transform="translate(-3898.019 -7591.656)"
          />
          <path
            d="M8.48 0a6.058 6.058 0 01-.32 1.536 3.887 3.887 0 01-4.034 2.688C-.144 4.224 0 0 0 0z"
            transform="translate(-1736.221 -4949.712)"
          />
          <path
            d="M26.325 13.162A13.162 13.162 0 1113.162 0a13.162 13.162 0 0113.163 13.162zm-1 0a12.162 12.162 0 10-12.163 12.163 12.162 12.162 0 0012.163-12.163z"
            transform="translate(-1745.098 -4963.163)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
