<template>
  <div class="st-prospectives--demo">
    <div class="bx--grid st-prospectives__demo-grid">
      <div class="st-prospectives__demo-head">
        <div class="st-prospectives__demo-head-left">
          <a class="st-prospectives__demo-logo">
            <icon-wrapper
              icon-name="spacetrics"
            />
            <span class="st-layout__header-logo-name">spacetrics</span>
          </a>
          <div class="st-prospectives__demo-head-content">
            <div class="st-prospectives__demo-dropdown-component">
              <span class="st-prospectives__demo-dropdown-text">For A</span>
              <div class="st-prospectives__demo-dropdown">
                <div
                  :class="{
                    'st-prospectives__demo-dropdown-image': true,
                    [`st-prospectives__demo-dropdown-image--${useCaseIndex}`]: true
                  }"
                />
                <div>
                  {{ useCases[useCaseIndex] }}
                </div>
              </div>
            </div>
            <div class="st-prospectives__demo-dropdown-component">
              <span class="st-prospectives__demo-dropdown-text">Considering</span>
              <div class="st-prospectives__demo-dropdown">
                {{ spaceTypes[spaceTypeIndex] }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isDesktop"
          class="st-prospectives__toggle-canvas"
        >
          <st-toggle
            class="st-prospectives__toggle"
            :active-option="activeDemoView"
            first-option="Mobile"
            second-option="Desktop"
            @updateOption="handleViewToggle"
          />
        </div>
      </div>
      <div class="st-prospectives__demo-main">
        <div
          :class="{
            'st-prospectives__demo-content': true,
            'st-prospectives__demo-content--desktop': !mobileDemoView
          }"
        >
          <a
            class="st-prospectives__link st-prospectives__back"
            href="/prospective/how_it_works"
          >
            <icon-wrapper
              icon-name="arrow"
              class="st-prospectives__back-icon"
            />
            <span class="st-prospectives__back-text">Back</span>
          </a>
          <div
            class="st-prospectives__demo-detail"
          >
            <template
              v-if="showSteps"
            >
              <h1
                :class="{
                  'st-prospectives__heading st-prospectives__demo-main-heading': true,
                  'st-prospectives__demo-main-heading--desktop': !mobileDemoView
                }"
              >
                Before you begin the demo, follow these steps:
              </h1>
              <div class="st-prospectives__demo-steps">
                <h4
                  :class="{
                    'st-prospectives__demo-steps-heading': true,
                    'st-prospectives__demo-steps-heading--desktop': !mobileDemoView
                  }"
                >
                  {{ stepNumber }}
                </h4>
                <p
                  :class="{
                    'st-prospectives__demo-steps-para': true,
                    'st-prospectives__demo-steps-para--desktop': !mobileDemoView
                  }"
                >
                  {{ stepDescription }}
                <!-- Or
                <a
                  href="#"
                  class="st-prospectives__link-sm"
                >check here</a> for instructions to activate your microphone. -->
                </p>
                <div class="st-prospectives__demo-steps-bottom">
                  <div class="st-prospectives__demo-dots-canvas">
                    <span
                      :class="{
                        'st-prospectives__demo-dots': true,
                        'st-prospectives__demo-dots-current': stepCount === 1
                      }"
                      @click="stepCount = 1"
                    />
                    <span
                      :class="{
                        'st-prospectives__demo-dots': true,
                        'st-prospectives__demo-dots-current': stepCount === 2
                      }"
                      @click="stepCount = 2"
                    />
                  </div>
                  <a
                    class="st-prospectives__link st-prospectives__forward"
                    @click="handleStepAction"
                  >
                    <span class="st-prospectives__forward-text">
                      {{ stepAction }}
                    </span>
                    <icon-wrapper
                      v-if="stepCount === 1"
                      icon-name="arrow"
                      class="st-prospectives__forward-icon"
                    />
                  </a>
                </div>
              </div>
            </template>
            <p
              v-else
              :class="{
                'st-prospectives__demo-assist-text': true,
                'st-prospectives__demo-assist-text--desktop': !mobileDemoView
              }"
            >
              {{ inDemoAssistText }}
            </p>
          </div>
        </div>
        <div
          :class="{
            'st-prospectives__demo-intro': true,
            'st-prospectives__demo-intro--desktop': !mobileDemoView
          }"
        >
          <div
            v-if="mobileDemoView"
            class="st-prospectives__demo-intro-mobile"
          >
            <div
              :class="{
                'st-prospectives__demo-background': true,
                'st-prospectives__demo-background--disabled': questionPhase === PHASES.demoSteps
              }"
            >
              <slot />
            </div>
          </div>
          <div
            v-else
            class="st-prospectives__demo-intro-desktop"
          >
            <div
              :class="{
                'st-prospectives__demo-background': true,
                'st-prospectives__demo-background--disabled': questionPhase === PHASES.demoSteps
              }"
            >
              <slot />
            </div>
          </div>
          <div
            :class="{
              'st-prospectives__demo-button-canvas': true,
              'st-prospectives__demo-button-canvas--desktop': !mobileDemoView
            }"
          >
            <st-button
              :class="{
                'st-prospectives__button st-prospectives__demo-button st-button__set': true,
              }"
              :variant="['secondary']"
              caption="Exit & Submit"
              icon-name="upload"
              :disabled="showSteps"
              :show-action="true"
              :underline="false"
              @click="handleSubmit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { isDesktop } from '../../../mixins'
import { IconWrapper, StButton, StToggle } from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StToggle
  },
  mixins: [isDesktop],
  props: {
    initialSpaceTypeIndex: {
      type: Number,
      required: false,
      default: null
    },
    initialUseCaseIndex: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      activeRecipientsFilterOption: 'Add Recipients',
      showSteps: true,
      showSubmitAssist: false,
      spaceTypeIndex: this.initialSpaceTypeIndex,
      spaceTypes: ['offices', 'hotels', 'fitness centers'],
      stepCount: 1,
      useCaseIndex: this.initialUseCaseIndex,
      useCases: ['workplace strategist', 'designer', 'cxo']

    }
  },
  computed: {
    ...mapState('participants', ['mobileDemoView', 'showDemoBlocker', 'questionPhase']),
    ...mapGetters('participants', ['PHASES']),
    activeDemoView () {
      return this.mobileDemoView ? 'Mobile' : 'Desktop'
    },
    inDemoAssistText () {
      return this.showSubmitAssist
        ? 'Once you are done providing feedback, click the EXIT & SUBMIT button to move forward. Once you submit your feedback, within 2-5 minutes we will send you an email with a hyperlink for you to check the results of your experience.'
        : 'Answer different topics out loud to provide targeted feedback about different areas of the virtual tour. As you walk around the virtual space you will encounter different questions set up for you to answer. Click the dialogue button to find all available questions.'
    },
    stepAction () {
      return this.stepCount === 1 ? 'Next' : 'Done'
    },
    stepDescription () {
      return this.stepCount === 1
        ? 'We require you to enable your microphone in order to provide your recorded feedback about a virtual space. This will allow us to understand how you feel about the experience. If prompted, please allow your browser to access your microphone.'
        : 'Move around the virtual tour and speak out loud to share your thoughts. Every thing you say is crucial to help workplace strategiests understand how you feel about the space.'
    },
    stepNumber () {
      return this.stepCount === 1 ? 'Step 1' : 'Step 2'
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    ...mapMutations('participants', ['updateMobileDemoView', 'updateShowDemoBlocker']),
    handleBackLink () {
      window.location.href = `/prospective/how_it_works`
    },
    handleSubmit () {
      this.$slots.default[0].child.$children[0].triggerSubmitQuestionAnswer()
    },
    handleStepAction () {
      switch (this.stepCount) {
        case 1:
          this.$slots.default[0].child.$children[0].askForAudioPermission()
          this.updateShowDemoBlocker(false)
          this.stepCount++
          break
        case 2:
          this.showSteps = false
          this.$slots.default[0].child.$children[0].startTour()
          setTimeout(() => { this.showSubmitAssist = true }, 25000)
          break
        default:
      }
      return true
    },
    handleViewToggle (option) {
      return option === 'Mobile' ? this.updateMobileDemoView(true) : this.updateMobileDemoView(false)
    }
  }
}
</script>
