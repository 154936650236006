<template>
  <div class="icon_gender">
    <div class="icon_gender__svg">
      <svg
        viewBox="0 0 16.292 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.349 4.675A4.675 4.675 0 114.675 0a4.675 4.675 0 014.674 4.675zm-.935 0a3.74 3.74 0 10-3.74 3.74 3.74 3.74 0 003.74-3.74z"
          transform="translate(0 2.928)"
        />
        <path
          d="M9.349 4.675A4.675 4.675 0 114.675 0a4.675 4.675 0 014.674 4.675zm-.935 0a3.74 3.74 0 10-3.74 3.74 3.74 3.74 0 003.74-3.74z"
          transform="scale(-1) rotate(37 15.193 -22.799)"
        />
        <path
          d="M1.213 0l1.213 1.617H0z"
          transform="rotate(37 6.963 20.812)"
        />
        <path
          d="M.935 0v3.234H0V0z"
          transform="scale(-1) rotate(37 .521 -21.098)"
        />
        <path
          d="M.435 0v4.19H-.5V0z"
          transform="translate(4.48 11.81)"
        />
        <path
          d="M3.234.435H0V-.5h3.234z"
          transform="translate(2.83 14.415)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
