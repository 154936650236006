<template>
  <div class="specifications__section">
    <div class="st-input__row">
      <label class="st-input__label">
        <input
          v-model="activeQuestion.description"
          :disabled="false"
          class="st-input quadrant-input"
          type="text"
          name="question[description]"
          placeholder="Question Text"
          @blur="updateQuestion('description', activeQuestion.description)"
        >
      </label>
    </div>
    <div
      v-for="(n, index) in 4"
      :key="index"
      class="st-input__row quadrant-row"
    >
      <div class="quadrant-number-count">
        <p>Quadrant {{ index+1 }}</p>
      </div>

      <div class="quadrant-inner-row">
        <div class="st-input__label st-input__label--half-length quadrant-input-div">
          <input
            class="st-input quadrant-input"
            type="text"
            :name="`question[${inputsArray[index].firstOption}]`"
            v-model="activeQuestion[inputsArray[index].firstOption]"
            :placeholder="placeholder[index].firstOption"
            @blur="updateAxisLabel(saveDataArray[index].firstOption, activeQuestion[inputsArray[index].firstOption])"
          >
        </div>
        <div class="quadrant-separater">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 12H4"
            />
          </svg>
        </div>
        <div class="st-input__label st-input__label--half-length quadrant-input-div">
          <input
            class="st-input quadrant-input"
            type="text"
            :name="`question[${inputsArray[index].secondOption}]`"
            v-model="activeQuestion[inputsArray[index].secondOption]"
            :placeholder="placeholder[index].secondOption"
            @blur="updateAxisLabel(saveDataArray[index].secondOption, activeQuestion[inputsArray[index].secondOption])"
          >
        </div>
      </div>
    </div>
    <!-- <div class="radio-button-section">
      <p class="label-caption">
        Would you like participants to create lables?
         <st-tooltip
                class="st-specifications__header-icon-info-tooltip"
                :maxWidth="200"
                tip="Allow participants to create labels when filling survey."
              >
                <icon-wrapper
                  class="st-specifications__header-icon"
                  icon-name="info"
                />
              </st-tooltip>
      </p>
      
      <st-radio-button
        v-model="addLabels"
        class="st-radio-button__default-container"
        :disabled="false"
        :options="[
          { caption: 'No', value: 'no', iconName: 'dont'},
          { caption: 'Yes', value: 'yes', iconName: 'add-prompt'},

        ]"
        @change="changeLabel"
      />
    </div> -->
    <div class="add-labels-section">
      <p class="label-caption">
        Add labels for participants to choose from   <st-tooltip
                class="st-specifications__header-icon-info-tooltip"
                :maxWidth="200"
                tip="Add multiple labels for participant to get better idea."
              >
                <icon-wrapper
                  class="st-specifications__header-icon"
                  icon-name="info"
                />
              </st-tooltip>
      </p>
      <div class="st-input__row label-input-container" v-for="(option, index) in activeQuestion.options" :key="index">
        <label class="st-input__label">
           <st-input
           :disabled="false"
            :hover-delete="index > 0"
            :label=" `${labelPlaceholders[index]} Label`"
            :value="option.name"
            @blur="updateQuestionOptionFromComponent(option.id, index, { name: $event.target.value })"
            @input="updateOptions(option, $event)"
            @onDelete="deleteQuestionOption(option.id, index)"
          />
        </label>
      </div>
      <st-hyperlink
        @click.native="createLabel"
        v-if="activeQuestion.options.length < 5 && !addingOption"
        icon-class="st-hyperlink__default-icon-spacing"
        icon-name="plus"
        text="Add Another Label"
        style="margin-top:10px;"
      />
      <st-inline-spinner
        v-if="addingOption"
      />
      <!-- 'st-specifications__upload-link--image-board-with-options': options.length > 0, -->
    </div>
    <!-- <div class="radio-button-section">
      <p class="label-caption">
        Would you like to ask for an explaination?
      </p>
      <st-radio-button
        v-model="askForExplanation"
        class="st-radio-button__default-container"
        :disabled="false"
        :options="[
          { caption: 'No', value: 'no_explanation', iconName: 'dont'},
          { caption: 'Yes, Voice', value: 'voice_recording', iconName: 'microphone'},
          { caption: 'Yes, Typed', value: 'text', iconName: 'keyboard' }
        ]"
      />
    </div> -->

    <div class="bottom-margin" />
  </div>
</template>

<script>
import { StRadioButton, StHyperlink, StInput, StTooltip, IconWrapper, StInlineSpinner } from '../../../common/'
import updateQuestion, { actionSetter, mutationSetter } from '../../../../mixins/question/updateQuestion.vue'
import { updateQuestionOption } from '../../../../mixins'

export default {
  components: {
    StRadioButton,
    StHyperlink,
    StInput,
    StTooltip,
    IconWrapper,
    StInlineSpinner
  },
  mixins: [ updateQuestion, updateQuestionOption],
  data: function () {
    return {
      addingOption: false,
      placeholder: [
        { 'firstOption': 'Option A', 'secondOption': 'Option B' },
        { 'firstOption': 'Option A', 'secondOption': 'Option C' },
        { 'firstOption': 'Option D', 'secondOption': 'Option B' },
        { 'firstOption': 'Option D', 'secondOption': 'Option C' }
      ],
      inputsArray : [
        { 'firstOption': "maxYLabel", 'secondOption': "minXLabel" },
        { 'firstOption': "maxYLabel", 'secondOption': "maxXLabel" },
        { 'firstOption': "minYLabel", 'secondOption': "minXLabel" },
        { 'firstOption': "minYLabel", 'secondOption': "maxXLabel" }
      ],
      saveDataArray : [
        { 'firstOption': "max_y_label", 'secondOption': "min_x_label" },
        { 'firstOption': "max_y_label", 'secondOption': "max_x_label" },
        { 'firstOption': "min_y_label", 'secondOption': "min_x_label" },
        { 'firstOption': "min_y_label", 'secondOption': "max_x_label" }
      ],

      labelPlaceholders : [
        "First",
        "Second",
        "Third",
        "Forth",
        "Fifth",
      ],
      addLabels:'',

    }
  },
 
  mounted () {
     window.scrollTo({
        top: 0,
      })
      if (this.options.length === 0) this.createLabel();
     this.addLabels = this.$store.getters["specifications/extraTags"]
  },

  computed: {
    ...actionSetter(['askForExplanation']),
    ...mutationSetter(['description', 'options'])
  },
  methods: {
    updateAxisLabel(axis, value) {
      this.updateQuestion(axis, value);
    },
    updateQuestionName(option, value, id){
    console.log(option, value, id)
      this.updateQuestion(option, value);
    const currentOption =  this.activeQuestion.options.find(opt=>opt.id== id);
          currentOption.name = currentOption.name!=null ? ( currentOption.name + ` - ${value}`) : value;
          this.updateQuestionOptionFromComponent(id, 0, {name: currentOption.name});
    },
    changeLabel(){
      this.$store.commit("specifications/setExtraTags", this.addLabels)
      
    },
    createLabel(){
      this.addingOption = true
      this.createNewOptions(1, () => this.addingOption = false);
    },
    updateQuestionOptionFromComponent(id, index, data){
     let exists = this.activeQuestion.options.find((item)=>{return item.id === id});
     if(exists){
        this.updateQuestionOption(id, index, data);
     }
    },
    
    updateOptions (option, payload) {
      let updatedOptions = this.activeQuestion.options.map(oldInput => {
        if (oldInput.id !== option.id) return oldInput
        return { ...oldInput, name: payload }
      })
      this.mutateQuestion('options', updatedOptions)
    }

  }
}
</script>

<style scoped>
.quadrant-title{
  margin-top: 2%;
  margin-bottom: 2%;
}
.quadrant-input{
  padding:7px;
}
.quadrant-row{
  margin-top:5%;
}
.quadrant-inner-row{
  /* background:red; */

  display:flex;
}
.quadrant-number-count{
  margin:5px 0;
  padding:2px;
}
.quadrant-input-div{

  width:45%;
}
.quadrant-separater{
  width:4%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  opacity: 0.3;
}
.radio-button-section{
  margin-top: 6%;
  margin-bottom: 2%;
}
.add-labels-section{
  margin-top: 4%;
  margin-bottom: 2%;
}
.label-caption{
  margin-top: 1%;
  margin-bottom: 1%;
}
.bottom-margin{
  margin-bottom: 2%;
}
.label-input-container{
  margin-top: 1%;

}
</style>
