<template>
  <st-modal
    v-model="show"
    class="st-microphone-access__modal"
    actions-class="st-microphone-access__modal-content"
    :hide-button="true"
    size-class="st-microphone-access__modal"
    @closeModal="$emit('closeMicrophoneAccess')"
  >
    <template slot="modalActions">
      <div class="st-microphone-access__upper-content">
        <icon-wrapper
          class="st-microphone-access__microphone"
          icon-name="secondary-microphone"
        />
        <p class="st-microphone-access__content st-microphone-access__header">
          Microphone Access Required
        </p>
        <div class="st-microphone-access__browser-steps">
          <p class="st-microphone-access__content">
            {{ generalBrowserInstructions }}, please go to:
          </p>
          <p class="st-microphone-access__content st-microphone-access__browser-instructions">
            {{ specificBrowserInstructions }}
          </p>
        </div>
        <ol class="st-microphone-access__content">
          <li class="st-microphone-access__content st-microphone-access__steps">
            1. Enable microphone to ‘Ask’
          </li>
          <li class="st-microphone-access__content st-microphone-access__steps">
            2. Ensure app.spacetrics.com is not blocked
          </li>
          <li class="st-microphone-access__content st-microphone-access__steps">
            3. Refresh page
          </li>
        </ol>
      </div>
      <st-button
        class="st-microphone-access__button"
        caption="Refresh Page"
        :underline="false"
        @click="refreshPage"
      />
    </template>
  </st-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { IconWrapper, StButton, StModal } from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StModal
  },
  props: {
    showMicrophoneAccess: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      show: this.showMicrophoneAccess
    }
  },
  computed: {
    ...mapGetters(['isBrowserChrome', 'isBrowserFirefox', 'isBrowserSafari', 'isDeviceIOS']),
    generalBrowserInstructions () {
      if (this.isDeviceIOS) { return 'In Device Settings' }
      return 'In your browser'
    },
    specificBrowserInstructions () {
      if (this.isDeviceIOS) {
        if (this.isBrowserChrome) { return 'Chrome > Privacy > Microphone' }
        if (this.isBrowserFirefox) { return 'Firefox > Privacy > Microphone' }
        if (this.isBrowserSafari) { return 'Safari > Privacy > Microphone' }
      }
      return 'Settings > Site Settings > Microphone'
    }
  },
  methods: {
    refreshPage () {
      window.location.reload()
    }
  }
}
</script>
