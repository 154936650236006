<template>
  <div class="icon_home">
    <div class="icon_home__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-21024.107-10192.132v9.324h-5.986v-4.011h-4.012v4.011h-6v-9.324l8-6.676zm-11 8.326v-4.012h6.012v4.012h3.986v-7.86l-7-5.841-7 5.841v7.86z"
          transform="translate(21040.107 10198.808)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
