<template>
  <div class="icon_updates">
    <div class="icon_updates__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-23130.088-238.643l-4.385 5.741h4.963l-16 10.259 5.09-6.745h-3.256l6.373-9.255zm-11.258 12.142l8.424-5.4h-3.572l4.385-5.741h-4.668l-5 7.255h3.361z"
          transform="translate(23145.51 238.643)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
