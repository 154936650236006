<template>
  <div class="bx--grid">
    <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
    </div>
    <div class="bx--row">
      <st-toast-notification
        v-if="errorMessage"
        class="st-toast-notification--fixed"
        kind="error"
        title="Error"
        :caption="errorMessage"
        @click="errorMessage = ''"
      />
      <div class="bx--offset-sm-1 bx--col-sm-10 bx--offset-xl-2 bx--col-xl-8">
        <div class="st-request-complete__content-wrapper">
          <h1 class="page-header st-request-audience__header">
            Audience Ordered
          </h1>
          <div class="st-request-complete__text-container">
            <p class="st-request-complete__text">
              You have placed your order succesfully. Gathering the right people takes time. Our recruiters are on the hunt. We will notify you via email within 5-7 days, so you can start using your new audience.
            </p>
            <p class="st-request-complete__text">
              In the next 24 hours you will receive an invoice to pay for your order.
            </p>
            <p class="st-request-complete__text">
              Thank you.
            </p>
          </div>
          <div class="st-request-complete__btn-canvas">
            <st-button
              class="st-request-complete__request-list-button"
              caption="View Requested List"
              url="/audience_requests"
            />
            <p
              v-if="phase === 'cancelled'"
              class="st-request-complete__canceled-text"
            >
              [Audience Order has been canceled]
            </p>
            <st-hyperlink
              v-else
              class="st-request-complete__cancel-order-link"
              text="Cancel Order"
              @click="cancelOrder"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { StButton, StHyperlink, StToastNotification, IconWrapper } from '../../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StToastNotification
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('requestAudience', ['phase'])
  },
  mounted () {
    this.replaceData(this.request)
  },
  methods: {
    ...mapMutations('requestAudience', ['replaceData']),
    ...mapActions('requestAudience', ['updatePhase']),
    cancelOrder () {
      this.$axios.request({
        url: `audience_requests/${this.request.id}/cancel`,
        method: 'patch',
        data: { audience_request: this.request }
      })
        .then(res => { this.updatePhase('cancelled') })
        .catch(err => { this.errorMessage = err.response.data.message })
    }
  }
}
</script>
