<script>
export default {
  props: {
  },
  data: function () {
    return {
      overflowDescription: false,
      resizeObserver: null
    }
  },
  computed: {
  },
  watch: {
    description () {
      this.responsiveQuestionText()
    },
    options () {
      this.responsiveQuestionText()
    }
  },
  mounted () {
    this.resizeObserver = new ResizeObserver(this.responsiveQuestionText)
    this.resizeObserver.observe(this.$refs.questionContainer)
  },
  beforeDestroy () {
    if (this.resizeObserver) { this.resizeObserver.unobserve(this.$refs.questionContainer) }
  },
  methods: {
    responsiveQuestionText () {
      this.$nextTick(() => {
        let textContainer = this.$refs.questionContainer
        let text = this.$refs.question
        // let fontSize = this.hasMultipleMedia && this.isDesktop ? 18 : 36
        let fontSize = 26
        text.style.fontSize = `${fontSize}px`
        while (text.offsetHeight > textContainer.offsetHeight) {
          if (fontSize <= 12) {
            textContainer.style.overflowY = 'scroll'
            this.overflowDescription = true
            break
          }
          fontSize--
          text.style.fontSize = `${fontSize}px`
        }
      })
    }
  }
}
</script>
