<template>
  <div class="st-virtual-tour-insights__room-sentiment">
    <div class="st-sentiment-grid__participant-sentiment-title">
      Sentiment Per Participant
    </div>
    <div class="st-sentiment-grid__split-section">
      <sentiment-grid
        :responses="responses"
        @openResponse="handleOpenResponse"
        @closeResponse="handleCloseResponse"
      />
      <div
        class="st-sentiment-grid__transcript-section"
        :style="{
          'max-height': responses.length < 64 ? '304px' : responses.length * 5 + 'px'
        }"
      >
        <template v-if="activeResponseId">
          <div class="st-sentiment-grid__room-prompts-section">
            <div
              v-if="showPromptsSection"
              class="st-sentiment-grid__prompts-row"
              @click="showAllPrompts = !showAllPrompts"
            >
              <div class="st-sentiment-grid__transcript-section-title">
                Room Prompts
              </div>
              <icon-wrapper
                icon-name="chevron"
                :class="{
                  'st-insights__chevron-down': !showAllPrompts,
                  'st-insights__chevron-up': showAllPrompts
                }"
              />
            </div>
            <template v-if="showPromptsSection && showAllPrompts">
              <div
                v-for="(prompt, n) in activeRoomPrompts"
                :key="`prompt-${n}`"
                class="st-sentiment-grid__room-prompt"
              >
                {{ prompt }}
              </div>
            </template>
          </div>
          <h3 class="st-sentiment-grid__room-transcripts-title">
            {{ transcriptCaption }}
          </h3>
          <div class="st-insights__transcript-avatar-row">
            <div class="st-insights__transcript-avatar-with-name">
              <participant-avatar :participant="activeResponse" />
              <div class="st-sentiment-grid__transcript-section-name">
                {{ activeName }}
              </div>
            </div>
            <div
              class="st-insights__full-transcript-group"
              @click="$emit('expand-result', activeResponse)"
            >
              <div class="st-insights__full-transcript-text">
                {{ expandResultText }}
              </div>
              <icon-wrapper
                :icon-name="expandResultIcon"
              />
            </div>
          </div>
          <div class="st-sentiment-grid__transcript-section-text">
            {{ activeTranscript }}
          </div>
        </template>
        <template v-else>
          <h3 class="st-sentiment-grid__room-transcripts-title">
            Room Transcript
          </h3>
          <div class="st-sentiment-grid__transcript-section-text">
            Select a user from the grid to see their text transcript
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { formatSecondsToStopwatch } from '../../../utilities/data_utilities'
import { IconWrapper } from '../../common'
import ParticipantAvatar from './participant-avatar'
import SentimentGrid from './sentiment-grid'

export default {
  components: {
    IconWrapper,
    'participant-avatar': ParticipantAvatar,
    'sentiment-grid': SentimentGrid
  },
  props: {
    expandResultOption: {
      type: String,
      required: true,
      validator: val => ['openTourMode', 'openTranscript'].includes(val)
    },
    identifier: {
      type: [Number, String],
      required: true
    },
    includeSentence: {
      type: Function,
      required: true
    },
    responses: {
      type: Array,
      required: true
    },
    showPromptsSection: {
      type: Boolean,
      required: false,
      default: false
    },
    transcriptCaption: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      activeResponseId: '',
      showAllPrompts: true
    }
  },
  computed: {
    activeResponse () {
      // returns either a response object or undefined
      return this.responses.find(response => response.id === this.activeResponseId)
    },
    activeResponseSentences () {
      if (!this.activeResponse) { return [] }
      return this.activeResponse.sentences.filter(sentence => {
        return this.includeSentence(sentence, this.identifier)
      })
    },
    activeTranscript () {
      return this.activeResponseSentences.map(sentence => sentence.text).join(' ')
    },
    activeRoomPrompts () {
      let prompts = []
      this.activeResponse.roomTranscripts[this.identifier].forEach(transcript => {
        if (transcript.prompt) {
          if (!prompts.includes(transcript.prompt)) {
            prompts.push(transcript.prompt)
          }
        }
      })
      if (prompts.length > 0) {
        return prompts
      } else {
        return ['No prompts visited in this room']
      }
    },
    activeName () {
      let name = 'Anonymous User'
      if (this.activeResponse.firstName && this.activeResponse.lastName) {
        name = this.activeResponse.firstName + ' ' + this.activeResponse.lastName[0]
      } else if (this.activeResponse.email) {
        name = this.activeResponse.email
      }
      return name
    },
    expandResultIcon () {
      let icon = ''
      switch (this.expandResultOption) {
        case 'openTranscript':
          icon = 'transcript'
          break
        case 'openTourMode':
          icon = 'tour-mode'
          break
      }
      return icon
    },
    expandResultText () {
      let text = ''
      switch (this.expandResultOption) {
        case 'openTranscript':
          text = 'Transcript'
          break
        case 'openTourMode':
          text = 'Open Tour Mode'
          break
      }
      return text
    }
  },
  methods: {
    handleOpenResponse (responseId) {
      this.activeResponseId = responseId
    },
    handleCloseResponse (responseId) {
      this.activeResponseId = null
    },
    formatSeconds (time) {
      return formatSecondsToStopwatch(time)
    }
  }
}
</script>
