<template>
  <div class="st-simple-data-table">
    <table class="st-data-table">
      <thead class="st-data-table__header">
        <tr class="st-data-table__header-row">
          <th
            v-for="(header, n) in headers"
            :key="n"
            class="st-data-table__head-item"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row,i) in rows"
          :key="`row-${i}`"
          class="st-data-table__row"
        >
          <td
            v-for="(cell,n) in row"
            :key="`row-${i}-${n}`"
            class="st-data-table__item"
          >
            <template v-if="cell.iconName">
              <icon-wrapper
                :icon-name="cell.iconName"
                class="st-concept-ranking-insights__graph-emoji"
              />
            </template>
            <template v-else>
              {{ cell }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { IconWrapper } from '../../common'

export default {
  components: {
    IconWrapper
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
