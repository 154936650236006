<template>
  <div
    :class="{
      'st-vt-microphone': true,
      'st-vt-microphone--phone-demo-preview': isDemoPreview && mobileDemoView
    }"
  >
    <div
      :class="{
        'st-vt-microphone__button-container': true,
        'st-vt-microphone__button-container--phone-demo-preview': isDemoPreview && mobileDemoView,
        'st-vt-microphone__button-container--disabled': skipCreateAudioStream
      }"
      @click="toggleMute"
    >
      <div
        :class="{
          'st-vt-microphone__button': true,
          'st-vt-microphone__button--muted': muted,
          'st-vt-microphone__button--specs-preview': isSpecificationsPreview,
          'st-vt-microphone__button--phone-demo-preview': isDemoPreview && mobileDemoView
        }"
      >
        <icon-wrapper
          icon-name="microphone"
          :invert="muted"
        />
      </div>
      <div
        ref="audioVisualizer"
        :class="{
          'st-vt-microphone__visualizer': true,
          'st-vt-microphone__visualizer--specs-preview': isSpecificationsPreview,
          'st-vt-microphone__visualizer--phone-demo-preview': isDemoPreview && mobileDemoView
        }"
        :style="visualizerStyle"
      />
    </div>
    <p
      :class="{
        'st-vt-microphone__caption': true,
        'st-vt-microphone__caption--specs-preview': isSpecificationsPreview
      }"
    >
      {{ muteText }}
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { IconWrapper } from '../../../../common'

const AudioParams = Object.freeze({
  add: 0.95,
  max: 1.75,
  mult: 0.25,
  maxPctDiff: 25
})

export default {
  components: { IconWrapper },
  props: {
    audioStream: {
      type: MediaStream,
      required: false,
      default: () => new MediaStream()
    },
    isDemoPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSpecificationsPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    skipCreateAudioStream: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    // TODO: right now we're sort of cheating and not actually muting them
    // it's... complicated b/c we use the timing to compare to the timing of the pano changes
    // we may be able to remove the track from the MedaiStream and then add it again, but it's not fully supported on all browsers
    return {
      ...AudioParams,
      scale: 1,
      userMuted: false,
      muted: false
    }
  },
  computed: {
    ...mapState('participants', ['isFullTopic', 'mobileDemoView']),
    ...mapGetters('participants', ['hasViewedTopic', 'isMatterportIndicatorOpen']),
    muteText () {
      return this.muted ? 'Unmute' : 'Mute'
    },
    visualizerStyle () {
      let style = {
        transform: `scale(${this.scale})`
      }
      if (this.muted) {
        style.display = 'none'
      }
      return style
    }
  },
  watch: {
    isFullTopic (isNowOpen, wasOpen) {
      if (this.userMuted) { return }
      isNowOpen ? this.muteTracks() : this.unmuteTracks()
    },
    isMatterportIndicatorOpen (isNowOpen, wasOpen) {
      if (this.userMuted) { return }
      isNowOpen ? this.muteTracks() : this.unmuteTracks()
    }
  },
  mounted () {
    /* eslint-disable no-undef */
    let audioCtx = new (window.AudioContext || webkitAudioContext)()
    let analyser = audioCtx.createAnalyser()
    analyser.fftSize = 1024
    let timeSliceSize = analyser.frequencyBinCount
    let timeSlice = new Uint8Array(timeSliceSize)

    if (!this.isSpecificationsPreview && !this.skipCreateAudioStream) {
      let source = audioCtx.createMediaStreamSource(this.audioStream)
      source.connect(analyser)
    }

    let lastVal = 0
    const minFract = (100 - this.maxPctDiff) / 100
    const maxFract = (100 + this.maxPctDiff) / 100

    const draw = (ms = 0) => {
      let val = smoothVal(getRMS(), this.add)
      this.scale = Math.min(this.max, (val * this.mult) + this.add)
      requestAnimationFrame(draw)
    }

    function smoothVal (val, min) {
      val = Math.max(val, lastVal * minFract)
      val = Math.min(val, Math.max(lastVal * maxFract, min))
      lastVal = val
      return val
    }

    function getRMS () {
      analyser.getByteTimeDomainData(timeSlice)
      const total = timeSlice
        .map(x => x ** 2)
        .reduce((a, b) => a + b)
        // .map(x => x / 256.0) // normalize to [0, 1]
      const average = total / timeSliceSize
      return Math.sqrt(average)
      // console.log('total', total)
      // return total / timeSliceSize / 256.0
    }

    draw()
  },
  methods: {
    muteTracks () {
      if (this.muted) { return }
      this.muted = true

      if (!this.isSpecificationsPreview) {
        this.audioStream.getAudioTracks().forEach(track => { track.enabled = false })
      }
    },
    unmuteTracks () {
      if (!this.muted) { return }
      this.muted = false

      if (!this.isSpecificationsPreview) {
        this.audioStream.getAudioTracks().forEach(track => { track.enabled = true })
      }
    },
    toggleMute () {
      this.userMuted = !this.userMuted
      this.muted ? this.unmuteTracks() : this.muteTracks()
    }
  }
}
</script>
