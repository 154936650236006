var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'space-between': true,
    'space-around': _vm.max === 3,
  }},_vm._l((_vm.max),function(number){return _c('div',{key:number,class:{
      'st-numeric-scale__number-wrapper': true,
      'st-numeric-scale__number-wrapper--active': _vm.value === number
    },on:{"click":function($event){return _vm.$emit('input', number)}}},[_c('h2',{class:{
        'st-numeric-scale__numbers': true,
        'st-numeric-scale__numbers--active': _vm.value === number
      }},[_vm._v("\n      "+_vm._s(number)+"\n    ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }