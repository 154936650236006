<template>
  <div class="icon_camera">
    <div class="icon_camera__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h1v13H0z"
          transform="translate(0 3)"
        />
        <path
          d="M0 0h1v13H0z"
          transform="translate(15 3)"
        />
        <path
          d="M0 0h1v16H0z"
          transform="rotate(90 .5 15.5)"
        />
        <path
          d="M0 0h1v16H0z"
          transform="rotate(90 6.5 9.5)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="rotate(90 7.5 7.5)"
        />
        <path
          d="M6.5 3A3.5 3.5 0 113-.5 3.5 3.5 0 016.5 3zm-1 0A2.5 2.5 0 103 5.5 2.5 2.5 0 005.5 3z"
          transform="translate(5.5 6.5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
