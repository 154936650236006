<template>
  <div class="icon_share">
    <div class="icon_share__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 9)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 3)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 15)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(0 6)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12)"
        />
        <path
          d="M0 0h4v1H0z"
          transform="translate(12 12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(0 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(12 12)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(3 6)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(15)"
        />
        <path
          d="M0 0h1v4H0z"
          transform="translate(15 12)"
        />
        <path
          d="M-2.055-1.663l9.373 3.294-.331.943-9.373-3.293z"
          transform="translate(5.5 10.5)"
        />
        <path
          d="M7.318-.719l-9.373 3.293-.331-.943 9.373-3.294z"
          transform="translate(5.5 4.5)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
