<template>
  <div class="st-virtual-tour-insights__transcripts">
    <div v-if="editResponse">
      <edit-virtual-tour-transcript
        :response="editResponse"
        :initial-sentences="sentences"
        :current-sentence-index="currentSentenceIndex"
        @playSentence="playSentence"
        @exitEditMode="exitEditMode"
      />
    </div>
    <template v-else>
      <div
        v-for="response in responsesPassingParticipantCheckboxFilter"
        :key="'response-' + response.id"
      >
        <div class="st-virtual-tour-insights__transcript-row">
          <div class="st-virtual-tour-insights__transcript-participant">
            <participant-avatar :participant="response" />
            <div
              :class="{
                'st-virtual-tour-insights__transcript-name': true,
                'st-virtual-tour-insights__transcript-name--active': activeAudioUrl === response.audioUrl
              }"
            >
              {{ response.fullName }}
            </div>
          </div>
          <div
            class="st-sentiment-badge"
            :class="sentimentClass(response.sentimentScore)"
          >
            {{ sentimentString(response.sentimentScore) }}
          </div>
        </div>
        <template v-if="response.transcript">
          <template
            v-if="!isDesktop && showFullTranscriptId !== response.id && response.transcript.length > 97"
          >
            <div
              :class="{
                'st-virtual-tour-insights__transcript-text': true,
                'st-virtual-tour-insights__transcript-text--active': activeAudioUrl === response.audioUrl
              }"
            >
              {{ shortTranscript(response.transcript) }}
            </div>
            <div
              class="st-virtual-tour-insights__read-more"
              @click="showFullTranscriptId = response.id"
            >
              Read More
            </div>
          </template>
          <div
            v-else
            :class="{
              'st-virtual-tour-insights__transcript-text': true,
              'st-virtual-tour-insights__transcript-text--active': activeAudioUrl === response.audioUrl
            }"
          >
            {{ response.transcript }}
          </div>
        </template>
        <div class="st-virtual-tour-insights__transcript-actions">
          <div
            v-if="response.audioUrl"
            class="st-virtual-tour-insights__play-audio-transcript"
            @click="playTranscript(response)"
          >
            <icon-wrapper
              icon-name="play"
              class="st-virtual-tour-insights__play-audio-transcript-icon"
            />
          </div>
          <div
            v-if="response.transcript || response.audioUrl"
            class="st-virtual-tour-insights__edit-transcript"
            @click="openEditMode(response)"
          >
            <icon-wrapper icon-name="edit" />
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="activeAudioUrl"
      :class="{
        'st-virtual-tour-insights__audio-bar': true,
        'st-virtual-tour-insights__audio-bar--panel-open': activePanelSection
      }"
    >
      <audio
        ref="transcriptAudio"
        class="st-virtual-tour-insights__full-audio"
        controls
        @timeupdate="handleTimeUpdate"
      >
        <source
          :src="activeAudioUrl"
          :type="activeAudioContentType"
        >
        <source
          v-if="activeAudioFlacUrl"
          :src="activeAudioFlacUrl"
          type="audio/x-flac"
        >
      </audio>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { kebabCase } from 'lodash'
import { isDesktop } from '../../../../mixins'
import { bucketIdx } from '../../../../utilities/data_utilities'
import ParticipantAvatar from '../participant-avatar'
import EditVirtualTourTranscript from '../edit-virtual-tour-transcript'

import { IconWrapper } from '../../../common'

const BADGE_CAPTIONS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

const S_BUFFER = 0.3 // seconds

export default {
  components: {
    IconWrapper,
    'edit-virtual-tour-transcript': EditVirtualTourTranscript,
    'participant-avatar': ParticipantAvatar
  },
  mixins: [isDesktop],
  props: {
  },
  data: function () {
    return {
      activeResponse: {},
      editResponse: null,
      sentences: null,
      currentSentenceTimeout: null,
      currentAudioTime: 0,
      showFullTranscriptId: null
    }
  },
  computed: {
    ...mapState('insights', ['activePanelSection']),
    ...mapGetters('insights', ['responsesPassingParticipantCheckboxFilter']),
    currentSentenceIndex () {
      let sentence = this.sentences.find(sentence => {
        return this.currentAudioTime > sentence.startSecond && this.currentAudioTime <= sentence.endSecond
      })
      return this.sentences.indexOf(sentence)
    },
    activeAudioFlacUrl () {
      return this.activeResponse.audioFlacUrl
    },
    activeAudioUrl () {
      return this.activeResponse.audioUrl
    },
    activeAudioContentType () {
      return this.activeResponse.contentType
    }
  },
  mounted: function () {
  },
  methods: {
    sentimentClass (score) {
      return `st-sentiment-badge--${kebabCase(this.sentimentString(score))}`
    },
    sentimentString (score) {
      return BADGE_CAPTIONS[bucketIdx(score)]
    },
    playTranscript (response) {
      if (response.audioUrl !== this.activeAudioUrl) {
        this.activeResponse = response
        this.$nextTick(() => {
          this.$refs.transcriptAudio.load()
        })
      }
      setTimeout(() => {
        this.$refs.transcriptAudio.play()
      }, 1200)
    },
    openEditMode (response) {
      this.editResponse = response
      this.sentences = response.sentences.map(sentence => {
        return Object.assign({ formattedTimes: sentence.formattedTimes }, sentence)
      })
      if (this.activeAudioUrl !== response.audioUrl) {
        this.activeAudioUrl = response.audioUrl
        this.$nextTick(() => {
          this.$refs.transcriptAudio.load()
        })
      }
    },
    exitEditMode () {
      this.editResponse = null
      this.sentences = null
    },
    playSentence (startTime, endTime) {
      window.clearTimeout(this.currentSentenceTimeout)

      startTime = startTime + S_BUFFER
      endTime = endTime + S_BUFFER

      this.$refs.transcriptAudio.currentTime = startTime
      this.$refs.transcriptAudio.play()
      let duration = (endTime - startTime) * 1000
      this.currentSentenceTimeout = setTimeout(() => {
        this.$refs.transcriptAudio.pause()
      }, duration)
    },
    handleTimeUpdate (ev) {
      this.currentAudioTime = ev.srcElement.currentTime
    },
    shortTranscript (transcript) {
      let previewText = transcript.slice(0, 97)

      if (transcript.length > 97) {
        previewText += '...'
      }

      return previewText
    }
  }
}
</script>
