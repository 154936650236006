<template>
  <div class="icon_clock">
    <div class="icon_clock__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-1 0a7 7 0 10-7 7 7 7 0 007-7z" />
        <path
          d="M1 0a1 1 0 011 1 1.866 1.866 0 01-.363.765A.944.944 0 011 2a1 1 0 010-2z"
          transform="translate(7 7)"
        />
        <path
          d="M-21026.5-10160v5.385h3.3v1h-4.3v-6.385z"
          transform="translate(21035 10162)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
