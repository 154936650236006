<template>
  <div
    :class="{
      'st-inline-spinner--no-small': !small,
      'st-inline-spinner--small': small
    }"
  >
    <cv-loading
      :class="{
        'st-spinner': true,
        'st-inline-spinner': blackColor,
      }"
      :small="small"
    />
    <small
      :class="{
        'text-grey': true,
        'text-grey--smaller': small
      }"
    >{{ loadingText }}</small>
  </div>
</template>

<script>
import CvLoading from '@carbon/vue/src/components/cv-loading/cv-loading'

export default {
  components: {
    CvLoading
  },
  props: {
    small: {
      type: Boolean,
      default: true
    },
    loadingText: {
      type: String,
      default: ''
    },
    blackColor: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
