<template>
  <cv-toast-notification
    class="st-toast-notification"
    :caption="caption"
    :kind="kind"
    :sub-title="subTitle"
    :title="title"
    @close="$emit('click')"
  />
</template>

<script>
import CvToastNotification from '@carbon/vue/src/components/cv-toast-notification/cv-toast-notification'

export default {
  components: {
    CvToastNotification
  },
  props: {
    caption: {
      type: String,
      required: false,
      default: ''
    },
    kind: {
      type: String,
      required: false,
      default: ''
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
    }
  },
  mounted () {
    if (this.kind !== 'error') {
      setTimeout(() => {
        this.$emit('click')
      }, 2100)
    }
  }
}
</script>
