<template>
  <div class="st-survey-hub">
    <div class="bx--grid mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          caption="Continue"
          icon-name="arrow"
          :disabled="!surveySectionsComplete"
          :url="surveySectionsComplete ?`/surveys/${survey.id}/publish`: ``"
        />
      </div>
    </div>
    <div class="bx--grid">
      <div class="bx--row">
        <div
          class="bx--col-xs-12"
        >
          <div class="st-survey-hub__head">
            <st-hyperlink
              class="back-hyperlink-spacing--extra-space"
              :back-link="true"
              text="Back"
              :url="`/projects/${survey.projectId}`"
            />
            <div class="desktop-page-right-action-button desktop-page-right-action-button--minimal-space">
              <st-button
                caption="Continue"
                icon-name="arrow"
                :url="surveySectionsComplete ?`/surveys/${survey.id}/publish`: ``"
                :disabled="!surveySectionsComplete"
              />
            </div>
          </div>
          <div class="st-survey-hub__header-spacing">
            <h1 class="project__page-header">
              {{ surveyName }}
            </h1>
            <div
              v-if="canEdit && !survey.published"
              @click="editNameModal = true"
            >
              <icon-wrapper
                icon-name="edit"
                class="st-survey-hub__edit-name-icon"
              />
            </div>
          </div>
          <div
            class="st-survey-hub__share-button-and-team-popup"
          >
            <participant-avatar
              v-for="(member, index) in limitSurveyTeamMemberAvatars"
              :key="index"
              :class="{
                'st-survey-hub__team-avatar': true,
                'st-survey-hub__team-avatar-modal-open': addMembersModalOpen
              }"
              :participant="member.user"
            />
            <div
              class="st-survey-hub__record-members"
              :style="shareButtonSpacing"
            >
              <survey-members
                :members="surveyUsers"
                :survey-id="survey.id"
                :users="users"
                :can-edit="canEdit"
                @updateMembers="updateMembers"
                @modalClosed="addMembersModalOpen = false"
                @modalOpen="addMembersModalOpen = true"
                @removeMember="removeSurveyMember"
                @updateMember="updateSurveyMember"
              />
            </div>
          </div>
          <div class="st-survey-hub__combine-cards-wrapper">
            <div class="st-survey-hub__required-cards-wrapper">
              <survey-hub-card
                v-for="(section, index) in requiredSections"
                :key="index"
                :complete="section.complete"
                :description="section.description"
                :icon="section.icon"
                :survey-id="survey.id"
                :title="section.title"
                :url-end="section.urlEnd"
              />
            </div>
            <p class="st-survey-hub__optional-text">
              Optional Sections
            </p>
            <div class="st-survey-hub__optional-cards-wrapper">
              <survey-hub-card
                v-for="(section, index) in optionalSections"
                :key="index"
                :bolded-text="section.boldedText"
                :complete="section.complete"
                :description="section.description"
                :icon="section.icon"
                :survey-id="survey.id"
                :title="section.title"
                :url-end="section.urlEnd"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <st-modal
      v-model="editNameModal"
      :scrollable-modal="true"
    >
      <template slot="modalTitle">
        Edit Survey Name
      </template>
      <template
        slot="modalActions"
      >
        <st-input
          v-model="surveyName"
          class="st-survey-hub__edit-name-input"
          :show-label="true"
          label="Survey Name"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          :disabled="!surveyName"
          icon-name="save"
          @click="editSurveyName"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IconWrapper, StButton, StHyperlink, StInput, StModal } from '../../common'
import { isDesktop } from '../../../mixins'
import { ParticipantAvatar } from '../insights'
import SurveyHubCard from './survey-hub-card'
import SurveyMembers from './survey-members'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInput,
    StModal,
    SurveyHubCard,
    SurveyMembers,
    ParticipantAvatar
  },
  mixins: [isDesktop],
  props: {
    initialSurvey: {
      type: Object,
      required: true
    },
    questionCount: {
      type: Number,
      required: true
    },
    users: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      addMembersModalOpen: false,
      editNameModal: false,
      optionalSections: [
        {
          complete: this.initialSurvey['audienceComplete?'],
          description: 'Add recipients to share your survey with and start getting feedback.',
          icon: 'participants',
          title: 'Audience',
          urlEnd: 'audience'
        },
        {
          complete: this.initialSurvey['incentivesComplete?'],
          description: 'Add a reward to recipients in order to increase engagement.',
          icon: 'incentives',
          title: 'Incentives',
          urlEnd: 'incentives'
        },
        {
          complete: this.initialSurvey['segmentsComplete?'],
          description: 'Set up different demographic attributes that can help you understand your audience.',
          icon: 'segment',
          title: 'Segments',
          urlEnd: 'segments'
        }],
      requiredSections: [{
        complete: this.initialSurvey['invitationComplete?'],
        description: 'Set up a greeting page to give recipients a welcome message to your survey.',
        icon: 'welcome',
        title: 'Welcome Page',
        urlEnd: 'landing_page'
      },
      {
        complete: this.initialSurvey['contentComplete?'],
        description: 'Choose and add different questions to your survey to gather insights.',
        icon: 'survey-content',
        title: 'Content',
        urlEnd: this.questionCount > 0 ? 'content' : 'choose_template'
      }],
      survey: this.initialSurvey,
      surveyName: this.initialSurvey.name
    }
  },
  computed: {
    ...mapState(['currentUser', 'navOpen']),
    limitSurveyTeamMemberAvatars () {
      return this.survey.members.slice(0, 5)
    },
    shareButtonSpacing () {
      let styleObject = {}
      this.limitSurveyTeamMemberAvatars.length <= 3 ? styleObject['margin'] = '0 0 0 16px' : styleObject['margin'] = '0 0 0 -16px'
      return styleObject
    },
    surveySectionsComplete () {
      return this.requiredSections.every(section => { return section.complete })
    },
    surveyUsers () {
      return this.survey.members.map(member => {
        return { ...member.user, permission: member.permission, surveyMemberId: member.id }
      })
    },
    canEdit () {
      return this.initialSurvey.canEdit
    }
  },
  watch: {
    navOpen (newValue) {
      if (!this.isDesktop) { this.addMembersModalOpen = newValue }
    }
  },
  methods: {
    editSurveyName () {
      let url = `/surveys/${this.survey.id}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { name: this.surveyName }
      })
        .then(res => { this.editNameModal = false })
        .catch(err => {
          console.error(err)
          this.surveyName = this.survey.name
        })
    },
    removeSurveyMember (payload) {
      this.survey.members.splice(this.survey.members.indexOf(payload), 1)
    },
    updateMembers (members) {
      this.survey.members = members
    },
    updateSurveyMember (payload) {
      let member = this.survey.members.filter(member => { return member.user.email === payload.email })
      member[0].permission = payload.permission
    }
  }
}
</script>
