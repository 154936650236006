<template>
  <div class="icon_importcsv">
    <div class="icon_importcsv__svg">
      <svg
        viewBox="0 0 16 16.002"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h9.881v1H0z"
          transform="translate(5.996 15)"
        />
        <path
          d="M0 0h7.253v1H0z"
          transform="translate(4.739)"
        />
        <path
          d="M0 0h1v12H0z"
          transform="translate(14.99 4)"
        />
        <path
          d="M0 0h1v5H0z"
          transform="translate(10.993)"
        />
        <path
          d="M4.1.8L1.6 2.9 4.1 5l-.6.8L0 2.9 3.5 0z"
          transform="rotate(-90 8.001 8.001)"
        />
        <path
          d="M15 1H0V0h15z"
          transform="rotate(-90 8.7 6.3)"
        />
        <path
          d="M0 0h5v1H0z"
          transform="translate(10.993 4)"
        />
        <path
          d="M-21146.123-10161.846l4 4-.707.707-4-4z"
          transform="translate(21158.121 10161.853)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(4.996 7)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(9.99 7)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(9.99 10)"
        />
        <path
          d="M0-.5h4v1H0z"
          transform="translate(4.996 10)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
