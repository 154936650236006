var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'bx--grid': true,
    'st-join-complete__page': _vm.isJoinSurvey
  }},[(_vm.isWdm)?[_c('div',{staticClass:"st-complete__wdm-logo"}),_vm._v(" "),_c('div',{staticClass:"st-complete__wdm-thank-you"},[_vm._v("\n      Thank you for reviewing this submission\n    ")]),_vm._v(" "),_c('div',{staticClass:"bx--row st-complete__follow-up"},[(_vm.followUpLink)?_c('div',{staticClass:"bx--col-xs-12 bx--col-md-6"},[_c('st-button',{staticClass:"st-complete__follow-up-btn",attrs:{"url":_vm.followUpLink,"caption":"Next Submission","underline":false,"variant":['secondary']}})],1):_vm._e(),_vm._v(" "),_c('div',{class:{
          'bx--col-xs-12': true,
          'bx--col-md-6': _vm.followUpLink }},[_c('st-button',{staticClass:"st-complete__follow-up-btn",attrs:{"url":"https://www.workdesign.com/the-next-work-environment-jury-feedback-part-1/","caption":"All Submissions","underline":false,"variant":_vm.followUpLink ? [] : ['secondary']}})],1)]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',{staticClass:"st-complete__wdm-thanks-sponsors"},[_vm._v("\n      Special thanks to our sponsors:\n    ")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{class:{
        'st-powered-by-spacetrics st-powered-by-spacetrics--complete': true,
        'st-powered-by-spacetrics--wdm-complete': true,
        'st-powered-by-spacetrics--desktop': _vm.isDesktop
      }},[_vm._v("\n      Powered by\n      "),_c('icon-wrapper',{staticClass:"st-powered-by-spacetrics__icon",attrs:{"actionable":false,"icon-name":"spacetrics"}}),_vm._v("\n      spacetrics\n    ")],1)]:(_vm.isJoinSurvey)?[_vm._m(1)]:[_c('div',{staticClass:"st-complete__column"},[_c('div',{staticClass:"st-complete__image"}),_vm._v(" "),_c('p',{staticClass:"st-complete__text st-complete__text--header"},[_vm._v("\n        Thank you for submitting your feedback!\n      ")]),_vm._v(" "),_vm._m(2),_vm._v(" "),(_vm.isPreview && !_vm.isDesktop)?_c('st-hyperlink',{attrs:{"text":"Exit Preview","icon-name":"hide-password","icon-class":"st-hyperlink__default-icon-spacing","url":("/surveys/" + (_vm.survey.id) + "/publish")}}):_vm._e(),_vm._v(" "),_c('div',{class:{
          'st-powered-by-spacetrics st-complete__powered-by-complete': true,
          'st-powered-by-spacetrics--desktop': _vm.isDesktop
        }},[_vm._v("\n        Powered by\n        "),_c('icon-wrapper',{staticClass:"st-powered-by-spacetrics__icon",attrs:{"actionable":false,"icon-name":"spacetrics"}}),_vm._v("\n        spacetrics\n      ")],1)],1)]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-complete__logo-row"},[_c('div',{staticClass:"st-complete__logo st-complete__logo--crypton"}),_vm._v(" "),_c('div',{staticClass:"st-complete__logo st-complete__logo--acoufelt"}),_vm._v(" "),_c('div',{staticClass:"st-complete__logo st-complete__logo--kimball"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-join-complete__content-wrapper"},[_c('div',{staticClass:"st-join-complete__illustration"}),_vm._v(" "),_c('div',{staticClass:"st-join-complete__congrats-and-info"},[_c('p',{staticClass:"st-join-complete__congrats"},[_vm._v("\n          Congrats!\n        ")]),_vm._v(" "),_c('div',{staticClass:"st-join-complete__info-block"},[_c('p',{staticClass:"st-join-complete__info"},[_vm._v("\n            Well done and thank you for your time!\n          ")]),_vm._v(" "),_c('p',{staticClass:"st-join-complete__info"},[_vm._v("\n            Your reward will be sent to your email and phone number after we review your submission.\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"st-join-complete__additional-and-email-us"},[_c('p',{staticClass:"st-join-complete__additional"},[_vm._v("\n          Questions or comments?\n        ")]),_vm._v(" "),_c('a',{staticClass:"st-join-complete__email-us",attrs:{"href":"mailto:zak@spacetrics.com"}},[_vm._v("\n          Please email us.\n        ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-join-complete__additional-and-email-us"},[_c('p',{staticClass:"st-complete__text"},[_vm._v("\n          We appreciate your time. If you have anything to share please feel free to\n        ")]),_vm._v(" "),_c('a',{staticClass:"st-complete__email-us",attrs:{"href":"mailto:zak@spacetrics.com"}},[_vm._v("\n          email us.\n        ")])])}]

export { render, staticRenderFns }