<template>
  <div class="icon_emoji_dislike">
    <div class="icon_emoji_dislike__svg">
      <svg
        viewBox="0 0 26.325 26.325"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M26.325 13.162A13.162 13.162 0 1113.162 0a13.162 13.162 0 0113.163 13.162zm-1 0a12.162 12.162 0 10-12.163 12.163 12.162 12.162 0 0012.163-12.163z" />
        <path
          d="M3.51 2A1.755 1.755 0 010 2z"
          transform="translate(6.142 6.775)"
        />
        <path
          d="M1.5 0A1.5 1.5 0 110 1.5 1.5 1.5 0 011.5 0z"
          transform="translate(16.535 7.163)"
        />
        <path
          d="M-.052.38L8.429-.5l.1.995-8.478.88z"
          transform="translate(8.876 15.205)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
