<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    nextQuestionId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters("participants", ["participantSubmissionToken"]),
    isSubmissionSkippable () {
      return false
    }
  },
  methods: {
    ...mapMutations('participants', ['setInstructionsPage']),
    submitQuestionAnswer () {
      if (this.isPreview) {
        this.advanceForPreview()
      } else if (this.isSubmissionSkippable) {
        this.advanceWithoutSubmission()
      } else {
        this.performSubmission()
      }
    },
    advanceForPreview () {
      if (!this.isSurveyPreview) { return }
      if (this.nextPreviewQuestion) {
        window.location.href = `/questions/${this.nextPreviewQuestion.id}/preview`
      } else {
        window.location.href = `/surveys/${this.surveyId}/preview_complete`
      }
    },
    advanceWithoutSubmission () {
      if (!this.nextQuestionId) {
        window.location.href = `/surveys/${this.surveyId}/complete`
      } else {
        let url = '/questions/' + this.nextQuestionId + '/question_and_response.json'
        this.$axios.get(url)
          .then(res => {
            this.$emit('nextQuestion', res.data)
            this.submitting = false
          })
      }
    },
    performSubmission () {
      console.log(this.answerData())
      this.$store.commit('toggleFullSpinner', true)
      let url = `/questions/${this.questionId}/submit?participant_token=${this.participantSubmissionToken}`;
      let data = this.answerData()
      this.$emit('loading', true)
      this.$axios.request({
        url: url,
        method: 'post',
        data: data
      })
        .then(res => {
          if (res.data.surveyComplete) {
            window.location.href = `/surveys/${this.surveyId}/complete`
          } else {
            this.$emit('nextQuestion', res.data.nextQuestion)
            this.setInstructionsPage(1)
            if (this.activeQuestion.questionType === 'virtual_tour' && res.data.nextQuestion.questionType === 'virtual_tour') {
              this.$nextTick(() => {
                this.panoChanges = []
                this.initializeNewMediaRecorder()
                this.setActivePhase('instructions')
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('toggleFullSpinner', false)
          this.$emit('errorMessage')
        })
    }
  }
}
</script>
