export const PLAN_COMPARISON_FEATURES = [
  {
    name: "Sentiment Analysis",
    plans: ["basic", "premium", "development"],
  },
  {
    name: "Transcription Processing",
    plans: ["basic", "premium", "development"],
  },
  {
    name: "Multiple Question Types",
    plans: ["basic", "premium", "development"],
  },
  {
    name: "Publicly Accessible Survey Link",
    plans: ["basic", "premium", "development"],
  },
  {
    name: "Internal Audience",
    plans: ["basic", "premium", "development"],
  },
  {
    name: "Photo Library",
    plans: ["basic", "premium", "development"],
  },
  {
    name: "Unlimited Team Members",
    plans: ["basic", "premium", "development"],
  },
  {
    name: "Multiple Projects",
    plans: ["premium", "development"],
  },
  {
    name: "51 + Recipients/Project/mo",
    plans: ["premium", "development"],
  },
  {
    name: "Unlimited Surveys",
    plans: ["premium", "development"],
  },
  {
    name: "Custom Branding",
    plans: ["premium", "development"],
  },
  {
    name: "Custom Templates",
    plans: ["premium", "development"],
  },
  {
    name: "Aggregated Data",
    plans: ["premium", "development"],
  },
  {
    name: "Personal Onboarding",
    plans: ["development"],
  },
  {
    name: "Survey Consultation",
    plans: ["development"],
  },
  {
    name: "Insights Consultation",
    plans: ["development"],
  },
  {
    name: "Embeddable Spacetrics",
    plans: ["development"],
  },
  {
    name: "Audience Empaneling",
    plans: ["development"],
  },
]
