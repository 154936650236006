<template>
  <a
    class="st-card"
    :href="url"
    @mouseenter="hover=true"
    @mouseleave="hover=false"
    @click.prevent="$emit('click')"
  >
    <slot
      name="card-content"
      :hover="hover"
    />
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
      required: false
    }
  },
  data: function () {
    return {
      hover: false
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
