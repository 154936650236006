var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
      'st-accordion': true,
      'st-accordion--with-custom-icon': _vm.customIcon
    }},[_c('div',{class:{ 'st-accordion--remove-focus': _vm.removeFocus },attrs:{"tabindex":"0"},on:{"focus":function($event){_vm.hover = true},"focusout":function($event){_vm.hover = false, _vm.removeFocus = false},"mousedown":function($event){_vm.removeFocus = true},"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleExpanded.apply(null, arguments)},"click":_vm.toggleExpanded}},[_c('div',{class:[{
          'st-accordion__visible-row': true,
          'st-accordion__custom-icon-row': _vm.customIcon
        }, _vm.visibleRowClass]},[_vm._t("visible-section",null,{"expanded":_vm.expanded,"hover":_vm.hover}),_vm._v(" "),(!_vm.customIcon)?_c('div',[(_vm.expanded)?_c('div',[_c('div',{staticClass:"st-accordion--with-text"},[(_vm.expandedText.length > 0)?_c('div',{staticClass:"st-accordion__expanded-text"},[_vm._v("\n                ("+_vm._s(_vm.expandedText)+")\n              ")]):_vm._e(),_vm._v(" "),_vm._t("persist-visible-section-right"),_vm._v(" "),_c('icon-wrapper',{class:[{ 'st-accordion__minus-icon': true }, _vm.iconClass],attrs:{"icon-name":"minus"}})],2)]):_c('div',{staticClass:"st-accordion__plus-icon-wrapper"},[_vm._t("persist-visible-section-right"),_vm._v(" "),_c('icon-wrapper',{class:[{ 'st-accordion__plus-icon': true }, _vm.iconClass],attrs:{"icon-name":"plus"}})],2)]):_vm._e()],2)]),_vm._v(" "),(_vm.expanded)?_c('div',{class:{
        'st-accordion__collapse' : true,
        'st-accordion__collapse--lock-bottom': _vm.mobileBottomLock
      }},[_vm._t("expanded-section",null,{"toggleExpanded":_vm.toggleExpanded})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }