import { intersection } from 'lodash'

// given an array, sum the values
// if a prop is given, sum the values of that property
export function sum (arr, prop = null) {
  if (prop) {
    arr = arr.map((arr) => arr[prop])
  }
  return arr.reduce((acc, val) => acc + val, 0)
}

// given an array, return the average value of the entries
// if a prop is given, averages the value of that property instead
export function average (arr, prop = null) {
  const l = arr.length
  if (l === 0) { return 0 }
  const total = sum(arr, prop)
  return total / l
}

export function percent (num, denom, fixedSize = 1) {
  if (denom === 0) {
    return '0.0'
  }
  return dec2percent(num / denom, fixedSize)
}

export function dec2percent (val, fixedSize = 1) {
  return (100 * val).toFixed(fixedSize)
}

// checkedOptions and comparisonOptions should both be objects
//   the keys should match between the two
//   the values for the keys should be arrays
// if for each value array in comparisonOptions, it shares a value with the
//    corresponding checkedOptions array, then we return true
export function matchesFilters (checkedOptions, comparisonOptions) {
  for (let key in checkedOptions) {
    if (!comparisonOptions.hasOwnProperty(key)) {
      return false
    }

    if (!shareAnyElement(checkedOptions[key], comparisonOptions[key])) {
      return false
    }
  }
  return true
}

function shareAnyElement (...arrays) {
  return intersection(...arrays).length > 0
}

// given a score, give which bucket it belongs to ()
// options: minScore=-1 maxScore=1 bucketCount=5
export function bucketIdx (score, options = {}) {
  let minScore = options.minScore || -1
  let maxScore = options.maxScore || 1
  let scoreRange = maxScore - minScore
  let bucketCount = options.bucketCount || 5
  let normalizedScore = (score - minScore) / scoreRange
  const bucket = Math.floor(bucketCount * normalizedScore)
  return Math.min(bucket, bucketCount - 1)
}

export function bucketCounts (scores) {
  if (!scores) { return [] }

  return scores.reduce(
    (barCounts, score) => {
      barCounts[bucketIdx(score)] += 1
      return barCounts
    },
    new Array(5).fill(0)
  )
}

const SECONDS_PER_MINUTE = 60
const SECONDS_PER_HOUR = 3600

export function formatSecondsWithColon (seconds) {
  if (seconds <= SECONDS_PER_MINUTE) {
    return formatTimeWithUnit(seconds, 'second')
  } else if (seconds <= SECONDS_PER_HOUR) {
    let minutes = Math.floor(seconds / SECONDS_PER_MINUTE)
    let remainderSeconds = Math.round(seconds % SECONDS_PER_MINUTE)
    return `${minutes}:${remainderSeconds} minutes`
  } else {
    let hours = Math.floor(seconds / SECONDS_PER_HOUR)
    let minutes = Math.round(seconds % SECONDS_PER_HOUR)
    return `${hours}:${minutes} hours`
  }
}

export function formatSeconds (seconds) {
  if (seconds <= SECONDS_PER_MINUTE) {
    return formatTimeWithUnit(seconds, 'second')
  } else if (seconds <= SECONDS_PER_HOUR) {
    return formatTimeWithUnit(seconds / SECONDS_PER_MINUTE, 'minute')
  } else {
    return formatTimeWithUnit(seconds / SECONDS_PER_HOUR, 'hour')
  }
}

function formatTimeWithUnit (value, singularUnitName) {
  value = Math.round(value)
  let result = `${value} ${singularUnitName}`
  if (value > 1) {
    result += 's'
  }
  return result
}

export function formatSecondsToStopwatch (timeInSeconds) {
  let seconds = Math.round(timeInSeconds % SECONDS_PER_MINUTE)
  let formattedSeconds = zeroPad(seconds)
  let minutes = Math.floor(timeInSeconds / SECONDS_PER_MINUTE)
  let formattedMinutes = zeroPad(minutes)
  let hours = Math.floor(timeInSeconds / SECONDS_PER_HOUR)
  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`
  } else {
    return `${formattedMinutes}:${formattedSeconds}`
  }
}

function zeroPad (number) {
  if (number < 10) {
    return '0' + number
  } else {
    return number
  }
}

export function caseInsensitiveSortBy (arr, prop, reverse = false) {
  let sign = reverse ? -1 : 1
  return arr.slice().sort((a, b) => {
    return sign * a[prop].localeCompare(b[prop], 'en', { sensivity: 'base' })
  })
}

export function doubleCaseInsensitiveSortBy (arr, prop1, prop2, reverse = false) {
  let sign = reverse ? -1 : 1
  return arr.slice().sort((a, b) => {
    if (sign * a[prop1].localeCompare(b[prop1], 'en', { sensivity: 'base' }) === 0) {
      return sign * a[prop2].localeCompare(b[prop2], 'en', { sensivity: 'base' })
    }
    return sign * a[prop1].localeCompare(b[prop1], 'en', { sensivity: 'base' })
  })
}

export function recentDateLimit (dateStr, days) {
  let date = new Date(dateStr)
  let dateLimit = new Date()
  dateLimit.setDate(dateLimit.getDate() - days) // last x days
  return dateLimit - date <= 0
}

export function compareObjects (objectToFilter, objectWithInstance, key, notOperator = false) {
  return objectToFilter.filter(filterValue => {
    if (notOperator) {
      return !objectWithInstance.some(instanceValue => {
        return filterValue[key] === instanceValue[key]
      })
    } else {
      return objectWithInstance.some(instanceValue => {
        return filterValue[key] === instanceValue[key]
      })
    }
  })
}
