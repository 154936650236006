var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-new-question__page"},[(_vm.showQuestionsPanel)?_c('div',{staticClass:"st-new-question__questions-added st-new-question__questions-added--template"},[_c('p',{staticClass:"st-new-question__mobile-questions-title"},[_vm._v("\n      Questions\n    ")]),_vm._v(" "),_c('div',{staticClass:"st-new-question__new-tile-scroll-container"},[_c('div',{class:{
          'st-new-question__scroll-area': true,
          'st-new-question__scroll-area--empty': _vm.questions.length === 0,
        }},_vm._l((_vm.questions),function(question,idx){return _c('new-metric-tile',{key:question.id,attrs:{"index":idx},on:{"setActive":function($event){return _vm.setActive(idx)},"showSpecifications":_vm.showSpecifications}})}),1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bx--grid"},[_c('div',{staticClass:"bx--row st-new-question__back-and-save-links-container st-new-question__back-and-save-links-container--read-only"},[(_vm.activeTab === _vm.SPECS_TABS.SPECIFICATIONS)?_c('div',{staticClass:"st-specifications__mobile-header"},[_c('a',{attrs:{"href":"/welcome"}},[_c('icon-wrapper',{staticClass:"st-choose-template__mobile-header-icon",attrs:{"icon-name":"spacetrics"}})],1)]):_vm._e(),_vm._v(" "),_c('div',{class:{
          'bx--col-sm-12 st-new-question__back-and-save-links st-edit-survey__col-space': true
        }},[_c('st-hyperlink',{attrs:{"back-link":true,"text":"Back"},on:{"click":_vm.handleBackLink}}),_vm._v(" "),(_vm.showUseTemplate && _vm.surveyBackId)?[_c('div',{staticClass:"st-new-question__preview-choose-message"},[_vm._v("\n            Previewing Template\n          ")]),_vm._v(" "),_c('st-button',{attrs:{"caption":"Choose Template","icon-name":"pointer","show-action":true},on:{"click":_vm.useTemplate}})]:_c('div',{class:{
            'st-new-question__preview-only-message': true,
            'st-new-question__preview-only-message--read-only': !_vm.template.canEdit,
          }},[(_vm.template.canEdit)?_c('st-button',{attrs:{"caption":"Edit Template","icon-name":"arrow","show-action":true,"url":("/templates/" + (_vm.template.id) + "/edit")}}):_c('span',[_vm._v("This is a read-only view, you are unable to save changes.")])],1)],2)]),_vm._v(" "),_c('div',{staticClass:"bx--row"},[_c('div',{staticClass:"bx--col-md-7 bx--col-sm-6 st-new-question__question-specifications"},[_c('div',{class:{
            'st-specifications__header-row': true,
            'st-specifications__header-row--incomplete': !_vm.activeQuestion.complete,
          }},[_c('p',{staticClass:"st-specifications__title"},[_vm._v("\n            "+_vm._s(_vm.activeMetricName)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"st-specifications__header-icons-container"},[_c('st-tooltip',{staticClass:"st-specifications__header-icon-info-tooltip",attrs:{"direction":_vm.isDesktop ? 'bottom' : 'left',"max-width":_vm.isDesktop ? 411 : 225,"tip":_vm.activeMetricDescription.description}},[_c('icon-wrapper',{staticClass:"st-specifications__header-icon",attrs:{"icon-name":"info"}})],1)],1)]),_vm._v(" "),(!_vm.activeQuestion.complete)?_c('div',{staticClass:"st-specifications__alert-row"},[_c('icon-wrapper',{staticClass:"st-new-question-tile__alert-icon",attrs:{"icon-name":"alert"}}),_vm._v(" "),_c('p',{staticClass:"st-specifications__alert-description"},[_vm._v("\n            This question is incomplete.\n          ")])],1):_vm._e(),_vm._v(" "),_c('specifications',{key:_vm.activeQuestion.id,attrs:{"is-read-only":true}})],1),_vm._v(" "),_c('div',{staticClass:"bx--offset-md-1 bx--col-md-4 bx--offset-sm-1 bx--col-sm-5"},[_c('div',{staticClass:"st-new-question__virtual-phone-preview"},[_c('preview-example',{attrs:{"survey-name":_vm.surveyName,"survey-question-count":_vm.questions.length}})],1),_vm._v(" "),_c('div',{staticClass:"st-new-question__help-icon-container"},[_c('a',{attrs:{"id":"Intercom","href":"javascript:void(o)"}},[_c('icon-wrapper',{staticClass:"st-new-question__help-icon",attrs:{"icon-name":"help"}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }