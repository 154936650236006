<template>
  <div class="icon_archive">
    <div class="icon_archive__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 0h14l1 12.062H0zm13.914 11.062L14.079 1H1.921l-.835 10.062z" />
        <path
          d="M0 0h16v5H0zm15 4V1H1v3z"
          transform="translate(0 11)"
        />
        <path
          d="M3.3.4L.8 2.5l2.5 2.1-.6.8-3.5-2.9L2.7-.4z"
          transform="rotate(-90 6.85 1.352)"
        />
        <path
          d="M5 .5H0v-1h5z"
          transform="rotate(-90 7.999 .001)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
