<template>
  <div class="icon_expand-image">
    <div class="icon_expand-image__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.075.795L1.59 2.88l2.485 2.086-.6.795L0 2.88 3.478 0z"
          transform="rotate(135 6.85 5.223)"
        />
        <path
          d="M4.075.795L1.59 2.88l2.485 2.086-.6.795L0 2.88 3.478 0z"
          transform="rotate(-45 13.656 5.657)"
        />
        <path
          d="M6.343.993H0V0h6.343z"
          transform="rotate(135 2.108 5.791)"
        />
        <path
          d="M6.343.993H0V0h6.343z"
          transform="rotate(135 5.777 4.272)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
