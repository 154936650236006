<template>
  <div class="icon_surveys">
    <div class="icon_surveys__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h6v1H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(10 2)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(5 1)"
        />
        <path
          d="M0 0h6v1H0z"
          transform="translate(5 3)"
        />
        <path
          d="M0 0h8v1H0z"
          transform="translate(6 6)"
        />
        <path
          d="M0 0h8v1H0z"
          transform="translate(6 9)"
        />
        <path
          d="M0 0h8v1H0z"
          transform="translate(6 12)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(2 6)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(2 9)"
        />
        <path
          d="M0 0h2v1H0z"
          transform="translate(2 12)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
        <path
          d="M0 0h1v14H0z"
          transform="translate(0 2)"
        />
        <path
          d="M0 0h1v14H0z"
          transform="translate(15 2)"
        />
        <path
          d="M0 0h2v2H0z"
          transform="translate(7)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
