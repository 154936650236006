<template>
  <div>
    <opinion-metric-transcripts v-if="activeQuestionView === 'Transcripts'" />
    <opinion-metric-results v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OpinionMetricResults from './opinion-metric-results'
import OpinionMetricTranscripts from './opinion-metric-transcripts'

export default {
  components: {
    OpinionMetricResults,
    OpinionMetricTranscripts
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionView'])
  }
}
</script>
