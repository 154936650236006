<template>
  <st-card
    class="st-icon-and-description-card__contents"
    :url="url"
    tabindex="0"
    @click="handleNewPath"
  >
    <template
      v-slot:card-content
    >
      <div
        class="st-icon-and-description-card"
        @mouseenter="hover = true"
        @mouseleave="hover = null"
      >
        <div>
          <div class="st-icon-and-description-card__title-and-preview">
            <div class="st-icon-and-description-card__icon-and-title">
              <icon-wrapper
                class="st-icon-and-description-card__icon"
                :icon-name="iconName"
              />
              <p class="st-icon-and-description-card__title">
                {{ title }}
              </p>
            </div>
            <transition
              name="fade"
            >
              <st-hyperlink
                v-if="hover && showLink"
                class="st-icon-and-description-card__preview-link"
                text="Preview"
                icon-name="view-password"
                icon-class="st-hyperlink__default-icon-spacing"
                @click.native.stop="$emit('hyperlinkClick')"
              />
            </transition>
          </div>
          <p class="st-icon-and-description-card__description">
            {{ description }}
          </p>
        </div>
      </div>
    </template>
  </st-card>
</template>

<script>
import handleNewPath from '../../mixins/handleNewPath'
import IconWrapper from './icon-wrapper'
import StCard from './st-card'
import StHyperlink from './st-hyperlink'

export default {
  components: {
    'icon-wrapper': IconWrapper,
    'st-card': StCard,
    'st-hyperlink': StHyperlink
  },
  mixins: [handleNewPath],
  props: {
    /** Additional assistive text */
    description: {
      type: String,
      required: true
    },
    /** Used to display the icon in icon-wrapper */
    iconName: {
      type: String,
      required: true
    },
    /** Determine if a link within the card is shown */
    showLink: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Main description of the card */
    title: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      hover: false
    }
  }
}
</script>
