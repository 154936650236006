var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-slides"},[_c('div',{ref:"slidesMobile",staticClass:"st-slides__canvas-main",staticStyle:{"border":"none","border-style":"hidden","overflow-y":"scroll"}},_vm._l((_vm.templates),function(templateVal,templateKey,index){return _c('div',{key:'pr'+index,class:{
        'st-slides__block': true,
        'st-slides__block--active': _vm.currentTemplate === templateKey,
        'st-slides__block--1x1' : templateKey === '1x1',
        'st-slides__block--1x2' : templateKey === '1x2',
        'st-slides__block--2x1' : templateKey === '2x1',
        'st-slides__block--1x3' : templateKey === '1x3'
      },staticStyle:{"border":"none","overflow-y":"scroll"},style:({backgroundColor: _vm.slideColors[index], })},[_vm._l((_vm.texts),function(text,i){return _c('span',{key:'text'+i,style:({position: 'absolute', left: text.xPosition+'%', top: text.yPosition+'%', zIndex: 99}),domProps:{"innerHTML":_vm._s(text.content)}})}),_vm._v(" "),_vm._l((_vm.images),function(image,i){return _c('img',{key:i,style:({position: 'absolute', objectFit: 'cover', left: image.xPosition+'%', top: image.yPosition+'%', width: image.width+'%', height: image.height+'%', zIndex: 9}),attrs:{"src":image.mediaUrl,"alt":""}})}),_vm._v(" "),(_vm.currentTemplate === templateKey)?_vm._l((templateVal),function(val,boxIndx){return _c('div',{key:'preview'+val,ref:'preview-box' + val,refInFor:true,class:{
            'st-slides__box': true,
          },style:({backgroundColor: _vm.slideColors[boxIndx]})})}):_vm._e()],2)}),0),_vm._v(" "),_c('div',{staticClass:"st-specifications__button-slides-canvas"},[_c('st-button',{staticClass:"st-slide__button",attrs:{"caption":_vm.buttonCaption,"underline":false,"icon-name":"arrow","variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }