<template>
  <div class="bx--grid st-member-groups">
    <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-dropdown-button
          caption="Manage"
          :show-action="true"
          :options="dropdownOptions"
          @updateOption="handleDropdownOption"
        />
      </div>
    </div>
    <div>
      <div class="desktop-page-and-dropdown-button bx--col-sm-12 bx--col-xs-12">
        <h1 class="page-header">
          Member Groups
        </h1>
        <st-dropdown-button
          class="page-dropdown-button"
          caption="Manage"
          :show-action="true"
          :options="dropdownOptions"
          @updateOption="handleDropdownOption"
        />
      </div>
    </div>
    <div class="lookup-and-dropdown bx--col-sm-12 bx--col-xs-12">
      <st-input
        v-model="memberLookup"
        class="st-input--default lookup-and-dropdown__space-input"
        :show-icon="true"
        :show-label="false"
        label="Member Lookup"
        label-in-field-class="st-input--hide-mobile-label"
        @blur="updateSearchParam"
      />
      <st-dropdown-menu
        caption="Member Filter"
        :initial-active-option="filterOption"
        :options="['All Members', 'Recently Added', 'A - Z', 'Z - A']"
        @updateOption="setFilterOption"
      />
    </div>
    <div class="st-member-groups__accordions bx--col-sm-9 bx--col-xs-12">
      <template v-for="(group, i) in groups">
        <st-accordion
          v-if="isDesktop"
          :key="group.id"
          :expanded-text="expandedText(sortedUserGroups[i].length)"
        >
          <template v-slot:visible-section>
            <div class="st-member-groups__group-row">
              <icon-wrapper
                icon-name="list"
                class="st-member-groups__group-icon"
              />
              <div class="st-member-groups__group-name">
                {{ group.name }}
              </div>
              <div
                class="st-member-groups__action-icon"
                @click.stop="addMemberModal(i)"
              >
                <st-tooltip
                  tip="add members"
                  class="st-member-groups__tooltip"
                >
                  <icon-wrapper
                    icon-name="add-member"
                    class="st-member-groups__hover-icon"
                  />
                </st-tooltip>
              </div>
              <div
                class="st-member-groups__action-icon"
                @click.stop="openEditModal(i)"
              >
                <st-tooltip
                  tip="edit group name"
                  class="st-member-groups__tooltip"
                >
                  <icon-wrapper
                    icon-name="edit"
                    class="st-member-groups__hover-icon"
                  />
                </st-tooltip>
              </div>
              <div
                class="st-member-groups__action-icon"
                @click.stop="openDeleteModal(i)"
              >
                <st-tooltip
                  tip="delete group"
                  class="st-member-groups__tooltip"
                >
                  <icon-wrapper
                    icon-name="trash"
                    class="st-member-groups__hover-icon"
                  />
                </st-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:expanded-section>
            <div class="st-member-groups__group-expanded-section">
              <template v-if="group.users.length<1">
                <div>There are currently no team members in this group.</div>
              </template>
              <template v-else>
                <template
                  v-for="member in sortedUserGroups[i]"
                >
                  <div
                    :key="`avi-${member.id}-${group.id}`"
                  >
                    <participant-avatar
                      :participant="member"
                      class="st-member-groups__member-avatar"
                    />
                  </div>
                  <div
                    :key="`name-${member.id}-${group.id}`"
                    class="st-member-groups__name-email"
                  >
                    <div class="st-member-groups__name">
                      {{ member.firstName }} {{ member.lastName }}
                    </div>
                    <div class="st-member-groups__email">
                      {{ member.email }}
                    </div>
                  </div>
                  <div
                    :key="`departments-${member.id}-${group.id}`"
                    class="st-member-groups__departments-column"
                  >
                    <st-data-table-dropdown-info
                      v-if="member.departments.length > 0"
                      class="st-member-groups__department-dropdown"
                      :display-text="departmentsDisplay(member.departments)"
                      :open-title="departmentsOpenDisplay(member.departments)"
                      :info-rows="member.departments.map((d) => d.name)"
                      :show-count="true"
                    />
                  </div>
                  <div
                    :key="`kind-${member.id}-${group.id}`"
                    class="st-member-groups__member-kind"
                  >
                    <div>{{ getMemberKind(member.kind) }}</div>
                  </div>
                  <div
                    :key="`remove-${member.id}-${group.id}`"
                    class="st-member-groups__remove-container"
                  >
                    <div
                      class="st-member-groups__remove"
                      @click="openRemoveModal(i, [member.id], member.fullName)"
                    >
                      <div class="st-member-groups__remove-text">
                        Remove
                      </div>
                      <icon-wrapper icon-name="trash" />
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </st-accordion>
        <st-card
          v-else
          :key="group.id"
          class="st-list-and-group-card"
        >
          <template v-slot:card-content>
            <div class="st-list-and-group-card__list-name-and-recipients">
              <div class="st-list-and-group-card__icon-and-info">
                <icon-wrapper
                  icon-name="list"
                  class="st-list-and-group-card__icon"
                />
                <div class="st-list-and-group-card__name">
                  {{ group.name }}
                </div>
              </div>
              <div class="st-list-and-group-card__icon-and-info">
                <icon-wrapper
                  icon-name="members"
                  class="st-list-and-group-card__icon"
                  :actionable="false"
                />
                <div class="st-list-and-group-card__recipients">
                  {{ expandedText(group.users.length) }}
                </div>
              </div>
            </div>
            <st-overflow-menu
              :title="`${group.name}`"
              icon-name="list"
            >
              <st-overflow-menu-item
                text="View Members"
                icon-name="members"
                @mouseup="viewMemberModal(i)"
              />
              <st-overflow-menu-item
                text="Add Members"
                icon-name="add-member"
                @mouseup="addMemberModal(i)"
              />
              <st-overflow-menu-item
                text="Edit Group Name"
                icon-name="edit"
                @mouseup="openEditModal(i)"
              />
              <st-overflow-menu-item
                text="Delete Group"
                icon-name="trash"
                @mouseup="openDeleteModal(i)"
              />
            </st-overflow-menu>
          </template>
        </st-card>
      </template>
    </div>
    <st-modal
      v-model="removeUserModal"
      @closeModal="closeRemoveUserModal"
    >
      <template v-slot:modalTitle>
        Remove Member from Group
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          Are you sure you want to remove {{ modalUserName }} from {{ modalGroupName }}?
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Remove Member"
          :show-action="true"
          icon-name="trash"
          @click="removeMember"
        />
      </template>
    </st-modal>
    <st-modal v-model="createModal">
      <template v-slot:modalTitle>
        Create Member Group
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          <st-input
            v-model="newGroupName"
            class="st-input--default st-member-groups__group-name-input"
            :show-label="true"
            label="Member Group Name"
            placeholder="Name goes here"
            @emitEnter="createNewGroup"
          />
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Create Group"
          :show-action="true"
          icon-name="list"
          @click="createNewGroup"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="addMembersModal"
      size-class="st-modal--large"
    >
      <template v-slot:modalTitle>
        Add Members to {{ modalGroupName }}
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          <st-email-input
            ref="emailInput"
            label="Member's Emails:"
            invite-text="member group"
            :existing-members-list="accountMembers"
            :only-autocomplete="currentUser.kind !== 'admin' && currentUser.kind !== 'spacetrics_admin'"
            @addNewEmail="addNewEmail"
            @uncheckEmail="uncheckEmail"
            @removeEmail="removeNewEmail"
          />
          <st-dropdown-menu
            class="st-member-groups__add-member-filter-dropdown"
            caption="Member Filter"
            :initial-active-option="addMemberFilterOption"
            :options="['All Members', 'Recently Added']"
            @updateOption="setAddMemberFilterOption"
          />
          <st-data-table
            class="st-member-groups__member-table"
            :active-column="activeColumn"
            :headers="['Name', 'Email', 'Department']"
            :modal-table="true"
            :rows="sortedMembersByActiveColumn"
            :sortable-columns="['Name', 'Email']"
            @setActiveColumn="updateActiveColumn"
            @toggleActiveColumn="updateActiveColumn"
            @selectAll="toggleAllChecks(true)"
            @unselectAll="toggleAllChecks(false)"
          >
            <template v-slot:default="{ row: member, anyRowsChecked }">
              <st-data-table-checkbox-row
                :key="member.id"
                :row-data="member"
                :avatar-url="member.avatarUrl"
                :cell-keys="['fullName', 'email', 'departments']"
                :checked="member.selected"
                :show-all-checks="anyRowsChecked"
                @input="(val) => { updateCheck(member.id, val)}"
              >
                <template v-slot:cell-3>
                  <div v-if="member.departments.length === 0" />
                  <st-data-table-dropdown-info
                    v-else
                    :disabled="!isDesktop"
                    :display-text="departmentsDisplay(member.departments)"
                    :open-title="departmentsOpenDisplay(member.departments)"
                    :info-rows="member.departments.map((d) => d.name)"
                    :show-count="true"
                  />
                </template>
              </st-data-table-checkbox-row>
            </template>
          </st-data-table>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Add Members"
          :show-action="true"
          icon-name="add-member"
          @click="addMembers()"
        />
      </template>
    </st-modal>
    <st-modal v-model="deleteGroupModal">
      <template v-slot:modalTitle>
        Delete Group
      </template>
      <template v-slot:modalActions>
        <div
          class="st-member-groups__modal-text st-member-groups__modal-text--delete-group"
        >
          Are you sure you want to delete {{ modalGroupName }}? This won't affect projects in which members are part of.
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Delete Group"
          :show-action="true"
          icon-name="trash"
          @click="deleteGroup"
        />
      </template>
    </st-modal>
    <st-modal v-model="editGroupModal">
      <template v-slot:modalTitle>
        Edit Member Group
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          <st-input
            ref="editNameInput"
            v-model="modalGroupName"
            class="st-input--default st-member-groups__edit-name-input"
            :show-label="true"
            label="Group Name"
            @emitEnter="editGroupName"
          />
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          icon-name="save"
          @click="editGroupName"
        />
      </template>
    </st-modal>
    <st-modal v-model="csvModal">
      <template v-slot:modalTitle>
        Import CSV File
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          <!--  -->
          TO DO - ADD THE UPLOADER
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Import CSV"
          :show-action="true"
          icon-name="import-csv"
          @click="uploadCsv"
        />
      </template>
    </st-modal>
    <st-modal v-model="viewMembersModal">
      <template v-slot:modalTitle>
        Members in {{ modalGroupName }}
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__group-expanded-section st-member-groups__group-expanded-section--mobile">
          <template v-if="modalGroupUsers.length < 1">
            <div>There are currently no members in this group.</div>
          </template>
          <template v-else>
            <template v-for="member in modalGroupUsers">
              <div
                :key="`avi-${member.id}-${modalGroupId}`"
              >
                <participant-avatar
                  :participant="member"
                  class="st-member-groups__member-avatar"
                />
              </div>
              <div
                :key="`name-${member.id}-${modalGroupId}`"
                class="st-member-groups__name-email"
              >
                <div class="st-member-groups__name">
                  {{ member.firstName }} {{ member.lastName }}
                </div>
                <div class="st-member-groups__email">
                  {{ member.email }}
                </div>
              </div>
              <div
                :key="`departments-${member.id}-${modalGroupId}`"
              >
                <st-data-table-dropdown-info
                  v-if="member.departments.length > 0"
                  class="st-member-groups__department-dropdown"
                  :disabled="true"
                  :display-text="departmentsDisplay(member.departments)"
                  :open-title="departmentsOpenDisplay(member.departments)"
                  :info-rows="member.departments.map((d) => d.name)"
                  :show-count="true"
                />
              </div>
              <div
                :key="`kind-${member.id}-${modalGroupId}`"
                class="st-member-groups__member-kind"
              >
                <div>{{ getMemberKind(member.kind) }}</div>
              </div>
              <div
                :key="`remove-${member.id}-${modalGroupId}`"
                class="st-member-groups__remove-container"
              >
                <div
                  class="st-member-groups__remove"
                  @click="openRemoveModal(modalGroupIndex, [member.id], member.fullName)"
                >
                  <div class="st-member-groups__remove-text">
                    Remove
                  </div>
                  <icon-wrapper icon-name="trash" />
                </div>
              </div>
            </template>
          </template>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { startCase } from 'lodash'
import { mapState } from 'vuex'
import { caseInsensitiveSortBy, doubleCaseInsensitiveSortBy, recentDateLimit } from '../../../utilities/data_utilities'
import { isDesktop } from '../../../mixins'
import ParticipantAvatar from '../insights/participant-avatar'

import {
  IconWrapper,
  StAccordion,
  StButton,
  StCard,
  StDataTable,
  StDataTableCheckboxRow,
  StDataTableDropdownInfo,
  StDropdownButton,
  StDropdownMenu,
  StEmailInput,
  StInput,
  StModal,
  StOverflowMenu,
  StOverflowMenuItem,
  StTooltip
} from '../../common'
import refineUrlFilter from '../../../mixins/refineUrlFilter'

const DROPDOWN_OPTIONS = [{
  caption: 'Create Group',
  iconName: 'add-list'
}
// , {
//   caption: 'Import CSV',
//   iconName: 'import-csv'
// }
]

export function sortMembersAZ (arr) {
  return caseInsensitiveSortBy(arr, 'lastName')
}

export function sortMembersZA (arr) {
  return caseInsensitiveSortBy(arr, 'lastName', true)
}

export default {
  components: {
    IconWrapper,
    StAccordion,
    StButton,
    StCard,
    StDropdownMenu,
    StDataTable,
    StDataTableCheckboxRow,
    StDataTableDropdownInfo,
    StDropdownButton,
    StEmailInput,
    StInput,
    StModal,
    StOverflowMenu,
    StOverflowMenuItem,
    StTooltip,
    'participant-avatar': ParticipantAvatar
  },
  mixins: [isDesktop, refineUrlFilter],
  props: {
    accountMembers: {
      type: Array,
      required: true
    },
    initialGroups: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      activeColumn: { name: 'Name', ascending: true },
      addMemberFilterOption: 'All Members',
      addMembersModal: false,
      allMembers: this.accountMembers.map(member => {
        return Object.assign({ selected: false }, member)
      }),
      createModal: false,
      csvModal: false,
      deleteGroupModal: false,
      dropdownOptions: DROPDOWN_OPTIONS,
      editGroupModal: false,
      filterOption: 'All Members',
      groups: this.initialGroups,
      modalGroupIndex: -1,
      modalUserIds: [],
      modalUserName: '',
      memberLookup: null,
      newEmails: [],
      newGroupName: '',
      newMemberEmail: '',
      removeUserModal: false,
      viewMembersModal: false
    }
  },
  computed: {
    ...mapState(['currentUser', 'navOpen']),
    selectedMembers () {
      return this.allMembers.filter(member => member.selected)
    },
    selectedMembersIds () {
      return this.selectedMembers.map(member => member.id)
    },
    groupIds () {
      return this.groups.map(group => group.id)
    },
    modalGroupName: {
      get: function () {
        return this.groups[this.modalGroupIndex].name
      },
      set: function (newName) {
        this.groups[this.modalGroupIndex].name = newName
      }
    },
    modalGroupId () {
      return this.groupIds[this.modalGroupIndex]
    },
    modalGroupUsers () {
      return this.groups[this.modalGroupIndex].users
    },
    filteredUserGroups () {
      if (this.memberLookup) {
        return this.groups.map(group => {
          return group.users.filter(user => {
            const regex = new RegExp(this.memberLookup, 'gi')
            return user.fullName.match(regex) || user.email.match(regex)
          })
        })
      } else {
        return this.groups.map(group => group.users)
      }
    },
    sortedUserGroups () {
      let users
      switch (this.filterOption) {
        case 'All Members':
          users = this.filteredUserGroups
          break
        case 'A - Z':
          users = this.filteredUserGroups.map(group => {
            return sortMembersAZ(group)
          })
          break
        case 'Z - A':
          users = this.filteredUserGroups.map(group => {
            return sortMembersAZ(group).slice().reverse()
          })
          break
        case 'Recently Added':
          users = this.filteredUserGroups.map(group => {
            return group.filter(user => {
              return recentDateLimit(user.createdAt, 15)
            })
          })
          break
      }
      return users
    },
    mappedMembers () {
      return this.allMembers.map(member => {
        if (member.inactive) {
          member.status = 'deactivated'
          if (!member.firstName) { member.firstName = '' }
          if (!member.lastName) { member.lastName = '' }
        } else if (member.invitationCreatedAt && !member.invitationAcceptedAt) {
          member.firstName = 'Pending'
          member.lastName = 'Invitation'
          member.fullName = 'Pending Invitation'
          member.pending = true
          member.status = 'pending'
        } else {
          member.status = 'active'
        }
        return member
      })
    },
    allMembersFiltered () {
      let users
      switch (this.addMemberFilterOption) {
        case 'All Members':
          users = this.mappedMembers
          break
        case 'Recently Added':
          users = this.mappedMembers.filter(user => {
            return recentDateLimit(user.createdAt, 15)
          })
          break
      }
      return users
    },
    sortedMembersByActiveColumn () {
      let recipients = []
      switch (this.activeColumn.name) {
        case 'Name':
          return this.activeColumn.ascending
            ? doubleCaseInsensitiveSortBy(this.allMembersFiltered, 'lastName', 'firstName')
            : doubleCaseInsensitiveSortBy(this.allMembersFiltered, 'lastName', 'firstName', true)
        case 'Email':
          return this.sortColumnProperty('email')
      }
      return recipients
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'member-groups')
  },
  created () {
    this.defaultFilter = 'All Members'
    this.filterOption = this.filterParam
    this.memberLookup = this.searchParam
  },
  methods: {
    openRemoveModal (modalIdx, userIds, userName) {
      this.viewMembersModal = false
      this.removeUserModal = true
      this.modalUserName = userName
      this.modalGroupIndex = modalIdx
      this.modalUserIds = userIds
    },
    closeRemoveUserModal () {
      this.removeUserModal = false
      this.modalGroupIndex = -1
      this.modalUserIds = []
      this.modalUserName = ''
    },
    removeMember () {
      let url = `/user_groups/${this.modalGroupId}/users/`
      this.$axios.request({
        method: 'delete',
        url: url,
        data: { ids: this.modalUserIds, id: this.modalGroupId }
      })
        .then(res => {
          this.groups[this.modalGroupIndex].users = res.data.users
          this.$nextTick(() => { this.closeRemoveUserModal() })
        })
        .catch(err => { console.error(err) })
    },
    inviteNewMembers () {
      let url = `/users/invite`
      this.$axios.request({
        method: 'post',
        url: url,
        data: {
          emails: this.newEmails.map(member => member.email),
          user_group_ids: [this.modalGroupId],
          kind: 'member'
        }
      }).then(res => {
        this.groups[this.modalGroupIndex].users = this.groups[this.modalGroupIndex].users.concat(res.data)
        this.$nextTick(() => {
          this.modalGroupIndex = -1
          this.addMembersModal = false
          this.toggleAllChecks(false)
          this.newEmails = []
        })
      })
        .catch(err => { console.error(err) })
    },
    addNewEmail (newMember) {
      newMember.id ? this.updateCheck(newMember.id, true) : this.newEmails.push(newMember)
    },
    removeNewEmail (email) {
      let idx = this.newEmails.map(member => member.email).indexOf(email)
      this.newEmails.splice(idx, 1)
    },
    addMembers () {
      let url = `/user_groups/${this.modalGroupId}/users/`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { id: this.modalGroupId, ids: this.selectedMembersIds }
      })
        .then(res => {
          this.groups[this.modalGroupIndex].users = res.data.users
          this.modalUserIds = []
          this.toggleAllChecks(false)
          this.inviteNewMembers()
        })
        .catch(err => { console.error(err) })
    },
    addMemberModal (groupIdx) {
      this.modalGroupIndex = groupIdx
      this.$nextTick(() => {
        this.addMembersModal = true
      })
    },
    viewMemberModal (groupIdx) {
      this.modalGroupIndex = groupIdx
      this.$nextTick(() => { this.viewMembersModal = true })
    },
    openEditModal (groupIdx) {
      this.editGroupModal = true
      this.modalGroupIndex = groupIdx
      setTimeout(() => {
        this.$refs.editNameInput.focus()
      }, 10)
    },
    openDeleteModal (groupIdx) {
      this.deleteGroupModal = true
      this.modalGroupIndex = groupIdx
    },
    deleteGroup () {
      let url = `/user_groups/${this.modalGroupId}`
      this.$axios.request({
        method: 'delete',
        url: url
      })
        .then(res => {
          this.groups.splice(this.modalGroupIndex, 1)
          this.deleteGroupModal = false
          this.$nextTick(() => {
            this.modalGroupIndex = -1
          })
        })
        .catch(err => { console.error(err) })
    },
    createNewGroup () {
      let url = `/user_groups/`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { name: this.newGroupName }
      })
        .then(res => {
          this.groups.push(res.data)
          this.newGroupName = ''
          this.createModal = false
        })
        .catch(err => { console.error(err) })
    },
    setFilterOption (option) {
      this.updateUrlParams('filter', option)
      this.filterOption = option
    },
    setAddMemberFilterOption (option) {
      this.addMemberFilterOption = option
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.allMembersFiltered, property)
        : caseInsensitiveSortBy(this.allMembersFiltered, property, true)
    },
    editGroupName () {
      let url = `/user_groups/${this.modalGroupId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { name: this.modalGroupName }
      })
        .then(res => {
          this.$nextTick(() => { this.editGroupModal = false })
        })
        .catch(err => { console.error(err) })
    },
    departmentsDisplay (departments) {
      return departments[0].name
    },
    departmentsOpenDisplay (departments) {
      let text = `${departments.length} Department`
      if (departments.length > 1) { text += 's' }
      return text
    },
    getMemberKind (kind) {
      return startCase(kind)
    },
    toggleAllChecks (bool) {
      this.allMembers.forEach(member => { member.selected = bool })
      this.$refs.emailInput.toggleAllExisting(bool)
    },
    updateCheck (id, val) {
      let member = this.allMembers.find(member => { return member.id === id })
      member.selected = val
      if (val) {
        this.$refs.emailInput.addEmailFromDataTable(member.email)
      } else {
        let idx = this.$refs.emailInput.newEmailList.map(member => member.id).indexOf(id)
        this.$refs.emailInput.newEmailList.splice(idx, 1)
      }
    },
    uncheckEmail (member) {
      let tableMember = this.allMembers.find(user => { return user.id === member.id })
      tableMember.selected = false
    },
    handleDropdownOption (option) {
      switch (option.caption) {
        case 'Create Group':
          this.createModal = true
          break
        case 'Import CSV':
          this.csvModal = true
          break
      }
    },
    uploadCsv () {
      console.log('uploadCsv')
      // send ajax request to upload members
      // the close modal after success
      this.csvModal = false
    },
    expandedText (memberCount) {
      return memberCount === 1 ? '1 member' : `${memberCount} members`
    },
    updateActiveColumn (payload) {
      this.activeColumn = payload
    }
  }
}
</script>
