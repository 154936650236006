<template>
  <div
    :class="{
      'st-request-receipt__contents-wrapper': true,
      'st-request-receipt__contents-wrapper--remove-receipt': removeReceipt
    }"
  >
    <div class="st-request-receipt__canvas-wrapper">
      <div class="st-request-receipt__name-and-edit">
        <p class="st-request-receipt__request-name">
          {{ requestNameText }}
        </p>
        <div
          class="st-request-receipt__edit-name-icon-wrapper"
          tabindex="0"
          @click="editNameModal = true"
          @keydown.enter="editNameModal = true"
        >
          <icon-wrapper
            class="st-request-receipt__edit-name-icon"
            icon-name="edit"
          />
        </div>
      </div>
      <div class="st-request-receipt__number-of-recipients-and-cost">
        <p>
          {{ numberOfRecipientsText }}
        </p>
        <p>
          ${{ numberOfRecipientsCost }}
        </p>
      </div>
      <p class="st-request-receipt__demographics-title">
        {{ activeDemographicsText }}
      </p>
      <div
        :class="[{'st-request-receipt__demo-scroll-area': true}, scrollAreaClass]"
      >
        <div class="st-request-receipt__demos-wrapper">
          <div
            v-for="(demo, index) in demographics"
            :key="demo.component"
            :class="{ 'st-request-receipt__active-demographic': demo.display }"
          >
            <template v-if="demo.display">
              <div class="st-request-receipt__icon-and-caption">
                <icon-wrapper
                  :icon-name="ICON_NAMES[index]"
                  :neutral="true"
                />
                <p class="st-request-receipt__caption">
                  {{ demo.component }}
                </p>
              </div>
              <div
                v-if="withDelete"
                class="st-request-receipt__trash-icon"
                tabindex="0"
                @click="demo.reset"
                @keydown.enter="demo.reset"
              >
                <icon-wrapper
                  :actionable="false"
                  icon-name="trash"
                />
              </div>
              <p
                :class="{
                  'st-request-receipt__selected-demo': true,
                  'st-request-receipt__selected-demo--no-delete': !withDelete
                }"
              >
                {{ demo.display }}
              </p>
              <p class="st-request-receipt__demo-cost">
                ${{ PRICES.demographic * numberOfRecipients }}
              </p>
            </template>
          </div>
        </div>
      </div>
      <div class="st-request-receipt__total-wrapper">
        <p class="st-request-receipt__total-cost-title">
          Total Cost
        </p>
        <p class="st-request-receipt__total-cost">
          ${{ totalCost || 0 }}
        </p>
        <p class="st-request-receipt__total-cost-unit">
          USD
        </p>
      </div>
    </div>
    <st-modal v-model="editNameModal">
      <template slot="modalTitle">
        Edit Order Name
      </template>
      <template slot="modalActions">
        <st-input
          v-model="requestName"
          class="st-survey-hub__edit-name-input"
          :show-label="true"
          :autofocus="true"
          label="Order Name"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          icon-name="save"
          @click="editNameModal = false"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { IconWrapper, StButton, StInput, StModal } from '../../../common'
import demographics from './demographics'
import { ICON_NAMES } from './iconConstants'

export default {
  components: {
    IconWrapper,
    StButton,
    StInput,
    StModal
  },
  mixins: [demographics],
  props: {
    removeReceipt: {
      type: Boolean,
      required: false,
      default: false
    },
    scrollAreaClass: {
      type: String,
      required: false,
      default: ''
    },
    withDelete: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      editNameModal: false,
      ICON_NAMES
    }
  },
  computed: {
    ...mapState('requestAudience', ['ageHigh', 'ageLow', 'financialHigh', 'financialLow', 'locationCity', 'locationState', 'name', 'numberOfRecipients']),
    ...mapGetters('requestAudience', ['GENDERS', 'isAgePopulated', 'isFinancialPopulated', 'isLocationPopulated', 'numberOfRecipientsCost', 'PRICES', 'totalCost']),
    activeDemographicsText () {
      return this.demographics.filter(demo => { return demo.display }).length > 0
        ? 'Demographics'
        : '[Select Demographics]'
    },
    ageFormatDisplay () {
      return this.isAgePopulated ? `${this.ageLow} - ${this.ageHighDisplay}` : ''
    },
    ageHighDisplay () {
      return this.ageHigh === 99 ? `${this.ageHigh}+` : `${this.ageHigh}`
    },
    financialFormatDisplay () {
      if (!this.isFinancialPopulated) { return '' }

      let max = this.financialHigh === 200 ? '200K+' : `${this.financialHigh}K`
      return `${this.financialLow}K - ${max}`
    },
    locationFormatDisplay () {
      return this.isLocationPopulated ? `${this.locationCity}, ${this.locationState}` : ''
    },
    numberOfRecipientsText () {
      return this.numberOfRecipients ? `${this.numberOfRecipients} Recipients` : '[Select a Number of Recipients]'
    },
    requestNameText () {
      return this.name ? this.name : '[Order Name]'
    },
    requestName: {
      get () {
        return this.name
      },
      set (newName) {
        this.updateRequestName(newName)
      }
    }
  },
  methods: {
    ...mapActions('requestAudience', ['updateRequestName'])
    // resetValue (demographic) {
    //   let value
    //   switch (demographic) {
    //     case 'locationCity':
    //       value = ''
    //       break
    //     case 'locationState':
    //       value = ''
    //       break
    //     case 'genderSelectedIndexes':
    //       value = new Array(this.GENDERS.length).fill(false)
    //       break
    //     default:
    //       value = null
    //   }
    //   return value
    // }
  }
}
</script>
