<template>
  <div class="icon_calendar">
    <div class="icon_calendar__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(0 .5)">
          <g>
            <path
              d="M0 2V0h16v14H0V2zm1 0v11h14V1H1z"
              transform="translate(0 1.5)"
            />
            <path
              d="M.578.5a.5.5 0 01-.5-.5.5.5 0 01.5-.5H15a.5.5 0 01.5.5.5.5 0 01-.5.5z"
              transform="translate(0 5.5)"
            />
            <path
              d="M-.5-.5h1v4h-1z"
              transform="translate(12.5)"
            />
            <path
              d="M-.5-.5h1v4h-1z"
              transform="translate(3.5)"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
