<template>
  <div class="icon_modify">
    <div class="icon_modify__svg">
      <svg
        viewBox="0 0 16.005 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M144.383 203.124c5.055-1.3 9.04 3.888 7.172 8.594-.1.26-.218.037 3.46 3.231a2.343 2.343 0 01.2 3.251 2.156 2.156 0 01-3.031.185c-3.978-3.477-3.678-3.3-3.906-3.212-5.277 2.05-9.506-2.935-8.3-7.459.119-.442.427-.536.668-.332.048.04 3.352 3.487 3.4 3.532l3.412-3.568-3.28-3.414c-.331-.342-.247-.692.205-.808zm-.34 9.236l-3.259-3.4a5.271 5.271 0 005.085 5.689c1.574-.01 2.092-.658 2.825-.469.386.1.2 0 4.147 3.449a1.154 1.154 0 00.769.287 1.269 1.269 0 00.747-2.218c-3.721-3.231-3.528-3.019-3.684-3.306-.454-.833.264-1.176.331-2.744a5.321 5.321 0 00-1.843-4.491 4.665 4.665 0 00-3.6-1.231l3.279 3.414z"
            transform="translate(-139.771 -202.922)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
