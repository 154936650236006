let fullWidthQuestions = ['opinion_metric', 'concept_ranking']

export const PHASES = Object.freeze({
  'instructions': 'instructions',
  'countdown': 'countdown',
  'matterportIndicator': 'matterportIndicator',
  'tour': 'tour',
  'topicList': 'topicList',
  'exit': 'exit',
  'replay': 'replay',
  'demoSteps': 'demoSteps'
})

export const SURVEY_TYPES = Object.freeze({
  'demoPreview': 'demoPreview',
  'participantSurvey': 'participantSurvey',
  'specificationsPreview': 'specificationsPreview',
  'surveyPreview': 'surveyPreview'
})

const MS_TO_SHOW_TOPIC = 4000
const MS_TO_SHOW_INDICATOR = 40000

export default {
  namespaced: true,
  state: {
    activeQuestion: {},
    currentPano: '',
    hideMatterportIndicator: null,
    indicatorTimeout: false,
    instructionsPage: 1,
    isFullTopic: false,
    isTopicClosed: false,
    mobileDemoView: true,
    participantEmail: '',
    questionPhase: '',
    surveyType: '',
    showDemoBlocker: true,
    viewedTopics: {},
    participantSubmissionToken: ""
  },
  getters: {
    PHASES: () => PHASES,
    SURVEY_TYPES: () => SURVEY_TYPES,
    currentPrompt: (state, getters) => {
      return getters.promptsByPanoId[state.currentPano] || ''
    },
    currentTopic: (state, getters) => {
      return getters.topicsByPanoId[state.currentPano] || ''
    },
    fullWidth: (state) => {
      if (!state.activeQuestion.hasOwnProperty('options')) {
        return false
      } else {
        return state.activeQuestion.options.length > 1 && fullWidthQuestions.includes(state.activeQuestion.questionType)
      }
    },
    hasViewedTopic: (state, getters) => {
      return getters.currentTopic in state.viewedTopics
    },
    isDemoPreview: (state) => {
      return state.surveyType === SURVEY_TYPES['demoPreview']
    },
    isMatterportIndicatorOpen: (state) => {
      return state.questionPhase === PHASES['matterportIndicator']
    },
    isParticipantSurvey: (state) => {
      return state.surveyType === SURVEY_TYPES['participantSurvey']
    },
    isSpecificationsPreview: (state) => {
      return state.surveyType === SURVEY_TYPES['specificationsPreview']
    },
    isSurveyPreview: (state) => {
      return state.surveyType === SURVEY_TYPES['surveyPreview']
    },
    isTopicListOpen: (state) => {
      return state.questionPhase === PHASES['topicList']
    },
    promptsByPanoId: (state) => {
      if (!state.activeQuestion.panoramas) { return {} }
      let map = {}
      state.activeQuestion.panoramas
        .filter(pano => pano.prompt)
        .filter(pano => !pano.topic)
        .map(pano => { map[pano.identifier] = pano.prompt })
      return map
    },
    topics: (state) => {
      if (!state.activeQuestion.hasOwnProperty('panoramas')) { return [] }

      let topicSet = state.activeQuestion.panoramas
        .filter(pano => pano.prompt)
        .filter(pano => pano.topic)
        .map(pano => pano.prompt)
        .reduce((uniqueTopics, topic) => uniqueTopics.add(topic), new Set())
      return Array.from(topicSet)
    },
    topicsByPanoId: (state) => {
      if (!state.activeQuestion.panoramas) { return {} }
      let map = {}
      state.activeQuestion.panoramas
        .filter(pano => pano.prompt)
        .filter(pano => pano.topic)
        .map(pano => { map[pano.identifier] = pano.prompt })
      return map
    },
    participantSubmissionToken: (state) => {
      return state.participantSubmissionToken;
    }
  },
  mutations: {
    _changePano: (state, panoId) => {
      state.currentPano = panoId
      state.isTopicClosed = false
      if (state.questionPhase === PHASES.topicList) {
        state.questionPhase = PHASES.tour
      }
    },
    _addTopicToViewed: (state, topicText) => {
      state.viewedTopics = { ...state.viewedTopics, [topicText]: true }
    },
    closeTopic: (state) => {
      state.isTopicClosed = true
    },
    incrementInstructionsPage: (state) => {
      state.instructionsPage++
    },
    openTopic: (state) => {
      state.isTopicClosed = false
    },
    setActiveQuestion: (state, payload) => {
      state.activeQuestion = payload
    },
    setActivePhase: (state, payload) => {
      state.questionPhase = payload
    },
    setIndicatorTimeout: (state, payload) => {
      state.indicatorTimeout = payload
    },
    setInstructionsPage: (state, payload) => {
      state.instructionsPage = payload
    },
    setMatterportIndicator: (state, payload) => {
      state.hideMatterportIndicator = payload
    },
    setParticipantEmail: (state, payload) => {
      state.participantEmail = payload
    },
    setSurveyType: (state, payload) => {
      state.surveyType = payload
    },
    setParticipantSubmissionToken: (state, { token }) => {
      state.participantSubmissionToken = token;
    },
    updateActiveQuestion: (state, { key, value }) => {
      state.activeQuestion = { ...state.activeQuestion, [key]: value }
    },
    updateMobileDemoView: (state, payload) => {
      state.mobileDemoView = payload
    },
    updatePanos: (state, payload) => {
      state.activeQuestion = { ...state.activeQuestion, panoramas: payload }
    },
    updateFullTopic: (state, payload) => {
      state.isFullTopic = payload
    },
    updateShowDemoBlocker: (state, payload) => {
      state.showDemoBlocker = payload
    }
  },
  actions: {
    changePano: ({ commit, getters, dispatch, state }, newPanoId) => {
      // "old" topic - we haven't updated it yet. This is the one to add as viewed
      if (getters.currentTopic) { commit('_addTopicToViewed', getters.currentTopic) }

      commit('_changePano', newPanoId)
      if ([PHASES.tour, PHASES.topicList].includes(state.questionPhase)) {
        if (!getters.hasViewedTopic && getters.currentTopic) {
          dispatch('topicViewedTimeout')
        }
      }
    },
    playTopicAudio: ({ state, commit, dispatch, getters }) => {
      const pano = state.activeQuestion.panoramas.find(pano => pano.identifier === state.currentPano)
      if (pano && pano.topicAudioUrl) {
        const player = new Audio(pano.topicAudioUrl)
        player.addEventListener('ended', () => {
          commit('_addTopicToViewed', getters.currentTopic)
        })
        player.play()
      } else {
        dispatch('topicViewedTimeout')
      }
    },
    resetIndicatorTimeout: ({ state, dispatch }) => {
      clearTimeout(state.indicatorTimeout)
      dispatch('setIndicatorTimeout')
    },
    setIndicatorTimeout: ({ state, getters, commit, dispatch }) => {
      let interval = setTimeout(() => {
        if (state.questionPhase === getters.PHASES.tour && !state.hideMatterportIndicator && getters.isParticipantSurvey) {
          commit('setActivePhase', getters.PHASES.matterportIndicator)
          dispatch('resetIndicatorTimeout')
          clearTimeout(state.indicatorTimeout)
        }
      }, MS_TO_SHOW_INDICATOR)

      commit('setIndicatorTimeout', interval)
    },
    topicViewedTimeout: ({ state, getters, commit, dispatch }) => {
      commit('updateFullTopic', true)
      const currentTopic = getters.currentTopic
      clearTimeout(state.indicatorTimeout)
      window.setTimeout(() => {
        commit('_addTopicToViewed', currentTopic)
        commit('updateFullTopic', false)
        if (!state.hideMatterportIndicator) { dispatch('setIndicatorTimeout') }
      }, MS_TO_SHOW_TOPIC)
    }
  }
}
