<template>
  <div class="icon_audiencelist">
    <div class="icon_audiencelist__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 2a2 2 0 11-2-2 2 2 0 012 2zM3 2a1 1 0 10-1 1 1 1 0 001-1z" />
        <path
          d="M4 2a2 2 0 11-2-2 2 2 0 012 2zM3 2a1 1 0 10-1 1 1 1 0 001-1z"
          transform="translate(0 6)"
        />
        <path
          d="M4 2a2 2 0 11-2-2 2 2 0 012 2zM3 2a1 1 0 10-1 1 1 1 0 001-1z"
          transform="translate(0 12)"
        />
        <path
          d="M0 0h10"
          transform="translate(6 2)"
        />
        <path
          d="M0 0h10"
          transform="translate(6 8)"
        />
        <path
          d="M0 0h10"
          transform="translate(6 14)"
        />
        <path
          d="M0-.5h10v1H0z"
          transform="translate(6 2)"
        />
        <path
          d="M0-.5h10v1H0z"
          transform="translate(6 8)"
        />
        <path
          d="M0-.5h10v1H0z"
          transform="translate(6 14)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
