<template>
  <div class="st-virtual-tour__inner-wrapper">
    <div
      v-if="showDemoBlocker"
      class="st-virtual-tour__demo-blocker"
    />
    <div
      v-else
      class="st-matterport-indicator__pointer-and-circle st-virtual-tour__demo-indicator"
    >
      <icon-wrapper
        class="st-matterport-indicator__pointer"
        icon-name="pointer"
        :invert="true"
      />
      <div class="st-matterport-indicator__circle" />
    </div>
    <icon-wrapper
      class="st-virtual-tour__spacetrics-icon"
      icon-name="spacetrics"
      :invert="true"
    />
    <microphone
      class="st-virtual-tour__demo-microphone"
      :is-demo-preview="true"
      :skip-create-audio-stream="true"
    />
    <div
      v-if="topics.length > 0"
      :class="{
        'st-virtual-tour__question-list-button st-virtual-tour__question-list-button--disabled': true,
        'st-virtual-tour__question-list-button--phone-demo-preview ': mobileDemoView,
      }"
    >
      <icon-wrapper
        :class="{
          'st-virtual-tour__question-list-icon--phone-demo-preview ': mobileDemoView,
        }"
        icon-name="opinion-metric"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { IconWrapper } from '../../../../common'
import Microphone from './microphone'

export default {
  components: {
    IconWrapper,
    Microphone
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('participants', ['mobileDemoView', 'showDemoBlocker']),
    ...mapGetters('participants', ['topics'])
  },
  methods: {
  }
}
</script>
