<template>
  <div class="icon_custom-label-disable">
    <div class="icon_custom-label-disable__svg">
      <svg
        viewBox="0 0 16 16.004"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-19540.32 1506.441l-8.246 7.749 8.256 7.646.748-.7-7.533-6.949 7.533-7.045z"
          transform="rotate(90 -9016.722 10538.844)"
        />
        <path
          d="M0 0h7v1H0z"
          transform="translate(9)"
        />
        <path
          d="M0 0h1v7H0z"
          transform="translate(0 9.003)"
        />
        <path
          d="M0 0h1v16H0z"
          transform="translate(14.99)"
        />
        <path d="M8 4a4 4 0 11-4-4 4 4 0 014 4zm-.889 0A3.111 3.111 0 104 7.111 3.111 3.111 0 007.111 4z" />
        <path
          d="M.889 0v6.652H0V0z"
          transform="rotate(45 1.409 7.956)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
