<template>
  <div class="icon_pointer">
    <div class="icon_pointer__svg">
      <svg
        viewBox="0 0 16 15.999"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M12.951.019a1.9 1.9 0 011.762.714c.777 1.087-.311 2.634-.408 2.791-.371.637-.654 1.058-2.061 3.7 1.857-.029 3.09 2.336.088 3.922a19.547 19.547 0 01-4.484 1.964L6.42 14.99 0 9.954.885 8.76A3.462 3.462 0 011.1 6.742c.109-.245-.055.059 2.756-4.811a2 2 0 013.08-.274 2.1 2.1 0 012.375.5 1.906 1.906 0 011.5.166l.365-.675c.109-.21.624-1.458 1.775-1.629zM3.678 2.235v-.006a.008.008 0 010 .006zm8.2-.016a43.731 43.731 0 00-1.258 2.437c.139-.3.553-1.383-.4-1.681-1-.314-1.52.788-1.617 1.015a2.947 2.947 0 00.182-.49.892.892 0 00-.691-1.13c-1.125-.314-1.674 1.075-1.719 1.2a1.4 1.4 0 00.131-.847.958.958 0 00-.967-.823 1.171 1.171 0 00-1.039.673l-2.43 4.22a2.648 2.648 0 00-.221 2.14l-.644.867 5.049 3.96 1.107-1.46c.9.095 3.434-1.32 4.393-1.815.7-.344 1.867-1.109 1.379-1.95s-1.666-.275-1.666-.275l-1.006.451s2.051-3.95 2.975-5.448c.107-.226 1.271-1.788.242-2.3C12.521.4 12.043 2.017 11.879 2.22zM6.373 3.577v-.009zm2.217.454l.016-.04a.235.235 0 00-.016.04zm2.01.669a.2.2 0 00.021-.044zm-.018.036L10.6 4.7z"
            transform="matrix(.998 .07 -.07 .998 1.046 0)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
