<script>
export default {
  data () {
    return {
      defaultFilter: ''
    }
  },
  computed: {
    filterParam () {
      return this.urlParams().get('filter') || this.defaultFilter
    },
    searchParam () {
      return this.urlParams().get('search') || ''
    },
    pageParam () {
      return this.urlParams().get('page')
    },
    itemsParam () {
      return this.urlParams().get('items')
    }
  },
  methods: {
    urlParams () {
      return new URLSearchParams(window.location.search)
    },
    updateUrlParams (name, value) {
      const params = this.urlParams()
      params.set(name, value)
      window.history.replaceState(null, 'null', `?${params}`)
    },
    updateSearchParam (ev) {
      this.updateUrlParams('search', ev.target.value)
    }

  }
}
</script>
