<template>
  <div
    :class="{
      'st-radio-button-group': true,
      'st-radio-button-group--multi-row': multiRow
    }"
  >
    <label
      v-for="(option, index) in options"
      :key="index"
      :class="[{
        'st-radio-button': true,
        'st-radio-button--hover': index === hoverIdx,
        'st-radio-button--active': index === activeIdx,
        'st-radio-button__remove-focus': removeFocus,
        'st-radio-button__spacing': index !== 0 && !multiRow,
        'bx--col-sm-12': !isDesktop,
        'st-radio-button--disabled': index === hoverIdx && disabled,
        'st-radio-button--multi-row': multiRow
      }, contentClass, contentVariantClasses]"
      tabindex="0"
      @focus="hoverIdx = index"
      @focusout="hoverIdx = null, removeFocus = false"
      @mousedown="removeFocus = true"
      @mouseenter="hoverIdx = index"
      @mouseleave="hoverIdx = null"
    >
      <slot
        name="preceding-input"
        :option="option"
      />
      <div
        :class="{
          'st-radio-button__input-and-indicator': true,
          'st-radio-button__input-and-indicator--hover': index === hoverIdx && !hideUntilActive,
          'st-radio-button__input-and-indicator--active': index === activeIdx,
          'st-radio-button__input-and-indicator--hide-until-active': hideUntilActive
        }"
      >
        <input
          :id="stUid + '-' + index"
          v-model="innerValue"
          :disabled="disabled"
          :value="option.value"
          :name="name"
          type="radio"
          class="st-radio-button__input"
          @change="changeHandler"
        >
        <label
          v-if="index === activeIdx"
          :class="[{ 'st-radio-button-indicator': true }, indicatorClass]"
          :for="stUid + '-' + index"
        >
          <icon-wrapper
            :class="[{ 'st-radio-button-indicator__icon': true }, indicatorIconClass]"
            icon-name="check"
            :invert="true"
          />
        </label>
      </div>
      <label
        v-if="!hideCaption"
        :class="{
          'st-radio-button__icon-and-caption': true,
          'st-radio-button__icon-and-caption--hover': index === hoverIdx,
          'st-radio-button__icon-and-caption--active': index === activeIdx,
          'st-radio-button__icon-and-caption--disabled': index === hoverIdx && disabled,
        }"
        :for="stUid + '-' + index"
      >
        <div
          v-if="option.iconName"
          class="st-radio-button__icon"
        >
          <icon-wrapper
            :icon-name="option.iconName"
            :neutral="index !== hoverIdx && index !== activeIdx"
          />
        </div>
        {{ option.caption }}
      </label>
    </label>
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'
import { uid, isDesktop } from '../../mixins'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [uid, isDesktop],
  model: {
    prop: 'activeValue'
  },
  props: {
    /** Current selected option from radio button group. */
    // eslint-disable-next-line vue/require-prop-types
    activeValue: {
      required: false,
      default: ''
    },
    /** Used to change the styling of the main section of the radio button. */
    contentClass: {
      type: String,
      required: false,
      default: ''
    },
    /** Blocks the radio button from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Blocks the caption of the radio button from being used (in cases where slots are used). */
    hideCaption: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Prevent's the radio button input untill it's active */
    hideUntilActive: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used to change the styling of the indicator wrapper for the radio-button. */
    indicatorClass: {
      type: String,
      required: false,
      default: ''
    },
    /** Used to change the styling of the indicator icon for the radio-button. */
    indicatorIconClass: {
      type: String,
      required: false,
      default: ''
    },
    /** Used to create a radio-button group that has buttons on multiple rows */
    multiRow: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Value associated with st-radio-button input for forms. */
    name: {
      type: String,
      required: false,
      default: ''
    },
    /** All radio buttons (iconName, caption, value). */
    options: {
      type: Array,
      required: true
    },
    /** Can be used to change the kind of radio button (ex: fullBorder) */
    variant: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      hoverIdx: null,
      removeFocus: false
    }
  },
  computed: {
    activeIdx () {
      return this.options.findIndex(option => option.value === this.activeValue)
    },
    innerValue: {
      get () {
        return this.activeValue
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    contentVariantClasses () {
      return {
        'st-radio-button--full-border': this.variant.includes('fullBorder'),
        'st-radio-button--lighter-grey-border': this.variant.includes('lighterGreyBorder')
      }
    }
  },
  methods:{
    changeHandler(){
      this.$emit("change");
    }
  }
}
</script>
