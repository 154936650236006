<template>
  <div class="bx--col-xs-12">
    <st-toast-notification
      v-if="showToast"
      kind="info"
      title="Info"
      :sub-title="toastMessage"
      @click="showToast = false"
    />
    <div class="mobile-header">
      <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div
        v-if="!survey.published"
        class="mobile-header__button"
      >
        <st-button
          :caption="'Save & Continue'"
          :disabled="(selectedSegments.length===0 && !isEditingDisabled) || isEditingDisabled"
          @click="saveAndToHub"
        />
      </div>
    </div>
    <div>
      <div>
        <div class="space-between">
          <div>
            <div class="st-edit-survey__back-and-save">
              <st-hyperlink
                :back-link="true"
                text="Back"
                @click.native="saveAndToHub('backLink')"
              />
              <div class="st-edit-survey__mobile-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                />
                <st-hyperlink
                  v-if="!survey.published"
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
            </div>
            <div class="st-edit-survey__header-and-icon">
              <icon-wrapper
                class="st-edit-survey__header-icon"
                icon-name="segment"
              />
              <h1 class="st-edit-survey__page-header">
                Segments
              </h1>
            </div>
            <p
              v-if="isSpacetricsAccount"
              class="st-edit-segments__selected-text st-edit-segments__edit-default-text"
            >
              You are logged into the Spacetrics Account, your changes will affect Default Segments for the rest of the app.
            </p>
            <div
              v-else
              class="st-edit-segments__selected-text bx--col-sm-12"
            >
              You have {{ selectedSegments.length }} segments selected. Selecting segments will help you better understand your audience.
            </div>
          </div>
          <div>
            <div class="st-edit-survey__save-link-and-button">
              <div class="st-edit-survey__inline-spiner-and-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                  class="st-edit-survey__desktop-inline-spinner"
                />
                <st-hyperlink
                  v-if="!survey.published"
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
              <st-button
                v-if="!survey.published"
                :caption="'Save & Continue'"
                :disabled="(selectedSegments.length===0 && !isEditingDisabled) || isEditingDisabled"
                @click="saveAndToHub"
              />
            </div>
          </div>
        </div>
        <template v-if="!isSpacetricsAccount">
          <div class="st-edit-segments__section-title bx--col-sm-12">
            Default Segments
          </div>
          <div class="st-edit-segments__segments bx--row">
            <!-- <div
              v-for="(column, n) in defaultSegmentsInColumns"
              :key="'default-column-' + n+1"
              class="st-edit-segments__segment-column bx--col-xs-6"
            > -->
            <st-segment-accordion
              v-for="(segment) in defaultSegments"
              :key="'segment-' + segment.id"
              class="bx--col-lg-6 bx--col-sm-6"
              :disabled="isEditingDisabled"
              :segment="segment"
              :selected="segment.selected"
              @toggleSelected="toggleDefaultSelected(segment.id)"
            />
            <!-- </div> -->
          </div>
        </template>
        <div class="st-edit-segments__section-title st-edit-segments__section-title--custom">
          <div v-if="isSpacetricsAccount">
            Spacetrics (Default) Segments
          </div>
          <div v-else>
            Custom Segments
          </div>
          <div
            :class="{
              'st-edit-segments__create-custom-segment': true,
              'st-edit-segments__create-custom-segment--disabled': isEditingDisabled,
            }"
            @click="handleCreateCustomSegment"
          >
            <span v-if="isSpacetricsAccount">
              Create New Default Segment
            </span>
            <span v-else>
              Create Custom Segment
            </span>
            <icon-wrapper
              icon-name="segment"
              class="st-edit-segments__custom-icon"
              :neutral="true"
            />
          </div>
        </div>
        <div class="st-edit-segments__segments bx--row">
          <!-- <div
            v-for="(column, n) in customSegmentsInColumns"
            :key="'custom-column-' + n+1"
            class="st-edit-segments__segment-column bx--col-xs-6"
          > -->
          <st-segment-accordion
            v-for="segment in userCustomSegments"
            :key="'segment-' + segment.id"
            class="bx--col-lg-6 bx--col-sm-12"
            :disabled="isEditingDisabled"
            :editable="true"
            :segment="segment"
            :selected="segment.selected"
            @toggleSelected="toggleCustomSelected(segment.id)"
            @editSegment="openEditModal(segment.id)"
            @deleteSegment="openDeleteModal(segment.id)"
          />
        </div>
        <!-- </div> -->
        <st-modal
          v-model="addEditSegmentModal"
          @closeModal="closeAddEditModal"
        >
          <template slot="modalTitle">
            {{ editCreateTitle }}
          </template>
          <template
            slot="modalActions"
          >
            <st-input
              v-model="newSegment.name"
              class="st-edit-segments__input"
              label="Segment Display Name"
              placeholder="Segment Display Name"
            />
            <div class="st-edit-segments__input-label">
              <div>Answer Type</div>
              <st-tooltip
                tip="Should participants be able to select one answer, or multiple?"
                class="st-edit-segments__tooltip"
                :allow-wrap="true"
                :direction="isDesktop ? 'right' : 'bottom'"
                :max-width="isDesktop ? 350 : 250"
              >
                <icon-wrapper
                  icon-name="help"
                />
                <template v-slot:tip>
                  <div>
                    <span class="st-edit-segments__tooltip-bold">
                      Single Option:
                    </span> Participants can only select one answer.
                  </div>
                  <div>
                    <span class="st-edit-segments__tooltip-bold">
                      Multiple Choice:
                    </span> Participants can select more than one answer.
                  </div>
                </template>
              </st-tooltip>
            </div>
            <st-radio-button
              v-model="newSegment.multipleSelect"
              :active-value="newSegment.multipleSelect"
              class="st-edit-segments__radio-button-container"
              :options="[{ caption: 'Single Option', value: false }, { caption: 'Multiple Choice', value: true }]"
            />
            <div class="st-edit-segments__input-label st-edit-segments__input-label--mobile">
              <div>Add Answer Options For Participants to Choose</div>
              <st-tooltip
                tip="These will be all the different options you are providing participants to choose from."
                class="st-edit-segments__tooltip"
                :allow-wrap="true"
                :direction="isDesktop ? 'top' : 'left'"
                :max-width="isDesktop ? 250 : 200"
              >
                <icon-wrapper
                  icon-name="help"
                />
              </st-tooltip>
            </div>
            <template v-for="(answer, n) in newSegment.options">
              <st-input
                :key="'newOption-'+n"
                v-model="answer.name"
                class="st-edit-segments__input"
                :label="`Answer Option ${n+1}`"
                placeholder="Segment Display Name"
                :hover-delete="n>1"
                @onDelete="removeSegmentAnswer(n)"
              />
            </template>
            <div
              class="st-edit-segments__create-custom-segment-answer"
              @click="addSegmentAnswer"
            >
              <div>Add Another Answer Option</div>
              <icon-wrapper
                icon-name="plus"
                class="st-edit-segments__custom-icon"
              />
            </div>
          </template>
          <template
            v-if="newSegment.options.length<3"
            slot="modalButton"
          >
            <div class="st-finish-profile__modal-button">
              <st-button
                :caption="editCreateButton"
                icon-name="segment"
                :disabled="!newSegmentComplete"
                :show-action="true"
                @click="onAddEditSubmit"
              />
            </div>
          </template>
          <template
            v-if="newSegment.options.length>2"
            slot="additionalInformation"
          >
            <div class="st-edit-segments__scroll-buttons-container">
              <div
                class="st-edit-segments__scroll-button-cancel"
                @click="addEditSegmentModal=false"
              >
                Cancel
              </div>
              <div
                :class="{
                  'st-edit-segments__scroll-button-create': true,
                  'st-edit-segments__scroll-button-create--disabled': !newSegmentComplete
                }"
                @click="onAddEditSubmit"
              >
                <div>{{ editCreateButton }}</div>
                <icon-wrapper
                  icon-name="segment"
                  class="st-edit-segments__custom-icon"
                />
              </div>
            </div>
          </template>
        </st-modal>
        <st-modal
          v-model="deleteModal"
          :cancel-modal="true"
          :non-exit-modal="true"
          @closeModal="deleteModal = false"
        >
          <template v-slot:modalTitle>
            Delete Segment
          </template>
          <template v-slot:modalActions>
            <div
              class="st-member-groups__modal-text"
            >
              This segment will remain for any survey that has already been published. However, it will be removed anywhere else.
              <br>
              <br>
              Are you sure you want to continue and delete it?
            </div>
          </template>
          <template v-slot:modalButton>
            <st-button
              caption="Delete Segment"
              :show-action="true"
              icon-name="trash"
              @click="deleteSegment"
            />
          </template>
        </st-modal>

        <!-- <p
              :class="{
                'st-page-instructions st-edit-audience__header-spacing': true,
                'st-edit-audience__header-spacing--no-lists': !lists.length
              }"
            >
              {{ instructionsText }}
            </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { flatten, chunk } from 'lodash'
import { isDesktop } from '../../../mixins'
import isEditingDisabled from './isEditingDisabled'
import saveUpdates from './saveUpdates'
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInlineSpinner,
  StInput,
  StModal,
  StRadioButton,
  StToastNotification,
  StTooltip
} from '../../common'

import StSegmentAccordion from './segment-accordion'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInlineSpinner,
    StInput,
    StModal,
    StRadioButton,
    StToastNotification,
    StTooltip,
    'st-segment-accordion': StSegmentAccordion
  },
  mixins: [isDesktop, isEditingDisabled, saveUpdates],
  props: {
    customSegments: {
      type: Array,
      required: true
    },
    standardSegments: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    isSpacetricsAccount: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    let selectedSegments = this.survey.segments.map(segment => segment.id)

    return {
      addEditSegmentModal: false,
      deleteModal: false,
      defaultSegments: this.standardSegments.map(segment => {
        let isSelected = false
        if (selectedSegments.includes(segment.id)) {
          isSelected = true
        }
        return Object.assign({ expanded: false, selected: isSelected }, segment)
      }),
      editModal: false,
      userCustomSegments: this.customSegments.map(segment => {
        let isSelected = false
        if (selectedSegments.includes(segment.id)) {
          isSelected = true
        }
        return Object.assign({ expanded: false, selected: isSelected }, segment)
      }),
      newSegment: {
        name: null,
        multipleSelect: null,
        options: [{ name: null }, { name: null }]
      },
      modalSegmentId: null,
      modalSegmentIndex: -1,
      showToast: false
    }
  },
  computed: {
    customSegmentsInColumns () {
      return this.twoColumns(this.userCustomSegments)
    },
    defaultSegmentsInColumns () {
      return this.twoColumns(this.defaultSegments)
    },
    editCreateButton () {
      return this.editModal ? 'Save Changes' : 'Save Segment'
    },
    editCreateTitle () {
      if (this.isSpacetricsAccount) {
        return this.editModal ? 'Edit DEFAULT Segment' : 'Create DEFAULT Segment'
      } else {
        return this.editModal ? 'Edit Custom Segment' : 'Create Custom Segment'
      }
    },
    newSegmentComplete () {
      if (!this.newSegment.name) return false
      if (this.newSegment.multipleSelect === null) return false
      let emptyAnswers = this.newSegment.options.filter(answer => {
        return !answer.name
      })
      return emptyAnswers.length === 0
    },
    selectedSegments () {
      let selectedSegmentsList = []
      this.userCustomSegments.forEach(segment => {
        if (segment.selected) {
          selectedSegmentsList.push(segment.id)
        }
      })
      this.defaultSegments.forEach(segment => {
        if (segment.selected) {
          selectedSegmentsList.push(segment.id)
        }
      })
      return selectedSegmentsList
    }
  },
  methods: {
    handleCreateCustomSegment () {
      if (this.isEditingDisabled) { return }
      this.addEditSegmentModal = true
    },
    toggleSegmentDetails (idx) {
      this.defaultSegments[idx].expanded = !this.defaultSegments[idx].expanded
    },
    toggleCustomSegmentDetails (idx) {
      this.userCustomSegments[idx].expanded = !this.userCustomSegments[idx].expanded
    },
    saveNewSegment () {
      if (this.newSegmentComplete) {
        let url = `/segments`
        this.$axios.request({
          url: url,
          method: 'post',
          data: { segment: {
            name: this.newSegment.name,
            multiple_select: this.newSegment.multipleSelect,
            options: this.newSegment.options.map(segment => segment.name)
          } }
        })
          .then(res => {
            let newCustomSegment = Object.assign({ selected: true, expanded: false }, res.data)
            this.userCustomSegments.push(newCustomSegment)
          })
        this.closeAddEditModal()
      }
    },
    addSegmentAnswer () {
      this.newSegment.options.push({ name: null })
    },
    addNewSegment () {
      this.addSegment = true
    },
    removeSegmentAnswer (idx) {
      this.newSegment.options.splice(idx, 1)
    },
    updateSurvey () {
      return this.$axios.request({
        method: 'post',
        url: `/surveys/${this.survey.id}/segments`,
        data: { segment_ids: this.selectedSegments }
      })
        .then(res => { console.log(res) })
        .catch(err => { console.log(err) })
    },
    toggleDefaultSelected (id) {
      let idx = this.defaultSegments.map(seg => seg.id).indexOf(id)
      this.defaultSegments[idx].selected = !this.defaultSegments[idx].selected
    },
    toggleCustomSelected (id) {
      let idx = this.userCustomSegments.map(seg => seg.id).indexOf(id)
      this.userCustomSegments[idx].selected = !this.userCustomSegments[idx].selected
    },
    twoColumns (array) {
      if (array.length < 2) { return chunk(array) }

      let columnSize = Math.floor(array.length / 2)
      let columns = chunk(array, columnSize)
      let extras = flatten(columns.splice(2))
      for (let i = 0; i < extras.length; i++) {
        columns[i].push(extras[i])
      }
      return columns
    },
    onAddEditSubmit () {
      if (this.editModal) {
        this.editSegment()
      } else {
        this.saveNewSegment()
      }
    },
    editSegment () {
      let segmentData = {
        name: this.newSegment.name,
        multiple_select: this.newSegment.multipleSelect,
        options: this.newSegment.options.map(option => option.name)
      }
      let url = `/segments/${this.modalSegmentId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { segment: segmentData }
      })
        .then(res => {
          this.userCustomSegments.splice(this.modalSegmentIndex, 1, Object.assign({ selected: this.userCustomSegments[this.modalSegmentIndex].selected }, res.data))
          this.closeAddEditModal()
        })
        .catch(err => {
          console.error(err)
        })
    },
    openEditModal (segmentId) {
      this.modalSegmentIndex = this.userCustomSegments.map(x => x.id).indexOf(segmentId)
      // set newSegment info = to copy of segment being edited
      this.newSegment = this.userCustomSegments.slice(this.modalSegmentIndex, this.modalSegmentIndex + 1)[0]
      this.modalSegmentId = segmentId
      this.$nextTick(() => {
        this.editModal = true
        this.addEditSegmentModal = true
      })
    },
    openDeleteModal (segmentId) {
      this.modalSegmentIndex = this.customSegments.map(x => x.id).indexOf(segmentId)
      this.modalSegmentId = segmentId
      this.deleteModal = true
    },
    closeAddEditModal () {
      this.newSegment = {
        name: null,
        multipleSelect: null,
        options: [{ name: null }, { name: null }]
      }
      this.addEditSegmentModal = false
      this.editModal = false
      this.modalSegmentId = null
    },
    deleteSegment () {
      // send ajax request
      let url = `/segments/${this.modalSegmentId}`
      this.$axios.request({
        method: 'delete',
        url: url
      })
        .then(res => {
          this.userCustomSegments.splice(this.modalSegmentIndex, 1)
          this.$nextTick(() => {
            this.modalSegmentIndex = -1
            this.deleteModal = false
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    cancelDeleteSegmentModal () {
      this.deleteModal = false
      this.modalSegmentId = null
    }
  }
}
</script>
