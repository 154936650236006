<template>
  <div
    :class="{
      'st-topic-list__list-container': true,
      'st-topic-list__list-container--specs-preview': isSpecificationsPreview,
      'st-topic-list__list-container--phone-demo-preview': isDemoPreview && mobileDemoView
    }"
  >
    <div class="st-topic-list__title-and-close">
      <p class="st-topic-list__questions-title">
        Topics
      </p>
      <div
        class="st-topic-list__close-icon-container"
        @click="$emit('close-topic-list')"
      >
        <icon-wrapper
          class="st-topic-list__close-icon"
          icon-name="plus"
          :invert="true"
        />
      </div>
    </div>
    <div
      :class="{
        'st-topic-list__scroll-area': true,
        'st-topic-list__scroll-area--phone-demo-preview': isDemoPreview && mobileDemoView
      }"
    >
      <div
        v-for="(topic) in topics"
        :key="'topic' + topic"
        class="st-topic-list__topic-container"
        @click="changeViewpoint(topic)"
      >
        <div class="st-topic-list__topic-and-arrow">
          <div class="st-topic-list__room-and-topic">
            <div class="st-topic-list__topic-room">
              {{ getTopicRoom(topic) }}
            </div>
            <p class="st-topic-list__topic">
              {{ topic }}
            </p>
          </div>
          <icon-wrapper
            class="st-topic-list__topic-arrow"
            icon-name="arrow"
            :invert="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { IconWrapper } from '../../../../common'

export default {
  components: {
    IconWrapper
  },
  props: {
    isDemoPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSpecificationsPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapState('participants', ['activeQuestion', 'mobileDemoView']),
    ...mapGetters('participants', ['topicsByPanoId', 'topics'])
  },
  methods: {
    changeViewpoint (topic) {
      let newPanoId = Object.keys(this.topicsByPanoId).find(panoId => this.topicsByPanoId[panoId] === topic)
      this.$emit('movePano', newPanoId)
    },
    getTopicRoom (topic) {
      let topicPanoId = Object.keys(this.topicsByPanoId).find(topicId => this.topicsByPanoId[topicId] === topic)
      let topicPanorama = this.activeQuestion.panoramas.find(pano => { return pano.identifier === topicPanoId })
      let topicRoom = this.activeQuestion.rooms.find(room => { return room.id === topicPanorama.roomId })
      return topicRoom.name
    }
  }
}
</script>
