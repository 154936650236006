var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"st-slides st-slides__canvas",on:{"click":function($event){return _vm.clickedOnContainer($event)}}},[_vm._l((_vm.objects),function(object,index){return _c('moveable',_vm._b({key:'object'+object.id,ref:'object'+object.id,refInFor:true,staticClass:"moveable",style:({
      width: object.pxWidth=='0px'?'350px':object.pxWidth,
      height: object.pxHeight=='0px'?'200px':object.pxHeight,
      left: object.pxX,
      top: object.pxY,
      zIndex: object.type === 'text' ? 99: 9
    }),on:{"drag":function($event){return _vm.handleDrag($event, object, index)},"resize":function($event){return _vm.handleResize($event, object, index)},"resizeEnd":function($event){return _vm.handleResizeEnd($event, object, index)},"scale":function($event){return _vm.handleScale($event, object, index)},"rotate":function($event){return _vm.handleRotate($event, object, index)},"warp":function($event){return _vm.handleWarp($event, object, index)},"dragStart":function($event){return _vm.focusObject($event, object)},"dragEnd":function($event){return _vm.handleDragEnd($event, object, index)},"click":function($event){return _vm.focusObject($event, object)}}},'moveable',object.type === 'text' ? _vm.textMovable : _vm.imageMoveable,false),[(object.type === 'text')?_c('span',[_c('editor',{attrs:{"parent-ref":'object'+index,"object-index":object.index,"object":object},on:{"blur":_vm.blurObject}})],1):_vm._e(),_vm._v(" "),(object.type === 'image')?_c('div',{staticClass:"moveable-image"},[_c('img',{attrs:{"src":object.blob,"alt":"","tabindex":index},on:{"click":function($event){return _vm.focusObject($event, object)},"blur":function($event){return _vm.blurObject(object)}}}),_vm._v(" "),_c('span',{on:{"click":function($event){return _vm.removeImage(object)}}},[_c('icon-wrapper',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeObject && object.id === _vm.activeObject.id),expression:"activeObject && object.id === activeObject.id"}],staticClass:"moveable-image__delete",attrs:{"icon-name":"trash"}})],1)]):_vm._e()])}),_vm._v(" "),_vm._l((_vm.templates),function(templateVal,templateKey,index){return [(_vm.currentTemplate === templateKey)?_c('div',{key:index,class:{
        'st-slides__block': true,
        'st-slides__block--active': _vm.currentTemplate === templateKey,
        'st-slides__block--1x1' : templateKey === '1x1',
        'st-slides__block--1x2' : templateKey === '1x2',
        'st-slides__block--2x1' : templateKey === '2x1',
        'st-slides__block--1x3' : templateKey === '1x3'
      },style:({backgroundColor: _vm.slideColors[index], })},_vm._l((templateVal),function(val,boxIndx){return _c('div',{key:'box'+val,ref:'box' + val,refInFor:true,class:{
          'st-slides__box': true,
          'st-slides__box--1x1' : templateKey === '1x1',
          'st-slides__box--1x2' : templateKey === '1x2',
          'st-slides__box--2x1' : templateKey === '2x1',
          'st-slides__box--1x3' : templateKey === '1x3'
        },staticStyle:{"height":"100%"},style:({backgroundColor: _vm.slideColors[boxIndx]})})}),0):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }