<template>
  <div>
    <div
      ref="exitLink"
      class="st-virtual-tour-insights__exit-edit-mode"
      @click="$emit('exitEditMode')"
    >
      Exit Editing Mode
    </div>

    <div class="st-virtual-tour-insights__transcript-participant st-virtual-tour-insights__transcript-participant--edit">
      <participant-avatar :participant="response" />
      <div class="st-virtual-tour-insights__transcript-name">
        {{ response.fullName }}
      </div>
    </div>
    <div
      v-for="(sentence, idx) in sentences"
      :key="idx + sentence.text"
      class="st-virtual-tour-insights__one-sentence"
    >
      <div class="st-virtual-tour-insights__full-transcript-sentence-time-row">
        <div class="st-virtual-tour-insights__full-transcript-timestamp">
          {{ sentence.formattedTimes }}
        </div>
        <div
          class="st-virtual-tour-insights__sentence-icon"
          @click="playSentence(sentence.startSecond, sentence.endSecond)"
        >
          <icon-wrapper icon-name="play" />
        </div>
        <div
          class="st-virtual-tour-insights__sentence-icon"
          @click="editSentence(idx)"
        >
          <icon-wrapper icon-name="edit" />
        </div>
      </div>
      <div
        v-if="idx === sentenceIndexToEdit"
        class="st-virtual-tour-insights__edit-full-transcript-sentence"
      >
        <textarea
          ref="textarea"
          v-model="editSentenceText"
          class="st-input st-virtual-tour-insights__full-transcript-input"
          type="text"
          placeholder=" "
          :style="{
            minHeight: textAreaHeight
          }"
          @input="reStyleMinHeight"
        />

        <div class="st-virtual-tour-specs__prompt-actions">
          <div
            class="st-virtual-tour-specs__prompt-action st-virtual-tour-specs__prompt-action--cancel"
            @click="sentenceIndexToEdit=null"
          >
            Cancel
          </div>
          <div
            class="st-virtual-tour-specs__prompt-action"
            @click="saveChanges(idx, sentence.startSecond, sentence.endSecond)"
          >
            Save
          </div>
        </div>
      </div>
      <div
        v-else
        :class="{
          'st-virtual-tour-insights__full-transcript-sentence-text': true,
          'st-virtual-tour-insights__active-sentence': currentSentenceIndex === idx
        }"
      >
        {{ sentence.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { IconWrapper } from '../../common'
import ParticipantAvatar from './participant-avatar'

export default {
  components: {
    IconWrapper,
    ParticipantAvatar
  },
  props: {
    response: {
      type: Object,
      required: true
    },
    initialSentences: {
      type: Array,
      required: true
    },
    currentSentenceIndex: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      activeAudioUrl: null,
      activeAudioContentType: null,
      editResponse: this.response,
      sentences: this.initialSentences,
      sentenceIndexToEdit: null,
      editSentenceText: '',
      anyChanges: false,
      currentSentenceTimeout: null,
      textAreaHeight: ''
    }
  },
  computed: {
    ...mapState('insights', ['activePanelSection']),
    ...mapGetters('insights', ['responsesPassingParticipantCheckboxFilter'])
  },
  mounted: function () {
    this.$refs.exitLink.scrollIntoView({
      block: 'center',
      behavior: 'instant'
    })
  },
  beforeDestroy () {
    if (!this.anyChanges) { return }

    this.$axios.patch(`/question_responses/${this.response.id}/reanalyze`)
      .then(res => console.log('success'))
      .catch(err => console.log('error reanalyzing', err))
  },
  methods: {
    playSentence (startTime, endTime) {
      this.$emit('playSentence', startTime, endTime)
    },
    editSentence (sentenceIdx) {
      this.editSentenceText = this.sentences[sentenceIdx].text
      this.sentenceIndexToEdit = sentenceIdx
      this.$nextTick(() => {
        this.reStyleMinHeight()
      })
    },
    saveChanges (sentenceIdx, startSecond, endSecond) {
      this.anyChanges = true
      let url = `/question_responses/${this.editResponse.id}/transcript`
      let data = {
        start_second: startSecond,
        end_second: endSecond,
        text: this.editSentenceText
      }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: data
      })
        .then(res => {
          this.sentences[sentenceIdx].text = this.editSentenceText
          this.$store.dispatch('insights/replaceEditedResponse', res.data)
          this.$nextTick(() => {
            this.editSentenceText = ''
            this.sentenceIndexToEdit = null
          })
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    reStyleMinHeight () {
      this.textAreaHeight = this.$refs.textarea[0].scrollHeight + 'px'
    }
  }
}
</script>
