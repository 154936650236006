<template>
  <div class="preference-slider__container">
    <div class="preference-slider">
      <div class="options">
        <div
          class="option"
          :style="{fontSize: fontSize1}"
        >
          {{ minYLabel }}
        </div>
        <div class="option or">
          or
        </div>
        <div
          class="option"
          :style="{fontSize: fontSize2}"
        >
          {{ maxYLabel }}
        </div>
      </div>
      <div class="slider-axis">
        <div class="drag-container">
          <svg
            id="preference-slider-svg"
            class="drag-container__slider"
            viewBox="0 0 10 300"
          >
            <line
              class="drag-container__axis"
              x1="5"
              y1="0"
              x2="5"
              y2="297"
              stroke="#9CA6CC"
              stroke-width="3"
            />
          </svg>
          <div class="drag-container__elements">
            <ds-drag-element
              id="slider"
              ref="slider"
              :initial-top="150"
              :initial-left="5"
              :initial-radius="radius"
              :slider-y="true"
              :min-x="-6.5"
              :max-x="-6.5"
              @endPosition="finalPosition($event)"
              @moving="finalPosition($event)"
            />
          </div>
        </div>
        <div class="slider-axis-scores">
          <div class="score">
            {{ topScore }}
          </div>
          <div class="score">
            {{ bottomScore }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="drag-container__submit"
    >
      <cv-button
        v-if="isSurveyPreview && priority > 0"
        kind="secondary"
        class="st-question__preview-back-btn"
        @click="handlePreviewBack"
      >
        <div class="st-page__btn-slot">
          <div class="st-svg__left-chevron" />
          <div>Back</div>
        </div>
      </cv-button>
      <cv-button
        v-if="selected || isSurveyPreview"
        class="next-btn"
        @click="submitQuestionAnswer"
      >
        <div>
          Next
          <div class="btn-icon" />
        </div>
      </cv-button>
    </div>
  </div>
</template>

<script>
import DragElement from '../../common/drag-element'
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import previousResponse from '../../../mixins/previousResponse.vue'
import submitQuestionAnswer from '../../../mixins/question/submitQuestionAnswer.vue'
import handlePreviewBack from '../../../mixins/handlePreviewBack.vue'

export default {
  components: {
    'ds-drag-element': DragElement,
    'cv-button': CvButton
  },
  mixins: [submitQuestionAnswer, handlePreviewBack, previousResponse],
  props: {
    minYLabel: {
      type: String,
      required: true
    },
    maxYLabel: {
      type: String,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    priority: {
      type: Number,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      selected: null,
      position: {
        y: 150
      },
      radius: 11.5,
      maxY: 300
    }
  },
  computed: {
    fontSize1: function () {
      return (40 - 30 * this.absoluteScore(this.position.y)) + 'px'
    },
    fontSize2: function () {
      return (10 + 30 * this.absoluteScore(this.position.y)) + 'px'
    },
    topScore: function () {
      return 100 - this.axisScore(this.position.y)
    },
    bottomScore: function () {
      return this.axisScore(this.position.y)
    },
    value: function () {
      return 1 - this.absoluteScore(this.position.y)
    }
  },
  mounted () {
  },
  methods: {
    loadResponses () {
      if (this.questionResponses && this.questionResponses.length > 0) {
        this.$nextTick(() => {
          let maxRange = this.maxY - (2 * this.radius)
          let y = maxRange * (1 - Number(this.questionResponses[0].value)) + this.radius
          this.position.y = y
          this.$refs.slider.updateY(y)
          this.selected = true
        })
      }
    },
    answerResponse () {
      return { value: this.value }
    },
    finalPosition (data) {
      this.position.y = data.y
      this.selected = true
    },
    axisScore (position) {
      return Math.round(100 * this.absoluteScore(position))
    },
    absoluteScore (position) { // score between 0 and 1)
      let maxRange = this.maxY - (2 * this.radius)
      let radius = this.radius
      return (position - radius) / maxRange
    }
  }
}
</script>
