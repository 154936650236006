var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-concept-ranking__content': true,
    'st-concept-ranking__content--specs': _vm.isSpecificationsPreview
  }},[_c('div',{ref:"questionContainer",class:{
      'st-participant__question-container': true,
      'st-participant__question-container--no-media': _vm.hasNoMedia
    }},[_c('p',{ref:"question",staticClass:"st-participant__question-container-para"},[_vm._v("\n      "+_vm._s(_vm.description)+" \n      \n    ")])]),_vm._v(" "),_c('st-question-media',{class:{
      'st-concept-ranking__question-media': true,
      'st-concept-ranking__question-media--specs': _vm.isSpecificationsPreview
    },attrs:{"is-desktop-survey":_vm.isDesktopSurvey,"is-desktop-survey-preview":_vm.isDesktopSurveyPreview,"is-specifications-preview":_vm.isSpecificationsPreview,"media":_vm.options,"question-id":_vm.questionId}}),_vm._v(" "),_c('div',{class:{
      'st-concept-ranking__scales-and-response': true,
      'st-concept-ranking__scales-and-response--multi-media-desktop-preview': _vm.multiMediaSurveyPreview,
      'st-concept-ranking__scales-and-response--multi-media-desktop': _vm.hasMultipleMedia && _vm.isDesktopSurvey

    }},[(_vm.scaleLength)?_c('div',{class:{
        'st-concept-ranking__scale-spacing': !_vm.isSpecificationsPreview,
        'st-concept-ranking__scales': _vm.scaleValue,
        'st-concept-ranking__scales--specs': _vm.isSpecificationsPreview,
      }},[(_vm.isEmojiScale)?_c('st-emoji-scale',{attrs:{"max":_vm.scaleLength},model:{value:(_vm.scaleValue),callback:function ($$v) {_vm.scaleValue=$$v},expression:"scaleValue"}}):(_vm.isNumericScale)?_c('st-numeric-scale',{attrs:{"max":_vm.scaleLength},model:{value:(_vm.scaleValue),callback:function ($$v) {_vm.scaleValue=$$v},expression:"scaleValue"}}):(_vm.isAgreeDisagreeScale)?_c('st-slider',{attrs:{"labels":_vm.sliderLabels,"max":_vm.scaleLength},model:{value:(_vm.scaleValue),callback:function ($$v) {_vm.scaleValue=$$v},expression:"scaleValue"}}):(_vm.isStarsScale)?_c('st-star-scale',{attrs:{"max":_vm.scaleLength},model:{value:(_vm.scaleValue),callback:function ($$v) {_vm.scaleValue=$$v},expression:"scaleValue"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.hasResponse && _vm.isResponseTypeNone)?_c('st-tooltip',{staticClass:"st-concept-ranking__button-tooltip",attrs:{"tip":"Preview only button","is-active":_vm.isSpecificationsPreview}},[_c('st-button',{staticClass:"st-concept-ranking__button",attrs:{"caption":_vm.buttonCaption,"icon-name":"arrow","underline":false,"variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1):_vm._e(),_vm._v(" "),(_vm.hasResponse)?_c('div',{class:{
        'st-concept-ranking__response-area': _vm.isSpecificationsPreview,
      }},[(_vm.isResponseTypeVoice)?_c('st-voice-response',{attrs:{"ask-for-explanation":_vm.askForExplanation,"audio-blob":_vm.audioBlob,"is-preview":_vm.isPreview,"is-survey-preview":_vm.isSurveyPreview},on:{"update-audio-blob":_vm.updateAudioBlob}}):(_vm.isResponseTypeText)?_c('div',[_c('st-text-area',{class:{
            'st-participant-elements__text-area': true,
            'st-participant-elements__text-area--large': _vm.textAreaFocused
          },attrs:{"button":_vm.textAreaFocused,"label":"Share your thoughts here","placeholder":"Tell us what you think"},on:{"focus":_vm.focusTextArea,"focusout":_vm.focusTextArea},model:{value:(_vm.writtenResponse),callback:function ($$v) {_vm.writtenResponse=$$v},expression:"writtenResponse"}}),_vm._v(" "),(_vm.showTextResponseNextButton)?_c('st-tooltip',{staticClass:"st-concept-ranking__button-tooltip",attrs:{"tip":"Preview only button","is-active":_vm.isSpecificationsPreview}},[_c('st-button',{staticClass:"st-concept-ranking__button",attrs:{"caption":_vm.buttonCaption,"disabled":!_vm.writtenResponse.length,"icon-name":"arrow","underline":false,"variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1):_vm._e()],1):_c('div',[_c('st-button',{staticClass:"st-concept-ranking__button",attrs:{"caption":_vm.buttonCaption,"underline":false,"icon-name":"arrow","variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1)],1):_c('div',{class:{
        'st-concept-ranking__response-area': _vm.isSpecificationsPreview,
      }},[_c('st-button',{staticClass:"st-concept-ranking__button",attrs:{"disabled":true,"caption":_vm.buttonCaption,"underline":false,"icon-name":"arrow","variant":['secondary']}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }