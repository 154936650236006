<template>
  <div
    class="st-specifications__slide-icon-box"
  >
    <div
      class="st-specifications__slide-icon st-specifications__slide-color"
      :class="{ 'st-specifications__slide-active': showSlide }"
      tabindex="1"
      @click.self="toggleMenu('showSlide', $event)"
    > 
      <!-- @blur="closeMenu('showSlide')" -->
      <div
        v-if="showSlide"
        class="st-specifications__slide-menu-overlay"
      >
        <div class="st-specifications__slide-menu">
          <div
            v-for="(c, boxIndex) in templates[currentTemplate]"
            :key="c"
            class="st-specifications__slide-box st-specifications__slide-box-active"
            @click.stop="selectBox(c, $event)"
          >
          
            <color-picker
              :box-index="boxIndex"
              @changed="onValueChnaged"
              @closed="handleClosed"
            />
          </div>
        </div>
      </div>

      <div />
    </div>
    <div
      class="st-specifications__slide-icon st-specifications__slide-text"
      tabindex="2"
      @click="addNewObject(null, 'text')"
    />
    <div
      class="st-specifications__slide-icon st-specifications__slide-temp"
      :class="{ 'st-specifications__slide-active': showTemplate }"
      tabindex="3"
      @click.self="toggleMenu('showTemplate', $event)"
      @blur="closeMenu('showTemplate')"
    >
      <div class="st-specifications__slide-menu-overlay">
        <div class="st-specifications__slide-menu">
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-1"
              @click="$emit('changeTemplate', '1x1')"
            />
          </div>
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-2"
              @click="$emit('changeTemplate', '1x2')"
            />
          </div>
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-3"
              @click="$emit('changeTemplate', '2x1')"
            />
          </div>
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-4"
              @click="$emit('changeTemplate', '1x3')"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="st-specifications__slide-icon st-specifications__slide-img"
      tabindex="4"
    >
      <input
        @click="isUploadOpen = true"
      >
    </div>
    <st-media-uploader
      v-if="isUploadOpen"
      accept=".jpg, .jpeg, .png"
      :additional-props="additionalProps"
      :folders="folders"
      modal-title="Upload Media or Select From Library"
      :multi-upload="true"
      prop-name="slide_question_object[media]"
      :skip-cropper="true"
      request-type="post"
      :url="`/questions/${activeQuestion.id}/slide_question_objects`"
      @closeMediaUploader="isUploadOpen = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { SLIDES_TEMPLATES } from '../../../../../utilities/enums'
import updateQuestion from '../../../../../mixins/question/updateQuestion.vue'
import { updateSlideQuestionObject } from '../../../../../mixins'
import { IconWrapper, StMediaUploader } from '../../../../common'
import { SLIDE_COLORS } from '../constants'
import ColorPicker from './ColorPicker.vue'
export default {
  components: {
    IconWrapper,
    StMediaUploader,
    ColorPicker
  },
  mixins: [updateQuestion, updateSlideQuestionObject],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    changeTemplate: {
      type: Function,
      required: false,
      default: null
    }
  },
  data () {
    return {
      templates: SLIDES_TEMPLATES,
      colors: SLIDE_COLORS,
      showSlide: false,
      showText: false,
      showTemplate: false,
      selectedBox: null,
      isUploadOpen: false,

      showPicker: false
    }
  },
  computed: {
    ...mapGetters('specifications', ['currentTemplate', 'color', 'slideColors'])
  },
  methods: {
    ...mapMutations('specifications', ['addImage', 'addObject', 'setActiveObject']),
    ...mapActions('specifications', ['setColor', 'addText']),
    additionalProps () {
      const newIndex = this.activeQuestion.slideQuestionObjects.length

      return {
        'slide_question_object[question_id]': this.activeQuestion.id,
        'slide_question_object[object_index]': newIndex,
        'slide_question_object[slide_object_type]': 'image',
        'slide_question_object[x_position]': 0,
        'slide_question_object[y_position]': 0,
        'slide_question_object[width]': 50,
        'slide_question_object[height]': 50
      }
    },
    handleClosed(){
      this.closeMenu('showSlide')
      
    },
    onValueChnaged (color, index) {
      this.updateColor(color, index);
      this.showPicker = false;
      
    },
    toggleShowPicker () {
      this.showPicker = true
    },
    toggleMenu (prop, event) {
      event.target.focus()
      if (this[prop]) {
        this[prop] = false
        return
      }
      this.showSlide = false
      this.showText = false
      this.showTemplate = false
      this[prop] = !this[prop]
    },
    closeMenu (prop) {
      if (this[prop]) {
        this[prop] = false
      }
    },
    selectBox (box, event) {
      if (this.selectedBox && box === this.selectedBox.boxNumber) {
        this.selectedBox = { boxNumber: null, show: false }
      } else {
        this.selectedBox = { boxNumber: box, show: true }
      }
    },
    updateColor (code, boxIndex) {
      
      let newSelectedBox;
      if(!this.selectedBox.boxNumber){
        newSelectedBox = boxIndex+1;
      }
      else{
        newSelectedBox = this.selectedBox.boxNumber;
      }
      this.$nextTick(() => {
        this.setColor({
          code,
          boxIndex,
          elementRef: `box${newSelectedBox}`,
          previewElementRef: `preview-box${newSelectedBox}`
        })
        this.updateQuestion('slide_colors', this.slideColors)
        if(this.colors.indexOf(code) != -1 ){
           this.closeMenu('showSlide')
        }
      });
     
    },
    addNewObject (event, type) {
      let object = {}
      const { slideQuestionObjects } = this.activeQuestion
      const containerWidth = document.querySelector('.st-slides__canvas').offsetWidth
      const containerHeight = document.querySelector('.st-slides__canvas').offsetHeight
      // getting index of new element
      const newIndex = slideQuestionObjects.length

      if (type === 'text') {
        object = {
          type,
          index: newIndex,
          content: '',
          x: 0,
          y: 12,
          pxX: '0px',
          pxY: '42px',
          question_id: this.activeQuestion.id,
          object_index: newIndex,
          slide_object_type: type,
          x_position: 0,
          y_position: 0,
          pxWidth: object.width * containerWidth / 100 + 'px',
          pxHeight: object.height * containerHeight / 100 + 'px'
        }
        this.createNewObject({ slide_question_object: object }, null, res => {
          this.addObject({ ...object, id: res.id })
        })
      }
    },
    uploadComplete (payload) {
      const containerWidth = document.querySelector('.st-slides__canvas').offsetWidth
      const containerHeight = document.querySelector('.st-slides__canvas').offsetHeight

      payload.forEach(object => {
        this.addObject({
          id: object.id,
          index: object.objectIndex,
          type: object.slideObjectType,
          blob: object.mediaUrl,
          width: object.width,
          height: object.height,
          x: object.xPosition,
          y: object.yPosition,
          pxWidth: object.width * containerWidth / 100 + 'px',
          pxHeight: object.height * containerHeight / 100 + 'px',
          pxX: object.xPosition * containerWidth / 100 + 'px',
          pxY: object.yPosition * containerHeight / 100 + 'px'
        })
      })
      this.isUploadOpen = false
    },
    reset () {
      this.showSlide = false
      this.showText = false
      this.showTemplate = false
      this.selectedBox = null
    }
  }
}
</script>
