<script>
import { Loader } from '@googlemaps/js-api-loader'

const ADDRESS_FORM = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name'
}

export default {
  props: {
    apiKey: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      autoCompleteValue: ''
    }
  },
  computed: {
    autoCompleteConditional () {
      return this.stateToWatch || null
    }
  },
  watch: {
    autoCompleteConditional (newValue, oldValue) {
      if (newValue) { this.$nextTick(() => { this.addAutoComplete(); this.setInitialValue() }) }
    }
  },
  mounted () {
    const loader = new Loader({
      apiKey: this.apiKey,
      libraries: ['places']
    })

    loader.load().then(() => {
      if (Object.keys(this.$refs).includes('autoComplete')) { this.addAutoComplete() }
    })
  },
  methods: {
    addAutoComplete () {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autoComplete.$refs.stInput, { types: ['geocode'] })
      this.autocomplete.setFields(['address_components'])
      this.autocomplete.addListener('place_changed', this.handleAutoCompleteUpdate)
    },
    handleAutoCompleteUpdate () {
      let place = this.autocomplete.getPlace()
      let addressArr = []

      for (let component of place.address_components) {
        let addressType = component.types[0]

        if (ADDRESS_FORM[addressType]) {
          let val = component[ADDRESS_FORM[addressType]]
          if (addressType === 'locality' || addressType === 'administrative_area_level_1') { val = val + ',' }
          addressArr.push(val)
        }

        this.autoCompleteValue = addressArr.join(' ')
      }
    },
    setInitialValue () {
      if (this.initialAutoCompleteValue) { this.autoCompleteValue = this.initialAutoCompleteValue }
    }
  }
}
</script>
