<template>
  <div class="st-slider__container">
    <label
      v-if="!alwaysShowLabel"
      :class="{
        'st-slider__altering-label': true,
        'st-slider__altering-label--inactive': !isActive
      }"
      for="slider"
    >
      {{ getLabel(reducedInputValue) }}
    </label>
    <input
      :id="stUid"
      :class="{
        'st-slider__slider': true,
        'st-slider__slider--inactive': !isActive
      }"
      :max="max * stepMultiplier"
      :min="min * stepMultiplier"
      :step="1"
      type="range"
      :value="inputValue"
      @input="handleInput"
      @mousedown="isActive = true"
      @touchstart="isActive = true"
      @mouseup="handleMouseUp"
      @touchend="handleMouseUp"
    >
  </div>
</template>

<script>
import { bucketIdx } from '../../utilities/data_utilities'
import StUid from '../../mixins/uid'

const STEP_MULTIPLIER = 1000

export default {
  mixins: [StUid],
  props: {
    alwaysShowLabel: {
      type: Boolean,
      default: false,
      required: false
    },
    labels: {
      type: Array,
      required: false,
      default: () => []
    },
    max: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      default: 1,
      required: false
    },
    value: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data: function () {
    return {
      isActive: false,
      inputValue: null
    }
  },
  computed: {
    stepMultiplier () {
      return STEP_MULTIPLIER
    },
    reducedInputValue () {
      let options = {
        bucketCount: (this.max - this.min) + 1,
        maxScore: this.max * STEP_MULTIPLIER,
        minScore: this.min * STEP_MULTIPLIER
      }
      return bucketIdx(this.inputValue, options) + 1
    }
  },
  mounted () {
    if (this.value) { this.isActive = true }
    this.inputValue = (this.value * STEP_MULTIPLIER) || (Math.round(this.max / 2) * STEP_MULTIPLIER)
  },
  methods: {
    getLabel (value) {
      return this.labels[0].split(',')[value - 1]
    },
    handleMouseUp (event) {
      setTimeout(() => {
        this.inputValue = this.reducedInputValue * STEP_MULTIPLIER
        this.$emit('input', this.reducedInputValue)
      }, 50)
    },
    handleInput (event) {
      this.inputValue = parseInt(event.target.value)
    }
  }
}
</script>
