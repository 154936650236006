<template>
  <div class="st-question-instructions__page-wrapper">
    <div
      :class="[{ 'st-question-instructions__illustration': true }, illustrationClass]"
    />
    <div class="st-question-instructions__lower-content">
      <div class="st-question-instructions__text-wrapper">
        <p class="st-question-instructions__header">
          {{ instructionsHeader }}
        </p>
        <p class="st-question-instructions__additional-info">
          {{ instructionsInfo }}
        </p>
      </div>
      <st-button
        class="st-question-instructions__button"
        :caption="buttonCaption"
        icon-name="arrow"
        :underline="false"
        :variant="['secondary']"
        @click="handleButtonAction"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { companyLogoStyle } from '../../../mixins'
import { StButton } from '../../common'

export default {
  components: {
    StButton
  },
  mixins: [companyLogoStyle],
  props: {
    companyLogo: {
      type: String,
      required: false,
      default: ''
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    hasMediaRecorder: {
      type: Boolean,
      required: false,
      default: false
    },
    isSingleQuestionSurvey: {
      type: Boolean,
      required: false
    },
    returning: {
      type: Boolean,
      required: false
    },
    audioPermissionGranted: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('participants', ['instructionsPage']),
    buttonCaption () {
      let caption = ''
      switch (this.instructionsPage) {
        case 1:
          caption = 'Allow Microphone'
          break
        case 2:
          caption = 'Start'
          break
      }
      return caption
    },
    illustrationClass () {
      return {
        'st-question-instructions__illustration--microphone-access': this.instructionsPage === 1,
        'st-question-instructions__illustration--speak-aloud': this.instructionsPage === 2
      }
    },
    instructionsHeader () {
      let header = ''
      switch (this.instructionsPage) {
        case 1:
          header = 'Microphone Access'
          break
        case 2:
          header = 'Speak Aloud'
          break
      }
      return header
    },
    instructionsInfo () {
      let info = ''
      switch (this.instructionsPage) {
        case 1:
          info = 'Please allow microphone access to share your thoughts by speaking out loud.'
          break
        case 2:
          info = 'Freely voice your opinions about things you like and don’t like.'
          break
      }
      return info
    }
  },
  methods: {
    ...mapMutations('participants', ['incrementInstructionsPage']),
    handleButtonAction () {
      switch (this.instructionsPage) {
        case 1:
          return this.$emit('ask-for-audio-permission', true)
        case 2:
          this.incrementInstructionsPage()
          return this.$emit('disableInstructions')
      }
    }
  }
}
</script>
