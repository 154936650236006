<template>
  <iframe
    ref="tour"
    frameborder="0"
    :src="src"
    allowfullscreen
    allow="vr"
  />
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    matterportId: {
      type: String,
      required: true
    },
    showPins: {
      type: Boolean,
      required: false,
      default: false
    },
    showGuidedTour: {
      type: Boolean,
      required: false,
      default: false
    },
    showFloorplanIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    allowZoom: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      showcase: null
    }
  },
  computed: {
    ...mapState('insights', ['activeViewpointIdentifier']),
    pinParam () {
      return this.propParam('pin', this.showPins)
    },
    guidedTourParam () {
      return this.propParam('gt', this.showGuidedTour)
    },
    playParam () {
      return 'play=1'
    },
    helpParam () {
      return 'help=0'
    },
    dollhouseParam () {
      return 'dh=0'
    },
    brandParam () {
      return 'brand=0'
    },
    highlightParam () {
      return 'hr=0'
    },
    mlsParam () {
      return 'mls=2'
    },
    zoomParam () {
      return `nozoom=${Number(!this.allowZoom)}`
    },
    floorplanParam () {
      if (this.showFloorplanIcon) {
        return ''
      } else {
        return 'fp=0'
      }
    },
    quickstartParam () {
      return 'qs=1'
    },
    params () {
      return [
        `m=${this.matterportId}`,
        this.playParam,
        this.helpParam,
        this.dollhouseParam,
        this.pinParam,
        this.guidedTourParam,
        this.brandParam,
        this.highlightParam,
        this.mlsParam,
        this.floorplanParam,
        this.zoomParam,
        this.quickstartParam
      ].join('&')
    },
    src () {
      return `https://my.matterport.com/show/?${this.params}`
    }
  },
  mounted () {
    this.$refs.tour.addEventListener('load', () => { this.loadShowcase() }, true)
  },
  methods: {
    propParam (paramText, val) {
      return `${paramText}=${val ? 1 : 0}`
    },
    loadShowcase () {
      window.SHOWCASE_SDK.connect(
        this.$refs.tour,
        'bf1119417af14c5281844101e3204052',
        '3.0' // SDK version
      )
        .then((sdk) => {
          this.showcase = sdk
          sdk.on(sdk.App.Event.PHASE_CHANGE, appstate => {
            this.$emit('matterport-phase-change', appstate)
          })
          this.$emit('matterport-showcase-loaded', sdk)
          sdk.Settings.update('features/measurements', false)
        })
        .catch(err => { console.error('err', err) })
    }
  }
}
</script>
