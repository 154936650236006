<template>
  <div class="bx--offset-sm-3 bx--col-sm-6 bx--col-xs-12">
    <div class="st-finish-profile__header-row">
      <h1>Finish Your Profile</h1>
      <div
        class="st-finish-profile__skip"
        @click="handleSkip"
      >
        skip
      </div>
    </div>
    <div class="st-finish-profile__inputs">
      <st-dropdown-menu
        key="department-dropdown"
        class="st-finish-profile__dropdown-menu st-finish-profile__dropdown-menu--departments"
        caption="Department (Optional)"
        placeholder="Select as many as they apply"
        :display-caption="true"
        :select-multiple="true"
        :initial-active-options-array="user.departments"
        :option-caption-fn="(department) => department.name"
        :options="departments"
        @updateMultiple="setDepartmentsOptions"
      />
      <st-upload-preview
        v-if="user.avatarUrl"
        class="st-finish-profile__image-preview"
        :src="user.avatarUrl"
        :filename="fileName(user.avatarUrl)"
        :hide-bar="true"
        @remove="user.avatarUrl=null"
      />
      <st-hyperlink
        v-else
        class="st-finish-profile__upload"
        icon-class="st-hyperlink__default-icon-spacing"
        icon-name="upload"
        text="Upload Profile Photo"
        @click="modalVisible = true"
      />
    </div>
    <div class="st-finish-member-profile__button-row">
      <st-button
        caption="Continue"
        icon-name="arrow"
        :disabled="!user.companyName"
        @click="handleContinue"
      />
    </div>
    <st-media-uploader
      v-if="modalVisible"
      :aspect-ratio="1.0"
      :folders="folders"
      :modal-title="`Upload Profile Photo`"
      prop-name="user[avatar]"
      :url="`/users/${userId}`"
      @closeMediaUploader="modalVisible = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import {
  StButton,
  StDropdownMenu,
  StHyperlink,
  StMediaUploader,
  StUploadPreview
} from '../../common'

export default {
  components: {
    StButton,
    StDropdownMenu,
    StHyperlink,
    StMediaUploader,
    StUploadPreview
  },

  props: {
    departments: {
      type: Array,
      required: true
    },
    folders: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      modalVisible: false,
      user: this.userInfo
    }
  },
  computed: {
    selectedDepartmentIds () {
      return this.user.departments.map(x => x.id)
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    fileName (srcUrl) {
      return srcUrl.split('/').slice(-1).pop()
    },
    handleContinue () {
      let url = `/users/${this.userId}`
      let data = {
        department_ids: this.selectedDepartmentIds
      }
      this.$axios.request({
        url: url,
        method: 'patch',
        data: {
          id: this.userId,
          user: data
        }
      })
        .then(res => { window.location.href = '/welcome' })
        .catch(err => { console.log(err) })
    },
    handleSkip () {
      window.location.href = '/welcome'
    },
    setDepartmentsOptions (options) {
      this.user.departments = options
    },
    uploadComplete (payload) {
      this.user.avatarUrl = payload.avatarUrl
      this.modalVisible = false
    }
  }
}
</script>
