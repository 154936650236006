<template>
  <div
    ref="container"
    class="st-slides st-slides__canvas"
    @click="clickedOnContainer($event)"
  >
    <moveable
      v-for="(object, index) in objects"
      :key="'object'+object.id"
      :ref="'object'+object.id"
      class="moveable"
      v-bind="object.type === 'text' ? textMovable : imageMoveable"
      :style="{
        width: object.pxWidth=='0px'?'350px':object.pxWidth,
        height: object.pxHeight=='0px'?'200px':object.pxHeight,
        left: object.pxX,
        top: object.pxY,
        zIndex: object.type === 'text' ? 99: 9
      }"
      @drag="handleDrag($event, object, index)"
      @resize="handleResize($event, object, index)"
      @resizeEnd="handleResizeEnd($event, object, index)"
      @scale="handleScale($event, object, index)"
      @rotate="handleRotate($event, object, index)"
      @warp="handleWarp($event, object, index)"
      @dragStart="focusObject($event, object)"
      @dragEnd="handleDragEnd($event, object, index)"
      @click="focusObject($event, object)"
    >
      <span
        v-if="object.type === 'text'"
      >
        <editor
          :parent-ref="'object'+index"
          :object-index="object.index"
          :object="object"
          @blur="blurObject"
        />
      </span>
      <div
        v-if="object.type === 'image'"
        class="moveable-image"
      >
        <img
          :src="object.blob"
          alt=""
          :tabindex="index"
          @click="focusObject($event, object)"
          @blur="blurObject(object)"
        >
        <span @click="removeImage(object)">
          <icon-wrapper
            v-show="activeObject && object.id === activeObject.id"
            class="moveable-image__delete"
            icon-name="trash"
          />
        </span>
      </div>
    </moveable>
    <template
      v-for="(templateVal, templateKey, index) in templates"
    >
      <div

        v-if="currentTemplate === templateKey"
        :key="index"
        :class="{
          'st-slides__block': true,
          'st-slides__block--active': currentTemplate === templateKey,
          'st-slides__block--1x1' : templateKey === '1x1',
          'st-slides__block--1x2' : templateKey === '1x2',
          'st-slides__block--2x1' : templateKey === '2x1',
          'st-slides__block--1x3' : templateKey === '1x3'
        }"
        :style="{backgroundColor: slideColors[index], }"
      >
        <div
          v-for="(val, boxIndx) in templateVal"
          :key="'box'+val"
          :ref="'box' + val"
          :class="{
            'st-slides__box': true,
            'st-slides__box--1x1' : templateKey === '1x1',
            'st-slides__box--1x2' : templateKey === '1x2',
            'st-slides__box--2x1' : templateKey === '2x1',
            'st-slides__box--1x3' : templateKey === '1x3'
          }"
          :style="{backgroundColor: slideColors[boxIndx]}"
          style="height:100%"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Moveable from 'vue-moveable'
import { SLIDES_TEMPLATES } from '../../../../../utilities/enums'
import updateQuestion from '../../../../../mixins/question/updateQuestion.vue'
import { updateSlideQuestionObject } from '../../../../../mixins'
import { IconWrapper } from '../../../../common'
import Editor from './editor'

export default {
  components: {
    Moveable,
    Editor,
    IconWrapper
  },
  mixins: [updateQuestion, updateSlideQuestionObject],
  data: function () {
    return {
      templates: SLIDES_TEMPLATES,
      textMovable: {
        draggable: true,
        throttleDrag: 1,
        resizable: true,
        throttleResize: 1,
        keepRatio: false,
        throttleScale: 0.01,
        rotatable: false,
        throttleRotate: 0.2,
        pinchable: true,
        origin: false,
        snappable: true
      },
      imageMoveable: {
        snappable: true,
        draggable: true,
        throttleDrag: 1,
        resizable: true,
        throttleResize: 1,
        keepRatio: false,
        scalable: false,
        throttleScale: 1,
        rotatable: false,
        throttleRotate: 0.2,
        pinchable: false
      }
    }
  },
  computed: {
    ...mapGetters('specifications', ['currentTemplate', 'color', 'slideColors', 'texts', 'slideImages', 'objects', 'activeObject']),
    slideQuestionObjects () {
      return this.activeQuestion.slideQuestionObjects
    }
  },
  watch: {
    color (newVal) {
      if (newVal && this.$refs[newVal.elementRef]) {
        this.$refs[newVal.elementRef][0].style.background = newVal.code
      }
    }
  },
  beforeMount () {
    window.scrollTo(0, 0)
  },
  mounted () {
    const { slideQuestionObjects } = this.activeQuestion
    slideQuestionObjects.sort((a, b) => a.id - b.id)

    if (this.activeQuestion.slideTemplateKey) {
      this.setCurrentTemplate(this.activeQuestion.slideTemplateKey)
    } else {
      this.setCurrentTemplate(Object.keys(this.templates)[0])
      this.updateQuestion('slide_template_key', Object.keys(this.templates)[0])
    }
    if (this.activeQuestion.slideColors.length) {
      this.setSlideColors(this.activeQuestion.slideColors)
    } else {
      this.setSlideColors([])
    }
    if (slideQuestionObjects.length) {
      const objects = []
      const containerWidth = this.$refs.container.offsetWidth
      const containerHeight = this.$refs.container.offsetHeight
      slideQuestionObjects.forEach(object => {
        let slideObject = {
          id: object.id,
          index: object.objectIndex,
          type: object.slideObjectType,
          content: object.content,
          blob: object.mediaUrl,
          width: object.width,
          height: object.height,
          x: object.xPosition,
          y: object.yPosition,
          pxX: object.xPosition * containerWidth / 100 + 'px', // converting to px to set exact coords
          pxY: object.yPosition * containerHeight / 100 + 'px',
          pxWidth: object.width * containerWidth / 100 + 'px', // converting to px because of moveable component
          pxHeight: object.height * containerHeight / 100 + 'px'
        }
        objects.push(slideObject)
      })
      this.setObjects(objects)
      // removing control boxes from objects
      setTimeout(() => {
        for (let objectIndex = 0; objectIndex < objects.length; objectIndex++) {
          const lineStartingIndex = objectIndex * 4
          const controlStartingIndex = objectIndex * 8
          const lineEndingIndex = objectIndex === 0 ? 4 : 4 * (objectIndex + 1)
          const controlEndingIndex = objectIndex === 0 ? 8 : 8 * (objectIndex + 1)

          for (let index = lineStartingIndex; index < lineEndingIndex; index++) {
            document.querySelectorAll('.moveable-line')[index].style.opacity = 0
          }
          if (document.querySelectorAll('.moveable-control').length) {
            for (let index = controlStartingIndex; index < controlEndingIndex; index++) {
              document.querySelectorAll('.moveable-control')[index].style.backgroundColor = 'transparent'
              document.querySelectorAll('.moveable-control')[index].style.borderColor = 'transparent'
            }
          }
        }
      }, 250)
    }
    // this.getContainerDimension()
  },
  beforeDestroy () {
    this.setObjects([])
  },
  methods: {
    ...mapMutations('specifications', ['setObjects', 'updateObject', 'setActiveObject', 'setSlideColors', 'removeObject']),
    ...mapActions('specifications', ['setColor', 'setCurrentTemplate']),

    getPosition (parent, child) {
      const parentPos = parent.getBoundingClientRect()
      const childPos = child.getBoundingClientRect()
      const relativePos = {}

      relativePos.top = childPos.top - parentPos.top
      relativePos.right = childPos.right - parentPos.right
      relativePos.bottom = childPos.bottom - parentPos.bottom
      relativePos.left = childPos.left - parentPos.left

      return relativePos
    },
    handleDrag (event, object, index) {
      const { target, transform, translate, width, height } = event;
      let [tx, ty] = translate;
      let pos = this.getPosition(this.$refs.container, target)
      // if (object.type === 'text') {
      //   const texts = this.objects.filter(o => o.type === object.type)
      //   const textIndex = texts.findIndex(text => text.id === object.id)
      //   pos = this.getPosition(this.$refs.container, document.querySelectorAll('.ql-editor')[textIndex])
      // } else if (object.type === 'image') {
      //   pos = this.getPosition(this.$refs.container, target)
      // }
      const { top, left, bottom, right } = pos;
      if (top < 0) {
        ty = ty - top;
        pos.top = 0;
      }
      if (left < 0) {
        tx = tx - left;
        pos.left = 0;
      }
      if (right > 0) {
        tx = tx - right;
        pos.left = this.$refs.container.offsetWidth - width;
      }
      if (bottom > 0) {
        ty = ty - bottom;
        pos.top = this.$refs.container.offsetHeight - height;
      }
      target.style.transform = `${transform.split(" translate")[0]} translate(${tx}px, ${ty}px)`
      this.updateObject({
        id: object.id,
        x: pos.left / this.$refs.container.offsetWidth * 100, // converting to %
        y: pos.top / this.$refs.container.offsetHeight * 100 // converting to %
      })
    },
    handleDragEnd ({ target, transform }, object, index) {
      this.updateSlideQuestionObject(object.id, index, { slide_question_object: { x_position: object.x, y_position: object.y } })
    },
    handleResize (event, object, index) {
      let { target, width, height, drag } = event;
      const pos = this.getPosition(this.$refs.container, target)
      const { top, left, bottom, right } = pos;
      if (top < 0) height = height + top;
      if (left < 0) width = width + left;
      if (right > 0) width = width - right;
      if (bottom > 0) height = height - bottom;
      const beforeTranslate = drag.beforeTranslate;
      target.style.width = `${width}px`
      target.style.height = `${height}px`
      target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
      this.updateObject({
        id: object.id,
        width: width / this.$refs.container.offsetWidth * 100,
        height: height / this.$refs.container.offsetHeight * 100
      })
    },
    handleResizeEnd ({ target }, object, index) {
      this.updateSlideQuestionObject(object.id, index, { slide_question_object: { width: object.width, height: object.height } })
    },
    handleScale ({ target, transform }) {
      target.style.transform = transform
    },
    handleRotate ({ target, transform }) {
      target.style.transform = transform
    },
    handleWarp ({ target, transform }) {
      target.style.transform = transform
    },
    focusObject (event, object) {
      this.blurObject()
      // if (event) event.target.focus()
      const objectIndex = this.slideQuestionObjects.findIndex(o => o.id === object.id)
      const lineStartingIndex = objectIndex * 4
      const controlStartingIndex = objectIndex * 8
      const lineEndingIndex = objectIndex === 0 ? 4 : 4 * (objectIndex + 1)
      const controlEndingIndex = objectIndex === 0 ? 8 : 8 * (objectIndex + 1)
      // if the delete button was pressed it will return
      if(event.target.childNodes[0].getAttribute("d")=="M0 0h16v1H0z"){
        return;
      }
      for (let index = lineStartingIndex; index < lineEndingIndex; index++) {
       
        document.querySelectorAll('.moveable-line')[index].style.opacity = 1
      }
      if (document.querySelectorAll('.moveable-control').length) {
        for (let index = controlStartingIndex; index < controlEndingIndex; index++) {
          document.querySelectorAll('.moveable-control')[index].style.backgroundColor = '#44AAFF'
          document.querySelectorAll('.moveable-control')[index].style.borderColor = '#fff'
        }
      }
      this.setActiveObject(object)
    },
    blurObject () {
      if (this.activeObject) {
        const object = { ...this.activeObject }
        const objectIndex = this.slideQuestionObjects.findIndex(o => o.id === object.id)
        const lineStartingIndex = objectIndex * 4
        const controlStartingIndex = objectIndex * 8
        const lineEndingIndex = objectIndex === 0 ? 4 : 4 * (objectIndex + 1)
        const controlEndingIndex = objectIndex === 0 ? 8 : 8 * (objectIndex + 1)

        for (let index = lineStartingIndex; index < lineEndingIndex; index++) {
          document.querySelectorAll('.moveable-line')[index].style.opacity = 0
        }
        if (document.querySelectorAll('.moveable-control').length) {
          for (let index = controlStartingIndex; index < controlEndingIndex; index++) {
            document.querySelectorAll('.moveable-control')[index].style.backgroundColor = 'transparent'
            document.querySelectorAll('.moveable-control')[index].style.borderColor = 'transparent'
          }
        }
        this.setActiveObject(null)
      }
    },
    removeImage (object) {
      this.removeObject(object.id)
      this.deleteSlideObject(object.id)
    },
    clickedOnContainer (event) {
      if (event.target.className && event.target.className.includes("st-slides")) this.blurObject();
    }
  }
}
</script>
