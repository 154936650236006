var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bx--grid"},[(_vm.showToast)?_c('st-toast-notification',{attrs:{"kind":"info","title":"Info","sub-title":_vm.toastMessage},on:{"click":function($event){_vm.showToast = false}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mobile-header"},[_c('a',{attrs:{"href":"/welcome"}},[_c('icon-wrapper',{staticClass:"mobile-header__spacetrics-icon",attrs:{"icon-name":"spacetrics"}})],1),_vm._v(" "),_c('div',{staticClass:"mobile-header__button"},[_c('st-button',{attrs:{"caption":'Save & Continue',"disabled":_vm.buttonDisabled},on:{"click":_vm.saveAndToHub}})],1)]),_vm._v(" "),_c('div',[_c('div',{staticClass:"bx--col-sm-12 bx--col-xs-12"},[_c('div',{staticClass:"space-between"},[_c('div',[_c('div',{staticClass:"st-edit-survey__back-and-save"},[_c('st-hyperlink',{attrs:{"back-link":true,"text":"Back"},nativeOn:{"click":function($event){return _vm.saveAndToHub.apply(null, arguments)}}}),_vm._v(" "),_c('div',{staticClass:"st-edit-survey__mobile-save"},[(_vm.$store.state.isInlineSpinnerVisible)?_c('st-inline-spinner'):_vm._e(),_vm._v(" "),_c('st-hyperlink',{attrs:{"disabled":_vm.isEditingDisabled,"icon-name":"save","text":"Save"},on:{"click":_vm.saveProgress}})],1)],1),_vm._v(" "),_c('div',{staticClass:"st-edit-survey__header-and-icon"},[_c('icon-wrapper',{staticClass:"st-edit-survey__header-icon",attrs:{"icon-name":"participants"}}),_vm._v(" "),_c('h1',{staticClass:"st-edit-survey__page-header"},[_vm._v("\n              Audience\n            ")])],1),_vm._v(" "),_c('p',{class:{
              'st-page-instructions st-edit-audience__header-spacing': true,
              'st-edit-audience__header-spacing--no-lists': !_vm.lists.length
            }},[_vm._v("\n            "+_vm._s(_vm.instructionsText)+"\n          ")])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"st-edit-survey__save-link-and-button"},[_c('div',{staticClass:"st-edit-survey__inline-spiner-and-save"},[(_vm.$store.state.isInlineSpinnerVisible)?_c('st-inline-spinner',{staticClass:"st-edit-survey__desktop-inline-spinner"}):_vm._e(),_vm._v(" "),_c('st-hyperlink',{attrs:{"disabled":_vm.isEditingDisabled,"icon-name":"save","text":"Save"},on:{"click":_vm.saveProgress}})],1),_vm._v(" "),_c('st-button',{attrs:{"caption":'Save & Continue',"disabled":_vm.buttonDisabled},on:{"click":_vm.saveAndToHub}})],1)])]),_vm._v(" "),_c('div',{staticClass:"st-edit-audience__editable-contents"},[(_vm.lists.length > 0)?_c('div',[_c('st-dropdown-menu',{staticClass:"st-edit-audience__lists-dropdown",attrs:{"caption":"Audience Lists","accordion-contents-values":{
              nestedProperty: 'participants',
              nestedValue: 'email'
            },"active-toggle-disable":_vm.survey.published && !_vm.isEditingDisabled,"display-caption":true,"disable-checkbox":_vm.isEditingDisabled,"full-width":true,"initial-active-options-array":_vm.initialActiveOptionsArray,"options":_vm.lists,"option-caption-fn":function (list) { return list.name; },"placeholder":"Select one or more","select-multiple":true},on:{"updateMultiple":_vm.updateLists},scopedSlots:_vm._u([{key:"accordion-expanded-section",fn:function(ref){
            var options = ref.value;
return [_c('div',{staticClass:"st-dropdown-menu__expanded-accordion-container"},_vm._l((options['participants']),function(participant,index){return _c('p',{key:'participant ' + index},[_vm._v("\n                  "+_vm._s(participant.email)+"\n                ")])}),0)]}}],null,false,2793022266)}),_vm._v(" "),_c('p',{staticClass:"st-edit-audience__additional-instructions"},[_vm._v("\n            You can select an audience list and also add individual emails\n          ")])],1):_vm._e(),_vm._v(" "),_c('st-email-input',{ref:"emailInput",attrs:{"disabled":_vm.isEditingDisabled,"email-area-class":"st-edit-audience__emails-container","invite-text":"survey","label":"Individual Recipients' Emails","existing-members-list":_vm.allAccountParticipants,"non-removable-list":_vm.nonRemovableEmails,"removable-list":_vm.removableEmails,"recipient-text":"Recipients'","variant":"survey-audience"},on:{"addNewEmail":_vm.addNewEmail,"removeEmail":_vm.removeNewEmail}}),_vm._v(" "),_c('div',{staticClass:"st-edit-audience__anonymous-section"},[_c('div',{staticClass:"st-edit-audience__anonymous-instructions"},[_vm._v("\n            Would you like to make this audience anonymous?\n            "),_c('st-tooltip',{staticClass:"st-edit-audience__anonymous-tooltip",attrs:{"direction":_vm.isDesktop ? 'top' : 'left',"max-width":_vm.isDesktop ? 411 : 250,"tip":"Making an audience anonymous means we won’t display any identifiable information in your insights, such as email, first or last name."}},[_c('icon-wrapper',{attrs:{"icon-name":"info"}})],1)],1),_vm._v(" "),_c('st-radio-button',{staticClass:"st-radio-button__default-container",attrs:{"disabled":_vm.survey.published || _vm.isEditingDisabled,"options":[
              { caption: 'Yes', value: true },
              { caption: 'No', value: false }
            ]},model:{value:(_vm.anonymousSurvey),callback:function ($$v) {_vm.anonymousSurvey=$$v},expression:"anonymousSurvey"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }