<template>
  <div class="icon_dont">
    <div class="icon_dont__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-1 0a7 7 0 10-7 7 7 7 0 007-7z" />
        <path
          d="M-21026.5-10160v14.961h-1V-10160z"
          transform="rotate(45 -22774.565 20319.328)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
