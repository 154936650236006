<script>
import { sortIntoXYPairs } from '../utilities/response_utilities'

export default {
  props: {
  },
  data: function () {
    return {
    }
  },
  methods: {
    loadDragElementResponses (maxXValue, maxYValue) {
      let pairs = sortIntoXYPairs(this.questionResponses)
      let dragElementsOptionIds = this.dragElements.map(x => x.optionId)
      // console.log(pairs)
      pairs.forEach(pair => {
        this.$refs[pair.optionId][0].updateX(this.minX + Number(pair.xValue) * maxXValue)
        this.$refs[pair.optionId][0].updateY(this.minY + (1 - Number(pair.yValue)) * maxYValue)
        let dragIdx = dragElementsOptionIds.indexOf(pair.optionId)
        this.dragElements[dragIdx].xScore = Number(pair.xValue)
        this.dragElements[dragIdx].yScore = Number(pair.yValue)
        this.dragElements[dragIdx].placed = true
      })
    }
  }
}
</script>
