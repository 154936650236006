<template>
  <div>
    <div
      class="drag-container__element-preference-container"
      :style="previewStyle"
    >
      <div
        :style="style"
        class="element-preferences"
      >
        <div class="no-element-preference">
          I don’t like any element
        </div>
      </div>
      <div class="drag-container__elements">
        <ds-drag-element
          v-for="(dragElement, idx) in dragElements"
          :id="dragElement.optionId.toString()"
          :ref="dragElement.optionId"
          :key="dragElement.optionId"
          :initial-top="maxY + 40"
          :initial-left="initialLeft(idx, dragElements.length, -35, maxX + 35)"
          :initial-radius="dragElement.radius"
          :max-x="maxX"
          :max-y="maxY"
          @endPosition="finalPosition($event, idx)"
        />
      </div>
    </div>
    <div
      :class="{
        'drag-container__submit': true,
        'drag-container__submit-preview': isSurveyPreview,
      }"
    >
      <cv-button
        v-if="isSurveyPreview && priority > 0"
        kind="secondary"
        class="st-question__preview-back-btn"
        @click="handlePreviewBack"
      >
        <div class="st-page__btn-slot">
          <div class="st-svg__left-chevron" />
          <div>Back</div>
        </div>
      </cv-button>
      <cv-button
        v-if="submit"
        class="next-btn"
        @click="submitQuestionAnswer"
      >
        <div>
          Next
          <div class="btn-icon" />
        </div>
      </cv-button>
    </div>
  </div>
</template>

<script>
import DragElement from '../../common/drag-element'
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import previousResponse from '../../../mixins/previousResponse.vue'
import loadDragElementResponses from '../../../mixins/loadDragElementResponses'
import submitQuestionAnswer from '../../../mixins/question/submitQuestionAnswer.vue'
import initialLeft from '../../../mixins/initialLeft.vue'
import handlePreviewBack from '../../../mixins/handlePreviewBack.vue'

export default {
  components: {
    'ds-drag-element': DragElement,
    'cv-button': CvButton
  },
  mixins: [submitQuestionAnswer, initialLeft, handlePreviewBack, previousResponse, loadDragElementResponses],
  props: {
    options: {
      type: Array,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    priority: {
      type: Number,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      dragElements: this.options.map((x, idx) => {
        return {
          name: x.name,
          optionId: x.optionId,
          priority: x.priority,
          placed: false,
          radius: 20,
          x: null,
          xScore: null,
          y: null,
          yScore: null
        }
      }),
      minX: 0,
      minY: 0
    }
  },
  computed: {
    submit: function () {
      if (this.isSurveyPreview) { return true }
      let placedItems = 0
      this.dragElements.forEach(x => {
        if (x.placed) {
          placedItems++
        }
      })
      if (placedItems === this.dragElements.length) {
        return true
      } else {
        return false
      }
    },
    style: function () {
      return {
        background: `url("${this.imageUrl}") center no-repeat`,
        backgroundSize: '100% 100%',
        height: this.maxY + 'px',
        width: this.maxX + 'px',
        margin: 'auto'
      }
    },
    previewStyle: function () {
      if (this.isPreview) {
        return {
          height: '251px',
          width: '251px',
          margin: 'auto'
        }
      }
      return {}
    },
    isNormalWidth: function () { // iphone 6/7/8 and larger
      return window.innerWidth > 350
    },
    maxX: function () {
      if (this.isPreview) { return 251 }
      if (this.isNormalWidth) { return 292 }
      return 251
    },
    maxXDots: function () {
      if (this.isPreview) { return 251 }
      if (this.isNormalWidth) { return 355 }
      return window.innerWidth
    },
    maxY: function () {
      if (this.isPreview) { return 251 }
      if (this.isNormalWidth) { return 292 }
      return 251
    }
  },
  methods: {
    loadResponses () {
      if (this.questionResponses && this.questionResponses.length > 0) {
        this.$nextTick(() => {
          this.loadDragElementResponses(this.maxX, this.maxY)
        })
      }
    },
    answerResponse () {
      let answer = { options: {} }
      this.dragElements.forEach(x => {
        answer.options[x.optionId] = [x.xScore, x.yScore]
      })
      return answer
    },
    finalPosition (data, idx) {
      this.dragElements[idx].x = data.x
      this.dragElements[idx].y = data.y
      this.dragElements[idx].yScore = 1 - this.axisScore(data.y, 0, this.maxY)
      this.dragElements[idx].xScore = this.axisScore(data.x, 0, this.maxX)
      this.dragElements[idx].placed = data.placed
    },
    axisScore (val, min, max) {
      if (val < min) {
        return 0
      } else if (val > max) {
        return 1
      } else {
        let distFromMin = val - min
        let totalDistance = max - min
        let score = distFromMin / totalDistance
        return score
      }
    }
  }
}
</script>
