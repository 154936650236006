<template>
  <div>
    <st-toast-notification
      v-if="showPdfErrorToast"
      class="st-toast-notification--fixed"
      :caption="`There was an error creating a PDF for '${surveyName}'`"
      kind="error"
      title="Error"
      @click="showPdfErrorToast = false"
    />
    <st-toast-notification
      v-if="showPdfSuccessToast"
      class="st-toast-notification--fixed"
      :caption="`You will soon receive an email with PDF link for '${surveyName}'`"
      kind="success"
      title="Success"
      @click="showPdfSuccessToast = false"
    />
    <st-toast-notification
      v-if="showCSVSuccessToast"
      class="st-toast-notification--fixed"
      :caption="`You will soon receive an email with CSV link for '${surveyName}'`"
      kind="success"
      title="Success"
      @click="showCSVSuccessToast = false"
    />
    <div
      v-if="!tourMode"
      class="st-survey-insights__top-header"
    >
      <div class="st-survey-insights__row">
        <a
          href="/welcome"
          class="st-survey-insights__hide-mobile"
        >
          <icon-wrapper
            class="st-survey-insights__top-header-logo"
            icon-name="spacetrics"
          />
        </a>
        <a
          href="#"
          class="st-survey-insights__back-icon"
          @click="$goBack"
          @mouseenter="backHover=true"
          @mouseleave="backHover=false"
        >
          <icon-wrapper
            icon-name="chevron"
            :class="{
              'st-hyperlink__back-icon': true,
              'st-hyperlink__back-icon--hover': backHover
            }"
          />
        </a>
        <div class="st-survey-insights__top-header-title">
          {{ surveyName }}
        </div>
      </div>
      <div class="st-survey-insights__row st-survey-insights__hide-mobile">
        <!-- <div class="st-survey-insights__row st-survey-insights__top-header-link">
          <icon-wrapper
            class="st-survey-insights__link-icon"
            icon-name="search"
            :neutral="true"
          />
          <div>Search</div>
        </div> -->
        <st-overflow-menu
          class="st-survey-insights__top-header-overflow-link st-survey-insights__hide-mobile"
          :flip-menu="true"
          :fixed-page-position="true"
          vertical-position="top"
        >
          <template v-slot:open-menu-element>
            <div class="st-survey-insights__row st-survey-insights__top-header-link">
              <icon-wrapper
                class="st-survey-insights__link-icon"
                icon-name="help"
                :neutral="true"
              />
              <div>Help</div>
            </div>
          </template>
          <template v-slot:default>
            <st-overflow-menu-item
              icon-name="faq"
              text="FAQ"
              path="/help/faq"
            />
            <st-overflow-menu-item
              icon-name="tutorial"
              text="Tutorials"
              path="/help/tutorials"
            />
            <st-overflow-menu-item
              id="Intercom"
              icon-name="chat"
              text="Chat with us"
              path="javascript:void(o)"
            />
          </template>
        </st-overflow-menu>
        <div class="st-survey-insights__header-avatar-container st-survey-insights__hide-mobile">
          <st-overflow-menu
            :flip-menu="true"
            vertical-position="top"
            :fixed-page-position="true"
          >
            <template v-slot:open-menu-element>
              <participant-avatar
                class="st-survey-insights__header-avatar"
                :participant="currentUser"
              />
            </template>

            <template v-slot:default>
              <st-overflow-menu-item
                v-if="currentUser.kind === 'spacetrics_admin'"
                text="Admin"
                path="/admin"
              />
              <st-overflow-menu-item
                icon-name="profile"
                text="Profile"
                :path="'/profile'"
              />
              <st-overflow-menu-item
                icon-name="company"
                text="Company Info"
                path="/company_info"
              />
              <!-- <st-overflow-menu-item
                    icon-name="preferences"
                    text="Preferences"
                  />
                  <st-overflow-menu-item
                    icon-name="billing"
                    text="Billing"
                  />
                  <st-overflow-menu-item
                    icon-name="settings"
                    text="Settings"
                  /> -->
              <st-overflow-menu-item
                icon-name="sign-out"
                text="Sign Out"
                @mouseup="$root.signOut('../users/sign_out')"
              />
            </template>
          </st-overflow-menu>
        </div>
      </div>
    </div>
    <!-- main section -->
    <div
      :class="{
        'st-survey-insights__grid-main': !activePanelSection,
        'st-survey-insights__grid-main st-survey-insights__grid-main--open': activePanelSection
      }"
    >
      <div class="st-survey-insights__active-question-header">
        <div class="st-survey-insights__row">
          <icon-wrapper
            :icon-name="activeQuestionIconName"
            class="st-survey-insights__link-icon"
            :neutral="true"
          />
          <div class="st-survey-insights__active-question-type">
            {{ activeQuestionTypeString }}
          </div>
          <div class="st-survey-insights__active-question-indicator">
            ({{ activeQuestionIndex + 1 }}/{{ surveyQuestions.length }})
          </div>
          <div
            v-if="activeQuestionIndex !== 0"
            @click="incrementQuestionIndex(-1)"
          >
            <icon-wrapper
              icon-name="previous"
              class="st-survey-insights__toggle-question-button"
            />
          </div>
          <div
            v-if="activeQuestionIndex !== surveyQuestions.length - 1"
            @click="incrementQuestionIndex(+1)"
          >
            <icon-wrapper
              icon-name="next"
              class="st-survey-insights__toggle-question-button"
            />
          </div>
        </div>
        <div class="st-survey-insights__active-question-description-row">
          <div class="st-survey-insights__active-question-description">
            {{ questionText }}
          </div>

          <div
            v-if="activeQuestionType==='virtual_tour'"
            class="st-virtual-tour-insights__open-tour-mode st-survey-insights__hide-mobile"
            @click="openTourMode"
          >
            <div>Open Tour Mode </div>
            <icon-wrapper
              icon-name="tour-mode"
              class="st-virtual-tour-insights__tour-mode-icon"
            />
          </div>
        </div>
        <div class="st-survey-insights__tags">
          <template
            v-for="(segment,i) in mappedSegmentTagsToDisplay"
          >
            <template v-if="segment.anyUnchecked">
              <template
                v-for="(option,n) in segment.options"
              >
                <div
                  v-if="option.selected"
                  :key="`tag-${i}-${n}`"
                  class="st-survey-insights__tag"
                  @click="toggleSegmentOption(i,n)"
                >
                  <div>{{ option.name }}</div>
                  <icon-wrapper
                    icon-name="cancel"
                    class="st-survey-insights__tag-cancel"
                  />
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
      <div
        :class="{
          'st-survey-insights__question-insights-section': true,
          'st-survey-insights__question-insights-section--empty': activeQuestionResponses.length === 0
        }"
      >
        <div
          v-if="activeQuestionResponses.length === 0"
          class="st-survey-insights-empty__content-wrapper"
        >
          <icon-wrapper
            class="st-survey-insights-empty__icon"
            icon-name="speech-bubbles"
          />
          <p
            class="st-survey-insights-empty__text"
          >
            There are currently no responses for this question.
          </p>
          <p
            class="st-survey-insights-empty__text"
          >
            Please visit again later.
          </p>
        </div>
        <component
          :is="activeQuestionComponent"
          v-else
          :key="'q-'+ activeQuestionId"
          @startSpinner="isLoading = true"
          @stopSpinner="isLoading = false"
        />
      </div>
    </div>
    <div
      v-if="activePanelSection"
      class="st-survey-insights__mobile-panel-open-screen"
      @click="togglePanel(null)"
    />
    <div class="st-survey-insights__mobile-panel-open-group">
      <div
        class="st-survey-insights__buttons-header st-survey-insights__row"
        :class="{'st-survey-insights__buttons-header-panel-open': activePanelSection }"
      >
        <div
          :class="{
            'st-survey-insights__header-button':true,
            'st-survey-insights__header-button--active': activePanelSection ==='questions'
          }"
          @click="togglePanel('questions')"
        >
          <icon-wrapper
            icon-name="questions"
            class="st-survey-insights__link-icon"
            :neutral="true"
          />
          <div class="st-survey-insights__panel-link-text">
            Questions
          </div>
        </div>
        <div
          :class="{
            'st-survey-insights__header-button':true,
            'st-survey-insights__header-button--active': activePanelSection ==='segments'
          }"
          @click="togglePanel('segments')"
        >
          <icon-wrapper
            icon-name="segment"
            class="st-survey-insights__link-icon"
            :neutral="true"
          />
          <div class="st-survey-insights__panel-link-text">
            Segments
          </div>
        </div>
        <div
          :class="{
            'st-survey-insights__header-button':true,
            'st-survey-insights__header-button--active': activePanelSection ==='modifiers'
          }"
          @click="togglePanel('modifiers')"
        >
          <icon-wrapper
            icon-name="modify"
            class="st-survey-insights__link-icon"
            :neutral="true"
          />
          <div class="st-survey-insights__panel-link-text">
            Modifiers
          </div>
        </div>
        <!-- <div
          :class="{
            'st-survey-insights__hide-mobile': true,
            'st-survey-insights__header-button':true,
            'st-survey-insights__header-button--active': activePanelSection ==='notes'
          }"
          @click="togglePanel('notes')"
        >
          <icon-wrapper
            icon-name="notes"
            class="st-survey-insights__link-icon"
            :neutral="true"
          />
          <div>Notes</div>
        </div> -->
        <div
          :class="{
            'st-survey-insights__hide-mobile': true,
            'st-survey-insights__header-button':true,
            'st-survey-insights__header-button--active': activePanelSection ==='export'
          }"
          @click="togglePanel('export')"
        >
          <icon-wrapper
            icon-name="export"
            class="st-survey-insights__link-icon"
            :neutral="true"
          />
          <div>Export</div>
        </div>
      </div>
      <div
        v-if="activePanelSection"
        :class="{
          'st-survey-insights__grid-panel': true,
          'st-survey-insights__grid--panel-open': activePanelSection !== null,
          'st-survey-insights__grid--panel-closed': activePanelSection === null
        }"
      >
        <div
          class="st-survey-insights__grid-panel-scroll-area"
        >
          <div class="st-survey-insights__grid-panel-scroll-container">
            <notes-panel
              v-if="activePanelSection==='notes'"
              :notes="[]"
            />
            <questions-panel
              v-else-if="activePanelSection==='questions'"
            />
            <segments-panel
              v-else-if="activePanelSection==='segments'"
            />
            <modifiers-panel
              v-else-if="activePanelSection==='modifiers'"
            />
            <export-panel
              v-else-if="activePanelSection==='export'"
              :survey="survey"
              @showPdfErrorToast="showPdfErrorToast = true"
              @showPdfSuccessToast="showPdfSuccessToast = true"
              @showCSVSuccessToast="showCSVSuccessToast = true"
            />
          </div>
        </div>
      </div>
    </div>
    <tour-mode v-if="tourMode && activeQuestionType === 'virtual_tour'" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { kebabCase, startCase } from 'lodash'
import { IconWrapper, StOverflowMenu, StOverflowMenuItem, StToastNotification } from '../../common'
import { ModifiersPanel, NotesPanel, QuestionsPanel, SegmentsPanel, ExportPanel } from './insights_layout'
import TourMode from './tour-mode'
import * as QuestionTypes from './question_types'
import { ParticipantAvatar } from './index'

export default {
  components: {
    IconWrapper,
    ExportPanel,
    ModifiersPanel,
    NotesPanel,
    QuestionsPanel,
    SegmentsPanel,
    ParticipantAvatar,
    'tour-mode': TourMode,
    StOverflowMenu,
    StOverflowMenuItem,
    StToastNotification,
    ...QuestionTypes
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    surveyName: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      backHover: false,
      showPdfErrorToast: false,
      showPdfSuccessToast: false,
      showCSVSuccessToast: false
    }
  },
  
  computed: {
    ...mapState(['currentUser']),
    ...mapState('insights', ['activePanelSection', 'activeQuestionIndex', 'activeQuestionView', 'surveyQuestions', 'tourMode']),
    ...mapGetters('insights', ['activeQuestion', 'activeQuestionResponses', 'findResponse', 'mappedSegmentTagsToDisplay']),
    activeQuestionComponent () {
      return kebabCase(this.activeQuestion.questionType)
    },
    activeQuestionIconName () {
      if (this.activeQuestionType === 'culture_board') {
        return 'image-board'
      } else if (this.activeQuestionType === 'favorite_choice') {
        return 'check-mark'
      } else if (this.activeQuestionIndex === null) {
        return 'arrow'
      } else {
        return kebabCase(this.activeQuestionType)
      }
    },
    activeQuestionId () {
      return this.activeQuestionIndex ? this.surveyQuestions[this.activeQuestionIndex].id : -1
    },
    activeQuestionType () {
      return this.activeQuestion.questionType || 'Select a Question'
    },
    activeQuestionTypeString () {
      return this.activeQuestionType === 'culture_board' ? 'Image Board' : startCase(kebabCase(this.activeQuestionType))
    },
    avatarStyle () {
      if (this.currentUser.avatarUrl) {
        return {
          background: `url('${this.currentUser.avatarUrl}') center no-repeat`,
          backgroundSize: 'cover'
        }
      } else {
        return -1
      }
    },
    questionText () {
      return this.activeQuestion ? this.activeQuestion.description : ''
    }
  },
  created () {
    let segments = this.buildSegmentsArray()
    this.$store.commit('insights/replaceSegments', segments)
  },
  beforeMount () {
    let url = new URL(window.location)
    let activeQuestionId = url.searchParams.get('question')
    this.$store.commit('insights/replaceSurveyQuestions', this.questions.filter(q => q.questionType !== 'slide')) // TODO: remove filter if slide questions needs to be visible in insights
    this.$store.commit('insights/setInitialSelectedSurveyResponsIds', this.survey.responseIds || [])

    this.$nextTick(() => {
      if (activeQuestionId != null) {
        this.$store.dispatch('insights/setActiveFromUrl')
      } else {
        this.$store.dispatch('insights/setActive', 0)
      }
    })

    this.$store.commit('hideSidebar', true)
    this.$store.commit('insights/setInitialResponses')
  },
  mounted: function () {
    window.addEventListener('popstate', this.reloadInsightsMode)
  },
  methods: {
    ...mapMutations('insights', ['togglePanel']),
    buildSegmentsArray () {
      return this.survey.segments.map(segment => {
        return {
          name: segment.name,
          segmentId: segment.id,
          expanded: false,
          options: segment.options.map(option => {
            return { name: option.name, id: option.id, segmentId: option.segmentId, selected: true }
          })
        }
      })
    },
    incrementQuestionIndex (i) {
      this.$store.dispatch('insights/setActive', this.activeQuestionIndex + i)
    },
    openTourMode () {
      // open tour mode
      this.$store.commit('insights/toggleTourMode', true)
      // add to url
      let url = new URL(window.location)
      let questionId = url.searchParams.get('question')
      let title = ''
      let newState = {}
      title = 'tour mode'
      newState.mode = 'tour'
      url = `?question=${questionId}&mode=tour`
      window.history.pushState(newState, title, url)
    },
    reloadInsightsMode () {
      switch (window.history.state.mode) {
        case 'normal':
          this.$store.commit('insights/replaceView', 'Results')
          break
        case 'tour-mode':
          // set tour mode to true
          break
        case 'transcript':
          if (window.history.state.responseId) {
            const response = this.findResponse(Number(window.history.state.responseId))
            this.$store.dispatch('openSingleTranscript', response.surveyResponseId)
          } else {
            this.$store.commit('insights/replaceView', 'Transcripts')
          }
      }
    }
  }
}
</script>
