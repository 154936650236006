<template>
  <div class="culture-board">
    <div
      class="culture-board__tiles"
    >
      <div
        v-for="image in options"
        :key="image.optionId"
        :style="{
          background: backgroundProp(image.imageUrl),
          backgroundSize: 'cover'
        }"
        :class="{
          'culture-board__tile': true,
          'culture-board__tile--2-col': options.length < 6,
          'culture-board__tile--3-col': options.length > 5,
          'is-selected': selectedId == image.optionId
        }"
        @click="handleSelect(image.optionId)"
        @touch="handleSelect(image.optionId)"
      />
    </div>
    <div class="drag-container__submit">
      <cv-button
        v-if="isSurveyPreview && priority > 0"
        kind="secondary"
        class="st-question__preview-back-btn"
        @click="handlePreviewBack"
      >
        <div class="st-page__btn-slot">
          <div class="st-svg__left-chevron" />
          <div>Back</div>
        </div>
      </cv-button>
      <cv-button
        v-if="selectedId != null || isSurveyPreview"
        class="next-btn"
        @click="submitQuestionAnswer"
      >
        <div>
          Next
          <div class="btn-icon" />
        </div>
      </cv-button>
    </div>
  </div>
</template>

<script>
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import handleSelect from '../../../mixins/handleSelect'
import handlePreviewBack from '../../../mixins/handlePreviewBack.vue'
import previousResponse from '../../../mixins/previousResponse.vue'
import submitQuestionAnswer from '../../../mixins/question/submitQuestionAnswer.vue'

export default {
  components: {
    'cv-button': CvButton
  },
  mixins: [handleSelect, submitQuestionAnswer, handlePreviewBack, previousResponse],
  props: {
    options: {
      type: Array,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    priority: {
      type: Number,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    loadResponses () {
      if (this.questionResponses.length > 0) {
        this.selectedId = Number(this.questionResponses[0].value)
      }
    },
    backgroundProp (path) {
      return `url('${path}') center no-repeat`
    },
    answerResponse () {
      return { value: this.selectedId }
    }
  }
}
</script>
