var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-image-board__mobile-body st-image-board__image-body-participant"},[_c('p',{ref:"question",staticClass:"st-participant__question-container-para"},[_vm._v("\n    "+_vm._s(_vm.description)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"bx--grid st-image-board__mobile-body-grid"},[_c('div',{staticClass:"bx--row st-image-board__mobile-body-row"},_vm._l((_vm.options),function(option){return _c('div',{key:option.id,staticClass:"bx--col-xs-4"},[_c('span',{on:{"click":function($event){return _vm.selectBoard(option)}}},[_c('st-lazy-image',{attrs:{"src":option.mediaUrl,"background":"#eee","custom-class":_vm.selectedBoard && option.id === _vm.selectedBoard.id ? 'st-image-board__img st-image-board__img--active': 'st-image-board__img'}})],1)])}),0),_vm._v(" "),(_vm.showExplanationInput && _vm.askForExplanation === 'text')?_c('div',[_c('st-text-area',{class:{
          'st-image-board__text-area': true
        },attrs:{"button":_vm.textAreaFocused,"label":"Share your thoughts here","placeholder":"Type your response"},on:{"focus":_vm.focusTextArea,"focusout":_vm.focusOutTextArea},model:{value:(_vm.writtenResponse),callback:function ($$v) {_vm.writtenResponse=$$v},expression:"writtenResponse"}}),_vm._v(" "),(_vm.nextButtonTextArea)?_c('st-tooltip',{staticClass:"st-opinion-metric__button-tooltip",attrs:{"tip":"Preview only button","is-active":_vm.isSpecificationsPreview}},[_c('st-button',{staticClass:"st-opinion-metric__button st-opinion-metric__next-question-button",attrs:{"caption":_vm.buttonCaption,"disabled":!_vm.writtenResponse.length,"icon-name":"arrow","underline":false,"variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1):_vm._e()],1):(
        _vm.showExplanationInput && _vm.askForExplanation === 'voice_recording'
      )?_c('div',[(_vm.isRecordButton)?_c('st-button',{class:{
          'st-opinion-metric__button st-opinion-metric__record-thoughts-button': true,
        },attrs:{"caption":"Record My Thoughts","icon-name":"microphone","underline":false,"variant":['secondary']},on:{"click":_vm.initiateRecording}}):(_vm.isStopButton)?_c('div',{class:{
          'st-opinion-metric__stop-button-and-visualizer': true,
        }},[_c('button',{staticClass:"st-opinion-metric__audio-button-container",attrs:{"type":"button"},on:{"click":_vm.stopRecording}},[_c('div',{staticClass:"st-opinion-metric__stop-square"})]),_vm._v(" "),(_vm.isVisualizerVisible)?_c('canvas',{ref:"audioCanvas",class:{
            'st-opinion-metric__audio-visualizer': true,
            'st-opinion-metric__audio-visualizer--preview': _vm.isSpecsDesktopPreview,
          },attrs:{"width":_vm.canvasWidth,"height":_vm.canvasHeight}}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.isAudioPlayer)?_c('audio',{ref:"audioPlayer",staticClass:"st-opinion-metric__audio-player",attrs:{"controls":""}},[_vm._v("\n        Your browser does not support the "),_c('code',[_vm._v("audio")]),_vm._v(" element.\n      ")]):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('div',{class:{
          'st-opinion-metric__ready-to-submit': true,
          'st-opinion-metric__record-thoughts--multi-media': _vm.hasMultipleMedia && !_vm.isSpecsDesktopPreview && _vm.isDesktop,
          'st-opinion-metric__record-thoughts--multi-media-no-description': _vm.hasMultipleMedia && _vm.isDesktopSurvey && !_vm.description,
          'st-opinion-metric__record-thoughts--multi-media-desktop-preview': _vm.isMultiMediaDesktopSurveyPreview
        }},[(_vm.readyToSubmit && !_vm.replayAudio)?_c('button',{staticClass:"st-opinion-metric__audio-button-container",attrs:{"type":"button"},on:{"click":_vm.playAgain}},[_c('icon-wrapper',{staticClass:"st-opinion-metric__play-button",attrs:{"icon-name":"play-button","spacetrics-blue":true}})],1):_vm._e(),_vm._v(" "),(_vm.readyToSubmit && _vm.replayAudio)?_c('button',{staticClass:"st-opinion-metric__audio-button-container",attrs:{"type":"button"},on:{"click":_vm.stopReplay}},[_c('div',{staticClass:"st-opinion-metric__pause-bars"},[_vm._v("\n            ▌\n          ")]),_vm._v(" "),_c('div',{staticClass:"st-opinion-metric__pause-bars st-opinion-metric__pause-bars--right"},[_vm._v("\n            ▌\n          ")])]):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('p',{staticClass:"st-opinion-metric__audio-duration"},[_vm._v("\n          "+_vm._s(_vm.formatAudioDuration)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('div',{staticClass:"st-opinion-metric__trash-icon-container",on:{"click":_vm.rerecord}},[_c('icon-wrapper',{staticClass:"st-opinion-metric__trash-icon",attrs:{"icon-name":"trash"}})],1):_vm._e(),_vm._v(" "),(_vm.readyToSubmit)?_c('st-tooltip',{staticClass:"st-opinion-metric__button-tooltip",attrs:{"tip":"Preview only button","is-active":_vm.isSpecificationsPreview}},[_c('st-button',{staticClass:"st-opinion-metric__button",attrs:{"caption":_vm.buttonCaption,"icon-name":"arrow","underline":false,"variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1):_vm._e()],1):_vm._e()],1):_c('div',{staticClass:"st-image-board__btn-canvas"},[_c('st-button',{staticClass:"st-image-board__btn st-button--small",attrs:{"disabled":_vm.selectedBoard === null,"caption":_vm.buttonCaption,"underline":false,"icon-name":"arrow","variant":['secondary']},on:{"click":_vm.submitQuestionAnswer}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }