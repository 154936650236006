<template>
  <div class="icon_content">
    <div class="icon_content__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M1-1h6a2 2 0 012 2v.991c0 .446-3.161.079-3.161.8 0 1.636-.11 5.684-.109 7.516 0 .432 3.274.256 3.274.256v.357s-.023 2.446-.1 2.678A2 2 0 017.6 14.9c-.421.132-.29.092-6.6.092a2 2 0 01-2-2V1a2 2 0 012-2zM0 1v12a1 1 0 001 1h6a1 1 0 001-1v-2.441l-2.787-.393.251-7.506L8 2.407V1a1 1 0 00-1-1H1a1 1 0 00-1 1z"
            transform="translate(1 1)"
          />
          <path
            d="M0 1a1 1 0 011-1h8a1 1 0 011 1v7a1 1 0 01-1 1H1a1 1 0 01-1-1zm9 7V1H1v7z"
            transform="translate(6 2.999)"
          />
          <path
            d="M0 1a1 1 0 011-1h2a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1zm3 1V1H1v1z"
            transform="translate(8 4.998)"
          />
          <path
            d="M-1139.731 3242.854h5.549v1h-5.549z"
            transform="translate(1148 -3234.003)"
          />
          <path
            d="M-1144.317 3269.172a.5.5 0 01-.5-.5.5.5 0 01.5-.5h2.286a.5.5 0 01.5.5.5.5 0 01-.5.5z"
            transform="translate(1148 -3255.004)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
