var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-dropzone': true,
    'st-dropzone--active': _vm.active,
    'st-dropzone--hover': _vm.uploadViaDrop,
  },attrs:{"tabindex":"0"},on:{"dragover":function($event){_vm.uploadViaDrop = true},"focus":function($event){_vm.uploadViaDrop = true},"focusout":function($event){_vm.active = false, _vm.uploadViaDrop = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.dropzone.click()},"mouseleave":function($event){_vm.uploadViaDrop = false},"mouseenter":function($event){_vm.uploadViaDrop = true}}},[(_vm.uploadViaDrop)?_c('input',{ref:"dropzone",staticClass:"st-dropzone__input",attrs:{"id":"'st-dropzone'","multiple":_vm.multiple,"type":"file","name":_vm.name,"accept":_vm.accept},on:{"input":_vm.onInput,"click":function($event){_vm.active = true},"dragleave":function($event){_vm.uploadViaDrop = false},"drop":function($event){_vm.active = true},"focusout":function($event){_vm.active = false}}}):_vm._e(),_vm._v(" "),(!_vm.active)?_c('icon-wrapper',{class:{
      'st-dropzone__icon': true,
      'st-dropzone__icon--hovered': _vm.uploadViaDrop
    },attrs:{"icon-name":'upload'}}):_c('icon-wrapper',{staticClass:"st-dropzone__icon st-dropzone__icon--active",attrs:{"icon-name":'check-mark'}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade-text"}},[(!_vm.uploadViaDrop && !_vm.active)?_c('div',{staticClass:"st-dropzone__center-text"},[_c('p',{staticClass:"st-dropzone__drag-instructions-text"},[_c('span',{staticClass:"st-dropzone__drag-instruction"},[_vm._v("Drag files here, or")]),_vm._v(" "),_c('label',{staticClass:"st-dropzone__drag-instructions-label"},[_vm._v("\n          click to browse\n        ")])]),_vm._v(" "),_c('p',{staticClass:"st-dropzone__drag-instructions-help-text"},[_vm._v("\n        "+_vm._s(_vm.helpText)+"\n      ")]),_vm._v(" "),(false)?_c('p',{staticClass:"st-dropzone__drag-instructions-help-text st-dropzone__drag-instructions-help-text--video"},[_vm._v("\n        For video files upload only .mp4 files of 500mb max file size.\n      ")]):_vm._e()]):_vm._e()]),_vm._v(" "),(!_vm.uploadViaDrop && !_vm.active && _vm.displayWarning)?_c('span',{staticClass:"warning"},[_vm._v(" Image board can have maximum 12 images. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }