<template>
  <div
    tabindex="0"
    :class="{
      'st-dropzone': true,
      'st-dropzone--active': active,
      'st-dropzone--hover': uploadViaDrop,
    }"
    @dragover="uploadViaDrop = true"
    @focus="uploadViaDrop = true"
    @focusout="active = false, uploadViaDrop = false"
    @keyup.enter="$refs.dropzone.click()"
    @mouseleave="uploadViaDrop = false"
    @mouseenter="uploadViaDrop = true"
  >
 
    <input
      v-if="uploadViaDrop"
      id="'st-dropzone'"
      ref="dropzone"
      class="st-dropzone__input"
      :multiple="multiple"
      type="file"
      :name="name"
      :accept="accept"
      
      @input="onInput"
      @click="active = true"
      @dragleave="uploadViaDrop = false"
      @drop="active = true"
      @focusout="active = false"
    >
    <icon-wrapper
      v-if="!active"
      :class="{
        'st-dropzone__icon': true,
        'st-dropzone__icon--hovered': uploadViaDrop
      }"
      :icon-name="'upload'"
    />
    <icon-wrapper
      v-else
      class="st-dropzone__icon st-dropzone__icon--active"
      :icon-name="'check-mark'"
    />
    <transition
      name="fade-text"
    >
      <div
        v-if="!uploadViaDrop && !active"
        class="st-dropzone__center-text"
      >
        <p class="st-dropzone__drag-instructions-text">
          <span class="st-dropzone__drag-instruction">Drag files here, or</span>
          <label
            class="st-dropzone__drag-instructions-label"
          >
            click to browse
          </label>
        </p>
        <p class="st-dropzone__drag-instructions-help-text">
          {{ helpText }}
        </p>
        <p
          v-if="false"
          class="st-dropzone__drag-instructions-help-text st-dropzone__drag-instructions-help-text--video"
        >
          For video files upload only .mp4 files of 500mb max file size.
        </p>
         
      </div>
    </transition>
    <span class="warning"  v-if="!uploadViaDrop && !active && displayWarning"> Image board can have maximum 12 images. </span>
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'

import uid from '../../mixins/uid'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [uid],
  model: {
    prop: 'files'
  },
  props: {
    activeQuestion: {
      type:Object,
      required: false,
      default: {}
    },
    /**
      * Type of images available for upload.
    */
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png',
      required: false
    },
    /**
      * Instructive text for file uploading.
    */
    helpText: {
      type: String,
      default: 'Only .jpg, .jpeg, and .png files. 500kb max file size.',
      required: false
    },
    /**
      * Used to allow the user multiple files
    */
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * name attribute optionally passed to the file input
     */
    name: {
      type: String,
      default: 'file',
      required: false
    }
  },

  data () {
    return {
      active: false,
      imageTypes: ['image/png', 'image/jpg', 'image/jpeg'],
      uploadViaDrop: false,
      displayWarning: false,
    }
  },
  computed: {
    acceptedTypesOfFiles () {
      return this.accept.split(',').map(s => s.trim())
    }
  },
  
  methods: {
    onInput (ev) {
      
      if (this.validateFiles(ev)) {
        this.$emit('input', ev.target.files)
      } else {
        // TODO: show error notification
      }
    },
    validateFiles (_ev) {
     
      if(this.activeQuestion.questionType== "culture_board" && (this.activeQuestion.options.length + _ev.target.files.length )>12){
        this.displayWarning = true;
        return false;
      }
      // TODO: this is actually a little hard because it's not just a pure string match
      return true
    }
  }
}
</script>
<style scoped>
.warning{
  margin-top:30px;
  color:red;
}
</style>