<template>
  <div
    :class="{
      'st-virtual-tour-specs__phone-preview': true,
      'st-virtual-tour-specs__phone-preview--blocked': blockPreview
    }"
  >
    <matterport-iframe
      v-if="matterportId"
      ref="virtualTourPreview"
      class="st-virtual-tour-specs__phone-preview-embed"
      :matterport-id="matterportId"
      @matterport-showcase-loaded="handleShowcaseLoad"
    />
    <div
      v-if="matterportId"
      class="st-virtual-tour-specs__phone-wave-container"
    >
      <div class="st-virtual-tour-specs__phone-wave" />
    </div>
    <template v-if="activeViewpoint">
      <div
        v-if="activeViewpoint.editPrompt"
        class="st-virtual-tour-specs__prompt-text-box"
      >
        <div class="st-virtual-tour-specs__prompt-text-arrow" />
        <div
          class="st-virtual-tour-specs__phone-prompt-text"
        >
          . . .
        </div>
      </div>
      <div
        v-else-if="activeViewpoint.prompt"
        class="st-virtual-tour-specs__prompt-text-box"
      >
        <div class="st-virtual-tour-specs__prompt-text-arrow" />
        <div
          class="st-virtual-tour-specs__phone-prompt-text"
        >
          {{ activeViewpoint.prompt }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import MatterportIframe from '../../../matterport/matterport-iframe'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    'matterport-iframe': MatterportIframe
  },
  props: {
    matterportId: {
      required: true
    }
  },
  data () {
    return {
      blockPreview: false,
      showcase: null
    }
  },
  computed: {
    ...mapState('specifications', ['assignedViewpoints', 'activeViewpointIdentifier']),
    ...mapGetters('specifications', ['activeQuestion', 'activeViewpoint'])
  },
  methods: {
    handleShowcaseLoad (sdk) {
      this.showcase = sdk
      this.$emit('matterport-showcase-loaded', sdk)
      this.$store.commit('specifications/setMoveVirtualTour', this.moveToSweep)

      if (this.activeQuestion.initialViewpoint) { return }

      this.blockPreview = true
      this.$store.commit('toggleFullSpinner', true)
      sdk.on(sdk.Sweep.Event.EXIT, (identifier) => this.useActiveViewpoint(identifier))
    },
    useActiveViewpoint (identifier) {
      if (this.activeQuestion.initialViewpoint) { return }

      this.saveInitialActiveViewpoint(identifier)
      this.blockPreview = false
    },
    moveToSweep (identifier) {
      this.showcase.Sweep.moveTo(identifier)
        .then(res => { console.log(res) })
        .catch(err => { console.error(err) })
    },
    saveInitialActiveViewpoint (viewpoint) {
      this.$store.commit('specifications/mergeActiveQuestion', { initialViewpoint: viewpoint })
      this.$axios.request({
        method: 'patch',
        url: `/virtual_tours/${this.activeQuestion.virtualTourId}`,
        data: { virtual_tour: { initial_viewpoint: viewpoint } }
      })
        .then(() => { this.$store.commit('toggleFullSpinner', false) })
        .catch(() => { this.$store.commit('toggleFullSpinner', false) })
    }
  }
}
</script>
