<script>
import advanceToUrl from './advanceToUrl'
export default {
  mixins: [advanceToUrl],
  props: {
    /** Location the user will be taken to upon clicking the component where this mixin is used. */
    url: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    handleNewPath (event) {
      if (this.disabled) { return }
      if (this.url) { this.advanceToUrl(this.url) }
      this.$emit('click', event)
    }
  }
}
</script>
