<template>
  <div class="icon_add-prompt">
    <div class="icon_add-prompt__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M-23225.035-129.994v7.9s-.479.272-1.832-.239-1.646.239-1.646.239l-1.006.615-1.562 1.546h-3l-1.939 1.941-1.287-1.941h-1.713v-10.059zm-10.834 10.428l1.377-1.374h3.512l.529-.542 1.158-.854 3.242-.222.02-6.431h-12v8.05h1.25z"
            transform="translate(23239.027 129.995)"
          />
          <path
            d="M9 4.5A4.5 4.5 0 114.5 0 4.5 4.5 0 019 4.5zm-1 0A3.5 3.5 0 104.5 8 3.5 3.5 0 008 4.5z"
            transform="translate(7 7)"
          />
          <path
            d="M.5 0v4h-1V0z"
            transform="translate(11.5 9.5)"
          />
          <path
            d="M0-.5h4v1H0z"
            transform="translate(9.5 11.5)"
          />
          <path
            d="M0 0h2v2H0z"
            transform="translate(3 4)"
          />
          <path
            d="M0 0h2v2H0z"
            transform="translate(6 4)"
          />
          <path
            d="M0 0h2v2H0z"
            transform="translate(9 4)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
