<template>
  <div class="icon_star-filled">
    <div class="icon_star-filled__svg">
      <svg
        viewBox="0 0 33.053 33.054"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(1925.415 5032)">
          <path
            d="M121.158 118.717l5.107 10.881 11.42 1.745-8.263 8.469 1.951 11.959-10.214-5.646-10.214 5.646 1.951-11.959-8.263-8.469 11.42-1.745z"
            transform="translate(-2030.047 -5150.717)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
