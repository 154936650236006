<template>
  <div class="icon_libraries">
    <div class="icon_libraries__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-.558-.1l.094-.4h13.936l.094.4.955 6.029s-.2.556-.485.556c-16.384 0-15.5-.455-15.5-.455l.17-1.326zm14.03 5.587L12.718.5H.289l-.753 4.986z"
          transform="translate(1.479 .5)"
        />
        <path
          d="M-1.451.079s-.942-.58 15.5-.58c.283 0 .483.412.483.412l-.95 7.309-.343 2.27H-.294l-.259-2.215-.8-6.527zM.382 7.163l.152 1.418h11.91l.2-1.362.876-6.812H-.494z"
          transform="translate(1.461 6.511)"
        />
        <path
          d="M0 0h2"
          transform="translate(7.981 13)"
        />
        <path
          d="M0 0h10.006"
          transform="translate(3.979 5)"
        />
        <path
          d="M0 0h6"
          transform="translate(5.98 3)"
        />
        <path
          d="M0 0h4v1H0V0z"
          transform="translate(5.98 12)"
        />
        <path
          d="M0 0h10.006v1H0V0z"
          transform="translate(2.978 4)"
        />
        <path
          d="M0 0h6v1H0V0z"
          transform="translate(4.98 2)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
