<template>
  <div
    :class="{
      'st-favorite-choice__content--specs': isSpecificationsPreview,
    }"
  >
    <div
      ref="questionContainer"
      class="st-participant__question-container st-participant__question-container--favorite-choice"
    >
      <p
        ref="question"
        class="st-participant__question-container-para"
      >
        {{ activeQuestion.description }}
      </p>
    </div>
    <div class="st-favorite-choice__option-canvas">
      <div
        v-for="(option, index) in activeQuestion.options"
        :key="option.id"
        tabindex="0"
        :class="{
          'st-favorite-choice__option': true,
          'st-favorite-choice__option--active': activeOption === option.id,
          'st-favorite-choice__option--last': index == activeQuestion.options.length - 1,
          'st-favorite-choice__option--last-specs': index == activeQuestion.options.length - 1 && isSpecificationsPreview,
        }"
        @click="selectOption(option)"
        @keyup.enter="selectOption(option)"
      >
        <p
          :class="{
            'st-favorite-choice__option-text st-favorite-choice__option-text--ordinal': true,
            'st-favorite-choice__option-text--active': activeOption === option.id,
            'st-favorite-choice__option-text--active': activeOption === option.id,
            'st-favorite-choice__option-text--ordinal-specs': isSpecificationsPreview
          }"
        >
          {{ ordinalLetter(index) }})
        </p>
        <p
          :class="{
            'st-favorite-choice__option-text': true,
            'st-favorite-choice__option-text--active': activeOption === option.id,
            'st-favorite-choice__option-text--specs': isSpecificationsPreview
          }"
        >
          {{ option.name }}
        </p>
      </div>
    </div>
    <st-tooltip
      :class="{
        'st-favorite-choice__submit-button-tooltip-survey': !isSpecificationsPreview,
        'st-favorite-choice__submit-button-tooltip-survey--active': !isSpecificationsPreview,
        'st-favorite-choice__submit-button-tooltip-specs': isSpecificationsPreview && !activeOption,
        'st-favorite-choice__submit-button-tooltip-specs--active': isSpecificationsPreview
      }"
      tip="Preview only button"
      :is-active="isPreview && !isSurveyPreview"
    >
      <st-button
        class="st-favorite-choice__submit-button"
        :caption="buttonCaption"
        :disabled="!activeOption"
        icon-name="arrow"
        :underline="false"
        :variant="['secondary']"
        @click="submitQuestionAnswer"
      />
    </st-tooltip>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isDesktop, previousResponse, responsiveQuestionText, submitQuestionAnswer } from '../../../../mixins'
import { StButton, StTooltip } from '../../../common'

export default {
  components: {
    StButton,
    StTooltip
  },
  mixins: [ isDesktop, previousResponse, responsiveQuestionText, submitQuestionAnswer ],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    questionId: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      activeOption: null
    }
  },
  computed: {
    ...mapState('participants', { participantActiveQuestion: 'activeQuestion' }),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    activeQuestion () {
      return this.isSpecificationsPreview ? this.specsActiveQuestion : this.participantActiveQuestion
    },
    isSpecificationsPreview () {
      return this.isPreview && !this.isSurveyPreview && this.isDesktop
    },
    options () {
      return this.activeQuestion.options
    }
  },
  watch: {
    options () {
      this.activeOption = null
    }
  },
  methods: {
    answerData () {
      return { response: { value: this.activeOption } }
    },
    loadResponses () {
      if (this.questionResponses && this.questionResponses.length > 0) {
        this.$nextTick(() => {
          this.activeOption = this.questionResponses[0].value
        })
      }
    },
    ordinalLetter (index) {
      return String.fromCharCode((index + 1) + 64)
    },
    selectOption (option) {
      this.activeOption = option.id
    }
  }
}
</script>
