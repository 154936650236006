<script>
const idObj = {
  previousId: 0,
  newId () {
    this.previousId++
    return `st-uid-${this.previousId}`
  }
}

export default {
  props: {
    id: { type: String, default: null }
  },
  computed: {
    stUid () {
      return this.id && this.id.length ? this.id : idObj.newId()
    }
  }
}
</script>
