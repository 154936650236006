export function pullPropertyList (obj, ...props) {
  return props.reduce(
    (newObj, prop) => {
      if (obj.hasOwnProperty(prop)) {
        newObj[prop] = obj[prop]
      }
      return newObj
    },
    {}
  )
}

export function assignPropertyList (target, source, ...props) {
  return Object.assign(target, pullPropertyList(source, ...props))
}

// create an object where each entry from arr has an entry
// each value will be defaultValue (defaults to true)
export function mapFromArr (arr, defaultValue = true) {
  let defaulter = defaultFactory(defaultValue)
  return arr.reduce((result, el) => {
    result[el] = defaulter()
    return result
  }, {})
}

// given a default value or a default value generator, returns default value generator
function defaultFactory (defaultValue = true) {
  if (typeof defaultValue === 'function') {
    return () => defaultValue()
  }

  return () => defaultValue
}
