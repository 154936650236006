<template>
  <div class="virtual-tour-specs__half-screen">
    <div
      v-if="start"
      class="st-virtual-tour-specs__upload-tour-box"
    >
      <div
        class="st-virtual-tour-specs__upload-tour-link"
        :class="{'st-new-question__preview-cursor': isReadOnly}"
        @click="enterUploadPhase"
      >
        <div>Upload Tour</div>
        <icon-wrapper
          icon-name="upload"
          class="st-virtual-tour-specs__upload-tour-icon"
        />
      </div>
    </div>
    <div v-else>
      <div class="st-virtual-tour-specs__tour-title">
        <div>{{ tourTitle }}</div>
        <div
          v-if="selectedViewpoints.length>1"
          class="st-virtual-tour-specs__unselect-all"
          @click="unselectAllViewpoints"
        >
          Unselect all
        </div>
      </div>
      <matterport-embed
        ref="matterportTourFloorplan"
        class="st-virtual-tour-specs__matterport-floorplan"
        :floorplan="true"
        :matterport-id="activeQuestion.matterportId"
        :virtual-tour-id="activeQuestion.virtualTourId || newTourId"
        :assigned-viewpoint-identifiers="assignedViewpointIdentifiers"
        :active-viewpoints="selectedViewpoints"
        :save-sweeps="viewpoints.length===0"
        :question-id="activeQuestion.id"
        :tour-name="activeQuestion.description"
        @emitTourTitle="getTourTitle"
        @emitViewpoints="addViewpoints"
        @selectSweep="toggleSweep"
      />
      <div
        v-if="!isReadOnly"
        class="st-virtual-tour-specs__room-input-row"
      >
        <div class="st-virtual-tour-specs__room-input-icon" />
        <st-tooltip
          ref="roomTooltip"
          direction="bottom"
          tip=""
          class="st-virtual-tour-specs__room-input"
          :is-active="isDesktop"
        >
          <template v-slot:default>
            <st-autocomplete
              ref="roomAutocomplete"
              :active-viewpoint="activeViewpoint"
              :initial-matchable-list="roomNameList"
              :create-fn="createNewRoom"
              :label="autocompleteLabel"
              placeholder="Room name goes here"
              create-caption="Room Name"
              create-caption-icon-name="add-virtual-room"
              option-icon-name="virtual-room"
              @selectOption="(val) => selectRoomFromAutocomplete(val)"
              @unselectOption="addRoomName = null"
            />
          </template>
          <template v-slot:tip>
            <div>
              To complete set up you need to assign a room name to every viewpoint <div class="st-virtual-tour-specs__tooltip-room-icon" /> displayed on the floor-plan.
              <br>
              <br>
              Multiple viewpoints can be part of the same room.
            </div>
            <template />
          </template>
        </st-tooltip>
      </div>

      <div
        v-if="!isDesktop && rooms.length === 0"
        class="st-virtual-tour-specs__mobile-rooms-tooltip"
      >
        To complete set up you need to assign a room name to every viewpoint <span class="st-virtual-tour-specs__viewpoint-icon" /> displayed on the floor-plan. Multiple viewpoints can be part of the same room.
      </div>

      <template v-if="rooms.length > 0">
        <div
          id="rooms-heading"
          class="st-virtual-tour-specs__rooms-heading"
        >
          <div>Rooms</div>
          <st-tooltip
            class="st-virtual-tour-specs__rooms-tooltip"
            tip="This is a list of all rooms being assigned to viewpoints."
            :allow-wrap="true"
            :direction="isDesktop ? 'bottom' : 'right'"
            :max-width="isDesktop ? 411 : 225"
          />
        </div>
        <div class="st-virtual-tour-specs__rooms">
          <room-accordion
            v-for="(room, n) in rooms"
            :key="`room-${room.id}`"
            :ref="`roomAccordion${n}`"
            :is-preview="isReadOnly"
            :room="room"
            :room-index="n"
            @edit-prompt="editPrompt"
            @open-edit-room-modal="openEditRoomModal"
            @open-delete-room-modal="openDeleteRoomModal"
            @open-delete-prompt-modal="openDeletePromptModal"
            @cancel-edit-prompt="cancelEditPrompt"
            @submit-edit-prompt="submitEditPrompt"
            @prompt-handler="promptHandler"
            @close-open-prompt-input="closeOpenPromptInput"
          />
        </div>
      </template>
    </div>

    <st-modal v-model="showMergeModal">
      <template slot="modalTitle">
        Merge Viewpoint
      </template>
      <template
        slot="modalActions"
      >
        <div class="st-virtual-tour-specs__modal-text">
          There <template v-if="roomToMerge.panoramas.length===1">
            is 1 viewpoint
          </template><template v-else>
            are {{ roomToMerge.panoramas.length }} viewpoints
          </template> assigned to {{ roomToMerge.name }}. If you submit, all viewpoints will be merged with {{ tempRoomName }}. Are you sure you want to continue?
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Continue"
          :show-action="true"
          icon-name="arrow"
          @click="mergeRooms"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="showDeleteModal"
      :non-exit-modal="true"
      @closeModal="closeRoomModal"
    >
      <template slot="modalTitle">
        Delete Room
      </template>
      <template
        slot="modalActions"
      >
        <div class="st-virtual-tour-specs__modal-text">
          You are about to delete {{ modalRoomName }}. If you do this, you will have to re-assign all viewpoints that were part of this room. Are you sure you want to continue?
        </div>
      </template>
      <template v-slot:modalButton>
        <div class="st-media-library__modal-btn-section">
          <div
            class="st-media-library__cancel-modal-btn"
            @click="showDeleteModal=false"
          >
            <div>Cancel</div>
            <icon-wrapper
              icon-name="cancel"
              class="st-media-library__cancel-modal-icon"
            />
          </div>
          <st-button
            caption="Delete Room"
            :show-action="true"
            icon-name="trash"
            @click="deleteRoom"
          />
        </div>
      </template>
    </st-modal>
    <st-modal
      v-model="assignNewRoomModal"
      :non-exit-modal="true"
    >
      <template slot="modalTitle">
        Assign Viewpoint to new room
      </template>
      <template
        slot="modalActions"
      >
        <div class="st-virtual-tour-specs__modal-text">
          {{ assignedViewpointsString }}
        </div>
      </template>
      <template v-slot:modalButton>
        <div class="st-media-library__modal-btn-section">
          <div
            class="st-media-library__cancel-modal-btn"
            @click="assignNewRoomModal=false"
          >
            <div>Cancel</div>
            <icon-wrapper
              icon-name="cancel"
              class="st-media-library__cancel-modal-icon"
            />
          </div>
          <st-button
            caption="Assign New Room"
            :show-action="true"
            icon-name="arrow"
            @click="reassignNewRoom"
          />
        </div>
      </template>
    </st-modal>
    <st-modal
      v-model="deletePromptModal"
      :non-exit-modal="true"
      @closeModal="cancelEditPrompt"
    >
      <template slot="modalTitle">
        Delete Prompt
      </template>
      <template
        slot="modalActions"
      >
        <div class="st-virtual-tour-specs__modal-text">
          Are you sure you want to delete the prompt "{{ newPrompt }}" For {{ activeViewpointRoomName }}?
        </div>
      </template>
      <template v-slot:modalButton>
        <div class="st-media-library__modal-btn-section">
          <div
            class="st-media-library__cancel-modal-btn"
            @click="deletePromptModal=false"
          >
            <div>Cancel</div>
            <icon-wrapper
              icon-name="cancel"
              class="st-media-library__cancel-modal-icon"
            />
          </div>
          <st-button
            caption="Delete Prompt"
            :show-action="true"
            icon-name="trash"
            @click="deletePrompt(activeViewpoint.id)"
          />
        </div>
      </template>
    </st-modal>
    <st-modal v-model="showEditModal">
      <template slot="modalTitle">
        Edit Room Name
      </template>
      <template
        slot="modalActions"
      >
        <st-input
          v-model="tempRoomName"
          class="st-virtual-tour-specs__edit-prompt-input"
          :show-label="true"
          label="Room Name"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          icon-name="save"
          @click="editRoomName"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="matterportUploadModal"
      :class="{
        'st-virtual-tour-specs__screenshot-modal': takeScreenshot
      }"
      size-class="st-modal--large st-virtual-tour-specs__modal"
    >
      <template slot="modalTitle">
        <template v-if="uploadMatterportPhase">
          Embed Matterport Model
        </template>
        <template v-else>
          Upload Matterport or a 3D Render
        </template>
      </template>
      <template
        slot="modalActions"
      >
        <div
          v-if="takeScreenshot"
          class="st-virtual-tour-specs__tour-screenshot-container"
        >
          <div>
            We need a tour screenshot for this question. Is this image okay?
          </div>
          <matterport-embed
            ref="matterportTourScreenshot"
            class="st-virtual-tour-specs__tour-screenshot"
            :tour-screenshot="takeTourScreenshot"
            :virtual-tour-id="activeQuestion.virtualTourId || newTourId"
            :matterport-id="activeQuestion.matterportId"
            @tourScreenshotSrc="getScreenshot"
          />
          <div
            v-if="takeTourScreenshot"
            class="st-virtual-tour-specs__screenshot-buttons"
          >
            <st-button
              :disabled="!tourScreenshotSrc"
              :underline="false"
              caption="Retake Image"
              @click="retakeScreenshot"
            />
            <st-button
              :disabled="!tourScreenshotSrc"
              :underline="false"
              caption="Use Image"
              @click="saveScreenshot"
            />
          </div>
          <div
            v-else
            class="st-virtual-tour-specs__screenshot-buttons"
          >
            <div />
            <div class="st-virtual-tour-specs__btn-and-spinner">
              <st-inline-spinner
                v-if="isCapturingScreenshot && !tourScreenshotSrc"
                class="st-virtual-tour-specs__spinner"
              />
              <st-button
                caption="Capture Screenshot"
                :underline="false"
                @click="takeNewScreenshot"
              />
            </div>
          </div>
        </div>
        <template v-else-if="uploadMatterportPhase || matterportId">
          <st-hyperlink
            :back-link="true"
            text="Back"
            class="st-virtual-tour-specs__modal-back"
            @click="matterportUploadModal=false"
          />
          <div class="st-virtual-tour-specs__upload-text">
            Go to your <a
              href="https://my.matterport.com/accounts/login"
              target="_blank"
              class="st-virtual-tour-specs__link-text"
            >
              Matterport dashboard
            </a> and follow these instructions
          </div>
          <div class="st-virtual-tour-specs__video-container">
            <div
              class="wistia_responsive_padding"
              style="padding:0;position:relative;"
            >
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <iframe
                    src="https://fast.wistia.net/embed/iframe/i92ev1lmyg?seo=false&videoFoam=true"
                    title="Embed_tour_spacetrics_export Video"
                    allowtransparency="true"
                    frameborder="0"
                    scrolling="no"
                    class="wistia_embed"
                    name="wistia_embed"
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <label
              v-if="activeQuestion.matterportId"
            >
              Tour ID
              <input
                :value="activeQuestion.matterportId"
                class="st-input"
                disabled
              >
            </label>
            <template
              v-else
            >
              <st-input
                v-model="embedCode"
                class="st-accept-invite__input--first-name st-accept-invite__input"
                label="Share Link"
                placeholder="Paste Link here"
                :autofocus="true"
                :show-label="true"
              />

              <div
                class="st-virtual-tour-specs__upload-tour-button-row"
              >
                <st-button
                  caption="Upload Tour"
                  icon-name="upload"
                  :disabled="!embedCode || !matterportId"
                  @click="uploadMatterportTour"
                />
              </div>
            </template>
          </div>
        </template>
        <div
          v-else
          class="st-virtual-tour-specs__upload-cards"
        >
          <st-card
            class="st-virtual-tour-specs__upload-card"
            @click="uploadMatterportPhase=true"
          >
            <template
              v-slot:card-content
            >
              <div>
                <div class="st-virtual-tour-specs__upload-card-row">
                  <icon-wrapper
                    icon-name="virtual-tour"
                    class="st-virtual-tour-specs__upload-card-icon"
                  />
                  <div>Matterport</div>
                </div>
                <div class="st-virtual-tour-specs__upload-card-text">
                  You will need access to your matterport account
                </div>
              </div>
            </template>
          </st-card>
          <st-card
            class="st-virtual-tour-specs__upload-card"
            @click="upload3dRender=true"
          >
            <template
              v-slot:card-content
            >
              <div>
                <div class="st-virtual-tour-specs__upload-card-row">
                  <icon-wrapper
                    icon-name="add-virtual-room"
                    class="st-virtual-tour-specs__upload-card-icon"
                  />
                  <div>
                    3D Render
                  </div>
                </div>
                <div class="st-virtual-tour-specs__upload-card-text">
                  Upload one of your model, or find one in our libraries
                </div>
              </div>
            </template>
          </st-card>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { sample } from 'lodash'
import matterportEmbed from '../../../matterport/matterport-embed'
import { isDesktop } from '../../../../mixins'
import { ROOM_NAME_LIST } from './constants'
import RoomAccordion from './room-accordion'
import {
  IconWrapper,
  StAutocomplete,
  StButton,
  StCard,
  StHyperlink,
  StInlineSpinner,
  StInput,
  StModal,
  StTooltip
} from '../../../common'

export default {
  components: {
    IconWrapper,
    StAutocomplete,
    StButton,
    StCard,
    StHyperlink,
    StInlineSpinner,
    StInput,
    StModal,
    StTooltip,
    'room-accordion': RoomAccordion,
    'matterport-embed': matterportEmbed
  },
  mixins: [isDesktop],
  props: {
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      start: true,
      embedCode: null,
      tourTitle: null,
      viewpoints: [],
      addRoomName: null,
      rooms: [],
      tempRoomName: null, // used for editing an existing room
      oldRoomName: null, // so that you know what room name to delete from room name list
      roomNameList: ROOM_NAME_LIST,
      newPrompt: '',
      roomToMerge: { id: null, name: null, virtualTourId: null, panoramas: [] },
      showMergeModal: false,
      assignNewRoomModal: false,
      showEditModal: false,
      showDeleteModal: false,
      deletePromptModal: false,
      matterportUploadModal: false,
      selectedIdentifier: '',
      newTourId: null,
      modalRoomId: null,
      modalPromptId: null,
      modalRoomName: '',
      uploadMatterportPhase: true,
      takeScreenshot: false,
      isCapturingScreenshot: false,
      takeTourScreenshot: true,
      tourScreenshotSrc: null
    }
  },
  computed: {
    ...mapState('specifications', ['activeViewpointIdentifier']),
    ...mapGetters('specifications', ['activeQuestion', 'activeViewpoint']),
    assignedViewpointsCount () {
      let count = 0
      this.rooms.forEach(room => {
        count += room.panoramas.length
      })
      return count
    },
    roomNames () {
      return this.rooms.map(r => r.name)
    },
    assignedViewpointIdentifiers () {
      let identifiers = []
      this.rooms.forEach(room => {
        room.panoramas.forEach(pano => {
          identifiers.push(pano.identifier)
        })
      })
      return identifiers
    },
    assignedViewpointsString () {
      if (this.selectedViewpointsAlreadyAssigned.length === 1) {
        return 'One of the viewpoints selected is already assigned to a room, are you sure you want to assign it to a different room?'
      } else {
        return this.selectedViewpointsAlreadyAssigned.length + ' of the viewpoints selected are already assigned to a room, are you sure you want to assign them to a different room?'
      }
    },
    activeViewpointIndex () {
      if (this.selectedIdentifier.length > 0) {
        return this.viewpoints.map(point => {
          return point.identifier
        }).indexOf(this.selectedIdentifier)
      } else {
        return 0
      }
    },
    activeViewpoint () {
      if (this.selectedIdentifier.length > 0 && this.activeViewpointIndex > -1) {
        let point = this.viewpoints[this.activeViewpointIndex]
        if (point.roomId && (this.rooms.map(x => x.id).includes(point.roomId))) {
          let roomIdx = this.rooms.map(x => x.id).indexOf(point.roomId)
          let pointIdx = this.rooms[roomIdx].panoramas.map(x => x.id).indexOf(point.id)
          point.prompt = this.rooms[roomIdx].panoramas[pointIdx].prompt
        }
        return point
      } else {
        return {}
      }
    },
    activeViewpointRoomName () {
      let idx = this.rooms.map(room => room.id).indexOf(this.activeViewpoint.roomId)
      if (idx > -1) {
        return this.rooms[idx].name
      } else {
        return ''
      }
    },
    selectedViewpoints () {
      return this.viewpoints.filter(point => point.selected)
    },
    selectedViewpointIds () {
      return this.selectedViewpoints.map(point => point.id)
    },
    autocompleteLabel () {
      if (this.selectedViewpoints.length === 1) {
        return `Room name (1 viewpoint selected)`
      } else {
        return `Room name (${this.selectedViewpoints.length} viewpoints selected)`
      }
    },
    matterportId () {
      if (this.activeQuestion.matterportId) {
        return this.activeQuestion.matterportId
      } else if (this.embedCode) {
        if (this.embedCode.slice(-1) === '&') {
          return this.embedCode.slice(0, -1).split('=')[1]
        } else {
          return this.embedCode.split('=')[1]
        }
      } else {
        return null
      }
    },
    allAssignedViewpoints () {
      let points = []
      this.rooms.forEach((room, idx) => {
        room.panoramas.forEach((pano, n) => {
          points.push(Object.assign({ roomIndex: idx, panoIndex: n }, pano))
        })
      })
      return points
    },
    selectedViewpointsAlreadyAssigned () {
      let panos = []
      this.selectedViewpoints.forEach(pano => {
        if (this.assignedViewpointIdentifiers.includes(pano.identifier)) {
          panos.push(pano)
        }
      })
      return panos
    },
    selectedViewpointsRoom () {
      let rooms = {}
      this.selectedViewpoints.forEach(pano => {
        rooms[pano.roomId] = 1
      })
      if (Object.keys(rooms).length > 1) {
        return '-'
      } else {
        if (this.getRoomFromId(Number(Object.keys(rooms)[0]))) {
          return this.getRoomFromId(Number(Object.keys(rooms)[0])).name
        } else {
          return null
        }
      }
    },
    promptBeingEdited () {
      let openPrompt = {}
      this.rooms.forEach((room, i) => {
        room.panoramas.forEach((pano, n) => {
          if (pano.editPrompt) {
            openPrompt.roomIdx = i
            openPrompt.panoIdx = n
          }
        })
      })
      return openPrompt
    }
  },
  mounted: function () {
    // fetch the virtual tour json
    if (this.activeQuestion.virtualTourId) {
      this.mapVirtualTourData()
    }
    this.embedCode = this.activeQuestion.matterportId || this.activeQuestion.panoskinId
    this.tourTitle = this.activeQuestion.description
    this.start = !this.matterportId
    if (this.isDesktop && this.rooms.length > 0) {
      this.$nextTick(() => {
        this.$refs.roomTooltip.displayTooltip = true
        setTimeout(() => {
          this.$refs.roomTooltip.displayTooltip = false
        }, 5000)
      })
    }
  },
  methods: {
    ...mapMutations('specifications', ['mergeActiveQuestion', 'changeTab', 'moveVirtualTourPreview', 'setActiveViewpointIdentifier']),
    ...mapMutations('participants', ['setActiveQuestion']),
    ...mapActions('specifications', ['updateAssignedViewpoints']),
    ...mapActions('participants', ['changePano']),
    updateQuestionCompleteness (res) {
      this.mergeActiveQuestion({ complete: res.data.question.complete })
    },
    selectRoomFromAutocomplete (roomName) {
      if (this.selectedViewpointsAlreadyAssigned.length > 0) {
        this.assignNewRoomModal = true
        this.addRoomName = roomName
      } else {
        if (this.doesRoomExist(roomName)) {
          this.assignRoomToViewpoints(roomName)
        } else {
          this.createNewRoomFromPrefilledList(roomName)
        }
      }
    },
    createNewRoomFromPrefilledList (roomName) { // used when a roomname is selected but the room does not exist
      this.$store.commit('toggleInlineSpinner', true)
      let url = `/virtual_tours/${this.activeQuestion.virtualTourId}/add_room`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { virtual_tour_room: { name: roomName } }
      })
        .then(res => {
          this.rooms.push(this.mapRoomResponse(res.data))
          this.$nextTick(() => {
            this.assignRoomToViewpoints(roomName)
            this.updateAssignedViewpoints(this.allAssignedViewpoints)
            this.assignNewRoomModal = false
            this.updateQuestionCompleteness(res)
          })
          setTimeout(() => {
            this.$store.commit('toggleInlineSpinner', false)
          }, 2450)
        })
    },
    doesRoomExist (roomName) {
      return this.roomNames.includes(roomName)
    },
    assignRoomToViewpoints (roomName) {
      // send ajax request for assigning the panoramas to a room
      let roomId = this.getRoomIdFromName(roomName)
      this.assignPanosToRoom(roomId)
    },
    createNewRoom (roomName) {
      if (this.roomNames.includes(roomName)) {
        this.assignRoomToViewpoints(roomName)
      }
      this.$store.commit('toggleInlineSpinner', true)
      let url = `/virtual_tours/${this.activeQuestion.virtualTourId}/add_room`
      return this.$axios.request({
        method: 'post',
        url: url,
        data: { virtual_tour_room: { name: roomName } }
      })
        .then(res => {
          this.rooms.push(this.mapRoomResponse(res.data))
          this.assignNewRoomModal = false
          this.updateQuestionCompleteness(res)
          this.updateAssignedViewpoints(this.allAssignedViewpoints)
          if (!this.roomNameList.includes(roomName)) { this.roomNameList.push(roomName) }
          setTimeout(() => {
            this.$store.commit('toggleInlineSpinner', false)
          }, 2450)
          return roomName
        })
    },
    mapVirtualTourData () {
      this.rooms = this.activeQuestion.rooms.map(room => this.mapRoomResponse(room))
      this.rooms.forEach(room => {
        if (!this.roomNameList.includes(room.name)) {
          this.roomNameList.push(room.name)
        }
      })
      this.viewpoints = this.activeQuestion.panoramas.map(point => {
        return Object.assign({ selected: false }, point)
      })
      this.$nextTick(() => {
        this.updateAssignedViewpoints(this.allAssignedViewpoints)
      })
    },
    uploadMatterportTour () {
      this.$store.commit('toggleInlineSpinner', true)
      let url = '/virtual_tours'
      let data = {
        matterport_id: this.matterportId,
        question_id: this.activeQuestion.id
      }
      this.$store.commit('toggleInlineSpinner', true)

      this.$axios.request({
        url: url,
        method: 'post',
        data: { virtual_tour: data }
      })
        .then(res => {
          this.newTourId = res.data.id
          this.start = false
          this.takeScreenshot = true
          setTimeout(() => {
            this.$store.commit('toggleInlineSpinner', false)
          }, 2450)

          this.$nextTick(() => {
            this.$refs.roomTooltip.displayTooltip = true
            setTimeout(() => {
              this.$refs.roomTooltip.displayTooltip = false
            }, 5000)
          })
          setTimeout(() => {
            this.$store.commit('toggleInlineSpinner', false)
          }, 2450)
          this.mergeActiveQuestion({
            virtualTourId: res.data.id,
            matterportId: res.data.matterportId
          })
        })
    },
    mapRoomResponse (room) {
      return {
        id: room.id,
        name: room.name,
        virtualTourId: room.virtualTourId,
        panoramas: room.panoramas.map(pano => {
          return Object.assign({ editPrompt: false }, pano)
        })
      }
    },
    editRoomName () {
      let index = this.rooms.map(room => room.id).indexOf(this.modalRoomId)
      if (this.roomNames.includes(this.tempRoomName)) {
        this.showMergeModal = true
        this.showEditModal = false
        this.roomToMerge = this.rooms[index]
      } else {
        this.$store.commit('toggleInlineSpinner', true)
        let url = `/virtual_tour_rooms/${this.modalRoomId}/update`
        let data = { name: this.tempRoomName }
        this.$axios.request({
          method: 'patch',
          url: url,
          data: { virtual_tour_room: data }
        })
          .then(res => {
            this.rooms[index].name = this.tempRoomName
            this.showEditModal = false
            this.updateQuestionCompleteness(res)
            let roomNameIdx = this.roomNameList.indexOf(this.oldRoomName)
            this.roomNameList.splice(roomNameIdx, 1, this.tempRoomName)
            this.$nextTick(() => {
              this.mergeActiveQuestion({ rooms: this.rooms })
              this.tempRoomName = null
              this.oldRoomName = null
            })
            setTimeout(() => {
              this.$store.commit('toggleInlineSpinner', false)
            }, 2450)
          })
          .catch(err => console.error(err))
      }
    },
    mergeRooms () {
      let oldRoomIdx = this.rooms.map(room => room.id).indexOf(this.roomToMerge.id)
      let roomId = this.getRoomIdFromName(this.tempRoomName)
      let viewpointIds = this.rooms[oldRoomIdx].panoramas.map(pano => pano.id)

      this.$store.commit('toggleInlineSpinner', true)
      let url = `/virtual_tours/${this.activeQuestion.virtualTourId}/add_panoramas_to_room`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { room_id: roomId, viewpoint_ids: viewpointIds }
      })
        .then((res) => {
          let idx = this.rooms.map(room => room.id).indexOf(roomId)
          this.rooms.splice(idx, 1, this.mapRoomResponse(res.data))
          this.updateQuestionCompleteness(res)

          // delete old room
          let deleteUrl = `/virtual_tour_rooms/${this.rooms[oldRoomIdx].id}/delete`
          this.$axios.request({
            url: deleteUrl,
            method: 'delete'
          }).then(res => {
            this.rooms.splice(oldRoomIdx, 1)
            this.showMergeModal = false
            setTimeout(() => {
              this.$store.commit('toggleInlineSpinner', false)
            }, 2450)
            // remove old name from roomNameArray
            let roomNameIdx = this.roomNameList.indexOf(this.oldRoomName)
            this.roomNameList.splice(roomNameIdx, 1)
            this.$nextTick(() => {
              this.roomToMerge = null
              this.tempRoomName = null
              this.oldRoomName = null
            })
            this.updateQuestionCompleteness(res)
          })
            .catch(err => console.error(err))
        })
        .catch(err => {
          console.error(err)
        })
    },
    getTourTitle (title) {
      this.tourTitle = title
    },
    toggleSweep (identifier) {
      let idx = this.viewpoints.map(x => x.identifier).indexOf(identifier)
      if (idx === -1) {
        this.$store.commit('toggleInlineSpinner', true)
        // add the pano
        let url = `/virtual_tours/${this.activeQuestion.virtualTourId}/add_panorama`
        let data = { identifier: identifier }
        this.$axios.request({
          method: 'post',
          url: url,
          data: { virtual_tour_panorama: data }
        })
          .then(res => {
            setTimeout(() => {
              this.$store.commit('toggleInlineSpinner', false)
            }, 2450)
            // add it to the viewpoints array
            this.viewpoints = res.data.panoramas.map(point => {
              return Object.assign({ selected: false }, point)
            })
            this.viewpoints[idx].selected = !this.viewpoints[idx].selected
            this.moveVirtualTourPreview(identifier)
            this.setActiveViewpointIdentifier(identifier)
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        this.viewpoints[idx].selected = !this.viewpoints[idx].selected
        this.moveVirtualTourPreview(identifier)
        this.setActiveViewpointIdentifier(identifier)
        this.$nextTick(() => {
          this.$refs.roomAutocomplete.selectOptionWithoutEmitting(this.selectedViewpointsRoom)
        })
      }
    },
    assignPanosToRoom (roomId) {
      let alreadyAssignedViewpoints = this.selectedViewpointsAlreadyAssigned.slice()
      this.$store.commit('toggleInlineSpinner', true)
      let url = `/virtual_tours/${this.activeQuestion.virtualTourId}/add_panoramas_to_room`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { room_id: roomId, viewpoint_ids: this.selectedViewpointIds }
      })
        .then((res) => {
          setTimeout(() => {
            this.$store.commit('toggleInlineSpinner', false)
          }, 2450)
          let idx = this.rooms.map(room => room.id).indexOf(roomId)
          this.rooms.splice(idx, 1, this.mapRoomResponse(res.data))
          this.assignNewRoomModal = false
          this.updateQuestionCompleteness(res)
          this.setActiveQuestion({ ...this.activeQuestion, rooms: this.rooms })
          this.updateAssignedViewpoints(this.allAssignedViewpoints)
          this.$nextTick(() => {
            this.afterAssignViewpoints(alreadyAssignedViewpoints, roomId)
            if (this.assignedViewpointsCount === this.viewpoints.length) {
              window.location.href = '#rooms-heading'
              // open the first accordion
              this.$refs.roomAccordion0[0].expanded = true
            }
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    selectSweepInBothTours (identifier) {
      if (this.selectedIdentifier !== identifier) {
        this.selectedIdentifier = identifier
        this.$refs.matterportTourFloorplan.activeSweepIdentifier = identifier
        this.moveVirtualTourPreview(identifier)
      }
    },
    addViewpoints (data) {
      this.viewpoints = data.panoramas.map(point => {
        return Object.assign({ selected: false }, point)
      })
      setTimeout(() => {
        const initialViewpoint = data.initialViewpoint || sample(data.panoramas).identifier
        this.selectSweepInBothTours(initialViewpoint)
        this.selectedIdentifier = initialViewpoint
      }, 8000)
    },
    openDeleteRoomModal (roomId) {
      if (this.isReadOnly) { return }
      this.modalRoomId = roomId
      let room = this.getRoomFromId(roomId)
      this.modalRoomName = room.name
      this.showDeleteModal = true
    },
    openEditRoomModal (roomId) {
      if (this.isReadOnly) { return }
      this.modalRoomId = roomId
      let room = this.getRoomFromId(roomId)
      this.oldRoomName = room.name
      this.tempRoomName = room.name
      this.showEditModal = true
    },
    closeRoomModal () {
      this.modalRoomId = null
      this.modalRoomName = ''
      this.showEditModal = false
      this.showDeleteModal = false
    },
    deleteRoom () {
      if (this.isReadOnly) { return }
      this.$store.commit('toggleInlineSpinner', true)
      // send ajax request to delete the room
      let url = `/virtual_tour_rooms/${this.modalRoomId}/delete`
      this.$axios.request({
        url: url,
        method: 'delete'
      }).then(res => {
        setTimeout(() => {
          this.$store.commit('toggleInlineSpinner', false)
        }, 2450)
        let room = this.getRoomFromId(this.modalRoomId)
        room.panoramas.forEach(pano => {
          console.log('pano.identifier', pano.identifier)
          this.unassignPanoToRoom(pano.id)
        })
        this.$nextTick(() => {
          let idx = this.rooms.map(x => x.id).indexOf(room.id)
          this.rooms.splice(idx, 1)
          this.closeRoomModal()
          this.updateAssignedViewpoints(this.allAssignedViewpoints)
        })
        this.updateQuestionCompleteness(res)
      })
        .catch(err => console.error(err))
    },
    unassignPanoToRoom (panoId) {
      let pano = this.viewpoints.find(x => x.id === panoId)
      pano.roomId = null
      this.deletePrompt(panoId)
    },
    unselectAllViewpoints () {
      this.viewpoints.forEach(point => {
        point.selected = false
      })
    },
    getRoomFromId (roomId) {
      let room = this.rooms.find(room => room.id === roomId)
      return room
    },
    getRoomIdFromName (name) {
      let room = this.rooms.find(room => room.name === name)
      return room.id
    },
    editPrompt (roomIdx, viewpointIdx, identifier) {
      if (this.isReadOnly) { return }
      this.moveVirtualTourPreview(identifier)
      this.setActiveViewpointIdentifier(identifier)
      // close any other open prompt inputs
      this.closeOpenPromptInput()
      this.newPrompt = this.rooms[roomIdx].panoramas[viewpointIdx].prompt
      this.rooms[roomIdx].panoramas[viewpointIdx].editPrompt = true
      this.updateAssignedViewpoints(this.allAssignedViewpoints)
    },
    closeOpenPromptInput () {
      if (Object.keys(this.promptBeingEdited).length > 0) {
        this.rooms[this.promptBeingEdited.roomIdx].panoramas[this.promptBeingEdited.panoIdx].editPrompt = false
      }
    },
    cancelEditPrompt (roomIdx, panoIdx, prompt, topicBoolean) {
      if (typeof topicBoolean === 'string') {
        topicBoolean = topicBoolean === 'Topic'
      }
      if (prompt && this.newPrompt !== prompt) {
        this.rooms[roomIdx].panoramas[panoIdx].prompt = this.newPrompt
        this.rooms[roomIdx].panoramas[panoIdx].topic = topicBoolean
      } else if (prompt === '') {
        this.rooms[roomIdx].panoramas[panoIdx].prompt = ''
        this.rooms[roomIdx].panoramas[panoIdx].topic = topicBoolean
      }
      this.rooms[roomIdx].panoramas[panoIdx].editPrompt = false
      this.newPrompt = ''
      this.updateAssignedViewpoints(this.allAssignedViewpoints)
    },
    promptHandler (roomIdx, panoIdx, prompt, topicOrPrompt) {
      let topicBoolean = topicOrPrompt === 'Topic'
      this.rooms[roomIdx].panoramas[panoIdx].prompt = prompt
      this.rooms[roomIdx].panoramas[panoIdx].topic = topicBoolean
      this.updateAssignedViewpoints(this.allAssignedViewpoints)
      this.changePano(this.rooms[roomIdx].panoramas[panoIdx].identifier)
    },
    submitEditPrompt (roomIdx, panoIdx, prompt, topicOrPrompt) {
      this.$store.commit('toggleInlineSpinner', true)
      let url = `/virtual_tour_panoramas/${this.rooms[roomIdx].panoramas[panoIdx].id}/update`
      let topicBoolean = topicOrPrompt === 'Topic'
      let data = {
        prompt: prompt,
        topic: topicBoolean
      }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { virtual_tour_panorama: data }
      })
        .then(res => {
          setTimeout(() => { this.$store.commit('toggleInlineSpinner', false) }, 2450)
          this.rooms[roomIdx].panoramas[panoIdx].prompt = prompt
          this.rooms[roomIdx].panoramas[panoIdx].topic = topicBoolean
          this.updateQuestionCompleteness(res)
          this.$nextTick(() => {
            this.updateAssignedViewpoints(this.allAssignedViewpoints)
            this.cancelEditPrompt(roomIdx, panoIdx)
            let identifier = this.rooms[roomIdx].panoramas[panoIdx].identifier
            if (identifier !== this.activeViewpointIdentifier) {
              this.moveVirtualTourPreview(identifier)
              this.setActiveViewpointIdentifier(identifier)
            }
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    openDeletePromptModal (roomIdx, panoIdx) {
      if (this.isReadOnly) { return }
      this.deletePromptModal = true
      this.newPrompt = this.rooms[roomIdx].panoramas[panoIdx].prompt
      this.modalPromptId = this.rooms[roomIdx].panoramas[panoIdx].id
      this.selectSweepInBothTours(this.rooms[roomIdx].panoramas[panoIdx].identifier)
    },
    deletePrompt (viewpointId) {
      this.$store.commit('toggleInlineSpinner', true)
      let pano = this.allAssignedViewpoints.find(point => point.id === viewpointId)
      this.rooms[pano.roomIndex].panoramas[pano.panoIndex].prompt = ''
      let topicBoolean = this.rooms[pano.roomIndex].panoramas[pano.panoIndex].topic
      let url = `/virtual_tour_panoramas/${viewpointId}/update`
      let data = { prompt: '' }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { virtual_tour_panorama: data }
      })
        .then(res => {
          setTimeout(() => {
            this.$store.commit('toggleInlineSpinner', false)
          }, 2450)
          this.modalPromptId = null
          this.updateQuestionCompleteness(res)
          this.$nextTick(() => {
            this.deletePromptModal = false
            this.cancelEditPrompt(pano.roomIndex, pano.panoIndex, '', topicBoolean)
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    afterAssignViewpoints (viewpointsAlreadyAssigned, roomId) {
      if (viewpointsAlreadyAssigned.length > 0) {
        // remove viewpoints from their old rooms
        viewpointsAlreadyAssigned.forEach(pano => {
          let oldRoom = this.rooms.find(room => room.id === pano.roomId)
          oldRoom.panoramas.splice(oldRoom.panoramas.findIndex(viewpoint => viewpoint.id === pano.id), 1)
          if (oldRoom.panoramas.length === 0) {
            this.$store.commit('toggleInlineSpinner', true)
            // delete old room if there are no more viewpoints left
            let deleteUrl = `/virtual_tour_rooms/${oldRoom.id}/delete`
            this.$axios.request({
              url: deleteUrl,
              method: 'delete'
            }).then(res => {
              setTimeout(() => {
                this.$store.commit('toggleInlineSpinner', false)
              }, 2450)
              this.rooms.splice(this.rooms.findIndex(room => room.id === oldRoom.id), 1)
              // remove from roomlist
              this.roomNameList.splice(this.roomNameList.findIndex(room => room === oldRoom.name), 1)
            })
          }
        })
      }

      this.selectedViewpointIds.forEach(pano => {
        let viewpoint = this.viewpoints.find(point => point.id === pano)
        viewpoint.roomId = roomId
      })
      // unselect all
      this.unselectAllViewpoints()
    },
    enterUploadPhase () {
      if (this.isReadOnly) { return }
      this.matterportUploadModal = true
    },
    getScreenshot (imgSrc) {
      this.tourScreenshotSrc = imgSrc
    },
    saveScreenshot () {
      // send ajax request to save in database
      let assetData = new FormData()
      let file = this.dataURItoBlob(this.tourScreenshotSrc)
      assetData.append('virtual_tour[image]', file)

      let url = `/virtual_tours/${this.activeQuestion.virtualTourId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: assetData
      })
        .then(res => {
          this.matterportUploadModal = false
        })
        .catch(err => { console.log('err', err) })
    },
    dataURItoBlob (dataURI) {
      var byteStr
      if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteStr = atob(dataURI.split(',')[1]) } else { byteStr = unescape(dataURI.split(',')[1]) }

      var mimeStr = dataURI.split(',')[0].split(':')[1].split(';')[0]

      var arr = new Uint8Array(byteStr.length)
      for (var i = 0; i < byteStr.length; i++) {
        arr[i] = byteStr.charCodeAt(i)
      }

      return new Blob([arr], { type: mimeStr })
    },
    retakeScreenshot () {
      this.$refs.matterportTourScreenshot.tourScreenshotSrc = null
      this.tourScreenshotSrc = null
      this.takeTourScreenshot = false
    },
    takeNewScreenshot () {
      this.$refs.matterportTourScreenshot.takeTourModeScreenshot()
      this.takeTourScreenshot = true
    },
    reassignNewRoom () {
      if (this.doesRoomExist(this.addRoomName)) {
        this.assignRoomToViewpoints(this.addRoomName)
      } else {
        this.createNewRoomFromPrefilledList(this.addRoomName)
      }
    }

  }
}
</script>
