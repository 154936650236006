<script>
const MS_TO_SHOW_INLINE_SPINNER = 2000

export default {
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {}
  },
  methods: {
    saveAndToHub () {
      this.$store.commit('toggleFullSpinner', true)
      this.updateSurvey()
        .then(() => { window.location = `/surveys/${this.survey.id}/edit` })
        .catch(() => { window.location = `/surveys/${this.survey.id}/edit` })
    },
    saveProgress () {
      this.$store.commit('toggleInlineSpinner', true)
      this.updateSurvey()
        .then(() => {
          setTimeout(() => { this.$store.commit('toggleInlineSpinner', false) }, MS_TO_SHOW_INLINE_SPINNER)
        })
        .catch(err => { console.log('err', err) })
    }
  }
}
</script>
