<template>
  <div class="icon_team">
    <div class="icon_team__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 2a2 2 0 11-2-2 2 2 0 012 2zM3 2a1 1 0 10-1 1 1 1 0 001-1z"
          transform="translate(6)"
        />
        <path
          d="M4 2a2 2 0 11-2-2 2 2 0 012 2zM3 2a1 1 0 10-1 1 1 1 0 001-1z"
          transform="translate(0 12)"
        />
        <path
          d="M4 2a2 2 0 11-2-2 2 2 0 012 2zM3 2a1 1 0 10-1 1 1 1 0 001-1z"
          transform="translate(6 12)"
        />
        <path
          d="M4 2a2 2 0 11-2-2 2 2 0 012 2zM3 2a1 1 0 10-1 1 1 1 0 001-1z"
          transform="translate(12 12)"
        />
        <path
          d="M0 0v4"
          transform="translate(8 4)"
        />
        <path
          d="M0 4V0"
          transform="translate(2 8)"
        />
        <path
          d="M0 4V0"
          transform="translate(8 8)"
        />
        <path
          d="M0 4V0"
          transform="translate(14 8)"
        />
        <path
          d="M0 0h12"
          transform="translate(2.001 8)"
        />
        <path
          d="M-.5 0A.472.472 0 010-.5a.472.472 0 01.5.5v4a.472.472 0 01-.5.5.472.472 0 01-.5-.5z"
          transform="translate(8 4)"
        />
        <path
          d="M.5 4a.472.472 0 01-.5.5.472.472 0 01-.5-.5V0A.472.472 0 010-.5a.472.472 0 01.5.5z"
          transform="translate(2 8)"
        />
        <path
          d="M.5 4a.472.472 0 01-.5.5.472.472 0 01-.5-.5V0A.472.472 0 010-.5a.472.472 0 01.5.5z"
          transform="translate(8 8)"
        />
        <path
          d="M.5 4a.472.472 0 01-.5.5.472.472 0 01-.5-.5V0A.472.472 0 010-.5a.472.472 0 01.5.5z"
          transform="translate(14 8)"
        />
        <path
          d="M0 .5A.472.472 0 01-.5 0 .472.472 0 010-.5h12a.472.472 0 01.5.5.472.472 0 01-.5.5z"
          transform="translate(2.001 8)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
