<template>
  <div class="icon_questionnametoggle">
    <div class="icon_questionnametoggle__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h16v1H0z" />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 5)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 10)"
        />
        <path
          d="M0 0h16v1H0z"
          transform="translate(0 15)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
