<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  data: function () {
    const optionIds = this.question.options.map((option) => option.optionId)
    return {
      responses: [],
      optionIds: optionIds
    }
  },
  computed: {
    ...mapState('insights', ['segments']),
    ...mapGetters('insights', ['checkedSegments', 'responsesPassingSegmentFilter']),
    hasQuestionOptions () {
      return this.question.hasOwnProperty('options') && this.question.options.length > 0
    }
  },
  mounted: function () {
    this.$emit('startSpinner')
    this.$store.commit('insights/replaceQuestion', this.question)
    this.$store.commit('insights/replaceModifier', 'votes')
    const url = `/questions/${this.question.id}/results.json`
    this.$axios.request({
      method: 'get',
      url: url
    })
      .then(res => {
        let responses = res.data.map(this.transformResponse).filter(this.filterResponse)
        this.$store.commit('insights/replaceResponses', responses)
        this.$emit('stopSpinner')
      })
      .then(this.afterMountedFetch)
  },
  methods: {
    transformResponse (response) {
      return response
    },
    filterResponse (response) {
      return true
    },
    afterMountedFetch () {
    }
  }
}
</script>
