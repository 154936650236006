<template>
  <div>
    <div
      v-if="!withoutHeader"
      class="st-survey-insights__panel-title st-survey-insights__segment-panel-title"
    >
      Segments
    </div>
    <div
      :class="{
        'st-survey-insights__panel-text': true,
        'st-survey-insights__panel-text--invert': invert
      }"
    >
      {{ resultsCountText }}
    </div>
    <div
      v-if="skippedCount>0"
      class="st-survey-insights__skipped-text"
    >
      {{ skippedCountText }}
    </div>
    <segment-accordion
      v-for="(segment, idx) in segments"
      :key="'segment- '+ segment.segmentId"
      :class="{
        'st-survey-insights__panel-section-with-line': segments.length>1 && idx !== segments.length - 1
      }"
      :segment="segment"
      :idx="idx"
      :invert="invert"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SegmentAccordion from './segment-accordion'

export default {
  components: {
    'segment-accordion': SegmentAccordion
  },
  props: {
    withoutHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    invert: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      hover: false
    }
  },
  computed: {
    ...mapState('insights', ['segments', 'skippedCount']),
    ...mapGetters('insights', ['responsesPassingParticipantCheckboxFilter']),
    resultsCountText () {
      if (this.responsesPassingParticipantCheckboxFilter.length === 1) {
        return 'Displaying 1 result'
      } else {
        return `Displaying ${this.responsesPassingParticipantCheckboxFilter.length} results`
      }
    },
    skippedCountText () {
      if (this.skippedCount === 1) {
        return '1 participant skipped this question'
      } else {
        return `${this.skippedCount} participants skipped this question`
      }
    }
  },
  methods: {
  }
}
</script>
