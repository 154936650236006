<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    initialLeft: function (idx, total, min, max) {
      let spacer = (max - min) / total
      let offset = min + spacer / 2
      return idx * spacer + offset
    }
  }
}

</script>
