<template>
  <div class="icon_signin">
    <div class="icon_signin__svg">
      <svg
        viewBox="0 0 15.999 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-.5 3A3.5 3.5 0 103-.5 3.5 3.5 0 00-.5 3zm1 0A2.5 2.5 0 113 5.5 2.5 2.5 0 01.5 3z"
          transform="translate(1.836 .5)"
        />
        <path
          d="M0 .8l2.5 2.1L0 5l.6.8 3.5-2.9L.6 0z"
          transform="translate(11.901 9.108)"
        />
        <path
          d="M0 1h9.995V0H0z"
          transform="translate(5.003 11.508)"
        />
        <path
          d="M-23457.994-200.01h-7.986l.02-4.375a3.488 3.488 0 011.41-2.648 4.056 4.056 0 013.961-.676 3.634 3.634 0 012.494 2.755l1-.051c-.1-1.843-2.207-3.975-4.988-3.951a4.651 4.651 0 00-4.877 4.554l-.02 5.393h9.986v-2.146h-1z"
          transform="translate(23466.98 215.01)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
