var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModal)?_c('div',[_c('div',{ref:"stModal",staticClass:"st-modal__opacitor",attrs:{"tabindex":"0"},on:{"click":_vm.closeModal,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.escape.apply(null, arguments)}}}),_vm._v(" "),_c('div',{class:[{
      'st-modal': true,
    }, _vm.sizeClass],staticStyle:{"z-index":"9999"}},[_vm._t("default"),_vm._v(" "),(!_vm.hideTitleAndClose)?_c('div',{class:{
        'st-modal__container--title-and-close': true,
        'st-modal__container--title-and-close--hide-close': _vm.hideClose,
      }},[_c('span',{staticClass:"st-modal__title"},[_vm._t("modalTitle")],2),_vm._v(" "),(!_vm.nonExitModal && !_vm.hideClose)?_c('button',{staticClass:"st-modal__icon-close--button",on:{"click":function($event){return _vm.$emit('closeModal', false)}}},[_c('icon-wrapper',{staticClass:"st-modal__icon-close",attrs:{"icon-name":'plus'}})],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{class:_vm.actionsClass},[_vm._t("modalActions"),_vm._v(" "),(!_vm.hideButton)?_c('div',{class:{
          'st-modal__container--button': true,
          'st-cancel-modal__row': _vm.cancelModal
        }},[(_vm.cancelModal)?_c('div',{staticClass:"st-cancel-modal__button",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.$emit('closeModal', false)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('closeModal', false)}}},[_vm._v("\n          Cancel\n          "),_c('icon-wrapper',{staticClass:"st-cancel-modal__icon",attrs:{"icon-name":"cancel"}})],1):_vm._e(),_vm._v(" "),_vm._t("modalButton")],2):_vm._e()],2),_vm._v(" "),_vm._t("additionalInformation")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }