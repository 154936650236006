<template>
  <record-members
    :members="members"
    :survey-id="surveyId"
    title="Survey"
    :users="users"
    :can-add-record-member="isUserRecordAdmin"
    :can-open-dropdown="canEdit"
    @invite-members="inviteSurveyMembers"
    @modalClosed="$emit('modalClosed')"
    @modalOpen="$emit('modalOpen')"
    @removeMember="$emit('removeMember', $event)"
    @updateMember="$emit('updateMember', $event)"
  >
    <template v-slot="{ openMembers }">
      <st-button
        class="st-survey-hub__share-button"
        :caption="canEdit ? 'Share' : 'View'"
        icon-name="multiple-members"
        :show-action="true"
        :variant="['small']"
        @click.native.stop="openMembers"
      />
    </template>
  </record-members>
</template>

<script>
import { mapState } from 'vuex'
import RecordMembers from '../projects/record-members'
import { StButton } from '../../common'

export default {
  components: {
    RecordMembers,
    StButton
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    surveyId: {
      type: Number,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState(['currentUser']),
    currentSurveyUser () {
      return this.members.find(member => member.id === this.currentUser.id) || {}
    },
    isUserRecordAdmin () {
      return this.currentSurveyUser.permission === 'admin'
    }
  },
  methods: {
    existingUsersPromise (existingUsersToAdd) {
      let listOfPromises = existingUsersToAdd.map(user => {
        return this.$axios.request({
          method: 'post',
          url: `/surveys/${this.surveyId}/survey_members`,
          data: {
            user_id: user.id,
            permission: 'read'
          }
        })
      })
      return Promise.all(listOfPromises)
    },
    getSurveyMembers () {
      this.$axios.request({
        method: 'get',
        url: `/surveys/${this.surveyId}`
      }).then(res => {
        console.log(res)
        this.$emit('updateMembers', res.data.members)
      })
    },
    inviteSurveyMembers ({ existingUsersToAdd, newEmailsToAdd }) {
      this.newUsersPromise(newEmailsToAdd)
        .then(() => this.existingUsersPromise(existingUsersToAdd))
        .then(() => this.getSurveyMembers())
    },
    newUsersPromise (newEmailsToAdd) {
      if (newEmailsToAdd.length > 0) {
        return this.$axios.request({
          method: 'post',
          url: '/users/invite',
          data: {
            emails: newEmailsToAdd,
            survey_id: this.surveyId,
            survey_permission_levels: new Array(newEmailsToAdd.length).fill('read'),
            kind: 'member'
          }
        })
      } else {
        return Promise.resolve()
      }
    }
  }
}
</script>
