<template>
  <div class="icon_tutorials">
    <div class="icon_tutorials__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h14v1H0z"
          transform="translate(1)"
        />
        <path
          d="M0 0h10v1H0z"
          transform="translate(3 2)"
        />
        <path
          d="M0 0h2.545v1H0z"
          transform="translate(3 9)"
        />
        <path
          d="M0 0h2.492v1H0z"
          transform="translate(10.508 9)"
        />
        <path
          d="M0 0h5v1H0z"
          transform="translate(0 11)"
        />
        <path
          d="M0 0h5v1H0z"
          transform="translate(11 11)"
        />
        <path
          d="M0 0h1v12H0z"
          transform="translate(14)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(12 2)"
        />
        <path
          d="M0 0h1v12H0z"
          transform="translate(1)"
        />
        <path
          d="M0 0h1v8H0z"
          transform="translate(3 2)"
        />
        <path
          d="M2 0l2 3H0z"
          transform="rotate(90 0 10)"
        />
        <path
          d="M8 4a4 4 0 11-4-4 4 4 0 014 4zM7 4a3 3 0 10-3 3 3 3 0 003-3z"
          transform="translate(4 8)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
