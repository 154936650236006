<template>
  <component
    :is="url ? 'a' : 'button'"
    :key="disabled"
    :href="url"
    class="st-button"
    :type="type"
    :class="modifierClasses"
    :disabled="disabled"
    @click="handleNewPath"
    @focus="hover = true"
    @focusout="active = false, hover = false"
    @mousedown="active = true"
    @mouseenter="hover = true"
    @mouseleave="active = false, hover = false"
  >
    <div
      :class="{
        'st-button-content': true,
        'st-button-content--hover': hover && !disabled,
        'st-button-content--hover--secondary': hover && !disabled && variant.includes('secondary'),
        'st-button-content--hover--white-background': hover && !disabled && variant.includes('whiteBackground'),
        'st-button-content--active': active
      }"
    >
      <slot>
        <div
          v-if="displayCaption"
          class="st-button__caption"
        >
          {{ caption }}
        </div>
        <div
          v-if="displayAction"
          class="st-button__action"
        >
          {{ action }}
        </div>
        <icon-wrapper
          v-if="iconName.length>0"
          class="st-button__icon"
          :actionable="!disabled"
          :icon-name="iconName"
          :invert="variant.includes('secondary')"
        />
      </slot>
    </div>
  </component>
</template>

<script>
import HandleNewPath from '../../mixins/handleNewPath'
import IconWrapper from './icon-wrapper'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [HandleNewPath],
  props: {
    /** Text information that is displayed in the button. */
    caption: {
      type: String,
      required: false,
      default: '',
      validator: (val) => val.length <= 20
    },
    /** Blocks the button from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used by iconWrapper to display icon entered. */
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    /** Determines if, on :hover, the first word of the caption appears. */
    showAction: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    /** Can be set to false to remove underline effect */
    underline: {
      type: Boolean,
      required: false,
      default: true
    },
    /** Can be used to change the kind of button (ex: narrow, or small) */
    variant: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      active: false,
      hover: false
    }
  },
  computed: {
    action () {
      return this.caption.replace(/ .*/, '')
    },
    modifierClasses () {
      return {
        'st-button--underline': this.underline,
        'st-button--active': this.active,
        'st-button--hover': this.hover,
        'st-button--narrow': this.variant.includes('narrow'),
        'st-button--small': this.variant.includes('small'),
        'st-button--secondary': this.variant.includes('secondary'),
        'st-button--mobile-tall': this.variant.includes('mobile-tall')
      }
    },
    displayCaption () {
      if (this.disabled) { return true }
      if (!this.showAction) { return true }
      return !(this.hover || this.active)
    },
    displayAction () {
      if (this.disabled) { return false }
      if (!this.showAction) { return false }
      return this.hover || this.active
    }
  }
}
</script>
