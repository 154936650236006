<template>
  <div class="icon_caretup">
    <svg
      viewBox="0 0 32 32"
    >
      <path d="M8 20.695l7.997-11.39L24 20.695z" />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
