<template>
  <div class="icon_survey-insight">
    <div class="icon_survey-insight__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 0h16v1H0z"
            transform="translate(0 15)"
          />
          <path d="M0 0h1v16H0z" />
          <path
            d="M-23361.658-82.183l4.617-7.525 2.809 4.037 6.125-10.294.859.511-6.918 11.628-2.824-4.056-3.816 6.221z"
            transform="translate(23363.232 95.965)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
