var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-inline-spinner--no-small': !_vm.small,
    'st-inline-spinner--small': _vm.small
  }},[_c('cv-loading',{class:{
      'st-spinner': true,
      'st-inline-spinner': _vm.blackColor,
    },attrs:{"small":_vm.small}}),_vm._v(" "),_c('small',{class:{
      'text-grey': true,
      'text-grey--smaller': _vm.small
    }},[_vm._v(_vm._s(_vm.loadingText))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }