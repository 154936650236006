var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-participant__container': true,
    'st-participant__container--demo': _vm.isDemoPreview
  }},[_c('div',{class:{
      'st-participant__header': true,
      'st-participant__header--hide': _vm.hideLogoHeader || _vm.question.questionType === 'virtual_tour',
      'st-participant__header--desktop': _vm.isDesktop,
      'st-participant__header--full-width': _vm.question.fullWidth
    }},[_c('a',{staticClass:"st-participant__back-icon",attrs:{"href":"#"},on:{"click":_vm.$goBack}},[_c('icon-wrapper',{staticClass:"st-hyperlink__back-icon",attrs:{"icon-name":"chevron"}})],1),_vm._v(" "),(_vm.isPreview && !_vm.isDesktop)?_c('st-hyperlink',{attrs:{"text":"Exit Preview","icon-name":"hide-password","icon-class":"st-hyperlink__default-icon-spacing","url":(_vm.baseUrl + "/surveys/" + _vm.surveyId + "/edit/content?question=" + (_vm.question.id) + "&type=" + (_vm.question.questionType))}}):_c('div',{staticClass:"st-participant__question-count"},[_vm._v("\n      "+_vm._s(_vm.question.priority + 1)+" of "+_vm._s(_vm.surveyQuestionCount)+"\n    ")])],1),_vm._v(" "),_c('div',{class:{
      'st-participant__component-contain': !_vm.isDemoPreview,
      'st-participant__component-and-footer': _vm.isDesktop,
      'st-participant__component-and-footer--demo-preview': _vm.isDemoPreview
    }},[_c('div',{class:{
        'bx--grid': _vm.question.questionType !== 'virtual_tour',
        'st-participant__component-wrapper': true,
        'st-participant__component-wrapper--hide-logo-header': _vm.hideLogoHeader,
        'st-participant__component-wrapper--full-width': _vm.fullWidth,
        'st-participant__component-wrapper--virtual-tour': _vm.question.questionType === 'virtual_tour',
        'st-participant__component-wrapper--demo-preview': _vm.isDemoPreview
      }},[_c(_vm.componentName,{key:_vm.question.id,tag:"component",attrs:{"ask-for-explanation":_vm.question.askForExplanation,"description":_vm.question.description,"image":_vm.question.image,"image-url":_vm.question.imageUrl,"options":_vm.question.options,"question-id":_vm.question.id,"priority":_vm.question.priority,"prompt-lookup":_vm.question.promptLookup,"logo-url":_vm.companyLogo,"min-x-label":_vm.question.minXLabel,"max-x-label":_vm.question.maxXLabel,"min-y-label":_vm.question.minYLabel,"max-y-label":_vm.question.maxYLabel,"media-full-url":_vm.question.mediaFullUrl,"media-url":_vm.question.mediaUrl,"matterport-id":_vm.question.matterportId,"panoskin-id":_vm.question.panoskinId,"media":_vm.question.media,"next-question-id":_vm.question.nextQuestionId || 0,"next-preview-question":_vm.question.nextPreviewQuestion,"previous-preview-question":_vm.question.previousPreviewQuestion,"survey-id":_vm.surveyId,"survey-question-count":_vm.surveyQuestionCount,"is-demo-preview":_vm.isDemoPreview,"is-preview":_vm.isPreview,"is-join-survey":_vm.isJoinSurvey,"is-survey-preview":_vm.isPreview,"question-responses":_vm.question.questionResponses,"is-single-question-survey":_vm.surveyQuestionCount === 1,"can-skip":_vm.question.canSkip,"scale-length":_vm.question.scaleLength,"scale-style":_vm.question.scaleStyle,"spacetrics-logo":_vm.spacetricsLogo,"button-caption":_vm.buttonCaption,"show-question-instructions":_vm.question.showInstructions},on:{"errorMessage":_vm.errorMessage,"hideLogoHeader":_vm.handleHideLogoHeader,"hideSpacetricsFooter":_vm.handleHideSpacetricsFooter,"loading":function($event){_vm.active = true},"nextQuestion":_vm.nextQuestion}})],1)]),_vm._v(" "),(_vm.showErrorToast)?_c('st-toast-notification',{staticClass:"st-toast-notification--fixed",attrs:{"kind":"error","title":"Error","caption":_vm.error},on:{"click":function($event){_vm.showErrorToast = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }