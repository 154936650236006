<template>
  <div
    v-if="iOSBrowserNotSupported"
    class="st-unsupported-browser"
  >
    <div class="st-unsupported-browser__image" />
    <h2 class="st-unsupported-browser__title">
      Sorry, this feature is only available through Safari.
    </h2>
    <div class="st-unsupported-browser__message">
      To answer the survey, please copy the link below and paste it into Safari’s Address Bar.
    </div>

    <div class="st-unsupported-browser__btn-container">
      <StButton
        class="st-unsupported-browser__btn"
        caption="Copy Link"
        :underline="false"
        :variant="['secondary']"
        icon-name="share-link"
        @click="tryCopyLink"
      />
    </div>
    <StToastNotification
      v-if="showToast"
      :title="toastTitle"
      :caption="toastCaption"
      :kind="toastKind"
    />
  </div>
</template>

<script>
import { StButton, StToastNotification } from '../common'

export default {
  components: {
    StButton,
    StToastNotification
  },
  props: {
  },
  data: function () {
    return {
      url: window.location.href,
      navigator: window.navigator.platform,
      showToast: false,
      toastTitle: 'Link Copied!',
      toastCaption: '',
      toastKind: 'info'
    }
  },
  computed: {
    canRecord () {
      // check if voice recording is allowed
      return !!window.navigator.mediaDevices
    },
    iOSBrowserNotSupported () {
      return this.navigator === 'iPhone' && !this.canRecord
    }
  },
  mounted: function () {
    if (this.iOSBrowserNotSupported) {
      this.$store.commit('toggleFullscreen', true)
    }
  },
  methods: {
    tryCopyLink () {
      navigator.clipboard.writeText(window.location.href)
        .then(() => {
          this.showToast = true
        })
        .catch((x) => {
          if (document.execCommand('copy')) {
            this.execCopyLink()
          } else {
            this.toastTitle = 'Unable to copy link'
            this.toastCaption = x
            this.toastKind = 'error'
            this.showToast = true
          }
        })
    },
    execCopyLink () {
      const textArea = document.createElement('textarea')
      textArea.style.opacity = 0
      textArea.style.position = 'absolute'
      textArea.value = window.location.href
      textArea.contentEditable = true
      textArea.readOnly = false
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      this.showToast = true
    }
  }
}
</script>
