<template>
  <div class="icon_world-location">
    <div class="icon_world-location__svg">
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M4.857 3.328a.471.471 0 11.559-.757 4.99 4.99 0 011.334 1.52c.572.958 1.021 2.374.33 3.268A4.564 4.564 0 015.46 8.46c-.772.377-1 .552-1.019.66-.025.187.28.575.692 1.051a3.651 3.651 0 011.072 1.942A3.108 3.108 0 014.7 15.164a.471.471 0 01-.484-.807 2.175 2.175 0 001.062-2.109c-.17-1.17-1.918-2.115-1.766-3.253s2.2-1.407 2.828-2.212c.4-.516-.055-1.642-.394-2.21a4.063 4.063 0 00-1.089-1.245z"
            transform="translate(-1.446 -1.125)"
          />
          <path
            d="M17.384 3.45a.471.471 0 01.407.849 16.66 16.66 0 01-4.049 1.259c-.8.057-1.126-.437-1.483-1.061C11.345 2.9 10.136 2.5 10.592.924a.473.473 0 11.9.261 1.687 1.687 0 00-.056.48c0 .215.258.555.726 1.09 1.142 1.307 1.028 1.9 1.508 1.863a16.19 16.19 0 003.714-1.168z"
            transform="translate(-3.655 -.511)"
          />
          <path
            d="M13.8 9.453a2.843 2.843 0 013.421 2.18c.332 2.377-2.883 5.661-4.743 4.9-1.463-.6-.816-1.819-.9-3.8-.026-.6-.091-.813-.069-1.118A2.612 2.612 0 0113.8 9.453zm.163.927c-.811.142-1.6.742-1.514 1.492a12.622 12.622 0 01.026 2.525c-.064.916-.082 1.087.359 1.267 1.006.41 3.493-1.882 3.468-3.731a1.866 1.866 0 00-2.34-1.553z"
            transform="translate(-3.97 -3.309)"
          />
          <path
            d="M15.5 7.5a8 8 0 11-8-8 8 8 0 018 8zm-.941 0A7.059 7.059 0 107.5 14.559 7.059 7.059 0 0014.559 7.5z"
            transform="translate(.5 .5)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
