<template>
  <div
    :class="{
      'st-dropdown-menu': true,
      'st-dropdown-menu--open': open
    }"
    :style="{
      width: minWidth && mobileMinWidth
    }"
  >
    <div
      v-if="open"
      class="st-dropdown-menu__mobile-opacitor"
      @click="open = false"
    />
    <div
      v-if="open && displayCaption"
      :class="{
        'st-dropdown-menu__label': true,
        'st-dropdown-menu__label--select-multiple': selectMultiple,
      }"
    >
      <template>
        {{ caption }}
      </template>
      <p
        v-if="selectMultiple"
        class="st-dropdown-menu__amount-selected"
      >
        ({{ selectedOptions.length }}
        <icon-wrapper
          icon-name="plus"
          class="st-dropdown-menu__amount-selected--icon"
          :spacetrics-blue="true"
        />
        )
      </p>
    </div>
    <div
      :class="{
        'st-dropdown-menu__align-chosen': true,
        'st-dropdown-menu__align-chosen--menu-open': open,
        'st-dropdown-menu__disabled': disabled
      }"
      :style="{
        width: minWidth && mobileMinWidth
      }"
      @click="checkIfDisabled"
    >
      <input
        ref="chosen"
        :value="dropdownValue"
        type="button"
        :class="inputClasses"
      >
      <div
        v-if="open"
        class="st-dropdown-menu__option-type-mobile"
      >
        {{ caption }}
      </div>
      <icon-wrapper
        :class="{
          'st-dropdown-menu__icon': true,
          'st-dropdown-menu__icon--open': open
        }"
        :icon-name="checkIconName"
      />
    </div>
    <div
      v-if="open"
      class="st-dropdown-menu__options-container"
    >
      <input
        v-if="activeOptionText"
        :value="activeOptionText"
        type="button"
        :class="{
          'st-dropdown-menu__chosen-option-mobile st-dropdown-menu__options': true,
          'st-dropdown-menu__options--select-multiple': selectMultiple
        }"
      >
      <template v-if="selectMultiple && !Object.keys(accordionContentsValues).length">
        <div class="st-dropdown-menu__options-scroll">
          <st-checkbox
            v-for="(option, idx) in optionsData"
            :key="'option ' + idx"
            :checked="option.selected"
            class="st-dropdown-menu__multiple-select-option"
            @input="updateMultiple(idx)"
          >
            {{ option.name }}
          </st-checkbox>
        </div>
      </template>
      <template v-else-if="selectMultiple && Object.keys(accordionContentsValues).length">
        <st-accordion
          v-for="(option, idx) in optionsData"
          :key="'option ' + idx"
          class="st-dropdown-menu__accordion"
          :expanded-text="`${option.value.participants.length}` + ' recipients'"
          visible-row-class="st-dropdown-menu__visible-row-size"
        >
          <template
            slot="visible-section"
          >
            <st-checkbox
              :checked="option.selected"
              :class="{
                'st-dropdown-menu__accordion-checkbox': true,
                'st-dropdown-menu__accordion-checkbox--selected': option.selected
              }"
              :disabled="activeToggleDisable ? option.disabled : disableCheckbox"
              @input="updateMultiple(idx)"
            >
              {{ option.name }}
            </st-checkbox>
          </template>
          <template slot="expanded-section">
            <slot
              name="accordion-expanded-section"
              :value="option.value"
            />
          </template>
        </st-accordion>
      </template>
      <template v-else>
        <div
          v-for="(option) in inactiveOptions"
          :key="option"
          class="st-dropdown-menu__options-wrap"
        >
          <input
            class="st-dropdown-menu__options"
            type="button"
            :value="option"
            @click="updateSingle(option)"
          >
        </div>
      </template>
      <div
        v-if="open"
        class="st-dropdown-menu__mobile-bar"
      />
    </div>
  </div>
</template>

<script>
import { kebabCase } from 'lodash'
import { IconWrapper, StAccordion, StCheckbox } from '../common'

const variants = ['default']

export default {
  components: {
    IconWrapper,
    StAccordion,
    StCheckbox
  },
  props: {
    /** Used to display nested information in accordions.
     *  Accessed by optionsData.value.'accordionContentsValues'
     * (expects 'nestedProperty' and 'nestedValue')
    */
    accordionContentsValues: {
      type: Object,
      require: false,
      default: () => ({})
    },
    /**
       * Used to prevent a selected checkboxes from being removed
       */
    activeToggleDisable: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Additional information for the type of dropdown in mobile.
       */
    caption: {
      type: String,
      require: false,
      default: ''
    },
    /**
       * Passed to st-checkbox to prevent use
       */
    disableCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * How to get the caption to display for an option.
     * By default it's just the option (used for multi-select)
     */
    optionCaptionFn: {
      type: Function,
      required: false,
      default: option => option
    },
    /**
       * Blocks the dropdown from usage.
       */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * should the caption be displayed above the menu when the menu has focus?
       */
    displayCaption: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Set the min-width of the dropdown to be 100%.
       */
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Starting selected value.
       */
    initialActiveOption: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * Starting selected array of values for multi-select.
       */
    initialActiveOptionsArray: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
       * can you select more than one option in the dropdown?
       */
    selectMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * All available options, including the active option
       */
    options: {
      type: Array,
      required: true
    },
    /**
       * displayed in the dropdown selected position when there is nothing selected and the menu is open
       */
    placeholder: {
      type: String,
      required: false,
      default: 'Select one'
    },
    /**
       * Can be used to change the kind of dropdown-menu
       */
    variant: {
      type: String,
      required: false,
      default: 'default',
      validator: (val) => variants.includes(val)
    }
  },
  data: function () {
    let initialActiveCaptions = new Set(this.initialActiveOptionsArray.map(this.optionCaptionFn))
    return {
      activeIndex: this.options.indexOf(this.initialActiveOption),
      open: false,
      optionsData: this.options.map(option => {
        let caption = this.optionCaptionFn(option)
        return {
          disabled: initialActiveCaptions.has(caption),
          name: caption,
          value: option,
          selected: initialActiveCaptions.has(caption)
        }
      }),
      minWidth: 'unset'
    }
  },
  computed: {
    selectedOptions () {
      if (this.selectMultiple) {
        return this.optionsData.filter(option => {
          return option.selected
        })
      } else {
        return [this.options[this.activeIndex]]
      }
    },
    activeOptionText () {
      if (this.selectMultiple) {
        if (this.selectedOptions.length > 0) {
          return this.selectedOptions.map(option => option.name).join(', ')
        } else {
          return undefined
        }
      } else {
        return this.options[this.activeIndex]
      }
    },
    checkIconName () {
      if (this.open) {
        return 'caret-up'
      }
      return 'caret-down'
    },
    inactiveOptions () {
      return this.options.filter((_val, idx) => idx !== this.activeIndex)
    },
    dropdownValue () {
      if (this.open) {
        if (this.selectMultiple || !this.activeOptionText) {
          return this.placeholder
        } else {
          return this.activeOptionText
        }
      } else {
        if (this.activeOptionText) {
          return this.activeOptionText
        } else {
          return this.caption
        }
      }
    },
    mobileMinWidth () {
      if (this.open && window.innerWidth <= 576) {
        return window.innerWidth + 'px'
      } else {
        return this.minWidth
      }
    },
    extraClass () {
      return 'st-extra-class--' + kebabCase(this.dropdownValue)
    },
    inputClasses () {
      let obj = {
        'st-dropdown-menu__chosen-option': true,
        'st-dropdown-menu__chosen-option--multiple': this.selectMultiple,
        'st-dropdown-menu__chosen-option--menu-open': this.open,
        'st-dropdown-menu__chosen-option--disabled': this.disabled,
        'st-dropdown-menu__chosen-option--empty': this.dropdownValue === this.caption
      }
      obj[this.extraClass] = true
      return obj
    }
  },
  mounted () {
    // set min width to accomodate the hover effect and to be long enough for no ellipsis
    this.$nextTick(() => {
      if (!this.fullWidth) {
        let lengths = []
        this.options.forEach(option => {
          this.$refs.chosen.value = option
          lengths.push(this.$refs.chosen.clientWidth)
        })
        this.minWidth = Math.max(...lengths) + 87 + 'px'
        this.$refs.chosen.value = this.dropdownValue
      }
      if (this.selectMultiple && this.initialActiveOption.length && this.activeIndex === -1) {
        this.activeIndex = this.options.indexOf(this.initialActiveOption)
      }
    })
  },
  methods: {
    checkIfDisabled () {
      if (!this.disabled) { this.open = !this.open }
    },
    updateSingle (option) {
      let idx = this.options.indexOf(option)
      this.activeIndex = idx
      this.$emit('updateOption', option)
      this.open = false
    },
    updateMultiple (index) {
      this.toggleSelected(index)
      let optionValues = this.selectedOptions.map(option => option.value)
      this.$emit('updateMultiple', optionValues, index)
    },
    toggleSelected (optionIdx) {
      this.optionsData[optionIdx].selected = !this.optionsData[optionIdx].selected
    }
  }
}
</script>
