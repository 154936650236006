<template>
  <div class="specifications__section">
    <div class="specifications__title">
      Perception Slider
    </div>
    <div class="st-input__row">
      <label class="st-input__label">
        Question
        <input
          :value="activeQuestion.description"
          class="st-input"
          type="text"
          name="question[description]"
          placeholder="Your question goes here"
          :disabled="isPreview"
          @blur="updateQuestion('description', $event.target.value)"
        >
      </label>
    </div>
    <div class="st-input__row">
      <label class="st-input__label st-input__label--half-length">
        Category 1
        <input
          :value="activeQuestion.minXLabel"
          class="st-input"
          type="text"
          placeholder="Category name goes here"
          :disabled="isPreview"
          @blur="updateQuestion('min_x_label', $event.target.value)"
        >
      </label>
      <label class="st-input__label st-input__label--half-length">
        Category 2
        <input
          :value="activeQuestion.maxXLabel"
          class="st-input"
          type="text"
          placeholder="Category name goes here"
          :disabled="isPreview"
          @blur="updateQuestion('max_x_label', $event.target.value)"
        >
      </label>
    </div>
    <div class="st-input__row">
      <label class="st-input__label st-input__label--half-length">
        Pin 1
        <input
          :value="options[0].name"
          class="st-input"
          type="text"
          placeholder="Pin 1 label goes here"
          :disabled="isPreview"
          @blur="updateQuestionOption(options[0].optionId, 0, $event.target.value)"
        >
      </label>
      <label class="st-input__label st-input__label--half-length">
        Pin 2
        <input
          :value="options[1].name"
          class="st-input"
          type="text"
          placeholder="Pin 2 label goes here"
          :disabled="isPreview"
          @blur="updateQuestionOption(options[1].optionId, 1, $event.target.value)"
        >
      </label>
    </div>
  </div>
</template>

<script>
import updateQuestion from '../../../mixins/question/updateQuestion'
import updateQuestionOption from '../../../mixins/question/updateQuestionOption'

export default {
  mixins: [updateQuestion, updateQuestionOption],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
    }
  }
}
</script>
