<template>
  <div class="icon_alert">
    <div class="icon_alert__svg">
      <svg
        viewBox="0 0 29 29"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(17380 -11016)">
          <path
            d="M2.536-2.455l-.245-4.09h1.145l-.245 4.09zM2.872.082a.9.9 0 01-.6-.168.654.654 0 01-.192-.52v-.229a.678.678 0 01.192-.532.885.885 0 01.6-.172.868.868 0 01.6.172.686.686 0 01.188.532v.229a.654.654 0 01-.192.52.885.885 0 01-.596.168z"
            transform="translate(-17368 11035)"
          />
          <path
            d="M16 16H0L8 0zM8 2.236L1.618 15h12.764z"
            transform="translate(-17373 11022)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
