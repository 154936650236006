<template>
  <div class="st-dropdown-button__container">
    <div
      v-if="dropdownOpen"
      class="st-overflow-menu__disable-screen"
      @mouseup.stop="dropdownOpen=!dropdownOpen"
    />
    <st-button
      v-if="!dropdownOpen"
      :caption="caption"
      icon-name="caret-down"
      :show-action="true"
      @click.native.stop="openDropdown"
      @keyupEnter="openDropdown"
    />
    <div
      v-else
      class="st-dropdown-button__dropdown-opened"
    >
      <div
        :class="{
          'st-dropdown-button__dropdown-caption-and-icon': true,
          'st-dropdown-button__remove-focus': removeFocus,
        }"
        tabindex="0"
        @focusout="removeFocus = false"
        @keyup.enter="dropdownOpen=!dropdownOpen"
        @mousedown="removeFocus = true"
        @mouseup.stop="dropdownOpen=!dropdownOpen"
      >
        <div
          class="st-dropdown-button__mobile-bar"
        />
        <icon-wrapper
          v-if="!isDesktop"
          icon-name="caret-up"
        />
        <div
          class="st-dropdown-button__dropdown-caption"
        >
          {{ caption }}
        </div>
        <icon-wrapper
          v-if="isDesktop"
          icon-name="caret-up"
        />
      </div>
      <div
        v-for="(option, index) in options"
        :key="index"
        class="st-dropdown-button__dropdown-option"
        tabindex="0"
        @focusout="removeFocus = false"
        @keyup.enter="updateOption(option)"
        @mousedown="removeFocus = true"
        @mouseup="updateOption(option)"
        @blur="closeDropdownIfLast(index)"
      >
        <icon-wrapper
          :icon-name="option.iconName"
        />
        <input
          :class="{
            'st-dropdown-button__option-caption': true,
            'st-dropdown-button__remove-focus': removeFocus,
          }"
          type="button"
          tabindex="-1"
          :value="option.caption"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { isDesktop } from '../../mixins'
import IconWrapper from './icon-wrapper'
import StButton from './st-button'

export default {
  components: {
    'icon-wrapper': IconWrapper,
    'st-button': StButton
  },
  mixins: [isDesktop],
  props: {
    /**
      * Text describing what the dropdown button is used for.
    */
    caption: {
      type: String,
      required: true,
      validator: (val) => val.length <= 18
    },
    /** array of options where each option is an object with:
     * caption, (optionally) iconName
     */
    options: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      dropdownOpen: false,
      removeFocus: false
    }
  },
  mounted () {
    document.addEventListener('click', this.closeDropdown)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeDropdown)
  },
  methods: {
    closeDropdownIfLast (index) {
      if (index === this.options.length - 1) {
        this.closeDropdown()
      }
    },
    closeDropdown () {
      this.dropdownOpen = false
    },
    openDropdown () {
      this.dropdownOpen = true
    },
    updateOption (option) {
      /** an option has been clicked */
      this.$emit('updateOption', option)
      this.dropdownOpen = false
    }
  }
}
</script>
