import Vue from 'vue'

export const PRICES = Object.freeze({ // ($)
  'demographic': 12,
  'recipient': 5
})

function formatMultipleValues (selectedIndexes, lookupObject) {
  return lookupObject.filter((_val, idx) => selectedIndexes[idx]).join(', ')
}

function formatSingleValue (valueIdx, lookupObject) {
  return valueIdx === null ? '' : lookupObject[valueIdx]
}

const emptyIndex = (value) => [null, -1].includes(value)

function updateGenderSelectedIndexes (genderStrings) {
  let genderIndexes = genderStrings.map(gender => {
    return GENDER_NAMES.indexOf(gender)
  })

  return new Array(GENDERS.length).fill(false).map((arr, index) => {
    return !!genderIndexes.includes(index)
  })
}

export const EDUCATION = Object.freeze([
  'All',
  'Primary School',
  'Some High School',
  'High School',
  'Some College',
  '2-Year College',
  '4-Year College',
  'Graduate Degree'
])

export const EDUCATION_NAMES = Object.freeze([
  'all',
  'primary_school',
  'some_high_school',
  'high_school',
  'some_college',
  'college_2_year',
  'college_4_year',
  'graduate_degree'
])

export const GENDERS = Object.freeze([
  'Female',
  'Male',
  'Transgender',
  'Gender Neutral',
  'Non-Binary',
  'Agender',
  'Pangender',
  'Genderqueer',
  'Two-Spirit',
  'Third Gender'
])

export const GENDER_NAMES = Object.freeze([
  'female',
  'male',
  'transgender',
  'gender_neutral',
  'non_binary',
  'agender',
  'pangender',
  'genderqueer',
  'two_spirit',
  'third_gender'
])

export const RELATIONSHIP = Object.freeze([
  'All',
  'Married',
  'Widowed',
  'Divorced',
  'Separated',
  'Single'
])

export const WORK = Object.freeze([
  'All',
  'Part-Time',
  'Full-Time',
  'Not Employed',
  'Retired',
  'Disabled, not allowed to work'
])

export const WORK_NAMES = Object.freeze([
  'all',
  'part_time',
  'full_time',
  'not_employed',
  'retired',
  'disabled_not_able_to_work'
])

const DEFAULT_LATITUDE = 38.895
const DEFAULT_LONGITUDE = -77.036

export default {
  namespaced: true,
  state: {
    ageLow: null,
    ageHigh: null,
    audienceRequestId: null,
    educationIndex: null,
    financialLow: null,
    financialHigh: null,
    genderSelectedIndexes: new Array(GENDERS.length).fill(false),
    locationCity: '',
    locationLatitude: DEFAULT_LATITUDE,
    locationLongitude: DEFAULT_LONGITUDE,
    locationState: '',
    mapMarker: '',
    mapsKey: '',
    name: '',
    numberOfRecipients: null,
    phase: '',
    relationshipIndex: null,
    workIndex: null
  },
  getters: {
    EDUCATION: () => EDUCATION,
    EDUCATION_NAMES: () => EDUCATION_NAMES,
    GENDERS: () => GENDERS,
    GENDER_NAMES: () => GENDER_NAMES,
    PRICES: () => PRICES,
    RELATIONSHIP: () => RELATIONSHIP,
    WORK: () => WORK,
    WORK_NAMES: () => WORK_NAMES,
    educationFormatDisplay: (state) => {
      return formatSingleValue(state.educationIndex, EDUCATION)
    },
    genderFormatDisplay: (state) => {
      return formatMultipleValues(state.genderSelectedIndexes, GENDERS)
    },
    relationshipFormatDisplay: (state) => {
      return formatSingleValue(state.relationshipIndex, RELATIONSHIP)
    },
    workFormatDisplay: (state) => {
      return formatSingleValue(state.workIndex, WORK)
    },
    isAgePopulated: (state) => {
      return state.ageLow !== null && state.ageHigh
    },
    isEducationPopulated: (state) => {
      return !emptyIndex(state.educationIndex)
    },
    isFinancialPopulated: (state) => {
      return state.financialLow !== null && state.financialHigh
    },
    isGenderPopulated: (state) => {
      return state.genderSelectedIndexes.filter(val => val).length > 0
    },
    isLocationPopulated: (state) => {
      return state.locationCity && state.locationState
    },
    isRelationshipPopulated: (state) => {
      return !emptyIndex(state.relationshipIndex)
    },
    isWorkPopulated: (state) => {
      return !emptyIndex(state.workIndex)
    },
    numberOfRecipientsCost: (state) => {
      return state.numberOfRecipients * PRICES.recipient
    },
    totalCost: (state, getters) => {
      const populatedSectionCount = [
        getters.isAgePopulated,
        getters.isEducationPopulated,
        getters.isFinancialPopulated,
        getters.isGenderPopulated,
        getters.isLocationPopulated,
        getters.isRelationshipPopulated,
        getters.isWorkPopulated
      ].filter(x => x).length
      const demographicsMultiplier = PRICES.recipient + (populatedSectionCount * PRICES.demographic)
      return (demographicsMultiplier * state.numberOfRecipients).toFixed(2)
    },
    selectedGenderNames: (state, getters) => {
      let names = []
      for (const idx in state.genderSelectedIndexes) {
        if (state.genderSelectedIndexes[idx]) {
          names.push(getters.GENDER_NAMES[idx])
        }
      }
      return names
    }
  },
  mutations: {
    setMapMarker: (state, marker) => {
      state.mapMarker = marker
    },
    setMapsKey: (state, key) => {
      state.mapsKey = key
    },
    updateOneGender: (state, { idx, isSelected }) => {
      state.genderSelectedIndexes.splice(idx, 1, isSelected)
    },
    replaceData: (state, newData) => {
      state.audienceRequestId = newData.id
      state.ageLow = newData.ageMin
      state.ageHigh = newData.ageMax
      state.educationIndex = EDUCATION_NAMES.indexOf(newData.education)
      state.financialLow = newData.financialMin
      state.financialHigh = newData.financialMax
      state.genderSelectedIndexes = updateGenderSelectedIndexes(newData.gender)
      state.locationCity = newData.locationCity
      state.locationLatitude = newData.latitude || DEFAULT_LATITUDE
      state.locationLongitude = newData.longitude || DEFAULT_LONGITUDE
      state.locationState = newData.locationState
      state.name = newData.name
      state.numberOfRecipients = newData.recipientCount
      state.phase = newData.phase
      state.relationshipIndex = RELATIONSHIP.map(x => x.toLowerCase()).indexOf(newData.relationship)
      state.workIndex = WORK_NAMES.indexOf(newData.work)
    },
    setId: (state, id) => {
      state.audienceRequestId = id
    },
    setAge: (state, [lowAge, highAge]) => {
      state.ageLow = lowAge
      state.ageHigh = highAge
    },
    setFinancial: (state, [lowFinancial, highFinancial]) => {
      state.financialLow = lowFinancial
      state.financialHigh = highFinancial
    },
    setName: (state, newName) => {
      state.name = newName
    },
    setRecipientCount: (state, newCount) => {
      state.numberOfRecipients = newCount
    },
    setStateProp: (state, { propName, propValue }) => {
      // TODO replace w/ named mmutations for each purpose
      Vue.set(state, propName, propValue)
    }
  },
  actions: {
    updateAge: ({ commit, dispatch }, ageArray) => {
      commit('setAge', ageArray)
      dispatch('createOrUpdate', { age_min: ageArray[0], age_max: ageArray[1] })
    },
    updateEducation: ({ commit, dispatch }, idx) => {
      commit('setStateProp', { propName: 'educationIndex', propValue: idx })
      dispatch('createOrUpdate', { education: EDUCATION_NAMES[idx] })
    },
    updateFinancial: ({ dispatch, commit }, financialArray) => {
      commit('setFinancial', financialArray)
      dispatch('createOrUpdate', { financial_min: financialArray[0], financial_max: financialArray[1] })
    },
    updateGender: ({ dispatch, getters, commit }, { idx, isSelected }) => {
      commit('updateOneGender', { idx: idx, isSelected: isSelected })
      dispatch('createOrUpdate', { gender: getters.selectedGenderNames })
    },
    updateLocation: ({ dispatch, commit }, locationData) => {
      commit('setStateProp', { propName: 'locationCity', propValue: locationData.locationCity })
      commit('setStateProp', { propName: 'locationState', propValue: locationData.locationState })
      dispatch('createOrUpdate', { location_city: locationData.locationCity, location_state: locationData.locationState })
    },
    updateLocationCoordinates: ({ dispatch, commit }, locationData) => {
      commit('setStateProp', { propName: 'locationLatitude', propValue: locationData.latitude })
      commit('setStateProp', { propName: 'locationLongitude', propValue: locationData.longitude })
      dispatch('createOrUpdate', { latitude: locationData.latitude, longitude: locationData.longitude })
    },
    updateNumberOfRecipients: ({ dispatch, commit }, newNumber) => {
      commit('setRecipientCount', newNumber)
      dispatch('createOrUpdate', { recipient_count: newNumber })
    },
    updatePhase: ({ commit, dispatch }, newPhase) => {
      commit('setStateProp', { propName: 'phase', propValue: newPhase })
      dispatch('createOrUpdate', { phase: newPhase })
    },
    updateRelationship: ({ dispatch, commit }, idx) => {
      commit('setStateProp', { propName: 'relationshipIndex', propValue: idx })
      dispatch('createOrUpdate', { relationship: RELATIONSHIP[idx].toLowerCase() })
    },
    updateRequestName: ({ commit, dispatch }, newName) => {
      commit('setStateProp', { propName: 'name', propValue: newName })
      dispatch('createOrUpdate', { name: newName })
    },
    updateWork: ({ dispatch, commit }, idx) => {
      commit('setStateProp', { propName: 'workIndex', propValue: idx })
      dispatch('createOrUpdate', { work: WORK_NAMES[idx] })
    },
    createOrUpdate: ({ state, getters, dispatch }, requestData) => {
      // return dispatches in case the promise is used
      let data = { ...requestData, price: getters.totalCost }
      if (state.audienceRequestId) {
        return dispatch('updateAudienceRequest', data)
      } else {
        return dispatch('createAudienceRequest', data)
      }
    },
    createAudienceRequest: ({ rootState, commit }, data) => {
      return rootState.axios.request({
        url: '/audience_requests',
        method: 'post',
        data: { audience_request: { ...data, a: 'a' } }
      })
        .catch(err => { console.log('err', err) })
        .then(res => { commit('setId', res.data.id) })
    },
    updateAudienceRequest: ({ rootState, state }, data) => {
      return rootState.axios.request({
        url: `/audience_requests/${state.audienceRequestId}`,
        method: 'patch',
        data: { audience_request: data }
      })
        .catch(err => { console.log('err', err) })
    }
  }
}
