<template>
  <div class="icon_uploadphoto">
    <div class="icon_uploadphoto__svg">
      <svg
        viewBox="0 0 16 16.002"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M0 0h1v11H0z"
            transform="translate(5 5)"
          />
          <path
            d="M0 0h1v11H0z"
            transform="translate(15 5)"
          />
          <path
            d="M0 0h1v11H0z"
            transform="rotate(90 5.5 10.5)"
          />
          <path
            d="M0 0h1v11H0z"
            transform="rotate(90 .5 15.5)"
          />
          <path
            d="M4.1.8L1.6 2.9 4.1 5l-.6.8L0 2.9 3.5 0z"
            transform="rotate(90 2.898 2.898)"
          />
          <path
            d="M15 1H0V0h15z"
            transform="rotate(90 1.198 2.2)"
          />
          <path
            d="M-23178.012-229.667l-2.186 4h7l-1.2-2.638-1.66 1.087z"
            transform="translate(23187.197 239.667)"
          />
          <path
            d="M1 0a1 1 0 11-1 1 1 1 0 011-1z"
            transform="translate(12 7)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
